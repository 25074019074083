import './App.css';
import { db, auth, storage } from './firebaseData';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { strings } from './translations';
import _ from 'lodash';
import axios from 'axios';
import { isIOS } from 'react-device-detect';
import CssBaseline from '@mui/material/CssBaseline';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Chip from '@mui/material/Chip';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { constants } from './components/constants';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  Timestamp,
  addDoc,
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import PhoneInput from 'react-phone-number-input';
import DiscountIcon from '@mui/icons-material/Discount';
import ButtonGroup from '@mui/material/ButtonGroup';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { AspectRatio } from 'react-aspect-ratio';
import { Link, useParams } from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HomeIcon from '@mui/icons-material/Home';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Carousel from 'react-material-ui-carousel';
import MoneyIcon from '@mui/icons-material/Money';
import RemoveIcon from '@mui/icons-material/Remove';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import * as geolib from 'geolib';
import moment from 'moment';
import * as React from 'react';
import OtpInput from 'react18-otp-input';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Geocode from 'react-geocode';
import Toolbar from '@mui/material/Toolbar';
import ToggleButton from '@mui/material/ToggleButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useWindowDimensions from './getDimen';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Menu from '@mui/material/Menu';
import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import DataSaverOnRoundedIcon from '@mui/icons-material/DataSaverOnRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select from '@mui/material/Select';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';
import MoreIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarIcon from '@mui/icons-material/Star';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import parse from 'autosuggest-highlight/parse';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { v4 as uuidv4 } from 'uuid';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  updatePassword,
} from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GoogleMapReact from 'google-map-react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { ReactComponent as BusinessSvg } from './assets/business.svg';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import {
  Link as _ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { ReactComponent as Kg_svg } from './assets/kg_icon.svg';
import { Tooltip } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const mainLogo2 = require('./assets/logo2.png');
const stripePromise = loadStripe('pk_test_51Groo5F76HIlmZyWmhOPE8wDkPI40bvJrOmrhsPD4qCMKjPCIjgtuoPLzAwf0xhJHG1B95rGO5sOOgQlziO9P6Yb00tfKKwtpx');
const logo = require('./assets/loading.gif');
const closed_english = require('./assets/closed_english.png');
const closed_spanish = require('./assets/closed_spanish.png');
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const emails = ['username@gmail.com', 'user02@gmail.com'];
// const list = [
//   {
//     id: 'a',
//     firstname: 'Robin',
//     lastname: 'Wieruch',
//     year: 1988,
//   },
//   {
//     id: 'b',
//     firstname: 'Dave',
//     lastname: 'Davidds',
//     year: 1990,
//   },
//   {
//     id: 'c',
//     firstname: 'Robin',
//     lastname: 'Davidds',
//     year: 1988,
//   },
//   {
//     id: 'd',
//     firstname: 'Dave',
//     lastname: 'Wieruch',
//     year: 1990,
//   },
// ];

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

const scheduleFormatted = ({ hour, minute }) => {
  const checkTime = (val) => (val < 10 ? `0${val}` : val);
  return `${checkTime(hour)}:${checkTime(minute)}`;
};
const DAYS = [
  strings['Lun'],
  strings['Mar'],
  strings['Mié'],
  strings['Jue'],
  strings['Vie'],
  strings['Sáb'],
  strings['Dom'],
];

const ScrollLink = styled(_ScrollLink)(({ theme, color }) => ({
  '&.active-class': {
    color: color,
    fontWeight: '800',
  },
}));

const Order = styled('div')(({ theme }) => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'row',
  height: '19vh',
  margin: 'auto',
  marginTop: 15,
  borderRadius: 12,
  backgroundColor: '#fff',
  border: '1px solid #e6ebf1',
  boxShadow: 'none',
  padding: '12px 6px 12px',
  '& p': {
    fontSize: '14px',
  },
  '&:hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 10px #64717a42',
  },
  '@media screen and (max-width: 480px)': {
    flexDirection: 'column',
    height: 'auto',
    width: '90%',
    textAlign: 'center',
  },
}));

const ScheduleBlock = styled('div')(({ theme, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '0px 15px',
  borderRight: '1px solid #CCC',
  width: '10%',
  minWidth: '80px',
  '& h4': {
    margin: '0px 0px 3px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: color,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '& p': {
    margin: '0px',
    fontSize: '14px',
  },
  '&:last-child': {
    border: 0,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme, color }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  backgroundColor: color,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(2em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function BusinessInfoDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Información adicional']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {displayList}
    </Dialog>
  );
}

BusinessInfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  displayList: PropTypes.element.isRequired,
};

function WalletDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };

  return (
    <Dialog
    style={{zIndex: 99999}}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Cartera']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {displayList}
    </Dialog>
  );
}


WalletDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  displayList: PropTypes.element.isRequired,
};

function RegisterAdditionalDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.
  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }
  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }
  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };
  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Registro']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}
RegisterAdditionalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function AddCardDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 199999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Agregar tarjeta']}
        {onClose ? (
          <IconButton
            style={{ color: 'slategray' }}
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

AddCardDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function PreOrderDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings["Programar tu entrega"]}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

PreOrderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};
function SimpleDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Mis direcciones']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function MinimumDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList, displayTitle } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  return (
    <Dialog
    style={{ zIndex: 199999 }}
      fullScreen={false}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {displayTitle}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {displayList}
    </Dialog>
  );
}

MinimumDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
  displayTitle: PropTypes.string.isRequired,
};

function AlertDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList, displayTitle } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  return (
    <Dialog
      fullScreen={false}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle sx={{ m: 0, p: 2 }}>
        {displayTitle}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {displayList}
    </Dialog>
  );
}

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
  displayTitle: PropTypes.string.isRequired,
};

function AlertDialog2(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList, displayTitle } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  return (
    <Dialog
      fullScreen={false}
      onClose={handleClose}
      style={{ zIndex: 199999 }}
      open={open}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle sx={{ m: 0, p: 2 }}>
        {displayTitle}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {displayList}
    </Dialog>
  );
}

AlertDialog2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
  displayTitle: PropTypes.string.isRequired,
};


function MyCardsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['profileMyCard']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

MyCardsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function RegisterDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Registro']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

RegisterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function GuestDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Detalles adicionales']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

GuestDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function OTPDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['OTP']}
        {/* {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null} */}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

OTPDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function OTPDialog2(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Editar teléfono']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

OTPDialog2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <FormControl variant="filled" sx={{ minWidth: 75 }}>
    <InputLabel id="demo-simple-select-filled-label">Code</InputLabel>
    <Select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <MenuItem value="">{labels['ZZ']}</MenuItem>
      {getCountries().map((country) => (
        // <MenuItem key={country} value={"+"+getCountryCallingCode(country)}>
        //   {labels[country]} +{getCountryCallingCode(country)}
        // </MenuItem>
        <MenuItem key={country} value={'+' + getCountryCallingCode(country)}>
          +{getCountryCallingCode(country)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

function PaymentDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      maxWidth={'50vw'}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Selecciona tu método de pago']}
        {onClose ? (
          <IconButton
            style={{ color: 'slategray' }}
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

PaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function DiscountDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      maxWidth={'50vw'}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Promociones']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

DiscountDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function LoginDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} style={{ fontSize: '1.5rem', }}>
        {strings['Ingresar']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function VariablesDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      maxWidth={'100vh'}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#2e2c36',
          width: '93%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

VariablesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.string.isRequired,
};

function CartDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      className="MuiDialogtest-container"
      sx={{ zIndex: 9999, margin: 0, float: 'right !important' }}
      fullScreen={title > 600 ? false : true}
      maxWidth={title > 600 ? '400px' : '100vh'}
      alignContent="flex-end"
      justifyContent="flex-end"
      onClose={handleClose}
      open={open}
      scroll="body"
      PaperProps={{
        square: true,
        sx: {
          maxWidth: title > 600 ? '400px' : 'auto',
          height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          maxHeight: isIOS
            ? 'calc((var(--vh, 1vh) * 100)) !important'
            : '100vh !important',
          margin: 0,
          overflow: 'hidden',
          float: 'right',
        },
      }}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
                      fontSize: this.state.width > 600 ? '24px' : '22px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
      >
                    {'Tu canasta'}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

CartDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.number.isRequired,
};

function AllergyDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      className="MuiDialogtest-container"
      sx={{ zIndex: 9999, margin: 0, float: 'right !important' }}
      fullScreen={title > 600 ? false : true}
      maxWidth={title > 600 ? '400px' : '100vh'}
      alignContent="flex-end"
      justifyContent="flex-end"
      onClose={handleClose}
      open={open}
      scroll="body"
      PaperProps={{
        square: true,
        sx: {
          maxWidth: title > 600 ? '400px' : 'auto',
          height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          maxHeight: isIOS
            ? 'calc((var(--vh, 1vh) * 100)) !important'
            : '100vh !important',
          margin: 0,
          overflow: 'hidden',
          float: 'right',
        },
      }}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
                      fontSize: this.state.width > 600 ? '24px' : '22px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
      >
                    {'Tu canasta'}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

AllergyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.number.isRequired,
};

function TrackDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      className="MuiDialogtest-container"
      sx={{ zIndex: 9999, margin: 0, float: 'right !important' }}
      fullScreen={title > 600 ? false : true}
      maxWidth={title > 600 ? '400px' : '100vh'}
      alignContent="flex-end"
      justifyContent="flex-end"
      onClose={handleClose}
      open={open}
      scroll="body"
      PaperProps={{
        square: true,
        sx: {
          maxWidth: title > 600 ? '400px' : 'auto',
          height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          maxHeight: isIOS
            ? 'calc((var(--vh, 1vh) * 100)) !important'
            : '100vh !important',
          margin: 0,
          overflow: 'hidden',
          float: 'right',
        },
      }}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
                      fontSize: this.state.width > 600 ? '24px' : '22px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
      >
                    {'Tu canasta'}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

TrackDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.number.isRequired,
};

class Detail extends React.Component {
  constructor(props, context) {
    super(props, context);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);
        this.setState({
          orders: [],
        });
        var orders = [];
        const { id } = this.props.match.params;
        const q = query(
          collection(db, 'orders'),
          where('restId', '==', id),
          where('userId', '==', uid),
          where('delivered', '==', false),
          orderBy('createdOn', 'desc')
        );
        await onSnapshot(q, async (data) => {
          this.setState({
            orders: [],
          });
          if (data.docs.length <= 0 && this.state.trackingId) {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }
          data.docs.forEach(async (document) => {
            orders = orders.filter((element) => element.id !== document.id);
            if (
              this.state.trackingId &&
              this.state.trackingId === document.id
            ) {
              await this.setState({ trackingDetails: document.data() });
            }
            orders.push(document);
            await this.setState({ orders: orders });
            // console.log('orders', orders);
          });
        });

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
this.setState({ userCredential: null });
            if (docMenuSnap.data().phoneNumberVerified) {
              if (this.state.openLogin) {
                this.setState({ loading: false, openLogin: false });
                this.setState({
                  openAlert: true,
                  alertType: 'success',
                  alertMsg: strings['Inicio de sesión exitoso'],
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
              // if (docMenuSnap.data()['custId'] == null) {
              //   try {
              //     var response = await axios.post(
              //       'https://senor-order-85fa55d895ba.herokuapp.com/drivers/createcust/',null,
              //       {
              //       params: {
              //         'email': docMenuSnap.data().email,
              //         'name': docMenuSnap.data().fullName,
              //         'phone': docMenuSnap.data().phoneNumber,
              //         'uid': uid,
              //       },
              //     }
              //     );
              //     if (response.status === 200) {
              //       console.log(response.data['id']);
              //       if (response.data['id'] != null) {
              //       } else {
              //         console.log('failed');
              //       }
              //     } else {
              //       console.log('failed');
              //     }
              //   } catch (e) {
              //     console.log('exception $e',e);
              //   }
              // } else {
              //   // custId = (event.data() as Map)['custId'].toString();
              //   // print(custId);
              //   // //custId = 'cus_JwCyg2xRg0XoOT';
              //   // updateCards();
              // }
            } else {
              if(!this.state.openOTP && this.state.isMounted && this._ismounted) {
if (this.state.openLogin) {
                  this.setState({ loading: false, openLogin: false });
                }
              this.setState({
                openOTP: true,
                phoneNumber: docMenuSnap.data().phoneNumber,
                phoneCountry: '+52',
                showPassword: false,
              });
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend,this.state.isMounted, this._ismounted);
              this.setState({ otpSend: dataSend });
              await axios
                .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-new-otp/', {
                  code: dataSend,
                  number: docMenuSnap.data().phoneNumber,
                  uuid: uid
                })
                .then(async (response) => {
                  console.log(response);
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
            }
          } else {
            console.log(user.providerData[0].providerId);
           if(user.providerData[0].providerId.toLowerCase().includes('google')) {
            this.setState({ loading: false, openLogin: false });
            // handleClickOpenAdditionalRegister();
            this.setState({
              openAdditionalRegister: true,
              userCredential: user,
              password: '',
              email: user.providerData[0].email,
              fullName: user.providerData[0].displayName,
              otp: '',
              phoneNumber: '',
              phoneCountry: '+52',
              showPassword: false,
            });
            window.history.pushState(null, '', window.location.href);
      
            window.onpopstate = () => {
              window.onpopstate = () => {};
              window.history.back();
              auth.signOut();
              this.setState({
                openAdditionalRegister: false,
                password: '',
                email: '',
                fullName: '',
                otp: '',
                phoneNumber: '',
                phoneCountry: '+52',
                showPassword: false,
              });
            };
          } else {
            // console.log('openlogin',this.setState.openLogin);
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: 'Error de inicio de sesión',
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });
              auth.signOut();
}
            }
          }
        });
        // ...
      } else {
        this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });

        //       signInWithEmailAndPassword(auth, 'demo@demo.com', 'demo1234')
        // .then((userCredential) => {
        //   // Signed in
        //   console.log('signing',userCredential);
        //   const user = userCredential.user;
        //   const uid = user.uid;
        //   this.setState({ userId: uid });
        //   // ...
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   console.log(errorMessage,errorCode);
        // });
        // User is signed out
        // ...
      }
    });
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    this._ismounted = true;
    this.state = {
      orders: [],
      addedNotes: false,
      orderLoading: false,
      codeData: '',
      codeError: false,
      canPickup: false,
            toggledOff:false,
            cashOnly:false,
      newAllergy: '',
      busId: '',
      alertMsg: '',
      openAddCard: false,
      allergyActivate: true,
      newCard: {
        cardNo: '',
        cvv:'',
        month: '',
        year: '',
        expiry: '',
        name: '',
        'save': true,
      },
      selectLocationName: null,
      showAllergyDisclaimer: true,
      canDeliverHere: false,
      openAlert: false,
      showCart: false,
      showAllergy: false,
      showTracking: false,
      trackingDetails: null,
      toggleDetails: false,
      trackingId: null,
      showDiscount: false,
      showPayment: false,
      alertType: 'error',
      isMounted: true,
      anchorEl: null,
      open: false,
      openLocation: false,
      openCards: false,
      enteredAmount: 0,
      openWallet: false,
      showOtherWallet: false,
      openPreOrder: false,
      showTomorrow: false,
      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: null,
      currenciesList2Selected: null,
      currenciesListSelected: null,
      currenciesList3Selected: null,
      currenciesList4Selected: null,
      currenciesList5Selected: null,
      currenciesList6Selected: null,
      currenciesList7Selected: null,
      currenciesList8Selected: null,
      openLogin: false,
      showGuest: false,
      isGuest: false,
      guestOrder: null,
      showAdditionalDetails: false,
      guestDetails: {
        'name': '',
        'email': '',
        'phone': ''
      },
      openBusinessInfo: false,
      password: '',
      email: '',
      fullName: '',
      offersDetails: {},
      offersDetails2: {},
      tempOrder: {},
      hasAllData: true,
      productIds: [],
      otp: '',
      otpSend: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
      openRegister: false,
openAdditionalRegister: false,
      userCredential: null,
      hasNoData: false,
      openOTP: false,
      showMinimum: false,
      openDialogAlert: false,
      openDialogCancel: false,
      openVariable: false,
      selectedProduct: null,
      userId: null,
      showAllergyData: true,
      allergy: [],
      selectedValue: emails[1],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      businessData: null,
      locOptions: [],
      offers: [],
      offers2: [],
      offers3: [],
      addresses: [],
      defaultCountry: 'MX',
      selectedLocationDetails: {},
      cards: [],
      loading: false,
      offersUsed: [],
      cart: {
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        totalCost: 0,
        totalItems: 0,
        item: [],
        totalOxxoCost: 0,
        totalOxxoItems: 0,
        oxxoNameData: '',
        oxxoLogoImage: '',
        oxxoItem: [],
      },
      delivery: 0,
      selectedMenuIndex: 0,
      menuProductItems: [],
      selectedType: 0,
      hasClickedLoc: false,
      autoLoc: '',
      offerDetails: {},
      onlyPickup: false,
      storeIsOpen: false,
      deliveryFee: 0,
      dynamicFeeType: 0,
      selectLocationData: {
        id: '',
        name: '',
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: 10.99835602,
        longitude: 77.01502627,
      },
      cookingInstructions: '',
      spoon: false,
      selectedAddress: {
        latitude: 0,
        longitude: 0,
      },
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      circleData: null,
      defaultPropsCircle: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      mobileMoreAnchorEl: null,
      menuData: [],
      menu: [],
      upsellingProd: [],
      upsellingProd2: [],
      userDetails: null,
      width: 0,
      height: 0,
      showAddressModal: false,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      isTomorrow: false,
      isToday: false,
      isPreOrder: false,
      isNewUser: true,
      isStore: false,
      order: {
        offerCode: '',
        selectedOffer: {},
        selectedOffer2: {},
        offerId: '',
        offerValue: 0,
        cashbackValue: 0,
        cashbackValue2: 0,
        offerCode2: '',
        offerId2: '',
        offerValue2: 0,
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        itemCost: 0,
        deliveryCharge: 0,
        usageFee: 0,
        taxes: 0,
        serviceFee: 0,
        grandTotal: 0,
        items: [],
      },
      offerCode: '',
      usingWallet: false,
      selectedOffer: null,
      offerId: '',
      offerSuccess: '',
      offerErrorText: '',
      offerValue: 0,
      cashbackValue: 0,
      offerInd: null,
      isOfferApplied: false,
      isOfferError: false,
      isFreeDelivery: false,
      offerCode2: '',
      selectedOffer2: null,
      offerId2: '',
      offerSuccess2: '',
      offerErrorText2: '',
      offerValue2: 0,
      balance: 0,
      cashbackValue2: 0,
      offerInd2: null,
      isOfferApplied2: false,
      isOfferError2: false,
      isFreeDelivery2: false,
      deliveryInstructions: '',
      defaultCard: null,
      toggleDelivery: true,
      tipPercent: 0,
      dynamicDelivery: false,
      additionalDelivery: 0,
      normalDelivery: 0,
      additionalDeliveryPercentage2: 0,
      reasonTitle: '',
      reasonDescription: '',
      isStore2: false,
      showMap: false,
      currenciesList: [],
      currenciesList3: [],
      currenciesList4: [],
      currenciesList5: [],
      currenciesList6: [],
      currenciesList7: [],
      currenciesList8: [],
      currenciesList2: [],
      vh: window.innerHeight * 0.01,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setState({ vh: vh });
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      this.setState({ vh: vh });
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  async loadGuest() {
    if(this.state.guestOrder) {
      const orderDoc = doc(db, 'orders', this.state.guestOrder);
      await onSnapshot(orderDoc, async (document) => {
        // this.setState({
        //   orders: [],
        // });
        // if (data.docs.length <= 0 && this.state.trackingId) {
        //   this.setState({
        //     showTracking: false,
        //     trackingDetails: null,
        //     trackingId: null,
        //   });
        // }
        // data.docs.forEach(async (document) => {
          // orders = orders.filter((element) => element.id !== document.id);
          if (
            this.state.trackingId &&
            this.state.trackingId === document.id
          ) {
            await this.setState({ trackingDetails: document.data() });
          }
          // orders.push(document);
          // await this.setState({ orders: orders });
          // console.log('orders', orders);
        // });
      });
    }
  }

   extraCharges() {
    var sendData = 0.0;
    var totalProducts = 0;
    var cart = {
      restId: '',
      restName: '',
      restLogo: '',
      maxTime: 0,
      totalCost: 0,
      totalItems: 0,
      item: [],
      totalOxxoCost: 0,
      totalOxxoItems: 0,
      oxxoNameData: '',
      oxxoLogoImage: '',
      oxxoItem: [],
    };
    var selectedType = 0;
    var businessData;
    if(this.state.cart) {
      cart = this.state.cart;
    }
    if(this.state.selectedType) {
      selectedType = this.state.selectedType;
    }
    if(this.state.businessData) {
      businessData = this.state.businessData;
    }
    if(cart['item']!=null) {
      for(var i=0;i<cart['item'].length;i++) {
        if(cart['item'][i]['extraDelivery']!=null && cart['item'][i]['extraDelivery']) {
          totalProducts = totalProducts + (cart['item'][i]['quantity'] ?? 0);
        }
      }
    }
    if(totalProducts>=(businessData.minimumForExtraDelivery ?? 0) && selectedType===0) {
      sendData = (businessData.extraDelivery ?? 0) + 0.0;
    } else {
      sendData = 0.0;
    }
    return sendData;
  }

  async getCities(id) {
    // let { id } = this.props.params;
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const busDoc = doc(db, 'businesses', id);
    const docSnap = await getDoc(busDoc);
    if (docSnap.exists()) {
      // console.log('Document data:', docSnap.data());
      document.getElementById('favicon').href = docSnap.data().logo;
      document.getElementById('mainName2').innerHTML = docSnap.data().name;
      var value = docSnap.data();
      if (docSnap.data().enabled) {
        var polygon = [];
        var disablePolygon = [];
        var canDeliver = false;
        var dynamicFeeType = -1;
        var deliveryFee = 0;
        if (value['deliveryZones'] != null) {
          for (var j = 0; j < value['deliveryZones'].length; j++) {
            polygon = [];
            var canDeliverTemp = false;
            if(value['deliveryZones'][j]['enabled']==null || value['deliveryZones'][j]['enabled']) {
            if(value['deliveryZones'][j]['isRadius']!=null && value['deliveryZones'][j]['isRadius']) {
               canDeliverTemp = geolib.isPointWithinRadius(
                this.state.selectedAddress,
                { latitude: value['deliveryZones'][j]['center']['latitude'], longitude: value['deliveryZones'][j]['center']['longitude'] },
                value['deliveryZones'][j]['radius']
            )
            } else {
            for (var i = 0; i < value['deliveryZones'][j]['area'].length; i++) {
              if (
                value['deliveryZones'][j]['area'][i]['latitude'] != null &&
                value['deliveryZones'][j]['area'][i]['longitude'] != null
              ) {
                polygon.push({
                  latitude: value['deliveryZones'][j]['area'][i]['latitude'],
                  longitude: value['deliveryZones'][j]['area'][i]['longitude'],
                });

                //print('added data');
              }
            }
             canDeliverTemp = geolib.isPointInPolygon(
              this.state.selectedAddress,
              polygon
            );
            }
          }
            // console.log('delivery fee233 ' + canDeliverTemp.toString());
            if (canDeliverTemp && dynamicFeeType < 0 && deliveryFee <= 0) {
              canDeliver = true;
              this.setState({ canDeliverHere: true });
              // canDeliver = mp.PolygonUtil.containsLocation(
              //     selectedAddress, polygon, true);
              dynamicFeeType =
                value['deliveryZones'][j]['dynamicFeeType'] != null
                  ? value['deliveryZones'][j]['dynamicFeeType']
                  : 1;
              deliveryFee = value['deliveryZones'][j]['deliveryFee'] + 0.0;
              // console.log('delivery fee23 ' + deliveryFee.toString());
            } else if (canDeliverTemp) {
              canDeliver = true;
              this.setState({ canDeliverHere: true });
              if (deliveryFee >= value['deliveryZones'][j]['deliveryFee']) {
                dynamicFeeType =
                  value['deliveryZones'][j]['dynamicFeeType'] != null
                    ? value['deliveryZones'][j]['dynamicFeeType']
                    : 1;
                deliveryFee = value['deliveryZones'][j]['deliveryFee'] + 0.0;
                // console.log('delivery fee23 ' + deliveryFee.toString());
              }
            }

            //print('can deliver ID: ' + value['id']);

            // if (disablePolygon.length > 0 && !disableDelivery) {
            //   disableDelivery = mp.PolygonUtil.containsLocation(
            //       selectedAddress, disablePolygon, true);
            //   if (disableDelivery) {
            //     disableTime = value['disableArea2'][j]['disableTime'];
            //   }
            // }
          }
          // console.log('candeliver', canDeliver);
          var canDeliverHere2 = true;
          if (!canDeliver && value['canPickup'] != null && value['canPickup']) {
            this.setState({ selectedType: 1 });
          } else if (!canDeliver) {
            this.setState({ canDeliverHere: false });
            canDeliverHere2 = false;
          }
          // if (canDeliver) {
          var disableTime = {
            start: {
              hour: 0,
              minute: 0,
            },
            end: {
              hour: 0,
              minute: 0,
            },
          };
          var disableDelivery = false;
          if (value['disableArea2'] != null) {
            for (var j = 0; j < value['disableArea2'].length; j++) {
              disablePolygon = [];
              for (
                var i = 0;
                i < value['disableArea2'][j]['area'].length;
                i++
              ) {
                if (
                  value['disableArea2'][j]['area'][i]['latitude'] != null &&
                  value['disableArea2'][j]['area'][i]['longitude'] != null
                ) {
                  disablePolygon.push({
                    latitude: value['disableArea2'][j]['area'][i]['latitude'],
                    longitude: value['disableArea2'][j]['area'][i]['longitude'],
                  });
                  //print('added data');
                }
              }

              //print('can deliver ID: ' + value['id']);

              if (disablePolygon.length > 0 && !disableDelivery) {
                disableDelivery = geolib.isPointInPolygon(
                  this.state.selectedAddress,
                  disablePolygon,
                  true
                );
                if (disableDelivery) {
                  disableTime = value['disableArea2'][j]['disableTime'];
                }
              }
            }
          }
          var isOpen;
          var toggledOff = false;
          if (value['available']) {
             console.log("selectedWeekDay",this.state.selectedWeekDay,value['schedule'],value['schedule'][this.state.selectedWeekDay]['enabled']);
            if (value['schedule'][this.state.selectedWeekDay]['enabled']) {
              for (
                var i = 0;
                i <
                value['schedule'][this.state.selectedWeekDay]['lapses'].length;
                i++
              ) {
                //date = tz.TZDateTime.now(timezone);
                //print(date.hour);
                if (
                  value['schedule'][this.state.selectedWeekDay]['lapses'][i][
                    'open'
                  ]['hour'] < this.state.selectedDate.getHours() &&
                  value['schedule'][this.state.selectedWeekDay]['lapses'][i][
                    'close'
                  ]['hour'] > this.state.selectedDate.getHours()
                ) {
                  isOpen = true;
                } else if (
                  value['schedule'][this.state.selectedWeekDay]['lapses'][i][
                    'open'
                  ]['hour'] === this.state.selectedDate.getHours()
                ) {
                  if (
                    value['schedule'][this.state.selectedWeekDay]['lapses'][i][
                      'open'
                    ]['minute'] <= this.state.selectedDate.getMinutes()
                  ) {
                    isOpen = true;
                  }
                } else if (
                  value['schedule'][this.state.selectedWeekDay]['lapses'][i][
                    'close'
                  ]['hour'] === this.state.selectedDate.getHours()
                ) {
                  if (
                    value['schedule'][this.state.selectedWeekDay]['lapses'][i][
                      'close'
                    ]['minute'] > this.state.selectedDate.getMinutes()
                  ) {
                    isOpen = true;
                  }
                }
              }
            }
            if (isOpen == null) isOpen = false;
          } else {
            isOpen = false;
            toggledOff = true;
          }
          if (isOpen && disableDelivery) {
            var date12 = this.state.selectedDate.getHours();
            var disableendhour = disableTime['end']['hour'];
            if (disableTime['start']['hour'] > disableendhour) {
              if (disableTime['start']['hour'] > date12) date12 = date12 + 24;
              disableendhour = disableendhour + 24;
            }
            if (
              disableTime['start']['hour'] < date12 &&
              disableendhour > date12
            ) {
              isOpen = false;
            } else if (disableTime['start']['hour'] === date12) {
              if (
                disableTime['start']['minute'] <=
                this.state.selectedDate.getMinutes()
              ) {
                isOpen = false;
              }
            } else if (disableendhour === date12) {
              if (
                disableTime['end']['minute'] >
                this.state.selectedDate.getMinutes()
              ) {
                isOpen = false;
              }
            }
          }
          var cashOnly = false;
          if (value['cashOnly'] != null) {
            cashOnly = value['cashOnly'];
          }
          var cardOnly = false;
          if (value['cardOnly'] != null) {
            cardOnly = value['cardOnly'];
          }
          var spoon = false;
          var verified = false;
          var createdAt = 0;
          var offerEndTime = 0;
          var showOffer = false;
          var offerDesc = '';
          var partner = true;
          var cardOnDelivery = false;
          if (value['cardOnDelivery'] != null) {
            cardOnDelivery = value['cardOnDelivery'];
          }
          var minimumPurchase = 0;
          if (value['minimumPurchase'] != null) {
            minimumPurchase = value['minimumPurchase'] + 0.0;
          }
          var onlyInHouseDriver = false;
          if (value['onlyInHouseDriver'] != null) {
            onlyInHouseDriver = value['onlyInHouseDriver'];
          }
          var driverServiceFee = 0;
          if (value['driverServiceFee'] != null) {
            driverServiceFee = value['driverServiceFee'] + 0.0;
          }
          var driverServicePercentage = 0;
          if (value['driverServicePercentage'] != null) {
            driverServicePercentage = value['driverServicePercentage'] + 0.0;
          }
          var isCityDisable = false;
          if (value['isCityDisable'] != null) {
            isCityDisable = value['isCityDisable'];
          }
          var canPickup = false;
          if (value['canPickup'] != null) {
            canPickup = value['canPickup'];
          }
          var showAllergyData = true;
          if (value['showAllergyData'] != null) {
            showAllergyData = value['showAllergyData'];
          }
          if (value['partner'] != null) {
            partner = value['partner'];
          }
          if (value['verified'] != null) {
            verified = value['verified'];
          }
          if (value['spoon'] != null) {
            spoon = value['spoon'];
          }
          if (value['createdAt'] != null) {
            createdAt = value['createdAt'];
          }
          if (value['offerEndTime'] != null) {
            offerEndTime = value['offerEndTime'];
          }
          if (value['showOffer'] != null) {
            showOffer = value['showOffer'];
          }
          if (value['offerDesc'] != null) {
            offerDesc = value['offerDesc'];
          }
          this.setState({
            canPickup: canPickup,
            toggledOff:toggledOff,
            cashOnly:cashOnly
          });
          if (
            (isOpen || (canPickup && toggledOff && !cashOnly)) &&
            canDeliverHere2
          ) {
            if (!isOpen) {
              // console.log('candeliver2 $canDeliver');
              this.setState({
                onlyPickup: true,
                selectedType: 1
              });
              if(this.state.selectLocationName) {
                this.setState({
                  openDialogAlert: true,
                });
                window.history.pushState(null, '', window.location.href);

                window.onpopstate = () => {
                  window.onpopstate = () => {};
                  window.history.back();
                  this.setState({ openDialogAlert: false });
                };
              }
            }
            this.setState({
              storeIsOpen: true,
              deliveryFee: deliveryFee,
              dynamicFeeType: dynamicFeeType,
            });
          } else {
            this.setState({
              storeIsOpen: false,
            });
          }
          this.setState({ businessData: docSnap.data(), loading: true, showAllergyData: showAllergyData }, (newState) => {
            // console.log(
            //   'locationData',
            //   this.state.addresses,
            //   typeof this.state.addresses
            // );
            this.listMaker();
            if(this.state.selectedType === 1) {
              this.setState({ storeIsOpen: false,selectedType: 0 });
              if(this.state.selectLocationName) {
                this.setState({
                  openDialogAlert: true,
                });
                window.history.pushState(null, '', window.location.href);

                window.onpopstate = () => {
                  window.onpopstate = () => {};
                  window.history.back();
                  this.setState({ openDialogAlert: false });
                };
              }
            }
          });
          this.setState((prevState) => ({
            cart: {
              ...prevState.cart, // copy all other key-value pairs of food object
              restId: id,
              restName: value['name'],
              restLogo: value['logo'],
            },
          }));

          var cityId =
            value['location'] != null && value['location']['cityId'] != null
              ? value['location']['cityId'].toString()
              : '0';
              var cityName =
              value['location'] != null && value['location']['city'] != null
                ? value['location']['city'].toString()
                : '';
          // console.log('cityId', cityId);
          const cityDoc = doc(db, 'cities', cityId);
          const docCitySn = onSnapshot(cityDoc, async (docCitySnap) => {
            var dynamicDelivery = false;
            var additionalDelivery = 0;
            var normalDelivery = 0;
            var additionalDeliveryPercentage2 = 0;
            var reasonTitle = '';
            var reasonDescription = '';
            if (docCitySnap.data()) {
              dynamicDelivery = docCitySnap.data()['dynamicDelivery'];
              if (dynamicFeeType === 1)
                additionalDeliveryPercentage2 =
                  docCitySnap.data()['deliveryPercentage'] + 0.0;
              else if (dynamicFeeType === 2)
                additionalDeliveryPercentage2 =
                  docCitySnap.data()['deliveryPercentage5'] + 0.0;
              else if (dynamicFeeType === 3)
                additionalDeliveryPercentage2 =
                  docCitySnap.data()['deliveryPercentage5plus'] + 0.0;
              else if (dynamicFeeType === 0)
                additionalDeliveryPercentage2 =
                  docCitySnap.data()['deliveryPercentageInit'] + 0.0;
              reasonTitle = docCitySnap.data()['reasonTitle'];
              reasonDescription = docCitySnap.data()['reasonDescription'];
              // console.log(
              //   'additionalcharge: ' + additionalDeliveryPercentage2.toString()
              // );
            }

            normalDelivery = deliveryFee;
            var delivery = 0;
            if (dynamicDelivery) {
              additionalDelivery =
                normalDelivery * (additionalDeliveryPercentage2 / 100) + 0.0;
              delivery = normalDelivery + additionalDelivery;
            } else {
              delivery = normalDelivery;
            }

            // if (this.state.selectedType === 1) {
            //   delivery = 0;
            //   normalDelivery = 0;
            //   additionalDelivery = 0;
            // }
            // console.log(
            //   'delivery',
            //   delivery,
            //   normalDelivery,
            //   dynamicDelivery,
            //   additionalDeliveryPercentage2,
            //   additionalDelivery
            // );
            this.setState({
              delivery: delivery,
              dynamicDelivery: dynamicDelivery,
              additionalDelivery: additionalDelivery,
              normalDelivery: normalDelivery,
              additionalDeliveryPercentage2: additionalDeliveryPercentage2,
              reasonTitle: reasonTitle,
              reasonDescription: reasonDescription,
            });
          });
          if (value.cashOnly) {
            this.setState({
              defaultCard: {
                cardNumber: 'COD',
                recent: true,
              },
            });
          }
          try {
            if(docSnap.data().googleAID && docSnap.data().googleAID!='') {
            ReactGA.initialize(docSnap.data().googleAID);
            ReactGA.send({ hitType: "pageview", page: "/menu" });
            }
            if(docSnap.data().facebookPID && docSnap.data().facebookPID!='') {
            var advancedMatching = { };
  var options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(docSnap.data().facebookPID, advancedMatching, options);

  ReactPixel.pageView();
            }
          } catch(e) {}
          const menuDoc = doc(db, 'business_menu', id);
          const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
            if (docMenuSnap.data()) {
                        // console.log('Document data:', docMenuSnap.data().menu);

              var menus2 = docMenuSnap.data().menu;
                        var offersDetails = {};
                        var menus3 = [];
                        var offers = this.state.offers;
                        var offers2 = this.state.offers2;
                        var upsellingProd = [];
                        var upsellingProd2 = [];
                        const offerDoc  = query(
                            collection(db, 'offers')
                          );
    const offerSnap = await getDocs(offerDoc);
    if (offerSnap.size>0) {
      for (var i = 0; i < offerSnap.docs.length; i++) {
        const posF = offers2.map(e => e.id).indexOf(offerSnap.docs[i].data().id);
                            offers2.splice(posF,1);
                            if (offerSnap.docs[i].data()['enabled'] && (offerSnap.docs[i].data()['city'] == null ||
                            offerSnap.docs[i].data()['city']
                                    .toString()
                                    .toLowerCase() ===
                                    cityName
                                    .toString()
                                    .toLowerCase())) {
                              offers2.push(offerSnap.docs[i].data());
                                      }
      }
    }
    const offerDoc2  = query(
      collection(db, 'new_offers')
    );
const offerSnap2 = await getDocs(offerDoc2);
if (offerSnap2.size>0) {
for (var i = 0; i < offerSnap2.docs.length; i++) {
const posF = offers2.map(e => e.id).indexOf(offerSnap2.docs[i].data().id);
      offers2.splice(posF,1);
      if (offerSnap2.docs[i].data()['enabled'] && (offerSnap2.docs[i].data()['city'] == null ||
      offerSnap2.docs[i].data()['city']
              .toString()
              .toLowerCase() ===
              cityName
              .toString()
              .toLowerCase())) {
        offers2.push(offerSnap2.docs[i].data());
                }
}
}
                        if (docMenuSnap.data().offers2) {
                          for (var i = 0; i < docMenuSnap.data().offers2.length; i++) {
                            const posF = offers2.map(e => e.id).indexOf(docMenuSnap.data()['offers2'][i]['id']);
                            offers2.splice(posF,1);
                            if (docMenuSnap.data()['offers2'][i]['enabled']) {
                              offers2.push(docMenuSnap.data()['offers2'][i]);
                                      }
                                    }
                                  }
              if (docMenuSnap.data().offers) {
                for (var i = 0; i < docMenuSnap.data().offers.length; i++) {
                  const posF = offers.map(e => e.id).indexOf(docMenuSnap.data()['offers'][i]['id']);
                  offers.splice(posF,1);
                  if (docMenuSnap.data()['offers'][i]['enabled']) {
                    offers.push(docMenuSnap.data()['offers'][i]);
                            }
                          }
                for (var i = docMenuSnap.data().offers.length - 1;
                                  i >= 0;
                                  i--) {
                      if (docMenuSnap.data().offers[i]['enabled']) {
                        if (((docMenuSnap.data().offers[i]['validFrom'] == null || moment().isAfter(
                          moment(docMenuSnap.data().offers[i]
                                    ['validFrom']
                                .toDate())
                                  )) &&
                                (docMenuSnap.data().offers[i]
                                                      ['validTill'] ==
                                                  null || moment().isBefore(
                                          moment(docMenuSnap.data().offers[i]['validTill'].toDate())
                                                  ))) &&
                                      offersDetails != {} &&
                            docMenuSnap.data().offers[i]['isItemOffer'] != null &&
                            docMenuSnap.data().offers[i]['isItemOffer']) {
                                    for (var t = 0;
                                        t <
                              docMenuSnap.data().offers[i]['items']
                                                .length;
                                        t++) {
                            offersDetails[docMenuSnap.data().offers[i]
                                ['items'][t]['id']] = (docMenuSnap.data().offers[i]['freeItem'] +
                                            docMenuSnap.data().offers[i]
                                                      ['buyItem'])
                                              .toString() +
                                          'x' +
                                (docMenuSnap.data().offers[i]
                                                  ['buyItem'])
                                              .toString();
                                    }
                                  }
                                }
                              }

                              var offersAdd = [];

                              var allOffers = docMenuSnap.data().offers ?? [];
                              var weekDay = [6,0,1,2,3,4,5];
                              var currentDay = weekDay[new Date().getDay()];
                              for(var i =0;i<allOffers.length;i++) {
                                console.log(i,allOffers[i]['enabled'],allOffers[i]['isNewUser'],this.state.isNewUser);
                                if(allOffers[i]['enabled'] && ((allOffers[i]['isNewUser'] && this.state.userDetails && (this.state.isNewUser || (
                                  this.state.offersUsed && this.state.offersUsed.find(
                                    (element) =>
                                      element['id'] ===
                                      allOffers[i]['id']
                                  ) != null
                                ))) || (allOffers[i]['isNewUser']==null || !allOffers[i]['isNewUser']))) {
                                  var datedate = new Date();
                                  var isOpen;
                              if (allOffers[i].schedule != null) {
                                // var date2 = new Date();
                                // var weekDay = [6,0,1,2,3,4,5];
                                // var currentDay = weekDay[date2.getDay()];
                                // this.setState({ selectedDate: date2, selectedWeekDay: currentDay });
                                if (allOffers[i].schedule[currentDay]['enabled']) {
                                    //selectedDate = tz.TZDateTime.now(timezone);
                                    if (
                                      allOffers[i].schedule[currentDay]['open'][
                                        'hour'
                                      ] < datedate.getHours() &&
                                      allOffers[i].schedule[currentDay]['close'][
                                        'hour'
                                      ] > datedate.getHours()
                                    ) {
                                      isOpen = true;
                                    } else if (
                                      allOffers[i].schedule[currentDay]['open'][
                                        'hour'
                                      ] === datedate.getHours()
                                    ) {
                                      if (
                                        allOffers[i].schedule[currentDay]['open'][
                                          'minute'
                                        ] <= datedate.getMinutes()
                                      ) {
                                        isOpen = true;
                                      }
                                    } else if (
                                      allOffers[i].schedule[currentDay]['close'][
                                        'hour'
                                      ] === datedate.getHours()
                                    ) {
                                      if (
                                        allOffers[i].schedule[currentDay]['close'][
                                          'minute'
                                        ] > datedate.getMinutes()
                                      ) {
                                        isOpen = true;
                                      }
                                    }
                                }
                                if (isOpen == null) isOpen = false;
                              } else {
                                isOpen = true;
                              }

                              console.log(isOpen,'offersAdd',)
                              if(isOpen)
                                  offersAdd.push(allOffers[i]);
                                }
                              }

                              this.setState({
                                offers: offersAdd,
                                offers2: offers2
                              });
                        }
                        for (var im = 0; im < menus2.length; im++) {
                          for (var jm = 0; jm < menus2[im]['products'].length; jm++) {
                            if(menus2[im]['products'][jm]['isUpselling'] !=
                                null &&
                                menus2[im]['products'][jm]['isUpselling'] && (menus2[im]['products'] != null &&
                                this.getTimings(menus2[im]['schedule']) &&
                                (menus2[im]['enabled'] ||
                                    (!menus2[im]['enabled'] &&
                                        menus2[im]
                                        ['willBeAvailableAt'] !=
                                            null &&
                                            moment(this.state.selectedDate).isAfter(
                                              moment(menus2[im]['willBeAvailableAt'].toDate())
                                            ))) && (menus2[im]['outOfStock']==null || !menus2[im]['outOfStock'])) && (menus2[im]['products']
                            [jm]
                            ['enabled'] ||
                                (!menus2[im]['products']
                                [jm]
                                ['enabled'] &&
                                    menus2[im]['products']
                                    [jm][
                                    'willBeAvailableAt'] !=
                                        null &&
                                        moment(this.state.selectedDate).isAfter(
                                          moment(menus2[im]['products']
                                          [jm][
                                          'willBeAvailableAt'].toDate())
                                        ))) && (menus2[im]['products']
                                        [jm]['outOfStock']==null || !menus2[im]['products']
                                        [jm]['outOfStock'])) {
                                          menus2[im]['products'][jm].catId = menus2[im].id;
                                          menus2[im]['products'][jm].catName = menus2[im].name;
                                          menus2[im]['products'][jm].hasCatInventory = menus2[im].hasInventory ?? false;
                              upsellingProd.push(menus2[im]['products'][jm]);
                              upsellingProd2.push(menus2[im]['products'][jm]);
                            }
                          }
                        }
                        // menus2.splice(0, 0,{
                        //   'enabled': true,
                        //   'id': uuidv4(),
                        //   'name': 'Discounts',
                        //   'products': [],
                        // });

                        // for (var im = 0; im < menus2.length; im++) {
                        //   for (var jm = 0; jm < menus2[im]['products'].length; jm++) {
                        //     if(menus2[im]['products'][jm]['discountPercentage']!=null && menus2[im]['products'][jm]['discountPercentage']>0 && moment().isBefore(moment(menus2[im]['products'][jm]['discountedTill'].toDate())))
                        //       menus2[0]['products'].add(menus2[im]['products'][jm]);
                        //   }
                        // }
                        // if(menus2[0]['products'].length<=0) {
                        //   menus2.splice(0, 1);
                        // }
                        for (var i = 0; i < menus2.length; i++) {
                          // print(menus2[i]['willBeAvailableAt']!=null);
                          // if(menus2[i]['willBeAvailableAt']!=null)
                          // print(DateTime.now().compareTo(menus2[i]['willBeAvailableAt'].toDate()));
                          if (
                            menus2[i]['products'] != null &&
                            this.getTimings(menus2[i]['schedule']) &&
                            (menus2[i]['enabled'] ||
                              (!menus2[i]['enabled'] &&
                                menus2[i]['willBeAvailableAt'] != null &&
                                moment(this.state.selectedDate).isAfter(
                                  moment(menus2[i]['willBeAvailableAt'].toDate())
                                ))) && (menus2[i]['outOfStock']==null || !menus2[i]['outOfStock'])
                          ) {
                            // console.log(menus2[i]['name']);
                            menus3.push(menus2[i]);
                            // menuitems.add(
                            //   MenuItem(
                            //     title: ((menus2[i]['nameEng']!=null && context.locale == Locale('en'))?menus2[i]['nameEng']:menus2[i]['name']).toString(),
                            //     titleColor: textColor,
                            //     backgroundColor: textFieldColor,
                            //     //child: Icon(Icons.accessibility, color: Colors.black),
                            //     subtitle: "",
                            //     subTitleColor: textColor,
                            //     onTap: () =>
                            //         _scrollController.scrollTo(
                            //           index: i + 3,
                            //           alignment: MediaQuery
                            //               .of(context)
                            //               .size
                            //               .height * 0.0125 * 0.025,
                            //           duration: Duration(milliseconds: 200),
                            //         ),
                            //   ),
                            // );
                            // print(menuitems.length);
                          } else {
                          }
                        }
                        this.setState({
                          menuData: menus3.map(item => ({...item})),
                          menu: menus3,
                          offersDetails: offersDetails,
                          upsellingProd: upsellingProd,
                          upsellingProd2: upsellingProd2,
                          // offers: [],
                          offerData: offers,
                          offers2: offers2,
                          loading:false,
                        });
                    } else {
                      this.setState({
                        loading:false,
                      });
                      // doc.data() will be undefined in this case
                      console.log('No such document!');
                    }
                    });
          // }
        }
      }
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
    //return cityList;
  }

  isOpenOn(date) {
    var isOpen;
    var value = this.state.businessData;
    var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[date.getDay()];

    var disableTime = {
      start: {
        hour: 0,
        minute: 0,
      },
      end: {
        hour: 0,
        minute: 0,
      },
    };

    var disableDelivery = false;
    var disablePolygon = [];
          if (value['disableArea2'] != null) {
            for (var j = 0; j < value['disableArea2'].length; j++) {
              disablePolygon = [];
              for (
                var i = 0;
                i < value['disableArea2'][j]['area'].length;
                i++
              ) {
                if (
                  value['disableArea2'][j]['area'][i]['latitude'] != null &&
                  value['disableArea2'][j]['area'][i]['longitude'] != null
                ) {
                  disablePolygon.push({
                    latitude: value['disableArea2'][j]['area'][i]['latitude'],
                    longitude: value['disableArea2'][j]['area'][i]['longitude'],
                  });
                  //print('added data');
                }
              }

              //print('can deliver ID: ' + value['id']);

              if (disablePolygon.length > 0 && !disableDelivery) {
                disableDelivery = geolib.isPointInPolygon(
                  this.state.selectedAddress,
                  disablePolygon,
                  true
                );
                if (disableDelivery) {
                  disableTime = value['disableArea2'][j]['disableTime'];
                }
              }
            }
          }

    // if (value['available']) {
      // console.log(currentDay);
      if (value['schedule'][currentDay]['enabled']) {
        for (
          var i = 0;
          i <
          value['schedule'][currentDay]['lapses'].length;
          i++
        ) {
          //date = tz.TZDateTime.now(timezone);
          //print(date.hour);
          if (
            value['schedule'][currentDay]['lapses'][i][
              'open'
            ]['hour'] < date.getHours() &&
            value['schedule'][currentDay]['lapses'][i][
              'close'
            ]['hour'] > date.getHours()
          ) {
            isOpen = true;
          } else if (
            value['schedule'][currentDay]['lapses'][i][
              'open'
            ]['hour'] === date.getHours()
          ) {
            if (
              value['schedule'][currentDay]['lapses'][i][
                'open'
              ]['minute'] <= date.getMinutes()
            ) {
              isOpen = true;
            }
          } else if (
            value['schedule'][currentDay]['lapses'][i][
              'close'
            ]['hour'] === date.getHours()
          ) {
            if (
              value['schedule'][currentDay]['lapses'][i][
                'close'
              ]['minute'] > date.getMinutes()
            ) {
              isOpen = true;
            }
          }
        }
      }
      if (isOpen == null) isOpen = false;
    // }
    if (isOpen && disableDelivery) {
      var date12 = date.getHours();
      var disableendhour = disableTime['end']['hour'];
      if (disableTime['start']['hour'] > disableendhour) {
        if (disableTime['start']['hour'] > date12) date12 = date12 + 24;
        disableendhour = disableendhour + 24;
      }
      if (
        disableTime['start']['hour'] < date12 &&
        disableendhour > date12
      ) {
        isOpen = false;
      } else if (disableTime['start']['hour'] === date12) {
        if (
          disableTime['start']['minute'] <=
          date.getMinutes()
        ) {
          isOpen = false;
        }
      } else if (disableendhour === date12) {
        if (
          disableTime['end']['minute'] >
          date.getMinutes()
        ) {
          isOpen = false;
        }
      }
    }
    //console.log(date, isOpen);
    return isOpen;
  }

  async componentWillMount() {
var dataToAdd;
  try {
    dataToAdd = JSON.parse(
    localStorage.getItem('preOrder')
  );
} catch(e) {
console.log(e);
}
console.log(localStorage.getItem('preOrder'));
if(dataToAdd) {
  dataToAdd['selectedDate'] = new Date(dataToAdd['selectedDate']);
} else {
  var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[new Date().getDay()];
  dataToAdd = {selectedDate: new Date(),
    selectedWeekDay: currentDay,
    isTomorrow: false,
    isToday: false,
    isPreOrder: false,};
}

this.setState(dataToAdd, (bee)=>{
  console.log(dataToAdd);
});
    var locationData;
    try {
      locationData = JSON.parse(
      localStorage.getItem('location')
    );
} catch(e) {
console.log(e);
}
var locationData = JSON.parse(localStorage.getItem('location'));
if(locationData  == null) {
  locationData = [];
}
    var showAllergyDisclaimer;
    try {
      showAllergyDisclaimer = JSON.parse(localStorage.getItem('showAllergyDisclaimer'))
} catch(e) {
console.log(e);
}
    if (showAllergyDisclaimer == null) {
      showAllergyDisclaimer = true;
    }
    for (var i = 0; i < locationData.length; i++) {
      if (locationData[i]['selected']) {
        this.setState({
          selectLocationName: locationData[i]['geoAddress'],
        });
        // console.log(locationData[i]);
        break;
      }
    }
    this.setState({ addresses: locationData, showAllergyDisclaimer: showAllergyDisclaimer }, (newState) => {
      // console.log(
      //   'locationData',
      //   this.state.addresses,
      //   typeof this.state.addresses
      // );
    });
    var selected;
    try {
      selected = JSON.parse(localStorage.getItem('selected'));
} catch(e) {
console.log(e);
}
    if (selected == null) {
      selected = { latitude: 0, longitude: 0 };
    }
    this.setState({ selectedAddress: selected }, (newState) => {
      // console.log(
      //   'selectedAddress',
      //   this.state.selectedAddress,
      //   typeof this.state.selectedAddress
      // );
    });
    // console.log('locationData', locationData);
    const { id } = this.props.match.params;
    var date2 = new Date();
    var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[date2.getDay()];
    this.setState({ busId: id });
    // this.setState({ businessData: {} });

    await this.getCities(id);
  }

  async listMaker() {
    var date2 = new Date();
    var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[date2.getDay()];
    var weekText = [strings['Domingo'],strings['Lunes'],strings['Martes'],strings['Miércoles'],strings['Jueves'],strings['Viernes'],strings['Sábado']];
    var currenciesList3 = [];
    var currenciesList4 = [];
    var currenciesList5 = [];
    var currenciesList6 = [];
    var currenciesList7 = [];
    var currenciesList8 = [];
    var currenciesList2 = [];
    var currenciesList = [];
        // await preOrder.put('isTomorrow', isTomorrow);
    // await preOrder.put('isPreOrder', isPreOrder);
    // await preOrder.put('selectedWeekDay', currentDay);
    // await preOrder.put('selectedDate', selected);
    var current = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    // console.log(current);
    var startHour = current.getHours();
    var startMinute = current.getMinutes();
    if (startMinute !== 0 || startMinute !== 15 || startMinute !== 30 || startMinute !== 45) {
      if (startMinute < 15) {
        startMinute = 15;
      } else if (startMinute < 30) {
        startMinute = 30;
      } else if (startMinute < 35) {
        startMinute = 45;
      } else if (startMinute < 60) {
        startMinute = 0;
        startHour = startHour + 1;
      }
    }
    //print(startHour);
    //print(startMinute);
    // console.log(new Date().getDate(),current.getDate(),(new Date().getDate() >= current.getDate()),startHour,current,new Date());
    if(new Date().getDate() >= current.getDate()) {
    for (var i = startHour; startHour <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current.getFullYear());
      addTime.setMonth(current.getMonth());
      addTime.setDate(current.getDate());
      addTime.setHours(startHour);
      addTime.setMinutes(startMinute);
      addTime = moment(addTime).add(0, 'm').toDate();
      console.log(new Date().getDate(),current.getDate(),addTime,startHour,startMinute);
      //addTime = DateTime(current.year,current.month,current.day,startHour,startMinute).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current.year, current.month,
      //         current.day, startHour, startMinute)
      //     .add(Duration(minutes: 0));
      if (startMinute === 0) {
        addition = addition +
            startHour.toString() +
            ':' +
            startMinute.toString() +
            '0';

        startMinute = 15;
      } else if (startMinute === 15) {
        addition = addition +
            startHour.toString() +
            ':' +
            startMinute.toString() +
            '';

        startMinute = 30;
      } else if (startMinute === 30) {
        addition = addition +
            startHour.toString() +
            ':' +
            startMinute.toString() +
            '';

        startMinute = 45;
      } else {
        addition = addition +
            startHour.toString() +
            ':' +
            startMinute.toString() +
            '';
        startMinute = 0;
        startHour = startHour + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);
      // console.log(this.isOpenOn(addTime));
      if(this.isOpenOn(addTime))
      currenciesList.push({'display': addition, 'date': addTime});
    }
  }
    //print(currenciesList);

    var current2 = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    //print(current2);
    var startHour2 = 0;
    var startMinute2 = 0;
    if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
      if (startMinute2 < 15) {
        startMinute2 = 15;
      } else if (startMinute2 < 30) {
        startMinute2 = 30;
      } else if (startMinute2 < 35) {
        startMinute2 = 45;
      } else if (startMinute2 < 60) {
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
    }
    //print(startHour2);
    //print(startMinute2);
    for (var i = startHour2; startHour2 <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current2.getFullYear());
      addTime.setMonth(current2.getMonth());
      addTime.setDate(current2.getDate()+1);
      addTime.setHours(startHour2);
      addTime.setMinutes(startMinute2);
      addTime = moment(addTime).add(0, 'm').toDate();
      //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
      //         current2.day + 1, startHour2, startMinute2)
      //     .add(Duration(minutes: -0));
      if (startMinute2 === 0) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '0';

        startMinute2 = 15;
      } else if (startMinute2 === 15) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 30;
      } else if (startMinute2 === 30) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 45;
      } else {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);
      // console.log(this.isOpenOn(addTime));
      if(this.isOpenOn(addTime))
      currenciesList3.push({'display': addition, 'date': addTime});
    }
    try {
      var current2 = moment(new Date()).add(45, 'm').toDate();
      //List timing = [];
      //print(current2);
      var startHour2 = 0;
      var startMinute2 = 0;
      if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
        if (startMinute2 < 15) {
          startMinute2 = 15;
        } else if (startMinute2 < 30) {
          startMinute2 = 30;
        } else if (startMinute2 < 35) {
          startMinute2 = 45;
        } else if (startMinute2 < 60) {
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
      }
      //print(startHour2);
      //print(startMinute2);
      for (var i = startHour2; startHour2 <= 23; i++) {
        var addition = '';
        var addTime = new Date();
        addTime.setFullYear(current2.getFullYear());
        addTime.setMonth(current2.getMonth());
        addTime.setDate(current2.getDate()+2);
        addTime.setHours(startHour2);
        addTime.setMinutes(startMinute2);
        addTime = moment(addTime).add(0, 'm').toDate();
        //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
        // var timezone = tz.getLocation('America/Mexico_City');
        // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
        //         current2.day + 1, startHour2, startMinute2)
        //     .add(Duration(minutes: 0));
        if (startMinute2 === 0) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '0';

          startMinute2 = 15;
        } else if (startMinute2 === 15) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 30;
        } else if (startMinute2 === 30) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 45;
        } else {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
        addition = addition.replaceAll('24', '00');
        //print(addition);
        //print(addTime);
        if(this.isOpenOn(addTime))
        currenciesList4.push({'display': addition, 'date': addTime});
      }
    } catch(e) {}
    try {
      var current2 = moment(new Date()).add(45, 'm').toDate();
      //List timing = [];
      //print(current2);
      var startHour2 = 0;
      var startMinute2 = 0;
      if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
        if (startMinute2 < 15) {
          startMinute2 = 15;
        } else if (startMinute2 < 30) {
          startMinute2 = 30;
        } else if (startMinute2 < 35) {
          startMinute2 = 45;
        } else if (startMinute2 < 60) {
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
      }
      //print(startHour2);
      //print(startMinute2);
      for (var i = startHour2; startHour2 <= 23; i++) {
        var addition = '';
        var addTime = new Date();
        addTime.setFullYear(current2.getFullYear());
        addTime.setMonth(current2.getMonth());
        addTime.setDate(current2.getDate()+3);
        addTime.setHours(startHour2);
        addTime.setMinutes(startMinute2);
        addTime = moment(addTime).add(0, 'm').toDate();
        //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
        // var timezone = tz.getLocation('America/Mexico_City');
        // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
        //         current2.day + 1, startHour2, startMinute2)
        //     .add(Duration(minutes: 0));
        if (startMinute2 === 0) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '0';

          startMinute2 = 15;
        } else if (startMinute2 === 15) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 30;
        } else if (startMinute2 === 30) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 45;
        } else {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
        addition = addition.replaceAll('24', '00');
        //print(addition);
        //print(addTime);
        if(this.isOpenOn(addTime))
        currenciesList5.push({'display': addition, 'date': addTime});
      }
    } catch(e) {}
    try {
      var current2 = moment(new Date()).add(45, 'm').toDate();
      //List timing = [];
      //print(current2);
      var startHour2 = 0;
      var startMinute2 = 0;
      if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
        if (startMinute2 < 15) {
          startMinute2 = 15;
        } else if (startMinute2 < 30) {
          startMinute2 = 30;
        } else if (startMinute2 < 35) {
          startMinute2 = 45;
        } else if (startMinute2 < 60) {
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
      }
      //print(startHour2);
      //print(startMinute2);
      for (var i = startHour2; startHour2 <= 23; i++) {
        var addition = '';
        var addTime = new Date();
        addTime.setFullYear(current2.getFullYear());
        addTime.setMonth(current2.getMonth());
        addTime.setDate(current2.getDate()+4);
        addTime.setHours(startHour2);
        addTime.setMinutes(startMinute2);
        addTime = moment(addTime).add(0, 'm').toDate();
        //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
        // var timezone = tz.getLocation('America/Mexico_City');
        // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
        //         current2.day + 1, startHour2, startMinute2)
        //     .add(Duration(minutes: 0));
        if (startMinute2 === 0) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '0';

          startMinute2 = 15;
        } else if (startMinute2 === 15) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 30;
        } else if (startMinute2 === 30) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 45;
        } else {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
        addition = addition.replaceAll('24', '00');
        //print(addition);
        //print(addTime);
        if(this.isOpenOn(addTime))
        currenciesList6.push({'display': addition, 'date': addTime});
      }
    } catch(e) {}
    try {
      var current2 = moment(new Date()).add(45, 'm').toDate();
      //List timing = [];
      //print(current2);
      var startHour2 = 0;
      var startMinute2 = 0;
      if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
        if (startMinute2 < 15) {
          startMinute2 = 15;
        } else if (startMinute2 < 30) {
          startMinute2 = 30;
        } else if (startMinute2 < 35) {
          startMinute2 = 45;
        } else if (startMinute2 < 60) {
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
      }
      //print(startHour2);
      //print(startMinute2);
      for (var i = startHour2; startHour2 <= 23; i++) {
        var addition = '';
        var addTime = new Date();
        addTime.setFullYear(current2.getFullYear());
        addTime.setMonth(current2.getMonth());
        addTime.setDate(current2.getDate()+5);
        addTime.setHours(startHour2);
        addTime.setMinutes(startMinute2);
        addTime = moment(addTime).add(0, 'm').toDate();
        //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
        // var timezone = tz.getLocation('America/Mexico_City');
        // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
        //         current2.day + 1, startHour2, startMinute2)
        //     .add(Duration(minutes: 0));
        if (startMinute2 === 0) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '0';

          startMinute2 = 15;
        } else if (startMinute2 === 15) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 30;
        } else if (startMinute2 === 30) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 45;
        } else {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
        addition = addition.replaceAll('24', '00');
        //print(addition);
        //print(addTime);
        if(this.isOpenOn(addTime))
        currenciesList7.push({'display': addition, 'date': addTime});
      }
    } catch(e) {}
    try {
      var current2 = moment(new Date()).add(45, 'm').toDate();
      //List timing = [];
      //print(current2);
      var startHour2 = 0;
      var startMinute2 = 0;
      if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
        if (startMinute2 < 15) {
          startMinute2 = 15;
        } else if (startMinute2 < 30) {
          startMinute2 = 30;
        } else if (startMinute2 < 35) {
          startMinute2 = 45;
        } else if (startMinute2 < 60) {
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
      }
      //print(startHour2);
      //print(startMinute2);
      for (var i = startHour2; startHour2 <= 23; i++) {
        var addition = '';
        var addTime = new Date();
        addTime.setFullYear(current2.getFullYear());
        addTime.setMonth(current2.getMonth());
        addTime.setDate(current2.getDate()+6);
        addTime.setHours(startHour2);
        addTime.setMinutes(startMinute2);
        addTime = moment(addTime).add(0, 'm').toDate();
        //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
        // var timezone = tz.getLocation('America/Mexico_City');
        // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
        //         current2.day + 1, startHour2, startMinute2)
        //     .add(Duration(minutes: 0));
        if (startMinute2 === 0) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '0';

          startMinute2 = 15;
        } else if (startMinute2 === 15) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 30;
        } else if (startMinute2 === 30) {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';

          startMinute2 = 45;
        } else {
          addition = addition +
              startHour2.toString() +
              ':' +
              startMinute2.toString() +
              '';
          startMinute2 = 0;
          startHour2 = startHour2 + 1;
        }
        addition = addition.replaceAll('24', '00');
        //print(addition);
        //print(addTime);
        if(this.isOpenOn(addTime))
        currenciesList8.push({'display': addition, 'date': addTime});
      }
    } catch(e) {}
    currenciesList2 = [
      //{'title': 'ahora','date':tz.TZDateTime.now(timezone),'id':1},
      {'title': strings['hoy'], 'date': new Date(), 'id': 1},
      {
        'title': strings['mańana'],
        'date': moment(new Date()).add(1, 'd').toDate(),
        'id': 2
      },
      {
        'title': weekText[moment(new Date()).add(2, 'd').toDate().getUTCDay()],
        'date': moment(new Date()).add(2, 'd').toDate(),
        'id': 3
      },
      {
        'title': weekText[moment(new Date()).add(3, 'd').toDate().getUTCDay()],
        'date': moment(new Date()).add(3, 'd').toDate(),
        'id': 4
      },
      {
        'title': weekText[moment(new Date()).add(4, 'd').toDate().getUTCDay()],
        'date': moment(new Date()).add(4, 'd').toDate(),
        'id': 5
      },
      {
        'title': weekText[moment(new Date()).add(5, 'd').toDate().getUTCDay()],
        'date': moment(new Date()).add(5, 'd').toDate(),
        'id': 6
      },
      {
        'title': weekText[moment(new Date()).add(6, 'd').toDate().getUTCDay()],
        'date': moment(new Date()).add(6, 'd').toDate(),
        'id': 7
      },
    ];
    // if(currenciesList.length<=0) {
    //   currenciesList2.splice(0,1);
    // }
    console.log(currenciesList);
    this.setState(
      {
        currenciesList2: currenciesList2,
        currenciesList3: currenciesList3,
        currenciesList4: currenciesList4,
        currenciesList5: currenciesList5,
        currenciesList6: currenciesList6,
        currenciesList7: currenciesList7,
        currenciesList8: currenciesList8,
        currenciesList: currenciesList,
        currenciesList2Selected: 0,
        currenciesList3Selected: 0,
        currenciesList4Selected: 0,
        currenciesList5Selected: 0,
        currenciesList6Selected: 0,
        currenciesList7Selected: 0,
        currenciesList8Selected: 0,
        currenciesListSelected: 0
      }
    );
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this._ismounted = true;
    this.setState({isMounted: true});
    const { id } = this.props.match.params;
    try {
    let localCartState = localStorage.getItem('cart_state');
    localCartState = JSON.parse(localCartState);
    this.setState({ cart: (localCartState && localCartState.restId === id) ? localCartState : this.state.cart });
    } catch(e) {}
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.setState({isMounted: false});
    // console.log('IT IS UNDONE');
    window.removeEventListener('resize', this.updateWindowDimensions);
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
    localStorage.setItem('cart_state', JSON.stringify(this.state.cart));
    window.dispatchEvent(new Event("cart_state"));
    } catch(e) {}
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  upsellingProdList() {
    var upsellingProdL = [];
    for(var i=0;i<this.state.upsellingProd.length;i++) {
      if((this.state.cart['item']).filter((element) => (element['id'].includes(this.state.upsellingProd[i]['id']))).length<=0) {
        upsellingProdL.push(this.state.upsellingProd[i]);
      }
    }
    return upsellingProdL;
  }

  getTimings(schedule) {
    //print(schedule);
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    var isOpen;
    if (schedule != null) {
      // var date2 = new Date();
      // var weekDay = [6,0,1,2,3,4,5];
      // var currentDay = weekDay[date2.getDay()];
      // this.setState({ selectedDate: date2, selectedWeekDay: currentDay });
      if (schedule[this.state.selectedWeekDay]['enabled']) {
        for (
          var i = 0;
          i < schedule[this.state.selectedWeekDay]['lapses'].length;
          i++
        ) {
          //selectedDate = tz.TZDateTime.now(timezone);
          if (
            schedule[this.state.selectedWeekDay]['lapses'][i]['open']['hour'] <
              this.state.selectedDate.getHours() &&
            schedule[this.state.selectedWeekDay]['lapses'][i]['close']['hour'] >
              this.state.selectedDate.getHours()
          ) {
            isOpen = true;
          } else if (
            schedule[this.state.selectedWeekDay]['lapses'][i]['open'][
              'hour'
            ] === this.state.selectedDate.getHours()
          ) {
            if (
              schedule[this.state.selectedWeekDay]['lapses'][i]['open'][
                'minute'
              ] <= this.state.selectedDate.getMinutes()
            ) {
              isOpen = true;
            }
          } else if (
            schedule[this.state.selectedWeekDay]['lapses'][i]['close'][
              'hour'
            ] === this.state.selectedDate.getHours()
          ) {
            if (
              schedule[this.state.selectedWeekDay]['lapses'][i]['close'][
                'minute'
              ] > this.state.selectedDate.getMinutes()
            ) {
              isOpen = true;
            }
          }
        }
      }
      if (isOpen == null) isOpen = false;
    } else {
      isOpen = true;
    }
    // print('isOpen $isOpen');
    return isOpen;
  }

  handleChange(e) {
    // var menuD = JSON.parse(JSON.stringify(this.state.menuData));
    var menuD = this.state.menuData.map(item => ({...item}))
    for (var i = 0; i < menuD.length; i++) {
      menuD[i]['products'] = menuD[i]['products'].filter(
        (val) =>
          ((typeof val.name === 'string' &&
          val.name.toLowerCase().includes(e.target.value.toLowerCase())) || (val['nameEng'] && typeof val.nameEng === 'string' &&
          val.nameEng.toLowerCase().includes(e.target.value.toLowerCase())))
      );
      // console.log(
      //   'handle change called',
      //   e.target.value,
      //   i,
      //   menuD[i]['products'].length
      // );
    }
    this.setState({ menu: menuD });
  }
  handleSetActive(to) {
    document.getElementById(to).scrollIntoView({ block: 'nearest' });
  }

  getTotalCost(cartData, offerData) {
    var totalCost = 0;
    if(offerData['isProductDis']) {
      for (
        var ij = 0;
        ij < cartData['item'].length;
        ij++
      ) {
        if(offerData['productDataIds'].includes(cartData['item'][ij]['id'])) {
          totalCost = totalCost + (cartData['item'][ij]['price'] * cartData['item'][ij]['quantity']);
          console.log(totalCost,offerData['productDataIds'],cartData['item'][ij]['id']);
        }
      }
    } else {
      totalCost = cartData['totalCost'];
    }
    return totalCost;
  }

  getTotalCost2(cartData, offerData) {
    var totalCost = (cartData.offerValue ?? 0)*(-1);
    if(offerData['isProductDis']) {
      for (
        var ij = 0;
        ij < cartData['item'].length;
        ij++
      ) {
        if(offerData['productDataIds'].includes(cartData['item'][ij]['id'])) {
          totalCost = totalCost + (cartData['item'][ij]['price'] * cartData['item'][ij]['quantity']);
          console.log(totalCost,offerData['productDataIds'],cartData['item'][ij]['id']);
        }
      }
    } else {
      totalCost = cartData['totalCost'] - cartData.offerValue;
    }
    return totalCost;
  }

  render() {
    const getStat = (order) => {
      var update = '';
      if (!order['acceptedByRest']) {
        update = strings['Esperando a que el negocio acepte su pedido'];
      }
      if (order['acceptedByRest'] && !order['readyToBePicked']) {
        update = strings['El negocio está preparando su producto.'];
      }
      // if (order['acceptedByRest'] && order['readyToBePicked']) {
      //   update = 'Orden lista para recolección ';
      // }
      if (
        order['driverAssigned'] &&
        !order['driverReached'] &&
        !order['orderPickedUp']
      ) {
        update =
          order['driverName'].split(' ')[0] +
          strings[' está en camino para recoger su pedido'];
      }
      if (
        order['driverAssigned'] &&
        order['driverReached'] &&
        !order['orderPickedUp']
      ) {
        update =
          order['driverName'].split(' ')[0] + strings[' llegó al negocio'];
      }

      if (order['orderPickedUp']) {
        update =
          order['driverName'].split(' ')[0] + strings[' ha recogido tu pedido'];
      }
      if (order['delivered'] && !order['cancelled']) {
        update = strings['Su orden ha sido completada'];
      }
      if (order['delivered'] && order['cancelled']) {
        update = strings['Su pedido ha sido cancelado'];
      }
      return update;
    };

    const getTimings = (schedule) => {
      //print(schedule);
      var days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      var isOpen;
      if (schedule != null) {
        // var date2 = new Date();
        // var weekDay = [6,0,1,2,3,4,5];
        // var currentDay = weekDay[date2.getDay()];
        // this.setState({ selectedDate: date2, selectedWeekDay: currentDay });
        if (schedule[this.state.selectedWeekDay]['enabled']) {
          for (
            var i = 0;
            i < schedule[this.state.selectedWeekDay]['lapses'].length;
            i++
          ) {
            //selectedDate = tz.TZDateTime.now(timezone);
            if (
              schedule[this.state.selectedWeekDay]['lapses'][i]['open'][
                'hour'
              ] < this.state.selectedDate.getHours() &&
              schedule[this.state.selectedWeekDay]['lapses'][i]['close'][
                'hour'
              ] > this.state.selectedDate.getHours()
            ) {
              isOpen = true;
            } else if (
              schedule[this.state.selectedWeekDay]['lapses'][i]['open'][
                'hour'
              ] === this.state.selectedDate.getHours()
            ) {
              if (
                schedule[this.state.selectedWeekDay]['lapses'][i]['open'][
                  'minute'
                ] <= this.state.selectedDate.getMinutes()
              ) {
                isOpen = true;
              }
            } else if (
              schedule[this.state.selectedWeekDay]['lapses'][i]['close'][
                'hour'
              ] === this.state.selectedDate.getHours()
            ) {
              if (
                schedule[this.state.selectedWeekDay]['lapses'][i]['close'][
                  'minute'
                ] > this.state.selectedDate.getMinutes()
              ) {
                isOpen = true;
              }
            }
          }
        }
        if (isOpen == null) isOpen = false;
      } else {
        isOpen = true;
      }
      // print('isOpen $isOpen');
      return isOpen;
    };
    const sendOTP = async (phone) => {
      const min = 0;
      const max = 999999;
      const rand = Math.floor(min + Math.random() * (max - min));
      var dataSend = rand.toString();
      for (var i = 0; i < 6; i++) {
        if (dataSend.length < 6) {
          dataSend = '0' + dataSend;
        }
      }
      // console.log(dataSend, phone);
      this.setState({ otpSend: dataSend });
      await axios
        .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-new-otp/', {
          code: dataSend,
          number: phone,
          uuid: this.state.userId
        })
        .then(async (response) => {
          // console.log(response);
        });
    };
    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
    const handleProfileMenuOpen = (event) => {
      // setAnchorEl(event.currentTarget);
      this.setState({ anchorEl: event.currentTarget });
      // this.state.anchorEl = event.currentTarget;
    };

    const handleCartOpen = (event) => {
      console.log('minimumPurchase',this.state.businessData.minimumPurchase);
      console.log('minimumPurchasePick',this.state.businessData.minimumPurchasePick);
      if(((this.state.selectedType == 1 && this.state.businessData.minimumPurchasePick!=null && this.state.businessData.minimumPurchasePick<=this.state.cart['totalCost']) || ((this.state.selectedType != 1 || this.state.businessData.minimumPurchasePick==null) && (this.state.businessData.minimumPurchase==null || this.state.businessData.minimumPurchase<=this.state.cart['totalCost'])))) {
      var locationData = this.state.addresses;
      for (var i = 0; i < locationData.length; i++) {
        if (locationData[i]['selected']) {
          this.setState({
            selectedLocation: locationData[i],
            selectedLocationName: locationData[i]['name'],
            selectedLocationData: locationData[i]['geoAddress'],
            selectedLocationNotes: locationData[i]['addressNotes'],
          });
          // console.log(locationData[i]);
          break;
        }
      }

      var offersAdd = [];

      var allOffers = this.state.offerData ?? [];

      var weekDay = [6,0,1,2,3,4,5];
      var currentDay = weekDay[new Date().getDay()];
      for(var i =0;i<allOffers.length;i++) {
        console.log(i,allOffers[i]['enabled'],allOffers[i]['isNewUser'],this.state.isNewUser);
        if(allOffers[i]['enabled'] && ((allOffers[i]['isNewUser'] && this.state.userDetails && (this.state.isNewUser || (
          this.state.offersUsed && this.state.offersUsed.find(
            (element) =>
              element['id'] ===
              allOffers[i]['id']
          ) != null
        ))) || (allOffers[i]['isNewUser']==null || !allOffers[i]['isNewUser']))) {
          var datedate = new Date();
          var isOpen = null;
          console.log(allOffers[i]['schedule']);
      if (allOffers[i]['schedule'] != null) {
        console.log('Has schedule12',currentDay);
        // var date2 = new Date();
        // var weekDay = [6,0,1,2,3,4,5];
        // var currentDay = weekDay[date2.getDay()];
        // this.setState({ selectedDate: date2, selectedWeekDay: currentDay });
        if (allOffers[i].schedule[currentDay]['enabled']) {



          console.log('Has schedule',currentDay);
            //selectedDate = tz.TZDateTime.now(timezone);
            if (
              allOffers[i].schedule[currentDay]['open'][
                'hour'
              ] < datedate.getHours() &&
              allOffers[i].schedule[currentDay]['close'][
                'hour'
              ] > datedate.getHours()
            ) {
              isOpen = true;
            } else if (
              allOffers[i].schedule[currentDay]['open'][
                'hour'
              ] === datedate.getHours()
            ) {
              if (
                allOffers[i].schedule[currentDay]['open'][
                  'minute'
                ] <= datedate.getMinutes()
              ) {
                isOpen = true;
              }
            } else if (
              allOffers[i].schedule[currentDay]['close'][
                'hour'
              ] === datedate.getHours()
            ) {
              if (
                allOffers[i].schedule[currentDay]['close'][
                  'minute'
                ] > datedate.getMinutes()
              ) {
                isOpen = true;
              }
            }
        }
        if (isOpen == null) isOpen = false;
      } else {
        isOpen = true;
      }

      console.log(isOpen,'offersAdd',)
      if(isOpen)
          offersAdd.push(allOffers[i]);
        }
      }
      console.log(this.state.offers3,this.state.offers2);
      this.setState({
        offers: offersAdd.concat(this.state.offers2.concat(this.state.offers3)),
      });



      // setAnchorEl(event.currentTarget);
      this.setState({
        showCart: true,
        showGuest: false,
        isGuest: false,
        showAdditionalDetails: false,
        guestDetails: {
          'name': '',
          'email': '',
          'phone': ''
        },
        cards:(this.state.userDetails && this.state.userDetails.cards) ? this.state.userDetails.cards : [],
        order: {
          offerCode: '',
          selectedOffer: {},
          offerId: '',
          offerValue: 0,
          offerValue2: 0,
          cashbackValue: 0,
          offerCode2: '',
          offerId2: '',
          offerValue2: 0,
          restId: '',
          restName: '',
          restLogo: '',
          maxTime: 0,
          itemCost: 0,
          deliveryCharge: 0,
          usageFee: 0,
          taxes: 0,
          serviceFee: 0,
          grandTotal: 0,
          items: [],
        },
        offerCode: '',
        selectedOffer: null,
        offerId: '',
        offerSuccess: '',
        offerErrorText: '',
        offerValue: 0,
        offerId2: '',
        offerSuccess2: '',
        offerErrorText2: '',
        offerValue2: 0,
        usingWallet: false,
        cashbackValue: 0,
        offerInd: null,
        defaultCard: null,
        isOfferApplied: false,
        isOfferError: false,
        isFreeDelivery: false,
        cookingInstructions: '',
        deliveryInstructions: '',
        showDiscountIsBusiness: false,
      },(newState)=>{
        try {
          if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
            ReactGA.initialize(this.state.businessData.googleAID);
          ReactGA.send({ hitType: "pageview", page: "/cart" });
          }
        } catch(e) {}
        for (var offerIndex = (offersAdd.length - 1); offerIndex>=0;offerIndex--) {


          if((offersAdd[offerIndex]['autoAdd']!=null && offersAdd[offerIndex]['autoAdd'])) {

            console.log('AUTO ADDING');

                                                // console.log('Her');
                                                var offerCode = this.state.offerCode;
                                                var offerId = this.state.offerId;
                                                var offerSuccess =
                                                  this.state.offerSuccess;
                                                var offerErrorText =
                                                  this.state.offerErrorText;
                                                var offerValue = this.state.offerValue;
                                                var cashbackValue =
                                                  this.state.cashbackValue;
                                                var offerInd = this.state.offerInd;
                                                var isOfferApplied =
                                                  this.state.isOfferApplied;
                                                var isOfferError =
                                                  this.state.isOfferError;
                                                var isFreeDelivery =
                                                  this.state.isFreeDelivery;
                                                var offersUsed = this.state.offersUsed;
                                                var devyOffers = offersAdd;
                                                var cart = this.state.cart;
                                                var delivery = this.state.delivery;
                                                var totalCost = this.getTotalCost(cart,devyOffers[offerIndex]);
                                                // usingWallet = false;
                                                // isUsingInviteCode = false;
                                                // isUsingSubscription = false;
                                                this.setState({
                  selectedOffer: devyOffers[offerIndex],
                });
                                                if (
                                                  offersUsed.find(
                                                    (element) =>
                                                      element['id'] ===
                                                      devyOffers[offerIndex]['id']
                                                  ) == null
                                                ) {
                                                  if (
                                                    devyOffers[offerIndex][
                                                      'isItemOffer'
                                                    ] != null &&
                                                    devyOffers[offerIndex][
                                                      'isItemOffer'
                                                    ]
                                                  ) {
                                                    offerValue = 0;
                                                    cashbackValue = 0.0;
                                                    // console.log(
                                                    //   devyOffers[offerIndex]['items']
                                                    // );
                                                    var testList =
                                                      devyOffers[offerIndex]['items'];
                                                    for (
                                                      var ij = 0;
                                                      ij < cart['item'].length;
                                                      ij++
                                                    ) {
                                                      //print(testList.filter((element34) => element34['id'].contains(cart['item'][ij]['id'])));
                                                      if (
                                                        testList.find(
                                                          (element34) =>
                                                            element34['id'] ===
                                                            cart['item'][ij]['id']
                                                        ) != null
                                                      ) {
                                                        var tempValue = testList.find(
                                                          (element) =>
                                                            element['id'].toString() ===
                                                            cart['item'][ij][
                                                              'id'
                                                            ].toString()
                                                        );
                                                        if (
                                                          tempValue['id'] ===
                                                            cart['item'][ij]['id'] &&
                                                          Math.trunc(
                                                            cart['item'][ij][
                                                              'quantity'
                                                            ] /
                                                              (devyOffers[offerIndex][
                                                                'freeItem'
                                                              ] +
                                                                devyOffers[offerIndex][
                                                                  'buyItem'
                                                                ])
                                                          ) > 0
                                                        ) {
                                                          offerValue =
                                                            offerValue +
                                                            cart['item'][ij]['price'] *
                                                              Math.trunc(
                                                                cart['item'][ij][
                                                                  'quantity'
                                                                ] /
                                                                  (devyOffers[
                                                                    offerIndex
                                                                  ]['freeItem'] +
                                                                    devyOffers[
                                                                      offerIndex
                                                                    ]['buyItem'])
                                                              ) +
                                                            0.0;
                                                        }
                                                      }
                                                    }
                                                    if (offerValue > 0) {
                                                      isOfferApplied = true;
                                                      offerInd = offerIndex;
                                                      isFreeDelivery = false;
                                                      isOfferError = false;
                                                      offerErrorText = '';
                                                      offerCode =
                                                        devyOffers[offerIndex]['code'];
                                                      offerId =
                                                        devyOffers[offerIndex]['id'];
                                                      offerSuccess =
                                                        strings['Su cupón ha sido agregado con éxito.'];
                                                    } else {
                                                      isOfferApplied = false;
                                                      isOfferError = true;
                                                      isFreeDelivery = false;
                                                      offerInd = offerIndex;
                                                      offerErrorText =
                                                        strings['Añadir al menos '] +
                                                        (
                                                          devyOffers[offerIndex][
                                                            'freeItem'
                                                          ] +
                                                          devyOffers[offerIndex][
                                                            'buyItem'
                                                          ]
                                                        ).toString() +
                                                        strings[' productos promocionales.'];
                                                      offerCode =
                                                        devyOffers[offerIndex]['code'];
                                                      offerSuccess = '';
                                                      offerValue = 0;
                                                      cashbackValue = 0.0;
                                                      offerId = '';
                                                    }
                                                  } else if (
                                                    totalCost >=
                                                    devyOffers[offerIndex][
                                                      'minimumItemValue'
                                                    ]
                                                  ) {
                                                    if (
                                                      devyOffers[offerIndex][
                                                        'maximumItemValue'
                                                      ] === 0 ||
                                                      totalCost <=
                                                        devyOffers[offerIndex][
                                                          'maximumItemValue'
                                                        ]
                                                    ) {
                                                      isOfferApplied = true;
                                                      offerInd = offerIndex;
                                                      isFreeDelivery = false;
                                                      isOfferError = false;
                                                      offerErrorText = '';
                                                      offerCode =
                                                        devyOffers[offerIndex]['code'];
                                                      offerId =
                                                        devyOffers[offerIndex]['id'];
                                                      offerSuccess =
                                                        strings['Su cupón ha sido agregado con éxito.'];
                                                      // if(devyOffers[offerIndex]['isInvite']!=null && devyOffers[offerIndex]['isInvite']) {
                                                      //   isUsingInviteCode = true;
                                                      // } else if(devyOffers[offerIndex]['isSubscription']!=null && devyOffers[offerIndex]['isSubscription']) {
                                                      //   isUsingSubscription = true;
                                                      // }
                                                      //storage.setItem('cart', jsonEncode(cart));
                                                      //Utilizando CarteraDevy

                                                      if (
                                                        devyOffers[offerIndex][
                                                          'freeDelivery'
                                                        ]
                                                      ) {
                                                        offerValue = (delivery + this.extraCharges()) + 0.0;
                                                        cashbackValue = 0.0;
                                                        isFreeDelivery = true;
                                                      } else {
                                                        isFreeDelivery = false;
                                                        offerValue =
                                                          devyOffers[offerIndex][
                                                            'discountAmount'
                                                          ] +
                                                          0.0 +
                                                          (devyOffers[offerIndex][
                                                            'discountPercentage'
                                                          ] /
                                                            100) *
                                                            totalCost;
                                                        if (
                                                          offerValue <
                                                          devyOffers[offerIndex][
                                                            'minimumDiscount'
                                                          ]
                                                        ) {
                                                          offerValue =
                                                            devyOffers[offerIndex][
                                                              'minimumDiscount'
                                                            ] + 0.0;
                                                        } else if (
                                                          devyOffers[offerIndex][
                                                            'maximumDiscount'
                                                          ] !== 0 &&
                                                          offerValue >
                                                            devyOffers[offerIndex][
                                                              'maximumDiscount'
                                                            ]
                                                        ) {
                                                          offerValue =
                                                            devyOffers[offerIndex][
                                                              'maximumDiscount'
                                                            ] + 0.0;
                                                        }
                                                        cashbackValue =
                                                          devyOffers[offerIndex][
                                                            'cashbackAmount'
                                                          ] != null
                                                            ? devyOffers[offerIndex][
                                                                'cashbackAmount'
                                                              ] +
                                                              0.0 +
                                                              (((devyOffers[offerIndex][
                                                                'cashbackMax'
                                                              ] != null && devyOffers[offerIndex][
                                                                'cashbackMax'
                                                              ]>0) && ((devyOffers[offerIndex][
                                                                'cashbackPercentage'
                                                              ] != null
                                                                ? (devyOffers[
                                                                    offerIndex
                                                                  ][
                                                                    'cashbackPercentage'
                                                                  ] /
                                                                    100) *
                                                                  totalCost
                                                                : 0)>devyOffers[offerIndex][
                                                                  'cashbackMax'
                                                                ]))?devyOffers[offerIndex][
                                                                  'cashbackMax'
                                                                ]:(devyOffers[offerIndex][
                                                                  'cashbackPercentage'
                                                                ] != null
                                                                  ? (devyOffers[
                                                                      offerIndex
                                                                    ][
                                                                      'cashbackPercentage'
                                                                    ] /
                                                                      100) *
                                                                    totalCost
                                                                  : 0))
                                                            : 0.0;
                                                      }
                                                    } else {
                                                      isOfferApplied = false;
                                                      isOfferError = true;
                                                      isFreeDelivery = false;
                                                      offerInd = offerIndex;
                                                      offerErrorText =
                                                        strings['Los productos máximos deben ser $'] +
                                                        devyOffers[offerIndex][
                                                          'maximumItemValue'
                                                        ].toString();
                                                      offerCode =
                                                        devyOffers[offerIndex]['code'];
                                                      offerSuccess = '';
                                                      offerValue = 0;
                                                      cashbackValue = 0.0;
                                                      offerId = '';
                                                    }
                                                  } else if (
                                                    totalCost <
                                                    devyOffers[offerIndex][
                                                      'minimumItemValue'
                                                    ]
                                                  ) {
                                                    isOfferApplied = false;
                                                    isOfferError = true;
                                                    isFreeDelivery = false;
                                                    offerInd = offerIndex;
                                                    offerErrorText =
                                                      strings['Los productos mínimos deben ser $'] +
                                                      devyOffers[offerIndex][
                                                        'minimumItemValue'
                                                      ].toString();
                                                    offerCode =
                                                      devyOffers[offerIndex]['code'];
                                                    offerSuccess = '';
                                                    offerValue = 0;
                                                    cashbackValue = 0.0;
                                                    offerId = '';
                                                  }
                                                } else {
                                                  if (
                                                    offersUsed
                                                      .filter(
                                                        (element) =>
                                                          element['id'] ===
                                                          devyOffers[offerIndex]['id']
                                                      )
                                                      [
                                                        offersUsed.filter(
                                                          (element) =>
                                                            element['id'] ===
                                                            devyOffers[offerIndex]['id']
                                                        ).length - 1
                                                      ]['usedOn'].toMillis() +
                                                      devyOffers[offerIndex][
                                                        'timeBetweenUses'
                                                      ] *
                                                        1000 <
                                                    Date.now()
                                                  ) {
                                                    if (
                                                      devyOffers[offerIndex][
                                                        'isItemOffer'
                                                      ] != null &&
                                                      devyOffers[offerIndex][
                                                        'isItemOffer'
                                                      ]
                                                    ) {
                                                      offerValue = 0;
                                                      cashbackValue = 0.0;
                                                      for (
                                                        var ij = 0;
                                                        ij < cart['item'].length;
                                                        ij++
                                                      ) {
                                                        if (
                                                          devyOffers[offerIndex][
                                                            'items'
                                                          ].find((element) =>
                                                            element['id'] ===
                                                            cart['item'][ij]['id']
                                                              ? true
                                                              : false
                                                          ) != null
                                                        ) {
                                                          var tempValue = devyOffers[
                                                            offerIndex
                                                          ]['items'].find((element) =>
                                                            element['id'] ===
                                                            cart['item'][ij]['id']
                                                              ? true
                                                              : false
                                                          );
                                                          if (
                                                            tempValue['id'] ===
                                                              cart['item'][ij]['id'] &&
                                                            Math.trunc(
                                                              cart['item'][ij][
                                                                'quantity'
                                                              ] /
                                                                (devyOffers[offerIndex][
                                                                  'freeItem'
                                                                ] +
                                                                  devyOffers[
                                                                    offerIndex
                                                                  ]['buyItem'])
                                                            ) > 0
                                                          ) {
                                                            offerValue =
                                                              offerValue +
                                                              cart['item'][ij][
                                                                'price'
                                                              ] *
                                                                Math.trunc(
                                                                  cart['item'][ij][
                                                                    'quantity'
                                                                  ] /
                                                                    (devyOffers[
                                                                      offerIndex
                                                                    ]['freeItem'] +
                                                                      devyOffers[
                                                                        offerIndex
                                                                      ]['buyItem'])
                                                                ) +
                                                              0.0;
                                                          }
                                                        }
                                                      }
                                                      if (offerValue > 0) {
                                                        isOfferApplied = true;
                                                        offerInd = offerIndex;
                                                        isFreeDelivery = false;
                                                        isOfferError = false;
                                                        offerErrorText = '';
                                                        offerCode =
                                                          devyOffers[offerIndex][
                                                            'code'
                                                          ];
                                                        offerId =
                                                          devyOffers[offerIndex]['id'];
                                                        offerSuccess =
                                                          strings['Su cupón ha sido agregado con éxito.'];
                                                      } else {
                                                        isOfferApplied = false;
                                                        isOfferError = true;
                                                        isFreeDelivery = false;
                                                        offerInd = offerIndex;
                                                        offerErrorText =
                                                          strings['Añadir al menos '] +
                                                          (
                                                            devyOffers[offerIndex][
                                                              'freeItem'
                                                            ] +
                                                            devyOffers[offerIndex][
                                                              'buyItem'
                                                            ]
                                                          ).toString() +
                                                          strings[' productos promocionales.'];

                                                        offerCode =
                                                          devyOffers[offerIndex][
                                                            'code'
                                                          ];
                                                        offerSuccess = '';
                                                        offerValue = 0;
                                                        cashbackValue = 0.0;
                                                        offerId = '';
                                                      }
                                                    } else if (
                                                      totalCost >=
                                                      devyOffers[offerIndex][
                                                        'minimumItemValue'
                                                      ]
                                                    ) {
                                                      if (
                                                        devyOffers[offerIndex][
                                                          'maximumItemValue'
                                                        ] === 0 ||
                                                        totalCost <=
                                                          devyOffers[offerIndex][
                                                            'maximumItemValue'
                                                          ]
                                                      ) {
                                                        isOfferApplied = true;
                                                        isOfferError = false;
                                                        isFreeDelivery = false;
                                                        offerInd = offerIndex;
                                                        offerErrorText = '';
                                                        offerId =
                                                          devyOffers[offerIndex]['id'];
                                                        offerCode =
                                                          devyOffers[offerIndex][
                                                            'code'
                                                          ];
                                                        offerSuccess =
                                                          strings['Su cupón ha sido agregado con éxito.'];
                                                        if (
                                                          devyOffers[offerIndex][
                                                            'freeDelivery'
                                                          ]
                                                        ) {
                                                          offerValue = (delivery + this.extraCharges()) + 0.0;
                                                          cashbackValue = 0.0;
                                                          isFreeDelivery = true;
                                                        } else {
                                                          isFreeDelivery = false;
                                                          offerValue =
                                                            devyOffers[offerIndex][
                                                              'discountAmount'
                                                            ] +
                                                            0.0 +
                                                            (devyOffers[offerIndex][
                                                              'discountPercentage'
                                                            ] /
                                                              100) *
                                                              totalCost;
                                                          if (
                                                            offerValue <
                                                            devyOffers[offerIndex][
                                                              'minimumDiscount'
                                                            ]
                                                          ) {
                                                            offerValue =
                                                              devyOffers[offerIndex][
                                                                'minimumDiscount'
                                                              ] + 0.0;
                                                          } else if (
                                                            devyOffers[offerIndex][
                                                              'maximumDiscount'
                                                            ] !== 0 &&
                                                            offerValue >
                                                              devyOffers[offerIndex][
                                                                'maximumDiscount'
                                                              ]
                                                          ) {
                                                            offerValue =
                                                              devyOffers[offerIndex][
                                                                'maximumDiscount'
                                                              ] + 0.0;
                                                          }
                                                          cashbackValue =
                                                            devyOffers[offerIndex][
                                                              'cashbackAmount'
                                                            ] != null
                                                              ? devyOffers[offerIndex][
                                                                  'cashbackAmount'
                                                                ] +
                                                                0.0 +
                                                                (((devyOffers[offerIndex][
                                                                  'cashbackMax'
                                                                ] != null && devyOffers[offerIndex][
                                                                  'cashbackMax'
                                                                ]>0) && ((devyOffers[offerIndex][
                                                                  'cashbackPercentage'
                                                                ] != null
                                                                  ? (devyOffers[
                                                                      offerIndex
                                                                    ][
                                                                      'cashbackPercentage'
                                                                    ] /
                                                                      100) *
                                                                    totalCost
                                                                  : 0)>devyOffers[offerIndex][
                                                                    'cashbackMax'
                                                                  ]))?devyOffers[offerIndex][
                                                                    'cashbackMax'
                                                                  ]:(devyOffers[offerIndex][
                                                                    'cashbackPercentage'
                                                                  ] != null
                                                                    ? (devyOffers[
                                                                        offerIndex
                                                                      ][
                                                                        'cashbackPercentage'
                                                                      ] /
                                                                        100) *
                                                                      totalCost
                                                                    : 0))
                                                              : 0.0;
                                                        }
                                                      } else {
                                                        isOfferApplied = false;
                                                        isOfferError = true;
                                                        isFreeDelivery = false;
                                                        offerInd = offerIndex;
                                                        offerErrorText =
                                                          strings['Los productos máximos deben ser $'] +
                                                          devyOffers[offerIndex][
                                                            'maximumItemValue'
                                                          ].toString();
                                                        offerCode =
                                                          devyOffers[offerIndex][
                                                            'code'
                                                          ];
                                                        offerSuccess = '';
                                                        offerValue = 0;
                                                        cashbackValue = 0.0;
                                                        offerId = '';
                                                      }
                                                    } else if (
                                                      totalCost <
                                                      devyOffers[offerIndex][
                                                        'minimumItemValue'
                                                      ]
                                                    ) {
                                                      isOfferApplied = false;
                                                      isFreeDelivery = false;
                                                      isOfferError = true;
                                                      offerInd = offerIndex;
                                                      offerErrorText =
                                                        strings['Los productos mínimos deben ser $'] +
                                                        devyOffers[offerIndex][
                                                          'minimumItemValue'
                                                        ].toString();
                                                      offerCode =
                                                        devyOffers[offerIndex]['code'];
                                                      offerSuccess = '';
                                                      offerValue = 0;
                                                      cashbackValue = 0.0;
                                                      offerId = '';
                                                    }
                                                  } else {
                                                    isOfferApplied = false;
                                                    isOfferError = true;
                                                    isFreeDelivery = false;
                                                    offerInd = offerIndex;
                                                    offerErrorText =
                                                      strings['Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.'];
                                                    offerCode =
                                                      devyOffers[offerIndex]['code'];
                                                    offerSuccess = '';
                                                    offerValue = 0;
                                                    cashbackValue = 0.0;
                                                    offerId = '';
                                                  }
                                                }
                                                this.setState(
                                                  {
                                                    offerCode: offerCode,
                                                    offerId: offerId,
                                                    offerSuccess: offerSuccess,
                                                    offerErrorText: offerErrorText,
                                                    offerValue: offerValue,
                                                    cashbackValue: cashbackValue,
                                                    offerInd: offerInd,
                                                    isOfferApplied: isOfferApplied,
                                                    isOfferError: isOfferError,
                                                    isFreeDelivery: isFreeDelivery,
                                                    offersUsed: offersUsed,
                                                    offers: devyOffers,
                                                    cart: cart,
                                                    delivery: delivery,
                                                    showDiscount: false,
                                                  },
                                                  (newState) => {
                                                    checkOffer();
                                                  }
                                                );

          }

        }
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showCart: false });
      };
    } else{
      this.setState({
        showMinimum: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showMinimum: false });
      };
    }
      // this.state.anchorEl = event.currentTarget;
    };

    const handleAlertOpen = (event) => {
      if(this.state.selectLocationName) {
      this.setState({
        openDialogAlert: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ openDialogAlert: false });
      };
    }
  }

    const handleAlertCancelOpen = (event) => {
      if(this.state.selectLocationName) {
      this.setState({
        openDialogCancel: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ openDialogCancel: false });
      };
    }

    };
    const handleCartOpen2 = (event) => {
      console.log('minimumPurchase',this.state.businessData.minimumPurchase);
      if(this.state.businessData.minimumPurchase==null || this.state.businessData.minimumPurchase<=this.state.cart['totalCost']) {
      var locationData = this.state.addresses;
      for (var i = 0; i < locationData.length; i++) {
        if (locationData[i]['selected']) {
          this.setState({
            selectedLocation: locationData[i],
            selectedLocationName: locationData[i]['name'],
            selectedLocationData: locationData[i]['geoAddress'],
            selectedLocationNotes: locationData[i]['addressNotes'],
          });
          // console.log(locationData[i]);
          break;
        }
      }
      // setAnchorEl(event.currentTarget);
      this.setState({
        showCart: true,
        cards:(this.state.userDetails && this.state.userDetails.cards) ? this.state.userDetails.cards : [],
        order: {
          offerCode: '',
          selectedOffer: {},
          offerId: '',
          offerValue: 0,
          cashbackValue: 0,
          offerCode2: '',
          offerId2: '',
          offerValue2: 0,
          restId: '',
          restName: '',
          restLogo: '',
          maxTime: 0,
          itemCost: 0,
          deliveryCharge: 0,
          usageFee: 0,
          taxes: 0,
          serviceFee: 0,
          grandTotal: 0,
          items: [],
        },
        offerCode: '',
        selectedOffer: null,
        offerId: '',
        offerSuccess: '',
        offerErrorText: '',
        offerValue: 0,
        cashbackValue: 0,
        offerInd: null,
        defaultCard: null,
        isOfferApplied: false,
        isOfferError: false,
        isFreeDelivery: false,
        cookingInstructions: '',
        deliveryInstructions: '',
        showDiscountIsBusiness: false,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showCart: false });
      };
    } else{
      this.setState({
        showMinimum: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showMinimum: false });
      };
    }
      // this.state.anchorEl = event.currentTarget;
    };

    const handleAllergyOpen = (event) => {
      this.setState({
        showAllergy: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showAllergy: false });
      };
      // this.state.anchorEl = event.currentTarget;
    };

    const handleTrackingOpen = (orderDetails, id) => {
      // setAnchorEl(event.currentTarget);
      this.setState({
        showTracking: true,
        trackingDetails: orderDetails,
        trackingId: id,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({
          showTracking: false,
          trackingDetails: null,
          trackingId: null,
        });
      };
      // this.state.anchorEl = event.currentTarget;
    };

    const handleClearCart = () => {
      this.setState({
        showCart: false,
        cart: {
          ...this.state.cart,
          item: [],
          totalItems: 0,
          totalCost: 0,
          totalOxxoCost: 0,
          totalOxxoItems: 0,
        },
      });
      try {
      localStorage.removeItem('cart_state');
      window.dispatchEvent(new Event("cart_state"));
      } catch(e){}
    };

    const getPrimaryColor = () => {
      if (
        this.state.businessData &&
        this.state.businessData?.primaryColor &&
        this.state.businessData.primaryColor != ''
      ) {
        return this.state.businessData.primaryColor;
      }

      return constants.primaryColor;
    };

    const handleMobileMenuClose = () => {
      // setMobileMoreAnchorEl(null);
      this.setState({ mobileMoreAnchorEl: null });
    };

    const handleMenuClose = () => {
      this.setState({ anchorEl: null });
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <>
        {!this.state.userId ? (
          <Menu
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                ReactGA.send({ hitType: "pageview", page: "/login" });
      }
    } catch(e) {}
                handleMenuClose();
                handleClickOpenLogin();
              }}
            >
            <Stack
                 direction="row"
                 justifyContent="center"
                 alignItems="center"
                 spacing={0}
                >
                <LoginRoundedIcon
                   style={{
                    fontSize: this.state.width > 600 ? '20px' : '22px',
                     fontWeight: 'bold',
                     color: getPrimaryColor(),
                     marginRight: '10px',
                   }}
                 />
                <Typography
                 style={{
                   fontWeight: '700',
                   fontFamily: 'Nunito',
                   textTransform: 'none',
                   padding: '5px 11px',
                   color: '#3e4547',
                    fontSize: this.state.width > 600 ? '16px' : '20px',
                 }}
               >
                  {strings['Iniciar sesión']}
               </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleClickOpenRegister();
                try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                ReactGA.send({ hitType: "pageview", page: "/register" });
      }
    } catch(e) {}
              }}
            >
            <Stack
                 direction="row"
                 justifyContent="center"
                 alignItems="center"
                 spacing={0}
                >
                <DataSaverOnRoundedIcon
                   style={{
                    fontSize: this.state.width > 600 ? '20px' : '22px',
                     fontWeight: 'bold',
                     color: getPrimaryColor(),
                     marginRight: '10px',
                   }}
                 />
                <Typography
                 style={{
                   fontWeight: '700',
                   fontFamily: 'Nunito',
                   textTransform: 'none',
                   padding: '5px 11px',
                   color: '#3e4547',
                    fontSize: this.state.width > 600 ? '16px' : '20px',
                 }}
               >
                  {strings['Registrarse']}
               </Typography>
              </Stack>
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            {this.state.userDetails && (
              <>
                <MenuItem
                  onClick={async () => {
                    handleMenuClose();
                    try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                    ReactGA.send({ hitType: "pageview", page: "/profile" });
      }
    } catch(e) {}
                  }}
                >
                  <Link
                    to={'/profile/'+this.state.busId}
                    style={{
                      color: '#444444',
                      textDecoration: 'none',
                      width: '100%',
                    }}
                  >
                    {strings['Perfil']}
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    handleMenuClose();

                    try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                    ReactGA.send({ hitType: "pageview", page: "/my-orders" });
      }
    } catch(e) {}
                  }}
                >
                  <Link
                    to={'/my-orders/'+this.state.busId}
                    style={{
                      color: '#444444',
                      textDecoration: 'none',
                      width: '100%',
                    }}
                  >
                    {strings['Mis Pedidos']}
                  </Link>
                </MenuItem>
                {/* <MenuItem
                  style={{
                    color: 'rgb(43 44 52)',
                    textDecoration: 'none',
                    width: '100%',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    handleMenuClose();
                    handleCardClickOpen();
                try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                ReactGA.send({ hitType: "pageview", page: "/my-cards" });
      }
    } catch(e){}
                    // handleClickOpenOTP();
                  }}
                  >
                    {strings['profileMyCard']}
                </MenuItem> */}
                <MenuItem
                  style={{
                    color: '#444444',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                  onClick={() => {
                    handleMenuClose();
                    handleWalletClickOpen2();
                try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                ReactGA.send({ hitType: "pageview", page: "/my-cards" });
      }
    } catch(e){}
                    // handleClickOpenOTP();
                  }}
                  >
                    {strings['MiCartera']}
                </MenuItem>
                {(this.state.showAllergyData) ?
                 <MenuItem
                 style={{
                  color: '#444444',
                  textDecoration: 'none',
                  width: '100%',
                }}
                   onClick={() => {
                     handleMenuClose();
                     handleAllergyOpen();
                try {
      if(this.state.businessData.googleAID && this.state.businessData.googleAID!='') {
        ReactGA.initialize(this.state.businessData.googleAID);
                ReactGA.send({ hitType: "pageview", page: "/allergy" });
      }
      } catch(e) {}
                     // handleClickOpenOTP();
                   }}
                 >{strings['profileAllergy']}
                 </MenuItem>
                :null }
              </>
            )}
            <MenuItem
            style={{
              color: '#444444',
              textDecoration: 'none',
              width: '100%',
            }}
              onClick={async () => {
                handleMenuClose();
                await auth.signOut();
              }}
            >
              {strings['Cerrar sesión']}
            </MenuItem>
          </Menu>
        )}
      </>
    );
    // const renderMenu = (
    //   <Menu
    //     anchorEl={this.state.anchorEl}
    //     anchorOrigin={{
    //       vertical: 'top',
    //       horizontal: 'right',
    //     }}
    //     id={menuId}
    //     keepMounted
    //     transformOrigin={{
    //       vertical: 'top',
    //       horizontal: 'right',
    //     }}
    //     open={isMenuOpen}
    //     onClose={handleMenuClose}
    //   >
    //   { (this.state.userId)?<><MenuItem onClick={async()=>{handleMenuClose();
    //   await auth.signOut();
    //   }}>Log Out</MenuItem></>: <><MenuItem onClick={()=>{handleMenuClose();
    //   handleClickOpenLogin();
    //   }}>Login</MenuItem><MenuItem onClick={()=>{handleMenuClose();
    //   handleClickOpenRegister();
    //   // handleClickOpenOTP();
    //   }}>Register</MenuItem></>}
    //   </Menu>
    // );
    const handleClickOpen = () => {
      this.setState({ openLocation: true });
      this.setState({ hasClickedLoc: false });
      this.setState({
        selectLocationData: {
          id: '',
          name: strings['mi hogar'],
          address: '',
          geoAddress: '',
          addressNotes: '',
          latitude: this.state.defaultProps.center.lat,
          longitude: this.state.defaultProps.center.lng,
        },
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleClose();
      };
    };
    const handleCardClickOpen = () => {
      this.setState({ openCards: true, cards:(this.state.userDetails && this.state.userDetails.cards) ? this.state.userDetails.cards : [], });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleCardClose();
      };
    };

    const handleWalletClickOpen = () => {
      this.setState({ openWallet: true,showOtherWallet: true, enteredAmount: 0 });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleWalletClose();
      };
    };

    const handleWalletClickOpen2 = () => {
      this.setState({ openWallet: true,showOtherWallet: false, enteredAmount: 0 });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleWalletClose();
      };
    };

    const handlePreOrderOpen = () => {
      this.listMaker();
      console.log(this.state.currenciesList3,this.state.currenciesList);
      this.setState({ openPreOrder: true,      showTomorrow: false,      show3Day: false,
        show4Day: false,
        show5Day: false,
        show6Day: false,
        show7Day: false,
        selectedPreOrder: (this.state.showTomorrow)?(this.state.currenciesList3[this.state.currenciesList3Selected]?this.state.currenciesList3[this.state.currenciesList3Selected]['date']:null):(this.state.currenciesList && (this.state.currenciesList.length - 1)>=this.state.currenciesListSelected)?this.state.currenciesList[this.state.currenciesListSelected]['date']:((this.state.currenciesList3[this.state.currenciesList3Selected])?this.state.currenciesList3[this.state.currenciesList3Selected]['date']:null) });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handlePreOrderClose();
      };
    };

    const handleClickAddCardOpen = () => {
      this.setState({ openAddCard: true,newCard: {
        cardNo: '',
        cvv:'',
        month: '',
        year: '',
        expiry: '',
        name: ''
      } });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleAddCardClose();
      };
    };

    const multiFactor = () => {
      var factor = 1.0;
      if(this.state.selectedType < 2) {
        if(this.state.businessData.enableIncrease != null &&
            this.state.businessData.enableIncrease && this.state.businessData.increasePercentage != null &&
            this.state.businessData.increasePercentage) {
              factor = 1 + (this.state.businessData.increasePercentage/ 100);
            }
      }
      return factor;
    }

        const getOriginal = () => {
      var returnValue = 0.0;
      var cart =  this.state.cart;
      for(var i=0;i<cart['item'].length;i++) {
      if(cart['item'][i]['originalPrice']!=null) {
        returnValue = returnValue + (cart['item'][i]['originalPrice'] * cart['item'][i]['quantity']) + 0.0;
      } else {
        returnValue = returnValue + (cart['item'][i]['price'] * cart['item'][i]['quantity']) + 0.0;
      }
    }
      return returnValue;
    }

    const handleClickOpenVariable = (product) => {
      if (product['customize'] != null) {
        // console.log(product);
        var productIds = [];
        var tempOrder = {
          name: product['name'],
          nameEng: product['nameEng'] && strings.language === 'en' ? product['nameEng']: product['name'],
          time: product['averageTime'],
          id: product['id'],
          perKg: product['perKg'] ?? false,
          riesgoDeDerrame: product['riesgoDeDerrame'] ?? false,
          extraDelivery: product['extraDelivery'] ?? false,
          originalPrice:
            this.state.businessData.partner != null &&
            this.state.businessData.partner
              ? product['discountPercentage'] != null &&
                product['discountPercentage'] > 0 &&
                moment().isBefore(moment(product['discountedTill'].toDate()))
                ? product['price'] -
                  (product['price'] * product['discountPercentage']) / 100
                : product['price']
              : (product['discountPercentage'] != null &&
                product['discountPercentage'] > 0 &&
                moment().isBefore(moment(product['discountedTill'].toDate()))
                  ? product['price'] -
                    (product['price'] * product['discountPercentage']) / 100
                  : product['price']) +
                (product['discountPercentage'] != null &&
                product['discountPercentage'] > 0 &&
                moment().isBefore(moment(product['discountedTill'].toDate()))
                  ? product['price'] -
                    (product['price'] * product['discountPercentage']) / 100
                  : product['price']) *
                  (this.state.businessData.tax / 100),
          price:
            Number(((this.state.businessData.partner != null &&
            this.state.businessData.partner
              ? product['discountPercentage'] != null &&
                product['discountPercentage'] > 0 &&
                moment().isBefore(moment(product['discountedTill'].toDate()))
                ? product['price'] -
                  (product['price'] * product['discountPercentage']) / 100
                : product['price']
              : (product['discountPercentage'] != null &&
                product['discountPercentage'] > 0 &&
                moment().isBefore(moment(product['discountedTill'].toDate()))
                  ? product['price'] -
                    (product['price'] * product['discountPercentage']) / 100
                  : product['price']) +
                (product['discountPercentage'] != null &&
                product['discountPercentage'] > 0 &&
                moment().isBefore(moment(product['discountedTill'].toDate()))
                  ? product['price'] -
                    (product['price'] * product['discountPercentage']) / 100
                  : product['price']) *
                  (this.state.businessData.tax / 100)) * multiFactor()).toFixed((multiFactor()>1)?0:2)),
          quantity: 1,
          addons: 0,
        };
        for (var j = 0; j < product['customize'].length; j++) {
          // print(menus2[i -
          //     3]['products'][index]['customize'][j]['conditionOption']);
          if (
            (product['customize'][j]['enabled'] ||
              (!product['customize'][j]['enabled'] &&
                product['customize'][j]['willBeAvailableAt'] != null &&
                moment(this.state.selectedDate).isAfter(
                  moment(product['customize'][j]['willBeAvailableAt'].toDate())
                ))) &&
            (product['customize'][j]['conditioned'] == null ||
              !product['customize'][j]['conditioned'] ||
              (product['customize'][j]['conditioned'] != null &&
                product['customize'][j]['conditioned'] &&
                productIds.includes(
                  product['customize'][j]['conditionOption']
                )))
          ) {
            if (
              product['customize'][j]['min'] != null &&
              (tempOrder['customize'] == null ||
                tempOrder['customize'].length === j ||
                tempOrder['customize'][j]['options'].length <
                  product['customize'][j]['min'])
            ) {
              //print('hee');
              if (product['customize'][j]['min'] === 0) {
                if (tempOrder['customize'] == null) {
                  //print('heedd');
                  tempOrder['customize'] = [
                    {
                      name: product['customize'][j]['name'],
                      nameEng: product['customize'][j]['nameEng'] && strings.language === 'en' ? product['customize'][j]['nameEng']: product['customize'][j]['name'],
                      id: product['customize'][j]['id'],
                      options: [],
                    },
                  ];
                } else if (tempOrder['customize'].length === j) {
                  tempOrder['customize'].push({
                    name: product['customize'][j]['name'],
                    nameEng: product['customize'][j]['nameEng'] && strings.language === 'en' ? product['customize'][j]['nameEng']: product['customize'][j]['name'],
                    id: product['customize'][j]['id'],
                    options: [],
                  });
                  //                                                                                                } else if(tempOrder['customize'][j]['options'].find((tord)=> tord['id'].contains(restaurant[0]
                  //                                                                                                    .menu[i -
                  //                                                                                                    3]['products'][index]['customize'][j]['options'][temp]['id']))){
                  //                                                                                                  setState(() {
                  //                                                                                                    tempOrder['price'] = tempOrder['price'] + restaurant[0]
                  //                                                                                                        .menu[i -
                  //                                                                                                        3]['products'][index]['customize'][j]['options'][temp]['price'];
                  //                                                                                                    tempOrder['addons'] = tempOrder['addons'] + 1;
                  //                                                                                                  });
                }
              }
              for (
                var temp = 0;
                temp < product['customize'][j]['min'];
                temp++
              ) {
                //                                                                                                restaurant[0]
                //                                                                                                    .menu[i -
                //                                                                                                    3]['products'][index]['customize'][j]['options'][temp]['selected'] =
                //                                                                                                true;
                if (tempOrder['customize'] == null) {
                  //print('heedd');
                  tempOrder['customize'] = [
                    {
                      name: product['customize'][j]['name'],
                      nameEng: product['customize'][j]['nameEng'] && strings.language === 'en' ? product['customize'][j]['nameEng']: product['customize'][j]['name'],
                      id: product['customize'][j]['id'],
                      options: [
                        {
                          name: product['customize'][j]['options'][temp][
                            'name'
                          ],
                          nameEng: product['customize'][j]['options'][temp]['nameEng'] && strings.language === 'en' ? product['customize'][j]['options'][temp]['nameEng']: product['customize'][j]['options'][temp][
                            'name'
                          ],
                          id: product['customize'][j]['options'][temp]['id'],
                          originalPrice:
                            this.state.businessData.partner != null &&
                            this.state.businessData.partner
                              ? product['customize'][j]['options'][temp][
                                  'price'
                                ]
                              : product['customize'][j]['options'][temp][
                                  'price'
                                ] +
                                product['customize'][j]['options'][temp][
                                  'price'
                                ] *
                                  (this.state.businessData.tax / 100),
                          price:
                            Number(((this.state.businessData.partner != null &&
                            this.state.businessData.partner
                              ? product['customize'][j]['options'][temp][
                                  'price'
                                ]
                              : product['customize'][j]['options'][temp][
                                  'price'
                                ] +
                                product['customize'][j]['options'][temp][
                                  'price'
                                ] *
                                  (this.state.businessData.tax / 100)) * multiFactor()).toFixed(multiFactor()>1?0:2)),
                        },
                      ],
                    },
                  ];
                  productIds.push(
                    product['customize'][j]['options'][temp]['id']
                  );
                  tempOrder['price'] =
                    tempOrder['price'] +
                    Number(((this.state.businessData.partner != null &&
                    this.state.businessData.partner
                      ? product['customize'][j]['options'][temp]['price']
                      : product['customize'][j]['options'][temp]['price'] +
                        product['customize'][j]['options'][temp]['price'] *
                          (this.state.businessData.tax / 100))* multiFactor()).toFixed(multiFactor()>1?0:2));
                          tempOrder['originalPrice'] =
                    tempOrder['originalPrice'] +
                    (this.state.businessData.partner != null &&
                    this.state.businessData.partner
                      ? product['customize'][j]['options'][temp]['price']
                      : product['customize'][j]['options'][temp]['price'] +
                        product['customize'][j]['options'][temp]['price'] *
                          (this.state.businessData.tax / 100));
                  tempOrder['addons'] = tempOrder['addons'] + 1;
                } else if (tempOrder['customize'].length === j) {
                  tempOrder['customize'].push({
                    name: product['customize'][j]['name'],
                    nameEng: product['customize'][j]['nameEng'] && strings.language === 'en' ? product['customize'][j]['nameEng']: product['customize'][j]['name'],
                    id: product['customize'][j]['id'],
                    options: [
                      {
                        name: product['customize'][j]['options'][temp]['name'],
                        nameEng: product['customize'][j]['options'][temp]['nameEng'] && strings.language === 'en' ? product['customize'][j]['options'][temp]['nameEng']: product['customize'][j]['options'][temp]['name'],
                        id: product['customize'][j]['options'][temp]['id'],
                        originalPrice:
                          this.state.businessData.partner != null &&
                          this.state.businessData.partner
                            ? product['customize'][j]['options'][temp]['price']
                            : product['customize'][j]['options'][temp][
                                'price'
                              ] +
                              product['customize'][j]['options'][temp][
                                'price'
                              ] *
                                (this.state.businessData.tax / 100),
                        price:
                          Number(((this.state.businessData.partner != null &&
                          this.state.businessData.partner
                            ? product['customize'][j]['options'][temp]['price']
                            : product['customize'][j]['options'][temp][
                                'price'
                              ] +
                              product['customize'][j]['options'][temp][
                                'price'
                              ] *
                                (this.state.businessData.tax / 100))* multiFactor()).toFixed(multiFactor()>1?0:2)),
                      },
                    ],
                  });
                  //                                                                                                } else if(tempOrder['customize'][j]['options'].find((tord)=> tord['id'].contains(restaurant[0]
                  //                                                                                                    .menu[i -
                  //                                                                                                    3]['products'][index]['customize'][j]['options'][temp]['id']))){
                  productIds.push(
                    product['customize'][j]['options'][temp]['id']
                  );
                  tempOrder['price'] =
                    tempOrder['price'] +
                    Number(((this.state.businessData.partner != null &&
                    this.state.businessData.partner
                      ? product['customize'][j]['options'][temp]['price']
                      : product['customize'][j]['options'][temp]['price'] +
                        product['customize'][j]['options'][temp]['price'] *
                          (this.state.businessData.tax / 100))* multiFactor()).toFixed(multiFactor()>1?0:2));
                  tempOrder['originalPrice'] =
                    tempOrder['originalPrice'] +
                    (this.state.businessData.partner != null &&
                    this.state.businessData.partner
                      ? product['customize'][j]['options'][temp]['price']
                      : product['customize'][j]['options'][temp]['price'] +
                        product['customize'][j]['options'][temp]['price'] *
                          (this.state.businessData.tax / 100));
                  tempOrder['addons'] = tempOrder['addons'] + 1;
                  //                                                                                                  setState(() {
                  //                                                                                                    tempOrder['price'] = tempOrder['price'] + restaurant[0]
                  //                                                                                                        .menu[i -
                  //                                                                                                        3]['products'][index]['customize'][j]['options'][temp]['price'];
                  //                                                                                                    tempOrder['addons'] = tempOrder['addons'] + 1;
                  //                                                                                                  });
                } else {
                  tempOrder['customize'][j]['options'].push({
                    name: product['customize'][j]['options'][temp]['name'],
                    nameEng: product['customize'][j]['options'][temp]['nameEng'] && strings.language === 'en' ? product['customize'][j]['options'][temp]['nameEng']: product['customize'][j]['options'][temp]['name'],
                    id: product['customize'][j]['options'][temp]['id'],
                    originalPrice:
                      this.state.businessData.partner != null &&
                      this.state.businessData.partner
                        ? product['customize'][j]['options'][temp]['price']
                        : product['customize'][j]['options'][temp]['price'] +
                          product['customize'][j]['options'][temp]['price'] *
                            (this.state.businessData.tax / 100),
                    price:
                      Number(((this.state.businessData.partner != null &&
                      this.state.businessData.partner
                        ? product['customize'][j]['options'][temp]['price']
                        : product['customize'][j]['options'][temp]['price'] +
                          product['customize'][j]['options'][temp]['price'] *
                            (this.state.businessData.tax / 100))* multiFactor()).toFixed(multiFactor()>1?0:2)),
                  });

                  productIds.push(
                    product['customize'][j]['options'][temp]['id']
                  );
                  tempOrder['price'] =
                    tempOrder['price'] +
                    Number(((this.state.businessData.partner != null &&
                    this.state.businessData.partner
                      ? product['customize'][j]['options'][temp]['price']
                      : product['customize'][j]['options'][temp]['price'] +
                        product['customize'][j]['options'][temp]['price'] *
                          (this.state.businessData.tax / 100))* multiFactor()).toFixed(multiFactor()>1?0:2));
                  tempOrder['originalPrice'] =
                    tempOrder['originalPrice'] +
                    (this.state.businessData.partner != null &&
                    this.state.businessData.partner
                      ? product['customize'][j]['options'][temp]['price']
                      : product['customize'][j]['options'][temp]['price'] +
                        product['customize'][j]['options'][temp]['price'] *
                          (this.state.businessData.tax / 100));
                  tempOrder['addons'] = tempOrder['addons'] + 1;
                  //                                                                                                  setState(() {
                  //                                                                                                    tempOrder['price'] = tempOrder['price'] + restaurant[0]
                  //                                                                                                        .menu[i -
                  //                                                                                                        3]['products'][index]['customize'][j]['options'][temp]['price'];
                  //                                                                                                    tempOrder['addons'] = tempOrder['addons'] + 1;
                  //                                                                                                  });
                }
                //print(tempOrder);
              }
            }
          } else {
            if (tempOrder['customize'] == null) {
              //print('heedd');
              tempOrder['customize'] = [
                {
                  name: product['customize'][j]['name'],
                  nameEng: product['customize'][j]['nameEng'] && strings.language === 'en' ? product['customize'][j]['nameEng']: product['customize'][j]['name'],
                  id: product['customize'][j]['id'],
                  options: [],
                },
              ];
              // Future.delayed(Duration.zero, () {
              //   setState(() {});
              // });
            } else if (tempOrder['customize'].length === j) {
              tempOrder['customize'].push({
                name: product['customize'][j]['name'],
                nameEng: product['customize'][j]['nameEng'] && strings.language === 'en' ? product['customize'][j]['nameEng']: product['customize'][j]['name'],
                id: product['customize'][j]['id'],
                options: [],
              });
              //                                                                                                } else if(tempOrder['customize'][j]['options'].find((tord)=> tord['id'].contains(restaurant[0]
              //                                                                                                    .menu[i -
              //                                                                                                    3]['products'][index]['customize'][j]['options'][temp]['id']))){
              //                                             Future.delayed(Duration.zero, () {
              //                                               setState(() {});
              //                                             });
              //                                                                                                  setState(() {
              //                                                                                                    tempOrder['price'] = tempOrder['price'] + restaurant[0]
              //                                                                                                        .menu[i -
              //                                                                                                        3]['products'][index]['customize'][j]['options'][temp]['price'];
              //                                                                                                    tempOrder['addons'] = tempOrder['addons'] + 1;
              //                                                                                                  });
            }
            var temp2 = 0;
            if (
              tempOrder['customize'] != null &&
              tempOrder['customize'].length > j
            ) {
              for (
                temp2 = 0;
                temp2 < tempOrder['customize'][j]['options'].length;
                temp2++
              ) {
                const index = productIds.indexOf(
                  tempOrder['customize'][j]['options'][temp2]['id']
                );
                if (index > -1) {
                  // only splice array when item is found
                  productIds.splice(index, 1); // 2nd parameter means remove one item only
                }
                // productIds.remove(tempOrder['customize'][j]['options'][temp2]['id']);
                tempOrder['price'] =
                  tempOrder['price'] -
                  tempOrder['customize'][j]['options'][temp2]['price'];
                tempOrder['addons'] = tempOrder['addons'] - 1;
              }
              if (temp2 === tempOrder['customize'][j]['options'].length) {
                tempOrder['customize'][j]['options'] = [];
              }
            }
            // productIds.add(menus2[i -
            //     3]['products'][index]['customize'][j]['options'][temp]['id']);
            // tempOrder['price'] =
            //     tempOrder['price'] +
            //         (menus2[i -
            //             3]['products'][index]['customize'][j]['options'][temp]['price'] *
            //             tempOrder['quantity']);
            // tempOrder['addons'] =
            //     tempOrder['addons'] + 1;
          }
        }
        this.setState({ tempOrder: tempOrder, productIds: productIds });
        this.setState({ selectedProduct: product });
        this.setState({ openVariable: true });
        window.history.pushState(null, '', window.location.href);

        window.onpopstate = () => {
          window.onpopstate = () => {};
          window.history.back();
          handleCloseVariable();
        };
      }
    };

    const handleClickOpenLogin = () => {
      this.setState({
        openLogin: true,
        showGuest: false,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneNumber: '',
        phoneCountry: '+52',
        showPassword: false,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleCloseLogin();
      };
    };

    const handleClickOpenPayment = () => {
      this.setState({
        showPayment: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleClosePayment();
      };
    };

    const handleClickOpenDiscount = () => {
      this.setState({
        showDiscount: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleCloseDiscount();
      };
    };

    const handleClickOpenRegister = () => {
      this.setState({
        openRegister: true,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneNumber: '',
        phoneCountry: '+52',
        showPassword: false,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleCloseRegister();
      };
    };
    const handleClickOpenAdditionalRegister = () => {
      this.setState({
        openAdditionalRegister: true,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneNumber: '',
        phoneCountry: '+52',
        showPassword: false,
      });
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        handleCloseAdditionalRegister();
      };
    };
    const handleCloseAdditionalRegister = () => {
      auth.signOut();
      this.setState({
        openAdditionalRegister: false,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneNumber: '',
        phoneCountry: '+52',
        showPassword: false,
      });
    };

    // const handleClickOpenRegisterGuest = () => {
    //   this.setState({
    //     showGuest: false,
    //     isGuest: false,
    //     showAdditionalDetails: true,
    //     guestDetails: {
    //       'name': '',
    //       'email': '',
    //       'phone': ''
    //     },
    //     password: '',
    //     email: '',
    //     fullName: '',
    //     otp: '',
    //     phoneNumber: '',
    //     phoneCountry: '+34',
    //     showPassword: false,
    //   });
    //   window.history.pushState(null, '', window.location.href);

    //   window.onpopstate = () => {
    //     window.onpopstate = () => {};
    //     window.history.back();
    //     handleCloseRegisterGuest();
    //   };
    // };

    const handleClickOpenOTP = () => {
      this.setState({ openOTP: true, showPassword: false });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        // window.history.back()
        // handleCloseVariable();
      };
    };

    // const handleToggle = () => {
    //   this.setState({ open: !this.state.open });
    // };
    const openAlert = (alertType, alertMsg) => {
      this.setState({
        openAlert: true,
        alertType: alertType,
        alertMsg: alertMsg,
      });
    };
    const handleClose = (value) => {
      this.setState({ openLocation: false });
      this.setState({ hasClickedLoc: false });
      this.setState({ autoLoc: '' });
      this.setState({ selectedValue: value });
      this.setState({
        selectLocationData: {
          id: '',
          name: strings['mi hogar'],
          address: '',
          geoAddress: '',
          addressNotes: '',
          latitude: this.state.defaultProps.center.lat,
          longitude: this.state.defaultProps.center.lng,
        },
      });
    };
    const handleCardClose = (value) => {
      this.setState({ openCards: false });
    };
    const handleWalletClose = (value) => {
      this.setState({ openWallet: false });
    };
    const handleMinimumClose = (value) => {
      this.setState({ showMinimum: false });
    };
    const handleAlertClose = (value) => {
      this.setState({ openDialogAlert: false });
    };
    const handleAlertCancelClose = (value) => {
      this.setState({ openDialogCancel: false });
    };
    const handlePreOrderClose = (value) => {
      this.setState({ openPreOrder: false,      showTomorrow: false,      show3Day: false,
        show4Day: false,
        show5Day: false,
        show6Day: false,
        show7Day: false,
        selectedPreOrder: null, });
    };

    const handleAddCardClose = (value) => {
      this.setState({ openAddCard: false });
      // this.setState({ hasClickedLoc: false });
      // this.setState({ autoLoc: '' });
      // this.setState({ selectedValue: value });
      // this.setState({
      //   selectLocationData: {
      //     id: '',
      //     name: strings['mi hogar'],
      //     address: '',
      //     geoAddress: '',
      //     addressNotes: '',
      //     latitude: this.state.defaultProps.center.lat,
      //     longitude: this.state.defaultProps.center.lng,
      //   },
      // });
    };

    const handleCloseVariable = () => {
      this.setState({ selectedProduct: null });
      this.setState({ tempOrder: {}, productIds: [] });
      this.setState({ openVariable: false });
    };

    const handleClosePayment = () => {
      this.setState({
        showPayment: false,
      });
    };

    const handleCloseDiscount = () => {
      this.setState({
        showDiscount: false,
        showDiscountIsBusiness: false,
      });
    };

    const handleCloseLogin = () => {
      this.setState({
        openLogin: false,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneNumber: '',
        phoneCountry: '+52',
        showPassword: false,
      });
    };
    const handleCloseRegister = () => {
      this.setState({
        openRegister: false,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneNumber: '',
        phoneCountry: '+52',
        showPassword: false,
      });
    };
    const handleCloseOTP = () => {
      this.setState({
        openOTP: false,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneCountry: '+52',
        phoneNumber: '',
        showPassword: false,
      });
    };
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* <MenuItem>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={4} color="error">
              <ShoppingCartRoundedIcon />
            </Badge>
          </IconButton>
          <p>Cart</p>
        </MenuItem> */}
        {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            style={{
              background: 'white',
              boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
              width: '40px',
              height: '40px',
              marginRight: '0px',
              marginTop: this.state.width > 1000 ? '0' : '0',
            }}
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            {this.state.photoURL ? (
              <img
                style={{
                  verticalAlign: 'middle',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                }}
                src={this.state.photoURL}
              />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <p>{strings['Perfil']}</p>
        </MenuItem>
      </Menu>
    );
    const refs = this.state.menu.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

    const walletMax= ()=>{
      var usable = 0;
      var balance = this.state.balance;
      if (balance <= grandTotalBefore()) {
        usable = balance;
      } else {
        usable = grandTotalBefore();
      }
      console.log(grandTotalBefore());
      console.log(usable);
      return Number(usable.toFixed(2));
    }
    const sendNotification = async (receiver, msg, title, id, order) => {
      //List tokenData = await getToken(receiver);
      //final FirebaseFirestore _db = FirebaseFirestore.instance;
      const q = query(
        collection(db, 'business_users'),
        where('businessId', '==', receiver)
      );
      await getDocs(q).then((data) => {
        //print('size: ' + data.size.toString());
        data.docs.forEach(async (element) => {
          // console.log('sending notifications! ',element.data()['email'].toString());
          if (
            element.data()['token'] != null &&
            element.data()['token'] !== ''
          ) {
            //tokenData.add((element.data() as Map)['token']);
            //print('token : ${(element.data() as Map)['token']}');
            //print('msg: $msg');
            //print('title:$title');
            //print('id:$id');
      var data2 = {
        click_action: 'FLUTTER_NOTIFICATION_CLICK',
        orderId: id,
        for: 'restaurant',
        isOrder: 'yes',
        status: 'done',
        isPreOrder: order['isPreOrder'].toString(),
        title: title,
        body: msg,
              token: element.data()['token'],
      };

      await axios
              .post('https://senor-order-85fa55d895ba.herokuapp.com/business/send/', null,{
          params: data2
        })
        .then(async (response) => {
          // console.log(response);
        });
            // var uri = Uri.https(herokuOrderAppHttp, '/business/send/', data2);

            // var response = await http.post(uri, headers: {
            //   HttpHeaders.contentTypeHeader: 'application/x-www-form-urlencoded',
            // });

            // if(response.statusCode)
            // // final headers = {
            // //   'content-type': 'application/json',
            // //   'Authorization':
            // //       'Bearer AAAAnlq9nws:APA91bG4geMfGAupCddUNthlMn9MD0Td2N11gG91FE_bf3aBBwXuP_KZLpoDmQ974LMVDd4hl2BpzCfAfKDKl3yvhpaaHm05mXHfKF3usIzu1E1FhFPkTaTB0f5SwAkJGOsOa2ttKUOA'
            // // };
            //
            // BaseOptions options = new BaseOptions(
            //   connectTimeout: 5000,
            //   receiveTimeout: 3000,
      // );

            // try {
            //   final response = await Dio().post(
            //     'https://devy-app.herokuapp.com/business/send/',
            //     queryParameters: {
            //       "message": {
            //         "click_action": "FLUTTER_NOTIFICATION_CLICK",
            //         "orderId": id,
            //         'for': 'restaurant',
            //         'isOrder': 'yes',
            //         "status": "done",
            //         'title': title,
            //         'body': msg
            //       },
            //       "token": "${(element.data() as Map)['token']}"
            //     },
            //     options: Options(headers: {
            //       HttpHeaders.contentTypeHeader: 'application/x-www-form-urlencoded'
            //     }),
            //   );
            //   if (response.statusCode == 200) {
            //
            //   } else {
            //     print('failed');
            //     //_onErrorPressed(context, 'something went wrong');
            //   }
            // } on DioError catch (e) {
            //   print('exception $e');
            //   //_onErrorPressed(context, 'something went wrong');
            // }
          }

          // try {
          //   final response = await Dio(options).post(
          //       'https://fcm.googleapis.com/v1/projects/devyxyz/messages:send',
          //       data: data);
          //   if (response.statusCode == 200) {
          //   } else {
          //     print('notification sending failed');
          //   }
          // } on DioError catch (e) {
          //   print('exception $e');
          //   print('exception ${e.message}');
          // }
        });
      });
    };
    const findTotal3 = (id) => {
      var total1 = 0;
      //print(cart['item'].filter((item)=>item['id'].toString().contains(id)));
      this.state.cart['item']
        .filter((item) => item['id'].toString().includes(id))
        .forEach((itemss) => (total1 = total1 + itemss['quantity']));
      return total1.toString();
    };

    const findTotal = (id, i) => {
      var total1 = (this.state.cart['item'][id]['customize'][i]['nameEng'] && strings.language === 'en' ? this.state.cart['item'][id]['customize'][i]['nameEng']:this.state.cart['item'][id]['customize'][i]['name']) + ': ';
      for (
        var j = 0;
        j < this.state.cart['item'][id]['customize'][i]['options'].length;
        j++
      ) {
        if (j === 0) {
          total1 =
            total1 +
            (this.state.cart['item'][id]['customize'][i]['options'][j]['nameEng'] && strings.language === 'en' ? this.state.cart['item'][id]['customize'][i]['options'][j]['nameEng']:this.state.cart['item'][id]['customize'][i]['options'][j]['name']);
        } else {
          total1 =
            total1 +
            ',' +
            (this.state.cart['item'][id]['customize'][i]['options'][j]['nameEng'] && strings.language === 'en' ? this.state.cart['item'][id]['customize'][i]['options'][j]['nameEng']:this.state.cart['item'][id]['customize'][i]['options'][j]['name']);
        }
      }
      return total1;
    };

    const checkOffer = () => {
      var offerCode = this.state.offerCode;
      var offerId = this.state.offerId;
      var offerSuccess = this.state.offerSuccess;
      var offerErrorText = this.state.offerErrorText;
      var offerValue = this.state.offerValue;
      var cashbackValue = this.state.cashbackValue;
      var offerInd = this.state.offerInd;
      var isOfferApplied = this.state.isOfferApplied;
      var isOfferError = this.state.isOfferError;
      var isFreeDelivery = this.state.isFreeDelivery;
      var offersUsed = this.state.offersUsed;
      var devyOffers = this.state.offers;
      var cart = this.state.cart;
      var delivery = this.state.delivery;
      var offerValue2 = this.state.offerValue2;

      // setState(() {
      if (offerInd != null) {
        var totalCost = this.getTotalCost(cart,devyOffers[offerInd]);
        this.setState({
          selectedOffer: devyOffers[offerInd],
        });
        if (
          offersUsed.find(
            (element) => element['id'] === devyOffers[offerInd]['id']
          ) == null
        ) {
          if (
            devyOffers[offerInd]['isItemOffer'] != null &&
            devyOffers[offerInd]['isItemOffer']
          ) {
            offerValue = 0;
            cashbackValue = 0.0;
            // console.log(devyOffers[offerInd]['items']);
            var testList = devyOffers[offerInd]['items'];
            for (var ij = 0; ij < cart['item'].length; ij++) {
              //print(testList.filter((element34) => element34['id'].contains(cart['item'][ij]['id'])));
              if (
                testList.find(
                  (element34) => element34['id'] === cart['item'][ij]['id']
                ) != null
              ) {
                var tempValue = testList.find(
                  (element) =>
                    element['id'].toString() ===
                    cart['item'][ij]['id'].toString()
                );
                if (
                  tempValue['id'] === cart['item'][ij]['id'] &&
                  Math.trunc(
                    cart['item'][ij]['quantity'] /
                      (devyOffers[offerInd]['freeItem'] +
                        devyOffers[offerInd]['buyItem'])
                  ) > 0
                ) {
                  offerValue =
                    offerValue +
                    cart['item'][ij]['price'] *
                      Math.trunc(
                        cart['item'][ij]['quantity'] /
                          (devyOffers[offerInd]['freeItem'] +
                            devyOffers[offerInd]['buyItem'])
                      ) +
                    0.0;
                }
              }
            }
            if (offerValue > 0) {
              isOfferApplied = true;
              offerInd = offerInd;
              isFreeDelivery = false;
              isOfferError = false;
              offerErrorText = '';
              offerCode = devyOffers[offerInd]['code'];
              offerId = devyOffers[offerInd]['id'];
              offerSuccess = strings['Su cupón ha sido agregado con éxito.'];
            } else {
              isOfferApplied = false;
              isOfferError = true;
              isFreeDelivery = false;
              offerInd = offerInd;
              offerErrorText =
                strings['Añade al menos '] +
                (
                  devyOffers[offerInd]['freeItem'] +
                  devyOffers[offerInd]['buyItem']
                ).toString() +
                strings[' productos promocionales.'];
              offerCode = devyOffers[offerInd]['code'];
              offerSuccess = '';
              offerValue = 0;
              cashbackValue = 0.0;
              offerId = '';
            }
          } else if (
            totalCost >= devyOffers[offerInd]['minimumItemValue']
          ) {
            if (
              devyOffers[offerInd]['maximumItemValue'] === 0 ||
              totalCost <= devyOffers[offerInd]['maximumItemValue']
            ) {
              isOfferApplied = true;
              isFreeDelivery = false;
              isOfferError = false;
              offerErrorText = '';
              offerCode = devyOffers[offerInd]['code'];
              offerId = devyOffers[offerInd]['id'];
              //storage.setItem('cart', jsonEncode(cart));
              offerSuccess = strings['Su cupón ha sido agregado con éxito.'];
              if (devyOffers[offerInd]['freeDelivery']) {
                offerValue = (delivery + this.extraCharges()) + 0.0;
                cashbackValue = 0.0;
                isFreeDelivery = true;
              } else {
                isFreeDelivery = false;
                offerValue =
                  devyOffers[offerInd]['discountAmount'] +
                  0.0 +
                  (devyOffers[offerInd]['discountPercentage'] / 100) *
                  totalCost;
                if (offerValue < devyOffers[offerInd]['minimumDiscount']) {
                  offerValue = devyOffers[offerInd]['minimumDiscount'] + 0.0;
                } else if (
                  devyOffers[offerInd]['maximumDiscount'] !== 0 &&
                  offerValue > devyOffers[offerInd]['maximumDiscount']
                ) {
                  offerValue = devyOffers[offerInd]['maximumDiscount'] + 0.0;
                }
                cashbackValue =
                  devyOffers[offerInd]['cashbackAmount'] != null
                    ? devyOffers[offerInd]['cashbackAmount'] +
                      0.0 +
                      (((devyOffers[offerInd][
                        'cashbackMax'
                      ] != null && devyOffers[offerInd][
                        'cashbackMax'
                      ]>0) && ((devyOffers[offerInd][
                        'cashbackPercentage'
                      ] != null
                        ? (devyOffers[
                            offerInd
                          ][
                            'cashbackPercentage'
                          ] /
                            100) *
                          totalCost
                        : 0)>devyOffers[offerInd][
                          'cashbackMax'
                        ]))?devyOffers[offerInd][
                          'cashbackMax'
                        ]:(devyOffers[offerInd][
                          'cashbackPercentage'
                        ] != null
                          ? (devyOffers[
                              offerInd
                            ][
                              'cashbackPercentage'
                            ] /
                              100) *
                            totalCost
                          : 0))
                    : 0.0;
              }
            } else {
              isOfferApplied = false;
              isFreeDelivery = false;
              isOfferError = true;
              offerErrorText =
                strings['Los productos máximos deben ser $'] +
                devyOffers[offerInd]['maximumItemValue'].toString();
              offerCode = devyOffers[offerInd]['code'];
              offerSuccess = '';
              offerValue = 0;
              cashbackValue = 0.0;
              offerId = '';
            }
          } else if (
            totalCost < devyOffers[offerInd]['minimumItemValue']
          ) {
            isOfferApplied = false;
            isFreeDelivery = false;
            isOfferError = true;
            offerErrorText =
              strings['Los productos mínimos deben ser $'] +
              devyOffers[offerInd]['minimumItemValue'].toString();
            offerCode = devyOffers[offerInd]['code'];
            offerSuccess = '';
            offerValue = 0;
            cashbackValue = 0.0;
            offerId = '';
          }
        } else {
          if (
            offersUsed
              .filter((element) => element['id'] === devyOffers[offerInd]['id'])
              [
                offersUsed.filter(
                  (element) => element['id'] === devyOffers[offerInd]['id']
                ).length - 1
              ]['usedOn'].toMillis() +
              devyOffers[offerInd]['timeBetweenUses'] * 1000 <
            Date.now()
          ) {
            if (
              devyOffers[offerInd]['isItemOffer'] != null &&
              devyOffers[offerInd]['isItemOffer']
            ) {
              offerValue = 0;
              cashbackValue = 0.0;
              // console.log(devyOffers[offerInd]['items']);
              var testList = devyOffers[offerInd]['items'];
              for (var ij = 0; ij < cart['item'].length; ij++) {
                //print(testList.filter((element34) => element34['id'].contains(cart['item'][ij]['id'])));
                if (
                  testList.find(
                    (element34) => element34['id'] === cart['item'][ij]['id']
                  ) != null
                ) {
                  var tempValue = testList.find(
                    (element) =>
                      element['id'].toString() ===
                      cart['item'][ij]['id'].toString()
                  );
                  if (
                    tempValue['id'] === cart['item'][ij]['id'] &&
                    Math.trunc(
                      cart['item'][ij]['quantity'] /
                        (devyOffers[offerInd]['freeItem'] +
                          devyOffers[offerInd]['buyItem'])
                    ) > 0
                  ) {
                    offerValue =
                      offerValue +
                      cart['item'][ij]['price'] *
                        Math.trunc(
                          cart['item'][ij]['quantity'] /
                            (devyOffers[offerInd]['freeItem'] +
                              devyOffers[offerInd]['buyItem'])
                        ) +
                      0.0;
                  }
                }
              }
              if (offerValue > 0) {
                isOfferApplied = true;
                isFreeDelivery = false;
                offerInd = offerInd;
                isOfferError = false;
                offerErrorText = '';
                offerCode = devyOffers[offerInd]['code'];
                offerId = devyOffers[offerInd]['id'];
                offerSuccess = strings['Su cupón ha sido agregado con éxito.'];
              } else {
                isOfferApplied = false;
                isFreeDelivery = false;
                isOfferError = true;
                offerInd = offerInd;
                offerErrorText =
                  strings['Añade al menos '] +
                  (
                    devyOffers[offerInd]['freeItem'] +
                    devyOffers[offerInd]['buyItem']
                  ).toString() +
                  strings[' productos promocionales.'];
                offerCode = devyOffers[offerInd]['code'];
                offerSuccess = '';
                offerValue = 0;
                cashbackValue = 0.0;
                offerId = '';
              }
            } else if (
              totalCost >= devyOffers[offerInd]['minimumItemValue']
            ) {
              if (
                devyOffers[offerInd]['maximumItemValue'] === 0 ||
                totalCost <= devyOffers[offerInd]['maximumItemValue']
              ) {
                isOfferApplied = true;
                isFreeDelivery = false;
                isOfferError = false;
                offerErrorText = '';
                offerId = devyOffers[offerInd]['id'];
                offerCode = devyOffers[offerInd]['code'];
                offerSuccess = strings['Su cupón ha sido agregado con éxito.'];
                if (devyOffers[offerInd]['freeDelivery']) {
                  offerValue = (delivery + this.extraCharges()) + 0.0;
                  cashbackValue = 0.0;
                  isFreeDelivery = true;
                } else {
                  isFreeDelivery = false;
                  offerValue =
                    devyOffers[offerInd]['discountAmount'] +
                    0.0 +
                    (devyOffers[offerInd]['discountPercentage'] / 100) *
                    totalCost;
                  if (offerValue < devyOffers[offerInd]['minimumDiscount']) {
                    offerValue = devyOffers[offerInd]['minimumDiscount'] + 0.0;
                  } else if (
                    devyOffers[offerInd]['maximumDiscount'] !== 0 &&
                    offerValue > devyOffers[offerInd]['maximumDiscount']
                  ) {
                    offerValue = devyOffers[offerInd]['maximumDiscount'] + 0.0;
                  }
                  cashbackValue =
                    devyOffers[offerInd]['cashbackAmount'] != null
                      ? devyOffers[offerInd]['cashbackAmount'] +
                        0.0 +
                        (((devyOffers[offerInd][
                          'cashbackMax'
                        ] != null && devyOffers[offerInd][
                          'cashbackMax'
                        ]>0) && ((devyOffers[offerInd][
                          'cashbackPercentage'
                        ] != null
                          ? (devyOffers[
                              offerInd
                            ][
                              'cashbackPercentage'
                            ] /
                              100) *
                            totalCost
                          : 0)>devyOffers[offerInd][
                            'cashbackMax'
                          ]))?devyOffers[offerInd][
                            'cashbackMax'
                          ]:(devyOffers[offerInd][
                            'cashbackPercentage'
                          ] != null
                            ? (devyOffers[
                                offerInd
                              ][
                                'cashbackPercentage'
                              ] /
                                100) *
                              totalCost
                            : 0))
                      : 0.0;
                }
              } else {
                isOfferApplied = false;
                isFreeDelivery = false;
                isOfferError = true;
                offerErrorText =
                  strings['Los productos máximos deben ser $'] +
                  devyOffers[offerInd]['maximumItemValue'].toString();
                offerCode = devyOffers[offerInd]['code'];
                offerSuccess = '';
                offerValue = 0;
                cashbackValue = 0.0;
                offerId = '';
              }
            } else if (
              totalCost < devyOffers[offerInd]['minimumItemValue']
            ) {
              isOfferApplied = false;
              isFreeDelivery = false;
              isOfferError = true;
              offerErrorText =
                strings['Los productos mínimos deben ser $'] +
                devyOffers[offerInd]['minimumItemValue'].toString();
              offerCode = devyOffers[offerInd]['code'];
              offerSuccess = '';
              offerValue = 0;
              cashbackValue = 0.0;
              offerId = '';
            }
          } else {
            isOfferApplied = false;
            isFreeDelivery = false;
            isOfferError = true;
            offerErrorText =
              strings[
                'Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.'
              ];
            offerCode = devyOffers[offerInd]['code'];
            offerSuccess = '';
            offerValue = 0;
            cashbackValue = 0.0;
            offerId = '';
          }
        }
      }
      if(this.state.usingWallet) {

        if(offerValue2>walletMax()) {
          // setState(() {
            offerValue2= walletMax();
            this.setState({offerValue2: offerValue2});
            // cashbackValue =  0.0;
            console.log(offerValue2);
          // });
        }
        console.log(offerValue2 >= walletMax(),offerValue2,walletMax());
        if(offerValue2 === walletMax()) {
          this.setState({
            defaultCard: {
              cardNumber: 'COD',
            },
            showPayment: false,
          });
        }
      }
      // else if(offerInd2!=null) {
      //   if (offersUsed
      //       .find((
      //       element) =>
      //       element['id']
      //           .contains(
      //           devyOffers2[offerInd2]['id'])) ==
      //       null) {
      //     if(devyOffers2[offerInd2]['isItemOffer']!=null && devyOffers2[offerInd2]['isItemOffer']){
      //       offerValue2 = 0;
      //       cashbackValue =  0.0;
      //       print(devyOffers2[offerInd2]['items']);
      //       var testList = devyOffers2[offerInd2]['items'];
      //       for(var ij=0;ij<cart['item'].length;ij++) {
      //         //print(testList.filter((element34) => element34['id'].contains(cart['item'][ij]['id'])));
      //         if(testList.find((element34) => element34['id'].contains(cart['item'][ij]['id'])) !=
      //             null) {
      //               var tempValue = testList.find((element) => (element['id'].toString().contains(cart['item'][ij]['id'].toString())));
      //           if(tempValue['id']==cart['item'][ij]['id'] && (cart['item'][ij]['quantity']/(devyOffers2[offerInd2]['freeItem']+devyOffers2[offerInd2]['buyItem'])).truncate()>0) {
      //             offerValue2 = offerValue2 + (cart['item'][ij]['price'] * ((cart['item'][ij]['quantity']/(devyOffers2[offerInd2]['freeItem']+devyOffers2[offerInd2]['buyItem'])).truncate())) + 0.0;
      //           }
      //         }
      //       }
      //       if(offerValue2>0) {
      //         isOfferApplied2 = true;
      //         offerInd2 = offerInd2;
      //         isFreeDelivery2 = false;
      //         isOfferError2 = false;
      //         offerErrorText2 = '';
      //         offerCode2 = devyOffers2[offerInd2]['code'];
      //         offerId2 = devyOffers2[offerInd2]['id'];
      //         offerSuccess2 = "cart3077".tr();
      //       } else {
      //         isOfferApplied2 = false;
      //         isOfferError2 = true;
      //         isFreeDelivery2 = false;
      //         offerInd2 = offerInd2;
      //         // offerErrorText2 = 'cart3082'.tr(args:[(devyOffers2[offerInd2]['freeItem']+devyOffers2[offerInd2]['buyItem']).toString()]);
      //         offerCode2 = devyOffers2[offerInd2]['code'];
      //         offerSuccess2 = "";
      //         offerValue2 = 0;
      //         cashbackValue =  0.0;
      //         offerId2 = '';
      //       }
      //     }
      //     else if ((cart['totalCost'] - ((isFreeDelivery)?0:offerValue)) >=
      //         devyOffers2[offerInd2]['minimumItemValue']) {
      //       if (devyOffers2[offerInd2]['maximumItemValue'] ==
      //           0 ||
      //           (cart['totalCost'] - ((isFreeDelivery)?0:offerValue)) <=
      //               devyOffers2[offerInd2]['maximumItemValue']) {
      //         isOfferApplied2 =
      //         true;
      //         isFreeDelivery2 = false;
      //         isOfferError2 =
      //         false;
      //         offerErrorText2 =
      //         '';
      //         offerCode2 =
      //         devyOffers2[offerInd2]['code'];
      //         offerId2 =
      //         devyOffers2[offerInd2]['id'];
      //         //storage.setItem('cart', jsonEncode(cart));
      //         offerSuccess2 =
      //             "cart3108".tr();
      //         if (devyOffers2[offerInd2]['freeDelivery'] && !isFreeDelivery) {
      //           offerValue2 =
      //               delivery +
      //                   0.0;
      //           cashbackValue =  0.0;
      //           isFreeDelivery2 = true;
      //         } else if (devyOffers2[offerInd2]['freeDelivery'] && isFreeDelivery) {
      //           isOfferApplied2 = false;
      //           isOfferError2 = true;
      //           isFreeDelivery2 = false;
      //           isUsingInviteCode = false;
      //           isUsingSubscription = false;
      //           offerInd2 = offerInd2;
      //           offerErrorText2 = 'Oferta de envío gratis ya aplicada';
      //           offerCode2 = devyOffers2[offerInd2]['code'];
      //           offerSuccess2 = "";
      //           offerValue2 = 0;
      //           cashbackValue =  0.0;
      //           offerId2 = '';
      //         } else {
      //           isFreeDelivery2 = false;
      //           offerValue2 =
      //               devyOffers2[offerInd2]['discountAmount'] +
      //                   0.0 +
      //                   (devyOffers2[offerInd2]['discountPercentage'] /
      //                       100) *
      //                       (cart['totalCost'] - ((isFreeDelivery)?0:offerValue));
      //           if (offerValue2 <
      //               devyOffers2[offerInd2]['minimumDiscount']) {
      //             offerValue2 =
      //                 devyOffers2[offerInd2]['minimumDiscount'] +
      //                     0.0;
      //           } else
      //           if (devyOffers2[offerInd2]['maximumDiscount'] !=
      //               0 &&
      //               offerValue2 >
      //                   devyOffers2[offerInd2]['maximumDiscount']) {
      //             offerValue2 =
      //                 devyOffers2[offerInd2]['maximumDiscount'] +
      //                     0.0;
      //           }
      //           cashbackValue = devyOffers2[offerInd2]['cashbackAmount'] != null ? (devyOffers2[offerInd2]['cashbackAmount'] + 0.0 +((devyOffers2[offerInd2]['cashbackPercentage']!=null)?( (devyOffers2[offerInd2]['cashbackPercentage'] / 100) * (cart['totalCost'] - ((isFreeDelivery)?0:offerValue))):0)): 0.0;
      //         }
      //       } else {
      //         isOfferApplied2 =
      //         false;
      //         isFreeDelivery2 = false;
      //         isOfferError2 =
      //         true;
      //         offerErrorText2 =
      //             'cart3143'.tr() +
      //                 devyOffers2[offerInd2]['maximumItemValue']
      //                     .toString();
      //         offerCode2 =
      //         devyOffers2[offerInd2]['code'];
      //         offerSuccess2 =
      //         "";
      //         offerValue2 =
      //         0;
      //         cashbackValue =  0.0;
      //         offerId2 =
      //         '';
      //       }
      //     } else if ((cart['totalCost'] - ((isFreeDelivery)?0:offerValue)) <
      //         devyOffers2[offerInd2]['minimumItemValue']) {
      //       isOfferApplied2 =
      //       false;
      //       isFreeDelivery = false;
      //       isOfferError2 =
      //       true;
      //       offerErrorText2 =
      //           'cart3163'.tr() +
      //               devyOffers2[offerInd2]['minimumItemValue']
      //                   .toString();
      //       offerCode2 =
      //       devyOffers2[offerInd2]['code'];
      //       offerSuccess2 =
      //       "";
      //       offerValue2 =
      //       0;
      //       cashbackValue =  0.0;
      //       offerId2 =
      //       '';
      //     }
      //   } else {
      //     if ((offersUsed
      //         .filter((
      //         element) =>
      //         element['id']
      //             .contains(
      //             devyOffers2[offerInd2]['id']))
      //         .toList()[offersUsed
      //         .filter((
      //         element) =>
      //         element['id']
      //             .contains(
      //             devyOffers2[offerInd2]['id']))
      //         .toList()
      //         .length -
      //         1]['usedOn']
      //         .toMillis() +
      //         int.parse(
      //             devyOffers2[offerInd2]['timeBetweenUses']) * 1000) <
      //         (DateTime
      //             .now()
      //             .toMillis())) {
      //       if(devyOffers2[offerInd2]['isItemOffer']!=null && devyOffers2[offerInd2]['isItemOffer']){
      //         offerValue2 = 0;
      //         cashbackValue =  0.0;
      //         print(devyOffers2[offerInd2]['items']);
      //         var testList = devyOffers2[offerInd2]['items'];
      //         for(var ij=0;ij<cart['item'].length;ij++) {
      //           //print(testList.filter((element34) => element34['id'].contains(cart['item'][ij]['id'])));
      //           if(testList.find((element34) => element34['id'].contains(cart['item'][ij]['id'])) !=
      //               null) {
      //                 var tempValue = testList.find((element) => (element['id'].toString().contains(cart['item'][ij]['id'].toString())));
      //             if(tempValue['id']==cart['item'][ij]['id'] && (cart['item'][ij]['quantity']/(devyOffers2[offerInd2]['freeItem']+devyOffers2[offerInd2]['buyItem'])).truncate()>0) {
      //               offerValue2 = offerValue2 + (cart['item'][ij]['price'] * ((cart['item'][ij]['quantity']/(devyOffers2[offerInd2]['freeItem']+devyOffers2[offerInd2]['buyItem'])).truncate())) + 0.0;
      //             }
      //           }
      //         }
      //         if(offerValue2>0) {
      //           isOfferApplied2 = true;
      //           isFreeDelivery2 = false;
      //           offerInd2 = offerInd2;
      //           isOfferError2 = false;
      //           offerErrorText2 = '';
      //           offerCode2 = devyOffers2[offerInd2]['code'];
      //           offerId2 = devyOffers2[offerInd2]['id'];
      //           offerSuccess2 = "cart3221".tr();
      //         } else {
      //           isOfferApplied2 = false;
      //           isFreeDelivery2 = false;
      //           isOfferError2 = true;
      //           offerInd2 = offerInd2;
      //           // offerErrorText2 = 'cart3226'.tr(args:[(devyOffers2[offerInd2]['freeItem']+devyOffers2[offerInd2]['buyItem']).toString()]);
      //           offerCode2 = devyOffers2[offerInd2]['code'];
      //           offerSuccess2 = "";
      //           offerValue2 = 0;
      //           cashbackValue =  0.0;
      //           offerId2 = '';
      //         }
      //       }
      //       else if ((cart['totalCost'] - ((isFreeDelivery)?0:offerValue)) >=
      //           devyOffers2[offerInd2]['minimumItemValue']) {
      //         if (devyOffers2[offerInd2]['maximumItemValue'] ==
      //             0 ||
      //             (cart['totalCost'] - ((isFreeDelivery)?0:offerValue)) <=
      //                 devyOffers2[offerInd2]['maximumItemValue']) {
      //           isOfferApplied2 =
      //           true;
      //           isFreeDelivery2 = false;
      //           isOfferError2 =
      //           false;
      //           offerErrorText2 =
      //           '';
      //           offerId2 =
      //           devyOffers2[offerInd2]['id'];
      //           offerCode2 =
      //           devyOffers2[offerInd2]['code'];
      //           offerSuccess2 =
      //               "cart3251".tr();
      //           if (devyOffers2[offerInd2]['freeDelivery'] && !isFreeDelivery) {
      //             offerValue2 =
      //                 delivery +
      //                     0.0;
      //             cashbackValue =  0.0;
      //             isFreeDelivery2 = true;
      //           } else if (devyOffers2[offerInd2]['freeDelivery'] && isFreeDelivery) {
      //             isOfferApplied2 = false;
      //             isOfferError2 = true;
      //             isFreeDelivery2 = false;
      //             isUsingInviteCode = false;
      //             isUsingSubscription = false;
      //             offerInd2 = offerInd2;
      //             offerErrorText2 = 'Oferta de envío gratis ya aplicada';
      //             offerCode2 = devyOffers2[offerInd2]['code'];
      //             offerSuccess2 = "";
      //             offerValue2 = 0;
      //             cashbackValue =  0.0;
      //             offerId2 = '';
      //           } else {
      //             isFreeDelivery2 = false;
      //             offerValue2 =
      //                 devyOffers2[offerInd2]['discountAmount'] +
      //                     0.0 +
      //                     (devyOffers2[offerInd2]['discountPercentage'] /
      //                         100) *
      //                         (cart['totalCost'] - ((isFreeDelivery)?0:offerValue));
      //             if (offerValue2 <
      //                 devyOffers2[offerInd2]['minimumDiscount']) {
      //               offerValue2 =
      //                   devyOffers2[offerInd2]['minimumDiscount'] +
      //                       0.0;
      //             } else
      //             if (devyOffers2[offerInd2]['maximumDiscount'] !=
      //                 0 &&
      //                 offerValue2 >
      //                     devyOffers2[offerInd2]['maximumDiscount']) {
      //               offerValue2 =
      //                   devyOffers2[offerInd2]['maximumDiscount'] +
      //                       0.0;
      //             }
      //             cashbackValue = devyOffers2[offerInd2]['cashbackAmount'] != null ? (devyOffers2[offerInd2]['cashbackAmount'] + 0.0 +((devyOffers2[offerInd2]['cashbackPercentage']!=null)?( (devyOffers2[offerInd2]['cashbackPercentage'] / 100) * (cart['totalCost'] - ((isFreeDelivery)?0:offerValue))):0)): 0.0;
      //           }
      //         } else {
      //           isOfferApplied2 =
      //           false;
      //           isFreeDelivery2 = false;
      //           isOfferError2 =
      //           true;
      //           offerErrorText2 =
      //               'cart3286'.tr() +
      //                   devyOffers2[offerInd2]['maximumItemValue']
      //                       .toString();
      //           offerCode2 =
      //           devyOffers2[offerInd2]['code'];
      //           offerSuccess2 =
      //           "";
      //           offerValue2 =
      //           0;
      //           cashbackValue = 0.0;
      //           offerId2 =
      //           '';
      //         }
      //       } else if((cart['totalCost'] - ((isFreeDelivery)?0:offerValue)) <
      //           devyOffers2[offerInd2]['minimumItemValue']) {
      //         isOfferApplied2 =
      //         false;
      //         isFreeDelivery2 = false;
      //         isOfferError2 =
      //         true;
      //         offerErrorText2 =
      //             'cart3306'.tr() +
      //                 devyOffers2[offerInd2]['minimumItemValue']
      //                     .toString();
      //         offerCode2 =
      //         devyOffers2[offerInd2]['code'];
      //         offerSuccess2 =
      //         "";
      //         offerValue2 =
      //         0;
      //         cashbackValue =  0.0;
      //         offerId2 =
      //         '';
      //       }
      //     } else {
      //       isOfferApplied2 =
      //       false;
      //       isFreeDelivery2 = false;
      //       isOfferError2 =
      //       true;
      //       offerErrorText2 =
      //           'cart3325'.tr();
      //       offerCode2 =
      //       devyOffers2[offerInd2]['code'];
      //       offerSuccess2 =
      //       "";
      //       offerValue2 =
      //       0;
      //       cashbackValue =  0.0;
      //       offerId2 =
      //       '';
      //     }
      //   }
      // }
      // });

      this.setState({
        offerCode: offerCode,
        offerId: offerId,
        offerSuccess: offerSuccess,
        offerErrorText: offerErrorText,
        offerValue: offerValue,
        offerValue2: offerValue2,
        cashbackValue: cashbackValue,
        offerInd: offerInd,
        isOfferApplied: isOfferApplied,
        isOfferError: isOfferError,
        isFreeDelivery: isFreeDelivery,
        offersUsed: offersUsed,
        offers: devyOffers,
        cart: cart,
        delivery: delivery,
      });
      // console.log('he', {
      //   offerCode: offerCode,
      //   offerId: offerId,
      //   offerSuccess: offerSuccess,
      //   offerErrorText: offerErrorText,
      //   offerValue: offerValue,
      //   cashbackValue: cashbackValue,
      //   offerInd: offerInd,
      //   isOfferApplied: isOfferApplied,
      //   isOfferError: isOfferError,
      //   isFreeDelivery: isFreeDelivery,
      //   offersUsed: offersUsed,
      //   offers: devyOffers,
      //   cart: cart,
      //   delivery: delivery,
      // });
    };

    const findTotal2 = () => {
      var serviceFee =
      (this.state.businessData.driverServiceFee
        ? this.state.businessData.driverServiceFee
        : 0) +
      (this.state.cart['totalCost'] *
        (this.state.businessData.driverServicePercentage
          ? this.state.businessData.driverServicePercentage
          : 0)) /
        100 +
      0.0;
      var total1 = (this.state.businessData.name?this.state.businessData.name:'')+' ';
      for (var j = 0; j < this.state.cart['item'].length; j++) {
        if (j === 0) {
          total1 =
            total1 +
            this.state.cart['item'][j]['quantity'].toString() +
            ' X ' +
            this.state.cart['item'][j]['name'] +
            ' - $' +
            (
              this.state.cart['item'][j]['price'] *
              this.state.cart['item'][j]['quantity']
            ).toFixed(2);
        } else {
          total1 =
            total1 +
            ',' +
            this.state.cart['item'][j]['quantity'].toString() +
            ' X ' +
            this.state.cart['item'][j]['name'] +
            ' - $' +
            (
              this.state.cart['item'][j]['price'] *
              this.state.cart['item'][j]['quantity']
            ).toFixed(2);
        }
      }
      total1 =
        total1 + strings[' Costo de envío: '] + (this.state.delivery + (  this.extraCharges())).toFixed(2);
        total1 =
        total1 + strings[' Tarifa de servicio: '] + serviceFee.toFixed(2);
      total1 =
        total1 + strings[' Descuento: '] + this.state.offerValue.toFixed(2);
      return total1;
    };

    const grandTotal = () => {
      //print(distance['rows'][0]['elements'][0]['distance']['value']);
      //print(distance['rows'][0]['elements'][0]['distance']['value']);
      // normalDelivery =restaurant[0].deliveryPrice;

      // if(dynamicDelivery) {
      //   additionalDelivery = (normalDelivery * (additionalDeliveryPercentage2/100)) + 0.0;
      //   delivery = normalDelivery + additionalDelivery;
      // } else {
      //   delivery= normalDelivery;
      // }

      // if(widget.isPickup) {
      //   delivery = 0;
      //   normalDelivery = 0;
      //   additionalDelivery = 0;
      // }

      var serviceFee =
        this.state.businessData.driverServiceFee +
        (this.state.cart['totalCost'] *
          this.state.businessData.driverServicePercentage) /
          100 +
        0.0;
      var total23 =
        (this.state.cart['totalCost'] * this.state.tipPercent) / 100 + 0.0;
      var total1 =
        this.state.cart['totalCost'] -
        this.state.offerValue +
        this.state.delivery + (this.extraCharges()) +
        total23 +
        serviceFee;
      // var total1 = cart['item'][id]['customize'][i]['name'] + ': ';
      // for (int j = 0;
      // j < cart['item'][id]['customize'][i]['options'].length;
      // j++) {
      //   if (j == 0) {
      //     total1 =
      //         total1 + cart['item'][id]['customize'][i]['options'][j]['name'];
      //   } else {
      //     total1 = total1 +
      //         ',' +
      //         cart['item'][id]['customize'][i]['options'][j]['name'];
      //   }
      // }
      return total1;
    };

    const calcTip = () => {
      var total1 =
        (this.state.cart['totalCost'] * this.state.tipPercent) / 100 + 0.0;
      return total1;
    };

    const findTotalT = (id, i, order) => {
      var total1 =
        (order['items'][id]['customize'][i]['nameEng'] != null
          ? order['items'][id]['customize'][i]['nameEng']
          : order['items'][id]['customize'][i]['name']) + ': ';
      for (
        var j = 0;
        j < order['items'][id]['customize'][i]['options'].length;
        j++
      ) {
        if (j === 0) {
          total1 =
            total1 +
            (order['items'][id]['customize'][i]['options'][j]['nameEng'] != null
              ? order['items'][id]['customize'][i]['options'][j]['nameEng']
              : order['items'][id]['customize'][i]['options'][j]['name']);
        } else {
          total1 =
            total1 +
            ',' +
            (order['items'][id]['customize'][i]['options'][j]['nameEng'] != null
              ? order['items'][id]['customize'][i]['options'][j]['nameEng']
              : order['items'][id]['customize'][i]['options'][j]['name']);
        }
      }
      return total1;
    };
    const grandTotalBefore = () => {
      var serviceFee =
        (this.state.businessData.driverServiceFee
          ? this.state.businessData.driverServiceFee
          : 0) +
        (this.state.cart['totalCost'] *
          (this.state.businessData.driverServicePercentage
            ? this.state.businessData.driverServicePercentage
            : 0)) /
          100 +
        0.0;
      var total1 =
        this.state.cart['totalCost'] -
        this.state.offerValue + (  this.extraCharges()) +
        ((this.state.selectedType === 1)?0:this.state.delivery) +
        calcTip() +
        serviceFee;
      // if(cart['totalOxxoCost']>0) {
      //   total1 = total1 + oxxoDelivery + cart['totalOxxoCost'];
      // }
      // var total1 = cart['item'][id]['customize'][i]['name'] + ': ';
      // for (int j = 0;
      // j < cart['item'][id]['customize'][i]['options'].length;
      // j++) {
      //   if (j == 0) {
      //     total1 =
      //         total1 + cart['item'][id]['customize'][i]['options'][j]['name'];
      //   } else {
      //     total1 = total1 +
      //         ',' +
      //         cart['item'][id]['customize'][i]['options'][j]['name'];
      //   }
      // }
      return total1;
    };
    const grandTotal2 = () => {
      var serviceFee =
        (this.state.businessData.driverServiceFee
          ? this.state.businessData.driverServiceFee
          : 0) +
        (this.state.cart['totalCost'] *
          (this.state.businessData.driverServicePercentage
            ? this.state.businessData.driverServicePercentage
            : 0)) /
          100 +
        0.0;
      var total1 =
        this.state.cart['totalCost'] - this.state.offerValue2 -
        this.state.offerValue + this.extraCharges() +
        ((this.state.selectedType === 1)?0:this.state.delivery) +
        calcTip() +
        serviceFee;
      // if(cart['totalOxxoCost']>0) {
      //   total1 = total1 + oxxoDelivery + cart['totalOxxoCost'];
      // }
      // var total1 = cart['item'][id]['customize'][i]['name'] + ': ';
      // for (int j = 0;
      // j < cart['item'][id]['customize'][i]['options'].length;
      // j++) {
      //   if (j == 0) {
      //     total1 =
      //         total1 + cart['item'][id]['customize'][i]['options'][j]['name'];
      //   } else {
      //     total1 = total1 +
      //         ',' +
      //         cart['item'][id]['customize'][i]['options'][j]['name'];
      //   }
      // }
      return Number(total1.toFixed(2));
    };

    // Scroll Into View API: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    const handleClick = (id) =>
      refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    // console.log("Document data:", this.state.businessData);
    var _sessionToken = uuidv4();
    let columns = this.state.width < 1000 ? 1 : this.state.width < 1600 ? 2 : 3;
    // const GOOGLE_MAPS_API_KEY = 'AIzaSyC3aviU6KHXAjoSnxcw6qbOhjnFctbxPkE';

    return this.state.businessData ? (
      <>
        <Snackbar
        style={{zIndex: 9999999}}
          open={this.state.openAlert}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }

            this.setState({ openAlert: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }

              this.setState({ openAlert: false });
            }}
            severity={this.state.alertType}
            sx={{ width: '100%' }}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>

        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="sticky"
            color="transparent"
            elevation={1}
            style={{
              border: '0px solid #e2e8f0',
              boxShadow: 'none',
              background: 'white',
              height: '60px',
            }}
          >
            <Toolbar style={{padding: this.state.width < 599 ? '0 4%' : '0 15px',}}>
            <Button
                style={{
                  margin: '0px 6px 0px 0px ',
                  backgroundColor: '#ffffff00',
                  textTransform: 'none',
                  borderRight: '0px solid #cccccc',
                  padding: '5px 0px 5px 5px',
                  fontSize: '14px',
                  borderRadius: '6px',
                  color: '#2e2c36',
                  minWidth: 'auto',
                }}
                variant="text"
                startIcon={
                  <ArrowBackRoundedIcon style={{ color: '#0c0c0c', fontSize: '24px', margin: '0', }} />
                }
                onClick={() => window.history.back()}
              >
              </Button>
            <Box className="ocultarentablet"
              textAlign="center"
              style={{ borderRadius: '50%', height: 'auto', }}
            >
              <img onClick={() => window.history.back()}
                src={mainLogo2}
                alt="logo"
                height={'100%'}
                style={{ borderRadius: '50%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px', width: '48px', verticalAlign: 'middle',}}
              />
            </Box>
            {this.state.width >= 1000 ? (
              <div>
                <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                >
                <>{(!this.state.isPreOrder)?
                <Button
                  style={{
                    backgroundColor: '#fff',
                    textTransform: 'none',
                    borderRight: '0px solid #cccccc',
                    padding: '5px 10px 5px 10px',
                    fontSize: '15px',
                    borderRadius: '12px',
                    margin:"0px 8px 0px",
                    boxShadow: 'rgb(17 12 46 / 15%) 0px 48px 100px 0px',
                    fontFamily: 'Nunito',
                  }}
                  variant="text"
                  startIcon={
                    <AccessTimeRoundedIcon style={{ color: '#6a696e' }} />
                  }
                  endIcon={
                    <ArrowDropDownIcon style={{ color: 'slategray' }} />
                  }
                  onClick={handlePreOrderOpen}
                >
                  <>

                      <span
                        style={{ color: '#2c3036', fontWeight: 'bold' }}
                      >
                        {strings["Ahora"]}
                      </span>
                     {' '}
                  </>
                </Button>: <Button
                  style={{
                    margin:"0px 8px 0px",
                    backgroundColor: '#fff',
                    textTransform: 'none',
                    borderRight: '0px solid #cccccc',
                    padding: '5px 15px 5px',
                    fontSize: '15px',
                    borderRadius: '6px',
                    backgroundColor: getPrimaryColor()
                  }}
                  variant="filled"
                  startIcon={
                    <AccessTimeIcon style={{ color: '#fff' }} />
                  }
                  endIcon={
                    <CancelIcon style={{ color: '#fff' }} />
                  }
                  onClick={()=>{
                    this.setState(
                        { businessData: null },
                        (newState) => {
                          var weekDay = [6,0,1,2,3,4,5];
                    var currentDay = weekDay[new Date().getDay()];
                    const { id } = this.props.match.params;
                    var dataToAdd = { selectedDate: this.state.selectedPreOrder, selectedWeekDay: currentDay, isTomorrow: this.state.showTomorrow, isToday: (!this.state.show3Day && !this.state.show4Day && !this.state.show5Day && !this.state.show6Day && !this.state.show7Day) ,isPreOrder: true };
    localStorage.setItem('preOrder', JSON.stringify(dataToAdd));
    window.dispatchEvent(new Event("preOrder"));
                    this.setState({ selectedDate: new Date(), selectedWeekDay: currentDay, busId: id,isTomorrow: false,  isPreOrder: false, isToday: false });

                              handlePreOrderClose();
                              // this.setState({ businessData: {} });
                              this.getCities(id);
                            });

                      }}
                    >
                      <>

                          <span
                            style={{ color: '#fff', fontWeight: 'bold' }}
                          >
                            {((!this.state.isTomorrow && this.state.isToday)
                                                      ? strings[' Hoy, ']
                                                      : (this.state.isTomorrow && !this.state.isToday)
                                                      ? strings[' Mañana, ']
                                                      : moment(this.state.selectedDate).format('DD-MM-YYYY').toString()+ ',  ')  + moment(this.state.selectedDate).format('kk:mm').toString()
                                                  }
                          </span>
                         {' '}
                      </>
                    </Button>
                    }</></Stack>
                  </div>
                ) : null}
              {this.state.width >= 1000 && this.state.selectedType === 0 ? (
                <Button
                  style={{
                    margin: '1px',
                    backgroundColor: '#fff',
                    textTransform: 'none',
                    borderRight: '0px solid #cccccc',
                    padding: '5px 15px 5px 5px',
                    fontSize: '15px',
                    borderRadius: '6px',
                  }}
                  variant="text"
                  startIcon={
                    <LocationOnIcon style={{ color: getPrimaryColor() }} />
                  }
                  endIcon={
                    <ArrowDropDownIcon style={{ color: getPrimaryColor() }} />
                  }
                  onClick={handleClickOpen}
                >
                  <>
                    {this.state.width >= 1000 ? (
                      <span
                        style={{ color: getPrimaryColor(), fontWeight: 'bold' }}
                      >
                        {this.state.selectLocationName
                          ? this.state.selectLocationName
                          : strings['Seleccionar mi ubicación']}
                      </span>
                    ) : null}{' '}
                  </>
                </Button>
              ) : null}{' '}
              <Box sx={{ flexGrow: 1 }} />
              <Search
                style={{
                  borderRadius: 10,
                  backgroundColor: '#f8f9fb',
                  margin: '0',
                }}
              >
                <SearchIconWrapper color={getPrimaryColor()}>
                  <SearchIcon style={{ color: 'white' }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={strings['Buscar producto']}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
              </Search>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{ display: { xs: 'flex', md: 'flex' } }}
                style={{
                  margin: '1px',
                  borderLeft: '0px solid #cccccc',
                  color: getPrimaryColor(),
                }}
              >

              {/* {' Phone footer '} */}
              <div className="showonphone" style={{display: 'flex',position: 'fixed',zIndex: '999',background: 'white',bottom: '0px',right: '0px',boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',borderRadius: '28px 28px 0 0',padding: '12px 18px',width: '100%',height: '64px',}}>
                {/* {' preorder select on phoneview '} */}
                <div style={{ margin: '0px', width: this.state.cart.totalItems > 0 ? '25%' : '33%', }}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                  <>{(!this.state.isPreOrder)?
                  <Button
                    style={{
                      height: '40px',
                      backgroundColor: '#fff',
                      textTransform: 'none',
                      borderRight: '0px solid #cccccc',
                      padding: '6px 15px 6px 15px',
                      fontSize: '15px',
                      borderRadius: '12px',
                    }}
                    variant="text"
                    startIcon={
                      <AccessTimeIcon style={{ color: '#040607', fontSize: '26px', marginRight: '-8px', marginLeft: '4px', }} />
                    }
                    onClick={handlePreOrderOpen}
                  >
                    <>
                       {' '}
                    </>
                  </Button>: <Button
                    style={{
                      backgroundColor: '#fff',
                      height: '40px',
                      textTransform: 'none',
                      borderRight: '0px solid #cccccc',
                      padding: '6px 15px 6px 15px',
                      fontSize: '15px',
                      borderRadius: '12px',
                      backgroundColor: getPrimaryColor()
                    }}
                    variant="filled"
                    startIcon={
                      <AccessTimeIcon style={{ color: '#040607', fontSize: '26px', marginRight: '-8px', marginLeft: '4px', }} />
                    }
                    onClick={()=>{
                      this.setState(
                          { businessData: null },
                          (newState) => {
                            var weekDay = [6,0,1,2,3,4,5];
              var currentDay = weekDay[new Date().getDay()];
              const { id } = this.props.match.params;
              this.setState({ selectedDate: new Date(), selectedWeekDay: currentDay, busId: id,isTomorrow: false,  isPreOrder: false });

                            handlePreOrderClose();
                            // this.setState({ businessData: {} });
                            this.getCities(id);
                          });

                    }}
                  >
                    <>
                       {' '}
                    </>
                  </Button>
                  }</></Stack>
                </div>
                {/* {' Select the order type on phone view '} */}
                <FormControl className="ocultatipodeordenrencel"
                  sx={{ m: 0, minWidth: 120, p: 0,display: 'flex',justifyContent: 'center',alignItems: 'center', margin: '0px', width: this.state.cart.totalItems > 0 ? '25%' : '33%', height: '40px' }}>
                  <Select className="ocultatipodeordenrencel2"
                    style={{height: '40px', paddingLeft: '20px', }}
                    value={this.state.selectedType}
                    onChange={(event) => {
                      console.log(event.target.value,this.state.selectedType, (typeof event.target.value));
                      if(event.target.value !== -1) {
                        this.setState({ selectedType: event.target.value });
                                          console.log(this.state);
                                          if (!this.state.canDeliverHere && event.target.value === 0) {
                                            this.setState({ storeIsOpen: false });
                                          } else if (
                                            !this.state.canDeliverHere &&
                                            (event.target.value === 1 || event.target.value === 2)
                                          ) {
                                            this.setState({ storeIsOpen: true });
                                          }
                            }
                          }} displayEmpty inputProps={{ 'aria-label': '' }}>
                    <MenuItem value={0}>
                          <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                      >
                      <DirectionsBikeIcon
                        style={{
                          fontSize: this.state.width > 600 ? '20px' : '22px',
                          fontWeight: 'bold',
                          color: getPrimaryColor(),
                          marginRight: '10px',
                        }}
                      />
                      <Typography
                      style={{
                        fontWeight: '700',
                        fontFamily: 'Nunito',
                        textTransform: 'none',
                        padding: '5px 11px',
                        color: '#3e4547',
                        fontSize: this.state.width > 600 ? '16px' : '20px',
                      }}
                    >
                      {strings["Entrega a domicilio"]}
                    </Typography>
                    </Stack>
                    </MenuItem>
                    <MenuItem value={1}>
                       <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                        <DirectionsRunIcon
                            style={{
                              height: '17px',
                              fontSize: this.state.width > 600 ? '20px' : '22px',
                              fontWeight: 'bold',
                              color: getPrimaryColor(),
                              marginRight: '10px',
                            }}
                          />
                          <Typography
                            style={{
                              fontWeight: '700',
                              fontFamily: 'Nunito',
                              textTransform: 'none',
                              padding: '5px 11px',
                              color: '#3e4547',
                              fontSize: this.state.width > 600 ? '16px' : '20px',
                              }}
                            >
                          {strings["Recoger en tienda"]}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  </Select>
                </FormControl>
                  {/* {' cart on phone view this.state.width < 600 && {this.state.width < 600 && this.state.cart.totalItems > 0 ? ( '} */}
                  {this.state.cart.totalItems > 0 ? (
                    <IconButton
                      style={{
                        background: getPrimaryColor(),
                        boxShadow: '0 1px 7px'+getPrimaryColor()+69,
                        borderRadius: '50px',
                        padding: '9px 8px',
                      height: '40px',
                      margin: '0px',
                      width: this.state.cart.totalItems > 0 ? '25%' : '33%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                      backgroundImage: 'url("https://dl3.pushbulletusercontent.com/TdNV48MFqCOZiU57WBx86aEeoZIl6jhj/Shopping_cart.png")',
                      }}
                      size="large"
                      aria-label=""
                      color="inherit"
                      onClick={() => {
if(this.state.userCredential) {
                                      // handleClickOpenAdditionalRegister();
            this.setState({
              openAdditionalRegister: true,
              password: '',
              email: '',
              fullName: '',
              otp: '',
              phoneNumber: '',
              phoneCountry: '+',
              showPassword: false,
            });
            window.history.pushState(null, '', window.location.href);
      
            window.onpopstate = () => {
              window.onpopstate = () => {};
              window.history.back();
              this.setState({
                openAdditionalRegister: false,
                password: '',
                email: '',
                fullName: '',
                otp: '',
                phoneNumber: '',
                phoneCountry: '+52',
                showPassword: false,
              });
            };
                        } else{
                        if (
                          this.state.cart.totalItems > 0 &&
                          this.state.storeIsOpen
                        ) {
                          handleCartOpen();
                          // this.setState({ showCart: true });
                        }
                      }
                      }}
                    >
                      <Badge
                        badgeContent={this.state.cart.totalItems}
                        color="error"
                      >
                      <div className="App">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" sx={{ fill: getPrimaryColor(),}}>
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#ffffff" fillRule="nonzero" opacity="0.3"/>
                                <path d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z" fill="#ffffff"/>
                            </g>
                        </svg>
                      </div>
                      </Badge>
                    </IconButton>
                  ) : null}
                {/* {' Profile on phone view footer '} */}
                <Box sx={{ display: { xs: 'flex', md: 'flex' } }} style={{ margin: '0px', width: this.state.cart.totalItems > 0 ? '25%' : '33%', display: 'grid', placeContent: 'center', color: '#040607', }} >
                  <IconButton style={{ background: 'none', }}
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit">
                    {this.state.photoURL ? (
                      <img
                        style={{
                          verticalAlign: 'middle',
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                        }}
                        src={this.state.photoURL}
                      />
                    ) : (
                      <AccountCircle />
                    )}
                  </IconButton>
                </Box>
                {/* {' Profile on phone view footer '} */}

                </div>
              {/* {' Phone footer '} */}

              {/* View cart on web view */}
              <>
                {this.state.width > 599 && this.state.cart.totalItems > 0 ? (
                <IconButton
                  style={{
                    height: '74px',
                    width: '200px',
                    position: 'fixed',
                    bottom: '10px',
                    right: '20px',
                    background: '#f8f9fb',
                    borderRadius: '10px',
                    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
                    border: '2px solid #ffffff',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    paddingLeft: '50px',
                    backgroundImage: 'url("/viewcart.png")',
                  }}
                  size="large"
                  aria-label=""
                  color="inherit"
                  onClick={() => {
                    if(this.state.userCredential) {
                      // handleClickOpenAdditionalRegister();
this.setState({
openAdditionalRegister: true,
password: '',
email: '',
fullName: '',
otp: '',
phoneNumber: '',
phoneCountry: '+52',
showPassword: false,
});
window.history.pushState(null, '', window.location.href);
window.onpopstate = () => {
window.onpopstate = () => {};
window.history.back();
this.setState({
openAdditionalRegister: false,
password: '',
email: '',
fullName: '',
otp: '',
phoneNumber: '',
phoneCountry: '+52',
showPassword: false,
});
};
        } else{
                    if (
                      this.state.cart.totalItems > 0 &&
                      this.state.storeIsOpen
                    ) {
                      handleCartOpen();
                      // this.setState({ showCart: true });
                    }
                  }
                  }}
                >
                  <Badge
                    badgeContent={this.state.cart.totalItems}
                    color="error"
                  >
                    <div className="App">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" sx={{ fill: getPrimaryColor(),}}>
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                              <path d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z" fill="#000000"/>
                          </g>
                      </svg>
                    </div>
                  </Badge>
                  <Typography
                    style={{
                      fontSize: '16px',
                      color: '#464c51',
                      paddingLeft: '20px',
                    }}
                  >{strings["Ver pedido"]}
                  </Typography>
                </IconButton>
                ) : null}
              </>
              {this.state.width > 599 ? (
                <IconButton
                  style={{ background: 'white',
                    boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                    width: '40px',
                    height: '40px',
                    marginRight: '0px',
                    marginTop: this.state.width > 1000 ? '0' : '0',
                  }}
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {this.state.photoURL ? (
                    <img
                      style={{
                        verticalAlign: 'middle',
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                      }}
                      src={this.state.photoURL}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              ) : null}
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </Box>
        {/* <h2
        onClick={
          ()=>{
            console.log("herr");
          }
        }
        style={{  position: "relative",
  left: "calc( 100vw - 100px )",
  height: "0px",
  zIndex: 999,
  margin: "0px",
  top: 'calc( 100vh - 260px )'}}>This is a heading with an absolute position</h2> */}
        <>
          {this.state.orders && this.state.orders.length > 0 ? (
            <div
              style={{
                position: this.state.width > 720 ? 'relative' : 'fixed',
                left: this.state.width > 720 ? 'calc( 100vw - 480px )' : '0px',
                height: '0px',
                zIndex: 999,
                width: this.state.width > 720 ? '460px' : '100vw',
                margin: '0px',
                top: isIOS
                  ? 'calc((var(--vh, 1vh) * 100) - 55px)'
                  : this.state.width > 720
                  ? 'calc( 100vh - 167px )'
                  : 'calc( 100vh - 165px )',
              }}
            >
              <div
                style={{
                  padding: '0px 10px 5px',
                  marginTop: isIOS ? '-50px' : '0',
                }}
              >
                <Carousel
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '11px',
                    height: '90px',
                    boxShadow: '0px 0px 10px #aaa',
                  }}
                >
                  {this.state.orders.map((i, ind) => (
                    <div
                      onClick={() => {
                        handleTrackingOpen(i.data(), i.id);
                      }}
                      style={{
                        width:
                          this.state.width > 720
                            ? 'calc(500px - 20px)'
                            : 'calc(100vw - 20px)',
                        borderRadius: '17.5px',
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                      }}
                      key={`order-id-1-${i.id}`}
                    >
                      <div
                        style={{
                          padding: '15px 2% 15px',
                          borderRadius: '17.5px',
                          backgroundColor: '#fff',
                        }}
                      >
                        <Stack
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box
                            textAlign="center"
                            height={'7.5%'}
                            style={{ borderRadius: '50%' }}
                          >
                            <img
                              src={i.data()['restLogo']}
                              alt="logo"
                              height={'50px'}
                              style={{
                                borderRadius: '50%',
                                verticalAlign: 'middle',
                              }}
                            />
                          </Box>
                          <div style={{ padding: '0px 1% 0px' }}></div>
                          <div style={{ width: '46%' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize:
                                    this.state.width > 600 ? '16px' : '2vh',
                                  fontWeight: 'bold',
                                  color: '#2c3136',
                                }}
                              >
                                {strings['ID del pedido: ']}
                                {i.data()['number']}
                              </Typography>
                              <div style={{ padding: '1px 0px 1px' }}></div>
                              <Typography
                                style={{
                                  fontSize:
                                    this.state.width > 600 ? '14px' : '1.5vh',
                                  fontWeight: 'normal',
                                  color: 'slategray',
                                }}
                              >
                                {i.data()['status']}
                              </Typography>
                                </Stack>
                              </div>
                          <div style={{ padding: '0px 1% 0px' }}></div>
                          <div style={{ flexGrow: 1 }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <>
                                  {!i.data()['acceptedByRest'] ? (
                                    <div
                                      style={{
                                        border:
                                          '2px solid ' + getPrimaryColor(),
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['acceptedByRest'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          color: getPrimaryColor(),
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: getPrimaryColor(),
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'acceptedByRest'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {!i.data()['driverReached'] ? (
                                    <div
                                      style={{
                                        border: i.data()['acceptedByRest']
                                          ? '2px solid ' + getPrimaryColor()
                                          : '2px solid #C4C1C0',
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['driverReached'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: getPrimaryColor(),
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'driverAssigned'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'driverReached'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {!i.data()['delivered'] ? (
                                    <div
                                      style={{
                                        border: i.data()['driverReached']
                                          ? `2px solid ${getPrimaryColor()}`
                                          : '2px solid #C4C1C0',
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['delivered'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: getPrimaryColor(),
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'orderPickedUp'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()['delivered']
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {!i.data()['delivered'] ? (
                                    <div
                                      style={{
                                        border: i.data()['delivered']
                                          ? `2px solid ${getPrimaryColor()}`
                                          : '2px solid #C4C1C0',
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['delivered'] &&
                                  !i.data()['cancelled'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: getPrimaryColor(),
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['delivered'] &&
                                  !i.data()['cancelled'] ? (
                                    <div>
                                      <CancelIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: getPrimaryColor(),
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              </Stack>
                            </Stack>
                          </div>
                        </Stack>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : null}
        </>
        <VariablesDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openVariable}
          onClose={handleCloseVariable}
          title={
            this.state.selectedProduct ? this.state.selectedProduct['nameEng'] && strings.language === 'en' ? this.state.selectedProduct['nameEng']:this.state.selectedProduct.name : ''
          }
          displayList={
            this.state.selectedProduct ? (
              <DialogContent dividers style={{ padding: '5px 24px' }}>
                <div
                  style={{ width: this.state.width < 1000 ? '100%' : '100vh' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height:
                        this.state.width < 600
                          ? 'calc( 80vh)'
                          : 'calc( 100vh - 185px )', //change this for webview
                      overflow: 'hidden',
                    }}
                  >
                    {this.state.width >= 1000 ? (
                      <div
                        style={{
                          width: '49.9%',
                          height: '100%',
                          float: 'left',
                          overflow: 'scroll',
                          paddingBottom: '76px',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            width: '100%',
                            height: 'calc(67%)',
                          }}
                        >
                          {this.state.selectedProduct.images ? (
                            <img
                              src={this.state.selectedProduct.images}
                              style={{
                                borderRadius: '12px',
                                width: 'calc(100% - 40px)',
                                height: 'calc(97%)',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>

                        <Typography
                          style={{
                            fontSize: '17px',
                            color: '#464c51',
                            paddingRight: '10px',
                          }}
                        >
                          {this.state.selectedProduct.description
                            ? this.state.selectedProduct['descriptionEng'] && strings.language === 'en' ? this.state.selectedProduct['desriptionEng']:this.state.selectedProduct.description
                            : ''}
                        </Typography>
                        <div style={{height: '10px'}}></div>
                        {(this.state.selectedProduct['perKg']) ?
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/kilo.png')} alt = {"se vende por kilo"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{ fontSize: "14px"}}>{strings['se vende por kilo']}</Typography>
                        </div>
                        : null}
                        {(this.state.selectedProduct.vegan != null && this.state.selectedProduct.vegan) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/vegan.png')} alt = {"vegan"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["este producto es 100% vegano"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.riesgoDeDerrame != null && this.state.selectedProduct.riesgoDeDerrame) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/derramable.png')} alt = {"Riesgo de derrame"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["este producto tiene riesgo de derrame"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.picorValue != null) ? (
                        <div style={{display: 'flex'}}>
                          {(this.state.selectedProduct.picorValue != null && (
                          this.state.selectedProduct.picorValue == '1' || this.state.selectedProduct.picorValue == '2' ||
                          this.state.selectedProduct.picorValue == '3' || this.state.selectedProduct.picorValue == '4' )) ? (
                            <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                          ) : null}
                          {(this.state.selectedProduct.picorValue != null && (this.state.selectedProduct.picorValue == '2' ||
                          this.state.selectedProduct.picorValue == '3' || this.state.selectedProduct.picorValue == '4' )) ? (
                            <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                          ) : null}
                          {(this.state.selectedProduct.picorValue != null && (
                          this.state.selectedProduct.picorValue == '3' || this.state.selectedProduct.picorValue == '4' )) ? (
                            <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                          ) : null}
                          {(this.state.selectedProduct.picorValue != null && this.state.selectedProduct.picorValue == '4') ? (
                            <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                          ) : null}
                          <Typography style={{fontSize: '14px',}}>{strings["nivel de picante"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.gluten != null && this.state.selectedProduct.gluten) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/gluten.png')} alt = {"Gluten"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene gluten"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.crustaceos != null && this.state.selectedProduct.crustaceos) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/crustaseos.png')} alt = {"Crustaceos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene crustaceos"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.huevo != null && this.state.selectedProduct.huevo) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/huevo.png')} alt = {"Huevo"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene huevo"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.pescado != null && this.state.selectedProduct.pescado) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/pescado.png')} alt = {"Pescado"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene pescado"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.cacahuates != null && this.state.selectedProduct.cacahuates) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/cacahuates.png')} alt = {"Cacahuates"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene cacahuates"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.soya != null && this.state.selectedProduct.soya) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/soya.png')} alt = {"Soya"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene soya"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.leche != null && this.state.selectedProduct.leche) ? (
                       <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/leche.png')} alt = {"Leche"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene leche"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.frutos != null && this.state.selectedProduct.frutos) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/cascara.png')} alt = {"Cascara"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene frutos"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.apio != null && this.state.selectedProduct.apio) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/apio.png')} alt = {"Apio"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene apio"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.mostaza != null && this.state.selectedProduct.mostaza) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/mostaza.png')} alt = {"Mostaza"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene mostaza"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.sesamo != null && this.state.selectedProduct.sesamo) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/sesamo.png')} alt = {"Sésamo"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene sesamo"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.sulfitos != null && this.state.selectedProduct.sulfitos) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/sulfitos.png')} alt = {"Sulfitos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene sulfitos"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.moluscos != null && this.state.selectedProduct.moluscos) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/moluscos.png')} alt = {"Moluscos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene moluscos"]}</Typography>
                        </div>
                        ) : null}
                        {(this.state.selectedProduct.altramuces != null && this.state.selectedProduct.altramuces) ? (
                        <div style={{display: 'flex'}}>
                          <img src = {require('./assets/alergenos/altramuces.png')} alt = {"Altramuces"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                          <Typography style={{fontSize: '14px',}}>{strings["Contiene altramuces"]}</Typography>
                        </div>
                        ) : null}
                      </div>
                    ) : null}
                    <div
                      style={{
                        width: this.state.width < 1000 ? '100%' : '50%',
                        height: '100%',
                        float: 'right',
                        overflow: 'auto',
                      }}
                    >
                      {this.state.width < 1000 ? (
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              width: '100%',
                            }}
                          >
                            {this.state.selectedProduct.images ? (
                              <img
                                src={this.state.selectedProduct.images}
                                style={{
                                  borderRadius: '12px',
                                  width: 'calc(100%)',
                                  // height: 'calc(75%)',
                                  objectFit: 'cover',
                                  height: '160px',
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <Box height={'8px'}></Box>

                          <Typography
                            style={{ fontSize: '19px', color: '#464c51' }}
                          >
                            {this.state.selectedProduct.description
                              ? this.state.selectedProduct['descriptionEng'] && strings.language === 'en' ? this.state.selectedProduct['desriptionEng']:this.state.selectedProduct.description
                              : ''}
                          </Typography>
                          <div style={{height: '10px'}}></div>
                          {(this.state.selectedProduct['perKg']) ?
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/kilo.png')} alt = {"se vende por kilo"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{ fontSize: "14px"}}>{strings['se vende por kilo']}</Typography>
                          </div>
                          : null}
                          {(this.state.selectedProduct.vegan != null && this.state.selectedProduct.vegan) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/vegan.png')} alt = {"Vegan"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["este producto es 100% vegano"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.riesgoDeDerrame != null && this.state.selectedProduct.riesgoDeDerrame) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/derramable.png')} alt = {"Riesgo de derrame"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["este producto tiene riesgo de derrame"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.picorValue != null) ? (
                          <div style={{display: 'flex'}}>
                            {(this.state.selectedProduct.picorValue != null && (
                            this.state.selectedProduct.picorValue == '1' || this.state.selectedProduct.picorValue == '2' ||
                            this.state.selectedProduct.picorValue == '3' || this.state.selectedProduct.picorValue == '4' )) ? (
                              <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                            ) : null}
                            {(this.state.selectedProduct.picorValue != null && (this.state.selectedProduct.picorValue == '2' ||
                            this.state.selectedProduct.picorValue == '3' || this.state.selectedProduct.picorValue == '4' )) ? (
                              <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                            ) : null}
                            {(this.state.selectedProduct.picorValue != null && (
                            this.state.selectedProduct.picorValue == '3' || this.state.selectedProduct.picorValue == '4' )) ? (
                              <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                            ) : null}
                            {(this.state.selectedProduct.picorValue != null && this.state.selectedProduct.picorValue == '4') ? (
                              <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                            ) : null}
                            <Typography style={{fontSize: '14px',}}>{strings["nivel de picante"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.gluten != null && this.state.selectedProduct.gluten) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/gluten.png')} alt = {"Gluten"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene gluten"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.crustaceos != null && this.state.selectedProduct.crustaceos) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/crustaseos.png')} alt = {"Crustaceos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene crustaceos"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.huevo != null && this.state.selectedProduct.huevo) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/huevo.png')} alt = {"Huevo"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene huevo"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.pescado != null && this.state.selectedProduct.pescado) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/pescado.png')} alt = {"Pescado"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene pescado"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.cacahuates != null && this.state.selectedProduct.cacahuates) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/cacahuates.png')} alt = {"Cacahuates"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene cacahuates"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.soya != null && this.state.selectedProduct.soya) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/soya.png')} alt = {"Soya"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene soya"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.leche != null && this.state.selectedProduct.leche) ? (
                        <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/leche.png')} alt = {"Leche"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene leche"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.frutos != null && this.state.selectedProduct.frutos) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/cascara.png')} alt = {"Cascara"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene frutos"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.apio != null && this.state.selectedProduct.apio) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/apio.png')} alt = {"Apio"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene apio"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.mostaza != null && this.state.selectedProduct.mostaza) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/mostaza.png')} alt = {"Mostaza"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene mostaza"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.sesamo != null && this.state.selectedProduct.sesamo) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/sesamo.png')} alt = {"Sésamo"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene sesamo"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.sulfitos != null && this.state.selectedProduct.sulfitos) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/sulfitos.png')} alt = {"Sulfitos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene sulfitos"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.moluscos != null && this.state.selectedProduct.moluscos) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/moluscos.png')} alt = {"Moluscos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene moluscos"]}</Typography>
                          </div>
                          ) : null}
                          {(this.state.selectedProduct.altramuces != null && this.state.selectedProduct.altramuces) ? (
                          <div style={{display: 'flex'}}>
                            <img src = {require('./assets/alergenos/altramuces.png')} alt = {"Altramuces"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                            <Typography style={{fontSize: '14px'}}>{strings["Contiene altramuces"]}</Typography>
                          </div>
                          ) : null}
                        </div>
                      ) : null}
                      {/* {' '} */}
                      <List sx={{ pt: 0 }}>
                        {this.state.selectedProduct.customize.map((email, j) =>
                          (email['enabled'] ||
                            (!email['enabled'] &&
                              email['willBeAvailableAt'] != null &&
                              moment(this.state.selectedDate).isAfter(
                                moment(email['willBeAvailableAt'].toDate())
                              ))) &&
                          (email['conditioned'] == null ||
                            !email['conditioned'] ||
                            (email['conditioned'] != null &&
                              email['conditioned'] &&
                              this.state.productIds.includes(
                                email['conditionOption']
                              ))) ? (
                            <>
                              <div
                                key={email.id}
                                ref={refs[email.id]}
                                style={{
                                  backgroundColor: '#ffffff',
                                  margin: '10px 0px 25px 0px',
                                  width: 'calc(100% - 0px)',
                                }}
                              >
                                <Typography
                                  style={{
                                    padding: '0px 10px 0px 0px',
                                    fontSize: '18.8px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {email['nameEng'] && strings.language === 'en' ? email['nameEng']:email.name}
                                </Typography>
                                  <Typography
                                    style={{
                                    padding: '2px 10px 0px 0px',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: 'slategray',
                                    }}
                                  >
                                    {email['min'] >= 1
                                    ? email['max'] === email['min']
                                      ? strings['Obligatorio']
                                      : email['max']
                                      ? strings['Selecciona al menos '] +
                                        email['min'].toString()
                                      : strings['Selecciona de '] +
                                          email['min'].toString() +
                                          strings['.Hasta '] +
                                        email['max'].toString()
                                    : email['max'] == null
                                      ? ''
                                    : strings['Selecciona hasta '] +
                                      email['max'].toString()}
                                  </Typography>
                                <div
                                  style={{
                                    backgroundColor: '#ffffff',
                                    padding: '0px 0px 0px',
                                    width: 'calc(100% - 0px)',
                                  }}
                                >
                                  {/* <GridList cellHeight={130} cols={columns}> */}
                                  {email.options.map((image) =>
                                    image['enabled'] ||
                                    (!image['enabled'] &&
                                      image['willBeAvailableAt'] != null &&
                                      moment(this.state.selectedDate).isAfter(
                                        moment(
                                          image['willBeAvailableAt'].toDate()
                                        )
                                      )) ? (
                                      <>
                                        <div
                                          key={image.id}
                                          style={{
                                            borderBottom: '1px solid #e6ebf1',
                                          }}
                                          onClick={async () => {
                                            // console.log('hdjdjfhf');
                                            var productIds = JSON.parse(
                                              JSON.stringify(
                                                this.state.productIds
                                              )
                                            );
                                            var tempOrder = JSON.parse(
                                              JSON.stringify(
                                                this.state.tempOrder
                                              )
                                            );
                                            if (
                                              !tempOrder['customize'][j][
                                                'options'
                                              ].find(
                                                (opt) =>
                                                  opt['id'] === image['id']
                                              )
                                            ) {
                                              // console.log('hdjdjfhf', 1);
                                              if (
                                                email['max'] &&
                                                this.state.tempOrder[
                                                  'customize'
                                                ][j]['options'].length ===
                                                  email['max']
                                              ) {
                                                // console.log('hdjdjfhf', 2);
                                                // setState(() {
                                                const index =
                                                  productIds.indexOf(
                                                    tempOrder['customize'][j][
                                                      'options'
                                                    ][0]['id']
                                                  );
                                                if (index > -1) {
                                                  // only splice array when item is found
                                                  productIds.splice(index, 1); // 2nd parameter means remove one item only
                                                }

                                                tempOrder['price'] =
                                                  tempOrder['price'] -
                                                  tempOrder['customize'][j][
                                                    'options'
                                                  ][0]['price'];
                                                tempOrder['originalPrice'] =
                                                  tempOrder['originalPrice'] -
                                                  tempOrder['customize'][j][
                                                    'options'
                                                  ][0]['originalPrice'];
                                                tempOrder['customize'][j][
                                                  'options'
                                                ].splice(0, 1);
                                                // if(!menuIds.contains(menus2[i -
                                                //     3]['products'][index]['customize'][j]['id'])) {
                                                //   menuIds.add(menus2[i -
                                                //       3]['products'][index]['customize'][j]['id']);
                                                // }

                                                tempOrder['addons'] =
                                                  tempOrder['addons'] - 1;
                                                productIds.push(image['id']);
                                                tempOrder['customize'][j][
                                                  'options'
                                                ].push({
                                                  name: image['name'],
                                                  'nameEng':image['nameEng'] && strings.language === 'en' ? image['nameEng']:image['name'],
                                                  id: image['id'],
                                                  perKg: image['perKg'] ?? false,
                                                  riesgoDeDerrame: image['riesgoDeDerrame'] ?? false,
                                                  extraDelivery: image['extraDelivery'] ?? false,
                                                  originalPrice:
                                                    this.state.businessData
                                                      .partner != null &&
                                                    this.state.businessData
                                                      .partner
                                                      ? image['price']
                                                      : image['price'] +
                                                        image['price'] *
                                                          (this.state
                                                            .businessData.tax /
                                                            100),
                                                  price:
                                                    Number(((this.state.businessData
                                                      .partner != null &&
                                                    this.state.businessData
                                                      .partner
                                                      ? image['price']
                                                      : image['price'] +
                                                        image['price'] *
                                                          (this.state
                                                            .businessData.tax /
                                                            100))*multiFactor()).toFixed(multiFactor()>1?0:2)),
                                                });
                                                tempOrder['price'] =
                                                  tempOrder['price'] +
                                                  Number(((this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                                  tempOrder['originalPrice'] =
                                                  tempOrder['originalPrice'] +
                                                  (this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100));
                                                tempOrder['addons'] =
                                                  tempOrder['addons'] + 1;
                                                // });
                                              } else {
                                                // setState(() {
                                                productIds.push(image['id']);
                                                tempOrder['customize'][j][
                                                  'options'
                                                ].push({
                                                  name: image['name'],
                                                  nameEng: image['nameEng'] && strings.language === 'en' ? image['nameEng']: image['name'],
                                                  id: image['id'],
                                                  perKg: image['perKg'] ?? false,
                                                  riesgoDeDerrame: image['riesgoDeDerrame'] ?? false,
                                                  extraDelivery: image['extraDelivery'] ?? false,
                                                  originalPrice:
                                                    this.state.businessData
                                                      .partner != null &&
                                                    this.state.businessData
                                                      .partner
                                                      ? image['price']
                                                      : image['price'] +
                                                        image['price'] *
                                                          (this.state
                                                            .businessData.tax /
                                                            100),
                                                  price:
                                                    Number(((this.state.businessData
                                                      .partner != null &&
                                                    this.state.businessData
                                                      .partner
                                                      ? image['price']
                                                      : image['price'] +
                                                        image['price'] *
                                                          (this.state
                                                            .businessData.tax /
                                                            100))*multiFactor()).toFixed(multiFactor()>1?0:2)),
                                                });
                                                tempOrder['price'] =
                                                  tempOrder['price'] +
                                                  Number(((this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                                          tempOrder['originalPrice'] =
                                                  tempOrder['originalPrice'] +
                                                  (this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100));
                                                tempOrder['addons'] =
                                                  tempOrder['addons'] + 1;
                                                // });
                                              }
                                            } else {
                                              // console.log('hdjdjfhf', 12);
                                              if (email['min']) {
                                                // console.log('hdjdjfhf', 22);
                                                if (
                                                  tempOrder['customize'][j][
                                                    'options'
                                                  ].length > email['min']
                                                ) {
                                                  // console.log('hdjdjfhf', 23);
                                                  // setState(() {
                                                  const index =
                                                    productIds.indexOf(
                                                      image['id']
                                                    );
                                                  if (index > -1) {
                                                    // only splice array when item is found
                                                    productIds.splice(index, 1); // 2nd parameter means remove one item only
                                                  }
                                                  // productIds
                                                  //     .remove(
                                                  //     menus2[i -
                                                  //         3]['products'][index]['customize'][j]['options'][k]['id']);
                                                  tempOrder['customize'][j][
                                                    'options'
                                                  ] = tempOrder['customize'][j][
                                                    'options'
                                                  ].filter(
                                                    (item) =>
                                                      item['id'] !== image['id']
                                                  );
                                                  // tempOrder['customize'][j]['options']
                                                  //     .removeWhere((
                                                  //     item) =>
                                                  // item['id'] ==
                                                  //     menus2[i -
                                                  //         3]['products'][index]['customize'][j]['options'][k]['id']);
                                                  tempOrder['price'] =
                                                    tempOrder['price'] -
                                                    Number(((this.state.businessData
                                                      .partner != null &&
                                                    this.state.businessData
                                                      .partner
                                                      ? image['price']
                                                      : image['price'] +
                                                        image['price'] *
                                                          (this.state
                                                            .businessData.tax /
                                                            100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                                    tempOrder['originalPrice'] =
                                                    tempOrder['originalPrice'] -
                                                    (this.state.businessData
                                                      .partner != null &&
                                                    this.state.businessData
                                                      .partner
                                                      ? image['price']
                                                      : image['price'] +
                                                        image['price'] *
                                                          (this.state
                                                            .businessData.tax /
                                                            100));
                                                  tempOrder['addons'] =
                                                    tempOrder['addons'] - 1;
                                                  // });
                                                } else {
                                                  // console.log(
                                                  //   'hdjdjfhf',
                                                  //   122,
                                                  //   tempOrder['customize'][j][
                                                  //     'options'
                                                  //   ].length,
                                                  //   email['min']
                                                  // );
                                                }
                                              } else {
                                                // setState(() {
                                                const index =
                                                  productIds.indexOf(
                                                    image['id']
                                                  );
                                                if (index > -1) {
                                                  // only splice array when item is found
                                                  productIds.splice(index, 1); // 2nd parameter means remove one item only
                                                }
                                                // productIds
                                                //     .remove(
                                                //     menus2[i -
                                                //         3]['products'][index]['customize'][j]['options'][k]['id']);
                                                tempOrder['customize'][j][
                                                  'options'
                                                ] = tempOrder['customize'][j][
                                                  'options'
                                                ].filter(
                                                  (item) =>
                                                    item['id'] !== image['id']
                                                );
                                                // tempOrder['customize'][j]['options']
                                                //     .removeWhere((
                                                //     item) =>
                                                // item['id'] ==
                                                //     menus2[i -
                                                //         3]['products'][index]['customize'][j]['options'][k]['id']);
                                                tempOrder['price'] =
                                                  tempOrder['price'] -
                                                  Number(((this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                                tempOrder['originalPrice'] =
                                                  tempOrder['originalPrice'] -
                                                  (this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100));
                                                tempOrder['addons'] =
                                                  tempOrder['addons'] - 1;
                                                // });
                                              }
                                            }
                                            this.setState({
                                              tempOrder: tempOrder,
                                              productIds: productIds,
                                            });
                                            // console.log(
                                            //   'hdjdjfhf',
                                            //   tempOrder,
                                            //   productIds
                                            // );
                                          }}
                                        >
                                          <Box
                                            sx={{ flexGrow: 1 }}
                                            style={{
                                              backgroundColor: '#ffffff',
                                              margin: '10px 0px 10px 0px',
                                              width: 'calc(100%)',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            <Grid
                                              style={{
                                                marginLeft: '0px',
                                                width: 'calc(100% + 0px)',
                                                justifyContent: 'left',
                                              }}
                                              container
                                              spacing={1}
                                              direction="row"
                                              alignItems="center"
                                              justifyContent="center"
                                            >
                                              <Grid
                                                style={{ paddingLeft: '0px' }}
                                                item
                                                xs="1"
                                              >
                                                <Checkbox
                                                  style={{
                                                    paddingLeft: '0px',
                                                    color: getPrimaryColor(),
                                                  }}
                                                  checked={
                                                    !this.state.tempOrder[
                                                      'customize'
                                                    ][j]['options'].find(
                                                      (opt) =>
                                                        opt['id'] ===
                                                        image['id']
                                                    )
                                                      ? false
                                                      : true
                                                  }
                                                  onChange={() => {}}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs
                                                style={{
                                                  paddingLeft: '0px',
                                                  maxWidth: '65%',
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: '17px',
                                                    fontWeight: 'light',
                                                    paddingLeft: '5px',
                                                  }}
                                                >
                                                  {' '}
                                                  {image['nameEng'] && strings.language === 'en' ? image['nameEng']:image.name}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                xs="1"
                                                style={{
                                                  maxWidth: '85px',
                                                  right: '0',
                                                  position: 'absolute',
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#6a696e',
                                                  }}
                                                >
                                                  {Number(((this.state.businessData
                                                    .partner != null &&
                                                  this.state.businessData
                                                    .partner
                                                    ? image['price']
                                                    : image['price'] +
                                                      image['price'] *
                                                        (this.state.businessData
                                                          .tax /
                                                          100))*multiFactor()).toFixed(multiFactor()>1?0:2)) > 0
                                                    ? '+$' +
                                                      ((this.state.businessData
                                                        .partner != null &&
                                                      this.state.businessData
                                                        .partner
                                                        ? image['price']
                                                        : image['price'] +
                                                          image['price'] *
                                                            (this.state
                                                              .businessData
                                                              .tax /
                                                              100)
                                                      )*multiFactor()).toFixed(multiFactor()>1?0:2)
                                                    : ''}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </div>
                                      </>
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null
                        )}
                      </List>{' '}
                    </div>
                  </div>
                  {this.state.storeIsOpen ? (
                    <div
                      style={{
                        width: 'calc(100% - 48px)',
                        height: 'calc( 69px )',
                        overflow: 'hidden',
                        marginBottom: '1px',
                        paddingTop: '7px',
                        bottom: '0',
                        position: 'absolute',
                        background: 'white',
                      }}
                    >
                      <div
                        style={{
                          width: '49.9%',
                          height: '100%',
                          float: 'left',
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <ButtonGroup
                            variant="text"
                            disableElevation
                            size={'small'}
                            style={{
                              backgroundColor: '#f8f9fb',
                              borderRadius: this.state.width < 600 ? '12px' : '8px',
                              height: this.state.width < 600 ? '52px' : '48px',
                              width: this.state.width < 600 ? 'auto' : '200px',
                              marginRight:
                                this.state.width < 600 ? 'auto' : 'inherit',
                            }}
                          >
                            <Button
                              style={{
                                borderRadius: this.state.width < 600 ?'12px 0 0 12px' : '8px 0 0 8px',
                                border: 'none',
                                minWidth:
                                  this.state.width < 600 ? '45px' : '33.333%',
                              }}
                            >
                              <RemoveRoundedIcon
                                sx={{ color: '#000' }}
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                  color: '#06696e',
                                }}
                                onClick={() => {
                                  if (this.state.tempOrder['quantity'] === 1) {
                                    handleCloseVariable();
                                  } else
                                    this.setState((prevState) => ({
                                      tempOrder: {
                                        ...prevState.tempOrder, // copy all other key-value pairs of food object
                                        quantity:
                                          this.state.tempOrder['quantity'] - 1,
                                      },
                                    }));
                                }}
                              />
                              {/* <Typography style={{fontSize: "15px", fontWeight: "bold"}}>{123}</Typography>
        <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                            </Button>
                            <Button
                              disabled
                              style={{
                                border: 'none',
                                minWidth:
                                  this.state.width < 600 ? '45px' : '33.333%',
                              }}
                            >
                              {/* <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                              <Typography
                                style={{
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  color: constants.secondaryColor,
                                }}
                              >
                                {this.state.tempOrder['quantity']}
                              </Typography>
                              {/* <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                            </Button>
                            <Button
                              style={{
                                borderRadius: this.state.width < 600 ?'0 12px 12px 0' : '0 8px 8px 0',
                                border: 'none',
                                minWidth:
                                  this.state.width < 600 ? '45px' : '33.333%',
                              }}
                            >
                              {/* <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <Typography style={{fontSize: "15px", fontWeight: "bold"}}>{123}</Typography> */}
                              <AddRoundedIcon
                                sx={{ color: '#000' }}
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                  color: '#06696e',
                                }}
                                onClick={() => {
                                  this.setState((prevState) => ({
                                    tempOrder: {
                                      ...prevState.tempOrder, // copy all other key-value pairs of food object
                                      quantity:
                                        this.state.tempOrder['quantity'] + 1,
                                    },
                                  }));
                                  // this.setState({
                                  //   // tempOrder['price'] =
                                  //   //     tempOrder['price'] -
                                  //   //         (tempOrder['price'] /
                                  //   //             tempOrder['quantity']);
                                  //   quantity: this.state.tempOrder['quantity'] + 1
                                  // });
                                }}
                              />
                            </Button>
                          </ButtonGroup>{' '}
                        </Grid>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          height: '100%',
                          float: 'right',
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            onClick={() => {
                              var product = this.state.selectedProduct;
                              var tempOrder = this.state.tempOrder;
                              var productIds = this.state.productIds;
                              var hasAllData = true;
                              for (var j = 0; j < product['customize'].length; j++) {
          if (
            (product['customize'][j]['enabled'] ||
              (!product['customize'][j]['enabled'] &&
                product['customize'][j]['willBeAvailableAt'] != null &&
                moment(this.state.selectedDate).isAfter(
                  moment(product['customize'][j]['willBeAvailableAt'].toDate())
                ))) &&
            (product['customize'][j]['conditioned'] == null ||
              !product['customize'][j]['conditioned'] ||
              (product['customize'][j]['conditioned'] != null &&
                product['customize'][j]['conditioned'] &&
                productIds.includes(
                  product['customize'][j]['conditionOption']
                )))
          ) {
            if (
              product['customize'][j]['min'] != null &&
              (tempOrder['customize'] == null ||
                tempOrder['customize'].length === j ||
                tempOrder['customize'][j]['options'].length <
                  product['customize'][j]['min'])
            ) {
                if (tempOrder['customize'] == null) {
                  hasAllData = false;
                } else if (tempOrder['customize'].length === j) {
                  hasAllData = false;
                } else if(tempOrder['customize'][j]['options'].length <
                  product['customize'][j]['min']) {
                    hasAllData = false;
                  }
            }
          }
        }
        if(hasAllData) {
                              var tempOrder = JSON.parse(
                                JSON.stringify(this.state.tempOrder)
                              );
                              var cart = JSON.parse(
                                JSON.stringify(this.state.cart)
                              );
                              // setState(() {
                              if (tempOrder['customize'] == null)
                                tempOrder['customize'] = [];
                              cart['item'].push(tempOrder);
                              cart['totalCost'] =
                                cart['totalCost'] +
                                tempOrder['price'] * tempOrder['quantity'];
                              cart['totalItems'] =
                                cart['totalItems'] + tempOrder['quantity'];
                              if (
                                cart['maxTime'] != null &&
                                tempOrder['time'] != null &&
                                cart['maxTime'] < tempOrder['time']
                              ) {
                                cart['maxTime'] = tempOrder['time'];
                              }
                              // });
                              this.setState({
                                tempOrder: tempOrder,
                                cart: cart,
                              });
                              handleCloseVariable();
                            } else {
                              this.setState({
                                hasAllData: hasAllData,
                              });
                            }
                            }}
                            variant="contained"
                            style={{
                              width: '240px',
                              borderRadius: this.state.width < 600 ? '12px' : '8px',
                              textAlign: 'center',
                              fontSize: this.state.width < 600 ? '18px' : '16px',
                              fontWeight: '800',
                              textTransform: 'none',
                              color: '#fff',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                              boxShadow: 'none',
                              padding: '10px 2px',
                              backgroundColor: getPrimaryColor(),
                            }}
                          >
                            {(this.state.width > 900 ? strings['Agregar $'] : '+$') +
                              (
                                this.state.tempOrder['price'] *
                                this.state.tempOrder['quantity']
                              ).toFixed(2)}
                          </Button>
                        </Grid>{' '}
                      </div>
                    </div>
                  ) : !this.state.canDeliverHere ? <div
                      style={{
                        width: 'calc(100% - 48px)',
                        height: 'calc( 69px )',
                        overflow: 'hidden',
                        marginBottom: '1px',
                        paddingTop: '7px',
                        bottom: '0',
                        position: 'absolute',
                        background: 'white',
                      }}
                    >
                      <div
                        style={{
                          width: '49.9%',
                          height: '100%',
                          float: 'left',
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <ButtonGroup
                            variant="text"
                            disableElevation
                            size={'small'}
                            style={{
                              backgroundColor: '#f8f9fb',
                              borderRadius: this.state.width < 600 ? '12px' : '8px',
                              height: this.state.width < 600 ? '52px' : '48px',
                              width: this.state.width < 600 ? 'auto' : '200px',
                              marginRight:
                                this.state.width < 600 ? 'auto' : 'inherit',
                            }}
                          >
                            <Button
                              style={{
                                borderRadius: this.state.width < 600 ?'12px 0 0 12px' : '8px 0 0 8px',
                                border: 'none',
                                minWidth:
                                  this.state.width < 600 ? '45px' : '33.333%',
                              }}
                            >
                              <RemoveRoundedIcon
                                sx={{ color: '#000' }}
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                  color: '#06696e',
                                }}
                                onClick={() => {
                                  if (this.state.tempOrder['quantity'] === 1) {
                                    handleCloseVariable();
                                  } else
                                    this.setState((prevState) => ({
                                      tempOrder: {
                                        ...prevState.tempOrder, // copy all other key-value pairs of food object
                                        quantity:
                                          this.state.tempOrder['quantity'] - 1,
                                      },
                                    }));
                                }}
                              />
                              {/* <Typography style={{fontSize: "15px", fontWeight: "bold"}}>{123}</Typography>
        <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                            </Button>
                            <Button
                              disabled
                              style={{
                                border: 'none',
                                minWidth:
                                  this.state.width < 600 ? '45px' : '33.333%',
                              }}
                            >
                              {/* <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                              <Typography
                                style={{
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  color: constants.secondaryColor,
                                }}
                              >
                                {this.state.tempOrder['quantity']}
                              </Typography>
                              {/* <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                            </Button>
                            <Button
                              style={{
                                borderRadius: this.state.width < 600 ?'0 12px 12px 0' : '0 8px 8px 0',
                                border: 'none',
                                minWidth:
                                  this.state.width < 600 ? '45px' : '33.333%',
                              }}
                            >
                              {/* <AddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <Typography style={{fontSize: "15px", fontWeight: "bold"}}>{123}</Typography> */}
                              <AddRoundedIcon
                                sx={{ color: '#000' }}
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                  color: '#06696e',
                                }}
                                onClick={() => {
                                  this.setState((prevState) => ({
                                    tempOrder: {
                                      ...prevState.tempOrder, // copy all other key-value pairs of food object
                                      quantity:
                                        this.state.tempOrder['quantity'] + 1,
                                    },
                                  }));
                                  // this.setState({
                                  //   // tempOrder['price'] =
                                  //   //     tempOrder['price'] -
                                  //   //         (tempOrder['price'] /
                                  //   //             tempOrder['quantity']);
                                  //   quantity: this.state.tempOrder['quantity'] + 1
                                  // });
                                }}
                              />
                            </Button>
                          </ButtonGroup>{' '}
                        </Grid>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          height: '100%',
                          float: 'right',
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            onClick={() => {
                              handleCloseVariable();
                              handleClickOpen();
                            }}
                            variant="contained"
                            style={{
                              width: '240px',
                              borderRadius: this.state.width < 600 ? '12px' : '8px',
                              textAlign: 'center',
                              fontSize: this.state.width < 600 ? '18px' : '16px',
                              fontWeight: '800',
                              textTransform: 'none',
                              color: '#fff',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                              boxShadow: 'none',
                              padding: '10px',
                              backgroundColor: getPrimaryColor(),
                            }}
                          >
                            {(this.state.width > 900 ? strings['Por favor ingresa tu dirección2'] : strings['Ingresar dirección2'])}
                          </Button>
                        </Grid>{' '}
                      </div>
                    </div>:null}
                </div>
              </DialogContent>
            ) : null
          }
        ></VariablesDialog>
        <LoginDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLogin}
          onClose={handleCloseLogin}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={this.state.businessData.logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '65px',
                  }}
                >
                  {strings['Bienvenido']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '35px',
                  }}
                >
                  {strings['Crea tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings['Contraseña']}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Typography
                align="center"
                onClick={async ()=>{
                  if (
                    validator.isEmail(this.state.email)
                  ) {
                    this.setState({ loading: true });
                  await sendPasswordResetEmail(
                    auth,
                      this.state.email,
                  ).then((userCredential) => {
                    this.setState({ loading: false });
                    openAlert('success', strings['Restablecer instrucciones enviadas al correo electrónico ingresado']);
                  }).catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        openAlert('error', strings['No puedo encontrar el correo electrónico o algo salió mal.']);
                        // console.log(errorMessage, errorCode);
                      });
                } else {
                  openAlert('error', strings['Por favor ingrese un correo electrónico válido primero']);
                }
                }}
                style={{ marginBottom: '0', fontWeight: '700', fontSize: "16px", cursor: "pointer" }}
              >
                {strings['¿Olvidaste tu contraseña?']}
              </Typography>
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: getPrimaryColor(), }}
              sx={{ backgroundColor: getPrimaryColor() }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6
                  ) {
                    this.setState({ loading: true });
                    signInWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then((userCredential) => {
                        // Signed in
                        // console.log('signing', userCredential);
                        const user = userCredential.user;
                        const uid = user.uid;

                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorMessage, errorCode);
                        signInWithEmailAndPassword(
                      auth,
                      this.state.email.trim(),
                      '12345678'
                    )
                      .then(async(userCredential) => {

                        await updatePassword(
                          userCredential.user,
                          this.state.password
                        )
                          .then((res) => {
                            // console.log('Result after updating password', res);
                          });
                        // Signed in
                        // console.log('signing', userCredential);
                        const user = userCredential.user;
                        const uid = user.uid;

                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error2) => {
                        const errorCode = error2.code;
                        const errorMessage = error2.message;
                        this.setState({ loading: false });
                        openAlert('error', 'Los datos ingresados no son correctos');
                        console.log(errorMessage, errorCode);
                      });
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings['Iniciar sesión']}
              </Button>
              <Typography
                gutterBottom
                align="center"
                style={{ marginTop: '8px' }}
              >
                {strings['o']}
              </Typography>
              <br />
              <Button style={{ fontWeight: '600', height: '61px', borderRadius: '12px', color: "#000", boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: "#fff", }}
              sx={{ backgroundColor: "#fff" }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (true
                  ) {
                    this.setState({ loading: true });
                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();
                    auth.useDeviceLanguage();
                    signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    this.setState({ loading: false });
    openAlert('error', 'The data entered is not correct');
                    });
                  }
                }}
              > 
              <img src = {require('./assets/google.png')} alt = {"Google"} style={{height: '40px',width: 'auto',marginLeft:'5px',marginRight:'5px'}}/><div style={{width: "10px"}}></div>
                {strings['first2086']}
              </Button>
              <Typography
                gutterBottom
                align="center"
                style={{ marginBottom: '0', }}
              >
                {strings['o']}
              </Typography>
              <Button style={{ background: 'none', boxShadow: 'none', color: getPrimaryColor(), textTransform: 'inherit', fontSize: '18px', }}
              sx={{ backgroundColor: getPrimaryColor() }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  handleCloseLogin();
                  handleClickOpenRegister();
                }}
              >
                {strings['Registro']}
              </Button>
            </DialogContent>
          }
        />
                        <AddCardDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openAddCard}
          onClose={handleAddCardClose}
          displayList={
            <div style={{width: this.state.width>600?"500px":"auto"}}>
            <Elements stripe={stripePromise}>
               <ElementsConsumer>
    {({stripe, elements}) => (
            <DialogContent dividers>
              <div               style= {
           {
            color: constants.secondaryColor,
                        borderRadius: '9px',
                        backgroundColor: '#e6ebf1',
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                        height: "50px",

            '::placeholder': {
              color: constants.secondaryColor,
            }
          }
          }>
             <CardNumberElement className="card-element" options={{
              placeholder:strings['Número de tarjeta'],
              style: {
          base: {
            color: constants.secondaryColor,
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                        height: "50px",
            '::placeholder': {
              color: constants.secondaryColor,
            }
          },
          invalid: {
            color: constants.secondaryColor,
            iconColor: '#fa755a'
          },
          }
        }} />        </div>
        <div style={{padding: "5px"}}></div>
        <div               style= {
           {
            color: constants.secondaryColor,
                        borderRadius: '9px',
                        backgroundColor: '#e6ebf1',
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                        height: "50px",

            '::placeholder': {
              color: constants.secondaryColor,
            }
          }
          }>
        <CardExpiryElement className="card-element" options={{
              style: {
          base: {
            color: constants.secondaryColor,
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                        height: "50px",
            '::placeholder': {
              color: constants.secondaryColor,
            }
          },
          invalid: {
            color: constants.secondaryColor,
            iconColor: '#fa755a'
          },
          }
        }} />         </div>
        <div style={{padding: "5px"}}></div>
        <div               style= {
           {
            color: constants.secondaryColor,
                        borderRadius: '9px',
                        backgroundColor: '#e6ebf1',
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                        height: "50px",

            '::placeholder': {
              color: constants.secondaryColor,
            }
          }
          }>
        <CardCvcElement className="card-element" options={{
              style: {
          base: {
            color: constants.secondaryColor,
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                        height: "50px",
            '::placeholder': {
              color: constants.secondaryColor,
            }
          },
          invalid: {
            color: constants.secondaryColor,
            iconColor: '#fa755a'
          },
          }
        }} />         </div>

              {/* <CreditCardInput
  cardNumberInputProps={{ value: this.state.newCard.cardNo, onChange:(event) => {
                  this.setState({ newCard: {
                    ...this.state.newCard,
                    cardNo: event.target.value
                  } });
                },}}
  cardExpiryInputProps={{ value: this.state.newCard.expiry, onChange:(event) => {
                  this.setState({ newCard: {
                    ...this.state.newCard,
                    expiry: event.target.value
                  } });
                }, }}
  cardCVCInputProps={{ value: this.state.newCard.cvv, onChange:(event) => {
                  this.setState({ newCard: {
                    ...this.state.newCard,
                    cvv: event.target.value
                  } });
                }, }}
  fieldClassName="input"
/> */}
<div style={{padding: "5px"}}></div>
<TextField style={{                        color: constants.secondaryColor,
                         borderRadius: '9px',
                         backgroundColor: '#e6ebf1',
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',}} variant="filled" fullWidth label={strings["Nombre de la titular"]} id="fullWidthcard" value={this.state.newCard.name}  onChange={(event) => {
                  this.setState({ newCard: {
                    ...this.state.newCard,
                    name: event.target.value
                  } });
                }} />
                <div style={{padding: "5px"}}></div>
                <FormControlLabel control={<Checkbox
  checked={this.state.newCard.save}
  onChange={(event) => {
    this.setState({ newCard: {
                    ...this.state.newCard,
                    save: event.target.checked
                  } });
  }}
  inputProps={{ 'aria-label': 'controlled' }}
/>} label={strings['Guardar tarjeta']} />
                <div style={{padding: '5px 5px 15px 5px',textAlign: 'center',fontSize: '14px',color: 'slategray',}}>The transaction will be shown as <span style={{fontWeight: '600',color: '#434c55',}}>Y-GO EATS</span> on your bank statement</div>
                <Button fullWidth variant="contained" disabled={!stripe || this.state.loading}
                sx={{backgroundColor: getPrimaryColor(), borderRadius: '9px', height: '49px', boxShadow: '0 1px 7px'+getPrimaryColor()+69,}}
                onClick={async()=>{
                  // console.log(this.state.newCard);
                  var cardNo = this.state.newCard.cardNo.replaceAll(" ","");
                  var expMonth = this.state.newCard.expiry.replaceAll(" ","").split('/')[0];
                  var expYear = this.state.newCard.expiry.replaceAll(" ","").split('/')[1];
                  var cvv = this.state.newCard.cvv.replaceAll(" ","");
                  var name = this.state.newCard.name;
                  // console.log(cardNo, expMonth, expYear, cvv, name);
                  // const stripe = await loadStripe('pk_test_51Groo5F76HIlmZyWmhOPE8wDkPI40bvJrOmrhsPD4qCMKjPCIjgtuoPLzAwf0xhJHG1B95rGO5sOOgQlziO9P6Yb00tfKKwtpx');
                  // var elements = stripe.elements();
                  this.setState( {
                                            loading: true,
                                          });
                  const {paymentMethod, error} = await stripe.createPaymentMethod({
  type: 'card',
  card: elements.getElement(CardNumberElement),
  billing_details: {
    name: name,
  },
});
// console.log(error,'error');
// console.log('paymentMethod',paymentMethod,{
//                                         'id': paymentMethod.id,
//                                         // 'number':
//                                         // defaultCard['cardNumber']
//                                         //     .replaceAll(
//                                         //     new RegExp(r"\s+"),
//                                         //     ""),
//                                         // 'month': int.parse(
//                                         //     defaultCard['expiryDate']
//                                         //         .split('/')[0]),
//                                         // 'year': int.parse(
//                                         //     defaultCard['expiryDate']
//                                         //         .split('/')[1]),
//                                         // 'cvv': defaultCard['cvv'],
//                                         'custId': this.state.userDetails.custId,
//                                       });
if(!error) {
  if(this.state.newCard.save) {
  try {
                                    var response = await axios.post(
                                      'https://senor-order-85fa55d895ba.herokuapp.com/drivers/linkcustomer/',null,
                                        {
                                          params: {
                                        'id': paymentMethod.id,
                                        // 'number':
                                        // defaultCard['cardNumber']
                                        //     .replaceAll(
                                        //     new RegExp(r"\s+"),
                                        //     ""),
                                        // 'month': int.parse(
                                        //     defaultCard['expiryDate']
                                        //         .split('/')[0]),
                                        // 'year': int.parse(
                                        //     defaultCard['expiryDate']
                                        //         .split('/')[1]),
                                        // 'cvv': defaultCard['cvv'],
                                        'custId': this.state.userDetails.custId,
                                      },
                                        }
                                    );
                                    // console.log(response);
                                    if (response.status === 200) {
                                      // console.log(response.data['id']);
                                      if (response.data['id'] != null &&
                                          response.data['error'] == null) {
                                        this.state.cards.push(response.data);
                                        await updateDoc(doc(db, 'users', this.state.userId), {
                        cards:this.state.cards,
                      }).then((value)=>{
                        this.setState({
                              defaultCard: response.data,
                              showPayment: false,
                                            loading: false,
                                          });
                                          handleAddCardClose();
                                          // Navigator.pop(context);
                                          //await cardBox.delete(element['id']);
                                        });
                                        //if(mounted) {
                                        //   await processPaymentAsDirectCharge(
                                        //       response.data,
                                        //       (grandTotal() * 100)
                                        //           .round(),
                                        //       code);
                                        //}
                                      } else {
                                        // console.log(response.data);
                                        this.setState( {
                                            loading: false,
                                          });
                                        if (response.data['code']
                                            .toString()
                                            .includes('card_declined')) {

                                          openAlert(
                                              'error', strings["El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras"]);
                                        } else if (response.data['code']
                                                .toString()
                                                .contains('incorrect') ||
                                            response.data['code']
                                                .toString()
                                                .contains('invalid')) {
                                                  openAlert(
                                              'error', strings["Los datos de la tarjeta son incorrectos. Verifíquelos para continuar."]);
                                        } else {
                                          openAlert(
                                              'error', strings["Algo salió mal al crear el pago"]);
                                        }
                                      }
                                    } else {
                                      console.log('failed');
                                      this.setState( {
                                            loading: false,
                                          });
                                      openAlert(
                                              'error', strings["Algo salió mal al crear el pago"]);
                                    }
                                  } catch (e) {
                                    // console.log('exception $e',e);
                                    this.setState( {
                                            loading: false,
                                          });
                                    openAlert(
                                              'error', strings["Algo salió mal al crear el pago"]);
                                  }
                                } else {
                                  this.state.cards.push(JSON.parse(JSON.stringify(paymentMethod)));
                      //                   await updateDoc(doc(db, 'users', this.state.userId), {
                      //   cards:this.state.cards,
                      // }).then((value)=>{
                        this.setState({
                              defaultCard: JSON.parse(JSON.stringify(paymentMethod)),
                              showPayment: false,
                                            loading: false,
                                          });
                                          handleAddCardClose();
                                          // Navigator.pop(context);
                                          //await cardBox.delete(element['id']);
                                        // });
                                        //if(mounted) {
                                        //   await processPaymentAsDirectCharge(
                                        //       response.data,
                                        //       (grandTotal() * 100)
                                        //           .round(),
                                        //       code);
                                        //}
                                }
} else {
  this.setState( {
                                            loading: false,
                                          });
                                          openAlert(
                                              'error', strings["Algo salió mal al crear el pago"]);
}
                }}
                >{strings["Continuar"]}</Button>

{/* <PaymentInputsContainer>
      {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
        <div>
          <TextField fullWidth label="Card Number" id="fullWidthcardNo"  inputProps={getCardNumberProps({})}  value={this.state.newCard.cardNo}  onChange={(event) => {
                  this.setState({ newCard: {
                    ...this.state.newCard,
                    cardNo: event.target.value
                  } });
                }} />
          <input {...getCardNumberProps({ onChange: e => console.log('expiry2 change', e) })} value={this.state.newCard.cardNo} />
          <input {...getExpiryDateProps({ onChange: e => console.log('expiry3 change', e) })} value={this.state.newCard.expiry} />
          <input {...getCVCProps({ onChange: e => console.log('expiry4 change', e) })} value={this.state.newCard.cvv} />
          {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
          <TextField fullWidth label="Holder Name" id="fullWidthcard" />
        </div>
      )}
    </PaymentInputsContainer> */}

            </DialogContent>   )}
            </ElementsConsumer>
            </Elements></div>
          }
        />
                <RegisterDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openRegister}
          onClose={handleCloseRegister}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={this.state.businessData.logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '35px',
                  }}
                >
                  {strings['Bienvenido de regreso']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '15px',
                  }}
                >
                  {strings['Ingresa tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={this.state.fullName.length <= 0}
                fullWidth
                id="filled-basic23"
                label={strings['Nombre completo']}
                variant="filled"
                value={this.state.fullName}
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
              />
              <br />
              <br />
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic45"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings['Contraseña']}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                  }}
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl
                  variant="filled"
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings['Número de teléfono']}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                  />
                </FormControl>
              </Box>

              <br />
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: getPrimaryColor(), }}
                fullWidth
                variant="contained"
                sx={{backgroundColor: getPrimaryColor()}}
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6 &&
                    this.state.phoneNumber.length >= 10 &&
                    this.state.fullName.length > 0
                  ) {
                    this.setState({ loading: true });
                    createUserWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then(async (userCredential) => {
                        const user = userCredential.user;
                        const uid = user.uid;

                        var nameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var insertNameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var i = 0;
                        var dataInsert = '';
                        while (true) {
                          if (i === nameSearch.length) {
                            break;
                          }
                          if (dataInsert === '') {
                            dataInsert = nameSearch[i];
                          } else {
                            dataInsert = dataInsert + ' ' + nameSearch[i];
                            insertNameSearch.push(dataInsert);
                          }
                          i = i + 1;
                        }
                        await setDoc(doc(db, 'users', uid), {
                          fullName: this.state.fullName,
                          nameSearch: insertNameSearch,
                          email: this.state.email,
                          fromNew: true,
                          phoneNumber:
                            this.state.phoneCountry + this.state.phoneNumber,
                          loginType: 'Email/Password',
                          needcount: true,
                          orders: {
                            '0-2021': 0,
                            '1-2021': 0,
                            '2-2021': 0,
                            '3-2021': 0,
                            '4-2021': 0,
                            '5-2021': 0,
                            '6-2021': 0,
                            '7-2021': 0,
                            '8-2021': 0,
                            '9-2021': 0,
                            '10-2021': 0,
                            '11-2021': 0,
                          },
                          isSignedIn: false,
                          lastLogin: null,
                          lastOrder: null,
                          isTestUser: false,
        });

                        // sendOTP(
                        //   this.state.phoneCountry + this.state.phoneNumber
                        // );
                        this.setState({ loading: false, openRegister: false });
                        this.setState({
                          openAlert: true,
                          alertType: 'success',
                          alertMsg: strings['Datos correctos'],
                        });
                        this.setState({
                          userId: uid,
                          userDetails: {
                            fullName: this.state.fullName,
                            nameSearch: insertNameSearch,
                            email: this.state.email,
                            fromNew: true,
                            phoneNumber:
                              this.state.phoneCountry + this.state.phoneNumber,
                            loginType: 'Email/Password',
                            orders: {
                              '0-2021': 0,
                              '1-2021': 0,
                              '2-2021': 0,
                              '3-2021': 0,
                              '4-2021': 0,
                              '5-2021': 0,
                              '6-2021': 0,
                              '7-2021': 0,
                              '8-2021': 0,
                              '9-2021': 0,
                              '10-2021': 0,
                              '11-2021': 0,
                            },
                            isSignedIn: false,
                            lastLogin: null,
                            lastOrder: null,
                            isTestUser: false,
                          },
                          cards: [],
                        });
                        // handleClickOpenOTP();
                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        if(errorCode !== 'auth/email-already-in-use')
                        openAlert(
                          'error',
                          strings['Existe un error en el registro']
                        );
                        else {
                          openAlert(
                          'error',
                          strings['La identificación de correo electrónico ya está en uso']
                        );
                        }
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings['Crear cuenta']}
              </Button>
<Typography
                gutterBottom
                align="center"
                style={{ marginBottom: '0' }}
              >
                {strings['o']}
              </Typography>
              <br />
              <Button style={{ fontWeight: '600', height: '61px', borderRadius: '12px', color: "#000", boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: "#fff", }}
              sx={{ backgroundColor: "#fff" }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (true
                  ) {
                    this.setState({ loading: true });
                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();
                    auth.useDeviceLanguage();
                    signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    this.setState({ loading: false });
    openAlert('error', 'The data entered is not correct');
                    });
                  }
                }}
              > 
              <img src = {require('./assets/google.png')} alt = {"Google"} style={{height: '40px',width: 'auto',marginLeft:'5px',marginRight:'5px'}}/><div style={{width: "10px"}}></div>
                {strings['first2086']}
              </Button>
            </DialogContent>
          }
        />
        <RegisterAdditionalDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openAdditionalRegister}
          onClose={handleCloseAdditionalRegister}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={this.state.businessData.logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', objectFit: 'cover', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: this.state.fontType,
                    letterSpacing: 'normal',
                    marginTop: '35px',
                  }}
                >
                  {strings['Bienvenido de regreso']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: this.state.fontType,
                    letterSpacing: 'normal',
                    marginBottom: '15px',
                  }}
                >
                  {strings['Ingresa tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: '#272727',
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                  fontFamily: this.state.fontType,
                }}
                error={this.state.fullName.length <= 0}
                fullWidth
                id="filled-basic23"
                label={strings['Nombre completo']}
                variant="filled"
                value={this.state.fullName}
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
              />
              <br />
              <br />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  style={{
                    color: '#272727',
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    fontFamily: this.state.fontType,
                  }}
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl
                  variant="filled"
                  style={{
                    color: '#272727',
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    width: '100%',
                    fontFamily: this.state.fontType,
                  }}
                >
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings['Número de teléfono']}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                    //           endAdornment={
                    //             <InputAdornment position="end">
                    //               <IconButton
                    //                 aria-label="toggle password visibility"
                    //                 onClick={()=>{
                    //                   this.setState({'showPassword': !this.state.showPassword})
                    //                 }}
                    //                 onMouseDown={(event) => {
                    //   event.preventDefault();
                    // }}
                    //                 edge="end"
                    //               >
                    //                 {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    //               </IconButton>
                    //             </InputAdornment>
                    //           }
                  />
                </FormControl>
              </Box>

              <br />
              <br />
              <Button
              style={{fontFamily: this.state.fontType, height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: getPrimaryColor()}}
                fullWidth
                variant="contained"
                sx={{backgroundColor: getPrimaryColor()}}
                onClick={async () => {
                  if (
                    this.state.phoneNumber.length >= 9 &&
                    this.state.fullName.length > 0
                  ) {
                    this.setState({ loading: true });
                    // await axios.get(request).then(async (response) => {
                    //           console.log(response);
                    //           this.setState({ locOptions: response.data.predictions });
                    //         });
                    // createUserWithEmailAndPassword(
                    //   auth,
                    //   this.state.email,
                    //   this.state.password
                    // )
                    //   .then(async (userCredential) => {
                        // Signed in

                        // console.log('signing', userCredential);
                        const user = this.state.userCredential;
                        const uid = user.uid;


                        var nameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var insertNameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var i = 0;
                        var dataInsert = '';
                        while (true) {
                          if (i === nameSearch.length) {
                            break;
                          }
                          if (dataInsert === '') {
                            dataInsert = nameSearch[i];
                          } else {
                            dataInsert = dataInsert + ' ' + nameSearch[i];
                            insertNameSearch.push(dataInsert);
                          }
                          i = i + 1;
                        }
                        await setDoc(doc(db, 'users', uid), {
                          fullName: this.state.fullName,
                          nameSearch: insertNameSearch,
                          email: this.state.email,
                          fromNew: true,
                          phoneNumber:
                            this.state.phoneCountry + this.state.phoneNumber,
                          loginType: 'Email/Password',
                          needcount: true,
                          orders: {
                            '0-2021': 0,
                            '1-2021': 0,
                            '2-2021': 0,
                            '3-2021': 0,
                            '4-2021': 0,
                            '5-2021': 0,
                            '6-2021': 0,
                            '7-2021': 0,
                            '8-2021': 0,
                            '9-2021': 0,
                            '10-2021': 0,
                            '11-2021': 0,
                          },
                          isSignedIn: false,
                          lastLogin: null,
                          lastOrder: null,
                          isTestUser: false,
                        });

                        // sendOTP(
                        //   this.state.phoneCountry + this.state.phoneNumber
                        // );
                        this.setState({ loading: false, openAdditionalRegister: false });
                        this.setState({
                          openAlert: true,
                          alertType: 'success',
                          alertMsg: strings['Datos correctos'],
                        });
                        this.setState({
                          userId: uid,
                          userDetails: {
                            fullName: this.state.fullName,
                            nameSearch: insertNameSearch,
                            email: this.state.email,
                            fromNew: true,
                            phoneNumber:
                              this.state.phoneCountry + this.state.phoneNumber,
                            loginType: 'Email/Password',
                            orders: {
                              '0-2021': 0,
                              '1-2021': 0,
                              '2-2021': 0,
                              '3-2021': 0,
                              '4-2021': 0,
                              '5-2021': 0,
                              '6-2021': 0,
                              '7-2021': 0,
                              '8-2021': 0,
                              '9-2021': 0,
                              '10-2021': 0,
                              '11-2021': 0,
                            },
                            isSignedIn: false,
                            lastLogin: null,
                            lastOrder: null,
                            isTestUser: false,
                          },
                          cards: [],
                        });
                        // handleClickOpenOTP();
                        // this.setState({ userId: uid });
                        // ...
                      // })
                      // .catch((error) => {
                      //   const errorCode = error.code;
                      //   const errorMessage = error.message;
                      //   this.setState({ loading: false });
                      //   openAlert(
                      //     'error',
                      //     strings['Existe un error en el registro']
                      //   );
                      //   // console.log(errorMessage, errorCode);
                      // });
                  }
                }}
              >
                {strings['Crear cuenta']}
              </Button>
            </DialogContent>
          }
        />
        <GuestDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showAdditionalDetails}
          // onClose={handleCloseRegisterGuest}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={this.state.businessData.logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '65px',
                  }}
                >
                  {strings['Bienvenido']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '15px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '35px',
                  }}
                >
                  {strings['Proporcione detalles adicionales para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                error={this.state.fullName.length <= 0}
                fullWidth
                id="filled-basic23"
                label={strings['Nombre completo']}
                variant="filled"
                value={this.state.fullName}
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
              />
              <br />
              <br />
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic45"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                  }}
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl
                  variant="filled"
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 9 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings['Número de teléfono']}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 9 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                  />
                </FormControl>
              </Box>

              <br />
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: getPrimaryColor(), }}
                fullWidth
                variant="contained"
                sx={{backgroundColor: getPrimaryColor()}}
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.phoneNumber.length >= 9 &&
                    this.state.fullName.length > 0
                  ) {
                    this.setState({ guestDetails: {
                      'name': this.state.fullName,
        'email': this.state.email,
        'phone': this.state.phoneCountry + this.state.phoneNumber
                    },isGuest: true });
                  //  handleCloseRegisterGuest();
                    handleCartOpen2();
                  }
                }}
              >
                {strings['Guardar']}
              </Button>
            </DialogContent>
          }
        />
        <OTPDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openOTP}
          onClose={handleCloseOTP}
          displayList={
            <DialogContent dividers>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl variant="filled">
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                   {strings["Número de teléfono"]}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber.replace('+52','')}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                    //           endAdornment={
                    //             <InputAdornment position="end">
                    //               <IconButton
                    //                 aria-label="toggle password visibility"
                    //                 onClick={()=>{
                    //                   this.setState({'showPassword': !this.state.showPassword})
                    //                 }}
                    //                 onMouseDown={(event) => {
                    //   event.preventDefault();
                    // }}
                    //                 edge="end"
                    //               >
                    //                 {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    //               </IconButton>
                    //             </InputAdornment>
                    //           }
                  />
                </FormControl>
                &nbsp;&nbsp;
                <Button
                  variant="text"
                  style={{ textTransform: 'none' }}
                  onClick={async () => {
                    if (this.state.phoneNumber.length >= 10) {
                      this.setState({ loading: true });
                      await updateDoc(doc(db, 'users', this.state.userId), {
                        phoneNumber:
                          this.state.phoneCountry + this.state.phoneNumber,
                      });
              sendOTP(this.state.phoneCountry + this.state.phoneNumber);
                      this.setState({ loading: false });
                      this.setState({
                        openAlert: true,
                        alertType: 'success',
                        alertMsg: strings['Cambiar número Éxito'],
                      });
                      // this.setState({ userId: uid });
                      // ...
                      // })
                      // .catch((error) => {
                      //   const errorCode = error.code;
                      //   const errorMessage = error.message;
                      //   this.setState({loading: false});
                      //   openAlert('error',"Cant login");
                      //   console.log(errorMessage,errorCode);
                      // });
                    }
                  }}
                >
                  {strings["Reenviar"]}
                </Button>
              </Box>
              <br></br>
              <br></br>
              <Typography align={'center'}>
                {strings["Mensaje enviado correctamente al número anterior"]}
              </Typography>

              <Button
                fullWidth
                variant="text"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  sendOTP(this.state.phoneCountry + this.state.phoneNumber);
                }}
              ></Button>
              <br></br>
              <br></br>
              <OtpInput
                style={{ placeContent: 'center' }}
                align={'center'}
                inputStyle={{
                  width: '32px',
                  height: '32px',
                  fontSize: '17px',
                  fontWeight: 'bold',
                }}
                numInputs={6}
                onChange={(value) => {
                  this.setState({ otp: value });
                  // console.log(value, typeof value);
                }}
                separator={
                  <span style={{ width: '20px', height: '20px' }}></span>
                }
                isInputNum={true}
                shouldAutoFocus
                value={this.state.otp}
              />
              <br />
              <br />
              <Button
                fullWidth
                variant="contained"
                sx={{ backgroundColor: getPrimaryColor() }}
                onClick={async () => {
                  if (this.state.otp === this.state.otpSend) {
                    this.setState({ loading: true });
                    await updateDoc(doc(db, 'users', this.state.userId), {
                      phoneNumberVerified: true,
                    });
                    this.setState({ loading: false, openOTP: false });
                    this.setState({
                      openAlert: true,
                      alertType: 'success',
                      alertMsg: strings['Has ingresado correctamente'],
                    });
                    // this.setState({ userId: uid });
                    // ...
                    // })
                    // .catch((error) => {
                    //   const errorCode = error.code;
                    //   const errorMessage = error.message;
                    //   this.setState({loading: false});
                    //   openAlert('error',"Cant login");
                    //   console.log(errorMessage,errorCode);
                    // });
                  } else {
                    this.setState({
                      openAlert: true,
                      alertType: 'error',
                      alertMsg:
                        strings['El código ingresado no es correcto, intenta nuevamente'],
                    });
                  }
                }}
              >
                {strings["Ingresar"]}
              </Button>
            </DialogContent>
          }
        />
        <BusinessInfoDialog
          open={this.state.openBusinessInfo}
          onClose={() => {
            this.setState({ openBusinessInfo: false });
          }}
          displayList={
            <DialogContent dividers>
              <Typography
                style={{
                  fontWeight: 'bolder',
                  fontSize: '20px',
                  color: '#2c3036',
                }}
              >
                {this.state.businessData?.name}
              </Typography>
              <Typography
                fontWeight={'400'}
                fontSize={16}
                style={{ color: 'slategray', marginBottom: '10px' }}
              >
                {this.state.businessData?.description}
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography
                  fontWeight={'bold'}
                  fontSize={16}
                  style={{ color: '#2c3036', marginBottom: '10px' }}
                >
                  {strings["Calificación:"]}&nbsp;
                </Typography>
                <Typography
                  gutterBottom
                  fontSize={16}
                  marginLeft={'2px'}
                  style={{ color: 'slategray', marginBottom: '10px' }}
                >
                  {this.state.businessData.averageRating.toFixed(2)}
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography
                  fontWeight={'bold'}
                  fontSize={16}
                  style={{ color: '#2c3036', marginBottom: '10px' }}
                >
                  {strings["Correo:"]}&nbsp;
                </Typography>
                <Typography
                  gutterBottom
                  fontSize={16}
                  marginLeft={'2px'}
                  style={{ color: 'slategray', marginBottom: '10px' }}
                >
                  {this.state.businessData?.email}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '16px',
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={16}
                  style={{ color: '#2c3036', marginBottom: '10px' }}
                >
                  {strings["Teléfono"]}&nbsp;
                </Typography>
                <Typography
                  gutterBottom
                  fontSize={16}
                  marginLeft={'2px'}
                  style={{ color: 'slategray', marginBottom: '10px' }}
                >
                  {this.state.businessData?.phone}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '16px',
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  fontSize={16}
                  style={{ color: '#2c3036', marginBottom: '20px' }}
                >
                  {strings["Dirección:"]}&nbsp;
                </Typography>
                <Typography
                  gutterBottom
                  fontSize={16}
                  marginLeft={'2px'}
                  style={{ color: 'slategray', marginBottom: '20px' }}
                >
                  {this.state.businessData?.location?.address}
                </Typography>
              </div>
              {Object.keys(this.state.businessData?.schedule).length > 0 && (
                <Box style={{ width: '100%' }}>
                  <Box
                    style={{
                      width: '102%',
                      display: 'flex',
                      overflowX: 'auto',
                    }}
                  >
                    <div style={{ display: 'flex', width: '100%' }}>
                      {this.state.businessData?.schedule?.map((schedule, i) => (
                        <ScheduleBlock key={i} color={getPrimaryColor()}>
                          <h4>{DAYS[i]}</h4>
                          {schedule.enabled ? (
                            schedule.lapses.map((time, k) => (
                              <React.Fragment key={k}>
                                <p>{scheduleFormatted(time.open)}</p>
                                <p
                                  style={{
                                    borderBottom: '2px solid',
                                    borderBottomColor: getPrimaryColor(),
                                    marginBottom: 10,
                                  }}
                                >
                                  {scheduleFormatted(time.close)}
                                </p>
                              </React.Fragment>
                            ))
                          ) : (
                            <p style={{ fontWeight: 500 }}>{strings["closed_today"]}</p>
                          )}
                        </ScheduleBlock>
                      ))}
                    </div>
                  </Box>
                </Box>
              )}

              <br />
              <>
                <GoogleMapReact
                  defaultCenter={{
                    lat: this.state.businessData?.location?.latitude,
                    lng: this.state.businessData?.location?.longitude,
                  }}
                  defaultZoom={this.state.defaultProps.zoom}
                  resetBoundsOnResize={true}
                  bootstrapURLKeys={{
                    key: constants.googleMapsAPI,
                  }}
                  style={{ height: '41vh', borderRadius: '16px', overflow: 'hidden', }}
                >
                  <LocationOnIcon
                    style={{
                      color: getPrimaryColor(),
                      transform: 'translate(-50%, -100%)',
                    }}
                    fontSize={'large'}
                    lat={this.state.businessData?.location?.latitude}
                    lng={this.state.businessData?.location?.longitude}
                  />
                </GoogleMapReact>
              </>
            </DialogContent>
          }
        />
                        <SimpleDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLocation}
          onClose={handleClose}
          displayList={
            <DialogContent dividers>
              {this.state.addresses <= 0 ?
                <div style={{ textAlign: 'center', marginBottom: '12px', }}>
                  <img src = {require('./assets/location_home.png')} alt = {"Location"} style={{ height: '60px',width: 'auto', }}/>
                </div>
              :null}
              {this.state.addresses <= 0 ?
                <Typography style={{ fontSize: '20px', fontWeight: '800', letterSpacing: 'normal', color: '#2e2c36', marginBottom: '26px', textAlign: 'center', }}>
                  {strings["Selecciona tu ubicación o ingrese una nueva dirección2"]}
                </Typography>
              :null}
              {this.state.addresses <= 0 ?
                <Typography gutterBottom style={{ textAlign: 'center', }}>
                  {strings["Selecciona tu ubicación o ingrese una nueva dirección4"]}
                </Typography>
              :null}
              {this.state.addresses != 0 ?
              <Typography gutterBottom>
                {strings["Selecciona tu ubicación o ingrese una nueva dirección"]}
              </Typography>
              :null}
              <br />
              <Autocomplete
                style={{ width: '100%' }}
                id="google-map-demo"
                sx={{ width: 300 }}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={this.state.locOptions}
                loading={true}
                loadingText={strings['Buscando direcciones']}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={this.state.autoLoc}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  this.setState({ hasClickedLoc: false });
                  if (newValue.description) {

                    Geocode.setApiKey(
                      constants.googleMapsAPI
                    );
                    Geocode.setLanguage('es');
                    Geocode.setRegion('MX');
                    Geocode.setLocationType('ROOFTOP');
                    Geocode.enableDebug();
                    Geocode.fromAddress(newValue.description).then(
                      (response) => {
                        const { lat, lng } =
                          response.results[0].geometry.location;
                        // console.log(lat, lng);
                        this.setState({
                          defaultProps: {
                            center: {
                              lat: lat,
                              lng: lng,
                            },
                            zoom: 16,
                          },
                          defaultPropsCircle: {
                            center: {
                              lat: lat,
                              lng: lng,
                            },
                            zoom: 16,
                          },
                          selectLocationData: {
                            id: this.state.selectLocationData.id
                              ? this.state.selectLocationData.id
                              : '',
                            name: this.state.selectLocationData.name
                              ? this.state.selectLocationData.name
                              : strings['mi hogar'],
                            address: this.state.selectLocationData.address
                              ? this.state.selectLocationData.address
                              : '',
                            geoAddress: newValue.description,
                            addressNotes: this.state.selectLocationData
                              .addressNotes
                              ? this.state.selectLocationData.addressNotes
                              : '',
                            latitude: lat,
                            longitude: lng,
                          },
                        });
                        this.setState({ hasClickedLoc: true });
                        this.setState({ autoLoc: newValue });
                      },
                      (error) => {
                        console.error(error);
                      }
                    );
                  }
                  // setOptions(newValue ? [newValue, ...options] : options);
                  // setValue(newValue);
                }}
                onInputChange={_.debounce(async (event, newInputValue) => {
                  // setInputValue(newInputValue);
                  const { value } = event.target;
                  if (newInputValue.length > 4) {
                    var API_KEY = constants.googleMapsAPI;
                    // var type = '(regions)';
                    var baseURL =
                      'https://senor-order-85fa55d895ba.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                    var request =
                      baseURL +
                      '?input=' +
                      value +
                      '&key=' +
                      API_KEY +
                      '&sessiontoken=' +
                      _sessionToken;
                    await axios.get(request).then(async (response) => {
                      // console.log(response);
                      this.setState({ locOptions: response.data.predictions });
                    });
                  }
                },2000)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="filled-basic"
                    label={strings["Ingrese la dirección de entrega"]}
                    variant="filled"
                    style={{ width: '100%' }}
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocationOnIcon}
                            sx={{ color: 'text.secondary', mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}

                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              {/* <TextField id="filled-basic" label="Enter delivery address" variant="filled" style={{width: "100%"}}
                  onChange={async (event) => {
          const { value } = event.target;
          if(value.length>4) {
            var API_KEY =
                                                "AIzaSyDG8OvakDZ2x2OonPWn2Yj_kmFlI1LUXvM";
                                                var type = '(regions)';
                                                var baseURL =
                                                'https://senor-order-85fa55d895ba.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                                                var request =
                                                baseURL+'?input='+value+'&key='+API_KEY+'&sessiontoken='+_sessionToken;
                                                await axios.get(request).then(async (response) => {
                                                  console.log(response);
                                                })
                                              }
        }}
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
        }}
          /> */}
              {this.state.hasClickedLoc ? (
                <>
                  <br />
                  <GoogleMapReact
                    defaultCenter={this.state.defaultProps.center}
                    defaultZoom={this.state.defaultProps.zoom}
                    resetBoundsOnResize={true}
                    yesIWantToUseGoogleMapApiInternals
  onGoogleApiLoaded={({ map, maps }) => {
    var circle = new maps.Circle({
      strokeColor: getPrimaryColor(),
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: getPrimaryColor(),
      fillOpacity: 0.3,
      map,
      center: {lat: this.state.defaultPropsCircle.center.lat, lng: this.state.defaultPropsCircle.center.lng},
      radius: 100,
    });
    this.setState({
      circleData: circle
    })
  }}
                    options={{
                      fullscreenControl: false,
                      scrollwheel: false,
                      panControl: false,
                      zoomControl: false,
                      rotateControl: false,
                    }}
                    bootstrapURLKeys={{
                      key: constants.googleMapsAPI,
                    }}
                    style={{ height: '23vh' }}
                    onChange={(changeData) => {
                      // console.log(changeData);
                      var dist = geolib.getDistance(
                        {
                          latitude: changeData.center.lat,
                          longitude: changeData.center.lng,
                        },
                        {
                          latitude: this.state.defaultPropsCircle.center.lat,
                          longitude: this.state.defaultPropsCircle.center.lng,
                        }
                      );
                      if(dist<=100) {
                      this.setState({
                        defaultProps: {
                          center: {
                            lat: changeData.center.lat,
                            lng: changeData.center.lng,
                          },
                          zoom: changeData.zoom,
                        },
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          latitude: changeData.center.lat,
                          longitude: changeData.center.lng,
                        },
                      });
                    } else {
                      Geocode.setApiKey(
                        constants.googleMapsAPI
                      );
                      Geocode.setLanguage('es');
                      Geocode.setRegion('MX');
                      Geocode.setLocationType('ROOFTOP');
                      Geocode.enableDebug();
                      Geocode.fromLatLng(changeData.center.lat,changeData.center.lng).then(
                      // Geocode.fromAddress(newValue.description).then(
                        (response) => {
                          this.state.circleData.setCenter({lat: changeData.center.lat, lng: changeData.center.lng});
                          // const { lat, lng } =
                          //   response.results[0].geometry.location;
                          // console.log(lat, lng);
                          this.setState({
                            defaultProps: {
                              center: {
                                lat: changeData.center.lat,
                                lng: changeData.center.lng,
                              },
                              zoom: 16,
                            },
                            defaultPropsCircle: {
                              center: {
                                lat: changeData.center.lat,
                                lng: changeData.center.lng,
                              },
                              zoom: 16,
                            },
                            selectLocationData: {
                              id: this.state.selectLocationData.id,
                              name: this.state.selectLocationData.name,
                              address: this.state.selectLocationData.address,
                              geoAddress: this.state.selectLocationData.geoAddress,
                              addressNotes:
                                this.state.selectLocationData.addressNotes,
                              latitude: changeData.center.lat,
                              longitude: changeData.center.lng,
                            },
                          });
                          this.setState({ hasClickedLoc: true });
                          this.setState({ autoLoc: response.results[0].formatted_address });
                        },
                        (error) => {
                          console.error(error);
                        }
                      );
                    }
                    }}
                  >
                    {/* <div
        style={{  display: "flex",
  width: "50px",
  opacity: "0.5",
  height: "50px",
  backgroundColor: "green",
  borderRadius: "50%"}}
        lat={this.state.defaultCircleProps.center.lat}
          lng={this.state.defaultCircleProps.center.lng}
      /> */}
                    <LocationOnIcon
                      style={{
                        color: getPrimaryColor(),
                        transform: 'translate(-50%, -100%)',
                      }}
                      fontSize={'large'}
                      lat={this.state.defaultProps.center.lat}
                      lng={this.state.defaultProps.center.lng}
                      // text={'Google Map'}
                    />
                  </GoogleMapReact>
                  <br />
                  <TextField
                    fullWidth
                    id="filled-basic"
                    label={strings["Detalles adicionales"]}
                    variant="filled"
                    value={this.state.selectLocationData.addressNotes}
                    onChange={(event) => {
                      this.setState({
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes: event.target.value,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        },
                      });
                    }}
                  />
                  <br />
                  <br />
                  <ToggleButtonGroup
                    fullWidth
                    // color="primary"
                    value={this.state.selectLocationData.name}
                    exclusive
                    onChange={(event, newAlignment) => {
                      this.setState({
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: newAlignment,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        },
                      });
                    }}
                  >
                    <ToggleButton
                      value={strings["mi hogar"]}
                      style={{ color: getPrimaryColor() }}
                    >
                      {strings["Hogar"]}
                    </ToggleButton>
                    <ToggleButton
                      value={strings["mi Trabajo"]}
                      style={{ color: getPrimaryColor() }}
                    >
                      {strings["Trabajo"]}
                    </ToggleButton>
                    <ToggleButton
                      value={strings["otra dirección"]}
                      style={{ color: getPrimaryColor() }}
                    >
                      {strings["Visita"]}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <br />
                  <br />
                  <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ hasClickedLoc: false });
                      this.setState({ autoLoc: '' });
                      this.setState({
                        selectLocationData: {
                          id: '',
                          name: strings['mi hogar'],
                          address: '',
                          geoAddress: '',
                          addressNotes: '',
                          latitude: this.state.defaultProps.center.lat,
                          longitude: this.state.defaultProps.center.lng,
                        },
                      });
                    }}
                  >
                    {strings["Cancelar"]}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ backgroundColor: getPrimaryColor() }}
                    style={{
                      float: 'right',
                      color: '#ffffff',
                      boxShadow: 'none',
                    }}
                    onClick={() => {
                      var locationData = JSON.parse(
      localStorage.getItem('location')
    );
                      if (locationData == null) {
                        locationData = [];
                      }
                      if (this.state.selectLocationData.id === '') {
                        for (var i = 0; i < locationData.length; i++) {
                          locationData[i]['selected'] = false;
                        }
                        var idLoc = uuidv4();
                        locationData.push({
                          id: idLoc,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          selected: true,
                        });
                        // console.log('locationData', locationData);
                        var selected = {
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        };
                        try {
                        localStorage.setItem(
                          'selected',
                          JSON.stringify(selected)
                        );
                        window.dispatchEvent(new Event("selected"));
                        } catch(e){}
                        if (selected == null) {
                          selected = { latitude: 0, longitude: 0 };
                        }
                        this.setState(
                          {
                            selectedAddress: selected,
                            selectLocationName:
                              this.state.selectLocationData.geoAddress,
                          },
                          (newState) => {
                            handleClose();
                            // console.log(
                            //   'selectedAddress',
                            //   this.state.selectedAddress,
                            //   typeof this.state.selectedAddress
                            // );
                          }
                        );
                      } else {
                        for (var i = 0; i < locationData.length; i++) {
                          locationData[i]['selected'] = false;
                        }
                        const indexOfTaskToDelete = locationData.findIndex(
                          (task) => task.id === this.state.selectLocationData.id
                        );
                        locationData.splice(indexOfTaskToDelete, 1);
                        locationData.push({
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          selected: true,
                        });
                        var selected2 = {
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        };
                        if (this.state.userId && this.state.userId !== '') {
                          updateDoc(doc(db, 'users', this.state.userId), {
                            locations: locationData,
                          });
                        }
                        try {
                        localStorage.setItem(
                          'selected',
                          JSON.stringify(selected2)
                        );
                        window.dispatchEvent(new Event("selected"));
                        } catch(e) {}
                        if (selected2 == null) {
                          selected2 = { latitude: 0, longitude: 0 };
                        }
                        this.setState(
                          {
                            selectedAddress: selected2,
                            selectLocationName:
                              this.state.selectLocationData.geoAddress,
                          },
                          (newState) => {
                            handleClose();
                            // console.log(
                            //   'selectedAddress',
                            //   this.state.selectedAddress,
                            //   typeof this.state.selectedAddress
                            // );
                          }
                        );
                      }
                      this.setState({ addresses: locationData });
                      try {
                      localStorage.setItem(
                        'location',
                        JSON.stringify(locationData)
                      );
                      window.dispatchEvent(new Event("location"));
                      } catch(e) {}
                      this.setState({ hasClickedLoc: false });
                      this.setState({ autoLoc: '' });
                      this.setState({
                        selectLocationData: {
                          id: '',
                          name: strings['mi hogar'],
                          address: '',
                          geoAddress: '',
                          addressNotes: '',
                          latitude: this.state.defaultProps.center.lat,
                          longitude: this.state.defaultProps.center.lng,
                        },
                      });
                      this.setState(
                        { canDeliverHere: false, businessData: null },
                        (newState) => {
                          handleClose();
                          const { id } = this.props.match.params;
                          // this.setState({ businessData: {} });
                          this.getCities(id);
                        }
                      );
                    }}
                  >
                    {strings["Guardar"]}
                  </Button>
                </>
              ) : (
                <>
                  {/* <GoogleMapReact
                defaultCenter={this.state.defaultProps.center}
        defaultZoom={this.state.defaultProps.zoom}
        resetBoundsOnResize={true}
        bootstrapURLKeys={{ key: constants.googleMapsAPI }}
        style={{height: '50px'}}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text={'Google Map'}
        />
      </GoogleMapReact> */}
                  <br />
                  <List sx={{ pt: 0 }}>
                    {this.state.addresses != null &&
                    Array.isArray(this.state.addresses)
                      ? this.state.addresses.map((email, ind) => (
                          <div key={ind}>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '1px solid #e6ebf1',
                                padding: '5px 0px 5px 0px',
                                height: '75px',
                              }}
                            >
                              <div
                                style={{
                                  width: 'calc(30px)',
                                  float: 'left',
                                  padding: '18px 0px 0px 0px',
                                }}
                              >
                                <LocationOnIcon
                                  style={{
                                    color: email.selected
                                      ? getPrimaryColor()
                                      : '#000',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: 'calc(100% - 31px)',
                                  float: 'right',
                                }}
                              >
                                <div
                                  style={{
                                    width: 'calc(100% - 55px)',
                                    float: 'left',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    var locationData;
                                        try {
                                          locationData = JSON.parse(
                                          localStorage.getItem('location')
                                        );
                                    } catch(e) {
                                    console.log(e);
                                    }
                                    if (locationData == null) {
                                      locationData = [];
                                    }
                                    for (
                                      var i = 0;
                                      i < locationData.length;
                                      i++
                                    ) {
                                      locationData[i]['selected'] = false;
                                    }
                                    const indexOfTaskToDelete =
                                      locationData.findIndex(
                                        (task) => task.id === email.id
                                      );
                                    locationData.splice(indexOfTaskToDelete, 1);
                                    locationData.push({
                                      id: email.id,
                                      name: email.name,
                                      address: email.address,
                                      latitude: email.latitude,
                                      longitude: email.longitude,
                                      geoAddress: email.geoAddress,
                                      addressNotes: email.addressNotes,
                                      selected: true,
                                    });
                                    var selected2 = {
                                      latitude: email.latitude,
                                      longitude: email.longitude,
                                    };
                                    if (
                                      this.state.userId &&
                                      this.state.userId !== ''
                                    ) {
                                      updateDoc(
                                        doc(db, 'users', this.state.userId),
                                        {
                                          locations: locationData,
                                        }
                                      );
                                    }
                                    try {
                                    localStorage.setItem(
                                      'selected',
                                      JSON.stringify(selected2)
                                    );
                                    window.dispatchEvent(new Event("selected"));
                                    } catch(e) {}
                                    if (selected2 == null) {
                                      selected2 = { latitude: 0, longitude: 0 };
                                    }
                                    this.setState(
                                      {
                                        selectedAddress: selected2,
                                        selectLocationName: email.geoAddress,
                                      },
                                      (newState) => {
                                        handleClose();
                                        this.setState({ addresses: locationData });
                                        try {
                                    localStorage.setItem(
                                      'location',
                                      JSON.stringify(locationData)
                                    );
                                    window.dispatchEvent(new Event("location"));
                                        } catch(e) {}
                                    this.setState({
                                      canDeliverHere: false,
                                      businessData: null,
                                    });
                                    // handleClose();
                                    const { id } = this.props.match.params;
                                    // this.setState({ businessData: {} });
                                    this.getCities(id);
                                        // console.log(
                                        //   'selectedAddress',
                                        //   this.state.selectedAddress,
                                        //   typeof this.state.selectedAddress
                                        // );
                                      }
                                    );
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '700',
                                      textTransform: 'capitalize',
                                      color: 'slategray',
                                    }}
                                  >
                                    {email.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{ fontSize: '17px', fontWeight: '700', }}
                                  >
                                    {email.geoAddress}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      color: 'slategray',
                                    }}
                                  >
                                    {email.addressNotes}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    width: 'calc(54px)',
                                    float: 'right',
                                    padding: '18px 0px 0px 0px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span>
                                    <EditIcon
                                      style={{
                                        color: email.selected
                                          ? getPrimaryColor()
                                          : '#000',
                                      }}
                                      onClick={() => {
                                        // this.setState({ hasClickedLoc: false });
                                        // console.log(email);
                                        if (email.latitude != null) {
                                          // const { lat, lng } = response.results[0].geometry.location;

                                          this.setState({
                                            defaultProps: {
                                              center: {
                                                lat: email.latitude,
                                                lng: email.longitude,
                                              },
                                              zoom: 16,
                                            },
                                            defaultPropsCircle: {
                                              center: {
                                                lat: email.latitude,
                                                lng: email.longitude,
                                              },
                                              zoom: 16,
                                            },
                                            selectLocationData: {
                                              id: email.id,
                                              name: email.name,
                                              address: email.address,
                                              geoAddress: email.geoAddress,
                                              addressNotes: email.addressNotes,
                                              latitude: email.latitude,
                                              longitude: email.longitude,
                                            },
                                          });
                                          this.setState({
                                            autoLoc: email.geoAddress,
                                          });
                                          this.setState({
                                            hasClickedLoc: true,
                                          });
                                        }
                                        // setOptions(newValue ? [newValue, ...options] : options);
                                        // setValue(newValue);
                                        // );
                                      }}
                                    />
                                    &nbsp;
                                    {email.selected ? null : (
                                      <DeleteForeverIcon
                                      onClick={
                                        ()=>{
                                          var locationData = this.state.addresses;
                        const indexOfTaskToDelete = locationData.findIndex(
                          (task) => task.id === email.id
                        );
                        locationData.splice(indexOfTaskToDelete, 1);
                        if (this.state.userId && this.state.userId !== '') {
                          updateDoc(doc(db, 'users', this.state.userId), {
                            locations: locationData,
                          });
                        }
                        this.setState({ addresses: locationData });
                        try {
                      localStorage.setItem(
                        'location',
                        JSON.stringify(locationData)
                      );
                      window.dispatchEvent(new Event("location"));
                        } catch(e) {}
                                        }
                                      }
                                        style={{ color: '#e53837' }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </List>

                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      startIcon={<MyLocationIcon />}
                      disableElevation
                      align="center"
                      style={{
                        height: '61px',
                        borderRadius: '12px',
                       //boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px',
                       //backgroundColor: getPrimaryColor(),
                       backgroundColor: 'white',
                       textTransform: 'none',
                        fontSize: '16px',
                      }}
                      onClick={async () => {
                        await navigator.geolocation.getCurrentPosition(
                          (position) => {
                            // console.log(position.coords.latitude);
                            this.setState({ hasClickedLoc: false });
                            if (position.coords.latitude != null) {
                              Geocode.setApiKey(
                                constants.googleMapsAPI
                              );
                              Geocode.setLanguage('es');
                              Geocode.setRegion('MX');
                              Geocode.setLocationType('ROOFTOP');
                              Geocode.enableDebug();
                              Geocode.fromLatLng(
                                position.coords.latitude,
                                position.coords.longitude
                              ).then(
                                (response) => {
                                  // const { lat, lng } = response.results[0].geometry.location;
                                  // console.log(lat, lng);
                                  this.setState({
                                    defaultProps: {
                                      center: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                      },
                                      zoom: 16,
                                    },
                                    defaultPropsCircle: {
                                      center: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                      },
                                      zoom: 16,
                                    },
                                    selectLocationData: {
                                      id: this.state.selectLocationData.id
                                        ? this.state.selectLocationData.id
                                        : '',
                                      name: this.state.selectLocationData.name
                                        ? this.state.selectLocationData.name
                                        : strings['mi hogar'],
                                      address: this.state.selectLocationData
                                        .address
                                        ? this.state.selectLocationData.address
                                        : '',
                                      geoAddress:
                                        response.results[0].formatted_address,
                                      addressNotes: this.state
                                        .selectLocationData.addressNotes
                                        ? this.state.selectLocationData
                                            .addressNotes
                                        : '',
                                      latitude: position.coords.latitude,
                                      longitude: position.coords.longitude,
                                    },
                                  });
                                  this.setState({ hasClickedLoc: true });
                                  this.setState({
                                    autoLoc:
                                      response.results[0].formatted_address,
                                  });
                                },
                                (error) => {
                                  console.error(error);
                                }
                              );
                            }
                            // setOptions(newValue ? [newValue, ...options] : options);
                            // setValue(newValue);
                          },
                          (err) => console.log(err)
                        );
                      }}
                    >
                      {strings["Usar mi ubicación actual"]}
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
          }
        />
                        <AlertDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openDialogAlert}
          displayTitle={""}
          onClose={handleAlertClose}
          displayList={
            <DialogContent dividers style={{ textAlign: 'center', }}>
              <Typography gutterBottom style={{
                                    fontSize: '19.5px',
                                    fontWeight: '600',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}>
                {strings['No hay una ubicación de entrega seleccionada o la ubicación de entrega seleccionada no es reparable']}
              </Typography>
              <img src = {require('./assets/sad_face.png')} alt = {"no delivery"} style={{height: '80px',width: 'auto',marginLeft:'5px',marginRight:'5px'}}/>
              <br />
              <Stack
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
  spacing={2}
>
              <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ openDialogAlert: false });
                    }}
                  >
                    {'OK'}
                  </Button></Stack>
            </DialogContent>
          }
        />
                                <AlertDialog2
          selectedValue={this.state.selectedValue}
          open={this.state.openDialogCancel}
          displayTitle={""}
          onClose={handleAlertCancelClose}
          displayList={
            <DialogContent dividers style={{ textAlign: 'center', }}>
              <Typography gutterBottom style={{
                                    fontSize: '19.5px',
                                    fontWeight: '600',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}>
                {strings['Su pedido ha sido cancelado con éxito.']}
              </Typography>
              <Stack
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
  spacing={2}
>
              <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ openDialogCancel: false });
                    }}
                  >
                    {'OK'}
                  </Button></Stack>
            </DialogContent>
          }
        />
                <MinimumDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showMinimum}
          displayTitle={strings['Añadir más productos']}
          onClose={handleMinimumClose}
          displayList={
            <DialogContent dividers>
              <Typography gutterBottom style={{
                                    fontSize: '17.5px',
                                    fontWeight: '400',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}>
                {strings["La compra mínima debe ser de $"]+((this.state.businessData && this.state.businessData.minimumPurchase)?this.state.businessData.minimumPurchase.toFixed(2):'0')}
              </Typography>
              <br />
              <Stack
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
  spacing={2}
>
              <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ showMinimum: false });
                    }}
                  >
                    {'OK'}
                  </Button></Stack>
            </DialogContent>
          }
        />
        <MyCardsDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openCards}
          onClose={handleCardClose}
          displayList={
            <DialogContent dividers>
              <>
                                              {this.state.cards.map((items, t) => (
                                                <div style={{padding: "8px"}}>
                      <div style={{borderRadius: "10px", backgroundColor: "#f8f9fb", padding: "0px 8px 0px"}}>
                      <Stack
                  direction={'row'}
                  spacing={0}
                  justifyContent="space-between"
                              alignItems="center"
                >
                  <Stack
                  direction={'row'}
                  spacing={0}
                  justifyContent="flex-start"
                              alignItems="center"
                              style={{padding: "15px 15px 15px"}}
                >
                   <img
                                style={{
                                  width: '25px',
                                  height:"auto",
                                  border: '0',
                                  marginRight: "15px"
                                }}

                                src={(items['card']['brand']
                                                      .toString()
                                                      .toLowerCase()
                                                      .includes('visa'))
                                                  ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fvisa.png?alt=media&token=1925130f-c1f1-40cf-96ce-07b6d12fb826"
                                                  : (items['card']
                                                              ['brand']
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes('master'))
                                                      ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fmastercard.png?alt=media&token=aed207cf-4abf-4617-9554-c7e4edb66d66"
                                                      : (items['card']
                                                                  ['brand']
                                                              .toString()
                                                              .toLowerCase()
                                                              .includes(
                                                                  'discover'))
                                                          ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiscover.png?alt=media&token=3321b25f-44d1-429a-916a-b10b7bf3fdc0"
                                                          : (items
                                                                          ['card']
                                                                      ['brand']
                                                                  .toString()
                                                                  .toLowerCase()
                                                                  .includes('diners'))
                                                              ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiners.png?alt=media&token=c08b27e3-a721-4fc0-9819-657716d471f4"
                                                              :"https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png"}
                              />
                        <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      width: `${this.state.width > 600 ? 300: this.state.width - 175}px`,
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      overflow: 'auto',
                      overflowWrap:'anywhere',

                    }}
                  >
                    {"  "+strings['cardData241']+items['card']['card_number']
                                                  .toString()}
                  </Typography></Stack>
                 { (this.state.userDetails?.subscription == null ||
                                      this.state.userDetails?.subscription['default_payment_method'] ==
                                          null ||
                                      (this.state.userDetails?.subscription['status'] !== 'active' &&
                                      this.state.userDetails?.subscription['status'] !==
                                              'trialing') ||
                                      items['id'] !==
                                      this.state.userDetails?.subscription[
                                              'default_payment_method'])? <DeleteForeverIcon
                  onClick={
                    async()=>{
                      this.setState({
                              loading: true
                            });
                      var temp = this.state.cards;
                      var deletedCardId = items['id'];
                      // var index = temp.indexOf(items);
                      if(t>=0) {
                        temp.splice(t,1);
                      }
                      // console.log(temp, t);
                            this.setState({
                              cards: temp
                            });
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              cards:temp,
            });
            try {
              await axios
                                      .post(
                                        'https://senor-order-85fa55d895ba.herokuapp.com/drivers/detachcustomer/',
                                        null,
                                        {
                                          params: {
                                            id: deletedCardId,
                                          },
                                        }
                                      )
                                      .then(async (response) => {
                                        this.setState({ loading: false });
                                        // console.log(response);
                                      })
                                      .catch((e) => {
                                        this.setState({ loading: false });
                                      });

            } catch(e) {
              this.setState({ loading: false });
            }
                    }
                  }
                                        style={{ color: '#e53837',cursor: 'pointer', }}
                                      />:null }
                  </Stack></div></div>
                                              ))}
                                            </>
                                            <>
                    {(this.state.businessData && !this.state.businessData.cashOnly && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(0))) ? (
                      <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                          onClick={()=>{
                            handleClickAddCardOpen();
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                    'url("https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Agregar nueva tarjeta2"]}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null}
                  </>
            </DialogContent>
          }
        />
        <WalletDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openWallet}
          onClose={handleWalletClose}
          displayList={
            <DialogContent dividers>
                <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              >
                <div style={{paddingLeft: "5px", marginBottom: "10px"}}
                >
                  <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Disponible:"]+"$"+this.state.balance.toFixed(2)}
                                </Typography>
                </div>
                {(this.state.showOtherWallet)?<>
                <div style={{padding:"10px 0px", width: "100%"}}>
                <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '9px',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                fullWidth
                id="filled-basic-wallet"
                label={strings["Ingresa el monto a utilizar en esta orden"]}
                variant="filled"
                value={this.state.enteredAmount}
                onChange={(event) => {
                  if(isNaN(Number(event.target.value))) {
                    this.setState({ enteredAmount: event.target.value });
                  } else
                  this.setState({ enteredAmount: Number(event.target.value) });
                }}
              />
                </div>
                <div style={{paddingLeft: "5px", marginBottom: "10px"}}
                >
                  <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Máximo posible en esta orden: "]+"$"+walletMax().toFixed(2)}
                                </Typography>
                </div>
                <Button
                fullWidth
                variant="contained"
                onClick={async () => {
                  if(isNaN(Number(this.state.enteredAmount))) {
                    this.setState({ enteredAmount: 0 });
                  } else
                  if (
                    this.state.enteredAmount > 0 && this.state.enteredAmount<=walletMax()
                  ){
                    this.setState({
                      usingWallet: true,
                      offerInd2: null,
                      offerCode2: strings["Cartera"],
                      offerValue2: this.state.enteredAmount,
                      offerId2:strings["Cartera"],
                      offerSuccess2:'',
                      isOfferApplied2:true,
                      isFreeDelivery2:false,
                      isOfferError2:false,
                    }, (newS)=>{
                      checkOffer();
                      handleWalletClose();
                    });
                  }
                }}
              >
                {strings["Usar"]}
              </Button></>: null}
              </Stack>
            </DialogContent>
          }
        />
        <PreOrderDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openPreOrder}
          onClose={handlePreOrderClose}
          displayList={
            <DialogContent dividers style={{border: '0',}}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <div style={{padding: "0px 15px 20px"}}>
                  <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '400',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado"]}
                                </Typography>
                                </div>
                                <div style={{width: "100%", padding: "0px 0px 20px"}}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-evenly"
                                  alignItems="center"
                                  spacing={0}
                                >
                                <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
                                        <Select
                                          value={this.state.currenciesList2Selected}
                                          onChange={(event) => {
                                            // console.log(event.target.value,this.state.currenciesList2Selected, (typeof event.target.value));
                                    if(event.target.value !== -1) {
                                      var showTomorrow = this.state.showTomorrow;
                                      var show3Day = this.state.show3Day;
                                      var show4Day = this.state.show4Day;
                                      var show5Day = this.state.show5Day;
                                      var show6Day = this.state.show6Day;
                                      var show7Day = this.state.show7Day;
                                      if (this.state.currenciesList2[event.target.value]['id'] === 1) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 2) {
                                        showTomorrow = true;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 3) {
                                        showTomorrow = false;
                                        show3Day = true;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 4) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = true;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 5) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = true;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 6) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = true;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 7) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = true;
                                      }
                                      this.setState({
                                        showTomorrow:showTomorrow,
                                        show3Day:show3Day,
                                        show4Day:show4Day,
                                        show5Day:show5Day,
                                        show6Day:show6Day,
                                        show7Day:show7Day,
                                        selectedPreOrder: (showTomorrow)?(this.state.currenciesList3[this.state.currenciesList3Selected]?this.state.currenciesList3[this.state.currenciesList3Selected]['date']:null):
                                        (show3Day)?(this.state.currenciesList4[this.state.currenciesList4Selected]?this.state.currenciesList4[this.state.currenciesList4Selected]['date']:null):
                                        (show4Day)?(this.state.currenciesList5[this.state.currenciesList5Selected]?this.state.currenciesList5[this.state.currenciesList5Selected]['date']:null):
                                        (show5Day)?(this.state.currenciesList6[this.state.currenciesList6Selected]?this.state.currenciesList6[this.state.currenciesList6Selected]['date']:null):
                                        (show7Day)?(this.state.currenciesList7[this.state.currenciesList7Selected]?this.state.currenciesList7[this.state.currenciesList7Selected]['date']:null):
                                        (show7Day)?(this.state.currenciesList8[this.state.currenciesList8Selected]?this.state.currenciesList8[this.state.currenciesList8Selected]['date']:null):
                                        (this.state.currenciesList[this.state.currenciesListSelected]?this.state.currenciesList[this.state.currenciesListSelected]['date']:null)
                                      });
                                    }
                                    this.setState({
                                      currenciesList2Selected:event.target.value
                                                                      });
                                  }}
                                          displayEmpty
                                          inputProps={{ 'aria-label': '' }}
                                        >

                                {this.state.currenciesList2.map((name2, index) => (
                                   <MenuItem value={index}>
                                    <Stack
                                  direction="column"
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  spacing={0}
                                >
                                <div style={{padding: "1.5px 0px 1.5px"}}></div>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                    color: 'slategray',
                                  }}
                                >
                                  {name2['title']}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '19.5px',
                                    fontWeight: '600',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  { moment(name2['date'])
                                .format('DD/MM/YYYY')}
                                </Typography>
</Stack>
   </MenuItem>
))}
        </Select>
      </FormControl>
      <>{this.state.currenciesList4.length == 0 && this.state.show3Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show3Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show3Day?this.state.currenciesList4Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show3Day = this.state.show3Day;
      if (this.state.show3Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList4[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show3Day) {
    this.setState({
      currenciesList4Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList4.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList5.length == 0 && this.state.show4Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show4Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show4Day?this.state.currenciesList5Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show4Day = this.state.show4Day;
      if (this.state.show4Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList5[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show4Day) {
    this.setState({
      currenciesList5Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList5.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList6.length == 0 && this.state.show5Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show5Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show5Day?this.state.currenciesList6Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show5Day = this.state.show5Day;
      if (this.state.show5Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList6[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show5Day) {
    this.setState({
      currenciesList6Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList6.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList7.length == 0 && this.state.show6Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show6Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show6Day?this.state.currenciesList7Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show6Day = this.state.show6Day;
      if (this.state.show6Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList7[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show6Day) {
    this.setState({
      currenciesList7Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList7.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList8.length == 0 && this.state.show7Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show7Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show7Day?this.state.currenciesList8Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
        console.log(event.target.value);
      var show7Day = this.state.show7Day;
      if (this.state.show7Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList8[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show7Day) {
    this.setState({
      currenciesList8Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList8.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
        </FormControl>: this.state.currenciesList.length == 0 && !this.state.showTomorrow ?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>
        : (!this.state.showTomorrow)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.showTomorrow?this.state.currenciesList3Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var showTomorrow = this.state.showTomorrow;
      if (this.state.showTomorrow) {
        this.setState({
          selectedPreOrder:this.state.currenciesList3[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.showTomorrow) {
    this.setState({
      currenciesList3Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList3.length == 0 ?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:<FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={this.state.showTomorrow?this.state.currenciesList3Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var showTomorrow = this.state.showTomorrow;
      if (this.state.showTomorrow) {
        this.setState({
          selectedPreOrder:this.state.currenciesList3[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.showTomorrow) {
    this.setState({
      currenciesList3Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList3.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>}
      </>
</Stack>
</div>
<Button fullWidth variant="contained" style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: getPrimaryColor(), }}
              sx={{ backgroundColor: getPrimaryColor() }}
onClick={

  ()=>{

    if(this.state.selectedPreOrder && (this.state.currenciesList2Selected > 0 || (this.state.currenciesList2Selected === 0 && this.state.currenciesList.length>0))) {
    this.setState(
                        { businessData: null },
                        (newState) => {
                          var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[this.state.selectedPreOrder.getDay()];
    const { id } = this.props.match.params;
    var dataToAdd = { selectedDate: this.state.selectedPreOrder, selectedWeekDay: currentDay, isTomorrow: this.state.showTomorrow, isToday: (!this.state.show3Day && !this.state.show4Day && !this.state.show5Day && !this.state.show6Day && !this.state.show7Day) ,isPreOrder: true };
    localStorage.setItem('preOrder', JSON.stringify(dataToAdd));
    window.dispatchEvent(new Event("preOrder"));
    this.setState({ selectedDate: this.state.selectedPreOrder, selectedWeekDay: currentDay, busId: id,isTomorrow: this.state.showTomorrow, isToday: (!this.state.show3Day && !this.state.show4Day && !this.state.show5Day && !this.state.show6Day && !this.state.show7Day) ,isPreOrder: true });

                          handlePreOrderClose();
                          // this.setState({ businessData: {} });
                          this.getCities(id);
                        }
                      );
    }
  }
}
>{strings["Programar y avanzar"]}</Button>
</Stack>
            </DialogContent>
          }
        />
        <PaymentDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showPayment}
          onClose={handleClosePayment}
          displayList={
            <DialogContent
              dividers
              style={{
                width: this.state.width < 1000 ? '100%' : '50vw',
                paddingTop: '0',
              }}
            >
              <List>
                <Stack direction={'column'} spacing={0}>
                 {/* <>
                    {(this.state.businessData && this.state.cards && !this.state.businessData.cashOnly && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(0))) ? (
                      <>
                        {this.state.cards.map((items, index) => (
                          <React.Fragment key={index}>
                            <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            this.setState({
                              defaultCard: items,
                              showPayment: false,
                            });
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                  (items['card']['brand']
                                                      .toString()
                                                      .toLowerCase()
                                                      .includes('visa'))
                                                  ? 'url("https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fvisa.png?alt=media&token=1925130f-c1f1-40cf-96ce-07b6d12fb826")'
                                                  : (items['card']
                                                              ['brand']
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes('master'))
                                                      ? 'url("https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fmastercard.png?alt=media&token=aed207cf-4abf-4617-9554-c7e4edb66d66")'
                                                      : (items['card']
                                                                  ['brand']
                                                              .toString()
                                                              .toLowerCase()
                                                              .includes(
                                                                  'discover'))
                                                          ? 'url("https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiscover.png?alt=media&token=3321b25f-44d1-429a-916a-b10b7bf3fdc0")'
                                                          : (items
                                                                          ['card']
                                                                      ['brand']
                                                                  .toString()
                                                                  .toLowerCase()
                                                                  .includes('diners'))
                                                              ? 'url("https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiners.png?alt=media&token=c08b27e3-a721-4fc0-9819-657716d471f4")'
                                                              :'url("https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Tarjeta con terminación "]+
                                                items['card']['last4']
                                                    .toString()}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                          </React.Fragment>
                        ))}
                      </>
                    ) : null}
                  </> */}
                  {/* <>
              {
                <div style={{padding: "20px 20px 0px"}}>
                  <div style={{padding: "5px", color: "#f8f9fb", borderRadius: "50px"}}>
                  <Stack direction={'row'} spacing={0} justifyContent="space-between"
  alignItems="center">
                  <Stack direction={'row'} spacing={0}
  alignItems="center">
    <CreditCardIcon
    style={{
      fontSize: "24px",
      color:"#000"
    }}
    />
    <div style={{padding: "5px"}}>
    <Typography
                    style={{ fontSize: '17.5px', fontWeight: 'normal' }}
                  >
                    Add new card
                  </Typography>
    </div>
                  </Stack>
                  </Stack>
</div>
                </div>
              }
              </> */}
                 {/* <>
                    {(this.state.businessData && !this.state.businessData.cashOnly && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(0))) ? (
                      <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                          onClick={()=>{
                            handleClickAddCardOpen();
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                    'url("https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Agregar nueva tarjeta"]}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null}
                  </> */}
                  <>
                    {(this.state.businessData && !this.state.businessData.cardOnly && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(1))) ? (
                      <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          onClick={() => {
                            this.setState({
                              defaultCard: {
                                cardNumber: 'COD',
                              },
                              showPayment: false,
                            });
                          }}
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                    'url("https://dl3.pushbulletusercontent.com/S4LwEZ2S0F5H5pHbRWmha1WWdQmnDtxg/Payment%20icons-03.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Pago en efectivo"]}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null}
                  </>
                  <>
                    {(this.state.businessData && !this.state.businessData.cardOnly &&
                    this.state.businessData.cardOnDelivery && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(2))) ? (
                      <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          onClick={() => {
                            this.setState({
                              defaultCard: {
                                cardNumber: 'CardOD',
                              },
                              showPayment: false,
                            });
                          }}
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                    'url("https://dl3.pushbulletusercontent.com/YT1RwbwCHJoEqRCYB9fjwk7lrpuzapck/Payment%20icons-02.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Tarjeta a la entrega"]}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null}
                  </>
                </Stack>
              </List>
            </DialogContent>
          }
        />
        <DiscountDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showDiscount}
          onClose={handleCloseDiscount}
          displayList={
            <DialogContent
              dividers
              style={{ width: this.state.width < 1000 ? '100%' : '50vw' }}
            >
              <>
                {this.state.offers && this.state.offers.length > 0 ? (
                  <List>
                    {this.state.offers.map((items, offerIndex) => (
                      <React.Fragment key={offerIndex}>
                        {(this.state.offersUsed.find(
                          (element) =>
                            element['id'] ===
                            this.state.offers[offerIndex]['id']
                        ) == null ||
                          this.state.offersUsed.filter(
                            (element) =>
                              element['id'] ===
                              this.state.offers[offerIndex]['id']
                          ).length <
                            this.state.offers[offerIndex]['maximumUses']) &&
                        (this.state.offers[offerIndex]['validFrom'] == null ||
                          moment().isAfter(
                            moment(
                              this.state.offers[offerIndex][
                                'validFrom'
                              ].toDate()
                            )
                          )) &&
                        (this.state.offers[offerIndex]['validTill'] == null ||
                          moment().isBefore(
                            moment(
                              this.state.offers[offerIndex][
                                'validTill'
                              ].toDate()
                            )
                          )) ? (
                          <div
                            style={{
                              borderBottom: '1px solid #ccc',
                              backgroundColor: '#fff',
                              padding: '20px',
                            }}
                          >
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                            >
                              <Stack
                                direction={'column'}
                                spacing={0}
                                sx={{ flexGrow: 1 }}
                                alignItems="flex-start"
                              >
                                <div style={{ padding: '0px 0px 10px' }}>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {this.state.offers[offerIndex]['offerName']}
                                  </Typography>
                                </div>
                                <div>
                                  <Stack direction={'row'} spacing={0}>
                                    <>
                                      {this.state.offers[offerIndex][
                                        'details'
                                      ] == null ||
                                      !this.state.offers[offerIndex][
                                        'details'
                                      ] ? (
                                        <div>
                                          <Typography
                                            onClick={() => {
                                              var devyOffers =
                                                this.state.offers;
                                              devyOffers[offerIndex][
                                                'details'
                                              ] = true;
                                              this.setState({
                                                offers: devyOffers,
                                              });
                                            }}
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            {this.state.offers[offerIndex][
                                              'description'
                                            ] + strings['. Ver detalles']}
                                          </Typography>
                                        </div>
                                      ) : null}
                                    </>
                                    <>
                                      {this.state.offers[offerIndex][
                                        'details'
                                      ] != null &&
                                      this.state.offers[offerIndex][
                                        'details'
                                      ] ? (
                                        <div>
                                          <Typography
                                            onClick={() => {
                                              var devyOffers =
                                                this.state.offers;
                                              devyOffers[offerIndex][
                                                'details'
                                              ] = false;
                                              this.setState({
                                                offers: devyOffers,
                                              });
                                            }}
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            {this.state.offers[offerIndex][
                                              'description'
                                            ] + strings['. Menos detalles']}
                                          </Typography>
                                        </div>
                                      ) : null}
                                    </>
                                  </Stack>
                                </div>
                                <>
                                  {this.state.offers[offerIndex]['details'] !=
                                    null &&
                                  this.state.offers[offerIndex]['details'] ? (
                                    <div style={{ padding: '8px 0px 8px' }}>
                                      <Stack direction={'column'} spacing={0}>
                                        <>
                                          {this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Comprar '] +
                                                (
                                                  this.state.offers[offerIndex][
                                                    'freeItem'
                                                  ] +
                                                  this.state.offers[offerIndex][
                                                    'buyItem'
                                                  ]
                                                ).toString() +
                                                strings[' productos y pago '] +
                                                this.state.offers[offerIndex][
                                                  'buyItem'
                                                ].toString() +
                                                strings[' en productos seleccionados']}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •{strings['Productos promocionados:']}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] ? (
                                            <>
                                              {this.state.offers[offerIndex][
                                                'items'
                                              ].map((items, t) => (
                                                <Typography
                                                  style={{
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                  }}
                                                >
                                                  •
                                                  {
                                                    this.state.offers[
                                                      offerIndex
                                                    ]['items'][t]['name']
                                                  }
                                                </Typography>
                                              ))}
                                            </>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'freeDelivery'
                                          ] ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {
                                                strings['Se eliminará la tarifa de envío']
                                              }
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'minimumItemValue'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['El número mínimo de productos debe ser $'] +
                                                this.state.offers[offerIndex][
                                                  'minimumItemValue'
                                                ].toString()}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'maximumItemValue'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['El número máximo de productos debe ser $'] +
                                                this.state.offers[offerIndex][
                                                  'maximumItemValue'
                                                ].toString()}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'discountAmount'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Descuento $'] +
                                                this.state.offers[offerIndex][
                                                  'discountAmount'
                                                ].toString()}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'cashbackAmount'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'cashbackAmount'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Obtener $'] +
                                                this.state.offers[offerIndex][
                                                  'cashbackAmount'
                                                ].toString() +
                                                strings[' al final del día en Wallet']}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          ((this.state.offers[offerIndex][
                                            'cashbackAmount'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'cashbackAmount'
                                          ]>0) || (this.state.offers[offerIndex][
                                            'cashbackPercentage'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'cashbackPercentage'
                                          ]>0)) ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['El reembolso se acreditará dentro de las 24 horas.']}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'cashbackPercentage'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'cashbackPercentage'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Obtener '] +
                                                this.state.offers[offerIndex][
                                                  'cashbackPercentage'
                                                ].toString() +
                                                strings['% de cashback sobre el total de tus productos en Wallet']}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'cashbackMax'
                                          ] != null &&
                                          this.state.offers[offerIndex][
                                            'cashbackMax'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Reembolso máximo de $'] +
                                                this.state.offers[offerIndex][
                                                  'cashbackMax'
                                                ].toString()}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'discountPercentage'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Obtener '] +
                                                this.state.offers[offerIndex][
                                                  'discountPercentage'
                                                ].toString() +
                                                strings['% de descuento en todos sus productos.']}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            !this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'minimumDiscount'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Consigue un descuento mínimo de $'] +
                                                this.state.offers[offerIndex][
                                                  'minimumDiscount'
                                                ].toString()}
                                            </Typography>
                                          ) : null}
                                        </>
                                        <>
                                          {(this.state.offers[offerIndex][
                                            'isItemOffer'
                                          ] == null ||
                                            this.state.offers[offerIndex][
                                              'isItemOffer'
                                            ]) &&
                                          this.state.offers[offerIndex][
                                            'maximumDiscount'
                                          ] > 0 ? (
                                            <Typography
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              •
                                              {strings['Sube hasta $'] +
                                                this.state.offers[offerIndex][
                                                  'maximumDiscount'
                                                ].toString()}
                                            </Typography>
                                          ) : null}
                                        </>
                                      </Stack>
                                    </div>
                                  ) : null}
                                </>
                              </Stack>
                              <div
                                style={{
                                  padding: '10px 0px 0px',
                                  width: '100%',
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <div
                                    style={{
                                      padding: '10px 10px 10px',
                                      backgroundColor: '#f8f9fb',
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: '17px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {this.state.offers[offerIndex]['code']}
                                    </Typography>
                                  </div>
                                  {!this.state.showDiscountIsBusiness && (
                                    <Typography
                                      onClick={() => {
                                        // console.log('Her');
                                        var offerCode = this.state.offerCode;
                                        var offerId = this.state.offerId;
                                        var offerSuccess =
                                          this.state.offerSuccess;
                                        var offerErrorText =
                                          this.state.offerErrorText;
                                        var offerValue = this.state.offerValue;
                                        var cashbackValue =
                                          this.state.cashbackValue;
                                        var offerInd = this.state.offerInd;
                                        var isOfferApplied =
                                          this.state.isOfferApplied;
                                        var isOfferError =
                                          this.state.isOfferError;
                                        var isFreeDelivery =
                                          this.state.isFreeDelivery;
                                        var offersUsed = this.state.offersUsed;
                                        var devyOffers = this.state.offers;
                                        var cart = this.state.cart;
                                        var delivery = this.state.delivery;
                                        // usingWallet = false;
                                        // isUsingInviteCode = false;
                                        // isUsingSubscription = false;
                                        this.setState({
          selectedOffer: devyOffers[offerIndex],
        });
                                        if (
                                          offersUsed.find(
                                            (element) =>
                                              element['id'] ===
                                              devyOffers[offerIndex]['id']
                                          ) == null
                                        ) {
                                          if (
                                            devyOffers[offerIndex][
                                              'isItemOffer'
                                            ] != null &&
                                            devyOffers[offerIndex][
                                              'isItemOffer'
                                            ]
                                          ) {
                                            offerValue = 0;
                                            cashbackValue = 0.0;
                                            // console.log(
                                            //   devyOffers[offerIndex]['items']
                                            // );
                                            var testList =
                                              devyOffers[offerIndex]['items'];
                                            for (
                                              var ij = 0;
                                              ij < cart['item'].length;
                                              ij++
                                            ) {
                                              //print(testList.filter((element34) => element34['id'].contains(cart['item'][ij]['id'])));
                                              if (
                                                testList.find(
                                                  (element34) =>
                                                    element34['id'] ===
                                                    cart['item'][ij]['id']
                                                ) != null
                                              ) {
                                                var tempValue = testList.find(
                                                  (element) =>
                                                    element['id'].toString() ===
                                                    cart['item'][ij][
                                                      'id'
                                                    ].toString()
                                                );
                                                if (
                                                  tempValue['id'] ===
                                                    cart['item'][ij]['id'] &&
                                                  Math.trunc(
                                                    cart['item'][ij][
                                                      'quantity'
                                                    ] /
                                                      (devyOffers[offerIndex][
                                                        'freeItem'
                                                      ] +
                                                        devyOffers[offerIndex][
                                                          'buyItem'
                                                        ])
                                                  ) > 0
                                                ) {
                                                  offerValue =
                                                    offerValue +
                                                    cart['item'][ij]['price'] *
                                                      Math.trunc(
                                                        cart['item'][ij][
                                                          'quantity'
                                                        ] /
                                                          (devyOffers[
                                                            offerIndex
                                                          ]['freeItem'] +
                                                            devyOffers[
                                                              offerIndex
                                                            ]['buyItem'])
                                                      ) +
                                                    0.0;
                                                }
                                              }
                                            }
                                            if (offerValue > 0) {
                                              isOfferApplied = true;
                                              offerInd = offerIndex;
                                              isFreeDelivery = false;
                                              isOfferError = false;
                                              offerErrorText = '';
                                              offerCode =
                                                devyOffers[offerIndex]['code'];
                                              offerId =
                                                devyOffers[offerIndex]['id'];
                                              offerSuccess =
                                                strings['Su cupón ha sido agregado con éxito.'];
                                            } else {
                                              isOfferApplied = false;
                                              isOfferError = true;
                                              isFreeDelivery = false;
                                              offerInd = offerIndex;
                                              offerErrorText =
                                                strings['Añadir al menos '] +
                                                (
                                                  devyOffers[offerIndex][
                                                    'freeItem'
                                                  ] +
                                                  devyOffers[offerIndex][
                                                    'buyItem'
                                                  ]
                                                ).toString() +
                                                strings[' productos promocionales.'];
                                              offerCode =
                                                devyOffers[offerIndex]['code'];
                                              offerSuccess = '';
                                              offerValue = 0;
                                              cashbackValue = 0.0;
                                              offerId = '';
                                            }
                                          } else if (
                                            cart['totalCost'] >=
                                            devyOffers[offerIndex][
                                              'minimumItemValue'
                                            ]
                                          ) {
                                            if (
                                              devyOffers[offerIndex][
                                                'maximumItemValue'
                                              ] === 0 ||
                                              cart['totalCost'] <=
                                                devyOffers[offerIndex][
                                                  'maximumItemValue'
                                                ]
                                            ) {
                                              isOfferApplied = true;
                                              offerInd = offerIndex;
                                              isFreeDelivery = false;
                                              isOfferError = false;
                                              offerErrorText = '';
                                              offerCode =
                                                devyOffers[offerIndex]['code'];
                                              offerId =
                                                devyOffers[offerIndex]['id'];
                                              offerSuccess =
                                                strings['Su cupón ha sido agregado con éxito.'];
                                              // if(devyOffers[offerIndex]['isInvite']!=null && devyOffers[offerIndex]['isInvite']) {
                                              //   isUsingInviteCode = true;
                                              // } else if(devyOffers[offerIndex]['isSubscription']!=null && devyOffers[offerIndex]['isSubscription']) {
                                              //   isUsingSubscription = true;
                                              // }
                                              //storage.setItem('cart', jsonEncode(cart));
                                              //Utilizando CarteraDevy

                                              if (
                                                devyOffers[offerIndex][
                                                  'freeDelivery'
                                                ]
                                              ) {
                                                offerValue = (delivery + this.extraCharges()) + 0.0;
                                                cashbackValue = 0.0;
                                                isFreeDelivery = true;
                                              } else {
                                                isFreeDelivery = false;
                                                offerValue =
                                                  devyOffers[offerIndex][
                                                    'discountAmount'
                                                  ] +
                                                  0.0 +
                                                  (devyOffers[offerIndex][
                                                    'discountPercentage'
                                                  ] /
                                                    100) *
                                                    cart['totalCost'];
                                                if (
                                                  offerValue <
                                                  devyOffers[offerIndex][
                                                    'minimumDiscount'
                                                  ]
                                                ) {
                                                  offerValue =
                                                    devyOffers[offerIndex][
                                                      'minimumDiscount'
                                                    ] + 0.0;
                                                } else if (
                                                  devyOffers[offerIndex][
                                                    'maximumDiscount'
                                                  ] !== 0 &&
                                                  offerValue >
                                                    devyOffers[offerIndex][
                                                      'maximumDiscount'
                                                    ]
                                                ) {
                                                  offerValue =
                                                    devyOffers[offerIndex][
                                                      'maximumDiscount'
                                                    ] + 0.0;
                                                }
                                                cashbackValue =
                                                  devyOffers[offerIndex][
                                                    'cashbackAmount'
                                                  ] != null
                                                    ? devyOffers[offerIndex][
                                                        'cashbackAmount'
                                                      ] +
                                                      0.0 +
                                                      (((devyOffers[offerIndex][
                                                        'cashbackMax'
                                                      ] != null && devyOffers[offerIndex][
                                                        'cashbackMax'
                                                      ]>0) && ((devyOffers[offerIndex][
                                                        'cashbackPercentage'
                                                      ] != null
                                                        ? (devyOffers[
                                                            offerIndex
                                                          ][
                                                            'cashbackPercentage'
                                                          ] /
                                                            100) *
                                                            cart['totalCost']
                                                        : 0)>devyOffers[offerIndex][
                                                          'cashbackMax'
                                                        ]))?devyOffers[offerIndex][
                                                          'cashbackMax'
                                                        ]:(devyOffers[offerIndex][
                                                          'cashbackPercentage'
                                                        ] != null
                                                          ? (devyOffers[
                                                              offerIndex
                                                            ][
                                                              'cashbackPercentage'
                                                            ] /
                                                              100) *
                                                              cart['totalCost']
                                                          : 0))
                                                    : 0.0;
                                              }
                                            } else {
                                              isOfferApplied = false;
                                              isOfferError = true;
                                              isFreeDelivery = false;
                                              offerInd = offerIndex;
                                              offerErrorText =
                                                strings['Los productos máximos deben ser $'] +
                                                devyOffers[offerIndex][
                                                  'maximumItemValue'
                                                ].toString();
                                              offerCode =
                                                devyOffers[offerIndex]['code'];
                                              offerSuccess = '';
                                              offerValue = 0;
                                              cashbackValue = 0.0;
                                              offerId = '';
                                            }
                                          } else if (
                                            cart['totalCost'] <
                                            devyOffers[offerIndex][
                                              'minimumItemValue'
                                            ]
                                          ) {
                                            isOfferApplied = false;
                                            isOfferError = true;
                                            isFreeDelivery = false;
                                            offerInd = offerIndex;
                                            offerErrorText =
                                              strings['Los productos mínimos deben ser $'] +
                                              devyOffers[offerIndex][
                                                'minimumItemValue'
                                              ].toString();
                                            offerCode =
                                              devyOffers[offerIndex]['code'];
                                            offerSuccess = '';
                                            offerValue = 0;
                                            cashbackValue = 0.0;
                                            offerId = '';
                                          }
                                        } else {
                                          if (
                                            offersUsed
                                              .filter(
                                                (element) =>
                                                  element['id'] ===
                                                  devyOffers[offerIndex]['id']
                                              )
                                              [
                                                offersUsed.filter(
                                                  (element) =>
                                                    element['id'] ===
                                                    devyOffers[offerIndex]['id']
                                                ).length - 1
                                              ]['usedOn'].toMillis() +
                                              devyOffers[offerIndex][
                                                'timeBetweenUses'
                                              ] *
                                                1000 <
                                            Date.now()
                                          ) {
                                            if (
                                              devyOffers[offerIndex][
                                                'isItemOffer'
                                              ] != null &&
                                              devyOffers[offerIndex][
                                                'isItemOffer'
                                              ]
                                            ) {
                                              offerValue = 0;
                                              cashbackValue = 0.0;
                                              for (
                                                var ij = 0;
                                                ij < cart['item'].length;
                                                ij++
                                              ) {
                                                if (
                                                  devyOffers[offerIndex][
                                                    'items'
                                                  ].find((element) =>
                                                    element['id'] ===
                                                    cart['item'][ij]['id']
                                                      ? true
                                                      : false
                                                  ) != null
                                                ) {
                                                  var tempValue = devyOffers[
                                                    offerIndex
                                                  ]['items'].find((element) =>
                                                    element['id'] ===
                                                    cart['item'][ij]['id']
                                                      ? true
                                                      : false
                                                  );
                                                  if (
                                                    tempValue['id'] ===
                                                      cart['item'][ij]['id'] &&
                                                    Math.trunc(
                                                      cart['item'][ij][
                                                        'quantity'
                                                      ] /
                                                        (devyOffers[offerIndex][
                                                          'freeItem'
                                                        ] +
                                                          devyOffers[
                                                            offerIndex
                                                          ]['buyItem'])
                                                    ) > 0
                                                  ) {
                                                    offerValue =
                                                      offerValue +
                                                      cart['item'][ij][
                                                        'price'
                                                      ] *
                                                        Math.trunc(
                                                          cart['item'][ij][
                                                            'quantity'
                                                          ] /
                                                            (devyOffers[
                                                              offerIndex
                                                            ]['freeItem'] +
                                                              devyOffers[
                                                                offerIndex
                                                              ]['buyItem'])
                                                        ) +
                                                      0.0;
                                                  }
                                                }
                                              }
                                              if (offerValue > 0) {
                                                isOfferApplied = true;
                                                offerInd = offerIndex;
                                                isFreeDelivery = false;
                                                isOfferError = false;
                                                offerErrorText = '';
                                                offerCode =
                                                  devyOffers[offerIndex][
                                                    'code'
                                                  ];
                                                offerId =
                                                  devyOffers[offerIndex]['id'];
                                                offerSuccess =
                                                  strings['Su cupón ha sido agregado con éxito.'];
                                              } else {
                                                isOfferApplied = false;
                                                isOfferError = true;
                                                isFreeDelivery = false;
                                                offerInd = offerIndex;
                                                offerErrorText =
                                                  strings['Añadir al menos '] +
                                                  (
                                                    devyOffers[offerIndex][
                                                      'freeItem'
                                                    ] +
                                                    devyOffers[offerIndex][
                                                      'buyItem'
                                                    ]
                                                  ).toString() +
                                                  strings[' productos promocionales.'];

                                                offerCode =
                                                  devyOffers[offerIndex][
                                                    'code'
                                                  ];
                                                offerSuccess = '';
                                                offerValue = 0;
                                                cashbackValue = 0.0;
                                                offerId = '';
                                              }
                                            } else if (
                                              cart['totalCost'] >=
                                              devyOffers[offerIndex][
                                                'minimumItemValue'
                                              ]
                                            ) {
                                              if (
                                                devyOffers[offerIndex][
                                                  'maximumItemValue'
                                                ] === 0 ||
                                                cart['totalCost'] <=
                                                  devyOffers[offerIndex][
                                                    'maximumItemValue'
                                                  ]
                                              ) {
                                                isOfferApplied = true;
                                                isOfferError = false;
                                                isFreeDelivery = false;
                                                offerInd = offerIndex;
                                                offerErrorText = '';
                                                offerId =
                                                  devyOffers[offerIndex]['id'];
                                                offerCode =
                                                  devyOffers[offerIndex][
                                                    'code'
                                                  ];
                                                // if(devyOffers[offerIndex]['isInvite']!=null && devyOffers[offerIndex]['isInvite']) {
                                                //   isUsingInviteCode = true;
                                                // }else if(devyOffers[offerIndex]['isSubscription']!=null && devyOffers[offerIndex]['isSubscription']) {
                                                //   isUsingSubscription = true;
                                                // }
                                                offerSuccess =
                                                  strings['Su cupón ha sido agregado con éxito.'];
                                                if (
                                                  devyOffers[offerIndex][
                                                    'freeDelivery'
                                                  ]
                                                ) {
                                                  offerValue = (delivery + this.extraCharges()) + 0.0;
                                                  cashbackValue = 0.0;
                                                  isFreeDelivery = true;
                                                } else {
                                                  isFreeDelivery = false;
                                                  offerValue =
                                                    devyOffers[offerIndex][
                                                      'discountAmount'
                                                    ] +
                                                    0.0 +
                                                    (devyOffers[offerIndex][
                                                      'discountPercentage'
                                                    ] /
                                                      100) *
                                                      cart['totalCost'];
                                                  if (
                                                    offerValue <
                                                    devyOffers[offerIndex][
                                                      'minimumDiscount'
                                                    ]
                                                  ) {
                                                    offerValue =
                                                      devyOffers[offerIndex][
                                                        'minimumDiscount'
                                                      ] + 0.0;
                                                  } else if (
                                                    devyOffers[offerIndex][
                                                      'maximumDiscount'
                                                    ] !== 0 &&
                                                    offerValue >
                                                      devyOffers[offerIndex][
                                                        'maximumDiscount'
                                                      ]
                                                  ) {
                                                    offerValue =
                                                      devyOffers[offerIndex][
                                                        'maximumDiscount'
                                                      ] + 0.0;
                                                  }
                                                  cashbackValue =
                                                    devyOffers[offerIndex][
                                                      'cashbackAmount'
                                                    ] != null
                                                      ? devyOffers[offerIndex][
                                                          'cashbackAmount'
                                                        ] +
                                                        0.0 +
                                                        (((devyOffers[offerIndex][
                                                          'cashbackMax'
                                                        ] != null && devyOffers[offerIndex][
                                                          'cashbackMax'
                                                        ]>0) && ((devyOffers[offerIndex][
                                                          'cashbackPercentage'
                                                        ] != null
                                                          ? (devyOffers[
                                                              offerIndex
                                                            ][
                                                              'cashbackPercentage'
                                                            ] /
                                                              100) *
                                                              cart['totalCost']
                                                          : 0)>devyOffers[offerIndex][
                                                            'cashbackMax'
                                                          ]))?devyOffers[offerIndex][
                                                            'cashbackMax'
                                                          ]:(devyOffers[offerIndex][
                                                            'cashbackPercentage'
                                                          ] != null
                                                            ? (devyOffers[
                                                                offerIndex
                                                              ][
                                                                'cashbackPercentage'
                                                              ] /
                                                                100) *
                                                                cart['totalCost']
                                                            : 0))
                                                      : 0.0;
                                                }
                                              } else {
                                                isOfferApplied = false;
                                                isOfferError = true;
                                                isFreeDelivery = false;
                                                offerInd = offerIndex;
                                                offerErrorText =
                                                  strings['Los productos máximos deben ser $'] +
                                                  devyOffers[offerIndex][
                                                    'maximumItemValue'
                                                  ].toString();
                                                offerCode =
                                                  devyOffers[offerIndex][
                                                    'code'
                                                  ];
                                                offerSuccess = '';
                                                offerValue = 0;
                                                cashbackValue = 0.0;
                                                offerId = '';
                                              }
                                            } else if (
                                              cart['totalCost'] <
                                              devyOffers[offerIndex][
                                                'minimumItemValue'
                                              ]
                                            ) {
                                              isOfferApplied = false;
                                              isFreeDelivery = false;
                                              isOfferError = true;
                                              offerInd = offerIndex;
                                              offerErrorText =
                                                strings['Los productos mínimos deben ser $'] +
                                                devyOffers[offerIndex][
                                                  'minimumItemValue'
                                                ].toString();
                                              offerCode =
                                                devyOffers[offerIndex]['code'];
                                              offerSuccess = '';
                                              offerValue = 0;
                                              cashbackValue = 0.0;
                                              offerId = '';
                                            }
                                          } else {
                                            isOfferApplied = false;
                                            isOfferError = true;
                                            isFreeDelivery = false;
                                            offerInd = offerIndex;
                                            offerErrorText =
                                              strings['Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.'];
                                            offerCode =
                                              devyOffers[offerIndex]['code'];
                                            offerSuccess = '';
                                            offerValue = 0;
                                            cashbackValue = 0.0;
                                            offerId = '';
                                          }
                                        }
                                        //                                                                        setState(
                                        //                                                                            () {
                                        //                                                                          isOfferApplied =
                                        //                                                                              true;
                                        //                                                                          isOfferError =
                                        //                                                                              false;
                                        //                                                                          offerErrorText =
                                        //                                                                              offerErrorText;
                                        //                                                                          offerCode =
                                        //                                                                              'offerCode';
                                        //                                                                          offerSuccess =
                                        //                                                                              'offerSuccess';
                                        //                                                                          offerValue =
                                        //                                                                              offerValue;
                                        //                                                                        });
                                        //                                                                      setState(
                                        //                                                                          () {
                                        //                                                                        isOfferApplied =
                                        //                                                                            true;
                                        //                                                                        isOfferError =
                                        //                                                                            false;
                                        //                                                                        offerErrorText =
                                        //                                                                            'offerErrorText';
                                        //                                                                        offerCode =
                                        //                                                                            'offerCode';
                                        //                                                                        offerSuccess =
                                        //                                                                            'offerSuccess';
                                        //                                                                        offerValue =
                                        //                                                                            0;
                                        this.setState(
                                          {
                                            offerCode: offerCode,
                                            offerId: offerId,
                                            offerSuccess: offerSuccess,
                                            offerErrorText: offerErrorText,
                                            offerValue: offerValue,
                                            cashbackValue: cashbackValue,
                                            offerInd: offerInd,
                                            isOfferApplied: isOfferApplied,
                                            isOfferError: isOfferError,
                                            isFreeDelivery: isFreeDelivery,
                                            offersUsed: offersUsed,
                                            offers: devyOffers,
                                            cart: cart,
                                            delivery: delivery,
                                            showDiscount: false,
                                          },
                                          (newState) => {
                                            checkOffer();
                                          }
                                        );

                                        // console.log({
                                        //   offerCode: offerCode,
                                        //   offerId: offerId,
                                        //   offerSuccess: offerSuccess,
                                        //   offerErrorText: offerErrorText,
                                        //   offerValue: offerValue,
                                        //   cashbackValue: cashbackValue,
                                        //   offerInd: offerInd,
                                        //   isOfferApplied: isOfferApplied,
                                        //   isOfferError: isOfferError,
                                        //   isFreeDelivery: isFreeDelivery,
                                        //   offersUsed: offersUsed,
                                        //   offers: devyOffers,
                                        //   cart: cart,
                                        //   delivery: delivery,
                                        //   showDiscount: false,
                                        // });
                                        //                                                                      print(
                                        //                                                                          isOfferApplied);

                                        // Navigator.pop(
                                        //     context);

                                        //                                                                        });
                                      }}
                                      style={{
                                        fontSize: '17px',
                                        fontWeight: 'bold',
                                        color: getPrimaryColor(),
                                        cursor: 'pointer',
                                      }}
                                    >
                                      {strings['Aplicar']}
                                    </Typography>
                                  )}
                                </Stack>
                              </div>
                            </Stack>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <Typography style={{ fontSize: '16px', fontWeight: '500' }}>
                    {
                      strings['Lo sentimos, no hay descuentos disponibles en este momento.']
                    }
                  </Typography>
                )}
              </>
            </DialogContent>
          }
        />
        {this.state.businessData ? (
          <div
            style={{
              width: '100%',
              height: isIOS
                ? 'calc((var(--vh, 1vh) * 100) - 60px )'
                : 'calc( 100vh - 60px )',
              overflow: 'hidden',
            }}
          >
            {columns > 1 ? (
              <div
                style={{
                  width: '280px',
                  height: '100%',
                  float: 'left',
                  overflow: 'auto',
                  borderRight: '0px solid #e6ebf1',
                }}
              >
                <Box
                  textAlign="center"
                  height={'0.5%'}
                  style={{
                    borderRadius: '50%',
                    color: '#2c3036',
                    marginBottom: '0px',
                    marginTop: '-10px',
                    padding: '0 10px',
                    height: 'auto',
                    fontWeight: '800',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                  }}
                >
                  <h2
                    style={{
                      fontWeight: '800',
                      fontFamily: 'Nunito',
                      letterSpacing: 'normal',
                    }}
                  >
                    {this.state.businessData.name}
                  </h2>
                </Box>
                {this.state.businessData?.promoText && (
                  <Box
                    textAlign="center"
                    height={'4.5%'}
                    style={{
                      borderRadius: '50%',
                      color: '#2c3036',
                      marginTop: '-10px',
                      padding: '0 10px',
                      height: 'auto',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        borderRadius: 8,
                        backgroundColor: getPrimaryColor(),
                        padding: '7px',
                      }}
                    >
                      <Typography
                        style={{ color: '#ffffff', fontWeight: '400' }}
                      >
                        {this.state.businessData?.promoText}
                      </Typography>
                    </div>
                  </Box>
                )}

                {/* {' Select the order type on web view '} */}
                <FormControl className="ocultatipodeordenrencel"
                  sx={{ m: 0, minWidth: 120, p: 0,display: 'flex',justifyContent: 'center',alignItems: 'center',width: '90%',marginLeft: '5%', height: '40px', marginTop: '20px', }}>
                  <Select
                    style={{height: '40px'}}
                    value={this.state.selectedType}
                    onChange={(event) => {
                      console.log(event.target.value,this.state.selectedType, (typeof event.target.value));
                      if(event.target.value !== -1) {
                        this.setState({ selectedType: event.target.value });
                                          console.log(this.state);
                                          if (!this.state.canDeliverHere && event.target.value === 0) {
                                            this.setState({ storeIsOpen: false});
                                            handleAlertOpen();
                                          } else if (
                                            !this.state.canDeliverHere &&
                                            (event.target.value === 1 || event.target.value === 2)
                                          ) {
                                            this.setState({ storeIsOpen: true });
                                          }
                            }
                          }} displayEmpty inputProps={{ 'aria-label': '' }}>
                    <MenuItem value={0}>
                          <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                      >
                      <DirectionsBikeIcon
                        style={{
                          fontSize: '17px',
                          fontWeight: 'bold',
                          color: getPrimaryColor(),
                          marginRight: '10px',
                        }}
                      />
                      <Typography
                      style={{
                        fontWeight: '700',
                        fontFamily: 'Nunito',
                        textTransform: 'none',
                        padding: '5px 11px',
                        color: '#3e4547',
                      }}
                    >
                      {strings["Entrega a domicilio"]}
                    </Typography>
                    </Stack>
                    </MenuItem>
                    <MenuItem value={1}>
                       <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                        <DirectionsRunIcon
                            style={{
                              height: '17px',
                              fontSize: '17px',
                              fontWeight: 'bold',
                              color: getPrimaryColor(),
                              marginRight: '10px',
                            }}
                          />
                          <Typography
                            style={{
                              fontWeight: '700',
                              fontFamily: 'Nunito',
                              textTransform: 'none',
                              padding: '5px 11px',
                              color: '#3e4547',}}>
                          {strings["Recoger en tienda"]}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  </Select>
                </FormControl>
                {/* {' Select the order type on web view '} */}
                <Box
                  height={'1.5%'}
                  style={{
                    borderRadius: '50%',
                    padding: '0px 0px 0px 25px',
                    height: 'auto',
                    color: '#2c3036',
                  }}
                >
                  {this.state.selectedType === 0 ? (
                    <p style={{ fontSize: '15px' }}>
                      <AccessTimeIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '4.5px',
                          color: '#6a696e',
                        }}
                      />{' '}
                      {strings["Entrega de:"]}{' '}
                      <span style={{ fontWeight: '800', }}>
                      {Math.trunc(
                        (this.state.businessData['totalTime'] == null ||
                        this.state.businessData['totalOrders'] == null ||
                        this.state.businessData['totalOrders'] === 0 ||
                        this.state.businessData['totalTime'] /
                          this.state.businessData['totalOrders'] /
                          60 <=
                          7
                          ? 35
                          : this.state.businessData['totalTime'] >= 46
                          ? 46
                          : this.state.businessData['totalTime'] /
                            this.state.businessData['totalOrders'] /
                            60) - 6
                      )}{' '}
                      -{' '}
                      {Math.trunc(
                        (this.state.businessData['totalTime'] == null ||
                        this.state.businessData['totalOrders'] == null ||
                        this.state.businessData['totalOrders'] === 0 ||
                        this.state.businessData['totalTime'] /
                          this.state.businessData['totalOrders'] /
                          60 <=
                          7
                          ? 35
                          : this.state.businessData['totalTime'] >= 46
                          ? 46
                          : this.state.businessData['totalTime'] /
                            this.state.businessData['totalOrders'] /
                            60) + 6
                      )}{' '}
                      min
                      </span>
                    </p>
                  ) : null}
                  {this.state.selectedType === 1 ? (
                    <p style={{ fontSize: '15px' }}>
                      <AccessTimeIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '4.5px',
                          color: '#6a696e',
                        }}
                      />{' '}
                      {strings["Recoger en:"]}{' '}
                      <span style={{ fontWeight: '800', }}>
                      {Math.trunc(
                        this.state.businessData['totalTime'] == null ||
                          this.state.businessData['totalOrders'] == null ||
                          this.state.businessData['totalOrders'] === 0 ||
                          this.state.businessData['totalTime'] /
                            this.state.businessData['totalOrders'] /
                            60 <=
                            7
                          ? 35
                          : this.state.businessData['totalTime'] >= 46
                          ? 46
                          : this.state.businessData['totalTime'] /
                            this.state.businessData['totalOrders'] /
                            60
                      )}{' '}
                      {strings["min aprox"]}
                      </span>
                    </p>
                  ) : null}
                </Box>
                <Box
                  height={'1.5%'}
                  style={{
                    borderRadius: '50%',
                    padding: '0px 0px 0px 25px',
                    height: 'auto',
                    color: '#2c3036',
                  }}
                >
                  {this.state.selectedType === 0 ? (
                    <p style={{ fontSize: '15px' }}>
                      <MonetizationOnOutlinedIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '4.5px',
                          color: '#6a696e',
                        }}
                      />{' '}
                      {strings[" Costo de envío: "]}
                      <span style={{ fontWeight: '800', }}>
                        {(this.state.selectLocationName && this.state.delivery)? "$"+(this.state.delivery + this.extraCharges()).toFixed(2):" "+strings["Pendiente"]}
                      </span>
                    </p>
                  ) : null}
                  {this.state.selectedType === 1 ? (
                    <p style={{ fontSize: '15px' }}>
                      <DirectionsRunIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '4.5px',
                          color: '#6a696e',
                        }}
                      />{' '}
                      {strings["Distancia"]}{' '}
                      {geolib.getDistance(
                        {
                          latitude: this.state.businessData.location.latitude,
                          longitude: this.state.businessData.location.longitude,
                        },
                        this.state.selectedAddress
                      ) > 100
                        ? '100+ '
                        : geolib
                            .getDistance(
                              {
                                latitude:
                                  this.state.businessData.location.latitude,
                                longitude:
                                  this.state.businessData.location.longitude,
                              },
                              this.state.selectedAddress
                            )
                            .toFixed(2)}
                      km
                    </p>
                  ) : null}
                </Box>
                <Box
                  className="hoversidemneu"
                  height={'1.5%'}
                  style={{
                    borderRadius: '50%',
                    padding: '0px 0px 0px 25px',
                    height: 'auto',
                    color: '#2c3036',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.setState({ openBusinessInfo: true })}
                >
                  <p style={{ fontSize: '15px' }}>
                    <InfoOutlinedIcon
                      style={{
                        height: '20px',
                        position: 'relative',
                        top: '4.5px',
                        color: '#6a696e',
                      }}
                    />{' '}
                    {strings["Información adicional"]}
                  </p>
                </Box>
                <Box
                  height={'1.5%'}
                  style={{
                    borderRadius: '50%',
                    padding: '0px 0px 0px 25px',
                    height: 'auto',
                    color: '#2c3036',
                  }}
                >
                  <p style={{ fontSize: '15px' }}>
                    <StarIcon
                      style={{
                        height: '20px',
                        position: 'relative',
                        top: '4.5px',
                        color: getPrimaryColor(),
                      }}
                    />{' '}
                    {strings["Calificación:"]}{' '}
                    <span style={{ fontWeight: '800', }}>
                      {this.state.businessData.averageRating.toFixed(2)}
                    </span>
                  </p>
                </Box>
                {this.state.offers && this.state.offers.length > 0 ? (
                  <Box
                    className="hoversidemneu"
                    height={'1.5%'}
                    style={{
                      borderRadius: '50%',
                      padding: '0px 0px 0px 25px',
                      height: 'auto',
                      color: '#3d75ee',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleClickOpenDiscount();
                      this.setState({ showDiscountIsBusiness: true });
                    }}
                  >
                    <p style={{ fontSize: '15px' }}>
                      <DiscountIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '4.5px',
                          color: '#3d75ee',
                        }}
                      />
                      {strings[' ¡Promociones! ']}
                    </p>
                  </Box>
                ) : null}
                {this.state.selectedType === 1 ? (
                  <Box
                    height={'5.5%'}
                    style={{
                      borderRadius: '50%',
                      padding: '0px 10px 0px 50px',
                      height: 'auto',
                      color: 'slategray',
                    }}
                  >
                    <span style={{ fontSize: '16px' }}>
                      {' '}
                      {strings["Para recoger tu pedido debes dirigirte a:"]}&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window.open(
                          'https://www.google.com/maps/search/?api=1&query=' +
                            this.state.businessData.location.latitude.toString() +
                            '%2C' +
                            this.state.businessData.location.longitude.toString(),
                          '_blank'
                        );
                      }}
                    >
                      {this.state.businessData.location.address}
                    </span>
                  </Box>
                ) : null}

                <Box height={'2%'}></Box>
                <div
                  style={{
                    borderBottom: '0px solid #e6ebf1',
                    borderTop: '0px solid #e6ebf1',
                    padding: '10px 0px 10px 25px',
                  }}
                >
                  <Typography
                    style={{ fontSize: '19.2px', fontWeight: 'bold' }}
                  >
                    <WidgetsRoundedIcon
                      style={{
                        height: '19px',
                        position: 'relative',
                        top: '3px',
                        color: 'slategray',
                      }}
                    />{' '}
                    {strings["Menú"]}
                  </Typography>
                </div>
                <ul style={{ listStyle: 'none', margin: '0 0 30px 0', paddingInlineStart: '25px', }}>
                  {this.state.menu.map((item) => (
                    <li key={item.id}>
                      <Button
                        className="hoversidemneu"
                        variant="contained"
                        // endIcon={<ArrowForwardIosIcon style={{height: "10px",position: 'relative', top: '1px'}} />}
                        disableElevation
                        align="center"
                        style={{
                          backgroundColor: '#fff',
                          color: '#2c3036',
                          textTransform: 'none',
                          fontSize: '16.8px',
                          textAlign: 'left',
                          padding: '6px 5px',
                          minWidth: '41px',
                          marginRight: '0px',
                          fontWeight: '500',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                        }}
                        // onClick={() => handleClick(item.id)}
                      >
                        <ScrollLink
                          style={{ width: '225px', }}
                          activeClass="active-class"
                          color={getPrimaryColor()}
                          to={`cat-name-${item.id}`}
                          containerId={'scroll-container'}
                          id={`cat-name-${item.id}`}
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                          onSetActive={this.handleSetActive}
                        >
                          {item['nameEng'] && strings.language === 'en' ? item['nameEng']:item.name}
                          <KeyboardArrowRightRoundedIcon
                            style={{
                              height: '18px',
                              position: 'relative',
                              top: '7px',
                              color: 'darkslategray',
                              float: 'right',
                            }}
                          />
                        </ScrollLink>
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div
              id="scroll-container"
              style={{
                width: columns > 1 ? 'calc(100% - 280px)' : 'calc(100%)',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                backgroundColor: this.state.width < 600 ? '#ffffff' : '#ffffff',
                float: 'right',
                overflow: 'auto',
              }}
            >
              {columns <= 1 ? (
                <div>
                  {this.state.selectedType === 0 ? (
                    <Button
                      style={{
                          marginBottom: '7px',
                        backgroundColor: '#fff',
                        textTransform: 'none',
                        borderRight: '0px solid #cccccc',
                        padding: '10px 15px 10px 5px',
                        fontSize: '18px',
                        borderRadius: '0px',
                          width: '90%',
                          marginLeft: '5%',
                          borderRadius: '12px',
                      }}
                      variant="text"
                      startIcon={
                          <LocationOnIcon style={{ color: 'black' }} />
                      }
                      endIcon={
                          <ArrowDropDownIcon style={{ color: 'black' }} />
                      }
                      onClick={handleClickOpen}
                    >
                      <>
                        <span
                          style={{
                              color: 'black',
                            fontWeight: '800',
                            fontFamily: 'Nunito',
                          }}
                        >
                          {this.state.selectLocationName
                            ? this.state.selectLocationName
                            : strings['Seleccionar mi ubicación']}
                        </span>
                      </>
                    </Button>
                  ) : null}

                  {/* Header on phone view */}
                  <div
                    style={{
                      width: '100%',
                      height: '120px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${this.state.businessData.header})`,
                      marginBottom: '12px',
                      width: '92%',
                      marginLeft: '4%',
                      borderRadius: '14px',
                      boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                    }}
                  >
                  {this.state.width < 1000 && this.state.width > 599 ? (
                    <div style={{float: 'right',marginTop: '70px',marginRight: '10px',}}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                      >
                      <>{(!this.state.isPreOrder)?
                      <Button
                        style={{
                          margin: '1px',
                          backgroundColor: '#fff',
                          textTransform: 'none',
                          borderRight: '0px solid #cccccc',
                          padding: '6px 15px 6px 15px',
                          fontSize: '15px',
                          borderRadius: '12px',
                          margin:"0px 8px 0px"
                        }}
                        variant="text"
                        startIcon={
                          <AccessTimeIcon style={{ color: '#646464' }} />
                        }
                        endIcon={
                          <ArrowDropDownIcon style={{ color: '#000' }} />
                        }
                        onClick={handlePreOrderOpen}
                      >
                        <>

                            <span
                              style={{ color: '#000', fontWeight: 'bold' }}
                            >
                              {strings["Ahora"]}
                            </span>
                           {' '}
                        </>
                      </Button>: <Button
                        style={{
                          margin: '1px',
                          backgroundColor: '#fff',
                          textTransform: 'none',
                          borderRight: '0px solid #cccccc',
                          padding: '6px 15px 6px 15px',
                          fontSize: '15px',
                          borderRadius: '12px',
                          backgroundColor: getPrimaryColor()
                        }}
                        variant="filled"
                        startIcon={
                          <AccessTimeIcon style={{ color: '#fff' }} />
                        }
                        endIcon={
                          <CancelIcon style={{ color: '#fff' }} />
                        }
                        onClick={()=>{
                          this.setState(
                              { businessData: null },
                              (newState) => {
                                var weekDay = [6,0,1,2,3,4,5];
                  var currentDay = weekDay[new Date().getDay()];
                  const { id } = this.props.match.params;
                  var dataToAdd = { selectedDate: new Date(), selectedWeekDay: currentDay, busId: id,isTomorrow: false,  isPreOrder: false };
    localStorage.setItem('preOrder', JSON.stringify(dataToAdd));
    window.dispatchEvent(new Event("preOrder"));
                  this.setState({ selectedDate: new Date(), selectedWeekDay: currentDay, busId: id,isTomorrow: false,  isPreOrder: false });

                                handlePreOrderClose();
                                // this.setState({ businessData: {} });
                                this.getCities(id);
                              });

                        }}
                      >
                        <>

                            <span
                              style={{ color: '#fff', fontWeight: 'bold' }}
                            >
                              {((!this.state.isTomorrow && this.state.isToday)
                                                      ? strings[' Hoy, ']
                                                      : (this.state.isTomorrow && !this.state.isToday)
                                                      ? strings[' Mañana, ']
                                                      : moment(this.state.selectedDate).format('DD-MM-YYYY').toString()+ ',  ')  + moment(this.state.selectedDate).format('kk:mm').toString()
                                                  }
                            </span>
                           {' '}
                        </>
                      </Button>
                      }</></Stack>
                    </div>
                  ) : null}
                  </div>
                  {/* Header on phone view */}

                  <Box height={'0px'}></Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0}
                    >
                      <Box
                        textAlign="center"
                        width={'10%'}
                        style={{
                          borderRadius: '50%',
                          marginLeft: '15px',
                          marginRight: '20px',
                        }}
                      >
                        <img
                          src={this.state.businessData.logo}
                          alt="logo"
                          width={'130%'}
                          style={{ borderRadius: '12px' }}
                        />
                      </Box>
                      <Typography
                        style={{
                          // padding: '20px 20px 20px 20px',
                          fontSize: '18px',
                          fontWeight: '800',
                          color: '#2e2c36',
                          fontFamily: 'Nunito',
                        }}
                      >
                        {this.state.businessData.name}
                      </Typography>
                    </Stack>
                      <Box
                        style={{
                          borderRadius: '10px',
                          color: '#2c3036',
                          marginLeft: '10px',
                          marginRight: '15px',
                          background: 'white',
                          padding: '3px 6px',
                          border: '1px solid rgb(230, 235, 241)',
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={0}
                        >
                          <StarIcon
                            style={{
                              height: '16px',
                              color: getPrimaryColor(),
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontWeight: '600',
                              color: '#2e2c36',
                              fontFamily: 'Nunito',
                            }}
                          >
                            {' '}
                            {this.state.businessData.averageRating.toFixed(2)}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  {this.state.businessData?.promoText && (
                    <Box
                      textAlign="center"
                      height={'4.5%'}
                      style={{
                        color: '#2c3036',
                        marginTop: '5px',
                        padding: '0 15px',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          borderRadius: 8,
                          backgroundColor: getPrimaryColor(),
                          padding: '7px',
                        }}
                      >
                        <Typography
                          style={{
                            color: '#ffffff',
                            fontWeight: '400',
                            fontFamily: 'Nunito',
                          }}
                        >
                          {this.state.businessData?.promoText}
                        </Typography>
                      </div>
                    </Box>
                  )}
                  {/* {' Business info on phone view '} */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                    style={{
                      paddingLeft: '15px',
                      paddingRight: '15px',
                      border: '1.5px solid #f7f8f9',
                      margin: '15px 10%',
                      borderRadius: '16px',
                      paddingBottom: '2px',
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0}
                      // height={'1.5%'}
                      style={{
                        // borderRadius: '50%',
                        // padding: '0px 0px 0px 25px',
                        // marginBottom: '20px',
                        color: '#2c3036',
                      }}
                    >
                      {this.state.selectedType === 0 ? (
                        <p
                          style={{
                            fontSize: '16px',
                            color: '#2e2c36',
                            fontFamily: 'Nunito',
                            margin: '0',
                          }}
                        >
                          <span style={{ color: 'slategray', }}>{strings["entrega"]}</span><AccessTimeIcon
                            style={{
                              height: '18px',
                              position: 'relative',
                              top: '4px',
                              color: '#6a696e',
                            }}
                          />{' '}
                          <p style={{margin: '0', fontWeight: '800', }}>
                          {Math.trunc(
                            (this.state.businessData['totalTime'] == null ||
                            this.state.businessData['totalOrders'] == null ||
                            this.state.businessData['totalOrders'] === 0 ||
                            this.state.businessData['totalTime'] /
                              this.state.businessData['totalOrders'] /
                              60 <=
                              7
                              ? 35
                              : this.state.businessData['totalTime'] >= 46
                              ? 46
                              : this.state.businessData['totalTime'] /
                                this.state.businessData['totalOrders'] /
                                60) - 6
                          )}{' '}
                          -{' '}
                            {Math.trunc(
                              (this.state.businessData['totalTime'] == null ||
                              this.state.businessData['totalOrders'] == null ||
                              this.state.businessData['totalOrders'] === 0 ||
                              this.state.businessData['totalTime'] /
                                this.state.businessData['totalOrders'] /
                                60 <=
                                7
                                ? 35
                                : this.state.businessData['totalTime'] >= 46
                                ? 46
                                : this.state.businessData['totalTime'] /
                                  this.state.businessData['totalOrders'] /
                                  60) + 6
                            )}{' '}
                            min
                          </p>
                        </p>
                      ) : null}
                      {this.state.selectedType === 1 ? (
                        <p
                          style={{
                            fontSize: '16px',
                            color: '#2e2c36',
                            fontFamily: 'Nunito',
                            margin: '0',
                          }}
                        >
                          <span style={{ color: 'slategray', }}>{strings["Recoger"]}</span><AccessTimeIcon
                            style={{
                              height: '18px',
                              position: 'relative',
                              top: '4px',
                              color: '#6a696e',
                            }}
                          />{' '}
                          <p style={{margin: '0', fontWeight: '800', }}>
                          {Math.trunc(
                            this.state.businessData['totalTime'] == null ||
                              this.state.businessData['totalOrders'] == null ||
                              this.state.businessData['totalOrders'] === 0 ||
                              this.state.businessData['totalTime'] /
                                this.state.businessData['totalOrders'] /
                                60 <=
                                7
                              ? 35
                              : this.state.businessData['totalTime'] >= 46
                              ? 46
                              : this.state.businessData['totalTime'] /
                                this.state.businessData['totalOrders'] /
                                60
                          )}{' '}
                          {strings["min aprox"]}
                          </p>
                        </p>
                      ) : null}
                    </Stack>
                    {this.state.selectedType === 0 ? (
                      <p
                        style={{
                          fontSize: '16px',
                          color: '#2e2c36',
                          fontFamily: 'Nunito',
                          margin: '0',
                        }}
                      >
                        <span style={{ color: 'slategray', }}>{strings["envio"]}</span><DirectionsBikeIcon
                          style={{
                            height: '18px',
                            position: 'relative',
                            top: '4px',
                            color: '#6a696e',
                          }}
                        />{' '}
                        <p style={{margin: '0', fontWeight: '800', }}>${(this.state.selectLocationName && this.state.delivery)?(this.state.delivery + this.extraCharges()).toFixed(2):" "+strings["Pendiente"]}</p>
                      </p>
                    ) : null}
                    {this.state.selectedType === 1 ? (
                      <p
                        style={{
                          fontSize: '16px',
                          color: '#2e2c36',
                          fontFamily: 'Nunito',
                          margin: '0',
                        }}
                      >
                        <span style={{ color: 'slategray', }}>{strings["Distancia"]}</span><DirectionsRunIcon
                          style={{
                            height: '18px',
                            position: 'relative',
                            top: '4px',
                            color: '#6a696e',
                          }}
                        />{' '}
                        <p style={{margin: '0', fontWeight: '800', }}>
                        {geolib.getDistance(
                          {
                            latitude: this.state.businessData.location.latitude,
                            longitude:
                              this.state.businessData.location.longitude,
                          },
                          this.state.selectedAddress
                        ) > 100
                          ? '100+ '
                          : geolib
                              .getDistance(
                                {
                                  latitude:
                                    this.state.businessData.location.latitude,
                                  longitude:
                                    this.state.businessData.location.longitude,
                                },
                                this.state.selectedAddress
                              )
                              .toFixed(2)}
                        km
                        </p>
                      </p>
                    ) : null}
                    <Stack
                      height={'1.5%'}
                      style={{
                        color: '#2c3036',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.setState({ openBusinessInfo: true })}
                    >
                      <p style={{ fontSize: '16px', fontFamily: 'Nunito', margin: '0', }}>
                        <InfoOutlinedIcon
                          style={{
                            height: '18px',
                            position: 'relative',
                            top: '4px',
                            color: '#6a696e',
                          }}
                        />{' '}

                      </p>
                      <p style={{margin: '0', fontWeight: '800', }}>{strings["Informaciónshort"]}</p>
                    </Stack>
                  </Stack>
                  {/* {' Business info on phone view '} */}

                  {this.state.offers && this.state.offers.length > 0 ? (
                    <Stack
                      height={'1.5%'}
                      style={{
                        padding: '0px 15px 20px',
                        color: '#3d75ee',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleClickOpenDiscount();
                        this.setState({ showDiscountIsBusiness: true });
                      }}
                    >
                    <p
                      style={{
                        fontSize: '15px',
                        margin: '0',
                        fontWeight: '700',
                        background: '#ffffff',
                        borderRadius: '15px',
                        padding: '9px 12px 12px 12px',
                        textDecoration: 'none',
                        boxShadow: '0 5px 9px #3e525d5e',
                        border: '1px solid #e6ebf1',
                      }}
                    >
                      <DiscountIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '6px',
                          color: '#3d75ee',
                        }}
                      />
                      {strings[' ¡Promociones! ']}
                      <KeyboardArrowRightRoundedIcon
                        style={{
                          height: '20px',
                          position: 'relative',
                          top: '6px',
                          color: 'darkslategray',
                          float: 'right',
                        }}
                      />
                    </p>
                  </Stack>
                  ) : null}

                  {/* <Box
                  height={'1.5%'}
                  style={{
                    borderRadius: '50%',
                    padding: '0px 0px 0px 40px',
                    marginBottom: '20px',
                    color: '#2c3036',
                  }}
                >
                  {this.state.selectedType === 0 ? (
                    <p style={{ fontSize: '15px' }}>
                      <MonetizationOnOutlinedIcon
                        style={{
                          height: '16px',
                          position: 'relative',
                          top: '3.5px',
                          color: '#6a696e',
                        }}
                      />{' '}
                      Delivery cost: ${this.state.delivery.toFixed(2)}
                    </p>
                  ) : null}
                  {this.state.selectedType === 1 ? (
                    <p style={{ fontSize: '15px' }}>
                      <MonetizationOnOutlinedIcon
                        style={{
                          height: '16px',
                          position: 'relative',
                          top: '3.5px',
                          color: '#6a696e',
                        }}
                      />{' '}
                      Distance{' '}
                      {geolib.getDistance(
                        {
                          latitude: this.state.businessData.location.latitude,
                          longitude: this.state.businessData.location.longitude,
                        },
                        this.state.selectedAddress
                      ) > 100
                        ? '100+ '
                        : geolib
                            .getDistance(
                              {
                                latitude:
                                  this.state.businessData.location.latitude,
                                longitude:
                                  this.state.businessData.location.longitude,
                              },
                              this.state.selectedAddress
                            )
                            .toFixed(2)}
                      km
                    </p>
                  ) : null}
                </Box> */}

                  {this.state.selectedType === 1 ? (
                    <Box
                      height={'5.5%'}
                      style={{
                        padding: '0px 10% 0px 10%',
                        marginBottom: '25px',
                        color: '#33373d',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '17px',
                          color: '#2e2c36',
                          fontFamily: 'Nunito',
                        }}
                      >
                        {' '}
                        {strings["Para recoger tu pedido debes dirigirte a:"]}&nbsp;
                      </span>
                      <span
                        style={{ fontSize: '17px', fontWeight: '800', color: getPrimaryColor(), fontFamily: 'Nunito', }}
                        onClick={() => {
                          window.open(
                            'https://www.google.com/maps/search/?api=1&query=' +
                              this.state.businessData.location.latitude.toString() +
                              '%2C' +
                              this.state.businessData.location.longitude.toString(),
                            '_blank'
                          );
                        }}
                      >
                        {this.state.businessData.location.address}
                      </span>
                    </Box>
                  ) : null}

                  <div className="hideontabletandupflex" style={{display: 'none', padding: "10px 0px 0px"}}></div>
                    <div className="hideontabletandupflex"
                      style={{
                        display: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ToggleButtonGroup
                      // color="primary"
                        value={this.state.selectedType}
                        exclusive
                        onChange={(event, newAlignment) => {
                          if (newAlignment !== null) {
                            this.setState({ selectedType: newAlignment });
                          // console.log(this.state);
                            if (
                              !this.state.canDeliverHere &&
                              newAlignment === 0
                            ) {
                              this.setState({ storeIsOpen: false });
                              handleAlertOpen();
                            } else if (
                              !this.state.canDeliverHere &&
                              newAlignment === 1
                            ) {
                              this.setState({ storeIsOpen: true });
                            }
                          }
                        // var data = Math.trunc((((this.state.businessData['totalTime'] == null ||
                        //                   this.state.businessData['totalOrders'] == null ||
                        //                   this.state.businessData['totalOrders'] === 0 ||
                        //                   ((this.state.businessData['totalTime'] / this.state.businessData['totalOrders']) /
                        //                       60) <=
                        //                       7)
                        //                   ? 35
                        //                   : (this.state.businessData['totalTime'] >= 46)
                        //                   ? 46
                        //                   : (this.state.businessData['totalTime'] / this.state.businessData['totalOrders']) /
                        //                   60)))
                        // console.log(newAlignment,data,(this.state.businessData['totalTime'] == null ||
                        //                   this.state.businessData['totalOrders'] == null ||
                        //                   this.state.businessData['totalOrders'] === 0));
                        }}
                      >
                      <ToggleButton
                        value={0}
                        style={{
                          fontWeight: '700',
                          fontFamily: 'Nunito',
                          textTransform: 'none',
                          color: '#3e4547',
                          borderRadius: '12px 0 0 12px',
                          border: '2px solid rgb(230, 235, 241)',
                        }}
                      >
                        <DirectionsBikeIcon
                          style={{
                            fontSize: '17px',
                            fontWeight: 'bold',
                            color: getPrimaryColor(),
                            marginRight: '5px',
                          }}
                        />
                        {strings["Entrega a domicilio"]}
                      </ToggleButton>
                      <ToggleButton
                        value={1}
                        style={{
                          fontWeight: '700',
                          fontFamily: 'Nunito',
                          textTransform: 'none',
                          color: '#3e4547',
                          borderRadius: '0 12px 12px 0',
                          border: '2px solid rgb(230, 235, 241)',
                        }}
                      >
                        <DirectionsRunIcon
                          style={{
                            height: '17px',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            color: getPrimaryColor(),
                            marginRight: '5px',
                          }}
                        />
                        {strings["Recoger en tienda"]}
                      </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    <Box className="hideontabletandupflex" height={'20px'} style={{display: 'none',}}></Box>
                  </div>
              ) : null}
              {/* {' '} */}
              <List sx={{ pt: 0 }} style={{background: this.state.width < 600 ? 'white' : 'initial',}} >
                {columns <= 1 ? (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="example"
                    spacing={1}
                    style={{
                      margin: '7px 10px 8px 10px',
                      display: 'flex',
                      flexShrink: 0,
                      overflowX: 'auto',
                      zIndex: 99,
                      position: 'sticky',
                      backgroundColor: '#f8f9fb',
                      borderTop: '0px solid rgb(230, 235, 241)',
                      borderBottom: '0px solid rgb(230, 235, 241)',
                      top: 0,
                      background: 'white',
                    }}
                  >
                    {/* <div   > */}
                    {/* <ul style={{ listStyle: 'none' }}> */}
                    {this.state.menu.map((item, index) => (
                      // <li key={item.id}>
                      <Typography
                        noWrap
                        className="noselect"
                        style={{
                          color: '#2e2c36',
                          textTransform: 'none',
                          fontSize: '19px',
                          textAlign: 'left',
                          padding: '6px 8px',
                          marginRight: '5px',
                          overflow: 'visible',
                          borderRadius: '10px',
                          border: '0px solid rgb(230, 235, 241)',
                          fontWeight: '800',
                        }}
                        onClick={() => handleClick(item.id)}
                        key={index}
                      >
                        <ScrollLink
                          activeClass="active-class"
                          color={getPrimaryColor()}
                          to={`cat-name-${item.id}`}
                          containerId={'scroll-container'}
                          id={`cat-name-${item.id}`}
                          spy={true}
                          smooth={true}
                          offset={-50}
                          duration={500}
                          onSetActive={this.handleSetActive}
                        >
                          {item['nameEng'] && strings.language === 'en' ? item['nameEng']:item.name}
                        </ScrollLink>
                      </Typography>

                      // </li>
                    ))}
                    {/* {this.state.menu.map((item) => (
                      // <li key={item.id}>
                      <Typography
                        noWrap
                        // variant="contained"
                        // // endIcon={<ArrowForwardIosIcon style={{height: "10px",position: 'relative', top: '1px'}} />}
                        // disableElevation
                        // align="center"
                        className='noselect'
                        style={{
                          // backgroundColor: '#fff',
                          color: '#2e2c36',
                          textTransform: 'none',
                          fontSize: '15px',
                          textAlign: 'left',
                          padding: '6px 5px',
                          marginRight: '5px',
                          overflow: 'visible',
                          borderRadius: '8px',
                          border: '1px solid rgb(230, 235, 241)',
                          fontFamily: 'Nunito',
                        }}
                        onClick={() => handleClick(item.id)}
                      >
                        {item.name}
                      </Typography>
                      // </li>
                    ))} */}
                    {/* </div> */}
                    {/* </ul> */}
                  </Stack>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '220px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${this.state.businessData.header})`,
                    }}
                  ></div>
                )}
                {this.state.menuData && this.state.menuData.length>0 && (!this.state.toggledOff)? this.state.menu.map((email) => (
                  <Element
                    key={email.id}
                    ref={refs[email.id]}
                    style={{
                      backgroundColor: '#ffffff',
                      margin: this.state.width < 600 ? '0px 10px 10px 10px' : '0',
                      width: this.state.width < 600 ? 'calc(100% - 20px)' : 'calc(100% - 0px)',
                    }}
                    name={`cat-name-${email.id}`}
                    id={`cat-${email.id}`}
                    className="element"
                  >
                    <Typography
                      style={{
                        padding:
                          this.state.width < 600
                            ? '20px 20px 20px 10px'
                            : '20px 20px 20px 20px',
                        fontSize: '26.4px',
                        fontWeight: '800',
                      }}
                    >
                      {email['nameEng'] && strings.language === 'en' ? email['nameEng']:email.name}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        padding: this.state.width > 600 ? '0px 15px 15px 15px' : '0',
                        width: 'calc(100% - 0px)',
                      }}
                    >
                      <GridList cellHeight={130} cols={columns} style={{ margin: this.state.width > 600 ? '0' : '-2px',}}>
                      {email.products.map((image) => (
                        <GridListTile
                          key={image.id}
                          style={{
                            backgroundColor: '#ffffff',
                              height: this.state.width > 600 ? '102px' : '130px',
                              border: this.state.width > 600 ? '0' : '0 0 1px solid #e6ebf1 0',
                              borderRadius:   this.state.width > 600 ? '10px' : '0',
                            margin: this.state.width < 600 ? '0px 0px 0px 2px' : '12px 6px',
                              padding: this.state.width > 600 ? '0' : '15px 10px 15px 10px',
                            cursor: 'pointer',
                              width:
                                columns > 1
                                  ? columns > 2
                                    ? 'calc(33.33% - 12px)'
                                    : 'calc(33.33% - 12px)'
                                  : 'calc(100% - 12px)',
                          }}
                        >
                          {/* <img src={ image.images } /> */}
                          <div
                            onClick={() => {
                              handleClickOpenVariable(image);
                            }}
                          >
                            <div
                              style={{
                                  float:
                                    this.state.width < 600 ? 'right' : 'left',
                                backgroundColor: '#ffffff',
                                  width: this.state.width < 600 ?' 100px' : '102px',
                                  height: this.state.width < 600 ? '100px' : '102px',
                              }}
                            >
                              {image.images ? (
                                <img
                                  src={image.images}
                                  width={'100%'}
                                  height={'100%'}
                                  style={{
                                    borderRadius:
                                      this.state.width < 600
                                        ? '17px'
                                        : '17px',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                                {((this.state.offersDetails[image.id] !=
                                                                    null))?<>{(this.state.width < 600)?<div
                              style={{
                                position: 'relative',
                                  paddingLeft: "25px",
                                      bottom: (image.images)?'50px':undefined,
                                top: (image.images)?undefined:'50px',
                                height: '100px',
                              }}
                              ><Chip sx={{backgroundColor: getPrimaryColor(),fontSize: this.state.width < 600 ? '16px' : '14.4px',
                                    fontWeight: this.state.width < 600 ? '600' : '600',
                                    color: "#ffffff",
                                  fontFamily: 'Nunito',
                                    letterSpacing: 'normal',}}  label={this.state.offersDetails[image.id]} /></div>:<div
                              style={{
                                position: 'relative',
                                  paddingLeft: "25px",
                                      bottom: (image.images)?'50px':undefined,
                                top: (image.images)?undefined:'50px',
                                height: '100px',
                              }}
                              ><Chip sx={{backgroundColor: getPrimaryColor(),fontSize: this.state.width < 600 ? '16px' : '14.4px',
                                    fontWeight: this.state.width < 600 ? '600' : '600',
                                    color: "#ffffff",
                                  fontFamily: 'Nunito',
                                    letterSpacing: 'normal',}} label={this.state.offersDetails[image.id]} /></div>}</>:null}
                            </div>
                            <div
                              style={{
                                float: 'right',
                                backgroundColor: '#ffffff',
                                  width: 'calc(100% - 102px)',
                                height: '100px',
                              }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '2',
                                  WebkitBoxOrient: 'vertical',
                                }}
                                style={{
                                  padding:
                                    this.state.width < 600
                                        ? '5px 15px 0px 0px'
                                        : '0px 45px 5px 10px',
                                  fontSize:
                                    this.state.width < 600
                                      ? '18px'
                                        : '16.4px',
                                  fontWeight:
                                    this.state.width < 600 ? '600' : '600',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                  {image['nameEng'] && strings.language === 'en' ? image['nameEng']:image.name}
                              </Typography>
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                                style={{
                                  padding: this.state.width < 600 ? '0px 15px 0px 0px' : '0px 5px 0px 10px',
                                  fontSize: this.state.width < 600 ? '14px' : '14px',
                                  fontWeight: 'light',
                                  color: 'slategray',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                  {image['descriptionEng'] && strings.language === 'en' ? image['desriptionEng']:image.description}
                              </Typography>
                              <>
                                {image['discountPercentage'] != null && image['discountPercentage'] > 0 &&
                                moment().isBefore(moment(image['discountedTill'].toDate())
                                ) ? (
                                  <Stack
                                    style={{padding: this.state.width < 600 ? '4px 5px 0px 0px' : '4px 5px 5px 10px'}}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0}>
                                    <LocalOfferIcon
                                      style={{
                                        fontSize: this.state.width < 600 ? '20px' : '17.4px',
                                        fontWeight: '800',
                                        color: '#3D75EE',
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        // padding: this.state.width < 600 ? '7px 5px 5px 0px' : '7px 5px 5px 10px',
                                        fontSize:
                                          this.state.width < 600
                                            ? '16px'
                                            : '15.4px',
                                        fontWeight: '800',
                                        fontFamily: 'Nunito',
                                        color: '#3D75EE',
                                      }}
                                    >
                                      {'  -' +
                                        image[
                                          'discountPercentage'
                                        ].toString() +
                                        '%'}
                                      &nbsp;&nbsp;
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize:
                                          this.state.width < 600
                                            ? '16px'
                                            : '15.4px',
                                        fontWeight: '800',
                                        fontFamily: 'Nunito',
                                      }}
                                    >
                                        $
                                        {((this.state.businessData.partner !=
                                        null &&
                                      this.state.businessData.partner
                                        ? image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                          ? image['price'] -
                                            (image['price'] *
                                              image['discountPercentage']) /
                                              100
                                          : image['price']
                                        : (image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                            ? image['price'] -
                                              (image['price'] *
                                                image['discountPercentage']) /
                                                100
                                            : image['price']) +
                                          (image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                            ? image['price'] -
                                              (image['price'] *
                                                image['discountPercentage']) /
                                                100
                                            : image['price']) *
                                            (this.state.businessData.tax /
                                              100)
                                        )*multiFactor()).toFixed(multiFactor()>1?0:2)}
                                      &nbsp;&nbsp;
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize:
                                          this.state.width < 600
                                            ? '16px'
                                            : '15.4px',
                                        fontWeight: '400',
                                        fontFamily: 'Nunito',
                                        textDecoration: 'line-through',
                                        color: '#616468',
                                      }}
                                    >
                                        $
                                        {((this.state.businessData.partner !=
                                        null &&
                                      this.state.businessData.partner
                                        ? image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                          ? image['price']
                                          : image['price']
                                        : (image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                            ? image['price']
                                            : image['price']) +
                                          (image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                            ? image['price']
                                            : image['price']) *
                                            (this.state.businessData.tax /
                                              100)
                                        )*multiFactor()).toFixed(multiFactor()>1?0:2)}
                                    </Typography>
                                  </Stack>
                                ) : (
                                  <Stack
                                    style={{padding: this.state.width < 600 ? '4px 5px 0px 0px' : '4px 5px 5px 10px',}}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0}>
                                    <Typography
                                      style={{
                                        paddingLeft: (image['perKg'])?"5px": null,
                                      fontSize:
                                        this.state.width < 600
                                          ? '16px'
                                          : '15.4px',
                                      fontWeight: '800',
                                      fontFamily: 'Nunito',
                                    }}
                                  >
                                      $
                                      {((this.state.businessData.partner !=
                                      null && this.state.businessData.partner
                                      ? image['discountPercentage'] != null &&
                                        image['discountPercentage'] > 0 &&
                                        moment().isBefore(
                                          moment(
                                            image['discountedTill'].toDate()
                                          )
                                        )
                                        ? image['price'] -
                                          (image['price'] *
                                            image['discountPercentage']) /
                                            100
                                        : image['price']
                                      : (image['discountPercentage'] !=
                                          null &&
                                        image['discountPercentage'] > 0 &&
                                        moment().isBefore(
                                          moment(
                                            image['discountedTill'].toDate()
                                          )
                                        )
                                          ? image['price'] -
                                            (image['price'] *
                                              image['discountPercentage']) /
                                              100
                                          : image['price']) +
                                        (image['discountPercentage'] !=
                                          null &&
                                        image['discountPercentage'] > 0 &&
                                        moment().isBefore(
                                          moment(
                                            image['discountedTill'].toDate()
                                          )
                                        )
                                          ? image['price'] -
                                            (image['price'] *
                                              image['discountPercentage']) /
                                              100
                                          : image['price']) *
                                          (this.state.businessData.tax / 100)
                                      )*multiFactor()).toFixed(multiFactor()>1?0:2)}
                                    </Typography>
                                  </Stack>
                                )}
                              </>
                              <>
                              <Stack
                                style={{padding: this.state.width < 600 ? '0px 5px 0px 0px' : '0px 5px 0px 10px'}}
                                direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                                {(image['perKg']) ? (
                                <Tooltip title={strings["se vende por kilo"]}>
                                  <img src = {require('./assets/kilo.png')} alt = {"Se vende por kilo"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['vegan'] != null && image['vegan']) ? (
                                <Tooltip title={strings["este producto es 100% vegano"]}>
                                  <img src = {require('./assets/vegan.png')} alt = {"Vegan"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['riesgoDeDerrame'] != null && image['riesgoDeDerrame']) ? (
                                <Tooltip title={strings["este producto tiene riesgo de derrame"]}>
                                  <img src = {require('./assets/derramable.png')} alt = {"Riesgo de derrame"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['picorValue'] != null && (image['picorValue'] == '1' || image['picorValue'] == '2' || image['picorValue'] == '3'
                                  || image['picorValue'] == '4')) ? (
                                <Tooltip title={strings["nivel de picante"]}>
                                  <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['picorValue'] != null && (image['picorValue'] == '2' || image['picorValue'] == '3' || image['picorValue'] == '4')) ? (
                                <Tooltip title={strings["nivel de picante"]}>
                                  <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['picorValue'] != null && (image['picorValue'] == '3' || image['picorValue'] == '4')) ? (
                                <Tooltip title={strings["nivel de picante"]}>
                                  <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['picorValue'] != null && (image['picorValue'] == '4')) ? (
                                <Tooltip title={strings["nivel de picante"]}>
                                  <img src = {require('./assets/spice.png')} alt = {"Picor"} style={{height: '18px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['gluten'] != null && image['gluten']) ? (
                                <Tooltip title={strings["Contiene gluten"]}>
                                  <img src = {require('./assets/alergenos/gluten.png')} alt = {"Gluten"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['crustaceos'] != null && image['crustaceos']) ? (
                                <Tooltip title={strings["Contiene crustaceos"]}>
                                  <img src = {require('./assets/alergenos/crustaseos.png')} alt = {"Crustaceos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['huevo'] != null && image['huevo']) ? (
                                <Tooltip title={strings["Contiene huevo"]}>
                                  <img src = {require('./assets/alergenos/huevo.png')} alt = {"Huevo"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['pescado'] != null && image['pescado']) ? (
                                <Tooltip title={strings["Contiene pescado"]}>
                                  <img src = {require('./assets/alergenos/pescado.png')} alt = {"Pescado"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['cacahuates'] != null && image['cacahuates']) ? (
                                <Tooltip title={strings["Contiene cacahuates"]}>
                                  <img src = {require('./assets/alergenos/cacahuates.png')} alt = {"Cacahuates"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['soya'] != null && image['soya']) ? (
                                <Tooltip title={strings["Contiene soya"]}>
                                  <img src = {require('./assets/alergenos/soya.png')} alt = {"Soya"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['leche'] != null && image['leche']) ? (
                                <Tooltip title={strings["Contiene leche"]}>
                                  <img src = {require('./assets/alergenos/leche.png')} alt = {"Leche"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['frutos'] != null && image['frutos']) ? (
                                <Tooltip title={strings["Contiene frutos"]}>
                                  <img src = {require('./assets/alergenos/cascara.png')} alt = {"Cascara"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['apio'] != null && image['apio']) ? (
                                <Tooltip title={strings["Contiene apio"]}>
                                  <img src = {require('./assets/alergenos/apio.png')} alt = {"Apio"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['mostaza'] != null && image['mostaza']) ? (
                                <Tooltip title={strings["Contiene mostaza"]}>
                                  <img src = {require('./assets/alergenos/mostaza.png')} alt = {"Mostaza"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['sesamo'] != null && image['sesamo']) ? (
                                <Tooltip title={strings["Contiene sesamo"]}>
                                  <img src = {require('./assets/alergenos/sesamo.png')} alt = {"Sésamo"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['sulfitos'] != null && image['sulfitos']) ? (
                                <Tooltip title={strings["Contiene sulfitos"]}>
                                  <img src = {require('./assets/alergenos/sulfitos.png')} alt = {"Sulfitos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['moluscos'] != null && image['moluscos']) ? (
                                <Tooltip title={strings["Contiene moluscos"]}>
                                  <img src = {require('./assets/alergenos/moluscos.png')} alt = {"Moluscos"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                                {(image['altramuces'] != null && image['altramuces']) ? (
                                <Tooltip title={strings["Contiene altramuces"]}>
                                  <img src = {require('./assets/alergenos/altramuces.png')} alt = {"Altramuces"} style={{height: '20px',width: 'auto',marginRight:'10px'}}/>
                                </Tooltip>
                                ) : null}
                              </Stack>         
                              </>
                            </div>
                          </div>
                          {this.state.storeIsOpen &&
                          this.state.cart['item'].find(
                            (opt) => opt['id'] === image['id']
                          ) == null &&
                          (image['enabled'] ||
                            (!image['enabled'] &&
                              image['willBeAvailableAt'] != null &&
                              moment().isAfter(
                                moment(image['willBeAvailableAt'].toDate())
                              ))) ? (
                            <div
                              style={{
                                position: 'relative',
                                  left:
                                    this.state.width < 600
                                      ? 'calc(100% - 35px)'
                                      : 'calc(100% - 140px)',
                                bottom: '100px',
                                height: '100px',
                              }}
                            >
                              <Avatar
                                style={{
                                  backgroundColor: getPrimaryColor(),
                                  width: '35px',
                                  height: '35px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (
                                    image['customize'] != null &&
                                    image['customize'].length > 0
                                  ) {
                                    handleClickOpenVariable(image);
                                  } else {
                                    var cart = JSON.parse(
                                      JSON.stringify(this.state.cart)
                                    );
                                    // setState(() {
                                    cart['item'].push({
                                      name: image['name'],
                                        nameEng: image['nameEng'] && strings.language === 'en' ? image['nameEng']: image['name'],
                                      id: image['id'],
                                      price:
                                          Number(((this.state.businessData.partner !=
                                            null &&
                                          this.state.businessData.partner
                                            ? image['discountPercentage'] !=
                                                null &&
                                              image['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  image[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                              ? image['price'] -
                                                (image['price'] *
                                                  image['discountPercentage']) /
                                                  100
                                              : image['price']
                                            : (image['discountPercentage'] !=
                                                null &&
                                              image['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  image[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                                ? image['price'] -
                                                  (image['price'] *
                                                    image[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : image['price']) +
                                              (image['discountPercentage'] !=
                                                null &&
                                              image['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  image[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                                ? image['price'] -
                                                  (image['price'] *
                                                    image[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : image['price']) *
                                                (this.state.businessData.tax /
                                                  100))*multiFactor()).toFixed(multiFactor()>1?0:2)),
                                                  perKg: image['perKg'] ?? false,
                                                  riesgoDeDerrame: image['riesgoDeDerrame'] ?? false,
                                                  extraDelivery: image['extraDelivery'] ?? false,
                                        originalPrice:
                                        this.state.businessData.partner !=
                                          null &&
                                        this.state.businessData.partner
                                          ? image['discountPercentage'] !=
                                              null &&
                                            image['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                image[
                                                  'discountedTill'
                                                ].toDate()
                                              )
                                            )
                                            ? image['price'] -
                                              (image['price'] *
                                                image['discountPercentage']) /
                                                100
                                            : image['price']
                                          : (image['discountPercentage'] !=
                                              null &&
                                            image['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                image[
                                                  'discountedTill'
                                                ].toDate()
                                              )
                                            )
                                              ? image['price'] -
                                                (image['price'] *
                                                  image[
                                                    'discountPercentage'
                                                  ]) /
                                                  100
                                              : image['price']) +
                                            (image['discountPercentage'] !=
                                              null &&
                                            image['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                image[
                                                  'discountedTill'
                                                ].toDate()
                                              )
                                            )
                                              ? image['price'] -
                                                (image['price'] *
                                                  image[
                                                    'discountPercentage'
                                                  ]) /
                                                  100
                                              : image['price']) *
                                              (this.state.businessData.tax /
                                                100),
                                      quantity: 1,
                                      addons: 0,
                                      customize: [],
                                    });
                                    cart['totalCost'] =
                                      cart['totalCost'] +
                                        Number(((this.state.businessData.partner !=
                                        null &&
                                      this.state.businessData.partner
                                        ? image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                          ? image['price'] -
                                            (image['price'] *
                                              image['discountPercentage']) /
                                              100
                                          : image['price']
                                        : (image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                            ? image['price'] -
                                              (image['price'] *
                                                image['discountPercentage']) /
                                                100
                                            : image['price']) +
                                          (image['discountPercentage'] !=
                                            null &&
                                          image['discountPercentage'] > 0 &&
                                          moment().isBefore(
                                            moment(
                                              image['discountedTill'].toDate()
                                            )
                                          )
                                            ? image['price'] -
                                              (image['price'] *
                                                image['discountPercentage']) /
                                                100
                                            : image['price']) *
                                            (this.state.businessData.tax /
                                                100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                    cart['totalItems']++;
                                    if (
                                      image['averageTime'] != null &&
                                      cart['maxTime'] < image['averageTime']
                                    ) {
                                      cart['maxTime'] = image['averageTime'];
                                    }
                                    this.setState({ cart: cart });
                                    // });
                                  }
                                }}
                              >
                                <AddRoundedIcon style={{ height: '30px' }} />
                              </Avatar>
                            </div>
                          ) : null}
                          {this.state.storeIsOpen  &&
                          (!image['enabled'] &&
                            (image['willBeAvailableAt'] == null ||
                              moment().isBefore(
                                moment(image['willBeAvailableAt'].toDate())
                              ))) ? (
                            <div
                              style={{
                                position: 'absolute',
                                left: this.state.width < 600 ? '0' : '0',
                                bottom: this.state.width < 600 ? 'inherit' : '0',
                                height: this.state.width < 600 ? 'auto' : 'auto',
                                width: '100%',
                                borderRadius: '16px',
                                overflow: 'hidden',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#ffffffd9',
                                  padding: "35px 0",
                                  border: '0',
                                  borderRadius: "10px",
                                  height: '102px',
                                  textAlign: 'center',
                                }}
                              >
                                 <Typography
                                      style={{
                                        // padding: this.state.width < 600 ? '7px 5px 5px 0px' : '7px 5px 5px 10px',
                                          fontSize:
                                            this.state.width < 600
                                              ? '18px'
                                              : '16.4px',
                                        fontFamily: 'Nunito',
                                        color: '#383944',
                                        fontWeight: '800',
                                      }}
                                    >
                                      {strings['Agotado']}
                                    </Typography>
                              </div>
                            </div>
                          ) : null}
                          {this.state.storeIsOpen &&
                          this.state.cart['item'].find(
                            (opt) => opt['id'] === image['id']
                          ) != null &&
                          (image['enabled'] ||
                            (!image['enabled'] &&
                              image['willBeAvailableAt'] != null &&
                              moment().isAfter(
                                moment(image['willBeAvailableAt'].toDate())
                              ))) ? (
                            <div
                              style={{
                                position: 'relative',
                                  left:
                                    this.state.width < 600
                                      ? 'calc(100% - 86px)'
                                      : 'calc(100% - 188px)',
                                bottom: '100px',
                                height: '100px',
                              }}
                            >
                              <ButtonGroup
                                variant="text"
                                disableElevation
                                size={'small'}
                                style={{
                                  backgroundColor: getPrimaryColor(),
                                  borderRadius: '50px',
                                }}
                              >
                                <Button
                                    style={{
                                      borderRadius: '50px 0 0 50px',
                                      minWidth: '28px',
                                      border: '0',
                                    }}
                                  >
                                    <RemoveRoundedIcon
                                      sx={{ color: 'action.active' }}
                                      style={{
                                        fontSize: '17px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                      }}
                                  onClick={() => {
                                    var cart = JSON.parse(
                                      JSON.stringify(this.state.cart)
                                    );
                                    for (
                                      var t1 = cart['item'].length - 1;
                                      t1 >= 0;
                                      t1--
                                    ) {
                                      if (
                                        cart['item'][t1]['id'] ===
                                        image['id']
                                      ) {
                                        if (
                                          cart['item'][t1]['quantity'] > 1
                                        ) {
                                          // setState(() {
                                          cart['totalCost'] =
                                            cart['totalCost'] -
                                            cart['item'][t1]['price'];
                                          cart['totalItems']--;
                                          cart['item'][t1]['quantity']--;
                                          // boxCart
                                          //     .put(
                                          //     'cart',
                                          //     cart);
                                          // });
                                        } else if (
                                          cart['item'][t1]['quantity'] === 1
                                        ) {
                                          // setState(() {
                                          cart['totalCost'] =
                                            cart['totalCost'] -
                                            cart['item'][t1]['price'];
                                          cart['totalItems']--;
                                          cart['item'].splice(t1, 1);
                                          // boxCart
                                          //     .put(
                                          //     'cart',
                                          //     cart);
                                          // });
                                          if (
                                            cart['maxTime'] ===
                                            image['averageTime']
                                          ) {
                                            cart['maxTime'] = 0;
                                            for (
                                              var t2 =
                                                cart['item'].length - 1;
                                              t2 >= 0;
                                              t2--
                                            ) {
                                              if (
                                                cart['maxTime'] != null &&
                                                cart['item'][t2]['time'] !=
                                                  null &&
                                                cart['maxTime'] <
                                                  cart['item'][t2]['time']
                                              ) {
                                                cart['maxTime'] =
                                                  cart['item'][t2]['time'];
                                              }
                                            }
                                          }
                                        }
                                        break;
                                      }
                                    }
                                    this.setState({ cart: cart });
                                  }}
                                  />
                                </Button>
                                <Button disabled style={{ minWidth: '30px', border: '0', }}>
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                      color: '#fff',
                                    }}
                                  >
                                    {findTotal3(image['id'])}
                                  </Typography>
                                </Button>
                                <Button
                                    style={{
                                      borderRadius: '0 50px 50px 0',
                                      minWidth: '28px',
                                      border: '0',
                                    }}
                                  >
                                    <AddRoundedIcon
                                      sx={{ color: 'action.active' }}
                                      style={{
                                        fontSize: '17px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                      }}
                                  onClick={() => {
                                    var cart = JSON.parse(
                                      JSON.stringify(this.state.cart)
                                    );
                                    if (
                                      image['customize'] != null &&
                                      image['customize'].length > 0
                                    ) {
                                      handleClickOpenVariable(image);
                                    } else {
                                      // setState(() {
                                      for (
                                        var t1 = cart['item'].length - 1;
                                        t1 >= 0;
                                        t1--
                                      ) {
                                        if (
                                          cart['item'][t1]['id'] ===
                                          image['id']
                                        ) {
                                          cart['item'][t1]['quantity']++;
                                          cart['totalCost'] =
                                            cart['totalCost'] +
                                                Number(((this.state.businessData
                                              .partner != null &&
                                            this.state.businessData.partner
                                              ? image[
                                                  'discountPercentage'
                                                ] != null &&
                                                image[
                                                  'discountPercentage'
                                                ] > 0 &&
                                                moment().isBefore(
                                                  moment(
                                                    image[
                                                      'discountedTill'
                                                    ].toDate()
                                                  )
                                                )
                                                ? image['price'] -
                                                  (image['price'] *
                                                    image[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : image['price']
                                              : (image[
                                                  'discountPercentage'
                                                ] != null &&
                                                image[
                                                  'discountPercentage'
                                                ] > 0 &&
                                                moment().isBefore(
                                                  moment(
                                                    image[
                                                      'discountedTill'
                                                    ].toDate()
                                                  )
                                                )
                                                  ? image['price'] -
                                                    (image['price'] *
                                                      image[
                                                        'discountPercentage'
                                                      ]) /
                                                      100
                                                  : image['price']) +
                                                (image[
                                                  'discountPercentage'
                                                ] != null &&
                                                image[
                                                  'discountPercentage'
                                                ] > 0 &&
                                                moment().isBefore(
                                                  moment(
                                                    image[
                                                      'discountedTill'
                                                    ].toDate()
                                                  )
                                                )
                                                  ? image['price'] -
                                                    (image['price'] *
                                                      image[
                                                        'discountPercentage'
                                                      ]) /
                                                      100
                                                  : image['price']) *
                                                  (this.state.businessData
                                                    .tax /
                                                        100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                          cart['totalItems']++;
                                          break;
                                        }
                                      }
                                      // });
                                    }
                                    this.setState({ cart: cart });
                                  }}
                                  />
                                </Button>
                              </ButtonGroup>
                            </div>
                          ) : null}
                        </GridListTile>
                      ))}
                    </GridList>
                    </div>
                  </Element>
                )):(!this.state.loading)?
                <div style={{width: "100%", height: "100%", display: 'flex', alignItems:"center", justifyContent: "center", padding: "10px"}}>
                  <Stack

                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}

                  >
                  <img
                    style={{ width: this.state.width < 1000 ? '50vw' : '17.5vw' }}
                    src={strings.language === 'es'?closed_spanish:closed_english}
                    alt="loading"
          />
                      <Typography
                        style={{
                          fontSize: '18.2px',
                          fontWeight: '500',
                          overflow: 'visible',
                          fontFamily: 'Nsunito',
                          textTransform: 'none',
                        }}
                      >
                        {strings["Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora"]}
                      </Typography>

                      <>{(!this.state.isPreOrder)?
                      <Button
                        style={{
                          margin: '1px',
                          backgroundColor: getPrimaryColor(),
                          textTransform: 'none',
                          borderRight: '0px solid #cccccc',
                          padding: '6px 15px 6px 15px',
                          fontSize: '15px',
                          borderRadius: '12px',
                          margin:"0px 8px 0px"
                        }}
                        variant="text"
                        startIcon={
                          <AccessTimeIcon style={{ color: '#fff' }} />
                        }
                        endIcon={
                          <ArrowDropDownIcon style={{ color: '#fff' }} />
                        }
                        onClick={handlePreOrderOpen}
                      >
                        <>

                            <span
                              style={{ color: '#fff', fontWeight: 'bold' }}
                            >
                              {strings["Progamar pedido"]}
                            </span>
                           {' '}
                        </>
                      </Button>: <Button
                        style={{
                          margin: '1px',
                          backgroundColor: '#fff',
                          textTransform: 'none',
                          borderRight: '0px solid #cccccc',
                          padding: '6px 15px 6px 15px',
                          fontSize: '15px',
                          borderRadius: '12px',
                          backgroundColor: getPrimaryColor()
                        }}
                        variant="filled"
                        startIcon={
                          <AccessTimeIcon style={{ color: '#fff' }} />
                        }
                        endIcon={
                          <CancelIcon style={{ color: '#fff' }} />
                        }
                        onClick={()=>{
                          this.setState(
                              { businessData: null },
                              (newState) => {
                                var weekDay = [6,0,1,2,3,4,5];
                      var currentDay = weekDay[new Date().getDay()];
                      const { id } = this.props.match.params;
                      this.setState({ selectedDate: new Date(), selectedWeekDay: currentDay, busId: id,isTomorrow: false,  isPreOrder: false },async (newData)=> {
                      await this.getCities(id);
                      });

                                handlePreOrderClose();
                                // this.setState({ businessData: {} });
                                //this.getCities(id);
                              });

                        }}
                      >
                        <>

                            <span
                              style={{ color: '#fff', fontWeight: 'bold' }}
                            >
                              {((!this.state.isTomorrow && this.state.isToday)
                                                      ? strings[' Hoy, ']
                                                      : (this.state.isTomorrow && !this.state.isToday)
                                                      ? strings[' Mañana, ']
                                                      : moment(this.state.selectedDate).format('DD-MM-YYYY').toString()+ ',  ')  + moment(this.state.selectedDate).format('kk:mm').toString()
                                                  }
                            </span>
                           {' '}
                        </>
                      </Button>
                      }</>

                      </Stack>
                </div>:null
                }

                {/* <ListItem autoFocus button onClick={() => console.log('Hello')}>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add account" />
              </ListItem> */}
              </List>{' '}
              <div
                style={{
                  padding:
                    this.state.width > 600 ? '125px 0px 0px' : '165px 0px 0px',
                }}
              ></div>
            </div>
          </div>
        ) : null}

        {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
        <Backdrop
          onClick={() => {}}
          style={{ color: '#fff', zIndex: 9999999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CartDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showCart}
          onClose={() => {
            this.setState({ showCart: false });
          }}
          title={this.state.width}
          displayList={
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings['Tu pedido']}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showCart: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <>
                    {' '}
                    {this.state.selectedType === 0 ? (
                      <div style={{ padding: '8px' }}>
                        <Stack direction={'column'} spacing={0}>
                          <div style={{ padding: '8px' }}>
                            <Stack direction={'row'} spacing={2}>
                              <DirectionsBikeIcon
                                style={{
                                  fontSize: '24px',
                                  fontWeight: 'bold',
                                  color: '#01a9e0',
                                }}
                              />
                              <Typography
                                sx={{ flexGrow: 1 }}
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#5E5E5D',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                {strings['Entregar a '] +
                                  this.state.selectedLocationName}
                              </Typography>
                            </Stack>
                          </div>
                          <div
                            style={{ padding: '0px 8px 0px', height: '100px' }}
                          >
                            <Stack direction={'row'} spacing={1}>
                              <GoogleMapReact
                                defaultCenter={{
                                  lat: this.state.selectedLocation.latitude,
                                  lng: this.state.selectedLocation.longitude,
                                }}
                                defaultZoom={this.state.defaultProps.zoom}
                                resetBoundsOnResize={true}
                                options={{
                                  fullscreenControl: false,
                                  scrollwheel: false,
                                  panControl: false,
                                  zoomControl: false,
                                  rotateControl: false,
                                  draggable: false,
                                }}
                                bootstrapURLKeys={{
                                  key: constants.googleMapsAPI,
                                }}
                                style={{
                                  height: '100px',
                                  padding: '8px',
                                  borderRadius: this.state.width < 600 ? '18px' : '12px',
                                  width: this.state.width < 600 ? '100px' : '150px',
                                  border: this.state.width < 600 ? '2px solid #ffffff' : '0',
                                  boxShadow: this.state.width < 600 ? '0 4px 8px #46545d0a' : 'none',
                                  overflow: 'hidden',
                                }}
                                onChange={(changeData) => {
                                  // console.log(changeData);
                                  // this.setState({
                                  //   defaultProps: {
                                  //     center: {
                                  //       lat: changeData.center.lat,
                                  //       lng: changeData.center.lng,
                                  //     },
                                  //     zoom: changeData.zoom,
                                  //   },
                                  //   selectLocationData: {
                                  //     id: this.state.selectLocationData.id,
                                  //     name: this.state.selectLocationData.name,
                                  //     address: this.state.selectLocationData.address,
                                  //     geoAddress: this.state.selectLocationData.geoAddress,
                                  //     addressNotes:
                                  //       this.state.selectLocationData.addressNotes,
                                  //     latitude: changeData.center.lat,
                                  //     longitude: changeData.center.lng,
                                  //   },
                                  // });
                                }}
                              >
                                {/* <div
        style={{  display: "flex",
  width: "50px",
  opacity: "0.5",
  height: "50px",
  backgroundColor: "green",
  borderRadius: "50%"}}
        lat={this.state.defaultCircleProps.center.lat}
          lng={this.state.defaultCircleProps.center.lng}
      /> */}
                                <LocationOnIcon
                                  style={{
                                    color: getPrimaryColor(),
                                    transform: 'translate(-50%, -100%)',
                                  }}
                                  fontSize={'large'}
                                  lat={this.state.selectedLocation.latitude}
                                  lng={this.state.selectedLocation.longitude}
                                  // text={'Google Map'}
                                />
                              </GoogleMapReact>
                              <Stack direction={'column'} spacing={1}>
                                <Typography
                                  sx={{ flexGrow: 1 }}
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#3f464a',
                                    padding: '8px',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {this.state.selectedLocationData}
                                </Typography>
                                <Typography
                                  sx={{ flexGrow: 1 }}
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: 'slategray',
                                    padding: '0 8px',
                                    marginTop: '0',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {this.state.selectedLocationNotes}
                                </Typography>
                              </Stack>
                            </Stack>
                          </div>
                          <div
                            style={{
                              padding: '15px 6px 5px',
                              borderRadius: '10px',
                            }}
                          >
                            <TextField
                              style={{
                                color: constants.secondaryColor,
                                borderRadius: '0',
                                backgroundColor: '#e6ebf1',
                                overflow: 'hidden',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                              fullWidth
                              id="filled-basic-34"
                              label={strings["Agregar instrucciones de entrega"]}
                              variant="filled"
                              value={this.state.deliveryInstructions}
                              onChange={(event) => {
                                this.setState({
                                  deliveryInstructions: event.target.value,
                                });
                              }}
                            />
                          </div>
                        </Stack>
                      </div>
                    ) : null}
                  </>
                  <>
                    {' '}
                    {this.state.selectedType === 1 ? (
                      <div style={{ padding: '8px' }}>
                        <Stack direction={'column'} spacing={0}>
                          <div style={{ padding: '8px' }}>
                            <Stack direction={'row'} spacing={2}>
                              <DirectionsRunIcon
                                style={{
                                  fontSize: '24px',
                                  fontWeight: 'bold',
                                  color: getPrimaryColor(),
                                }}
                              />
                              <Typography
                                sx={{ flexGrow: 1 }}
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#5E5E5D',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                {strings['Para recoger tu pedido debes dirigirte a']}
                              </Typography>
                            </Stack>
                          </div>
                          <div
                            style={{ padding: '0px 8px 0px', height: '100px' }}
                          >
                            <Stack direction={'row'} spacing={1}>
                              <GoogleMapReact
                                defaultCenter={{
                                  lat: this.state.businessData.location
                                    .latitude,
                                  lng: this.state.businessData.location
                                    .longitude,
                                }}
                                defaultZoom={this.state.defaultProps.zoom}
                                resetBoundsOnResize={true}
                                options={{
                                  fullscreenControl: false,
                                  scrollwheel: false,
                                  panControl: false,
                                  zoomControl: false,
                                  rotateControl: false,
                                  draggable: false,
                                }}
                                bootstrapURLKeys={{
                                  key: constants.googleMapsAPI,
                                }}
                                style={{
                                  height: '100px',
                                  padding: '8px',
                                  borderRadius: '12px',
                                  width: '150px',
                                  overflow: 'hidden',
                                }}
                                onChange={(changeData) => {
                                }}
                              >
                                <LocationOnIcon
                                  style={{
                                    color: getPrimaryColor(),
                                    transform: 'translate(-50%, -100%)',
                                  }}
                                  fontSize={'large'}
                                  lat={
                                    this.state.businessData.location.latitude
                                  }
                                  lng={
                                    this.state.businessData.location.longitude
                                  }
                                />
                              </GoogleMapReact>
                              <div
                                style={{
                                  padding: '8px',
                                }}
                              >
                                <Stack
                                  direction={'column'}
                                  spacing={1}
                                  sx={{ flexGrow: 1 }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'normal',
                                      color: '#000',
                                      fontFamily: 'Nunito',
                                      letterSpacing: 'normal',
                                    }}
                                  >
                                    {this.state.businessData.location.address}
                                  </Typography>
                                  <Typography
                                    onClick={() => {
                                      window.open(
                                        'https://www.google.com/maps/search/?api=1&query=' +
                                          this.state.businessData.location.latitude.toString() +
                                          '%2C' +
                                          this.state.businessData.location.longitude.toString(),
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                      color: '#4BCF90',
                                      cursor: 'pointer',
                                      fontFamily: 'Nunito',
                                      letterSpacing: 'normal',
                                    }}
                                  >
                                    {strings['Ver el mapa']}
                                  </Typography>
                                </Stack>
                              </div>
                            </Stack>
                          </div>
                          {/* <TextField fullWidth id="filled-basic-34" label="Add delivery instructions" variant="filled"
                style={{padding: "15px 5px 5px", borderRadius: "10px"}}
                value={this.state.deliveryInstructions}
                onChange={(event) => {
                  this.setState({ deliveryInstructions: event.target.value });
                }}
                /> */}
                        </Stack>
                      </div>
                    ) : null}
                  </>
                        <Divider variant="middle" />
                  {/* <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}> */}
                  {/* <DirectionsBikeIcon
                          style={{
                      fontSize: '26.4px',
                      fontWeight: 'bold',
                      color: '#000',
                          }}
                  /> */}
                  <div style={{ padding: '10px 20px 0px 20px' }}>
                    <List>
                      <Stack direction={'column'} spacing={0}>
                        {this.state.cart.item.map((items, index) => (
                          <Stack direction={'column'} spacing={0} key={index}>
                            <Stack
                              direction={'row'}
                              spacing={0}
                              style={{
                                borderBottom: '1px solid #e6ebf1',
                                paddingBottom: '8px',
                                marginBottom: '15px',
                              }}
                            >
                              <Stack
                                sx={{ flexGrow: 1 }}
                                direction={'column'}
                                spacing={0}
                              >
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#000',
                                    paddingTop: '6px',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {items['nameEng'] && strings.language === 'en' ? items['nameEng']:items['name']}
                                </Typography>
                                <div style={{ padding: '5px 0px 0px' }}></div>
                                <List
                                // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                >
                                  {items['customize'].map((customize, t) => (
                                    <React.Fragment key={t}>
                                      {' '}
                                      {customize['options'].length > 0 ? (
                                        <Typography
                                          style={{
                                            fontSize: '13px',
                                            fontWeight: '500',
                                            color: '#6a696e',
                                            fontFamily: 'Nunito',
                                            letterSpacing: 'normal',
                                          }}
                                        >
                                          {findTotal(index, t)}
                                        </Typography>
                                      ) : null}
                                    </React.Fragment>
                                  ))}
                                </List>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '800',
                                    color: '#2e2c36',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  $
                                  {(items['quantity'] * items['price']).toFixed(
                                    2
                                  )}
                                </Typography>
                              </Stack>
                              <div style={{ padding: '0px 5px 0px' }}>
                                <ButtonGroup
                                  variant="text"
                                  disableElevation
                                  size={'small'}
                                  style={{
                                    backgroundColor: getPrimaryColor(),
                                    borderRadius: '50px',
                                  }}
                                >
                                  <Button
                                    style={{
                                      borderRadius: '50px 0 0 50px',
                                      minWidth: '28px',
                                      border: '0',
                                    }}
                                  >
                                    <RemoveRoundedIcon
                                      sx={{ color: 'action.active' }}
                                      style={{
                                        fontSize: '17px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                      }}
                                      onClick={() => {
                                        var cart = JSON.parse(
                                          JSON.stringify(this.state.cart)
                                        );
                                        if (
                                          cart['item'][index]['quantity'] > 1
                                        ) {
                                          cart['totalCost'] =
                                            cart['totalCost'] -
                                            cart['item'][index]['price'];
                                          cart['totalItems']--;
                                          cart['item'][index]['quantity']--;
                                          // boxCart.put(
                                          //     'cart', cart);
                                        } else {
                                          cart['totalCost'] =
                                            cart['totalCost'] -
                                            cart['item'][index]['price'];
                                          cart['totalItems']--;
                                          cart['item'].splice(index, 1);
                                          // boxCart.put(
                                          //     'cart', cart);
                                        }
                                        // checkOffer();
                                        if (cart['item'].length === 0) {
                                          this.setState({ showCart: false });
                                        }
                                        this.setState({ cart: cart },
                                          (newState) => {
                                            checkOffer();
                                          });
                                      }}
                                    />
                                  </Button>
                                  <Button disabled style={{ minWidth: '30px', border: '0', }}>
                                    <Typography
                                      style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: '#fff',
                                      }}
                                    >
                                      {this.state.cart['item'][index][
                                        'quantity'
                                      ].toString()}
                                    </Typography>
                                  </Button>
                                  <Button
                                    style={{
                                      borderRadius: '0 50px 50px 0',
                                      minWidth: '28px',
                                      border: '0',
                                    }}
                                  >
                                    <AddRoundedIcon
                                      sx={{ color: 'action.active' }}
                                      style={{
                                        fontSize: '17px',
                                        fontWeight: '800',
                                        textAlign: 'center',
                                        color: '#fff',
                                        fontFamily: 'Nunito',
                                        letterSpacing: 'normal',
                                      }}
                                      onClick={() => {
                                        var cart = JSON.parse(
                                          JSON.stringify(this.state.cart)
                                        );
                                        cart['item'][index]['quantity']++;
                                        cart['totalCost'] =
                                          cart['totalCost'] +
                                          cart['item'][index]['price'];
                                        cart['totalItems']++;
                                        // checkOffer();
                                        this.setState({ cart: cart },
                                          (newState) => {
                                            checkOffer();
                                          });
                                      }}
                                    />
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </Stack>
                          </Stack>
                        ))}
                        {(this.state.selectedOffer!=null && this.state.selectedOffer['id']!=null && this.state.isOfferApplied && this.state.selectedOffer['isFreeItem']!=null && this.state.selectedOffer['isFreeItem'])?
                        <Stack direction={'column'} spacing={0} key={this.state.cart.length}>
                            <Stack
                              direction={'row'}
                              spacing={0}
                              style={{
                                borderBottom: '1px solid #e6ebf1',
                                paddingBottom: '8px',
                                marginBottom: '15px',
                              }}
                            >
                              <Stack
                                sx={{ flexGrow: 1 }}
                                direction={'column'}
                                spacing={0}
                              >
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#000',
                                    paddingTop: '6px',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {this.state.selectedOffer['freeItems'][0]['name']}
                                </Typography>
                                <div style={{ padding: '5px 0px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '800',
                                    color: '#2e2c36',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings['Gratis']}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>:null}
                      </Stack>
                    </List>
                  </div>
                  {(this.upsellingProdList().length>0)?<Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: '800',
                          color: '#2c3136',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "0px 15px 10px 15px",
                        }}
                      >
                          {strings['¿Un último antojo?']}
                      </Typography>:null}
                  {(this.upsellingProdList().length>0)?<Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    // className="example"
                    spacing={1}
                    style={{
                      margin: '7px 10px 15px 10px',
                      display: 'flex',
                      flexShrink: 0,
                      overflowX: 'auto',
                      position: 'sticky',
                      backgroundColor: '#f8f9fb',
                      borderTop: '0px solid rgb(230, 235, 241)',
                      borderBottom: '0px solid rgb(230, 235, 241)',
                      top: 0,
                      background: 'white',
                    }}
                  >
                    {/* <div   > */}
                    {/* <ul style={{ listStyle: 'none' }}> */}
                    {this.upsellingProdList().map((item, index) => (
                      <div style={{
                        height: '150px',
              width: '110px',
              paddingLeft: "20px"
                      }}>
                        <div
                        style={{cursor: "pointer"}}
                        onClick={() => {
                                    if (
                                      item['customize'] != null &&
                                      item['customize'].length > 0
                                    ) {
                                      handleClickOpenVariable(item);
                                    } else {
                                      var cart = JSON.parse(
                                        JSON.stringify(this.state.cart)
                                      );
                                      // setState(() {
                                      cart['item'].push({
                                        name: item['name'],
                                        nameEng: item['nameEng'] && strings.language === 'en' ? item['nameEng']: item['name'],
                                        id: item['id'],
                                        perKg: item['perKg'] ?? false,
                                        riesgoDeDerrame: item['riesgoDeDerrame'] ?? false,
                                        extraDelivery: item['extraDelivery'] ?? false,
                                        originalPrice:
                                          this.state.businessData.partner !=
                                            null &&
                                          this.state.businessData.partner
                                            ? item['discountPercentage'] !=
                                                null &&
                                              item['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  item[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                              ? item['price'] -
                                                (item['price'] *
                                                  item['discountPercentage']) /
                                                  100
                                              : item['price']
                                            : (item['discountPercentage'] !=
                                                null &&
                                              item['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  item[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                                ? item['price'] -
                                                  (item['price'] *
                                                    item[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : item['price']) +
                                              (item['discountPercentage'] !=
                                                null &&
                                              item['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  item[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                                ? item['price'] -
                                                  (item['price'] *
                                                    item[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : item['price']) *
                                                (this.state.businessData.tax /
                                                  100),
                                        price:
                                          Number(((this.state.businessData.partner !=
                                            null &&
                                          this.state.businessData.partner
                                            ? item['discountPercentage'] !=
                                                null &&
                                              item['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  item[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                              ? item['price'] -
                                                (item['price'] *
                                                  item['discountPercentage']) /
                                                  100
                                              : item['price']
                                            : (item['discountPercentage'] !=
                                                null &&
                                              item['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  item[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                                ? item['price'] -
                                                  (item['price'] *
                                                    item[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : item['price']) +
                                              (item['discountPercentage'] !=
                                                null &&
                                              item['discountPercentage'] > 0 &&
                                              moment().isBefore(
                                                moment(
                                                  item[
                                                    'discountedTill'
                                                  ].toDate()
                                                )
                                              )
                                                ? item['price'] -
                                                  (item['price'] *
                                                    item[
                                                      'discountPercentage'
                                                    ]) /
                                                    100
                                                : item['price']) *
                                                (this.state.businessData.tax /
                                                  100))*multiFactor()).toFixed(multiFactor()>1?0:2)),
                                        quantity: 1,
                                        addons: 0,
                                        customize: [],
                                      });
                                      cart['totalCost'] =
                                        cart['totalCost'] +
                                        Number(((this.state.businessData.partner !=
                                          null &&
                                        this.state.businessData.partner
                                          ? item['discountPercentage'] !=
                                              null &&
                                            item['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                item['discountedTill'].toDate()
                                              )
                                            )
                                            ? item['price'] -
                                              (item['price'] *
                                                item['discountPercentage']) /
                                                100
                                            : item['price']
                                          : (item['discountPercentage'] !=
                                              null &&
                                            item['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                item['discountedTill'].toDate()
                                              )
                                            )
                                              ? item['price'] -
                                                (item['price'] *
                                                  item['discountPercentage']) /
                                                  100
                                              : item['price']) +
                                            (item['discountPercentage'] !=
                                              null &&
                                            item['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                item['discountedTill'].toDate()
                                              )
                                            )
                                              ? item['price'] -
                                                (item['price'] *
                                                  item['discountPercentage']) /
                                                  100
                                              : item['price']) *
                                              (this.state.businessData.tax /
                                                100))*multiFactor()).toFixed(multiFactor()>1?0:2));
                                      cart['totalItems']++;
                                      if (
                                        item['averageTime'] != null &&
                                        cart['maxTime'] < item['averageTime']
                                      ) {
                                        cart['maxTime'] = item['averageTime'];
                                      }
                                      this.setState({ cart: cart });
                                      // });
                                    }
                                  }}>
                        <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    // className="example"
                    spacing={0}
                  >
                    {item.images ? (
                            <img
                              src={item.images}
                              style={{
                                borderRadius: '10px',
                                width: '90px',
                                height: '90px',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <img
                              src={"https://firebasestorage.googleapis.com/v0/b/traitte-app.appspot.com/o/images%2Fstore1.png?alt=media&token=2b50322f-1a9c-4c00-a6be-0c46a11f79ff"}
                              style={{
                                borderRadius: '10px',
                                width: '90px',
                                height: '90px',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                          <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#0c0c0c',
                          width: '90px',
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "5px 0px 0 0px",
                        }}
                      >
                          {item['nameEng'] && strings.language === 'en' ? item['nameEng']: item['name']}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#0c0c0c',
                          width: '90px',
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "5px 0px 0 0px",
                        }}
                      >
                          $
                                        {((this.state.businessData.partner !=
                                          null &&
                                        this.state.businessData.partner
                                          ? item['discountPercentage'] !=
                                              null &&
                                              item['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                item['discountedTill'].toDate()
                                              )
                                            )
                                            ? item['price'] -
                                              (item['price'] *
                                              item['discountPercentage']) /
                                                100
                                            : item['price']
                                          : (item['discountPercentage'] !=
                                              null &&
                                              item['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                item['discountedTill'].toDate()
                                              )
                                            )
                                              ? item['price'] -
                                                (item['price'] *
                                                item['discountPercentage']) /
                                                  100
                                              : item['price']) +
                                            (item['discountPercentage'] !=
                                              null &&
                                              item['discountPercentage'] > 0 &&
                                            moment().isBefore(
                                              moment(
                                                item['discountedTill'].toDate()
                                              )
                                            )
                                              ? item['price'] -
                                                (item['price'] *
                                                item['discountPercentage']) /
                                                  100
                                              : item['price']) *
                                              (this.state.businessData.tax /
                                                100)
                                        )*multiFactor()).toFixed(multiFactor()>1?0:2)}
                      </Typography>
                  </Stack>
                        </div>
                      </div>
                      // <li key={item.id}>
                      // <Typography
                      //   noWrap
                      //   className="noselect"
                      //   style={{
                      //     color: '#2e2c36',
                      //     textTransform: 'none',
                      //     fontSize: '19px',
                      //     textAlign: 'left',
                      //     padding: '6px 8px',
                      //     marginRight: '5px',
                      //     overflow: 'visible',
                      //     borderRadius: '10px',
                      //     border: '0px solid rgb(230, 235, 241)',
                      //     fontWeight: '800',
                      //   }}
                      //   onClick={() => handleClick(item.id)}
                      //   key={index}
                      // >
                      //   <ScrollLink
                      //     activeClass="active-class"
                      //     color={getPrimaryColor()}
                      //     to={`cat-name-${item.id}`}
                      //     containerId={'scroll-container'}
                      //     id={`cat-name-${item.id}`}
                      //     spy={true}
                      //     smooth={true}
                      //     offset={-50}
                      //     duration={500}
                      //     onSetActive={this.handleSetActive}
                      //   >
                      //     {item['nameEng'] && strings.language === 'en' ? item['nameEng']:item.name}
                      //   </ScrollLink>
                      // </Typography>

                      // </li>
                    ))}
                    {/* {this.state.menu.map((item) => (
                      // <li key={item.id}>
                      <Typography
                        noWrap
                        // variant="contained"
                        // // endIcon={<ArrowForwardIosIcon style={{height: "10px",position: 'relative', top: '1px'}} />}
                        // disableElevation
                        // align="center"
                        className='noselect'
                        style={{
                          // backgroundColor: '#fff',
                          color: '#2e2c36',
                          textTransform: 'none',
                          fontSize: '15px',
                          textAlign: 'left',
                          padding: '6px 5px',
                          marginRight: '5px',
                          overflow: 'visible',
                          borderRadius: '8px',
                          border: '1px solid rgb(230, 235, 241)',
                          fontFamily: 'Nunito',
                        }}
                        onClick={() => handleClick(item.id)}
                      >
                        {item.name}
                      </Typography>
                      // </li>
                    ))} */}
                    {/* </div> */}
                    {/* </ul> */}
                  </Stack>: null}
                  {(this.upsellingProdList().length>0)?<Divider variant="middle" />:null}
                  {/* <Typography
                    style={{
                      fontSize: '26.4px',
                      fontWeight: 'bold',
                      color: '#000',
                    }}
                  >
                    {"Deliver to Home"}
                  </Typography>
                </Stack> */}
                  <>
                    {this.state.businessData.spoon ? (
                      <div style={{ padding: '5px 15px 5px' }}>
                        <div>
                          <Stack
                            direction={'row'}
                            spacing={0}
                            style={{
                              borderRadius: '10px',
                              padding: '5px 8px 5px',
                              backgroundColor: '#f8f9fb',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <RestaurantIcon
                              style={{
                                fontSize: '20px',
                                fontWeight: 'normal',
                                color: 'slategray',
                              }}
                            />
                            <div style={{ padding: '0px 5px 0px' }}></div>
                            <Typography
                              sx={{ flexGrow: 1 }}
                              style={{
                                fontSize: '15px',
                                fontWeight: '500',
                                color: 'slategray',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {this.state.spoon
                                ? strings['Sí enviar cubiertos']
                                : strings['No envíar cubiertos']}
                            </Typography>
                            <Switch
                              checked={this.state.spoon}
                              onChange={() => {
                                this.setState({ spoon: !this.state.spoon });
                              }}
                              inputProps={{ 'aria-label': 'switch' }}
                            />
                          </Stack>
                        </div>
                      </div>
                    ) : null}
                  </>
                  <div style={{ padding: '10px 0px 0px' }}></div>
                  <div
                    style={{ padding: '5px 15px 5px', borderRadius: '10px' }}
                  >
                    <TextField
                      style={{
                        color: constants.secondaryColor,
                        borderRadius: '0',
                        backgroundColor: '#e6ebf1',
                        overflow: 'hidden',
                        fontFamily: 'Nunito',
                        letterSpacing: 'normal',
                      }}
                      fullWidth
                      id="filled-basic-35"
                      label={strings["Agregar instrucciones de preparación"]}
                      variant="filled"
                      value={this.state.cookingInstructions}
                      onChange={(event) => {
                        this.setState({
                          cookingInstructions: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <>
                  {
                    (this.state.userDetails && this.state.showAllergyData && (this.state.allergy==null || this.state.allergy.length === 0))?
                    <div style={{padding: "10px 0px 0px 0px", width: "100%",}}>
                      <Divider variant="middle" />
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: '800',
                          color: '#2c3136',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "10px 15px 0 15px",
                        }}
                      >
                        {strings['ckeckout_allergy_tittle']}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '15px',
                          fontWeight: '400',
                          color: 'slategray',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "0px 15px 0 15px",
                        }}
                      >
                        {strings['ckeckout_allergy_descr']}
                      </Typography>
                      <div style={{padding: "0 15px 15px 15px", width: "100%", display: 'flex',}}>
                        <IconButton
                          style={{
                            fontSize: '16px',
                            color: '#2c3136',
                            fontWeight: '400',
                            textAlign:"center",
                            fontFamily: 'Nunito',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '12px',
                            marginRight: '5px',
                          }}
                          onClick={() => {
                            handleMenuClose();
                            handleAllergyOpen();
                          }}>
                          <EditAttributesIcon style={{ height: '33px',width: '33px', color: '#28273c',}}/>
                          <Typography style={{ fontSize: '15px',fontWeight: '400',color: 'slategray', marginTop: '-8px',}}>{strings["edit_allergy"]}</Typography>
                        </IconButton>
                        <div style={{padding: "5px", backgroundColor: "#f8f9fb", borderRadius: "10px", width: "100%", cursor: "pointer"}}
                        onClick={
                          ()=>{
                            this.setState({
                              allergyActivate: true,
                            });
                          }
                        }
                        >
                      <Typography
                        style={{
                          fontSize: '16px',
                          color: '#2c3136',
                          fontWeight: '400',
                          textAlign:"center",
                          fontFamily: 'Nunito',
                        }}>
                        {strings["Alergias inactivas"]}
                      </Typography>
                      </div>
                      </div>
                      <Divider variant="middle" />
                    </div>:null
                  }
                  </>
                  <>
                  {
                    (this.state.userDetails && this.state.showAllergyData && !this.state.allergyActivate && (this.state.allergy && this.state.allergy.length > 0))?
                    <div style={{padding: "10px 0px 0px 0px", width: "100%",}}>
                      <Divider variant="middle" />
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: '800',
                          color: '#2c3136',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "10px 15px 0 15px",
                        }}
                      >
                        {strings['ckeckout_allergy_tittle']}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '15px',
                          fontWeight: '400',
                          color: 'slategray',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "0px 15px 0 15px",
                        }}
                      >
                        {strings['ckeckout_allergy_descr']}
                      </Typography>
                      <div style={{padding: "0 15px 15px 15px", width: "100%", display: 'flex',}}>
                        <IconButton
                          style={{
                            fontSize: '16px',
                            color: '#2c3136',
                            fontWeight: '400',
                            textAlign:"center",
                            fontFamily: 'Nunito',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '12px',
                            marginRight: '5px',
                          }}
                          onClick={() => {
                            handleMenuClose();
                            handleAllergyOpen();
                          }}>
                          <EditAttributesIcon style={{ height: '33px',width: '33px', color: '#28273c',}}/>
                          <Typography style={{ fontSize: '15px',fontWeight: '400',color: 'slategray', marginTop: '-8px',}}>{strings["edit_allergy"]}</Typography>
                        </IconButton>
                        <div style={{padding: "5px", backgroundColor: "#f8f9fb", borderRadius: "10px", width: "100%", cursor: "pointer"}}
                        onClick={
                          ()=>{
                            this.setState({
                              allergyActivate: true,
                            });
                          }
                        }
                        >
                        <Typography
                          style={{
                            fontSize: '16px',
                            color: '#2c3136',
                            fontWeight: '400',
                            textAlign:"center",
                            fontFamily: 'Nunito',
                          }}>
                          {strings["Alergias inactivas"]}
                        </Typography>
                        </div>
                      </div>
                      <Divider variant="middle" />
                    </div>:null
                  }
                  </>
                  <>
                  {
                    (this.state.userDetails && this.state.showAllergyData && this.state.allergyActivate && (this.state.allergy && this.state.allergy.length > 0))?
                    <div style={{padding: "10px 0px 0px 0px", width: "100%",}}>
                      <Divider variant="middle" />
                      <Typography
                        style={{
                          fontSize: '18px',
                          fontWeight: '800',
                          color: '#2c3136',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "10px 15px 0 15px",
                        }}
                      >
                        {strings['ckeckout_allergy_tittle']}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '15px',
                          fontWeight: '400',
                          color: 'slategray',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                          padding: "0px 15px 0 15px",
                        }}
                      >
                        {strings['ckeckout_allergy_descr']}
                      </Typography>
                    <div style={{padding: "0 15px 15px 15px", width: "100%", display: 'flex',}}>
                      <IconButton
                        style={{
                          fontSize: '16px',
                          color: '#2c3136',
                          fontWeight: '400',
                          textAlign:"center",
                          fontFamily: 'Nunito',
                          display: 'flex',
                          flexDirection: 'column',
                          borderRadius: '12px',
                          marginRight: '5px',
                        }}
                        onClick={() => {
                          handleMenuClose();
                          handleAllergyOpen();
                        }}>
                        <EditAttributesIcon style={{ height: '33px',width: '33px', color: '#28273c',}}/>
                        <Typography style={{ fontSize: '15px',fontWeight: '400',color: 'slategray', marginTop: '-8px',}}>{strings["edit_allergy"]}</Typography>
                      </IconButton>
                        <div style={{padding: "5px", backgroundColor: getPrimaryColor(), borderRadius: "10px", width: "100%", cursor: "pointer"}}
                        onClick={
                          ()=>{
                            this.setState({
                              allergyActivate: false,
                            });
                          }
                        }
                        >
                          <div style={{padding: "0", width: "100%", display: 'flex',placeItems: 'center',}}>
                            <VerifiedUserIcon style={{ height: '33px',width: '33px', color: '#efefef',marginRight: '6px',}}/>
                            <Typography
                              style={{
                                fontSize: '16px',
                                color: '#fff',
                                fontWeight: '400',
                                textAlign:"left",
                                fontFamily: 'Nunito',
                                marginTop: '2px',
                              }}>
                              {strings["Alergias activadas"]}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <Divider variant="middle" />
                    </div>:null
                  }
                  </>
                  <>
                    {!this.state.isOfferError && !this.state.isOfferApplied ? (
                      <div
                        onClick={() => {
                          handleClickOpenDiscount();
                        }}
                        style={{ padding: '10px 15px 10px' }}
                      >
                        <Stack
                          direction={'row'}
                          spacing={0}
                          style={{
                            borderRadius: '10px',
                            padding: '5px 10px 5px',
                            backgroundColor: '#f8f9fb',
                            display: 'flex',
                            alignItems: 'center',
                            height: '49px',
                            cursor: 'pointer',
                          }}
                        >
                          <DiscountIcon
                            style={{
                              fontSize: '24px',
                              fontWeight: 'normal',
                              color: '#29d884',
                            }}
                          />
                          <div style={{ padding: '0px 5px 0px' }}></div>
                          <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '16.5px',
                              fontWeight: '700',
                              color: '#828282',
                              fontSize: '16px',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Selecciona un cupón de descuento']}
                          </Typography>
                        </Stack>
                      </div>
                    ) : this.state.isOfferError &&
                      !this.state.isOfferApplied ? (
                      <div style={{ padding: '10px 15px 10px' }}>
                        <Stack
                          direction={'row'}
                          spacing={0}
                          style={{
                            borderRadius: '10px',
                            padding: '5px 10px 5px',
                            backgroundColor: '#f8f9fb',
                            display: 'flex',
                            alignItems: 'center',
                            height: '49px',
                          }}
                        >
                          <DiscountIcon
                            style={{
                              fontSize: '24px',
                              fontWeight: 'normal',
                              color: '#EF5350',
                            }}
                          />
                          <div style={{ padding: '0px 5px 0px' }}></div>
                          <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '17.5px',
                              fontWeight: 'bold',
                              color: '#EF5350',
                            }}
                          >
                            {this.state.offerCode}
                          </Typography>
                          <Typography
                            onClick={() => {
                              this.setState(
                                {
                                  selectedOffer: null,
                                  offerCode: '',
                                  offerSuccess: '',
                                  offerErrorText: '',
                                  offerValue: 0,
                                  cashbackValue: 0.0,
                                  offerInd: null,
                                  isOfferApplied: false,
                                  isOfferError: false,
                                  isFreeDelivery: false,
                                  offerId: '',
                                },
                                (newState) => {
                                  checkOffer();
                                }
                              );
                            }}
                            style={{
                              fontSize: '16px',
                              fontWeight: 'normal',
                              color: 'lightslategray',
                              cursor: 'pointer',
                            }}
                          >
                            {strings['Borrar']}
                          </Typography>
                        </Stack>
                      </div>
                    ) : !this.state.isOfferError &&
                      this.state.isOfferApplied ? (
                      <div style={{ padding: '10px 15px 10px' }}>
                        <Stack
                          direction={'row'}
                          spacing={0}
                          style={{
                            borderRadius: '10px',
                            padding: '5px 10px 5px',
                            backgroundColor: '#f8f9fb',
                            display: 'flex',
                            alignItems: 'center',
                            height: '49px',
                          }}
                        >
                          <DiscountIcon
                            style={{
                              fontSize: '24px',
                              fontWeight: 'normal',
                              color: '#4bcf8e',
                            }}
                          />
                          <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '16px',
                              fontWeight: 'normal',
                              color: '#4bcf8e',
                            }}
                          >
                            {this.state.offerCode}
                          </Typography>
                          <Typography
                            onClick={() => {
                              this.setState(
                                {
                                  selectedOffer: null,
                                  offerCode: '',
                                  offerSuccess: '',
                                  offerErrorText: '',
                                  offerValue: 0,
                                  cashbackValue: 0.0,
                                  offerInd: null,
                                  isOfferApplied: false,
                                  isOfferError: false,
                                  isFreeDelivery: false,
                                  offerId: '',
                                },
                                (newState) => {
                                  checkOffer();
                                }
                              );
                            }}
                            style={{
                              fontSize: '16px',
                              fontWeight: 'normal',
                              color: 'lightslategray',
                              cursor: 'pointer',
                            }}
                          >
                            {strings['Borrar']}
                          </Typography>
                        </Stack>
                      </div>
                    ) : null}
                  </>
                  <>
                    {this.state.isOfferError && !this.state.isOfferApplied ? (
                      <Typography
                        style={{
                          padding: '0px 15px 0px',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          color: '#EF5350',
                        }}
                      >
                        {this.state.offerErrorText}
                      </Typography>
                    ) : null}
                  </>
                  <>
                    {!this.state.isOfferError && this.state.isOfferApplied ? (
                      <Typography
                        style={{
                          padding: '0px 15px 0px',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          color: '#4bcf8e',
                        }}
                      >
                        {this.state.offerSuccess}
                      </Typography>
                    ) : null}
                  </>
                  <Divider variant="middle" />

                  <div style={{ padding: '15px 15px 15px' }}>
                  <div style={{ padding: '15px 15px 15px',background: 'linear-gradient(87deg,'+getPrimaryColor()+'90,'+getPrimaryColor()+')', borderRadius: "15px" }}>
                    <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                    >
                      <img src={require('./assets/user-wallet.png')} style={{width: '25px', marginRight:"10px"}} />
                      <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '16.5px',
                              fontWeight: '700',
                              color: 'white',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Disponible:']+"$"+this.state.balance.toFixed(2)}
                          </Typography>
                          <div onClick={()=>{
                            handleWalletClickOpen()
                          }}
                          style={{borderRadius: "10px", backgroundColor: "#57658C", padding: "5px 15px", cursor: "pointer"}}>
                          <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '16.5px',
                              color: 'white',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Usar']}
                          </Typography>
                          </div>
                    </Stack>
                    </div>
                  </div>
                  <>
                    {this.state.selectedType === 0 ? (
                      <div style={{ padding: '15px 15px 15px' }}>
                        <Stack direction={'column'} spacing={0}>
                          <Typography
                            style={{
                              fontSize: '18px',
                              fontWeight: '800',
                              color: '#2c3136',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Agregar propina para el repartidor']}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: '15px',
                              fontWeight: '400',
                              color: 'slategray',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                              marginBottom: '10px',
                            }}
                          >
                            {strings['¡Las entregas solo son posible gracias a ellos!']}
                          </Typography>
                          <div style={{ padding: '5px 0px 0px' }}></div>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                            spacing={2}
                          >
                            <div
                              style={{
                                borderRadius: this.state.width < 600 ? '13px' : '10px',
                                padding: '5px 15px 5px',
                                border: '2px solid',
                                borderColor:
                                  this.state.tipPercent === 0
                                    ? getPrimaryColor()
                                    : '#e6ebf1',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.setState({ tipPercent: 0 });
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#2c3136',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                {'0%'}
                              </Typography>
                            </div>
                            <div
                              style={{
                                borderRadius: this.state.width < 600 ? '13px' : '10px',
                                padding: '5px 15px 5px',
                                border: '2px solid',
                                borderColor:
                                  this.state.tipPercent === 5
                                    ? getPrimaryColor()
                                    : '#e6ebf1',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                textTransform: 'none',
                                background: 'none !important',
                              }}
                              onClick={() => {
                                this.setState({ tipPercent: 5 });
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#2c3136',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                  textTransform: 'none',
                                  background: 'none !important',
                                }}
                              >
                                {'5%'}
                              </Typography>
                            </div>
                            <div
                              style={{
                                borderRadius: this.state.width < 600 ? '13px' : '10px',
                                padding: '5px 15px 5px',
                                border: '2px solid',
                                borderColor:
                                  this.state.tipPercent === 10
                                    ? getPrimaryColor()
                                    : '#e6ebf1',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.setState({ tipPercent: 10 });
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#2c3136',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                {'10%'}
                              </Typography>
                            </div>
                            <div
                              style={{
                                borderRadius: this.state.width < 600 ? '13px' : '10px',
                                padding: '5px 15px 5px',
                                border: '2px solid',
                                borderColor:
                                  this.state.tipPercent === 15
                                    ? getPrimaryColor()
                                    : '#e6ebf1',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.setState({ tipPercent: 15 });
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#2c3136',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                {'15%'}
                              </Typography>
                            </div>
                            <div
                              style={{
                                borderRadius: this.state.width < 600 ? '13px' : '10px',
                                padding: '5px 15px 5px',
                                border: '2px solid',
                                borderColor:
                                  this.state.tipPercent === 20
                                    ? getPrimaryColor()
                                    : '#e6ebf1',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                this.setState({ tipPercent: 20 });
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#2c3136',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'normal',
                                }}
                              >
                                {'20%'}
                              </Typography>
                            </div>
                          </Stack>
                        </Stack>
                      </div>
                    ) : null}
                  </>
                  <Divider variant="middle" />
                  <div style={{ padding: '15px 15px 0px' }}>
                    <Stack direction={'column'} spacing={0}>
                      <Stack
                        direction={'row'}
                        spacing={0}
                        justifyContent="space-between"
                      >
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#2c3136',
                            fontFamily: 'Nunito',
                            letterSpacing: 'normal',
                          }}
                        >
                          {strings['Todos los productos']}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#2c3136',
                            fontFamily: 'Nunito',
                            letterSpacing: 'normal',
                          }}
                        >
                          {'$' + this.state.cart.totalCost.toFixed(2)}
                        </Typography>
                      </Stack>
                      <div style={{ padding: '8px 0px 0px' }}></div>
                      <Stack
                        direction={'row'}
                        spacing={0}
                        justifyContent="space-between"
                      >
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#2c3136',
                            fontFamily: 'Nunito',
                            letterSpacing: 'normal',
                          }}
                        >
                          {strings['Tarifa de servicio']}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#2c3136',
                            fontFamily: 'Nunito',
                            letterSpacing: 'normal',
                          }}
                        >
                          {'$' +
                            (
                              (this.state.businessData.driverServiceFee
                                ? this.state.businessData.driverServiceFee
                                : 0) +
                              (this.state.cart['totalCost'] *
                                (this.state.businessData.driverServicePercentage
                                  ? this.state.businessData
                                      .driverServicePercentage
                                  : 0)) /
                                100
                            ).toFixed(2)}
                        </Typography>
                      </Stack>
                      <div style={{ padding: '8px 0px 0px' }}></div>
                      <>
                        {this.state.selectedType === 0 ? (
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#2c3136',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {strings['Costo de envío']}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#2c3136',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {'$' + (this.state.delivery + this.extraCharges()).toFixed(2)}
                            </Typography>
                          </Stack>
                        ) : null}
                      </>
                      <>
                        {this.state.selectedType === 0 ? (
                          <div style={{ padding: '8px 0px 0px' }}></div>
                        ) : null}
                      </>
                      <>
                        {this.state.dynamicDelivery &&
                        this.state.selectedType === 0 ? (
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                fontSize: '14px',
                                fontWeight: 'normal',
                                color: '#a5a5b5',
                              }}
                            >
                              {'(' + this.state.reasonDescription + ')'}
                            </Typography>
                          </Stack>
                        ) : null}
                      </>
                      <>
                        {this.state.dynamicDelivery &&
                        this.state.selectedType === 0 ? (
                          <div style={{ padding: '8px 0px 0px' }}></div>
                        ) : null}
                      </>
                      <>
                        {this.state.selectedType === 0 ? (
                          <div style={{ padding: '8px 0px 0px' }}></div>
                        ) : null}
                      </>
                      {
                        (this.state.usingWallet)?
                        <div style={{margin:"5px 0px",padding:"10px", width: "100%",background: 'linear-gradient(87deg,'+getPrimaryColor()+'90,'+getPrimaryColor()+')', borderRadius: "10px"}}
                        >
                          <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={0}
                    >
                      <img src={require('./assets/user-wallet.png')} style={{width: '25px', marginRight:"10px"}} />
                      <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '16.5px',
                              fontWeight: '700',
                              color: 'white',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Saldo utilizado: ']+"$"+this.state.offerValue2.toFixed(2)}
                          </Typography>
                          <div onClick={()=>{
                            this.setState({
                              usingWallet: false,
                              offerInd2: null,
                              offerCode2: '',
                              offerValue2: 0,
                              offerId2:'',
                              offerSuccess2:'',
                              isOfferApplied2:false,
                              isFreeDelivery2:false,
                              isOfferError2:false,
                            }, (newS)=>{
                              checkOffer();
                              handleWalletClose();
                            });
                          }}
                          style={{borderRadius: "10px", backgroundColor: "#57658C", padding: "5px 15px", cursor: "pointer"}}>
                          <Typography
                            sx={{ flexGrow: 1 }}
                            style={{
                              fontSize: '16.5px',
                              color: 'white',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Eliminar']}
                          </Typography>
                          </div>
                    </Stack>
                        </div>
                        :null
                      }
                    </Stack>
                  </div>
                  <div style={{ padding: '0px 25px 0px' }}>
                    <Stack direction={'column'} spacing={0}>
                      <>
                        {this.state.cashbackValue > 0 ? (
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                color: '#66BB6A',
                                fontSize: '16px',
                                fontWeight: '500',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {"Cashback"}
                            </Typography>
                            <Typography
                              style={{
                                color: '#66BB6A',
                                fontSize: '16px',
                                fontWeight: '500',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {'$' + this.state.cashbackValue.toFixed(2)}
                            </Typography>
                          </Stack>
                        ) : null}
                      </>
                      <>
                        {this.state.offerValue > 0 ? (
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        ) : null}
                      </>
                    </Stack>
                  </div>
                  <div style={{ padding: '0px 15px 0px' }}>
                    <Stack direction={'column'} spacing={0}>
                      <>
                        {this.state.offerValue > 0 ? (
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                color: '#66BB6A',
                                fontSize: '16px',
                                fontWeight: '500',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {strings['Descuento']}
                            </Typography>
                            <Typography
                              style={{
                                color: '#66BB6A',
                                fontSize: '16px',
                                fontWeight: '500',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {'-$' + this.state.offerValue.toFixed(2)}
                            </Typography>
                          </Stack>
                        ) : null}
                      </>
                      <>
                        {this.state.offerValue > 0 ? (
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        ) : null}
                      </>
                    </Stack>
                  </div>
                  <>
                    {calcTip() > 0 ? (
                      <div style={{ padding: '0px 15px 15px' }}>
                        <Stack
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: '500',
                              color: '#2c3136',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {strings['Propina']}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: '500',
                              color: '#2c3136',
                              fontFamily: 'Nunito',
                              letterSpacing: 'normal',
                            }}
                          >
                            {'$' + calcTip().toFixed(2)}
                          </Typography>
                        </Stack>
                      </div>
                    ) : null}
                  </>
                  <>
                    {!this.state.loading &&
                    this.state.cart &&
                    this.state.cart.totalCost &&
                    this.state.defaultCard ? (
                      <div
                        style={{
                          padding:
                            this.state.width > 600
                              ? '15px 15px 15px 15px'
                              : '15px 15px 15px 15px',
                          borderTop: '1px solid #e6ebf1',
                          borderBottom: '1px solid #e6ebf1',
                          marginBottom: '15px',
                        }}
                      >
                        <div
                          onClick={() => {
                            handleClickOpenPayment();
                          }}
                          style={{ padding: '0', cursor: 'pointer' }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                          >
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#2c3136',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {strings['Método de pago']}
                            </Typography>
                            <Stack
                              direction={'row'}
                              spacing={0}
                              justifyContent="center"
                            >
                              <>
                                {this.state.defaultCard['cardNumber'] != null &&
                                this.state.defaultCard['cardNumber'] ===
                                  'COD' ? (
                                  <>
                                    <MoneyIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#54bd66',
                                        marginTop: '2px',
                                        marginRight: '2px',
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: '#2c3136',
                                        fontFamily: 'Nunito',
                                        letterSpacing: 'normal',
                                      }}
                                    >
                                      {strings['Pago en efectivo']}
                                    </Typography>
                                  </>
                                ) : null}
                              </>
                              <>
                                {this.state.defaultCard['cardNumber'] != null &&
                                this.state.defaultCard['cardNumber'] ===
                                  'CardOD' ? (
                                  <>
                                    <CreditCardIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#2c59a8',
                                        marginTop: '2px',
                                        marginRight: '2px',
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: '#2c3136',
                                        fontFamily: 'Nunito',
                                        letterSpacing: 'normal',
                                      }}
                                    >
                                      {strings['Tarjeta a la entrega']}
                                    </Typography>
                                  </>
                                ) : null}
                              </>
                              <>
                                {this.state.defaultCard['cardNumber'] == null ||
                                (this.state.defaultCard['cardNumber'] !==
                                  'COD' &&
                                  this.state.defaultCard['cardNumber'] !==
                                    'PAYPAL' &&
                                  this.state.defaultCard['cardNumber'] !==
                                    'CardOD') ? (
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                  >
                                    <CreditCardIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#c96812',
                                      }}
                                    />

                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        color: '#2c3136',
                                        fontWeight: '500',
                                        fontFamily: 'Nunito',
                                        letterSpacing: 'normal',
                                      }}
                                    >
                                      {'   ' +
                                        this.state.defaultCard['card'][
                                          'card_number'
                                        ].toString()}
                                    </Typography>
                                  </Stack>
                                ) : null}
                              </>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null}
                  </>
                  <div style={{ padding: '0px 15px 10px' }}>
                    <Stack
                      direction={'row'}
                      spacing={0}
                      justifyContent="space-between"
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          color: '#2c3136',
                          fontWeight: '500',
                          color: '#2c3136',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                        }}
                      >
                        {strings['Total']}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '18px',
                          color: '#2c3136',
                          fontWeight: '800',
                          fontFamily: 'Nunito',
                          letterSpacing: 'normal',
                        }}
                      >
                        {'$' + grandTotal2().toFixed(2)}
                      </Typography>
                    </Stack>
                  </div>
                  <div
                    style={{
                      padding:
                        this.state.width > 600
                          ? '80px 0px 0px 0px'
                          : '100px 0px 0px 0px',
                    }}
                  ></div>
                </Stack>
                {/* </div> */}
                <>
                  {!this.state.loading &&
                  this.state.cart &&
                  this.state.cart['totalCost'] != null &&
                  this.state.delivery != null &&
                  this.state.defaultCard == null &&
                  this.state.userDetails == null ? (
                    <div
                      style={{
                        position: this.state.width < 600 ? 'fixed' : 'relative',
                        left: '0px',
                        bottom: this.state.width < 600 ? '0' : '80px',
                        padding: '10px 15px 10px 5px',
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #0000001f',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '12%' }}>
                        <DeleteOutlineIcon
                          onClick={handleClearCart}
                          style={{
                            height: '24px',
                            position: 'relative',
                            top: '18px',
                            color: '#6a696e',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          if(((this.state.selectedType == 1 && this.state.businessData.minimumPurchasePick!=null && this.state.businessData.minimumPurchasePick<=this.state.cart['totalCost']) || ((this.state.selectedType != 1 || this.state.businessData.minimumPurchasePick==null) && (this.state.businessData.minimumPurchase==null || this.state.businessData.minimumPurchase<=this.state.cart['totalCost'])))) {
                          this.setState({ showCart: false });
                          handleClickOpenLogin();
                          } else{
      this.setState({
        showMinimum: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showMinimum: false });
      };
    }
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: getPrimaryColor(),
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          width: '88%',
                          cursor: 'pointer',
                        }}
                      >
                        {/* <div style={{padding: "10px"}}> */}
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['Ingresar']}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* </div> */}
                      </div>
                    </div>
                  ) : null}
                </>
                <>
                  {!this.state.loading &&
                  this.state.cart &&
                  this.state.cart['totalCost'] != null &&
                  this.state.delivery != null &&
                  this.state.defaultCard == null &&
                  this.state.userDetails != null ? (
                    <div
                      style={{
                        position: this.state.width < 600 ? 'fixed' : 'relative',
                        left: '0px',
                        bottom: this.state.width < 600 ? '0' : '80px',
                        padding: '10px 15px 10px 5px',
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #0000001f',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '12%' }}>
                        <DeleteOutlineIcon
                          onClick={handleClearCart}
                          style={{
                            height: '24px',
                            position: 'relative',
                            top: '18px',
                            color: '#6a696e',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          if(((this.state.selectedType == 1 && this.state.businessData.minimumPurchasePick!=null && this.state.businessData.minimumPurchasePick<=this.state.cart['totalCost']) || ((this.state.selectedType != 1 || this.state.businessData.minimumPurchasePick==null) && (this.state.businessData.minimumPurchase==null || this.state.businessData.minimumPurchase<=this.state.cart['totalCost'])))) {
                          // this.setState({ showCart: false });
                          } else{
      this.setState({
        showMinimum: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showMinimum: false });
      };
    }
                          handleClickOpenPayment();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: getPrimaryColor(),
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          width: '88%',
                          cursor: 'pointer',
                        }}
                      >
                        {/* <div style={{padding: "10px"}}> */}
                        <Stack
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <CreditCardIcon
                              style={{
                                fontSize: this.state.width < 600 ? '24px' : '22px',
                                fontWeight: 'normal',
                                color: '#ffffff',
                                marginTop: this.state.width < 600 ? '1px' : '4px',
                                marginRight: '8px',
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '16px',
                                fontWeight: '800',
                                color: '#ffffff',
                                textAlign: 'center',
                                marginTop: this.state.width < 600 ? '2px' : '5px',
                                letterSpacing: 'normal',
                              }}
                            >
                              {strings['Elige tu método de pago ']}
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* </div> */}
                      </div>
                    </div>
                  ) : null}
                </>
                <>
                  {!this.state.loading &&
                  this.state.cart &&
                  this.state.cart['totalCost'] != null &&
                  this.state.delivery != null &&
                  this.state.defaultCard != null &&
                  this.state.userDetails != null ? (
                    <div
                      style={{
                        position: this.state.width < 600 ? 'fixed' : 'relative',
                        left: '0px',
                        bottom: this.state.width < 600 ? '0' : '80px',
                        padding: '10px 15px 10px 5px',
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #0000001f',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '12%' }}>
                        <DeleteOutlineIcon
                          onClick={handleClearCart}
                          style={{
                            height: '24px',
                            position: 'relative',
                            top: '18px',
                            color: '#6a696e',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={async () => {
                          // this.setState({ showCart: false });
                          // handleClickOpenPayment();
                          if(((this.state.selectedType == 1 && this.state.businessData.minimumPurchasePick!=null && this.state.businessData.minimumPurchasePick<=this.state.cart['totalCost']) || ((this.state.selectedType != 1 || this.state.businessData.minimumPurchasePick==null) && (this.state.businessData.minimumPurchase==null || this.state.businessData.minimumPurchase<=this.state.cart['totalCost'])))) {
                          var value = this.state.businessData;
                          var order = this.state.order;
                          this.setState({
                            loading: true,
                          });
                          var isOpen;
                          var toggledOff = false;
                          if (value['available']) {
                            // console.log(this.state.selectedWeekDay);
                            if (
                              value['schedule'][this.state.selectedWeekDay][
                                'enabled'
                              ]
                            ) {
                              for (
                                var i = 0;
                                i <
                                value['schedule'][this.state.selectedWeekDay][
                                  'lapses'
                                ].length;
                                i++
                              ) {
                                //date = tz.TZDateTime.now(timezone);
                                //print(date.hour);
                                if (
                                  value['schedule'][this.state.selectedWeekDay][
                                    'lapses'
                                  ][i]['open']['hour'] <
                                    this.state.selectedDate.getHours() &&
                                  value['schedule'][this.state.selectedWeekDay][
                                    'lapses'
                                  ][i]['close']['hour'] >
                                    this.state.selectedDate.getHours()
                                ) {
                                  isOpen = true;
                                } else if (
                                  value['schedule'][this.state.selectedWeekDay][
                                    'lapses'
                                  ][i]['open']['hour'] ===
                                  this.state.selectedDate.getHours()
                                ) {
                                  if (
                                    value['schedule'][
                                      this.state.selectedWeekDay
                                    ]['lapses'][i]['open']['minute'] <=
                                    this.state.selectedDate.getMinutes()
                                  ) {
                                    isOpen = true;
                                  }
                                } else if (
                                  value['schedule'][this.state.selectedWeekDay][
                                    'lapses'
                                  ][i]['close']['hour'] ===
                                  this.state.selectedDate.getHours()
                                ) {
                                  if (
                                    value['schedule'][
                                      this.state.selectedWeekDay
                                    ]['lapses'][i]['close']['minute'] >
                                    this.state.selectedDate.getMinutes()
                                  ) {
                                    isOpen = true;
                                  }
                                }
                              }
                            }
                            if (isOpen == null) isOpen = false;
                          } else {
                            isOpen = false;
                            toggledOff = true;
                          }
                          // if (isOpen && restaurant[0].inDisableZone) {
                          //   // var timezone = tz.getLocation(restaurant[0].timezone);
                          //   // DateTime date = tz.TZDateTime.now(timezone);
                          //   var date12 = selectedDate.hour;
                          //   var disableendhour = restaurant[0].endHour;
                          //   if (restaurant[0].startHour > disableendhour) {
                          //     if (restaurant[0].startHour > date12) date12 = date12 + 24;
                          //     disableendhour = disableendhour + 24;
                          //   }
                          //   if (restaurant[0].startHour >
                          //       disableendhour) {
                          //     if (restaurant[0].startHour > date12)
                          //       date12 = date12 + 24;
                          //   }
                          //   if (restaurant[0].startHour < date12 && disableendhour > date12) {
                          //     isOpen = false;
                          //   } else if (restaurant[0].startHour == date12) {
                          //     if (restaurant[0].startMinute < selectedDate.minute) {
                          //       isOpen = false;
                          //     }
                          //   } else if (disableendhour == date12) {
                          //     if (restaurant[0].endMinute > selectedDate.minute) {
                          //       isOpen = false;
                          //     }
                          //   }
                          // }
                          // if (isOpen && value.inDisableZone) {
                          //   var timezone = tz.getLocation(value.timezone);
                          //   DateTime date = tz.TZDateTime.now(timezone);
                          //   int date12 = date.hour;
                          //   if (value.startHour >
                          //       value.endHour) {
                          //     if (value.startHour > date12)
                          //       date12 = date12 + 24;
                          //   }
                          //   if (value.startHour < date.hour && value.endHour > date.hour) {
                          //     isOpen = false;
                          //   } else if (value.startHour == date.hour) {
                          //     if (value.startMinute < date.minute) {
                          //       isOpen = false;
                          //     }
                          //   } else if (value.endHour == date.hour) {
                          //     if (value.endMinute > date.minute) {
                          //       isOpen = false;
                          //     }
                          //   }
                          // }
                          if (
                            isOpen ||
                            (value.canPickup && toggledOff && !value.cashOnly)
                          ) {
                            order = {
                              offerCode: this.state.offerCode,
                              offerId: this.state.offerId,
                              offerCode2: this.state.offerCode2,
                              offerId2: this.state.offerId2,
                              appVersion: '(up)5.1.65',
                              hasNumberAlready: false,
                              addedCount: false,
                              reviewedDriver: false,
                              reviewedBus: false,
                              tip: calcTip(),
                              driverService:
                              (value.driverServiceFee
          ? value.driverServiceFee
          : 0) +
        (this.state.cart['totalCost'] *
          (value.driverServicePercentage
            ? value.driverServicePercentage
            : 0)) /
          100,
                              driverServiceFee: value.driverServiceFee,
                              driverServicePercentage:
                                value.driverServicePercentage,
                              tipPercent: this.state.tipPercent,
                              canChangeProd: true,
                              isUsingWallet: this.state.usingWallet,
                              isUsingInvite: false,
                              onlyInHouseDriver:
                                this.state.selectedType === 1
                                  ? true
                                  : value.onlyInHouseDriver,
                              isPickup: this.state.selectedType === 1,
                              isDriver: false,
                              assignmentEnabled:
                                this.state.selectedType === 1
                                  ? false
                                  : !value.onlyInHouseDriver,
                              isClubDevy: false,
                              isUsingSubscription: false,
                              isNewUser: false,
                              isInviteFinished: false,
                              offerValue: this.state.offerValue,
                              offerValue2: this.state.offerValue2,
                              cashbackValue: this.state.cashbackValue,
                              restId: this.state.cart['restId'],
                              restName: this.state.cart['restName'],
                              oxxoNameData: this.state.cart['oxxoNameData'],
                              oxxoLogoImage: this.state.cart['oxxoLogoImage'],
                              restLogo: this.state.cart['restLogo'],
                              cookingTime: this.state.cart['maxTime'],
                              distance: geolib.getDistance(
                                {
                                  latitude:
                                    this.state.selectedLocation['latitude'],
                                  longitude:
                                    this.state.selectedLocation['longitude'],
                                },
                                {
                                  latitude: value.location['latitude'],
                                  longitude: value.location['longitude'],
                                }
                              ),
                              itemCost: this.state.cart['totalCost'],
                              deliveryCharge: this.state.selectedType === 1?0:(this.state.delivery + this.extraCharges()),
                              extraDelivery: this.extraCharges(),
                              normalCharge: this.state.normalDelivery,
                              additionalDeliveryCharge:
                                this.state.additionalDelivery,
                              additionalDeliveryPercentage:
                                this.state.additionalDeliveryPercentage2,
                              dynamicDelivery: this.state.dynamicDelivery,
                              reasonTitle: this.state.reasonTitle,
                              reasonDescription: this.state.reasonDescription,
                              grandTotal: grandTotal2(),
                              items: this.state.cart['item'],
                            };
                            // try {
                            //   if (isUsingInviteCode != null &&
                            //       isUsingInviteCode &&
                            //       enteredInviteCode != null && enteredInviteCode!='') {
                            //     order['inviteCodeUser'] = enteredInviteCode;
                            //   } else {
                            order['inviteCodeUser'] = '';
                            //   }
                            // } catch(e){}
                            order['oxxoItems'] = [];
                            order['oxxoDeliveryCharge'] = 0;
                            order['oxxoItemCost'] = 0;
                            order['grandTotalWithoutOxxo'] = grandTotal();
                            // if(cart['totalOxxoCost']>0) {
                            //   order['isOxxoEnabled'] = true;
                            //   order['oxxoId'] = 'ahharw6ApoVVlSgZBA7M';
                            // }
                            // if(cart['totalOxxoCost']>0) {
                            //
                            // }
                            //if(order['isOxxoEnabled']!=null && order['isOxxoEnabled'])
                            order['restLocation'] = value.location;
                            order['restEstimateTime'] = (value['totalTime'] == null ||
                            value['totalOrders'] == null ||
                            value['totalOrders'] === 0 ||
                            value['totalTime'] /
                            value['totalOrders'] /
                          60 <=
                          7
                          ? 35
                          : value['totalTime'] >= 46
                          ? 46
                          : value['totalTime'] /
                          value['totalOrders'] /
                            60);
                            order['restContact'] = value.phone;
                            order['perKMDeliveryFee'] = this.state.deliveryFee;
                            order['minimumDeliveryFee'] =
                              this.state.dynamicFeeType;
                            order['userName'] =
                              this.state.userDetails['fullName'];
                            order['userId'] = this.state.userId;
                            order['userContact'] =
                              this.state.userDetails['phoneNumber'];
                            order['userEmail'] =
                              this.state.userDetails['email'];
                            order['userLocation'] = this.state.selectedLocation;
                            order['acceptedByRest'] = false;
                            order['driverContact'] = '';
                            order['driverAvatar'] = '';
                            order['driverReachingTime'] = null;
                            order['orderReachingTime'] = 60;
                            order['driverId'] = '';
                            order['driverLocation'] = {
                              address: '',
                              latitude: null,
                              longitude: null,
                            };
                            order['showSpoonOption'] = value.spoon;
                            order['giveSpoon'] = this.state.spoon;
                            order['driversContacted'] = '';
                            order['completedOn'] = null;
                            order['orderAcceptedAt'] = null;
                            order['orderReadyAt'] = null;
                            order['orderPickedUpAt'] = null;
                            order['orderDeliveredAt'] = null;
                            order['driverAssignedAt'] = null;
                            order['driverName'] = '';
                            order['driverAssigned'] = false;
                            order['driverReached'] = false;
                            order['orderPickedUp'] = false;
                            order['cancelled'] = false;
                            order['delivered'] = false;
                            order['devyPercentage'] =
                              value.partner != null && !value.partner
                                ? value.fixedUsageFee
                                : 0;
                            order['isNonPartner'] =
                              value.partner != null ? !value.partner : false;
                            order['originalCost'] =
                              value.partner != null && !value.partner
                                ? getOriginal() /
                                  ((value.tax + 100) / 100)
                                : getOriginal();
                            order['additionalCharge'] =
                              value.partner != null && !value.partner
                                ? getOriginal() -
                                  getOriginal() /
                                    ((value.tax + 100) / 100)
                                : 0;
                                order['additionalIncrease'] = (Number((this.state.cart['totalCost'] - getOriginal())
                                  .toFixed(2)));
                            order['allergyActivate'] = this.state.allergyActivate && this.state.showAllergyData;
                            if (this.state.userDetails.allergy &&
                              this.state.userDetails['allergy'].length>0 && this.state.allergyActivate && this.state.showAllergyData) {
                              order['allergy'] = this.state['allergy'];
                            } else {
                            order['allergy'] = [];
                            }
                            if (
                              this.state.userDetails.avatar &&
                              this.state.userDetails['avatar'] !== ''
                            ) {
                              order['userAvatar'] =
                                this.state.userDetails['avatar'];
                            } else {
                              order['userAvatar'] =
                                this.state.businessData.logo;
                            }
                            order['issues'] = [];
                            order['custId'] = this.state.userDetails['custId'];
                            order['readyToBePicked'] = false;
                            order['status'] =
                              strings['Esperando a que el negocio acepte el pedido'];
                            if (
                              this.state.defaultCard['cardNumber'] != null &&
                              (this.state.defaultCard['cardNumber'] === 'COD' ||
                                this.state.defaultCard['cardNumber'] ===
                                  'CardOD')
                            ) {
                              order['onlinePayment'] = false;
                              if (
                                this.state.defaultCard['cardNumber'] === 'COD'
                              ) {
                                order['cardOnDelivery'] = false;
                              } else {
                                order['cardOnDelivery'] = true;
                              }
                            } else {
                              order['onlinePayment'] = true;
                            }
                            if (
                              order['onlinePayment'] &&
                              this.state.defaultCard['cardNumber'] === 'PAYPAL'
                            ) {
                              order['paypalPayment'] = true;
                            } else {
                              order['paypalPayment'] = false;
                            }
                            order = JSON.parse(JSON.stringify(order));
                            // console.log(order);
                            order['selectedOffer']= (this.state.isOfferApplied)?this.state.selectedOffer: {};
                            if(!this.state.isPreOrder) {
                            order['createdOn'] = Timestamp.now();
                            order['isPreOrder'] = false;
                            } else {
                              order['createdOn'] = Timestamp.fromDate(moment(this.state.selectedDate).add(-40, 'm').toDate());
                              order['preOrderedOn'] = Timestamp.now();
                              order['isPreOrder'] = true;
                              order['serverPreOrderInfo'] = {
                                timeOfChange: Timestamp.fromDate(this.state.selectedDate),
                                creationTime : Timestamp.now(),
                                minsBefore: 40,
                              };
                            }
                            order['isFleetOffer'] = false;
                            order['createdBy'] =
                              this.state.userDetails['fullName'];
                            order['preOrderedBy'] =
                              this.state.userDetails['fullName'];
                            if (
                              this.state.cookingInstructions != null &&
                              this.state.cookingInstructions !== ''
                            ) {
                              order['userToRest'] = [
                                {
                                  message: this.state.cookingInstructions,
                                  time: Timestamp.now(),
                                  from: 'user',
                                },
                              ];
                              order['cookingInstructions'] =
                                this.state.cookingInstructions;
                            } else order['userToRest'] = [];
                            if (
                              this.state.deliveryInstructions != null &&
                              this.state.deliveryInstructions !== ''
                            ) {
                              order['userToDriver'] = [
                                {
                                  message: this.state.deliveryInstructions,
                                  time: Timestamp.now(),
                                  from: 'user',
                                },
                              ];
                              order['deliveryInstructions'] =
                                this.state.deliveryInstructions;
                            } else order['userToDriver'] = [];
                            // this.setState( {
                            //   loading : false,
                            // });
                            // if ((this.state.defaultCard['cardNumber'] == null || (defaultCard['cardNumber'] != 'COD'&& defaultCard['cardNumber'] != 'CardOD')) && cart['totalOxxoCost']>0) {
                            //   print('ff');
                            //   _onBasicAlertPressed5(context);
                            // } else {
                            // Box countrySelect = Hive.box('countrySelect');
                            // setState(() {
                            //   defaultCurrency = {
                            //     "symbol": "${countrySelect.get('currency')} ",
                            //     "decimalDigits": 2,
                            //     "symbolBeforeTheNumber": true,
                            //     "currency": "${countrySelect.get('currency')}"
                            //   };
                            // });
                            var dataSend = {
                              order: order,
                              defaultCard: this.state.defaultCard,
                              code:
                                value.location['country'].toLowerCase() ===
                                'mexico'
                                  ? 'MXN'
                                  : 'EUR',
                              findTotal2: findTotal2(),
                              userData: this.state.userDetails,
                              balance: 0,
                              offersUsed: this.state.offersUsed,
                              inviteExpire: Timestamp.now(),
                              inviteBalance: 0,
                              inviteAdd: '',
                            };

                            if (
                              dataSend.defaultCard['cardNumber'] != null &&
                              dataSend.defaultCard['cardNumber'] === 'PAYPAL'
                            ) {
                              // processPaymentAsPaypalCharge((order['grandTotalWithoutOxxo']));
                            } else if (
                              dataSend.defaultCard['cardNumber'] == null ||
                              (dataSend.defaultCard['cardNumber'] !== 'COD' &&
                                dataSend.defaultCard['cardNumber'] !== 'CardOD')
                            ) {
                              // print(grandTotal()* 100);
                              // String code = (restaurant[0]
                              //     .location['country']
                              //     .toLowerCase() ==
                              //     'mexico')
                              //     ? 'MXN'
                              //     : 'USD';
                              // PaymentMethod paymentMethod = PaymentMethod();
                              // paymentMethod = await StripePayment.paymentRequestWithCardForm(
                              //   CardFormPaymentRequest(),
                              // ).then((PaymentMethod paymentMethod) {
                              //   return paymentMethod;
                              // }).catchError((e) {
                              //   print('Error Card: ${e.toString()}');
                              // });
                              // paymentMethod != null
                              //     ? processPaymentAsDirectCharge(paymentMethod, (grandTotal()*100).round(), code):_onBasicAlertPressed(context, 'Something went wrong when creating payment');
                              // console.log(dataSend.defaultCard);
                              // try {
                              //   final response = await Dio().post(
                              //     'https://devy-app.herokuapp.com/drivers/createpay/',
                              //     queryParameters: {
                              //       'number':
                              //       defaultCard['cardNumber']
                              //           .replaceAll(
                              //           new RegExp(r"\s+"),
                              //           ""),
                              //       'month': int.parse(
                              //           defaultCard['expiryDate']
                              //               .split('/')[0]),
                              //       'year': int.parse(
                              //           defaultCard['expiryDate']
                              //               .split('/')[1]),
                              //       'cvv': defaultCard['cvv'],
                              //       'name': defaultCard[
                              //       'cardHolderName'],
                              //       'custId': userData['custId']
                              //           .toString(),
                              //       'email': userData['email']
                              //           .toString(),
                              //       'phone': userData['phoneNumber']
                              //           .toString(),
                              //       'address': selectedLocation[
                              //       'geoAddress']
                              //           .toString(),
                              //       'city': restaurant[0]
                              //           .location['city']
                              //           .toString(),
                              //       'country': restaurant[0]
                              //           .location['country']
                              //           .toString(),
                              //     },
                              //     options: Options(headers: {
                              //       HttpHeaders.contentTypeHeader:
                              //       'application/x-www-form-urlencoded'
                              //     }),
                              //   );
                              //   if (response.statusCode == 200) {
                              //     print(response.data['id']);
                              //     if (response.data['id'] != null) {
                              //       await processPaymentAsDirectCharge(
                              //           response.data,
                              //           (grandTotal() * 100)
                              //               .round(),
                              //           code);
                              //     } else {
                              //       _onBasicAlertPressed(context,
                              //           'Algo salió al mal al crear el pago');
                              //     }
                              //   } else {
                              //     print('failed');
                              //     _onBasicAlertPressed(context,
                              //         'Algo salió al mal al crear el pago');
                              //   }
                              // } on DioError catch (e) {
                              //   print('exception $e');
                              //   _onBasicAlertPressed(context,
                              //       'Algo salió al mal al crear el pago');
                              // }
                              //Stripe.instance.createPaymentMethod(data)
                              // StripePayment.createPaymentMethod(
                              //   PaymentMethodRequest(
                              //     card: CreditCard(
                              //       number: defaultCard['cardNumber']
                              //           .replaceAll(new RegExp(r"\s+"), ""),
                              //       expMonth: int.parse(
                              //           defaultCard['expiryDate']
                              //               .split('/')[0]),
                              //       expYear: int.parse(
                              //           defaultCard['expiryDate']
                              //               .split('/')[1]),
                              //       cvc: defaultCard['cvv'],
                              //       name: defaultCard['cardHolderName'],
                              //
                              //     ),
                              //     billingAddress: BillingAddress(city: restaurant[0]
                              //         .location['city'].toString(),country:restaurant[0]
                              //         .location['country'].toString(),line1: selectedLocation['geoAddress'].toString(),name:defaultCard['cardHolderName']),
                              //   ),
                              // ).then((paymentMethod) async {
                              // try {
                              //   final response = await Dio().post(
                              //     'https://devy-app.herokuapp.com/drivers/createpay/',
                              //     queryParameters: {
                              //       'id':paymentMethod.id,
                              //       // 'number':
                              //       // defaultCard['cardNumber']
                              //       //     .replaceAll(
                              //       //     new RegExp(r"\s+"),
                              //       //     ""),
                              //       // 'month': int.parse(
                              //       //     defaultCard['expiryDate']
                              //       //         .split('/')[0]),
                              //       // 'year': int.parse(
                              //       //     defaultCard['expiryDate']
                              //       //         .split('/')[1]),
                              //       // 'cvv': defaultCard['cvv'],
                              //       'name': defaultCard[
                              //       'cardHolderName'],
                              //       'custId': userData['custId']
                              //           .toString(),
                              //       'email': userData['email']
                              //           .toString(),
                              //       'phone': userData['phoneNumber']
                              //           .toString(),
                              //       'address': selectedLocation[
                              //       'geoAddress']
                              //           .toString(),
                              //       'city': restaurant[0]
                              //           .location['city']
                              //           .toString(),
                              //       'country': restaurant[0]
                              //           .location['country']
                              //           .toString(),
                              //     },
                              //     options: Options(headers: {
                              //       HttpHeaders.contentTypeHeader:
                              //       'application/x-www-form-urlencoded'
                              //     },
                              //       receiveTimeout: 0,
                              //       followRedirects: false,
                              //     ),
                              //   );
                              //   if (response.statusCode == 200) {
                              //     print(response.data['id']);
                              //     if (response.data['id'] != null && response.data['error']==null) {
                              //       if(mounted) {
                              //         await processPaymentAsDirectCharge(
                              //             response.data,
                              //             (grandTotal() * 100)
                              //                 .round(),
                              //             code);
                              //       }
                              //     } else {
                              //       _onBasicAlertPressed(context,
                              //           'Algo salió al mal al crear el pago');
                              //     }
                              //   } else {
                              //     print('failed');
                              //     _onBasicAlertPressed(context,
                              //         'Algo salió al mal al crear el pago');
                              //   }
                              // } on DioError catch (e) {
                              //   print('exception $e');
                              //   _onBasicAlertPressed(context,
                              //       'Algo salió al mal al crear el pago');
                              // }
                              // await processPaymentAsDirectCharge(
                              //     defaultCard, (order['grandTotalWithoutOxxo'] * 100).round(), code);
                              // _scaffoldKey.currentState.showSnackBar(SnackBar(content: Text('Received ${paymentMethod.id}')));
                              // setState(() {
                              //   _paymentMethod = paymentMethod;
                              // });
                              // }).catchError((e) {
                              //   _onBasicAlertPressed(context,
                              //       'Algo salió al mal al crear el pago');
                              //   setState(() {
                              //     loading = false;
                              //   });
                              //   print(e);
                              // });
                              var dataSendStripe = dataSend.findTotal2;
                              // console.log("dataSend.order['grandTotalWithoutOxxo'] * 100",(dataSend.order['grandTotalWithoutOxxo'] * 100));
                              var response = await axios.get(
                    `https://senor-order-85fa55d895ba.herokuapp.com/drivers/proxy/https://us-central1-senor-delivery-app-app.cloudfunctions.net/StripePICust?amount=${grandTotal2() * 100}&currency=${dataSend.code}&customer=${dataSend.order['custId'].toString()}&description=${encodeURIComponent(dataSendStripe)}&paym=${dataSend.defaultCard['id']}`,null,
                  );
                  console.log('Now i decode');
    if (response.data != null && response.data !== 'error' && response.status === 200) {
      var paymentIntentX = response.data;
      var statusData = paymentIntentX['paymentIntent']['status'];
      var strAccount = paymentIntentX['stripeAccount'];
      if (statusData === 'succeeded') {
        // console.log(
        //     `Payment completed. ${paymentIntentX['paymentIntent']['amount'].toString()}p succesfully charged`);
        order['paymentIntentId'] =
            paymentIntentX['paymentIntent']['id'].toString();
            await addDoc(collection(db, 'orders'), order)
                                .then(async (value) => {
                                  order['id'] = value.id;
                                  //print('orderId ' + value.id);
                                  // order['createdOn'] =
                                  //     Timestamp.now().toMillis();
                                  if (order['offerValue'] > 0) {
                                    dataSend.offersUsed.push({
                                      id: order['offerId'],
                                      usedOn: Timestamp.now(),
                                    });
                                    // console.log(dataSend.offersUsed);
                                    await updateDoc(
                                      doc(db, 'users', this.state.userId),
                                      {
                                        offersUsed: dataSend.offersUsed,
                                        updatedOn: Timestamp.now(),
                                      }
                                    );
                                  }
                                  if (
                                    order['offerValue2'] > 0 ||
                                    (order['cashbackValue'] != null &&
                                      order['cashbackValue'] > 0)
                                  ) {
                                    if (
                                      !order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      //     dataSend.offersUsed.add({'id': order['offerId2'], 'usedOn': Timestamp.now()});
                                      // console.log(dataSend.offersUsed);
                                      // await updateDoc(doc(db, 'users', this.state.userId),
                                      //     {'offersUsed': dataSend.offersUsed, 'updatedOn': Timestamp.now()});
                                    } else if (
                                      order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      // await users.doc(order['userId']).update({
                                      //   'invitedBy': order['offerId2'],
                                      //   'usedInviteCode': order['offerCode2'],
                                      //   'inviteBalance': dataSend.inviteBalance - order['offerValue2'],
                                      //   'inviteAdd': dataSend.inviteAdd,
                                      //   'inviteExpire': dataSend.inviteExpire,
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                      if (order['isNewUser']) {
                                        // try {
                                        //   await inviteCollection.doc(order['offerCode2']).update({
                                        //     'totalUse': FieldValue.increment(1),
                                        //     'usedBy': FieldValue.arrayUnion([order['userId']]),
                                        //     'updatedOn': Timestamp.now()
                                        //   });
                                        // } catch (e) {}
                                      }
                                    } else if (
                                      !order['isUsingInvite'] &&
                                      order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      var walletHistory = this.state.userDetails['walletHistory'] ?? [];
                                      var walletBalance = (this.state.userDetails['walletBalance'] ?? 0) - order['offerValue2'];
                                      walletHistory.push({
                                          'id': uuidv4(),
                                        'amount': order['offerValue2'],
                                        'isCredit': false,
                                        'reason': 'order id: '+value.id.toString()+' discount',
                                        'timestamp': Timestamp.now()
                                      });
                                      // console.log(dataSend.offersUsed);
                                      await updateDoc(
                                        doc(db, 'users', (this.state.isGuest)?'DKuDugL29vcQDPrYhG2Z9YPga0r2':this.state.userId),
                                        {
                                          'walletBalance': walletBalance,
                                            'walletHistory': walletHistory,
                                        }
                                      );
                                      // var walletHistory = dataSend.userData['walletHistory'];
                                      // var walletBalance = dataSend.balance - order['offerValue2'];
                                      // if (walletHistory == null) {
                                      //   walletHistory = [];
                                      // }
                                      // walletHistory.push({
                                      //   'id': uuidv4(),
                                      //   'amount': order['offerValue2'],
                                      //   'isCredit': false,
                                      //   'reason': 'order id: '+value.id.toString()+' discount',
                                      //   'timestamp': Timestamp.now()
                                      // });
                                      // await  updateDoc(doc(db, 'users', this.state.userId),{
                                      //   'walletBalance': walletBalance,
                                      //   'walletHistory': walletHistory,
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                    } else if (
                                      !order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      order['isUsingSubscription']
                                    ) {
                                      //     await updateDoc(doc(db, 'users', this.state.userId), {
                                      //   'totalSubOrders': FieldValue.increment(1),
                                      //   'totalSubSaving': FieldValue.increment(order['offerValue2']),
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                    }
                                  }
                                  if (!order['isPreOrder']) {
                                    sendNotification(
                                      order['restId'],
                                      strings['Nuevo pedido de '] +
                                        dataSend.userData['fullName'],
                                      strings['Nuevo orden'],
                                      value.id,
                                      order
                                    );
                                  }
                                  this.setState({
                                    openAlert: true,
                                    alertType: 'success',
                                    alertMsg: strings['Pedido realizado con éxito'],
                                  });
                                  // boxOrder.clear();
                                  // boxCart.clear();
                                  this.setState({
                                    loading: false,
                                    showCart: false,
                                    cart: {
                                      ...this.state.cart,
                                      maxTime: 0,
                                      totalCost: 0,
                                      totalItems: 0,
                                      item: [],
                                      totalOxxoCost: 0,
                                      totalOxxoItems: 0,
                                      oxxoNameData: '',
                                      oxxoLogoImage: '',
                                      oxxoItem: [],
                                    },
                                  });
                                  //users.doc()
                                  // Navigator.pushNamedAndRemoveUntil(
                                  //     context,
                                  //     '/tracking',
                                  //     ModalRoute.withName('/dashboard'),
                                  //     arguments: value.id);
                                  // Navigator.pop(context);
                                  // Navigator.pop(context);
                                  // Navigator.pop(context);
                                  // Navigator.pushNamed(context, '/tracking', arguments: value.id);
                                })
                                .catch((e) => {
                                  openAlert(
                                    'error',
                                    strings['Algo salió mal al crear el pedido']
                                  );
                                  this.setState({
                                    loading: false,
                                  });
                                });
        // await orders.add(order).then((value) async {
        //   order['id'] = value.id;
        //   // order['createdOn'] = Timestamp.now().millisecondsSinceEpoch;
        //   if (order['offerValue'] > 0) {
        //     // if (!order['isUsingInvite'] &&
        //     //     !order['isUsingWallet'] &&
        //     //     !order['isUsingSubscription']) {
        //     offersUsed.add({'id': order['offerId'], 'usedOn': Timestamp.now()});
        //     await users.doc(order['userId']).update(
        //         {'offersUsed': offersUsed, 'updatedOn': Timestamp.now()});
        //   }
        //   if (order['offerValue2'] > 0 ||
        //       (order['cashbackValue'] != null && order['cashbackValue'] > 0)) {
        //     if (!order['isUsingInvite'] &&
        //         !order['isUsingWallet'] &&
        //         !order['isUsingSubscription']) {
        //       offersUsed
        //           .add({'id': order['offerId2'], 'usedOn': Timestamp.now()});
        //       await users.doc(order['userId']).update(
        //           {'offersUsed': offersUsed, 'updatedOn': Timestamp.now()});
        //     } else if (order['isUsingInvite'] &&
        //         !order['isUsingWallet'] &&
        //         !order['isUsingSubscription']) {
        //       await users.doc(order['userId']).update({
        //         'invitedBy': order['offerId2'],
        //         'usedInviteCode': order['offerCode2'],
        //         'inviteBalance': inviteBalance - order['offerValue2'],
        //         'inviteAdd': inviteAdd,
        //         'inviteExpire': inviteExpire,
        //         'updatedOn': Timestamp.now()
        //       });
        //       if (order['isNewUser']) {
        //         try {
        //           await inviteCollection.doc(order['offerCode2']).update({
        //             'totalUse': FieldValue.increment(1),
        //             'usedBy': FieldValue.arrayUnion([order['userId']]),
        //             'updatedOn': Timestamp.now()
        //           });
        //         } catch (e) {}
        //       }
        //     } else if (!order['isUsingInvite'] &&
        //         order['isUsingWallet'] &&
        //         !order['isUsingSubscription']) {
        //       List walletHistory = userData['walletHistory'];
        //       double walletBalance = balance - order['offerValue2'];
        //       if (walletHistory == null) {
        //         walletHistory = [];
        //       }
        //       walletHistory.add({
        //         'id': uuid.v1(),
        //         'amount': order['offerValue2'],
        //         'isCredit': false,
        //         'reason': 'loadingOrder1473'.tr(args: [value.id.toString()]),
        //         'timestamp': Timestamp.now()
        //       });
        //       await users.doc(order['userId']).update({
        //         'walletBalance': walletBalance,
        //         'walletHistory': walletHistory,
        //         'updatedOn': Timestamp.now()
        //       });
        //     } else if (!order['isUsingInvite'] &&
        //         !order['isUsingWallet'] &&
        //         order['isUsingSubscription']) {
        //       await users.doc(order['userId']).update({
        //         'totalSubOrders': FieldValue.increment(1),
        //         'totalSubSaving': FieldValue.increment(order['offerValue2']),
        //         'updatedOn': Timestamp.now()
        //       });
        //     }
        //   }
        //   if (!order['isPreOrder']) {
        //     await sendNotification(
        //         order['restId'],
        //         'loadingOrder1494'.tr() + userData['fullName'],
        //         'loadingOrder1495'.tr(),
        //         value.id);
        //   }
        //   boxOrder.clear();
        //   boxCart.clear();
        //   setState(() {
        //     loading = false;
        //   });
        //   // Navigator.pushNamedAndRemoveUntil(
        //   //     context, '/tracking', ModalRoute.withName('/dashboard'),
        //   //     arguments: value.id);
        //   Navigator.pop(context);
        //   Navigator.pop(context);
        //   Navigator.pop(context);
        //   Navigator.pushNamed(context, '/tracking', arguments: value.id);
        // }).catchError((e) {
        //   setState(() {
        //     loading = false;
        //   });
        //   _onBasicAlertPressed(context, 'loadingOrder1514'.tr());
        // });
        //payment was confirmed by the server without need for futher authentification
        //StripePayment.completeNativePayRequest();
        // setState(() {
        //   text =
        //   'Payment completed. ${paymentIntentX['paymentIntent']['amount'].toString()}p succesfully charged';
        //   showSpinner = false;
        // });
      } else {
        //step 4: there is a need to authenticate
        //StripePayment.setStripeAccount(strAccount);
        const stripe = await loadStripe('pk_test_51Groo5F76HIlmZyWmhOPE8wDkPI40bvJrOmrhsPD4qCMKjPCIjgtuoPLzAwf0xhJHG1B95rGO5sOOgQlziO9P6Yb00tfKKwtpx');
        const {paymentIntent, error} = await stripe.confirmCardPayment(
          paymentIntentX['paymentIntent']['client_secret'],
  {
    payment_method: paymentIntentX['paymentIntent']
                        ['payment_method'],
  },
);

if(!error) {

              //This code will be executed if the authentication is successful
            //step 5: request the server to confirm the payment with
            var statusFinal = paymentIntent.status;
            if (statusFinal === 'succeeded') {
              // console.log(
              //     `Payment completed. ${paymentIntentX['paymentIntent']['amount'].toString()}p succesfully charged`);
              order['paymentIntentId'] =
                  paymentIntentX['paymentIntent']['id'].toString();
                  await addDoc(collection(db, 'orders'), order)
                                .then(async (value) => {
                                  order['id'] = value.id;
                                  //print('orderId ' + value.id);
                                  // order['createdOn'] =
                                  //     Timestamp.now().toMillis();
                                  if (order['offerValue'] > 0) {
                                    dataSend.offersUsed.push({
                                      id: order['offerId'],
                                      usedOn: Timestamp.now(),
                                    });
                                    // console.log(dataSend.offersUsed);
                                    await updateDoc(
                                      doc(db, 'users', this.state.userId),
                                      {
                                        offersUsed: dataSend.offersUsed,
                                        updatedOn: Timestamp.now(),
                                      }
                                    );
                                  }
                                  if (
                                    order['offerValue2'] > 0 ||
                                    (order['cashbackValue'] != null &&
                                      order['cashbackValue'] > 0)
                                  ) {
                                    if (
                                      !order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      //     dataSend.offersUsed.add({'id': order['offerId2'], 'usedOn': Timestamp.now()});
                                      // console.log(dataSend.offersUsed);
                                      // await updateDoc(doc(db, 'users', this.state.userId),
                                      //     {'offersUsed': dataSend.offersUsed, 'updatedOn': Timestamp.now()});
                                    } else if (
                                      order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      // await users.doc(order['userId']).update({
                                      //   'invitedBy': order['offerId2'],
                                      //   'usedInviteCode': order['offerCode2'],
                                      //   'inviteBalance': dataSend.inviteBalance - order['offerValue2'],
                                      //   'inviteAdd': dataSend.inviteAdd,
                                      //   'inviteExpire': dataSend.inviteExpire,
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                      if (order['isNewUser']) {
                                        // try {
                                        //   await inviteCollection.doc(order['offerCode2']).update({
                                        //     'totalUse': FieldValue.increment(1),
                                        //     'usedBy': FieldValue.arrayUnion([order['userId']]),
                                        //     'updatedOn': Timestamp.now()
                                        //   });
                                        // } catch (e) {}
                                      }
                                    } else if (
                                      !order['isUsingInvite'] &&
                                      order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      var walletHistory = this.state.userDetails['walletHistory'] ?? [];
                                      var walletBalance = (this.state.userDetails['walletBalance'] ?? 0) - order['offerValue2'];
                                      walletHistory.push({
                                          'id': uuidv4(),
                                        'amount': order['offerValue2'],
                                        'isCredit': false,
                                        'reason': 'order id: '+value.id.toString()+' discount',
                                        'timestamp': Timestamp.now()
                                      });
                                      // console.log(dataSend.offersUsed);
                                      await updateDoc(
                                        doc(db, 'users', (this.state.isGuest)?'DKuDugL29vcQDPrYhG2Z9YPga0r2':this.state.userId),
                                        {
                                          'walletBalance': walletBalance,
                                            'walletHistory': walletHistory,
                                        }
                                      );
                                      // var walletHistory = dataSend.userData['walletHistory'];
                                      // var walletBalance = dataSend.balance - order['offerValue2'];
                                      // if (walletHistory == null) {
                                      //   walletHistory = [];
                                      // }
                                      // walletHistory.push({
                                      //   'id': uuidv4(),
                                      //   'amount': order['offerValue2'],
                                      //   'isCredit': false,
                                      //   'reason': 'order id: '+value.id.toString()+' discount',
                                      //   'timestamp': Timestamp.now()
                                      // });
                                      // await  updateDoc(doc(db, 'users', this.state.userId),{
                                      //   'walletBalance': walletBalance,
                                      //   'walletHistory': walletHistory,
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                    } else if (
                                      !order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      order['isUsingSubscription']
                                    ) {
                                      //     await updateDoc(doc(db, 'users', this.state.userId), {
                                      //   'totalSubOrders': FieldValue.increment(1),
                                      //   'totalSubSaving': FieldValue.increment(order['offerValue2']),
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                    }
                                  }
                                  if (!order['isPreOrder']) {
                                    sendNotification(
                                      order['restId'],
                                      strings['Nuevo pedido de '] +
                                        dataSend.userData['fullName'],
                                      strings['Nuevo orden'],
                                      value.id,
                                      order
                                    );
                                  }
                                  this.setState({
                                    openAlert: true,
                                    alertType: 'success',
                                    alertMsg: strings['Pedido realizado con éxito'],
                                  });
                                  // boxOrder.clear();
                                  // boxCart.clear();
                                  this.setState({
                                    loading: false,
                                    showCart: false,
                                    cart: {
                                      ...this.state.cart,
                                      maxTime: 0,
                                      totalCost: 0,
                                      totalItems: 0,
                                      item: [],
                                      totalOxxoCost: 0,
                                      totalOxxoItems: 0,
                                      oxxoNameData: '',
                                      oxxoLogoImage: '',
                                      oxxoItem: [],
                                    },
                                  });
                                  //users.doc()
                                  // Navigator.pushNamedAndRemoveUntil(
                                  //     context,
                                  //     '/tracking',
                                  //     ModalRoute.withName('/dashboard'),
                                  //     arguments: value.id);
                                  // Navigator.pop(context);
                                  // Navigator.pop(context);
                                  // Navigator.pop(context);
                                  // Navigator.pushNamed(context, '/tracking', arguments: value.id);
                                })
                                .catch((e) => {
                                  openAlert(
                                    'error',
                                    strings['Algo salió mal al crear el pedido']
                                  );
                                  this.setState({
                                    loading: false,
                                  });
                                });
              // await orders.add(order).then((value) async {
              //   order['id'] = value.id;
              //   // order['createdOn'] = Timestamp.now().millisecondsSinceEpoch;
              //   if (order['offerValue'] > 0) {
              //     // if (!order['isUsingInvite'] &&
              //     //     !order['isUsingWallet'] &&
              //     //     !order['isUsingSubscription']) {
              //     offersUsed
              //         .add({'id': order['offerId'], 'usedOn': Timestamp.now()});
              //     await users.doc(order['userId']).update(
              //         {'offersUsed': offersUsed, 'updatedOn': Timestamp.now()});
              //   }
              //   if (order['offerValue2'] > 0 ||
              //       (order['cashbackValue'] != null &&
              //           order['cashbackValue'] > 0)) {
              //     if (!order['isUsingInvite'] &&
              //         !order['isUsingWallet'] &&
              //         !order['isUsingSubscription']) {
              //       offersUsed.add(
              //           {'id': order['offerId2'], 'usedOn': Timestamp.now()});
              //       await users.doc(order['userId']).update({
              //         'offersUsed': offersUsed,
              //         'updatedOn': Timestamp.now()
              //       });
              //     } else if (order['isUsingInvite'] &&
              //         !order['isUsingWallet'] &&
              //         !order['isUsingSubscription']) {
              //       await users.doc(order['userId']).update({
              //         'invitedBy': order['offerId2'],
              //         'usedInviteCode': order['offerCode2'],
              //         'inviteBalance': inviteBalance - order['offerValue2'],
              //         'inviteAdd': inviteAdd,
              //         'inviteExpire': inviteExpire,
              //         'updatedOn': Timestamp.now()
              //       });
              //       if (order['isNewUser']) {
              //         try {
              //           await inviteCollection.doc(order['offerCode2']).update({
              //             'totalUse': FieldValue.increment(1),
              //             'usedBy': FieldValue.arrayUnion([order['userId']]),
              //             'updatedOn': Timestamp.now()
              //           });
              //         } catch (e) {}
              //       }
              //     } else if (!order['isUsingInvite'] &&
              //         order['isUsingWallet'] &&
              //         !order['isUsingSubscription']) {
              //       List walletHistory = userData['walletHistory'];
              //       double walletBalance = balance - order['offerValue2'];
              //       if (walletHistory == null) {
              //         walletHistory = [];
              //       }
              //       walletHistory.add({
              //         'id': uuid.v1(),
              //         'amount': order['offerValue2'],
              //         'isCredit': false,
              //         'reason':
              //             'loadingOrder1473'.tr(args: [value.id.toString()]),
              //         'timestamp': Timestamp.now()
              //       });
              //       await users.doc(order['userId']).update({
              //         'walletBalance': walletBalance,
              //         'walletHistory': walletHistory,
              //         'updatedOn': Timestamp.now()
              //       });
              //     } else if (!order['isUsingInvite'] &&
              //         !order['isUsingWallet'] &&
              //         order['isUsingSubscription']) {
              //       await users.doc(order['userId']).update({
              //         'totalSubOrders': FieldValue.increment(1),
              //         'totalSubSaving':
              //             FieldValue.increment(order['offerValue2']),
              //         'updatedOn': Timestamp.now()
              //       });
              //     }
              //   }
              //   if (!order['isPreOrder']) {
              //     await sendNotification(
              //         order['restId'],
              //         'loadingOrder1617'.tr() + userData['fullName'],
              //         'loadingOrder1618'.tr(),
              //         value.id);
              //   }
              //   boxOrder.clear();
              //   boxCart.clear();
              //   setState(() {
              //     loading = false;
              //   });
              //   // Navigator.pushNamedAndRemoveUntil(
              //   //     context, '/tracking', ModalRoute.withName('/dashboard'),
              //   //     arguments: value.id);
              //   Navigator.pop(context);
              //   Navigator.pop(context);
              //   Navigator.pop(context);
              //   Navigator.pushNamed(context, '/tracking', arguments: value.id);
              // }).catchError((e) {
              //   setState(() {
              //     loading = false;
              //   });
              //   _onBasicAlertPressed(context, 'loadingOrder1638'.tr());
              // });
              //payment was confirmed by the server without need for futher authentification
              //StripePayment.completeNativePayRequest();
              // setState(() {
              //   text =
              //   'Payment completed. ${paymentIntentX['paymentIntent']['amount'].toString()}p succesfully charged';
              //   showSpinner = false;
              // });
            } else if (statusFinal === 'processing') {
              this.setState( {
                loading: false
              });
              openAlert('error',
                  strings["El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos"]);
              console.log(
                  'The payment is still in "processing" state. This is unusual. Please contact us');
              //StripePayment.cancelNativePayRequest();
              // setState(() {
              //   showSpinner = false;
              // });
              // showDialog(
              //     context: context,
              //     builder: (BuildContext context) => ShowDialogToDismiss(
              //         title: 'Warning',
              //         content:
              //         'The payment is still in \'processing\' state. This is unusual. Please contact us',
              //         buttonText: 'CLOSE'));
            } else {
              this.setState( {
                loading: false
              });
              openAlert('error',
                  strings['Hubo un error al confirmar el pago. Detalles: ']+statusFinal.toString());
                  console.log(
                  'There was an error to confirm the payment. Details: $statusFinal',statusFinal);
              //StripePayment.cancelNativePayRequest();
              // setState(() {
              //   showSpinner = false;
              // });
              // showDialog(
              //     context: context,
              //     builder: (BuildContext context) => ShowDialogToDismiss(
              //         title: 'Error',
              //         content:
              //         'There was an error to confirm the payment. Details: $statusFinal',
              //         buttonText: 'CLOSE'));
            }
          //If Authentication fails, a PlatformException will be raised which can be handled her

} else {
  this.setState( {
                loading: false
              });
              openAlert('error',
                  strings["Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta"]);
                  console.log('this is error');
          //case B1
          //StripePayment.cancelNativePayRequest();
          // setState(() {
          //   showSpinner = false;
          // });
          // showDialog(
          //     context: context,
          //     builder: (BuildContext context) => ShowDialogToDismiss(
          //         title: 'Error',
          //         content:
          //         'There was an error to confirm the payment. Please try again with another card',
          //         buttonText: 'CLOSE'));
}
        // Stripe.stripeAccountId = strAccount;
        // Stripe.instance
        //     .confirmPayment(
        //         paymentIntentX['paymentIntent']['client_secret'],
        //         PaymentMethodParams.cardFromMethodId(
        //             paymentMethodId: paymentIntentX['paymentIntent']
        //                 ['payment_method']))
        //     // await StripePayment.confirmPaymentIntent(PaymentIntent(
        //     //     paymentMethodId: paymentIntentX['paymentIntent']
        //     //     ['payment_method'],
        //     //     clientSecret: paymentIntentX['paymentIntent']['client_secret']))
        //     .then(
        //   (PaymentIntent paymentIntentResult) async {

        // ).catchError((e) {

        // });
      }
    } else {
      this.setState({
        loading: false,
      });
      openAlert('error',
      strings["Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta"]);
      console.log(
          'There was an error in creating the payment. Please try again with another card');
      //case A
      //StripePayment.cancelNativePayRequest();
      // setState(() {
      //   showSpinner = false;
      // });
      // showDialog(
      //     context: context,
      //     builder: (BuildContext context) => ShowDialogToDismiss(
      //         title: 'Error',
      //         content:
      //         'There was an error in creating the payment. Please try again with another card',
      //         buttonText: 'CLOSE'));
    }
                            } else {
                              await addDoc(collection(db, 'orders'), order)
                                .then(async (value) => {
                                  order['id'] = value.id;
                                  //print('orderId ' + value.id);
                                  // order['createdOn'] =
                                  //     Timestamp.now().toMillis();
                                  if (order['offerValue'] > 0) {
                                    dataSend.offersUsed.push({
                                      id: order['offerId'],
                                      usedOn: Timestamp.now(),
                                    });
                                    // console.log(dataSend.offersUsed);
                                    await updateDoc(
                                      doc(db, 'users', this.state.userId),
                                      {
                                        offersUsed: dataSend.offersUsed,
                                        updatedOn: Timestamp.now(),
                                      }
                                    );
                                  }
                                  if (
                                    order['offerValue2'] > 0 ||
                                    (order['cashbackValue'] != null &&
                                      order['cashbackValue'] > 0)
                                  ) {
                                    if (
                                      !order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      //     dataSend.offersUsed.add({'id': order['offerId2'], 'usedOn': Timestamp.now()});
                                      // console.log(dataSend.offersUsed);
                                      // await updateDoc(doc(db, 'users', this.state.userId),
                                      //     {'offersUsed': dataSend.offersUsed, 'updatedOn': Timestamp.now()});
                                    } else if (
                                      order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      // await users.doc(order['userId']).update({
                                      //   'invitedBy': order['offerId2'],
                                      //   'usedInviteCode': order['offerCode2'],
                                      //   'inviteBalance': dataSend.inviteBalance - order['offerValue2'],
                                      //   'inviteAdd': dataSend.inviteAdd,
                                      //   'inviteExpire': dataSend.inviteExpire,
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                      if (order['isNewUser']) {
                                        // try {
                                        //   await inviteCollection.doc(order['offerCode2']).update({
                                        //     'totalUse': FieldValue.increment(1),
                                        //     'usedBy': FieldValue.arrayUnion([order['userId']]),
                                        //     'updatedOn': Timestamp.now()
                                        //   });
                                        // } catch (e) {}
                                      }
                                    } else if (
                                      !order['isUsingInvite'] &&
                                      order['isUsingWallet'] &&
                                      !order['isUsingSubscription']
                                    ) {
                                      var walletHistory = this.state.userDetails['walletHistory'] ?? [];
                                      var walletBalance = (this.state.userDetails['walletBalance'] ?? 0) - order['offerValue2'];
                                      walletHistory.push({
                                          'id': uuidv4(),
                                        'amount': order['offerValue2'],
                                        'isCredit': false,
                                        'reason': 'order id: '+value.id.toString()+' discount',
                                        'timestamp': Timestamp.now()
                                      });
                                      // console.log(dataSend.offersUsed);
                                      await updateDoc(
                                        doc(db, 'users', (this.state.isGuest)?'DKuDugL29vcQDPrYhG2Z9YPga0r2':this.state.userId),
                                        {
                                          'walletBalance': walletBalance,
                                            'walletHistory': walletHistory,
                                        }
                                      );
                                      // var walletHistory = dataSend.userData['walletHistory'];
                                      // var walletBalance = dataSend.balance - order['offerValue2'];
                                      // if (walletHistory == null) {
                                      //   walletHistory = [];
                                      // }
                                      // walletHistory.push({
                                      //   'id': uuidv4(),
                                      //   'amount': order['offerValue2'],
                                      //   'isCredit': false,
                                      //   'reason': 'order id: '+value.id.toString()+' discount',
                                      //   'timestamp': Timestamp.now()
                                      // });
                                      // await  updateDoc(doc(db, 'users', this.state.userId),{
                                      //   'walletBalance': walletBalance,
                                      //   'walletHistory': walletHistory,
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                    } else if (
                                      !order['isUsingInvite'] &&
                                      !order['isUsingWallet'] &&
                                      order['isUsingSubscription']
                                    ) {
                                      //     await updateDoc(doc(db, 'users', this.state.userId), {
                                      //   'totalSubOrders': FieldValue.increment(1),
                                      //   'totalSubSaving': FieldValue.increment(order['offerValue2']),
                                      //   'updatedOn': Timestamp.now()
                                      // });
                                    }
                                  }
                                  if (!order['isPreOrder']) {
                                    sendNotification(
                                      order['restId'],
                                      strings['Nuevo pedido de '] +
                                        dataSend.userData['fullName'],
                                      strings['Nuevo orden'],
                                      value.id,
                                      order
                                    );
                                  }
                                  this.setState({
                                    openAlert: true,
                                    alertType: 'success',
                                    alertMsg: strings['Pedido realizado con éxito'],
                                  });
                                  // boxOrder.clear();
                                  // boxCart.clear();
                                  this.setState({
                                    loading: false,
                                    showCart: false,
                                    cart: {
                                      ...this.state.cart,
                                      maxTime: 0,
                                      totalCost: 0,
                                      totalItems: 0,
                                      item: [],
                                      totalOxxoCost: 0,
                                      totalOxxoItems: 0,
                                      oxxoNameData: '',
                                      oxxoLogoImage: '',
                                      oxxoItem: [],
                                    },
                                  });
                                  //users.doc()
                                  // Navigator.pushNamedAndRemoveUntil(
                                  //     context,
                                  //     '/tracking',
                                  //     ModalRoute.withName('/dashboard'),
                                  //     arguments: value.id);
                                  // Navigator.pop(context);
                                  // Navigator.pop(context);
                                  // Navigator.pop(context);
                                  // Navigator.pushNamed(context, '/tracking', arguments: value.id);
                                })
                                .catch((e) => {
                                  openAlert(
                                    'error',
                                    strings['Algo salió mal al crear el pedido']
                                  );
                                  this.setState({
                                    loading: false,
                                  });
                                });
                            }

                            // Navigator.pushNamed(context, '/loading-order',arguments: dataSend);
                            // }

                            // } else if (defaultCard['cardNumber'] == null || defaultCard['cardNumber'] != 'COD') {
                            //                               // print(grandTotal()* 100);
                            //                               String code = (restaurant[0]
                            //                                   .location['country']
                            //                                   .toLowerCase() ==
                            //                                   'mexico')
                            //                                   ? 'MXN'
                            //                                   : 'USD';
                            //                               // PaymentMethod paymentMethod = PaymentMethod();
                            //                               // paymentMethod = await StripePayment.paymentRequestWithCardForm(
                            //                               //   CardFormPaymentRequest(),
                            //                               // ).then((PaymentMethod paymentMethod) {
                            //                               //   return paymentMethod;
                            //                               // }).catchError((e) {
                            //                               //   print('Error Card: ${e.toString()}');
                            //                               // });
                            //                               // paymentMethod != null
                            //                               //     ? processPaymentAsDirectCharge(paymentMethod, (grandTotal()*100).round(), code):_onBasicAlertPressed(context, 'Something went wrong when creating payment');
                            // print(defaultCard);
                            //                               // try {
                            //                               //   final response = await Dio().post(
                            //                               //     'https://devy-app.herokuapp.com/drivers/createpay/',
                            //                               //     queryParameters: {
                            //                               //       'number':
                            //                               //       defaultCard['cardNumber']
                            //                               //           .replaceAll(
                            //                               //           new RegExp(r"\s+"),
                            //                               //           ""),
                            //                               //       'month': int.parse(
                            //                               //           defaultCard['expiryDate']
                            //                               //               .split('/')[0]),
                            //                               //       'year': int.parse(
                            //                               //           defaultCard['expiryDate']
                            //                               //               .split('/')[1]),
                            //                               //       'cvv': defaultCard['cvv'],
                            //                               //       'name': defaultCard[
                            //                               //       'cardHolderName'],
                            //                               //       'custId': userData['custId']
                            //                               //           .toString(),
                            //                               //       'email': userData['email']
                            //                               //           .toString(),
                            //                               //       'phone': userData['phoneNumber']
                            //                               //           .toString(),
                            //                               //       'address': selectedLocation[
                            //                               //       'geoAddress']
                            //                               //           .toString(),
                            //                               //       'city': restaurant[0]
                            //                               //           .location['city']
                            //                               //           .toString(),
                            //                               //       'country': restaurant[0]
                            //                               //           .location['country']
                            //                               //           .toString(),
                            //                               //     },
                            //                               //     options: Options(headers: {
                            //                               //       HttpHeaders.contentTypeHeader:
                            //                               //       'application/x-www-form-urlencoded'
                            //                               //     }),
                            //                               //   );
                            //                               //   if (response.status == 200) {
                            //                               //     print(response.data['id']);
                            //                               //     if (response.data['id'] != null) {
                            //                               //       await processPaymentAsDirectCharge(
                            //                               //           response.data,
                            //                               //           (grandTotal() * 100)
                            //                               //               .round(),
                            //                               //           code);
                            //                               //     } else {
                            //                               //       _onBasicAlertPressed(context,
                            //                               //           'Algo salió al mal al crear el pago');
                            //                               //     }
                            //                               //   } else {
                            //                               //     print('failed');
                            //                               //     _onBasicAlertPressed(context,
                            //                               //         'Algo salió al mal al crear el pago');
                            //                               //   }
                            //                               // } on DioError catch (e) {
                            //                               //   print('exception $e');
                            //                               //   _onBasicAlertPressed(context,
                            //                               //       'Algo salió al mal al crear el pago');
                            //                               // }
                            //                               //Stripe.instance.createPaymentMethod(data)
                            //                               // StripePayment.createPaymentMethod(
                            //                               //   PaymentMethodRequest(
                            //                               //     card: CreditCard(
                            //                               //       number: defaultCard['cardNumber']
                            //                               //           .replaceAll(new RegExp(r"\s+"), ""),
                            //                               //       expMonth: int.parse(
                            //                               //           defaultCard['expiryDate']
                            //                               //               .split('/')[0]),
                            //                               //       expYear: int.parse(
                            //                               //           defaultCard['expiryDate']
                            //                               //               .split('/')[1]),
                            //                               //       cvc: defaultCard['cvv'],
                            //                               //       name: defaultCard['cardHolderName'],
                            //                               //
                            //                               //     ),
                            //                               //     billingAddress: BillingAddress(city: restaurant[0]
                            //                               //         .location['city'].toString(),country:restaurant[0]
                            //                               //         .location['country'].toString(),line1: selectedLocation['geoAddress'].toString(),name:defaultCard['cardHolderName']),
                            //                               //   ),
                            //                               // ).then((paymentMethod) async {
                            //                                 // try {
                            //                                 //   final response = await Dio().post(
                            //                                 //     'https://devy-app.herokuapp.com/drivers/createpay/',
                            //                                 //     queryParameters: {
                            //                                 //       'id':paymentMethod.id,
                            //                                 //       // 'number':
                            //                                 //       // defaultCard['cardNumber']
                            //                                 //       //     .replaceAll(
                            //                                 //       //     new RegExp(r"\s+"),
                            //                                 //       //     ""),
                            //                                 //       // 'month': int.parse(
                            //                                 //       //     defaultCard['expiryDate']
                            //                                 //       //         .split('/')[0]),
                            //                                 //       // 'year': int.parse(
                            //                                 //       //     defaultCard['expiryDate']
                            //                                 //       //         .split('/')[1]),
                            //                                 //       // 'cvv': defaultCard['cvv'],
                            //                                 //       'name': defaultCard[
                            //                                 //       'cardHolderName'],
                            //                                 //       'custId': userData['custId']
                            //                                 //           .toString(),
                            //                                 //       'email': userData['email']
                            //                                 //           .toString(),
                            //                                 //       'phone': userData['phoneNumber']
                            //                                 //           .toString(),
                            //                                 //       'address': selectedLocation[
                            //                                 //       'geoAddress']
                            //                                 //           .toString(),
                            //                                 //       'city': restaurant[0]
                            //                                 //           .location['city']
                            //                                 //           .toString(),
                            //                                 //       'country': restaurant[0]
                            //                                 //           .location['country']
                            //                                 //           .toString(),
                            //                                 //     },
                            //                                 //     options: Options(headers: {
                            //                                 //       HttpHeaders.contentTypeHeader:
                            //                                 //       'application/x-www-form-urlencoded'
                            //                                 //     },
                            //                                 //       receiveTimeout: 0,
                            //                                 //       followRedirects: false,
                            //                                 //     ),
                            //                                 //   );
                            //                                 //   if (response.status == 200) {
                            //                                 //     print(response.data['id']);
                            //                                 //     if (response.data['id'] != null && response.data['error']==null) {
                            //                                 //       if(mounted) {
                            //                                 //         await processPaymentAsDirectCharge(
                            //                                 //             response.data,
                            //                                 //             (grandTotal() * 100)
                            //                                 //                 .round(),
                            //                                 //             code);
                            //                                 //       }
                            //                                 //     } else {
                            //                                 //       _onBasicAlertPressed(context,
                            //                                 //           'Algo salió al mal al crear el pago');
                            //                                 //     }
                            //                                 //   } else {
                            //                                 //     print('failed');
                            //                                 //     _onBasicAlertPressed(context,
                            //                                 //         'Algo salió al mal al crear el pago');
                            //                                 //   }
                            //                                 // } on DioError catch (e) {
                            //                                 //   print('exception $e');
                            //                                 //   _onBasicAlertPressed(context,
                            //                                 //       'Algo salió al mal al crear el pago');
                            //                                 // }
                            //                                 await processPaymentAsDirectCharge(
                            //                                     defaultCard,
                            //                                     (grandTotal() * 100).round(),
                            //                                     code);
                            //                                 // _scaffoldKey.currentState.showSnackBar(SnackBar(content: Text('Received ${paymentMethod.id}')));
                            //                                 // setState(() {
                            //                                 //   _paymentMethod = paymentMethod;
                            //                                 // });
                            //                               // }).catchError((e) {
                            //                               //   _onBasicAlertPressed(context,
                            //                               //       'Algo salió al mal al crear el pago');
                            //                               //   setState(() {
                            //                               //     loading = false;
                            //                               //   });
                            //                               //   print(e);
                            //                               // });
                            //                             } else {
                            //                               await orders.add(order).then((value) async {
                            //                                 order['id'] = value.id;
                            //                                 //print('orderId ' + value.id);
                            //                                 // order['createdOn'] =
                            //                                 //     Timestamp.now().toMillis();
                            //                                 if (offerValue > 0) {
                            //
                            //                                   if(!isUsingInviteCode && !usingWallet && !isUsingSubscription) {
                            //                                     offersUsed.add({
                            //                                       'id': offerId,
                            //                                       'usedOn': Timestamp.now()
                            //                                     });
                            //                                     await users.doc(loggedInUser.uid).update({
                            //                                       'offersUsed': offersUsed,
                            //                                       'updatedOn': Timestamp.now()
                            //                                     });
                            //                                   } else if(isUsingInviteCode && !usingWallet && !isUsingSubscription) {
                            //                                     await users.doc(loggedInUser.uid).update({
                            //                                       'invitedBy': offerId,
                            //                                       'usedInviteCode': offerCode,
                            //                                       'inviteBalance':inviteBalance-offerValue,
                            //                                       'inviteAdd':inviteAdd,
                            //                                       'inviteExpire':inviteExpire,
                            //                                       'updatedOn': Timestamp.now()
                            //                                     });
                            //                                     if(canUseInviteCode) {
                            //                                       try {
                            //                                         await inviteCollection.doc(offerCode)
                            //                                             .update({'totalUse': FieldValue.increment(1),'usedBy':FieldValue.arrayUnion([loggedInUser.uid]),'updatedOn':Timestamp.now()});
                            //                                       } catch(e) {
                            //
                            //                                       }
                            //                                     }
                            //                                   } else if(!isUsingInviteCode && usingWallet && !isUsingSubscription) {
                            //                                     List walletHistory = userData['walletHistory'];
                            //                                     double walletBalance = balance - offerValue;
                            //                                     if(walletHistory==null) {
                            //                                       walletHistory = [];
                            //                                     }
                            //                                     walletHistory.add({'id':uuid.v1(),'amount': offerValue,'isCredit': false,'reason':'orden id: '+value.id.toString()+' descuento','timestamp': Timestamp.now()});
                            //                                     await users.doc(loggedInUser.uid).update({
                            //                                       'walletBalance': walletBalance,
                            //                                       'walletHistory': walletHistory,
                            //                                       'updatedOn': Timestamp.now()
                            //                                     });
                            //                                   } else if(!isUsingInviteCode && !usingWallet && isUsingSubscription) {
                            //                                     await users.doc(loggedInUser.uid).update({
                            //                                       'totalSubOrders': FieldValue.increment(1),
                            //                                       'totalSubSaving': FieldValue.increment(offerValue),
                            //                                       'updatedOn': Timestamp.now()
                            //                                     });
                            //                                   }
                            //                                 }
                            //                                 if(!isPreOrder) {
                            //                                   await sendNotification(
                            //                                       cart['restId'],
                            //                                       'Nueva orden de ' +
                            //                                           userData['fullName'],
                            //                                       'Nueva orden',
                            //                                       value.id);
                            //                                 }
                            //                                 boxOrder.clear();
                            //                                 boxCart.clear();
                            //                                 setState(() {
                            //                                   loading = false;
                            //                                 });
                            //                                 //users.doc()
                            //                                 // Navigator.pushNamedAndRemoveUntil(
                            //                                 //     context,
                            //                                 //     '/tracking',
                            //                                 //     ModalRoute.withName('/dashboard'),
                            //                                 //     arguments: value.id);
                            //                                 Navigator.pop(context);
                            //                                 Navigator.pop(context);
                            //                                 Navigator.pushNamed(context, '/tracking',
                            //                                     arguments: value.id);
                            //                               }).catchError((e) {
                            //                                 _onBasicAlertPressed(context,
                            //                                     'Algo salió al mal al crear la orden');
                            //                                 setState(() {
                            //                                   loading = false;
                            //                                 });
                            //                               });
                            //                             }
                          } else {
                            //setState(() {
                            //   restaurant.removeWhere((item) => item.id == key);
                            //   restClosed.removeWhere((item) => item.id == key);
                            //   recommended.removeWhere((item) => item.id == key);
                            //   restClosed.add(value);
                            //   //print(value.name + ' ' + value.distance.toString());
                            //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));
                            //});
                            // LazyBox boxOpen;
                            // LazyBox boxClosed;
                            // if (isStore2) {
                            //   if (Hive.isBoxOpen('displayStoreOpen')) {
                            //     boxOpen = Hive.lazyBox('displayStoreOpen');
                            //   } else {
                            //     boxOpen = await Hive.openLazyBox(
                            //         'displayStoreOpen');
                            //   }
                            //   if (Hive.isBoxOpen('displayStoreClosed')) {
                            //     boxClosed =
                            //         Hive.lazyBox('displayStoreClosed');
                            //   } else {
                            //     boxClosed = await Hive.openLazyBox(
                            //         'displayStoreClosed');
                            //   }
                            // } else {
                            //   if (Hive.isBoxOpen('displayRestOpen')) {
                            //     boxOpen = Hive.lazyBox('displayRestOpen');
                            //   } else {
                            //     boxOpen = await Hive.openLazyBox(
                            //         'displayRestOpen');
                            //   }
                            //   if (Hive.isBoxOpen('displayRestClosed')) {
                            //     boxClosed =
                            //         Hive.lazyBox('displayRestClosed');
                            //   } else {
                            //     boxClosed = await Hive.openLazyBox(
                            //         'displayRestClosed');
                            //   }
                            // }
                            // await boxOpen.delete(restaurant[0].id);
                            // await boxClosed.put(
                            //     restaurant[0].id, restaurant[0]);
                            this.setState({
                              loading: false,
                            });
                            openAlert('error', strings['El negocio está cerrado']);
                            // _onBasicAlertPressed3(context);
                          }
                        } else{
      this.setState({
        showMinimum: true,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({ showMinimum: false });
      };
    }
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: getPrimaryColor(),
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '88%',
                          boxShadow: this.state.width < 600 ? '0 4px 10px #3d485340' : 'none',
                        }}
                      >
                        {/* <div style={{padding: "10px"}}> */}
                        <Stack
                          style={{ marginTop: this.state.width > 600 ? '2px' : '0', }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '3px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <div
                                style={{
                                  padding: '1px 6px 1px',
                                  backgroundColor: '#e6ebf1',
                                  borderRadius: '7px',
                                  minWidth: '32px',
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: '20px',
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    color: '#41464a',
                                    textAlign: 'center',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {this.state.cart.totalItems.toString()}
                                </Typography>
                              </div>
                              <Typography
                                style={{
                                  fontSize: this.state.width > 600 ? '20px' : '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontFamily: 'Nunito',
                                  letterSpacing: 'norma',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width > 600 ? '18px' : '20px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                fontFamily: 'Nunito',
                                letterSpacing: 'normal',
                              }}
                            >
                              {this.state.defaultCard['cardNumber'] == null ||
                              (this.state.defaultCard['cardNumber'] !== 'COD' &&
                                this.state.defaultCard['cardNumber'] !==
                                  'CardOD')
                                ? strings['Pagar pedido ']
                                : strings['Hacer pedido ']}
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* </div> */}
                      </div>
                    </div>
                  ) : null}
                </>
              </Stack>
            </Container>
          }
        ></CartDialog>
        <AllergyDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showAllergy}
          onClose={() => {
            this.setState({ showAllergy: false });
          }}
          title={this.state.width}
          displayList={
            <>{ (this.state.showAllergyDisclaimer)?<Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergy"]}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showAllergy: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      textAlign:"center",
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px"
                    }}
                  >
                    {strings["allergyTerms1"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div className = "head-text" style={{width: `${this.state.width > 600 ? 370: this.state.width - 30}px`, height: `${(967 * ((this.state.width > 600 ? 370: this.state.width - 30) - 30)) / 3426}px`}}>
        <div>
          <img
          style={{width: `${this.state.width > 600 ? 385: this.state.width}px`,padding: "0px 15px 0px"}}
          src = {require('./assets/user-allergy.png')} alt = {"Alergia"} />
        </div>
          <div className='text-on-image'
          style={{
            padding: "0px 15px 0px",
            width: `${this.state.width > 600 ? 380: this.state.width - 20}px`,
            height: `${(967 * ((this.state.width > 600 ? 370: this.state.width - 30) - 30)) / 3426}px`,
            paddingLeft: `${(this.state.width > 600 ? 370: this.state.width - 30) * 0.37}px`,
            paddingRight: `${(this.state.width > 600 ? 370: this.state.width - 30) * 0.15}px`,
            top: this.state.width > 600 ? '3%' : '29%',
          }}>
          <Typography
                    sx={{ p: 0 }}
                    style={{
                      fontSize: `${this.state.width > 600 ? 16: (this.state.width * 0.00125  * 30)}px`,
                      fontWeight: '700',
                      color: '#fff',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyBannerTitle"]}
                  </Typography>
                  <Typography
                    sx={{ p: 0 }}
                    style={{
                      fontSize: `${this.state.width > 600 ? 16: (this.state.width * 0.00125  * 30)}px`,
                      fontWeight: '400',
                      color: '#fff',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyBannerSubTitle"]}
                  </Typography>
          </div>
      </div>
      <div style={{paddingTop: "20px"}}></div>
      <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms2"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      color: '#2e2c36',
                      fontWeight: '600',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms3"]}
                  </Typography>
                  </div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms4"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      color: '#2e2c36',
                      fontWeight: '600',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms5"]}
                  </Typography>
                  </div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms6"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px"
                    }}
                  >
                    {strings["allergyTerms7"]}
                  </Typography>
                  <div style={{paddingTop: "8px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms8"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms9"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms10"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms11"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms12"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms13"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms14"]}
                  </Typography>
                  <div style={{paddingTop: "5px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Stack
                  direction={'column'}
                  spacing={0}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms15"]}
                  </Typography>
                  <div style={{paddingTop: "10px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms16"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms17"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms18"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms19"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms20"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms21"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms22"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms23"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms24"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms25"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms26"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms27"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms28"]}
                  </Typography>
                </Stack>
                  </div>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                      {strings["allergyTerms29"]}
                  </Typography>
                  <div style={{paddingTop: "20px"}}></div>
                  <div
                      style={{
                        padding: "0px 15px 0px",
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={() => {
                          this.setState({ showAllergyDisclaimer: false });
                          try {
                          localStorage.setItem('showAllergyDisclaimer', JSON.stringify(false));
                          window.dispatchEvent(new Event("showAllergyDisclaimer"));
                          } catch(e) {}
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: getPrimaryColor(),
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%'
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '24px' : '22px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['begin']}
                            </Typography>
                          </Stack>
                          {/* <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </div>
                    </div>
                    <div style={{paddingTop: "20px"}}></div>
                </Stack>
                {/* <>
                  {!this.state.loading &&
                  this.state.cart &&
                  this.state.cart['totalCost'] != null &&
                  this.state.delivery != null &&
                  this.state.defaultCard == null &&
                  this.state.userDetails == null ? (
                    <div
                      style={{
                        position: this.state.width < 600 ? 'fixed' : 'relative',
                        left: '0px',
                        bottom: this.state.width < 600 ? '0' : '80px',
                        padding: '10px 15px 10px 5px',
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #0000001f',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '12%' }}>
                        <DeleteOutlineIcon
                          onClick={handleClearCart}
                          style={{
                            height: '24px',
                            position: 'relative',
                            top: '18px',
                            color: '#6a696e',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          // this.setState({ showAllergy: false });
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: getPrimaryColor(),
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          width: '88%',
                          cursor: 'pointer',
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['Ingresar']}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    </div>
                  ) : null} </> */}
              </Stack>
            </Container>:
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergy"]}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showAllergy: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "15px 15px 15px",
                      color: 'slategray',
                    }}
                  >
                    {strings["allergyText1"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '18px',
                      fontWeight: '800',
                      color: '#2c3136',
                      fontFamily: 'Nunito',
                      letterSpacing: 'normal',
                      padding: '0 15px',
                    }}
                  >
                    {strings['my_allergies_list_tittle']}
                  </Typography>
              {this.state.allergy?.map((allergy, i) => (
                <div style={{padding: "8px"}}>
                  <div style={{borderRadius: "10px", backgroundColor: "#f8f9fb", padding: "0px 8px 0px"}}>
                  <Stack direction={'row'} spacing={0} justifyContent="space-between" alignItems="center">
                  <Typography
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '20px',
                      width: `${this.state.width > 600 ? 350: this.state.width - 50}px`,
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      overflow: 'auto',
                      overflowWrap:'anywhere',
                      padding: "15px 15px 15px"
                    }}
                  >
                  {allergy}
                  </Typography>
                  <DeleteForeverIcon
                  onClick={
                    async()=>{
                      var temp = this.state.allergy;
                      var index = temp.indexOf(allergy);
                      if(index>=0) {
                        temp.splice(index,1);
                      }
                      // console.log(temp, index);
                            this.setState({
                              allergy: temp
                            });
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              allergy:temp,
            })
                    }
                  }
                                        style={{ color: '#e53837',cursor: 'pointer', }}
                                      />
                  </Stack></div></div>
                      ))}
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{padding: "15px 15px 15px"}}>
                  <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '9px',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                // error={
                //   !validator.isEmail(this.state.email) &&
                //   this.state.email.length > 0
                // }
                fullWidth
                id="filled-basicallergyAdd"
                label={strings["allergy_holder"]}
                variant="filled"
                value={this.state.newAllergy}
                onChange={(event) => {
                  this.setState({ newAllergy: event.target.value });
                }}
              /></div>
                                <div style={{paddingTop: "20px"}}></div>
                  <div
                      style={{
                        padding: "0px 15px 0px",
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={async() => {
                          if(this.state.newAllergy && this.state.newAllergy!=='') {
                            var temp = this.state.allergy;
                            temp.push(this.state.newAllergy);
                            this.setState({
                              newAllergy: '',
                              allergy: temp
                            });
                            try {
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              allergy:temp,
            })
          } catch(e) {
            console.log(e);
          }
                          }
                          // this.setState({ showAllergy: false });
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: getPrimaryColor(),
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%'
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                                marginTop: this.state.width < 600 ? '0' : '3px',
                              }}
                            >
                              {strings['allergyAdd']}
                            </Typography>
                          </Stack>
                          {/* <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </div>
                    </div>
                    <div style={{paddingTop: "20px"}}></div>
                </Stack>
              </Stack>
            </Container>
            }</>
          }
        ></AllergyDialog>
        <TrackDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showTracking}
          onClose={() => {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }}
          title={this.state.width}
          displayList={
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
              style={{ background: '#ffffff' }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width < 600 ? '24px' : '18px',
                      fontWeight: '800',
                      color: '#2c3136',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      paddingLeft: '10px',
                    }}
                  >
                    {strings['Detalles del pedido']}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({
                        showTracking: false,
                        trackingDetails: null,
                        trackingId: null,
                      });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <>
                  {this.state.trackingDetails ? (
                    <Stack
                      direction={'column'}
                      spacing={0}
                      style={{
                        height: isIOS
                          ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                          : 'calc(100vh - 50px)',
                        overflow: 'auto',
                      }}
                    >
                      <div style={{ height: '300px' }}>
                        <div style={{ height: '300px', width: '100%' }}>
                          <GoogleMapReact
                            defaultCenter={{
                              lat: this.state.trackingDetails.userLocation
                                .latitude,
                              lng: this.state.trackingDetails.userLocation
                                .longitude,
                            }}
                            defaultZoom={this.state.defaultProps.zoom}
                            resetBoundsOnResize={true}
                            options={{
                              fullscreenControl: false,
                              scrollwheel: false,
                              panControl: false,
                              zoomControl: false,
                              rotateControl: false,
                            }}
                            bootstrapURLKeys={{
                              key: constants.googleMapsAPI,
                            }}
                            style={{
                              height: '300px',
                              padding: '8px',
                              borderRadius: '10px',
                            }}
                            onChange={(changeData) => {
                              // console.log(changeData);
                              // this.setState({
                              //   defaultProps: {
                              //     center: {
                              //       lat: changeData.center.lat,
                              //       lng: changeData.center.lng,
                              //     },
                              //     zoom: changeData.zoom,
                              //   },
                              //   selectLocationData: {
                              //     id: this.state.selectLocationData.id,
                              //     name: this.state.selectLocationData.name,
                              //     address: this.state.selectLocationData.address,
                              //     geoAddress: this.state.selectLocationData.geoAddress,
                              //     addressNotes:
                              //       this.state.selectLocationData.addressNotes,
                              //     latitude: changeData.center.lat,
                              //     longitude: changeData.center.lng,
                              //   },
                              // });
                            }}
                          >
                            {/* <div
    style={{  display: "flex",
width: "50px",
opacity: "0.5",
height: "50px",
backgroundColor: "green",
borderRadius: "50%"}}
    lat={this.state.defaultCircleProps.center.lat}
      lng={this.state.defaultCircleProps.center.lng}
  /> */}
                            {/* <HomeIcon
                              style={{
                                color: getPrimaryColor(),
                                transform: 'translate(-50%, -100%)',
                              }}
                              fontSize={'large'}
                              lat={
                                this.state.trackingDetails.userLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.userLocation
                                  .longitude
                              }
                              // text={'Google Map'}
                            /> */}
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              style={{
                                fontSize: '50px',
                                padding: '6px',
                                transform: 'translate(-50%, -100%)',
                              }}
                              lat={
                                this.state.trackingDetails.userLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.userLocation
                                  .longitude
                              }
                            >
                              <rect
                                id="Rectangle-6_1_"
                                x="0.4"
                                y="0.4"
                                className="st0"
                                width="511.2"
                                height="511.2"
                              />
                              <g>
                                <path
                                  id="path-1_1_"
                                  className="st4"
                                  d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1s-334.3-46.8-379.5,0
                                              s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5C270.7,511.2,295.4,471.1,309.4,446.1z"
                                />
                              </g>
                              <path
                                className="st5"
                                d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                            c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                            c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                            c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                            C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                            c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                            c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                            c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                            C414.5,33.6,341.1,21.6,256,21.6z"
                              />
                              <path
                                className="st3"
                                d="M231.4,324.2c-7.2,0-14.1-3.1-18.9-8.5l-67.6-76c-9.3-10.5-8.3-26.5,2.1-35.8c10.5-9.3,26.5-8.4,35.8,2.1
                                            l46.5,52.3l108.2-151.5c8.1-11.4,23.9-14,35.3-5.9c11.4,8.1,14,24,5.9,35.3L252.1,313.6c-4.5,6.3-11.6,10.2-19.3,10.6
                                            C232.3,324.2,231.9,324.2,231.4,324.2z"
                              />
                            </svg>
                            {/* <StoreMallDirectoryIcon
                              style={{
                                color: getPrimaryColor(),
                                transform: 'translate(-50%, -100%)',
                              }}
                              fontSize={'large'}
                              lat={
                                this.state.trackingDetails.restLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.restLocation
                                  .longitude
                              }
                              // text={'Google Map'}
                            /> */}
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              style={{
                                fontSize: '50px',
                                padding: '6px',
                                transform: 'translate(-50%, -100%)',
                              }}
                              lat={
                                this.state.trackingDetails.restLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.restLocation
                                  .longitude
                              }
                            >
                              <rect
                                id="Rectangle-6"
                                x="0.4"
                                y="0.4"
                                className="st0"
                                width="511.2"
                                height="511.2"
                              />
                              <g>
                                <path
                                  id="path-1"
                                  className="st1"
                                  d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1s-334.3-46.8-379.5,0
                                          s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5C270.7,511.2,295.4,471.1,309.4,446.1z"
                                />
                              </g>
                              <path
                                className="st2"
                                d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                        c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                        c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                        c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                        C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                        c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                        c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                        c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                        C414.5,33.6,341.1,21.6,256,21.6z"
                              />
                              <path
                                className="st3"
                                d="M386.5,182.8c-0.2-0.5-0.5-0.9-0.8-1.3l-31.9-39.6v-54c0-3.9-3.1-7-7-7H166.3c-3.9,0-7,3.1-7,7v50.9L125,183.9
                                        c-0.4,0.5-0.7,1-0.9,1.6c-0.3,0.7-6.4,16.1,1.7,28.3c4.6,6.9,12.6,11,23.7,12.1v120.2c0,3.9,3.1,7,7,7h200.7c3.9,0,7-3.1,7-7V225.7
                                        c10.7-1.5,18.2-5.6,22.5-12.4C394.9,200.2,386.9,183.5,386.5,182.8z M231.6,339.2v-84.6h56.9v84.6H231.6z M263.8,212.2v-62.6h17.4
                                        l16.8,62.6C287.2,212.2,275.6,212.2,263.8,212.2z M312.5,212.2l-16.8-62.6h16.9l33.6,62.6C338.9,212.2,327.8,212.2,312.5,212.2z
                                        M167.3,212.3l33.6-62.7h16.9L201,212.3C188,212.3,176.4,212.3,167.3,212.3z M215.5,212.2l16.8-62.6h17.6v62.6
                                        C238.2,212.2,226.5,212.2,215.5,212.2z M217.6,247.6v91.6h-54V226.3h186.7v112.9h-47.7v-91.6c0-3.9-3.1-7-7-7h-70.9
                                        C220.7,240.6,217.6,243.7,217.6,247.6z M137.4,206L137.4,206c-3.3-4.9-1.3-12.4-0.6-14.5l31.8-41.9h16.4l-33.5,62.5
                                        C144.5,211.5,139.7,209.4,137.4,206z M339.8,94.8v40.8H173.3V94.8H339.8z M342.1,149.6l32.1,39.9c0.9,2.2,4.1,10.8,0.7,16.3
                                        c-2.1,3.2-6.4,5.3-13,6.1l-33.4-62.3H342.1z"
                              />
                            </svg>
                            <>
                              {this.state.trackingDetails.driverAssigned ? (
                                // <DeliveryDiningIcon
                                //   style={{
                                //     color: getPrimaryColor(),
                                //     transform: 'translate(-50%, -100%)',
                                //   }}
                                //   fontSize={'large'}
                                //   lat={
                                //     this.state.trackingDetails.driverLocation
                                //       .latitude
                                //   }
                                //   lng={
                                //     this.state.trackingDetails.driverLocation
                                //       .longitude
                                //   }
                                //   // text={'Google Map'}
                                // />
                                <svg
                                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                  style={{
                                    fontSize: '50px',
                                    padding: '6px',
                                    transform: 'translate(-50%, -100%)',
                                  }}
                                  lat={
                                    this.state.trackingDetails.driverLocation
                                      .latitude
                                  }
                                  lng={
                                    this.state.trackingDetails.driverLocation
                                      .longitude
                                  }
                                >
                                  <rect
                                    id="Rectangle-6_4_"
                                    x="0.4"
                                    y="0.4"
                                    className="st0"
                                    width="511.2"
                                    height="511.2"
                                  />
                                  <g>
                                    <g>
                                      <path
                                        id="path-1_12_"
                                        className="st6"
                                        d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1
                                                  s-334.3-46.8-379.5,0s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5
                                                  C270.7,511.2,295.4,471.1,309.4,446.1z"
                                      />
                                    </g>
                                  </g>
                                  <path
                                    className="st7"
                                    d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                              c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                              c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                              c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                              C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                              c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                              c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                              c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                              C414.5,33.6,341.1,21.6,256,21.6z"
                                  />
                                  <path
                                    className="st3"
                                    d="M168.9,319.9c-1.6,0-3.1-0.1-4.7-0.3c-23.9-2.9-39.5-22.4-37-46.5c0-0.1,0-0.2,0.1-0.4h-7.8
                                              c-2.2,0-4.2-1.2-5.3-3.1l-15-26.2c-1.3-2.3-1-5.2,0.8-7.1l42.7-46.8c1.2-1.3,2.8-2,4.5-2h58.1c3.4,0,6.1,2.7,6.1,6.1
                                              s-2.7,6.1-6.1,6.1h-55.4l-37.9,41.6l11.1,19.3h172.5c7.9-13.9,23.7-39.9,32.5-43.9c3-1.4,6.7-0.1,8.1,3c1.4,3.1,0,6.7-3,8
                                              c-4.4,2.3-18.4,23.5-28.7,41.9c-1.1,1.9-3.1,3.1-5.3,3.1H139.2c0.1,0.5,0.1,1,0.1,1.6c-1.7,17.2,9.3,31.1,26.4,33.2
                                              c12.8,1.6,25.1-6.2,30.4-19.3c1.3-3.1,4.8-4.6,7.9-3.3c3.1,1.3,4.6,4.8,3.4,7.9C200.5,309.5,185.5,319.9,168.9,319.9z M370.3,319.9
                                              c-21.5,0-40-16.7-43-38.8c-0.5-3.3,1.9-6.4,5.2-6.9c3.2-0.4,6.4,1.9,6.9,5.2c2.2,16.1,15.5,28.3,31,28.3c17.3,0,31.3-14.8,31.3-33.1
                                              c0-5.4-1.2-10.7-3.6-15.4c-1.5-3-0.3-6.7,2.7-8.2c3-1.5,6.7-0.3,8.2,2.7c3.2,6.4,4.9,13.6,4.9,20.8
                                              C413.8,299.6,394.3,319.9,370.3,319.9z M335.6,268.4h-19.5c-3.4,0-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1h15.8c3.9-7,14.2-21.6,30.7-21.6
                                              c13.9,0,22.9,1.4,26.4,2l4.8-1.5c-3.7-6.7-12.9-18.1-34.8-24.3c-0.2-0.1-0.4-0.1-0.6-0.2c-2.6,1-5.7,0.2-7.3-2.3
                                              c0,0-45.2-66.9-46.5-68.9c-1.2-1.7-1.4-3.9-0.6-5.9c0.8-1.9,2.5-3.3,4.6-3.7l6.6-1.1l-0.6-13.6l-4.1-1l-19.3,13.5
                                              c-1,0.7-2.2,1.1-3.5,1.1l-23.1,0.1c0,0,0,0,0,0c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1l21.2,0l20-14c1.4-1,3.2-1.3,4.9-0.9
                                              l11.2,2.7c2.6,0.6,4.5,3,4.6,5.7l1,23.6c0.1,3.1-2,5.7-5.1,6.2l-2.5,0.4c10.1,15,31.6,46.8,39.6,58.6c0.9-0.2,1.9-0.1,2.8,0.1
                                              c38.9,11.1,45.3,37.4,45.6,38.5c0.7,3.1-1.1,6.2-4.1,7.2l-12.6,3.9c-1,0.3-2.2,0.4-3.2,0.1c-0.1,0-8.9-2-25.3-2
                                              c-13.3,0-21.3,17.7-21.4,17.9C340.2,267,338.1,268.4,335.6,268.4z M276,242.7h-63c-2.3,0-4.4-1.3-5.5-3.4c-1-2.1-0.8-4.6,0.7-6.4
                                              l27.3-35.3c1.2-1.5,2.9-2.5,5-2.4l5.6,0.1c0.5-5.3-1.1-15-15.9-23.8l-91.6-0.4c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.5-6.2,6.1-6.1
                                              l93.2,0.4c1,0,2,0.3,2.9,0.8c25.8,14.4,25.4,34.9,21.7,43.8c-1,2.3-3.3,3.7-5.7,3.7l-7.4-0.2l-17.8,23H273l27.3-37.3l-20.9-36.6
                                              c-1.7-2.9-0.7-6.6,2.3-8.3c2.9-1.7,6.7-0.6,8.3,2.3l22.9,40.1c1.2,2.1,1,4.7-0.4,6.6l-31.5,42.9C279.8,241.7,278,242.7,276,242.7z"
                                  />
                                </svg>
                              ) : null}
                            </>
                          </GoogleMapReact>
                          <>
                            {!this.state.trackingDetails.acceptedByRest ? (
                              <div
                                style={{
                                  position: 'relative',
                                  bottom: '290px',
                                  left: '10px',
                                  width: 'fit-content',
                                }}
                              >
                                <Typography
                                  onClick={async () => {
                                    this.setState({ loading: true });
                                    await axios
                                      .post(
                                        'https://senor-order-85fa55d895ba.herokuapp.com/drivers/cancelorder/',
                                        null,
                                        {
                                          params: {
                                            id: this.state.trackingId,
                                          },
                                        }
                                      )
                                      .then(async (response) => {
                                        this.setState({ loading: false });
                                        handleAlertCancelOpen();
                                        // console.log(response);
                                      })
                                      .catch((e) => {
                                        this.setState({ loading: false });
                                      });
                                  }}
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#D62B2B',
                                    cursor: 'pointer',
                                    padding: '0 8px',
                                    background: 'white',
                                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                    borderRadius: '7px',
                                  }}
                                >
                                  {strings['Cancelar pedido']}
                                </Typography>
                              </div>
                            ) : null}
                          </>
                          <>
                            {!this.state.trackingDetails.delivered ? (
                              <div
                                style={{
                                  position: 'relative',
                                  bottom: '200px',
                                  right: '10px',
                                  width: '210px',
                                  float: 'right',
                                }}
                              >
                                <div style={{ padding: '15px' }}>
                                  <div
                                    style={{
                                      backgroundColor: '#fff',
                                      borderRadius: '20px',
                                      height: '120px',
                                      boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                                      padding: '10px',
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="center"
                                    >
                                      <>
                                        {this.state.trackingDetails != null &&
                                        (this.state.trackingDetails[
                                          'isPickup'
                                        ] == null ||
                                          !this.state.trackingDetails[
                                            'isPickup'
                                          ]) ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Pedido número']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['ID del pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['number']
                                          ? this.state.trackingDetails[
                                              'number'
                                            ].toString()
                                          : ''}
                                      </Typography>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Divider />
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        (this.state.trackingDetails[
                                          'isPickup'
                                        ] == null ||
                                          !this.state.trackingDetails[
                                            'isPickup'
                                          ]) ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Entrega estimada']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Recogida estimada']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {moment(this.state.trackingDetails['createdOn']
                                          .toDate()).add(Math.trunc(
                                              this.state.trackingDetails[
                                                'cookingTime'
                                              ]
                                                ? this.state.trackingDetails[
                                                    'cookingTime'
                                                  ]
                                                : 0
                                            ) +
                                            15, 'minutes').format('hh:mm a').toString()+" - "+moment(this.state.trackingDetails['createdOn']
                                          .toDate()).add(Math.trunc(
                                              this.state.trackingDetails[
                                                'cookingTime'
                                              ]
                                                ? this.state.trackingDetails[
                                                    'cookingTime'
                                                  ]
                                                : 0
                                            ) +
                                            25, 'minutes').format('hh:mm a').toString()}
                                      </Typography>
                                    </Stack>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: '10px 20px 10px',
                          marginTop: '-38px',
                          zIndex: '9',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ffffff87',
                            boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                            borderRadius: '17px',
                            padding: '12px 16px 12px 16px',
                            backdropFilter: 'blur(5px)',
                          }}
                        >
                          <Stack
                            direction={'column'}
                            spacing={0}
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '50%',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '2px',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <BusinessSvg
                                      viewBox="-20 -40 550 550"
                                      style={{
                                        border: `2px solid #4dd884`,
                                        borderRadius: '50%',
                                        width: 'calc(100%)',
                                        padding: '6px',
                                        fontSize: '50px',
                                      }}
                                    />
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['acceptedByRest'] ||
                        this.state.trackingDetails['delivered'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </> */}
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor: getPrimaryColor(),
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'acceptedByRest'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border: this.state.trackingDetails[
                                        'acceptedByRest'
                                      ]
                                        ? `0px solid ${getPrimaryColor()}`
                                        : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    <StoreMallDirectoryIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails[
                                          'acceptedByRest'
                                        ]
                                          ? getPrimaryColor()
                                          : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails[
                                          'acceptedByRest'
                                        ]
                                          ? getPrimaryColor()
                                          : '#C4C1C0',
                                      }}
                                    />
                                    {/* <img
                    src={this.state.trackingDetails['restLogo']}
                    alt="logo"
                    // width={'50px'}
                    style={{ borderRadius: '50%', width: "calc(100%)" }}
                  /> */}
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['driverReached'] ||
                        this.state.trackingDetails['delivered'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </> */}
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'driverAssigned'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'driverReached'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border: this.state.trackingDetails[
                                        'driverReached'
                                      ]
                                        ? `0px solid ${getPrimaryColor()}`
                                        : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    <DeliveryDiningIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails[
                                          'driverReached'
                                        ]
                                          ? getPrimaryColor()
                                          : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails[
                                          'driverReached'
                                        ]
                                          ? getPrimaryColor()
                                          : '#C4C1C0',
                                      }}
                                    />
                                    {/* <img
                    src={this.state.trackingDetails['restLogo']}
                    alt="logo"
                    // width={'50px'}
                    style={{ borderRadius: '50%', width: "calc(100%)" }}
                  /> */}
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['delivered'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </> */}

                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'orderPickedUp'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? getPrimaryColor()
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor: this.state
                                          .trackingDetails['delivered']
                                          ? getPrimaryColor()
                                          : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border:
                                        this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails['cancelled']
                                          ? `0px solid ${getPrimaryColor()}`
                                          : this.state.trackingDetails[
                                              'delivered'
                                            ] &&
                                            this.state.trackingDetails[
                                              'cancelled'
                                            ]
                                          ? '0px solid #D62B2B'
                                          : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '2px',
                                    }}
                                  >
                                    {/* <HomeIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails['delivered'] && !this.state.trackingDetails['cancelled']  ? getPrimaryColor()  : this.state.trackingDetails['delivered'] &&  this.state.trackingDetails['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                        color: this.state.trackingDetails['delivered'] &&  !this.state.trackingDetails['cancelled']  ? getPrimaryColor()  : this.state.trackingDetails['delivered'] && this.state.trackingDetails['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                        padding: '6px',
                                      }}
                                    /> */}
                                    <WhereToVoteIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor:
                                          this.state.trackingDetails[
                                            'delivered'
                                          ] &&
                                          !this.state.trackingDetails[
                                            'cancelled'
                                          ]
                                            ? getPrimaryColor()
                                            : this.state.trackingDetails[
                                                'delivered'
                                              ] &&
                                              this.state.trackingDetails[
                                                'cancelled'
                                              ]
                                            ? '#D62B2B'
                                            : '#C4C1C0',
                                        color:
                                          this.state.trackingDetails[
                                            'delivered'
                                          ] &&
                                          !this.state.trackingDetails[
                                            'cancelled'
                                          ]
                                            ? getPrimaryColor()
                                            : this.state.trackingDetails[
                                                'delivered'
                                              ] &&
                                              this.state.trackingDetails[
                                                'cancelled'
                                              ]
                                            ? '#D62B2B'
                                            : '#C4C1C0',
                                        padding: '6px',
                                      }}
                                    />

                                    {/* <img
                    src={this.state.trackingDetails['restLogo']}
                    alt="logo"
                    // width={'50px'}
                    style={{ borderRadius: '50%', width: "calc(100%)" }}
                  /> */}
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['delivered'] && !this.state.trackingDetails['cancelled'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </>
                      <>
                      {
                        (this.state.trackingDetails['delivered'] && this.state.trackingDetails['cancelled'])?<CancelIcon style={{color: "#D62B2B", fontSize: "28px"}} />:null
                      }
                      </> */}
                              {/* <>{
      <div style={{width: "calc(100% / 16 - 5px)",height: "6px"}}>
        <Divider style={{borderBottomWidth: "6px", color: (this.state.trackingDetails['orderPickedUp'] || this.state.trackingDetails['delivered'])?"#4DD884":"#C4C1C0"}} />
      </div>
    }
    </>
    <>{
      <div style={{width: "calc(100% / 16 - 5px)",height: "6px"}}>
        <Divider style={{borderBottomWidth: "6px", color: (this.state.trackingDetails['delivered'])
                                          ?"#4DD884":"#C4C1C0"}} />
      </div>
    }
    </> */}
                            </Stack>
                            <div style={{ padding: '15px 0px 0px' }}></div>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#2e3436',
                                textAlign: 'center',
                              }}
                            >
                              {getStat(this.state.trackingDetails)}
                            </Typography>
                            <div style={{ padding: '15px 0px 0px' }}></div>
                            <Stack
                              onClick={() => {
                                this.setState({
                                  toggleDetails: !this.state.toggleDetails,
                                });
                              }}
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  color: '#7E7D81',
                                  cursor: 'pointer',
                                }}
                              >
                                {strings['Ver más']}
                              </Typography>
                              <div style={{ padding: '0px 2px 0px' }}></div>
                              <>
                                {!this.state.toggleDetails ? (
                                  <KeyboardArrowDownIcon
                                    style={{
                                      fontSize: '24px',
                                      fontWeight: 'normal',
                                      color: '#7E7D81',
                                      cursor: 'pointer',
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    style={{
                                      fontSize: '24px',
                                      fontWeight: 'normal',
                                      color: '#7E7D81',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </>
                            </Stack>
                            <div style={{ padding: '5px 0px 0px' }}></div>
                            <>
                              {this.state.toggleDetails ? (
                                <div>
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['Esperando a que el negocio acepte su pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['Te avisaremos una vez que el negocio acepte tu pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['El negocio está preparando tu pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido está siendo preparado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' está en camino para recoger tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' está en camino para recoger tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' llegó al negocio']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha llegado al negocio y recogerá tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha recogido tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha recogido tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido entregado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido entregado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido cancelado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'reason'
                                            ] == null
                                              ? strings['Tu pedido ha sido cancelado']
                                              : this.state.trackingDetails[
                                                  'reason'
                                                ]}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Divider
                                            style={{
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '600',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu ubicación']}:{' '}
                                            <span
                                              style={{
                                                color: 'slategray',
                                                fontWeight: '400',
                                              }}
                                            >
                                              {
                                                this.state.trackingDetails[
                                                  'userLocation'
                                                ]['geoAddress']
                                              }
                                            </span>
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                    </Stack>
                                  </Stack>
                                </div>
                              ) : null}
                            </>
                          </Stack>
                        </div>
                      </div>
                      <>
                        {!this.state.trackingDetails['delivered'] ? (
                          <div
                            style={{
                              padding: '10px 10px 10px',
                              borderTop: '0px solid #d8d8d8',
                              marginTop: '10px',
                              width: '90%',
                              marginLeft: '5%',
                              background: 'white',
                              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                              borderRadius: '0px 16px 16px 16px',
                              marginBottom: '15px',
                            }}
                          >
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="center"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <div
                                    style={{
                                      borderRadius: '10px',
                                      width: 'calc(15%)',
                                      paddingTop: '10px',
                                    }}
                                  >
                                    <div style={{ padding: '1.75px' }}>
                                      <img
                                        src={
                                          this.state.trackingDetails['restLogo']
                                        }
                                        alt="logo"
                                        // width={'50px'}
                                        style={{
                                          borderRadius: '12px',
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: '5px 15px 0px',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '17px',
                                          fontWeight: '600',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['restName']}
                                      </Typography>
                                      <Stack
                                        direction={'row'}
                                        spacing={0}
                                        alignItems="center"
                                      >
                                        <StarIcon
                                          style={{
                                            fontSize: '16px',
                                            color: getPrimaryColor(),
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {' ' +
                                            this.state.businessData[
                                              'averageRating'
                                            ].toFixed(2)}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Stack>
                                <div style={{ padding: '0px 5px 0px' }}>
                                  <div
                                    onClick={() => {
                                      window.open(
                                        'tel://' +
                                          this.state.trackingDetails[
                                            'restContact'
                                          ],
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      width: '34px',
                                      height: '34px',
                                      backgroundColor: '#33343b',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      padding: '4px 6px 4px 8px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <LocalPhoneIcon
                                      style={{
                                        fontSize: '19px',
                                        color: '#fff',
                                      }}
                                    />
                                  </div>
                                </div>
                              </Stack>
                            </Stack>
                          </div>
                        ) : null}
                      </>

                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <>
                        {this.state.trackingDetails['driverAssigned'] &&
                        !this.state.trackingDetails['delivered'] ? (
                          <div style={{ padding: '5px 10px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="center"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <div
                                    style={{
                                      backgroundColor: '#f8f9fb',
                                      boxShadow: '0px 0px 5px #aaa',
                                      borderRadius: '100px',
                                      width: 'calc(12%)',
                                    }}
                                  >
                                    <div style={{ padding: '1.75px' }}>
                                      <img
                                        src={
                                          this.state.trackingDetails[
                                            'driverAvatar'
                                          ]
                                        }
                                        alt="logo"
                                        // width={'50px'}
                                        style={{
                                          borderRadius: '100px',
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: '5px 15px 0px',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '18px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {
                                          this.state.trackingDetails[
                                            'driverName'
                                          ]
                                        }
                                      </Typography>
                                      <Stack
                                        direction={'row'}
                                        spacing={0}
                                        alignItems="center"
                                      >
                                        <StarIcon
                                          style={{
                                            fontSize: '18px',
                                            color: getPrimaryColor(),
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {' ' +
                                          this.state.trackingDetails[
                                            'driverRating'
                                          ]
                                            ? this.state.trackingDetails[
                                                'driverRating'
                                              ].toFixed(2)
                                            : ''}
                                        </Typography>
                                        <div
                                          style={{ padding: '0px 7.5px 0px' }}
                                        ></div>
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {strings['Nivel: '] +
                                          this.state.trackingDetails[
                                            'driverLevel'
                                          ]
                                            ? this.state.trackingDetails[
                                                'driverLevel'
                                              ].toString()
                                            : '1'}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Stack>
                                <div style={{ padding: '0px 5px 0px' }}>
                                  <div
                                    onClick={() => {
                                      window.open(
                                        'tel://' +
                                          this.state.trackingDetails[
                                            'driverContact'
                                          ],
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      width: '45px',
                                      height: '45px',
                                      backgroundColor: constants.secondaryColor,
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      padding: '4px 6px 4px',
                                    }}
                                  >
                                    <LocalPhoneIcon
                                      style={{
                                        fontSize: '27px',
                                        color: '#fff',
                                      }}
                                    />
                                  </div>
                                </div>
                              </Stack>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <div style={{ padding: '15px 0px 0px' }}></div>
                      <Divider />
                      <>
                        {' '}
                        {this.state.trackingDetails != null &&
                        (this.state.trackingDetails['isPickup'] == null ||
                          !this.state.trackingDetails['isPickup']) ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Dirección de entrega']}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                              >
                                {
                                  this.state.trackingDetails['userLocation'][
                                    'geoAddress'
                                  ]
                                }
                              </Typography>
                              <>
                                {this.state.trackingDetails['userLocation'][
                                  'addressNotes'
                                ] != null &&
                                this.state.trackingDetails['userLocation'][
                                  'addressNotes'
                                ] !== '' ? (
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#6A6A6E',
                                    }}
                                  >
                                    {
                                      this.state.trackingDetails[
                                        'userLocation'
                                      ]['addressNotes']
                                    }
                                  </Typography>
                                ) : null}
                              </>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <>
                        {' '}
                        {this.state.trackingDetails != null &&
                        this.state.trackingDetails['isPickup'] != null &&
                        this.state.trackingDetails['isPickup'] ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Para recoger tu pedido debes dirigirte a:']}
                              </Typography>
                              <Typography
                                onClick={() => {
                                  window.open(
                                    'https://www.google.com/maps/search/?api=1&query=' +
                                      this.state.businessData.location.latitude.toString() +
                                      '%2C' +
                                      this.state.businessData.location.longitude.toString(),
                                    '_blank'
                                  );
                                }}
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                              >
                                {
                                  this.state.trackingDetails['restLocation'][
                                    'address'
                                  ]
                                }
                              </Typography>
                              {/* <>
                  {
                    (this.state.trackingDetails['userLocation']
                                                  ['addressNotes'] !=
                                              null &&
                                              this.state.trackingDetails['userLocation']
                                                  ['addressNotes'] !==
                                              '')? <Typography
                    style={{
                      fontSize: '15px',
                      fontWeight: 'normal',
                      color: '#6A6A6E',
                    }}
                  >

{this.state.trackingDetails['userLocation']['addressNotes']}
                  </Typography>:null
                  }
                  </> */}
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Método de pago']}
                          </Typography>
                          <>
                            {!this.state.trackingDetails['onlinePayment'] ? (
                              <Stack direction={'row'} spacing={0}>
                                <>
                                  {this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] != null &&
                                  this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] ? (
                                    <CreditCardIcon
                                      style={{
                                        fontWeight: 'normal',
                                        color: '#c96812',
                                      }}
                                    />
                                  ) : (
                                    <MoneyIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#54bd66',
                                        marginTop: '2px',
                                      }}
                                    />
                                  )}
                                </>
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <>
                                  {this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] != null &&
                                  this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] ? (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Tarjeta contra entrega']}
                                      </Typography>
                                    </>
                                  ) : (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Efectivo']}
                                      </Typography>
                                    </>
                                  )}
                                </>
                                <div style={{ padding: '0px 5.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: '#6A6A6E',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {this.state.trackingDetails['onlinePayment'] ? (
                              <Stack direction={'row'} spacing={0}>
                                <CreditCardIcon
                                  style={{
                                    fontWeight: 'normal',
                                    color: '#2c59a8',
                                  }}
                                />
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Tarjeta']}
                                </Typography>
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: '#6A6A6E',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                        </Stack>
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Día y hora de su pedido']}
                          </Typography>
                          <Stack
                            direction={'row'}
                            spacing={0}
                            alignItems="center"
                          >
                            <AccessTimeFilledIcon
                              style={{
                                fontSize: '20px',
                                fontWeight: 'normal',
                                color: '#4b596a',
                              }}
                            />{' '}
                            <div style={{ padding: '0px 2.5px 0px' }}></div>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#000',
                              }}
                            >
                              {moment(this.state.trackingDetails['createdOn']
                                .toDate()).add((this.state.trackingDetails['preOrderedOn']?40:0),'minutes').format('DD-MM-YYYY hh:mm a').toString()}
                              {/* {this.state.trackingDetails['createdOn']
                                .toDate()
                                .getDate()
                                .toString() +
                                '-' +
                                (
                                  this.state.trackingDetails['createdOn']
                                    .toDate()
                                    .getMonth() + 1
                                ).toString() +
                                '-' +
                                this.state.trackingDetails['createdOn']
                                  .toDate()
                                  .getFullYear()
                                  .toString() +
                                ' ' +
                                this.state.trackingDetails['createdOn']
                                  .toDate()
                                  .getHours()
                                  .toString() +
                                ':' +
                                this.state.trackingDetails['createdOn']
                                  .toDate()
                                  .getMinutes()
                                  .toString()} */}
                            </Typography>
                          </Stack>
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        </Stack>
                      </div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Enviar cubiertos']}
                              </Typography>
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                              >
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {this.state.trackingDetails['giveSpoon'] !=
                                    null &&
                                  this.state.trackingDetails['giveSpoon']
                                    ? strings['Sí']
                                    : strings['No']}
                                </Typography>
                              </Stack>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '0px 20px 0px' }}>
                            <Divider />
                          </div>
                        ) : null}
                      </>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        ) : null}
                      </>

                      <div style={{ padding: '5px 20px 5px', width: '100%' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Detalles del pedido:']}
                          </Typography>
                          <List style={{ width: '100%' }}>
                            {this.state.trackingDetails['items'].map(
                              (customize, index) => (
                                <div
                                  style={{
                                    padding: '5px 0px 0px',
                                    width: '100%',
                                  }}
                                  key={index}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['items'][
                                          index
                                        ]['quantity'].toString() +
                                          ' X ' +
                                          (this.state.trackingDetails['items'][
                                            index
                                          ]['nameEng'] != null
                                            ? this.state.trackingDetails[
                                                'items'
                                              ][index]['nameEng']
                                            : this.state.trackingDetails[
                                                'items'
                                              ][index]['name'])}
                                      </Typography>
                                      <div style={{ padding: '3px 0px 0px' }}>
                                        <List>
                                          {this.state.trackingDetails['items'][
                                            index
                                          ]['customize'].map((customize, t) => (
                                            <React.Fragment key={t}>
                                              {this.state.trackingDetails[
                                                'items'
                                              ][index]['customize'][t][
                                                'options'
                                              ].length > 0 ? (
                                                <Typography
                                                  style={{
                                                    fontSize: '13px',
                                                    fontWeight: 'normal',
                                                    color: 'slategray',
                                                  }}
                                                >
                                                  {findTotalT(
                                                    index,
                                                    t,
                                                    this.state.trackingDetails
                                                  )}
                                                </Typography>
                                              ) : null}
                                            </React.Fragment>
                                          ))}
                                        </List>
                                      </div>
                                    </Stack>
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-end"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {'$' +
                                          Number(
                                            this.state.trackingDetails['items'][
                                              index
                                            ]['price'] *
                                              this.state.trackingDetails[
                                                'items'
                                              ][index]['quantity']
                                          )
                                            .toFixed(2)
                                            .toString()}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </div>
                              )
                            )}
                            {(this.state.trackingDetails['removedItems']==null && this.state.trackingDetails['selectedOffer']!=null && this.state.trackingDetails['selectedOffer']['id']!=null && this.state.trackingDetails['selectedOffer']['isFreeItem']!=null && this.state.trackingDetails['selectedOffer']['isFreeItem'])?
                            <div
                                  style={{
                                    padding: '5px 0px 0px',
                                    width: '100%',
                                  }}
                                  key={this.state.trackingDetails['items'].length}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {'1 X ' +
                                          (this.state.trackingDetails['selectedOffer']['freeItems'][0]
                                                      ['name'])}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-end"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Gratis']}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </div>:null}
                          </List>
                          <div style={{ padding: '10px 0px 0px' }}></div>
                          {/* <div style={{padding: "0px 20px 0px"}}>
                    <Divider />
                   </div> */}
                        </Stack>
                      </div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '10px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px', width: '100%' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                        >
                          <div style={{ width: '100%' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                            >
                              <div
                                style={{
                                  padding: '0px 0px 5px',
                                  width: '100%',
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#33343a',
                                    }}
                                  >
                                    {strings['Total parcial']}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#33343a',
                                    }}
                                  >
                                    {'$' +
                                      this.state.trackingDetails[
                                        'itemCost'
                                      ].toFixed(2)}
                                  </Typography>
                                </Stack>
                              </div>
                              <>
                                {this.state.trackingDetails['driverService'] !=
                                  null &&
                                this.state.trackingDetails['driverService'] >
                                  0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Tarifa de servicio']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          this.state.trackingDetails[
                                            'driverService'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['deliveryCharge'] !=
                                  null &&
                                this.state.trackingDetails['deliveryCharge'] >
                                  0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Costo de envío']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          (
                                            this.state.trackingDetails[
                                              'deliveryCharge'
                                            ] +
                                            (this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] != null &&
                                            this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] > 0
                                              ? this.state.trackingDetails[
                                                  'changeAddressExtraCost'
                                                ] + 0.0
                                              : 0.0)
                                          ).toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['tip'] != null &&
                                this.state.trackingDetails['tip'] > 0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Propina']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          this.state.trackingDetails[
                                            'tip'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails[
                                  'dynamicDelivery'
                                ] != null &&
                                this.state.trackingDetails[
                                  'dynamicDelivery'
                                ] ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'(' +
                                          this.state.trackingDetails[
                                            'reasonDescription'
                                          ].toString() +
                                          ')'}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['offerValue'] !=
                                  null &&
                                this.state.trackingDetails['offerValue'] > 0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Descuento']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'-$' +
                                          this.state.trackingDetails[
                                            'offerValue'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                            </Stack>
                          </div>
                          <>
                            {this.state.trackingDetails['onlinePayment'] &&
                            ((this.state.trackingDetails['isOxxoEnabled'] !=
                              null &&
                              this.state.trackingDetails['isOxxoEnabled']) ||
                              (this.state.trackingDetails[
                                'changeAddressExtraCost'
                              ] != null &&
                                this.state.trackingDetails[
                                  'changeAddressExtraCost'
                                ] > 0)) ? (
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Pagado con '] +
                                    (this.state.trackingDetails[
                                      'paypalPayment'
                                    ] != null &&
                                    this.state.trackingDetails['paypalPayment']
                                      ? 'paypal'
                                      : strings['tarjeta'])}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {'$' +
                                    this.state.trackingDetails[
                                      'grandTotalWithoutOxxo'
                                    ].toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {this.state.trackingDetails['onlinePayment'] &&
                            ((this.state.trackingDetails['isOxxoEnabled'] !=
                              null &&
                              this.state.trackingDetails['isOxxoEnabled']) ||
                              (this.state.trackingDetails[
                                'changeAddressExtraCost'
                              ] != null &&
                                this.state.trackingDetails[
                                  'changeAddressExtraCost'
                                ] > 0)) ? (
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Pagar en efectivo']}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] -
                                      this.state.trackingDetails[
                                        'grandTotalWithoutOxxo'
                                      ] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {true ? (
                              <div style={{ width: '100%' }}>
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold',
                                      color: '#2c3136',
                                      marginTop: '20px',
                                    }}
                                  >
                                    {strings['Total']}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold',
                                      color: '#2c3136',
                                      marginTop: '20px',
                                    }}
                                  >
                                    {'$' +
                                      (
                                        this.state.trackingDetails[
                                          'grandTotal'
                                        ] +
                                        (this.state.trackingDetails[
                                          'changeAddressExtraCost'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'changeAddressExtraCost'
                                        ] > 0
                                          ? this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] + 0.0
                                          : 0.0)
                                      ).toFixed(2)}
                                  </Typography>
                                </Stack>
                              </div>
                            ) : null}
                          </>
                        </Stack>
                      </div>
                      <div
                        style={{
                          padding: isIOS ? '120px 0px 0px' : '65px 0px 0px',
                        }}
                      ></div>
                    </Stack>
                  ) : null}
                </>
                {/* </div> */}
              </Stack>
            </Container>
          }
        ></TrackDialog>

        <Backdrop
          onClick={() => {}}
          style={{
            color: '#fff',
            zIndex: 9999,
            maxHeight: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
            height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          }}
          open={false}
        >
          <React.Fragment>
            <CssBaseline />
          </React.Fragment>
        </Backdrop>
        {/* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */}
      </>
    ) : (
      <>
        <div id="loading_indicator" className="container">
          <img
            style={{ width: this.state.width < 1000 ? '30vw' : '15vw' }}
            src={logo}
            alt="loading"
          />
        </div>
      </>
    );
  }
}

class ProfilePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
            if (docMenuSnap.data().phoneNumberVerified) {
              this.setState({ loading: false, openLogin: false });
              this.setState({
                userId: uid,
                email: docMenuSnap.data().email,
                displayName: docMenuSnap.data().fullName ? docMenuSnap.data().fullName : '',
                userDetails: docMenuSnap.data(),
                photoURL: docMenuSnap.data().avatar ?? '',
                imageAsUrl: docMenuSnap.data().avatar,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
            } else {
              if(!this.state.hasSendOTP) {
              this.setState({hasSendOTP: true});
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend);
              this.setState({ otpSend: dataSend });
              await axios
                .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-new-otp/', {
                  code: dataSend,
                  number: docMenuSnap.data().phoneNumber,
                  uuid: uid
                })
                .then(async (response) => {
                  // console.log(response);
                });
              }
                this.setState({
                  userId: uid,
                  email: docMenuSnap.data().email,
                  displayName: docMenuSnap.data().fullName ? docMenuSnap.data().fullName : '',
                  userDetails: docMenuSnap.data(),
                  photoURL: docMenuSnap.data().avatar ?? '',
                imageAsUrl: docMenuSnap.data().avatar,
                  cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                  offersUsed: docMenuSnap.data().offersUsed
                    ? docMenuSnap.data().offersUsed
                    : [],
                });
              this.setState({
                openOTP: true,
                phoneNumber: docMenuSnap.data().phoneNumber,
                phoneCountry: '+52',
                showPassword: false,
              });
            }
          } else {
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: strings['Error de inicio de sesión'],
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [] });
              auth.signOut();
            }
          }
        });
      } else {
        this.setState({ userId: null, userDetails: null, cards: [] });
      }
    });
    this.state = {
      orders: [],
      busId: '',
      alertMsg: '',
      openOTP: false,
      openAlert: false,
      alertType: 'error',
      anchorEl: null,
      open: false,
      openLocation: false,
      openLogin: false,
      hasSendOTP: false,
      password: '',
      email: '',
      displayName: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
      userId: null,
      selectedValue: emails[1],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      addresses: [],
      defaultCountry: 'MX',
      selectedLocationDetails: {},
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      defaultPropsCircle: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      userDetails: null,
      width: 0,
      height: 0,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      showMap: false,
      userDetailsUpdated: false,
      photoURL: '',
      isPasswordChange: false,
      imageAsFile: '',
      imageAsUrl: '',
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentWillMount() {

    var locationData;
    try {
      locationData = JSON.parse(
      localStorage.getItem('location')
    );
} catch(e) {
console.log(e);
}
    if (locationData == null) {
      locationData = [];
    }
    for (var i = 0; i < locationData.length; i++) {
      if (locationData[i]['selected']) {
        this.setState({
          selectLocationName: locationData[i]['geoAddress'],
        });
        // console.log(locationData[i]);
        break;
      }
    }
    const { id } = this.props.match.params;
    this.setState({ selectedAddress: selected, busId: id }, (newState) => {
      // console.log(
      //   'locationData',
      //   this.state.addresses,
      //   typeof this.state.addresses
      // );
    });
    var selected;
    try {
      selected = JSON.parse(localStorage.getItem('selected'));
} catch(e) {
console.log(e);
}
    if (selected == null) {
      selected = { latitude: 0, longitude: 0 };
    }
    this.setState({ selectedAddress: selected }, (newState) => {
      // console.log(
      //   'selectedAddress',
      //   this.state.selectedAddress,
      //   typeof this.state.selectedAddress
      // );
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.setState({isMounted: true});
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    this.setState({isMounted: false});
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleChange(e) {
    var menuD = this.state.menuData.map(item => ({...item}));
    for (var i = 0; i < menuD.length; i++) {
      menuD[i]['products'] = menuD[i]['products'].filter(
        (val) =>
        ((typeof val.name === 'string' &&
        val.name.toLowerCase().includes(e.target.value.toLowerCase())) || (val['nameEng'] && typeof val.nameEng === 'string' &&
        val.nameEng.toLowerCase().includes(e.target.value.toLowerCase())))
      );
      // console.log(
      //   'handle change called',
      //   e.target.value,
      //   i,
      //   menuD[i]['products'].length
      // );
    }
    this.setState({ menu: menuD });
  }
  render() {
    const openAlert = (alertType, alertMsg) => {
      this.setState({
        openAlert: true,
        alertType: alertType,
        alertMsg: alertMsg,
      });
    };

    const handleImageAsFile = (e) => {
      if (e.target.files) {
        const image = e.target.files[0];
        this.setState({
          userDetailsUpdated: true,
          imageAsFile: image,
          imageAsUrl: URL.createObjectURL(image),
        });
      }
    };

    const handleFireBaseUpload = async () => {
      let downloadURL = null;
      if (this.state.imageAsFile === '') {
        console.error(
          `not an image, the image file is a ${typeof this.state.imageAsFile}`
        );
      }

      const storageRef = ref(
        storage,
        `/images/users/${this.state.userId}/avatar`
      );
      await uploadBytes(storageRef, this.state.imageAsFile)
        .then(async (response) => {
          await getDownloadURL(storageRef).then((url) => {
            downloadURL = url;
            this.setState({ ...this.state, imageAsUrl: url, photoURL: url });
          });
        })
        .catch((error) => {
          // console.log('error', error);
        });
      return downloadURL;
    };

    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
      // setAnchorEl(event.currentTarget);
      this.setState({ anchorEl: event.currentTarget });
      // this.state.anchorEl = event.currentTarget;
    };

    const handleMobileMenuClose = () => {
      // setMobileMoreAnchorEl(null);
      this.setState({ mobileMoreAnchorEl: null });
    };

    const handleMenuClose = () => {
      this.setState({ anchorEl: null });
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <>
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {this.state.userDetails && (
            <>
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                }}
              >
                <Link
                  to={'/profile/'}
                  style={{
                    color: '#444444',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  {strings["Perfil"]}
                </Link>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                }}
              >
                <Link
                  to={'/my-orders/'}
                  style={{
                    color: '#444444',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  {strings["Mis Pedidos"]}
                </Link>
              </MenuItem>
            </>
          )}
          <MenuItem
            onClick={async () => {
              handleMenuClose();
              await auth.signOut();
            }}
          >
            {strings["Cerrar sesión"]}
          </MenuItem>
        </Menu>
      </>
    );

    const handleCloseLogin = () => {
      this.setState({
        openLogin: false,
        showPassword: false,
      });
    };

    const handleCloseOTP = () => {
      this.setState({
        openOTP: false, hasSendOTP: false,
        password: '',
        email: '',
        fullName: '',
        otp: '',
        phoneCountry: '+52',
        phoneNumber: '',
        showPassword: false,
      });
    };

    const sendOTP = async (phone) => {
      const min = 0;
      const max = 999999;
      const rand = Math.floor(min + Math.random() * (max - min));
      var dataSend = rand.toString();
      for (var i = 0; i < 6; i++) {
        if (dataSend.length < 6) {
          dataSend = '0' + dataSend;
        }
      }
      // console.log(dataSend);
      this.setState({ otpSend: dataSend });
      await axios
        .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-new-otp/', {
          code: dataSend,
          number: phone,
          uuid: this.state.userId
        })
        .then(async (response) => {
          // console.log(response);
        });
    };

    const handleClickOpenOTP = () => {
      this.setState({ openOTP: true, showPassword: false });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        // window.history.back()
        // handleCloseVariable();
      };
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            {this.state.photoURL && this.state.photoURL!=='' ? (
              <img
                style={{
                  verticalAlign: 'middle',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                }}
                src={this.state.photoURL}
              />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <p>{strings['Perfil']}</p>
        </MenuItem>
      </Menu>
    );

    return this.state.userDetails ? (
      <>
        {/* Menu Start */}

        <Snackbar
        style={{zIndex: 9999999}}
          open={this.state.openAlert}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }

              this.setState({ openAlert: false });
            }}
            severity={this.state.alertType}
            sx={{ width: '100%' }}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>

        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="sticky"
            color="transparent"
            elevation={1}
            style={{
              boxShadow:
                'none',
            }}
          >
            <Toolbar>
              <Button
                style={{
                  margin: '1px',
                  backgroundColor: '#fff',
                  textTransform: 'none',
                  borderRight: '0px solid #cccccc',
                  padding: '5px 15px 5px 5px',
                  fontSize: '14px',
                  borderRadius: '6px',
                  color: '#2e2c36',
                }}
                variant="text"
                startIcon={
                  <ArrowBackRoundedIcon fontSize={'28px'} style={{ color: 'black', }} />
                }
                onClick={() => window.history.back()}
              >
                {strings["Regresar"]}
              </Button>{' '}
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{ display: { xs: 'flex', md: 'flex' } }}
                style={{
                  margin: '1px',
                  borderLeft: '0px solid #cccccc',
                  color: '#01d162',
                }}
              >
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {this.state.photoURL && this.state.photoURL!=='' ? (
                    <img
                      style={{
                        verticalAlign: 'middle',
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                      }}
                      src={this.state.photoURL}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </Box>

        {/* Menu End */}
        <OTPDialog2
          selectedValue={this.state.selectedValue}
          open={this.state.openOTP}
          onClose={handleCloseOTP}
          displayList={
            <DialogContent dividers>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl variant="filled">
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                   {strings["Número de teléfono"]}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                    //           endAdornment={
                    //             <InputAdornment position="end">
                    //               <IconButton
                    //                 aria-label="toggle password visibility"
                    //                 onClick={()=>{
                    //                   this.setState({'showPassword': !this.state.showPassword})
                    //                 }}
                    //                 onMouseDown={(event) => {
                    //   event.preventDefault();
                    // }}
                    //                 edge="end"
                    //               >
                    //                 {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    //               </IconButton>
                    //             </InputAdornment>
                    //           }
                  />
                </FormControl>
                &nbsp;&nbsp;
                <Button
                  variant="text"
                  style={{ textTransform: 'none' }}
                  onClick={async () => {
                    if (this.state.phoneNumber.length >= 10) {
                      this.setState({ loading: true });
                      await updateDoc(doc(db, 'users', this.state.userId), {
                        phoneNumber:
                          this.state.phoneCountry + this.state.phoneNumber,
                          phoneNumberVerified: false,
                      });
                      sendOTP(this.state.phoneCountry + this.state.phoneNumber);
                      this.setState({ loading: false });
                      this.setState({
                        openAlert: true,
                        alertType: 'success',
                        alertMsg: strings['Envío OTP con éxito'],
                      });
                      // this.setState({ userId: uid });
                      // ...
                      // })
                      // .catch((error) => {
                      //   const errorCode = error.code;
                      //   const errorMessage = error.message;
                      //   this.setState({loading: false});
                      //   openAlert('error',"Cant login");
                      //   console.log(errorMessage,errorCode);
                      // });
                    }
                  }}
                >
                  {strings["Enviar"]}
                </Button>
              </Box>
              <br></br>
              <br></br>
              <Typography align={'center'}>
                {strings["Ingrese OTP una vez que reciba"]}
              </Typography>

              <Button
                fullWidth
                variant="text"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  sendOTP(this.state.phoneCountry + this.state.phoneNumber);
                }}
              ></Button>
              <br></br>
              <br></br>
              <OtpInput
                style={{ placeContent: 'center' }}
                align={'center'}
                inputStyle={{
                  width: '32px',
                  height: '32px',
                  fontSize: '17px',
                  fontWeight: 'bold',
                }}
                numInputs={6}
                onChange={(value) => {
                  this.setState({ otp: value });
                  // console.log(value, typeof value);
                }}
                separator={
                  <span style={{ width: '20px', height: '20px' }}></span>
                }
                isInputNum={true}
                shouldAutoFocus
                value={this.state.otp}
              />
              <br />
              <br />
              <Button
                fullWidth
                variant="contained"
                onClick={async () => {
                  // console.log(this.state.otpSend,this.state.otp);
                  if (this.state.otp === this.state.otpSend) {
                    this.setState({ loading: true });
                    await updateDoc(doc(db, 'users', this.state.userId), {
                          phoneNumberVerified: true,
                      });
                    this.setState({ loading: false, openOTP: false, hasSendOTP: false });
                    this.setState({
                      openAlert: true,
                      alertType: 'success',
                      alertMsg: strings['Has ingresado correctamente'],
                    });
                    // this.setState({ userId: uid });
                    // ...
                    // })
                    // .catch((error) => {
                    //   const errorCode = error.code;
                    //   const errorMessage = error.message;
                    //   this.setState({loading: false});
                    //   openAlert('error',"Cant login");
                    //   console.log(errorMessage,errorCode);
                    // });
                  } else {
                    this.setState({
                      openAlert: true,
                      alertType: 'error',
                      alertMsg:
                        strings['El código ingresado no es correcto, intenta nuevamente'],
                    });
                  }
                }}
              >
                {strings["Guardar"]}
              </Button>
            </DialogContent>
          }
        />
        {/* Container Start */}
        <div
          style={{
            width: '70vw',
            height: this.state.width > 500 ? '53vh' : '70vh',
            margin: 'auto',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 10px #64717a42',
            padding: '12px 6px 12px',
            borderRadius: 20,
            marginTop: 25,
          }}
        >
          <div
            className="profile-pic"
            style={{
              paddingTop: 10,
              textAlign: 'center',
              width: '170px',
              margin: 'auto',
            }}
          >
            <input
              id="car"
              type="file"
              accept="image/*"
              capture="camera"
              style={{
                width: '1px',
                height: '1px',
                opacity: '0',
                position: 'absolute',
                overflow: 'hidden',
                zIndex: '-1',
              }}
              onChange={(e) => handleImageAsFile(e)}
            />
            <div
              style={{
                backgroundImage: `url(${
                  (this.state?.imageAsUrl && this.state?.imageAsUrl!=='')
                    ? this.state?.imageAsUrl
                    : require('./assets/default-user.png')
                })`,
                position: 'relative',
                width: '20vh',
                height: '20vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                border: '1px solid #e6ebf1',
                borderRadius: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!this.data && !this.loading && (
                <label
                  htmlFor="car"
                  style={{
                    marginBottom: '60%',
                    marginLeft: '75%',
                    fontSize: 0,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    backgroundColor: '#01d162',
                    borderRadius: 7,
                  }}
                >
                  <EditIcon
                    style={{
                      color: '#fff',
                      border: '2px solid #fff',
                      borderRadius: 7,
                    }}
                  />
                </label>
              )}
            </div>
            {/* <img style={{width: this.state.width < 1000? "40vw":"15vw"}} src={this.state.userDetails?.photoURL ? this.state.userDetails?.photoURL : require('./assets/default-user.png')} alt="Profile" /> */}
          </div>

          <div className="profile-form">
            <DialogContent>
              <div
                style={{
                  display: 'flex',
                  flexDirection: this.state.width > 420 ? 'row' : 'column',
                }}
              >
                <TextField
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '9px',
                    backgroundColor: '#e6ebf1',
                    overflow: 'hidden',
                    width: this.state.width > 420 ? '50%' : '100%',
                    marginRight: 10,
                  }}
                  error={this.state.displayName?.length < 3}
                  label={strings["Nombre"]}
                  variant="filled"
                  value={this.state.displayName}
                  onChange={(event) => {
                    this.setState({
                      displayName: event.target.value,
                      userDetailsUpdated: true,
                    });
                  }}
                />
                <br />
                <TextField
                disabled={true}
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '9px',
                    backgroundColor: '#e6ebf1',
                    overflow: 'hidden',
                    width: this.state.width > 420 ? '50%' : '100%',
                  }}
                  error={
                    !validator.isEmail(this.state.email) &&
                    this.state.email.length > 0
                  }
                  id="filled-basic"
                  label={strings["Email"]}
                  variant="filled"
                  value={this.state.email}
                  onChange={(event) => {
                    // this.setState({
                    //   email: event.target.value,
                    //   userDetailsUpdated: true,
                    // });
                  }}
                />
                <br />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: this.state.width > 420 ? 'row' : 'column',
                  marginTop: this.state.width > 420 ? 10 : 0,
                }}
              >
                <FormControl
                  fullWidth
                  variant="filled"
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '9px',
                    backgroundColor: '#e6ebf1',
                    overflow: 'hidden',
                    width: this.state.width > 420 ? '50%' : '100%',
                    marginRight: 10,
                    // marginRight: this.state.width > 420 ? '50.5%' : 0,
                  }}
                >
                  <InputLabel
                    error={
                      this.state.password.length <= 6 &&
                      this.state.password.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings["Contraseña"]}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.password.length <= 6 &&
                      this.state.password.length > 0
                    }
                    id="filled-adornment-password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={(event) => {
                      this.setState({
                        password: event.target.value,
                        userDetailsUpdated: true,
                        isPasswordChange: true,
                      });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{ color: '#b3b9c2' }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            this.setState({
                              showPassword: !this.state.showPassword,
                            });
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <br />
                <Button
                variant="contained"
                style={{width: this.state.width > 420 ? '50%' : '100%',backgroundColor: '#e6ebf1',textTransform: "none", color: "#000"}}
                onClick={async () => {
                  handleClickOpenOTP();
                }}
              >
                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                  style={{width: '100%'}}
                                >
                <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: '500',
                                      color: '#2c3136',
                                    }}
                                  >
                                    {this.state.userDetails['phoneNumber']}
                                  </Typography> <EditIcon /> </Stack>
              </Button>
                <br />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: this.state.width > 420 ? 10 : 0,
                }}
              >

                <br />
                <br />
              </div>
              {this.state.userDetailsUpdated && (
                <Button
                  disabled={!this.state.userDetailsUpdated}
                  style={{ float: 'right', marginTop: 20, color: '#ffffff' }}
                  variant="contained"
                  onClick={async () => {
                    let flag = true;
                    let shouldLoginAgain = false;
                    if (
                      validator.isEmail(this.state.email) &&
                      this.state.displayName?.length >= 3 &&
                      auth.currentUser
                    ) {
                      this.setState({ loading: true });
                      if (this.state.imageAsFile) {
                        const url = await handleFireBaseUpload();
                        this.state.imageAsUrl = url;
                      }

                      await updateDoc(doc(db, 'users', this.state.userId), {
                        fullName:this.state.displayName,
                        avatar:this.state.imageAsUrl ?this.state.imageAsUrl: (this.state.photoURL?this.state.photoURL:'' )
            })

                      // await updateProfile(auth.currentUser, {
                      //   displayName: this.state.displayName,
                      //   email: this.state.email,
                      //   photoURL: this.state.imageAsUrl,
                      // })
                        .then((result) => {
                          this.setState({ loading: false });
                        })
                        .catch((error) => {
                          const errorCode = error.code;
                          const errorMessage = error.message;
                          this.setState({ loading: false });
                          // console.log(errorMessage, errorCode);
                          flag = false;
                        });
                      if (this.state.isPasswordChange) {
                        await updatePassword(
                          auth.currentUser,
                          this.state.password
                        )
                          .then((res) => {
                            // console.log('Result after updating password', res);
                          })
                          .catch((error) => {
                            // console.log(
                            //   'error while updating password',
                            //   error.code
                            // );
                            if (error.code === 'auth/requires-recent-login') {
                              shouldLoginAgain = true;
                            }
                            flag = false;
                          });
                      }
                      if (flag) {
                        openAlert(
                          'success',
                          strings['Perfil actualizado correctamente']
                        );
                      } else if (shouldLoginAgain) {
                        this.setState({ openLogin: true });
                        openAlert(
                          'error',
                          strings['Por favor ingresa de nuevo para actualizar tu contraseña']
                        );
                      } else {
                        openAlert('error', strings["Algo salió mal"]);
                      }
                    }
                  }}
                >
                  {strings["Actualizar Perfil"]}
                </Button>
              )}
              <br />
            </DialogContent>
          </div>
        </div>
        {/* Container End */}

        {/* Start Loading Indicator */}
        <Backdrop
          onClick={() => {}}
          style={{ color: '#fff', zIndex: 9999999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* End Loading Indicator */}

        {/* Start Dialogs (Modals) */}

        <LoginDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLogin}
          onClose={handleCloseLogin}
          displayList={
            <DialogContent dividers>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '9px',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic"
                label={strings["Email"]}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '9px',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings["Contraseña"]}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Button
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6
                  ) {
                    this.setState({ loading: true });
                    signInWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then((userCredential) => {
                        // Signed in
                        this.setState({ loading: false, openLogin: false });
                        openAlert(
                          'success',
                          strings['Ahora puedes cambiar tu contraseña']
                        );
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        openAlert('error', strings['No puedo iniciar sesión']);
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings["Iniciar sesión"]}
              </Button>
              <br />
            </DialogContent>
          }
        />
        {/* End Dialogs (Modals) */}
      </>
    ) : (
      <>
        <div id="loading_indicator" className="container">
          <img
            style={{ width: this.state.width < 1000 ? '40vw' : '15vw' }}
            src={logo}
            alt="loading"
          />
        </div>
      </>
    );
  }
}

class OrdersPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);
        this.setState({
          orders: [],
        });
        var orders = [];
        const q = query(
          collection(db, 'orders'),
          where('userId', '==', uid),
          orderBy('createdOn', 'desc')
        );
        await onSnapshot(q, async (data) => {
          this.setState({
            orders: [],
          });
          if (data.docs.length <= 0 && this.state.trackingId) {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }
          data.docs.forEach(async (document) => {
            orders = orders.filter((element) => element.id !== document.id);
            if (
              this.state.trackingId &&
              this.state.trackingId === document.id
            ) {
              await this.setState({ trackingDetails: document.data() });
            }
            orders.push(document);
            await this.setState({ orders: orders });
            // console.log('orders', orders);
          });
        });

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
            if (docMenuSnap.data().phoneNumberVerified) {
              if (this.state.openLogin) {
                this.setState({ loading: false, openLogin: false });
                this.setState({
                  openAlert: true,
                  alertType: 'success',
                  alertMsg: strings['Inicio de sesión exitoso'],
                });
              }
              this.setState({
                userId: uid,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
            } else {
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend);
              // this.setState({ otpSend: dataSend });
              // await axios
              //   .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-otp/', {
              //     code: dataSend,
              //     number: docMenuSnap.data().phoneNumber,
              //   })
              //   .then(async (response) => {
              //     console.log(response);
              //   });
              this.setState({
                userId: uid,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
              });
              // this.setState({
              //   openOTP: true,
              //   phoneNumber: docMenuSnap.data().phoneNumber,
              //   phoneCountry: '+52',
              //   showPassword: false,
              // });
            }
          } else {
            // console.log('openlogin',this.setState.openLogin);
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: strings['Error de inicio de sesión'],
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [] });
              auth.signOut();
            }
          }
        });
        // ...
      } else {
        this.setState({ userId: null, userDetails: null, cards: [] });
      }
    });
    this.state = {
      orders: [],
      alertMsg: '',
      busId: '',
      selectLocationName: null,
      canDeliverHere: false,
      openAlert: false,
      showCart: false,
      showTracking: false,
      trackingDetails: null,
      toggleDetails: false,
      trackingId: null,
      showDiscount: false,
      showPayment: false,
      alertType: 'error',
      anchorEl: null,
      open: false,
      openLocation: false,
      openPreOrder: false,
      openLogin: false,
      openBusinessInfo: false,
      password: '',
      email: '',
      fullName: '',
      tempOrder: {},
      productIds: [],
      otp: '',
      otpSend: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
      openRegister: false,
      openOTP: false,
      openVariable: false,
      selectedProduct: null,
      userId: null,
      selectedValue: emails[1],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      businessData: null,
      locOptions: [],
      offers: [],
      offers2: [],
      addresses: [],
      defaultCountry: 'MX',
      selectedLocationDetails: {},
      cards: [],
      loading: false,
      offersUsed: [],
      cart: {
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        totalCost: 0,
        totalItems: 0,
        item: [],
        totalOxxoCost: 0,
        totalOxxoItems: 0,
        oxxoNameData: '',
        oxxoLogoImage: '',
        oxxoItem: [],
      },
      delivery: 0,
      selectedMenuIndex: 0,
      menuProductItems: [],
      selectedType: 0,
      hasClickedLoc: false,
      autoLoc: '',
      offerDetails: {},
      onlyPickup: false,
      storeIsOpen: false,
      deliveryFee: 0,
      dynamicFeeType: 0,
      selectLocationData: {
        id: '',
        name: '',
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: 10.99835602,
        longitude: 77.01502627,
      },
      cookingInstructions: '',
      spoon: false,
      selectedAddress: {
        latitude: 0,
        longitude: 0,
      },
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      mobileMoreAnchorEl: null,
      menuData: [],
      menu: [],
      userDetails: null,
      width: 0,
      height: 0,
      showAddressModal: false,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      isStore: false,
      order: {
        offerCode: '',
        offerId: '',
        offerValue: 0,
        cashbackValue: 0,
        offerCode2: '',
        offerId2: '',
        offerValue2: 0,
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        itemCost: 0,
        deliveryCharge: 0,
        usageFee: 0,
        taxes: 0,
        serviceFee: 0,
        grandTotal: 0,
        items: [],
      },
      offerCode: '',
      offerId: '',
      offerSuccess: '',
      offerErrorText: '',
      offerValue: 0,
      cashbackValue: 0,
      offerInd: null,
      isOfferApplied: false,
      isOfferError: false,
      isFreeDelivery: false,
      deliveryInstructions: '',
      defaultCard: null,
      toggleDelivery: true,
      tipPercent: 0,
      dynamicDelivery: false,
      additionalDelivery: 0,
      normalDelivery: 0,
      additionalDeliveryPercentage2: 0,
      reasonTitle: '',
      reasonDescription: '',
      isStore2: false,
      showMap: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentWillMount() {
    var locationData;
    try {
      locationData = JSON.parse(
      localStorage.getItem('location')
    );
} catch(e) {
console.log(e);
}
    if (locationData == null) {
      locationData = [];
    }
    for (var i = 0; i < locationData.length; i++) {
      if (locationData[i]['selected']) {
        this.setState({
          selectLocationName: locationData[i]['geoAddress'],
        });
        // console.log(locationData[i]);
        break;
      }
    }
    const { id } = this.props.match.params;
    this.setState({ selectedAddress: selected, busId: id }, (newState) => {
      // console.log(
      //   'locationData',
      //   this.state.addresses,
      //   typeof this.state.addresses
      // );
    });
    var selected;
    try {
      selected = JSON.parse(localStorage.getItem('selected'));
} catch(e) {
console.log(e);
}
    if (selected == null) {
      selected = { latitude: 0, longitude: 0 };
    }
    this.setState({ selectedAddress: selected }, (newState) => {
      // console.log(
      //   'selectedAddress',
      //   this.state.selectedAddress,
      //   typeof this.state.selectedAddress
      // );
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleChange(e) {
    var menuD = this.state.menuData.map(item => ({...item}));
    for (var i = 0; i < menuD.length; i++) {
      menuD[i]['products'] = menuD[i]['products'].filter(
        (val) =>
        ((typeof val.name === 'string' &&
        val.name.toLowerCase().includes(e.target.value.toLowerCase())) || (val['nameEng'] && typeof val.nameEng === 'string' &&
        val.nameEng.toLowerCase().includes(e.target.value.toLowerCase())))
      );
      // console.log(
      //   'handle change called',
      //   e.target.value,
      //   i,
      //   menuD[i]['products'].length
      // );
    }
    this.setState({ menu: menuD });
  }
  render() {
    const getStat = (order) => {
      var update = '';
      if (!order['acceptedByRest']) {
        update = strings['Esperando a que el negocio acepte su pedido'];
      }
      if (order['acceptedByRest'] && !order['readyToBePicked']) {
        update = strings['El negocio está preparando su producto.'];
      }
      // if (order['acceptedByRest'] && order['readyToBePicked']) {
      //   update = 'Orden lista para recolección ';
      // }
      if (
        order['driverAssigned'] &&
        !order['driverReached'] &&
        !order['orderPickedUp']
      ) {
        update =
          order['driverName'].split(' ')[0] +
          strings[' está en camino para recoger su pedido'];
      }
      if (
        order['driverAssigned'] &&
        order['driverReached'] &&
        !order['orderPickedUp']
      ) {
        update = order['driverName'].split(' ')[0] + strings[' llegó al negocio'];
      }

      if (order['orderPickedUp']) {
        update = order['driverName'].split(' ')[0] + strings[' ha recogido tu pedido'];
      }
      if (order['delivered'] && !order['cancelled']) {
        update = strings['Su orden ha sido completada'];
      }
      if (order['delivered'] && order['cancelled']) {
        update = strings['Su pedido ha sido cancelado'];
      }
      return update;
    };

    const handleTrackingOpen = (orderDetails, id) => {
      this.setState({
        showTracking: true,
        trackingDetails: orderDetails,
        trackingId: id,
      });
      window.history.pushState(null, '', window.location.href);

      window.onpopstate = () => {
        window.onpopstate = () => {};
        window.history.back();
        this.setState({
          showTracking: false,
          trackingDetails: null,
          trackingId: null,
        });
      };
    };
    // console.log('asdasd', this.state.trackingDetails);

    const findTotalT = (id, i, order) => {
      var total1 =
        (order['items'][id]['customize'][i]['nameEng'] != null
          ? order['items'][id]['customize'][i]['nameEng']
          : order['items'][id]['customize'][i]['name']) + ': ';
      for (
        var j = 0;
        j < order['items'][id]['customize'][i]['options'].length;
        j++
      ) {
        if (j === 0) {
          total1 =
            total1 +
            (order['items'][id]['customize'][i]['options'][j]['nameEng'] != null
              ? order['items'][id]['customize'][i]['options'][j]['nameEng']
              : order['items'][id]['customize'][i]['options'][j]['name']);
        } else {
          total1 =
            total1 +
            ',' +
            (order['items'][id]['customize'][i]['options'][j]['nameEng'] != null
              ? order['items'][id]['customize'][i]['options'][j]['nameEng']
              : order['items'][id]['customize'][i]['options'][j]['name']);
        }
      }
      return total1;
    };

    let columns = this.state.width < 770 ? 1 : this.state.width < 1600 ? 2 : 3;
    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
      // setAnchorEl(event.currentTarget);
      this.setState({ anchorEl: event.currentTarget });
      // this.state.anchorEl = event.currentTarget;
    };

    const handleMobileMenuClose = () => {
      // setMobileMoreAnchorEl(null);
      this.setState({ mobileMoreAnchorEl: null });
    };

    const handleMenuClose = () => {
      this.setState({ anchorEl: null });
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <>
        {!this.state.userId ? (
          <Menu
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose();
              }}
            >
              Iniciar sesión
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
              }}
            >
              {strings["Registrarse"]}
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            {this.state.userDetails && (
              <>
                <MenuItem
                  onClick={async () => {
                    handleMenuClose();
                  }}
                >
                  <Link
                    to={'/profile/'}
                    style={{
                      color: '#444444',
                      textDecoration: 'none',
                      width: '100%',
                    }}
                  >
                    {strings["Perfil"]}
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    handleMenuClose();
                  }}
                >
                  <Link
                    to={'/my-orders/'}
                    style={{
                      color: '#444444',
                      textDecoration: 'none',
                      width: '100%',
                    }}
                  >
                    {strings["Mis Pedidos"]}
                  </Link>
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={async () => {
                handleMenuClose();
                await auth.signOut();
              }}
            >
              {strings["Cerrar sesión"]}
            </MenuItem>
          </Menu>
        )}
      </>
    );

    const openAlert = (alertType, alertMsg) => {
      this.setState({
        openAlert: true,
        alertType: alertType,
        alertMsg: alertMsg,
      });
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            {this.state.photoURL ? (
              <img
                style={{
                  verticalAlign: 'middle',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                }}
                src={this.state.photoURL}
              />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <p>{strings["Perfil"]}</p>
        </MenuItem>
      </Menu>
    );

    return !this.loading ? (
      <>
        <Snackbar
        style={{zIndex: 9999999}}
          open={this.state.openAlert}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }

            this.setState({ openAlert: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }

              this.setState({ openAlert: false });
            }}
            severity={this.state.alertType}
            sx={{ width: '100%' }}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>

        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="sticky"
            color="transparent"
            elevation={1}
            style={{
              boxShadow:
                'none',
            }}
          >
            <Toolbar>
              <Button
                style={{
                  margin: '1px',
                  backgroundColor: '#fff',
                  textTransform: 'none',
                  borderRight: '0px solid #cccccc',
                  padding: '5px 15px 5px 5px',
                  fontSize: '14px',
                  borderRadius: '6px',
                  color: '#2e2c36',
                }}
                variant="text"
                startIcon={
                  <ArrowBackRoundedIcon fontSize={'28px'} style={{ color: 'black', }} />
                }
                onClick={() => window.history.back()}
              >
                {strings["Regresar"]}
              </Button>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{ display: { xs: 'flex', md: 'flex' } }}
                style={{
                  margin: '1px',
                  borderLeft: '0px solid #cccccc',
                  color: '#01d162',
                }}
              >
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {this.state.photoURL ? (
                    <img
                      style={{
                        verticalAlign: 'middle',
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                      }}
                      src={this.state.photoURL}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </Box>
        {/* Container Start */}
        <Container
          disableGutters
          style={{
            width: '100vw',
            margin: 'auto',
            padding: '10px 5px',
            background: '#ffffff',
          }}
        >
          {this.state.orders.length > 0 ? (
            this.state.orders.map((i, ind) => {
              let order = i.data();
              return (
                <Order
                  onClick={() => handleTrackingOpen(order, i.id)}
                  direction={'column'}
                  spacing={0}
                  style={{ background: '#ffffff' }}
                  key={'order-details' + i.id}
                >
                  <div
                    className="business-logo"
                    style={{
                      width: columns == 1 ? '100%' : '18%',
                      textAlign: 'center',
                      placeSelf: 'center',
                    }}
                  >
                    <img
                      style={{ width: 50, height: 50 }}
                      alt="business-logo"
                      src={i.data()['restLogo']}
                    />
                  </div>
                  <div
                    className="order-info"
                    style={{
                      width: columns == 1 ? '100%' : '42%',
                      padding: '5px',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 0,
                        marginTop: 0,
                        color: '#2e2c36',
                      }}
                    >
                      {i.data()['number']} · {i.data()['restName']}
                    </p>
                    <p
                      style={{
                        marginBottom: 0,
                        color: 'slategray',
                        marginTop: '6px',
                      }}
                    >
                      {' '}
                      {order.serverTime
                        ? moment
                            .unix(order.serverTime?.seconds).add((order['preOrderedOn']?40:0),'minutes')
                            .format('DD-MM-YYYY hh:mm a')
                        : ''}
                    </p>
                    <p
                      style={{
                        marginBottom: 0,
                        color: 'slategray',
                        marginTop: '6px',
                      }}
                    >
                      {' '}
                      {getStat(order)}
                    </p>
                  </div>
                  <div
                    className="action-and-status"
                    style={{
                      width: columns == 1 ? '100%' : '40%',
                      padding: '0px',
                      placeSelf: 'center',
                    }}
                  >
                    <div
                      style={{
                        padding: '0',
                        zIndex: '9',
                      }}
                    >
                      <div style={{ padding: '0' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <>
                              {true ? (
                                <AspectRatio
                                  ratio="1/1"
                                  style={{
                                    borderRadius: '50%',
                                    width: 'calc(100% / 8 + 7.5px)',
                                    maxWidth: 'calc(100% / 8 + 7.5px)',
                                    padding: '5.5px',
                                    marginTop: '3px',
                                  }}
                                >
                                  <BusinessSvg
                                    viewBox="-20 -40 550 550"
                                    style={{
                                      border: `2px solid #4dd884`,
                                      borderRadius: '50%',
                                      width: 'calc(100%)',
                                      padding: '6px',
                                      fontSize: '50px',
                                    }}
                                  />
                                </AspectRatio>
                              ) : null}
                            </>
                            <>
                              {
                                <div
                                  style={{
                                    width: 'calc(100% / 16 - 5px)',
                                    height: '6px',
                                  }}
                                >
                                  <Divider
                                    style={{
                                      borderBottomWidth: '4px',
                                      borderRadius: '60px 0 0 60px',
                                      backgroundColor: '#4DD884',
                                    }}
                                  />
                                </div>
                              }
                            </>
                            <>
                              {
                                <div
                                  style={{
                                    width: 'calc(100% / 16 - 5px)',
                                    height: '6px',
                                  }}
                                >
                                  <Divider
                                    style={{
                                      borderBottomWidth: '4px',
                                      borderRadius: '0 60px 60px 0',
                                      backgroundColor:
                                        order['acceptedByRest'] ||
                                        order['delivered']
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                    }}
                                  />
                                </div>
                              }
                            </>
                            <>
                              {true ? (
                                <AspectRatio
                                  ratio="1/1"
                                  style={{
                                    borderRadius: '80px',
                                    border: order['acceptedByRest']
                                      ? '0px solid #4dd884'
                                      : '0px solid #C4C1C0',
                                    width: 'calc(100% / 8 + 7.5px)',
                                    maxWidth: 'calc(100% / 8 + 7.5px)',
                                    padding: '5.5px',
                                  }}
                                >
                                  <StoreMallDirectoryIcon
                                    style={{
                                      fontSize: '29px',
                                      marginTop: '3px',
                                      border: '2px solid',
                                      borderRadius: '60px',
                                      borderColor: order['acceptedByRest']
                                        ? '#4DD884'
                                        : '#C4C1C0',
                                      padding: '6px',
                                      color: order['acceptedByRest']
                                        ? '#4DD884'
                                        : '#C4C1C0',
                                    }}
                                  />
                                </AspectRatio>
                              ) : null}
                            </>
                            <>
                              {
                                <div
                                  style={{
                                    width: 'calc(100% / 16 - 5px)',
                                    height: '6px',
                                  }}
                                >
                                  <Divider
                                    style={{
                                      borderBottomWidth: '4px',
                                      borderRadius: '60px 0 0 60px',
                                      backgroundColor:
                                        order['driverAssigned'] ||
                                        order['delivered']
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                    }}
                                  />
                                </div>
                              }
                            </>
                            <>
                              {
                                <div
                                  style={{
                                    width: 'calc(100% / 16 - 5px)',
                                    height: '6px',
                                  }}
                                >
                                  <Divider
                                    style={{
                                      borderBottomWidth: '4px',
                                      borderRadius: '0 60px 60px 0',
                                      backgroundColor:
                                        order['driverReached'] ||
                                        order['delivered']
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                    }}
                                  />
                                </div>
                              }
                            </>
                            <>
                              {true ? (
                                <AspectRatio
                                  ratio="1/1"
                                  style={{
                                    borderRadius: '80px',
                                    border: order['driverReached']
                                      ? '0px solid #4dd884'
                                      : '0px solid #C4C1C0',
                                    width: 'calc(100% / 8 + 7.5px)',
                                    maxWidth: 'calc(100% / 8 + 7.5px)',
                                    padding: '5.5px',
                                  }}
                                >
                                  <DeliveryDiningIcon
                                    style={{
                                      fontSize: '29px',
                                      marginTop: '3px',
                                      border: '2px solid',
                                      borderRadius: '60px',
                                      borderColor: order['driverReached']
                                        ? '#4DD884'
                                        : '#C4C1C0',
                                      padding: '6px',
                                      color: order['driverReached']
                                        ? '#4DD884'
                                        : '#C4C1C0',
                                    }}
                                  />
                                </AspectRatio>
                              ) : null}
                            </>

                            <>
                              {
                                <div
                                  style={{
                                    width: 'calc(100% / 16 - 5px)',
                                    height: '6px',
                                  }}
                                >
                                  <Divider
                                    style={{
                                      borderBottomWidth: '4px',
                                      borderRadius: '60px 0 0 60px',
                                      backgroundColor:
                                        order['orderPickedUp'] ||
                                        order['delivered']
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                    }}
                                  />
                                </div>
                              }
                            </>
                            <>
                              {
                                <div
                                  style={{
                                    width: 'calc(100% / 16 - 5px)',
                                    height: '6px',
                                  }}
                                >
                                  <Divider
                                    style={{
                                      borderBottomWidth: '4px',
                                      borderRadius: '0 60px 60px 0',
                                      backgroundColor: order['delivered']
                                        ? '#4DD884'
                                        : '#C4C1C0',
                                    }}
                                  />
                                </div>
                              }
                            </>
                            <>
                              {true ? (
                                <AspectRatio
                                  ratio="1/1"
                                  style={{
                                    borderRadius: '80px',
                                    border:
                                      order['delivered'] && !order['cancelled']
                                        ? '0px solid #4dd884'
                                        : order['delivered'] &&
                                          order['cancelled']
                                        ? '0px solid #D62B2B'
                                        : '0px solid #C4C1C0',
                                    width: 'calc(100% / 8 + 7.5px)',
                                    maxWidth: 'calc(100% / 8 + 7.5px)',
                                    padding: '5.5px',
                                  }}
                                >
                                  {/* <HomeIcon
                                        style={{
                                          fontSize: '29px',
                                          marginTop: '3px',
                                          border: '2px solid',
                                          borderRadius: '60px',
                                          borderColor:order['delivered'] && !order['cancelled'] ? '#4DD884' : order['delivered'] &&order['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                          padding: '6px',
                                          color:  order['delivered'] &&  !order['cancelled']  ? '#4DD884'  : order['delivered'] &&order['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                        }}
                                      /> */}
                                  <WhereToVoteIcon
                                    style={{
                                      fontSize: '29px',
                                      marginTop: '3px',
                                      border: '2px solid',
                                      borderRadius: '60px',
                                      borderColor:
                                        order['delivered'] &&
                                        !order['cancelled']
                                          ? '#4DD884'
                                          : order['delivered'] &&
                                            order['cancelled']
                                          ? '#D62B2B'
                                          : '#C4C1C0',
                                      padding: '6px',
                                      color:
                                        order['delivered'] &&
                                        !order['cancelled']
                                          ? '#4DD884'
                                          : order['delivered'] &&
                                            order['cancelled']
                                          ? '#D62B2B'
                                          : '#C4C1C0',
                                    }}
                                  />
                                </AspectRatio>
                              ) : null}
                            </>
                          </Stack>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </Order>
              );
            })
          ) : (
            <Typography
              style={{
                fontSize: '2vh',
                fontWeight: 'normal',
                color: 'slategray',
                textAlign: 'center',
              }}
            >
              {strings["No existen pedidos para mostrar"]}
            </Typography>
          )}
        </Container>
        {/* Container End */}

        <Backdrop
          onClick={() => {}}
          style={{ color: '#fff', zIndex: 9999999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <TrackDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showTracking}
          onClose={() => {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }}
          title={this.state.width}
          displayList={
            <Container
              maxWidth="sm"
              disableGutters
              sx={{
                margin: '0px',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
              }}
              style={{ background: '#ffffff' }}
            >
              <Stack direction={'column'} spacing={0}>
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width < 600 ? '24px' : '18px',
                      fontWeight: '800',
                      color: '#2c3136',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                    }}
                  >
                    {strings['Detalles del pedido']}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      this.setState({
                        showTracking: false,
                        trackingDetails: null,
                        trackingId: null,
                      });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <>
                  {this.state.trackingDetails ? (
                    <Stack
                      direction={'column'}
                      spacing={0}
                      style={{
                        height: isIOS
                          ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                          : 'calc(100vh - 50px)',
                        overflow: 'auto',
                      }}
                    >
                      <div style={{ height: '300px' }}>
                        <div style={{ height: '300px', width: '100%' }}>
                          <GoogleMapReact
                            defaultCenter={{
                              lat: this.state.trackingDetails.userLocation
                                .latitude,
                              lng: this.state.trackingDetails.userLocation
                                .longitude,
                            }}
                            defaultZoom={this.state.defaultProps.zoom}
                            resetBoundsOnResize={true}
                            options={{
                              fullscreenControl: false,
                              scrollwheel: false,
                              panControl: false,
                              zoomControl: true,
                              rotateControl: false,
                            }}
                            bootstrapURLKeys={{
                              key: constants.googleMapsAPI,
                            }}
                            style={{
                              height: '300px',
                              padding: '8px',
                              borderRadius: '10px',
                            }}
                            onChange={(changeData) => {}}
                          >
                            {/* <HomeIcon
                              style={{
                                color: '#01d162',
                                transform: 'translate(-50%, -100%)',
                              }}
                              fontSize={'large'}
                              lat={
                                this.state.trackingDetails.userLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.userLocation.longitude
                              }
                              // text={'Google Map'}
                            /> */}

                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              style={{
                                fontSize: '50px',
                                padding: '6px',
                                transform: 'translate(-50%, -100%)',
                              }}
                              lat={
                                this.state.trackingDetails.userLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.userLocation
                                  .longitude
                              }
                            >
                              <rect
                                id="Rectangle-6_1_"
                                x="0.4"
                                y="0.4"
                                className="st0"
                                width="511.2"
                                height="511.2"
                              />
                              <g>
                                <path
                                  id="path-1_1_"
                                  className="st4"
                                  d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1s-334.3-46.8-379.5,0
                                              s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5C270.7,511.2,295.4,471.1,309.4,446.1z"
                                />
                              </g>
                              <path
                                className="st5"
                                d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                            c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                            c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                            c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                            C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                            c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                            c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                            c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                            C414.5,33.6,341.1,21.6,256,21.6z"
                              />
                              <path
                                className="st3"
                                d="M231.4,324.2c-7.2,0-14.1-3.1-18.9-8.5l-67.6-76c-9.3-10.5-8.3-26.5,2.1-35.8c10.5-9.3,26.5-8.4,35.8,2.1
                                            l46.5,52.3l108.2-151.5c8.1-11.4,23.9-14,35.3-5.9c11.4,8.1,14,24,5.9,35.3L252.1,313.6c-4.5,6.3-11.6,10.2-19.3,10.6
                                            C232.3,324.2,231.9,324.2,231.4,324.2z"
                              />
                            </svg>

                            {/* <StoreMallDirectoryIcon
                              style={{
                                color: '#01d162',
                                transform: 'translate(-50%, -100%)',
                              }}
                              fontSize={'large'}
                              lat={
                                this.state.trackingDetails.restLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.restLocation.longitude
                              }
                              // text={'Google Map'}
                            /> */}

                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              style={{
                                fontSize: '50px',
                                padding: '6px',
                                transform: 'translate(-50%, -100%)',
                              }}
                              lat={
                                this.state.trackingDetails.restLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.restLocation
                                  .longitude
                              }
                            >
                              <rect
                                id="Rectangle-6"
                                x="0.4"
                                y="0.4"
                                className="st0"
                                width="511.2"
                                height="511.2"
                              />
                              <g>
                                <path
                                  id="path-1"
                                  className="st1"
                                  d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1s-334.3-46.8-379.5,0
                                                s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5C270.7,511.2,295.4,471.1,309.4,446.1z"
                                />
                              </g>
                              <path
                                className="st2"
                                d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                              c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                              c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                              c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                              C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                              c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                              c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                              c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                              C414.5,33.6,341.1,21.6,256,21.6z"
                              />
                              <path
                                className="st3"
                                d="M386.5,182.8c-0.2-0.5-0.5-0.9-0.8-1.3l-31.9-39.6v-54c0-3.9-3.1-7-7-7H166.3c-3.9,0-7,3.1-7,7v50.9L125,183.9
                                              c-0.4,0.5-0.7,1-0.9,1.6c-0.3,0.7-6.4,16.1,1.7,28.3c4.6,6.9,12.6,11,23.7,12.1v120.2c0,3.9,3.1,7,7,7h200.7c3.9,0,7-3.1,7-7V225.7
                                              c10.7-1.5,18.2-5.6,22.5-12.4C394.9,200.2,386.9,183.5,386.5,182.8z M231.6,339.2v-84.6h56.9v84.6H231.6z M263.8,212.2v-62.6h17.4
                                              l16.8,62.6C287.2,212.2,275.6,212.2,263.8,212.2z M312.5,212.2l-16.8-62.6h16.9l33.6,62.6C338.9,212.2,327.8,212.2,312.5,212.2z
                                              M167.3,212.3l33.6-62.7h16.9L201,212.3C188,212.3,176.4,212.3,167.3,212.3z M215.5,212.2l16.8-62.6h17.6v62.6
                                              C238.2,212.2,226.5,212.2,215.5,212.2z M217.6,247.6v91.6h-54V226.3h186.7v112.9h-47.7v-91.6c0-3.9-3.1-7-7-7h-70.9
                                              C220.7,240.6,217.6,243.7,217.6,247.6z M137.4,206L137.4,206c-3.3-4.9-1.3-12.4-0.6-14.5l31.8-41.9h16.4l-33.5,62.5
                                              C144.5,211.5,139.7,209.4,137.4,206z M339.8,94.8v40.8H173.3V94.8H339.8z M342.1,149.6l32.1,39.9c0.9,2.2,4.1,10.8,0.7,16.3
                                              c-2.1,3.2-6.4,5.3-13,6.1l-33.4-62.3H342.1z"
                              />
                            </svg>
                            <>
                              {this.state.trackingDetails.driverAssigned ? (
                                // <DeliveryDiningIcon
                                //   style={{
                                //     color: '#01d162',
                                //     transform: 'translate(-50%, -100%)',
                                //   }}
                                //   fontSize={'large'}
                                //   lat={
                                //     this.state.trackingDetails.driverLocation
                                //       .latitude
                                //   }
                                //   lng={
                                //     this.state.trackingDetails.driverLocation
                                //       .longitude
                                //   }
                                //   // text={'Google Map'}
                                // />
                                <svg
                                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                  style={{
                                    fontSize: '50px',
                                    padding: '6px',
                                    transform: 'translate(-50%, -100%)',
                                  }}
                                  lat={
                                    this.state.trackingDetails.driverLocation
                                      .latitude
                                  }
                                  lng={
                                    this.state.trackingDetails.driverLocation
                                      .longitude
                                  }
                                >
                                  <rect
                                    id="Rectangle-6_4_"
                                    x="0.4"
                                    y="0.4"
                                    className="st0"
                                    width="511.2"
                                    height="511.2"
                                  />
                                  <g>
                                    <g>
                                      <path
                                        id="path-1_12_"
                                        className="st6"
                                        d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1
                                                  s-334.3-46.8-379.5,0s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5
                                                  C270.7,511.2,295.4,471.1,309.4,446.1z"
                                      />
                                    </g>
                                  </g>
                                  <path
                                    className="st7"
                                    d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                              c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                              c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                              c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                              C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                              c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                              c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                              c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                              C414.5,33.6,341.1,21.6,256,21.6z"
                                  />
                                  <path
                                    className="st3"
                                    d="M168.9,319.9c-1.6,0-3.1-0.1-4.7-0.3c-23.9-2.9-39.5-22.4-37-46.5c0-0.1,0-0.2,0.1-0.4h-7.8
                                              c-2.2,0-4.2-1.2-5.3-3.1l-15-26.2c-1.3-2.3-1-5.2,0.8-7.1l42.7-46.8c1.2-1.3,2.8-2,4.5-2h58.1c3.4,0,6.1,2.7,6.1,6.1
                                              s-2.7,6.1-6.1,6.1h-55.4l-37.9,41.6l11.1,19.3h172.5c7.9-13.9,23.7-39.9,32.5-43.9c3-1.4,6.7-0.1,8.1,3c1.4,3.1,0,6.7-3,8
                                              c-4.4,2.3-18.4,23.5-28.7,41.9c-1.1,1.9-3.1,3.1-5.3,3.1H139.2c0.1,0.5,0.1,1,0.1,1.6c-1.7,17.2,9.3,31.1,26.4,33.2
                                              c12.8,1.6,25.1-6.2,30.4-19.3c1.3-3.1,4.8-4.6,7.9-3.3c3.1,1.3,4.6,4.8,3.4,7.9C200.5,309.5,185.5,319.9,168.9,319.9z M370.3,319.9
                                              c-21.5,0-40-16.7-43-38.8c-0.5-3.3,1.9-6.4,5.2-6.9c3.2-0.4,6.4,1.9,6.9,5.2c2.2,16.1,15.5,28.3,31,28.3c17.3,0,31.3-14.8,31.3-33.1
                                              c0-5.4-1.2-10.7-3.6-15.4c-1.5-3-0.3-6.7,2.7-8.2c3-1.5,6.7-0.3,8.2,2.7c3.2,6.4,4.9,13.6,4.9,20.8
                                              C413.8,299.6,394.3,319.9,370.3,319.9z M335.6,268.4h-19.5c-3.4,0-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1h15.8c3.9-7,14.2-21.6,30.7-21.6
                                              c13.9,0,22.9,1.4,26.4,2l4.8-1.5c-3.7-6.7-12.9-18.1-34.8-24.3c-0.2-0.1-0.4-0.1-0.6-0.2c-2.6,1-5.7,0.2-7.3-2.3
                                              c0,0-45.2-66.9-46.5-68.9c-1.2-1.7-1.4-3.9-0.6-5.9c0.8-1.9,2.5-3.3,4.6-3.7l6.6-1.1l-0.6-13.6l-4.1-1l-19.3,13.5
                                              c-1,0.7-2.2,1.1-3.5,1.1l-23.1,0.1c0,0,0,0,0,0c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1l21.2,0l20-14c1.4-1,3.2-1.3,4.9-0.9
                                              l11.2,2.7c2.6,0.6,4.5,3,4.6,5.7l1,23.6c0.1,3.1-2,5.7-5.1,6.2l-2.5,0.4c10.1,15,31.6,46.8,39.6,58.6c0.9-0.2,1.9-0.1,2.8,0.1
                                              c38.9,11.1,45.3,37.4,45.6,38.5c0.7,3.1-1.1,6.2-4.1,7.2l-12.6,3.9c-1,0.3-2.2,0.4-3.2,0.1c-0.1,0-8.9-2-25.3-2
                                              c-13.3,0-21.3,17.7-21.4,17.9C340.2,267,338.1,268.4,335.6,268.4z M276,242.7h-63c-2.3,0-4.4-1.3-5.5-3.4c-1-2.1-0.8-4.6,0.7-6.4
                                              l27.3-35.3c1.2-1.5,2.9-2.5,5-2.4l5.6,0.1c0.5-5.3-1.1-15-15.9-23.8l-91.6-0.4c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.5-6.2,6.1-6.1
                                              l93.2,0.4c1,0,2,0.3,2.9,0.8c25.8,14.4,25.4,34.9,21.7,43.8c-1,2.3-3.3,3.7-5.7,3.7l-7.4-0.2l-17.8,23H273l27.3-37.3l-20.9-36.6
                                              c-1.7-2.9-0.7-6.6,2.3-8.3c2.9-1.7,6.7-0.6,8.3,2.3l22.9,40.1c1.2,2.1,1,4.7-0.4,6.6l-31.5,42.9C279.8,241.7,278,242.7,276,242.7z"
                                  />
                                </svg>
                              ) : null}
                            </>
                          </GoogleMapReact>
                          <>
                            {!this.state.trackingDetails.delivered ? (
                              <div
                                style={{
                                  position: 'relative',
                                  bottom: '126px',
                                  right: '10px',
                                  width: '200px',
                                  float: 'right',
                                }}
                              >
                                <div style={{ padding: '15px' }}>
                                  <div
                                    style={{
                                      backgroundColor: '#fff',
                                      borderRadius: '13px',
                                      height: '73px',
                                      boxShadow: '0px 4px 10px #64717a42',
                                      padding: '10px',
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="center"
                                    >
                                      <>
                                        {this.state.trackingDetails != null &&
                                        (this.state.trackingDetails[
                                          'isPickup'
                                        ] == null ||
                                          !this.state.trackingDetails[
                                            'isPickup'
                                          ]) ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Pedido número']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Solicitar ID']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['number']
                                          ? this.state.trackingDetails[
                                              'number'
                                            ].toString()
                                          : ''}
                                      </Typography>
                                    </Stack>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: '10px 20px 10px',
                          marginTop: '-38px',
                          zIndex: '9',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ffffff87',
                            boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                            borderRadius: '20px',
                            padding: '12px 16px 12px 16px',
                            backdropFilter: 'blur(5px)',
                          }}
                        >
                          <Stack
                            direction={'column'}
                            spacing={0}
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '50%',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '2px',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <BusinessSvg
                                      viewBox="-20 -40 550 550"
                                      style={{
                                        border: `2px solid #4dd884`,
                                        borderRadius: '50%',
                                        width: 'calc(100%)',
                                        padding: '6px',
                                        fontSize: '50px',
                                      }}
                                    />
                                  </AspectRatio>
                                ) : null}
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor: '#4DD884',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'acceptedByRest'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? '#4DD884'
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border: this.state.trackingDetails[
                                        'acceptedByRest'
                                      ]
                                        ? '0px solid #4dd884'
                                        : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    <StoreMallDirectoryIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails[
                                          'acceptedByRest'
                                        ]
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails[
                                          'acceptedByRest'
                                        ]
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                      }}
                                    />
                                  </AspectRatio>
                                ) : null}
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'driverAssigned'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? '#4DD884'
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'driverReached'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? '#4DD884'
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border: this.state.trackingDetails[
                                        'driverReached'
                                      ]
                                        ? '0px solid #4dd884'
                                        : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    <DeliveryDiningIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails[
                                          'driverReached'
                                        ]
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails[
                                          'driverReached'
                                        ]
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                      }}
                                    />
                                  </AspectRatio>
                                ) : null}
                              </>

                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'orderPickedUp'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? '#4DD884'
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor: this.state
                                          .trackingDetails['delivered']
                                          ? '#4DD884'
                                          : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border:
                                        this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails['cancelled']
                                          ? '0px solid #4dd884'
                                          : this.state.trackingDetails[
                                              'delivered'
                                            ] &&
                                            this.state.trackingDetails[
                                              'cancelled'
                                            ]
                                          ? '0px solid #D62B2B'
                                          : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    {/* <HomeIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor:this.state.trackingDetails['delivered'] &&  !this.state.trackingDetails['cancelled']  ? '#4DD884'  : this.state.trackingDetails['delivered'] && this.state.trackingDetails['cancelled'] ? '#D62B2B'  : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails['delivered'] && !this.state.trackingDetails['cancelled']  ? '#4DD884'  : this.state.trackingDetails['delivered'] &&  this.state.trackingDetails['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                      }}
                                    /> */}

                                    <WhereToVoteIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor:
                                          this.state.trackingDetails[
                                            'delivered'
                                          ] &&
                                          !this.state.trackingDetails[
                                            'cancelled'
                                          ]
                                            ? '#4DD884'
                                            : this.state.trackingDetails[
                                                'delivered'
                                              ] &&
                                              this.state.trackingDetails[
                                                'cancelled'
                                              ]
                                            ? '#D62B2B'
                                            : '#C4C1C0',
                                        padding: '6px',
                                        color:
                                          this.state.trackingDetails[
                                            'delivered'
                                          ] &&
                                          !this.state.trackingDetails[
                                            'cancelled'
                                          ]
                                            ? '#4DD884'
                                            : this.state.trackingDetails[
                                                'delivered'
                                              ] &&
                                              this.state.trackingDetails[
                                                'cancelled'
                                              ]
                                            ? '#D62B2B'
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </AspectRatio>
                                ) : null}
                              </>
                            </Stack>
                            <div style={{ padding: '15px 0px 0px' }}></div>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#2e3436',
                                textAlign: 'center',
                              }}
                            >
                              {getStat(this.state.trackingDetails)}
                            </Typography>
                            <div style={{ padding: '15px 0px 0px' }}></div>
                            <Stack
                              onClick={() => {
                                this.setState({
                                  toggleDetails: !this.state.toggleDetails,
                                });
                              }}
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  color: '#7E7D81',
                                  cursor: 'pointer',
                                }}
                              >
                                {strings['Ver más']}
                              </Typography>
                              <div style={{ padding: '0px 2px 0px' }}></div>
                              <>
                                {!this.state.toggleDetails ? (
                                  <KeyboardArrowDownIcon
                                    style={{
                                      fontSize: '24px',
                                      fontWeight: 'normal',
                                      color: '#7E7D81',
                                      cursor: 'pointer',
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    style={{
                                      fontSize: '24px',
                                      fontWeight: 'normal',
                                      color: '#7E7D81',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </>
                            </Stack>
                            <div style={{ padding: '5px 0px 0px' }}></div>
                            <>
                              {this.state.toggleDetails ? (
                                <div>
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['Esperando a que el negocio acepte su pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['Te avisaremos una vez que el negocio acepte tu pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['El negocio está preparando tu producto']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido está siendo preparado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' está en camino para recoger tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' está en camino para recoger tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' llegó al negocio']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha llegado al negocio y recogerá tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha recogido tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha recogido tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu orden ha sido entregada']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido entregado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido cancelado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'reason'
                                            ] == null
                                              ? strings['Tu pedido ha sido cancelado']
                                              : this.state.trackingDetails[
                                                  'reason'
                                                ]}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Divider
                                            style={{
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '600',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu ubicación']}:{' '}
                                            <span
                                              style={{
                                                color: 'slategray',
                                                fontWeight: '400',
                                              }}
                                            >
                                              {
                                                this.state.trackingDetails[
                                                  'userLocation'
                                                ]['geoAddress']
                                              }
                                            </span>
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                    </Stack>
                                  </Stack>
                                </div>
                              ) : null}
                            </>
                          </Stack>
                        </div>
                      </div>
                      <>
                        {!this.state.trackingDetails['delivered'] ? (
                          <div
                            style={{
                              padding: '5px 10px 5px',
                              borderTop: '1px solid #d8d8d8',
                              marginTop: '10px',
                            }}
                          >
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="center"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <div
                                    style={{
                                      borderRadius: '10px',
                                      width: 'calc(15%)',
                                      paddingTop: '10px',
                                    }}
                                  >
                                    <div style={{ padding: '1.75px' }}>
                                      <img
                                        src={
                                          this.state.trackingDetails['restLogo']
                                        }
                                        alt="logo"
                                        // width={'50px'}
                                        style={{
                                          borderRadius: '12px',
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: '5px 15px 0px',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['restName']}
                                      </Typography>
                                      <Stack
                                        direction={'row'}
                                        spacing={0}
                                        alignItems="center"
                                      >
                                        <StarIcon
                                          style={{
                                            fontSize: '16px',
                                            color: '#01d162',
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {/* {' ' +
                                            this.state.businessData[
                                              'averageRating'
                                            ].toFixed(2)} */}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Stack>
                                <div style={{ padding: '0px 5px 0px' }}>
                                  <div
                                    onClick={() => {
                                      window.open(
                                        'tel://' +
                                          this.state.trackingDetails[
                                            'restContact'
                                          ],
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      width: '38px',
                                      height: '38px',
                                      backgroundColor: '#33343b',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      padding: '4px 6px 4px 8px',
                                    }}
                                  >
                                    <LocalPhoneIcon
                                      style={{
                                        fontSize: '23px',
                                        color: '#fff',
                                      }}
                                    />
                                  </div>
                                </div>
                              </Stack>
                            </Stack>
                          </div>
                        ) : null}
                      </>

                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <Divider />
                      <>
                        {this.state.trackingDetails['driverAssigned'] &&
                        !this.state.trackingDetails['delivered'] ? (
                          <div style={{ padding: '5px 10px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="center"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <div
                                    style={{
                                      backgroundColor: '#f8f9fb',
                                      boxShadow: '0px 0px 5px #aaa',
                                      borderRadius: '100px',
                                      width: 'calc(12%)',
                                    }}
                                  >
                                    <div style={{ padding: '1.75px' }}>
                                      <img
                                        src={
                                          this.state.trackingDetails[
                                            'driverAvatar'
                                          ]
                                        }
                                        alt="logo"
                                        // width={'50px'}
                                        style={{
                                          borderRadius: '100px',
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: '5px 15px 0px',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '18px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {
                                          this.state.trackingDetails[
                                            'driverName'
                                          ]
                                        }
                                      </Typography>
                                      <Stack
                                        direction={'row'}
                                        spacing={0}
                                        alignItems="center"
                                      >
                                        <StarIcon
                                          style={{
                                            fontSize: '18px',
                                            color: '#01d162',
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {' ' +
                                          this.state.trackingDetails[
                                            'driverRating'
                                          ]
                                            ? this.state.trackingDetails[
                                                'driverRating'
                                              ].toFixed(2)
                                            : ''}
                                        </Typography>
                                        <div
                                          style={{ padding: '0px 7.5px 0px' }}
                                        ></div>
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {strings['Nivel: '] +
                                          this.state.trackingDetails[
                                            'driverLevel'
                                          ]
                                            ? this.state.trackingDetails[
                                                'driverLevel'
                                              ].toString()
                                            : '1'}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Stack>
                                <div style={{ padding: '0px 5px 0px' }}>
                                  <div
                                    onClick={() => {
                                      window.open(
                                        'tel://' +
                                          this.state.trackingDetails[
                                            'driverContact'
                                          ],
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      width: '45px',
                                      height: '45px',
                                      backgroundColor: constants.secondaryColor,
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      padding: '4px 6px 4px',
                                    }}
                                  >
                                    <LocalPhoneIcon
                                      style={{
                                        fontSize: '27px',
                                        color: '#fff',
                                      }}
                                    />
                                  </div>
                                </div>
                              </Stack>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <div style={{ padding: '15px 0px 0px' }}></div>
                      <Divider />
                      <>
                        {' '}
                        {this.state.trackingDetails != null &&
                        (this.state.trackingDetails['isPickup'] == null ||
                          !this.state.trackingDetails['isPickup']) ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Dirección de entrega']}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                              >
                                {
                                  this.state.trackingDetails['userLocation'][
                                    'geoAddress'
                                  ]
                                }
                              </Typography>
                              <>
                                {this.state.trackingDetails['userLocation'][
                                  'addressNotes'
                                ] != null &&
                                this.state.trackingDetails['userLocation'][
                                  'addressNotes'
                                ] !== '' ? (
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#6A6A6E',
                                    }}
                                  >
                                    {
                                      this.state.trackingDetails[
                                        'userLocation'
                                      ]['addressNotes']
                                    }
                                  </Typography>
                                ) : null}
                              </>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <>
                        {' '}
                        {this.state.trackingDetails != null &&
                        this.state.trackingDetails['isPickup'] != null &&
                        this.state.trackingDetails['isPickup'] ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Para recoger tu pedido debes dirigirte a:']}
                              </Typography>
                              <Typography
                                onClick={() => {
                                  window.open(
                                    'https://www.google.com/maps/search/?api=1&query=' +
                                      this.state.trackingDetails.restLocation.latitude.toString() +
                                      '%2C' +
                                      this.state.trackingDetails.restLocation.longitude.toString(),
                                    '_blank'
                                  );
                                }}
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                              >
                                {
                                  this.state.trackingDetails['restLocation'][
                                    'address'
                                  ]
                                }
                              </Typography>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Método de pago']}
                          </Typography>
                          <>
                            {!this.state.trackingDetails['onlinePayment'] ? (
                              <Stack direction={'row'} spacing={0}>
                                <>
                                  {this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] != null &&
                                  this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] ? (
                                    <CreditCardIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#c96812',
                                        marginTop: '2px',
                                      }}
                                    />
                                  ) : (
                                    <MoneyIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#54bd66',
                                        marginTop: '2px',
                                      }}
                                    />
                                  )}
                                </>
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <>
                                  {this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] != null &&
                                  this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] ? (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Tarjeta contra entrega']}
                                      </Typography>
                                    </>
                                  ) : (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Efectivo']}
                                      </Typography>
                                    </>
                                  )}
                                </>
                                <div style={{ padding: '0px 5.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: '#6A6A6E',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {this.state.trackingDetails['onlinePayment'] ? (
                              <Stack direction={'row'} spacing={0}>
                                <CreditCardIcon
                                  style={{
                                    fontWeight: 'normal',
                                    color: '#2c59a8',
                                  }}
                                />
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Tarjeta']}
                                </Typography>
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: '#6A6A6E',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                        </Stack>
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Día y hora de su pedido']}
                          </Typography>
                          <Stack
                            direction={'row'}
                            spacing={0}
                            alignItems="center"
                          >
                            <AccessTimeFilledIcon
                              style={{
                                fontSize: '20px',
                                fontWeight: 'normal',
                                color: '#4b596a',
                              }}
                            />{' '}
                            <div style={{ padding: '0px 2.5px 0px' }}></div>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#000',
                              }}
                            >
                              {moment
                                (this.state.trackingDetails['createdOn'].toDate()).add((this.state.trackingDetails['preOrderedOn']?40:0),'minutes')
                                .format('DD-MM-YYYY hh:mm a')}
                            </Typography>
                          </Stack>
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        </Stack>
                      </div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Enviar cubiertos']}
                              </Typography>
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                              >
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {this.state.trackingDetails['giveSpoon'] !=
                                    null &&
                                  this.state.trackingDetails['giveSpoon']
                                    ? strings['Sí']
                                    : strings['No']}
                                </Typography>
                              </Stack>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '0px 20px 0px' }}>
                            <Divider />
                          </div>
                        ) : null}
                      </>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        ) : null}
                      </>

                      <div style={{ padding: '5px 20px 5px', width: '100%' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Detalles del pedido:']}
                          </Typography>
                          <List style={{ width: '100%' }}>
                            {this.state.trackingDetails['items'].map(
                              (customize, index) => (
                                <div
                                  key={'tracking-deals' + index}
                                  style={{
                                    padding: '5px 0px 0px',
                                    width: '100%',
                                  }}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['items'][
                                          index
                                        ]['quantity'].toString() +
                                          ' X ' +
                                          (this.state.trackingDetails['items'][
                                            index
                                          ]['nameEng'] != null
                                            ? this.state.trackingDetails[
                                                'items'
                                              ][index]['nameEng']
                                            : this.state.trackingDetails[
                                                'items'
                                              ][index]['name'])}
                                      </Typography>
                                      <div style={{ padding: '3px 0px 0px' }}>
                                        <List>
                                          {this.state.trackingDetails['items'][
                                            index
                                          ]['customize'].map((customize, t) => (
                                            <>
                                              {this.state.trackingDetails[
                                                'items'
                                              ][index]['customize'][t][
                                                'options'
                                              ].length > 0 ? (
                                                <Typography
                                                  style={{
                                                    fontSize: '13px',
                                                    fontWeight: 'normal',
                                                    color: 'slategray',
                                                  }}
                                                >
                                                  {findTotalT(
                                                    index,
                                                    t,
                                                    this.state.trackingDetails
                                                  )}
                                                </Typography>
                                              ) : null}
                                            </>
                                          ))}
                                        </List>
                                      </div>
                                    </Stack>
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-end"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {'$' +
                                          Number(
                                            this.state.trackingDetails['items'][
                                              index
                                            ]['price'] *
                                              this.state.trackingDetails[
                                                'items'
                                              ][index]['quantity']
                                          )
                                            .toFixed(2)
                                            .toString()}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </div>
                              )
                            )}
                                                        {(this.state.trackingDetails['removedItems']==null && this.state.trackingDetails['selectedOffer']!=null && this.state.trackingDetails['selectedOffer']['id']!=null && this.state.trackingDetails['selectedOffer']['isFreeItem']!=null && this.state.trackingDetails['selectedOffer']['isFreeItem'])?
                            <div
                                  style={{
                                    padding: '5px 0px 0px',
                                    width: '100%',
                                  }}
                                  key={this.state.trackingDetails['items'].length}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {'1 X ' +
                                          (this.state.trackingDetails['selectedOffer']['freeItems'][0]
                                                      ['name'])}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-end"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Gratis']}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </div>:null}
                          </List>
                          <div style={{ padding: '10px 0px 0px' }}></div>
                          {/* <div style={{padding: "0px 20px 0px"}}>
                    <Divider />
                   </div> */}
                        </Stack>
                      </div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '10px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px', width: '100%' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                        >
                          <div style={{ width: '100%' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                            >
                              <div
                                style={{
                                  padding: '0px 0px 5px',
                                  width: '100%',
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#33343a',
                                    }}
                                  >
                                    {strings['Total parcial']}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#33343a',
                                    }}
                                  >
                                    {'$' +
                                      this.state.trackingDetails[
                                        'itemCost'
                                      ].toFixed(2)}
                                  </Typography>
                                </Stack>
                              </div>
                              <>
                                {this.state.trackingDetails['driverService'] !=
                                  null &&
                                this.state.trackingDetails['driverService'] >
                                  0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Tarifa de servicio']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          this.state.trackingDetails[
                                            'driverService'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['deliveryCharge'] !=
                                  null &&
                                this.state.trackingDetails['deliveryCharge'] >
                                  0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Costo de envío']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          (
                                            this.state.trackingDetails[
                                              'deliveryCharge'
                                            ] +
                                            (this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] != null &&
                                            this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] > 0
                                              ? this.state.trackingDetails[
                                                  'changeAddressExtraCost'
                                                ] + 0.0
                                              : 0.0)
                                          ).toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['tip'] != null &&
                                this.state.trackingDetails['tip'] > 0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Propina']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          this.state.trackingDetails[
                                            'tip'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails[
                                  'dynamicDelivery'
                                ] != null &&
                                this.state.trackingDetails[
                                  'dynamicDelivery'
                                ] ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'(' +
                                          this.state.trackingDetails[
                                            'reasonDescription'
                                          ].toString() +
                                          ')'}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['offerValue'] !=
                                  null &&
                                this.state.trackingDetails['offerValue'] > 0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Descuento']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'-$' +
                                          this.state.trackingDetails[
                                            'offerValue'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                            </Stack>
                          </div>
                          <>
                            {this.state.trackingDetails['onlinePayment'] &&
                            ((this.state.trackingDetails['isOxxoEnabled'] !=
                              null &&
                              this.state.trackingDetails['isOxxoEnabled']) ||
                              (this.state.trackingDetails[
                                'changeAddressExtraCost'
                              ] != null &&
                                this.state.trackingDetails[
                                  'changeAddressExtraCost'
                                ] > 0)) ? (
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Pagado con '] +
                                    (this.state.trackingDetails[
                                      'paypalPayment'
                                    ] != null &&
                                    this.state.trackingDetails['paypalPayment']
                                      ? 'paypal'
                                      : strings['tarjeta'])}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {'$' +
                                    this.state.trackingDetails[
                                      'grandTotalWithoutOxxo'
                                    ].toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {this.state.trackingDetails['onlinePayment'] &&
                            ((this.state.trackingDetails['isOxxoEnabled'] !=
                              null &&
                              this.state.trackingDetails['isOxxoEnabled']) ||
                              (this.state.trackingDetails[
                                'changeAddressExtraCost'
                              ] != null &&
                                this.state.trackingDetails[
                                  'changeAddressExtraCost'
                                ] > 0)) ? (
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Pagar en efectivo']}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] -
                                      this.state.trackingDetails[
                                        'grandTotalWithoutOxxo'
                                      ] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {true ? (
                              <div style={{ width: '100%' }}>
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold',
                                      color: '#2c3136',
                                      marginTop: '20px',
                                    }}
                                  >
                                    {strings['Total']}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold',
                                      color: '#2c3136',
                                      marginTop: '20px',
                                    }}
                                  >
                                    {'$' +
                                      (
                                        this.state.trackingDetails[
                                          'grandTotal'
                                        ] +
                                        (this.state.trackingDetails[
                                          'changeAddressExtraCost'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'changeAddressExtraCost'
                                        ] > 0
                                          ? this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] + 0.0
                                          : 0.0)
                                      ).toFixed(2)}
                                  </Typography>
                                </Stack>
                              </div>
                            ) : null}
                          </>
                        </Stack>
                      </div>
                      <div style={{ padding: '60px 0px 0px' }}></div>
                    </Stack>
                  ) : null}
                </>
                {/* </div> */}
              </Stack>
            </Container>
          }
        ></TrackDialog>

        <Backdrop
          onClick={() => {}}
          style={{
            color: '#fff',
            zIndex: 9999,
            maxHeight: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
            height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          }}
          open={false}
        >
          <React.Fragment>
            <CssBaseline />
          </React.Fragment>
        </Backdrop>

        {/* <Backdrop
          id="track2backdrop"
          onClick={() => {}}
          style={{
            color: '#fff',
            zIndex: 9999,
            maxHeight: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
            height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          }}
          open={this.state.showTracking}
        >
          <React.Fragment>
            <CssBaseline />
            <>
              {this.state.width > 600 ? (
                <Container
                  style={{
                    flex: 1,
                    height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                  }}
                  onClick={() => {
                    this.setState({
                      showTracking: false,
                      trackingDetails: null,
                      trackingId: null,
                    });
                  }}
                ></Container>
              ) : null}
            </>

          </React.Fragment>
        </Backdrop> */}
      </>
    ) : (
      <>
        <div id="loading_indicator" className="container">
          <img
            style={{ width: this.state.width < 1000 ? '32vw' : '15vw' }}
            src={logo}
            alt="loading"
          />
        </div>
      </>
    );
  }
}

export default withRouter(Detail);
export const Profile = withRouter(ProfilePage);
export const Orders =  withRouter(OrdersPage);
