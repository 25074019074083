import './Main.css';
import './Register.css';
import * as React from 'react';
import { Alert, AppBar, Autocomplete, Backdrop, Badge, BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Container, CssBaseline, Dialog, DialogContent, DialogTitle, Divider, FilledInput, FormControl, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, Snackbar, Stack, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as BusinessSvg } from './assets/business.svg';
import ldb from 'localdata'
import * as geolib from 'geolib';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MedicationIcon from '@mui/icons-material/Medication';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MoneyIcon from '@mui/icons-material/Money';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import Tooltip from '@mui/material/Tooltip';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { AccountCircle, LocationOn, Visibility, VisibilityOff } from '@mui/icons-material';
import Geocode from 'react-geocode';
import _ from 'lodash';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import StarIcon from '@mui/icons-material/Star';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DataSaverOnRoundedIcon from '@mui/icons-material/DataSaverOnRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AppleIcon from '@mui/icons-material/Apple';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import validator from 'validator';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import moment from 'moment';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import GoogleMapReact from 'google-map-react';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CancelIcon from '@mui/icons-material/Cancel';
import AndroidIcon from '@mui/icons-material/Android';
import { strings } from './translations';
import { auth, db } from './firebaseData';
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import en from 'react-phone-number-input/locale/en.json';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import axios from 'axios';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { isIOS } from 'react-device-detect';
import { constants } from './components/constants';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InstagramIcon from '@mui/icons-material/Instagram';
import Carousel from 'react-material-ui-carousel';
import { AspectRatio } from 'react-aspect-ratio';
import OtpInput from 'react18-otp-input';

const logo = require('./assets/loading.gif');
const mainLogo = require('./assets/logo.png');
const mainLogo2 = require('./assets/logo2.png');
const userOn1 = require('./assets/user-on-1.png');
const userOn2 = require('./assets/user-on-2.png');
const Dandroid = require('./assets/Dandroid.png');
const Dapple = require('./assets/Dapple.png');

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <FormControl variant="filled" sx={{ minWidth: 75 }}>
    <InputLabel id="demo-simple-select-filled-label">Code</InputLabel>
    <Select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <MenuItem value="">{labels['ZZ']}</MenuItem>
      {getCountries().map((country) => (
        // <MenuItem key={country} value={"+"+getCountryCallingCode(country)}>
        //   {labels[country]} +{getCountryCallingCode(country)}
        // </MenuItem>
        <MenuItem key={country} value={'+' + getCountryCallingCode(country)}>
          +{getCountryCallingCode(country)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

const getAsyncItem = async (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ldb.get(value, function (value2) {
        //console.log('value',value);
        if(value === 'displayRestOpen') {
          //console.log('displayRestOpen', value2);
        }
        resolve(value2);
      })
    } catch(e) {
      //console.log(value,e);
      reject(new Error("Whoops!"));
    }
});
}


function SimpleDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Mis direcciones']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function TrackDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      className="MuiDialogtest-container"
      sx={{ zIndex: 9999, margin: 0, float: 'right !important' }}
      fullScreen={title > 600 ? false : true}
      maxWidth={title > 600 ? '400px' : '100vh'}
      alignContent="flex-end"
      justifyContent="flex-end"
      onClose={handleClose}
      open={open}
      scroll="body"
      PaperProps={{
        square: true,
        sx: {
          maxWidth: title > 600 ? '400px' : 'auto',
          height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          maxHeight: isIOS
            ? 'calc((var(--vh, 1vh) * 100)) !important'
            : '100vh !important',
          margin: 0,
          overflow: 'hidden',
          float: 'right',
        },
      }}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
                      fontSize: this.state.width > 600 ? '24px' : '22px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
      >
                    {'Tu canasta'}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

TrackDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.number.isRequired,
};

function AlertDialog2(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList, displayTitle } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  return (
    <Dialog
      fullScreen={false}
      onClose={handleClose}
      style={{ zIndex: 199999 }}
      open={open}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle sx={{ m: 0, p: 2 }}>
        {displayTitle}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {displayList}
    </Dialog>
  );
}

AlertDialog2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
  displayTitle: PropTypes.string.isRequired,
};

function AllergyDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList, title } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose();
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      className="MuiDialogtest-container"
      sx={{ zIndex: 9999, margin: 0, float: 'right !important' }}
      fullScreen={title > 600 ? false : true}
      maxWidth={title > 600 ? '400px' : '100vh'}
      alignContent="flex-end"
      justifyContent="flex-end"
      onClose={handleClose}
      open={open}
      scroll="body"
      PaperProps={{
        square: true,
        sx: {
          maxWidth: title > 600 ? '400px' : 'auto',
          height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
          maxHeight: isIOS
            ? 'calc((var(--vh, 1vh) * 100)) !important'
            : '100vh !important',
          margin: 0,
          overflow: 'hidden',
          float: 'right',
        },
      }}
      disableEscapeKeyDown={true}
    >
      {/* <DialogTitle
        sx={{ m: 0, p: 2 }}
        style={{
                      fontSize: this.state.width > 600 ? '24px' : '22px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
      >
                    {'Tu canasta'}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

AllergyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element,
  title: PropTypes.number.isRequired,
};

function RegisterAdditionalDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.
  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }
  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }
  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };
  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Registro']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}
RegisterAdditionalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};
function OTPDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.
  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }
  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }
  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };
  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };
  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['OTP']}
        {/* {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null} */}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}
OTPDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function MyCardsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['profileMyCard']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

MyCardsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};
function WalletDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };

  return (
    <Dialog
    style={{zIndex: 99999}}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Cartera']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {displayList}
    </Dialog>
  );
}

WalletDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  displayList: PropTypes.element.isRequired,
};
function PreOrderDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    // if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      // style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings["Programar tu entrega"]}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

PreOrderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function RegisterDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {strings['Registro']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

RegisterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};

function LoginDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // this.locOptions = this.locOptions.bind(this);
  // This key was created specifically for the demo in mui.com.
  // You need to create a new one for your application.

  // function loadScript(src, position, id) {
  //   if (!position) {
  //     return;
  //   }

  //   const script = document.createElement('script');
  //   script.setAttribute('async', '');
  //   script.setAttribute('id', id);
  //   script.src = src;
  //   position.appendChild(script);
  // }

  // const autocompleteService = { current: null };
  const { onClose, selectedValue, open, displayList } = props;

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose(selectedValue);
  };
  // const onBackdropClick = () => {
  //   // onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  // var locOptions = [];
  // // const defaultProps = {
  // //   center: {
  // //     lat: 10.99835602,
  // //     lng: 77.01502627
  // //   },
  // //   zoom: 11
  // // };

  return (
    <Dialog
      style={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} style={{ fontSize: '1.5rem', }}>
        {strings['Ingresar']}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {/* <DialogTitle>My Addresses<Box display="flex" justifyContent="flex-end" onClick={handleClose} style={{margin: '5px 0px 0px 0px', float: 'right'}}><CancelIcon /></Box> </DialogTitle>   */}
      {displayList}
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  displayList: PropTypes.element.isRequired,
};
class RegisterPage2 extends React.Component {

  constructor(props, context) {
    super(props, context);
    this._ismounted = true;
    this.state = {
        searchTextField: "123",
        city2: "",
        cityLat: "",
        cityLng: "",
        cities: [],
        selectedCity: "",
        selectedCityId: "",
        restName: "",
        restType: "",
        restEmail: "",
        restPerson: "",
        myInput: "",
        restPhone:"",
        restPos: "",
        nameerror: false,
        addresserror: false,
        typeerror: false,
        cityerror: false,
        emailerror: false,
        inchargeerror: false,
        phoneerror: false,
        poserror: false,
        showwhataspp: false,
        showwhataspp2: false,
        showwhataspp3: "",
      selectedPos: 0,
      balance: 0,
      isUnderFav: false,
      loadCityAgain: true,
      searchText: '',
      restOpen: [],
      restClosed: [],
      storeOpen: [],
      storeClosed: [],
      groceriesOpen: [],
      groceriesClosed: [],
      pharmacyOpen: [],
      pharmacyClosed: [],
      displayOpen: [],
      displayClosed: [],
      favorites: [],
      orders: [],
      addedNotes: false,
      orderLoading: false,
      codeData: '',
      codeError: false,
      canPickup: false,
            toggledOff:false,
            cashOnly:false,
      newAllergy: '',
      busId: '',
      alertMsg: '',
      openAddCard: false,
      allergyActivate: true,
      newCard: {
        cardNo: '',
        cvv:'',
        month: '',
        year: '',
        expiry: '',
        name: '',
        'save': false,
      },
      categoriesList: [
        // {name: strings['Fitness'],image: "Fitness.jpg"},
        // {name: strings['Comida Rápida'],image: "FastFood.jpg"},
        // {name: strings['Alitas'],image: "Wings.jpg"},
        // {name: strings['Tacos'],image: "Tacos.jpg"},
        // {name: strings['Pizzas'],image: "Pizzas.jpg"},
        // {name: strings['Hamburguesas'],image: "Burgers.jpg"},
        // {name: strings['Oriental'],image: "Oriental.jpg"},
        // {name: strings['Pollos'],image: "Chickens.jpg"},
        // {name: strings['Mariscos'],image: "SeaFood.jpg"},
        // {name: strings['Mexicana'],image: "Mexican.jpg"},
        // {name: strings['Internacional'],image: "International.jpg"},
        // {name: strings['Postres'],image: "Deserts.jpg"},
      ],
      categoriesStore: [],
      selectLocationName: null,
      showAllergyDisclaimer: true,
      canDeliverHere: false,
      openAlert: false,
      showCart: false,
      showAllergy: false,
      showTracking: false,
      trackingDetails: null,
      toggleDetails: false,
      trackingId: null,
      showDiscount: false,
      showPayment: false,
      alertType: 'error',
      isMounted: true,
      anchorEl: null,
      open: false,
      openLocation: false,
      openCards: false,
      openPreOrder: false,
      showTomorrow: false,
      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: null,
      currenciesList2Selected: null,
      currenciesListSelected: null,
      currenciesList3Selected: null,
      currenciesList4Selected: null,
      currenciesList5Selected: null,
      currenciesList6Selected: null,
      currenciesList7Selected: null,
      currenciesList8Selected: null,
      openLogin: false,
      showGuest: false,
      isGuest: false,
      guestOrder: null,
      showAdditionalDetails: false,
      guestDetails: {
        'name': '',
        'email': '',
        'phone': ''
      },
      openBusinessInfo: false,
      password: '',
      email: '',
      fullName: '',
      offersDetails: {},
      tempOrder: {},
      hasAllData: true,
      productIds: [],
      otp: '',
      otpSend: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
      openRegister: false,
      openAdditionalRegister: false,
      userCredential: null,
      hasNoData: false,
      openOTP: false,
      adminData: null,
      showMinimum: false,
      openDialogAlert: false,
      openDialogCancel: false,
      openVariable: false,
      selectedProduct: null,
      userId: null,
      showAllergyData: true,
      allergy: [],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      businessData: null,
      locOptions: [],
      offers: [],
      offers2: [],
      addresses: [],
      defaultCountry: 'ES',
      selectedLocationDetails: {},
      cards: [],
      loading: true,
      offersUsed: [],
      cart: {
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        totalCost: 0,
        totalItems: 0,
        item: [],
        totalOxxoCost: 0,
        totalOxxoItems: 0,
        oxxoNameData: '',
        oxxoLogoImage: '',
        oxxoItem: [],
      },
      delivery: 0,
      selectedMenuIndex: 0,
      menuProductItems: [],
      selectedType: 0,
      hasClickedLoc: false,
      autoLoc: '',
      offerDetails: {},
      onlyPickup: false,
      storeIsOpen: false,
      deliveryFee: 0,
      dynamicFeeType: 0,
      selectLocationData: {
        id: '',
        name: '',
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: 10.99835602,
        longitude: 77.01502627,
      },
      cookingInstructions: '',
      spoon: false,
      selectedAddress: {
        latitude: 0,
        longitude: 0,
      },
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      circleData: null,
      defaultPropsCircle: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      mobileMoreAnchorEl: null,
      menuData: [],
      menu: [],
      upsellingProd: [],
      upsellingProd2: [],
      userDetails: null,
      openWallet: false,
      showOtherWallet: false,
      width: 0,
      height: 0,
      showAddressModal: false,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      isTomorrow: false,
      isToday: false,
      isPreOrder: false,
      isNewUser: true,
      isStore: false,
      order: {
        offerCode: '',
        selectedOffer: {},
        offerId: '',
        offerValue: 0,
        cashbackValue: 0,
        offerCode2: '',
        offerId2: '',
        offerValue2: 0,
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        itemCost: 0,
        deliveryCharge: 0,
        usageFee: 0,
        taxes: 0,
        serviceFee: 0,
        grandTotal: 0,
        items: [],
      },
      offerCode: '',
      selectedOffer: null,
      offerId: '',
      offerSuccess: '',
      offerErrorText: '',
      offerValue: 0,
      cashbackValue: 0,
      offerInd: null,
      isOfferApplied: false,
      isOfferError: false,
      isFreeDelivery: false,
      deliveryInstructions: '',
      defaultCard: null,
      toggleDelivery: true,
      tipPercent: 0,
      dynamicDelivery: false,
      additionalDelivery: 0,
      normalDelivery: 0,
      additionalDeliveryPercentage2: 0,
      reasonTitle: '',
      reasonDescription: '',
      isStore2: false,
      showMap: false,
      currenciesList: [],
      currenciesList3: [],
      currenciesList4: [],
      currenciesList5: [],
      currenciesList6: [],
      currenciesList7: [],
      currenciesList8: [],
      currenciesList2: [],
      vh: window.innerHeight * 0.01,
    };
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);
        this.setState({
          orders: [],
        });
        var orders = [];
        const q = query(
          collection(db, 'orders'),
          where('userId', '==', uid),
          where('delivered', '==', false),
          orderBy('createdOn', 'desc')
        );
        await onSnapshot(q, async (data) => {
          this.setState({
            orders: [],
          });
          if (data.docs.length <= 0 && this.state.trackingId) {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }
          data.docs.forEach(async (document) => {
            orders = orders.filter((element) => element.id !== document.id);
            if (
              this.state.trackingId &&
              this.state.trackingId === document.id
            ) {
              await this.setState({ trackingDetails: document.data() });
            }
            orders.push(document);
            await this.setState({ orders: orders });
            // console.log('orders', orders);
          });
        });

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
            this.setState({ userCredential: null });
            if (docMenuSnap.data().phoneNumberVerified) {
              if (this.state.openLogin) {
                this.setState({ loading: false, openLogin: false });
                this.setState({
                  openAlert: true,
                  alertType: 'success',
                  alertMsg: strings['Inicio de sesión exitoso'],
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
              // if (docMenuSnap.data()['custId'] == null) {
              //   try {
              //     var response = await axios.post(
              //       'https://traitte-order.herokuapp.com/drivers/createcust/',null,
              //       {
              //       params: {
              //         'email': docMenuSnap.data().email,
              //         'name': docMenuSnap.data().fullName,
              //         'phone': docMenuSnap.data().phoneNumber,
              //         'uid': uid,
              //       },
              //     }
              //     );
              //     if (response.status === 200) {
              //       console.log(response.data['id']);
              //       if (response.data['id'] != null) {
              //       } else {
              //         console.log('failed');
              //       }
              //     } else {
              //       console.log('failed');
              //     }
              //   } catch (e) {
              //     console.log('exception $e',e);
              //   }
              // } else {
              //   // custId = (event.data() as Map)['custId'].toString();
              //   // print(custId);
              //   // //custId = 'cus_JwCyg2xRg0XoOT';
              //   // updateCards();
              // }
              
            } else {
              if(!this.state.openOTP && this.state.isMounted && this._ismounted) {
                if (this.state.openLogin) {
                  this.setState({ loading: false, openLogin: false });
                }
              this.setState({
                openOTP: true,
                phoneNumber: docMenuSnap.data().phoneNumber,
                phoneCountry: '+52',
                showPassword: false,
              });
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend,this.state.isMounted, this._ismounted);
              this.setState({ otpSend: dataSend });
              await axios
                .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-new-otp/', {
                  code: dataSend,
                  number: docMenuSnap.data().phoneNumber,
                  uuid: uid
                })
                .then(async (response) => {
                  console.log(response);
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
            }
          } else {
            console.log(user.providerData[0].providerId);
           if(user.providerData[0].providerId.toLowerCase().includes('google')) {
            this.setState({ loading: false, openLogin: false });
            // handleClickOpenAdditionalRegister();
            this.setState({
              openAdditionalRegister: true,
              userCredential: user,
              password: '',
              email: user.providerData[0].email,
              fullName: user.providerData[0].displayName,
              otp: '',
              phoneNumber: '',
              phoneCountry: '+52',
              showPassword: false,
            });
            window.history.pushState(null, '', window.location.href);
      
            window.onpopstate = () => {
              window.onpopstate = () => {};
              window.history.back();
              auth.signOut();
              this.setState({
                openAdditionalRegister: false,
                password: '',
                email: '',
                fullName: '',
                otp: '',
                phoneNumber: '',
                phoneCountry: '+52',
                showPassword: false,
              });
            };
          } else {
            // console.log('openlogin',this.setState.openLogin);
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: 'Error de inicio de sesión',
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });
              auth.signOut();
            }
          }
        }
        });
        // ...
      } else {
        this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });

        //       signInWithEmailAndPassword(auth, 'demo@demo.com', 'demo1234')
        // .then((userCredential) => {
        //   // Signed in
        //   console.log('signing',userCredential);
        //   const user = userCredential.user;
        //   const uid = user.uid;
        //   this.setState({ userId: uid });
        //   // ...
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   console.log(errorMessage,errorCode);
        // });
        // User is signed out
        // ...
      }
    });

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setState({ vh: vh });
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      this.setState({ vh: vh });
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
}

componentDidMount() {
  this.checkTotal();
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

async checkTotal() {
  var totalValues;
    try {
      totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
    } catch(e) {
      //console.log(e);
      totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
    }
    if(totalValues!=null && ((totalValues['restUpdatedOn'] != null || totalValues['storeUpdatedOn']!=null))) {
      this.setState({loading: false});
    } else {
      this.setState({loading: true});
    }
    window.addEventListener('totalValues', async() => {
      var totalValues;
      try {
        totalValues = JSON.parse((await getAsyncItem('totalValues')) ??"{}");
      } catch(e) {
        //console.log(e);
        totalValues = JSON.parse(localStorage.getItem('totalValues')??"{}");
      }
      if(totalValues!=null && ((totalValues['restUpdatedOn'] != null || totalValues['storeUpdatedOn']!=null))) {
        this.setState({loading: false});
      }  else {
        this.setState({loading: true});
      }
    });
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

async getCities() {
  const offerDoc  = query(
    collection(db, 'cities')
  );
const offerSnap = await getDocs(offerDoc);
this.setState({cities: []});
if (offerSnap.size>0) {
  var cities = [];
  for(var i = 0; i < offerSnap.size; i++) {
    var data = offerSnap.docs[i].data();
    data['id'] = offerSnap.docs[i].id;
    cities.push(data);
  }
  this.setState({cities: cities}, (newState) => {
    console.log('cities',this.state.cities);
  });
}
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}

async componentWillMount() {
  var dataToAdd;
  try {
    dataToAdd = JSON.parse(
    localStorage.getItem('preOrder')
  );
} catch(e) {
//console.log(e);
}
//console.log(localStorage.getItem('preOrder'));
if(dataToAdd) {
  dataToAdd['selectedDate'] = new Date(dataToAdd['selectedDate']);
} else {
  dataToAdd = {selectedDate: new Date(),
    selectedWeekDay: new Date().getDay(),
    isTomorrow: false,
    isToday: false,
    isPreOrder: false,};
}
this.setState(dataToAdd, (bee)=>{
  //console.log(dataToAdd);
});
  var locationData;
  try {
    locationData = JSON.parse(
    localStorage.getItem('location')
  );
} catch(e) {
//console.log(e);
}

if(locationData  == null) {
locationData = [];
}
  var showAllergyDisclaimer;
  try {
    showAllergyDisclaimer = JSON.parse(localStorage.getItem('showAllergyDisclaimer'))
} catch(e) {
//console.log(e);
}
  if (showAllergyDisclaimer == null) {
    showAllergyDisclaimer = true;
  }
  for (var i = 0; i < locationData.length; i++) {
    if (locationData[i]['selected']) {
      this.setState({
        selectLocationName: locationData[i]['geoAddress'],
      });
      // //console.log(locationData[i]);
      break;
    }
  }
  this.setState({ addresses: locationData, showAllergyDisclaimer: showAllergyDisclaimer }, (newState) => {
    // //console.log(
    //   'locationData',
    //   this.state.addresses,
    //   typeof this.state.addresses
    // );
  });
  var selected;
  try {
    selected = JSON.parse(localStorage.getItem('selected'));
} catch(e) {
//console.log(e);
}
  if (selected == null) {
    selected = { latitude: 0, longitude: 0 };
  }
  this.setState({ selectedAddress: selected }, (newState) => {
    // //console.log(
    //   'selectedAddress',
    //   this.state.selectedAddress,
    //   typeof this.state.selectedAddress
    // );
  });
  // //console.log('locationData', locationData);
  // const { id } = this.props.match.params;
  var date2 = new Date();
  var weekDay = [6,0,1,2,3,4,5];
  var currentDay = weekDay[date2.getDay()];
  // this.setState({ selectedDate: date2, selectedWeekDay: currentDay, busId: '' });
  this.getLocAndData();
  // this.setState({ businessData: {} });

  await this.getCities();
  this.getAdminData();
}

async listMaker() {
  return new Promise(async (resolve) => {
  var date2 = new Date();
  var weekDay = [6,0,1,2,3,4,5];
  var currentDay = weekDay[date2.getDay()];
  var weekText = [strings['Domingo'],strings['Lunes'],strings['Martes'],strings['Miércoles'],strings['Jueves'],strings['Viernes'],strings['Sábado']];
  var currenciesList3 = [];
  var currenciesList4 = [];
  var currenciesList5 = [];
  var currenciesList6 = [];
  var currenciesList7 = [];
  var currenciesList8 = [];
  var currenciesList2 = [];
  var currenciesList = [];
      // await preOrder.put('isTomorrow', isTomorrow);
  // await preOrder.put('isPreOrder', isPreOrder);
  // await preOrder.put('selectedWeekDay', currentDay);
  // await preOrder.put('selectedDate', selected);
  var current = moment(new Date()).add(45, 'm').toDate();
  //List timing = [];
  // //console.log(current);
  var startHour = current.getHours();
  var startMinute = current.getMinutes();
  if (startMinute !== 0 || startMinute !== 15 || startMinute !== 30 || startMinute !== 45) {
    if (startMinute < 15) {
      startMinute = 15;
    } else if (startMinute < 30) {
      startMinute = 30;
    } else if (startMinute < 35) {
      startMinute = 45;
    } else if (startMinute < 60) {
      startMinute = 0;
      startHour = startHour + 1;
    }
  }
  //print(startHour);
  //print(startMinute);
  // //console.log(new Date().getDate(),current.getDate(),(new Date().getDate() >= current.getDate()),startHour,current,new Date());
  if(new Date().getDate() >= current.getDate()) {
  for (var i = startHour; startHour <= 23; i++) {
    var addition = '';
    var addTime = new Date();
    addTime.setFullYear(current.getFullYear());
    addTime.setMonth(current.getMonth());
    addTime.setDate(current.getDate());
    addTime.setHours(startHour);
    addTime.setMinutes(startMinute);
    addTime = moment(addTime).add(0, 'm').toDate();
    //console.log(new Date().getDate(),current.getDate(),addTime,startHour,startMinute);
    //addTime = DateTime(current.year,current.month,current.day,startHour,startMinute).add(Duration(minutes:-45));
    // var timezone = tz.getLocation('America/Mexico_City');
    // addTime = tz.TZDateTime(timezone, current.year, current.month,
    //         current.day, startHour, startMinute)
    //     .add(Duration(minutes: 0));
    if (startMinute === 0) {
      addition = addition +
          startHour.toString() +
          ':' +
          startMinute.toString() +
          '0';

      startMinute = 15;
    } else if (startMinute === 15) {
      addition = addition +
          startHour.toString() +
          ':' +
          startMinute.toString() +
          '';

      startMinute = 30;
    } else if (startMinute === 30) {
      addition = addition +
          startHour.toString() +
          ':' +
          startMinute.toString() +
          '';

      startMinute = 45;
    } else {
      addition = addition +
          startHour.toString() +
          ':' +
          startMinute.toString() +
          '';
      startMinute = 0;
      startHour = startHour + 1;
    }
    addition = addition.replaceAll('24', '00');
    //print(addition);
    //print(addTime);
    // //console.log(this.isOpenOn(addTime));

    currenciesList.push({'display': addition, 'date': addTime});
  }
}
  //print(currenciesList);

  var current2 = moment(new Date()).add(45, 'm').toDate();
  //List timing = [];
  //print(current2);
  var startHour2 = 0;
  var startMinute2 = 0;
  if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
    if (startMinute2 < 15) {
      startMinute2 = 15;
    } else if (startMinute2 < 30) {
      startMinute2 = 30;
    } else if (startMinute2 < 35) {
      startMinute2 = 45;
    } else if (startMinute2 < 60) {
      startMinute2 = 0;
      startHour2 = startHour2 + 1;
    }
  }
  //print(startHour2);
  //print(startMinute2);
  for (var i = startHour2; startHour2 <= 23; i++) {
    var addition = '';
    var addTime = new Date();
    addTime.setFullYear(current2.getFullYear());
    addTime.setMonth(current2.getMonth());
    addTime.setDate(current2.getDate()+1);
    addTime.setHours(startHour2);
    addTime.setMinutes(startMinute2);
    addTime = moment(addTime).add(0, 'm').toDate();
    //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
    // var timezone = tz.getLocation('America/Mexico_City');
    // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
    //         current2.day + 1, startHour2, startMinute2)
    //     .add(Duration(minutes: -0));
    if (startMinute2 === 0) {
      addition = addition +
          startHour2.toString() +
          ':' +
          startMinute2.toString() +
          '0';

      startMinute2 = 15;
    } else if (startMinute2 === 15) {
      addition = addition +
          startHour2.toString() +
          ':' +
          startMinute2.toString() +
          '';

      startMinute2 = 30;
    } else if (startMinute2 === 30) {
      addition = addition +
          startHour2.toString() +
          ':' +
          startMinute2.toString() +
          '';

      startMinute2 = 45;
    } else {
      addition = addition +
          startHour2.toString() +
          ':' +
          startMinute2.toString() +
          '';
      startMinute2 = 0;
      startHour2 = startHour2 + 1;
    }
    addition = addition.replaceAll('24', '00');
    //print(addition);
    //print(addTime);
    // //console.log(this.isOpenOn(addTime));

    currenciesList3.push({'display': addition, 'date': addTime});
  }
  try {
    var current2 = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    //print(current2);
    var startHour2 = 0;
    var startMinute2 = 0;
    if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
      if (startMinute2 < 15) {
        startMinute2 = 15;
      } else if (startMinute2 < 30) {
        startMinute2 = 30;
      } else if (startMinute2 < 35) {
        startMinute2 = 45;
      } else if (startMinute2 < 60) {
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
    }
    //print(startHour2);
    //print(startMinute2);
    for (var i = startHour2; startHour2 <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current2.getFullYear());
      addTime.setMonth(current2.getMonth());
      addTime.setDate(current2.getDate()+2);
      addTime.setHours(startHour2);
      addTime.setMinutes(startMinute2);
      addTime = moment(addTime).add(0, 'm').toDate();
      //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
      //         current2.day + 1, startHour2, startMinute2)
      //     .add(Duration(minutes: 0));
      if (startMinute2 === 0) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '0';

        startMinute2 = 15;
      } else if (startMinute2 === 15) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 30;
      } else if (startMinute2 === 30) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 45;
      } else {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);

      currenciesList4.push({'display': addition, 'date': addTime});
    }
  } catch(e) {}
  try {
    var current2 = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    //print(current2);
    var startHour2 = 0;
    var startMinute2 = 0;
    if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
      if (startMinute2 < 15) {
        startMinute2 = 15;
      } else if (startMinute2 < 30) {
        startMinute2 = 30;
      } else if (startMinute2 < 35) {
        startMinute2 = 45;
      } else if (startMinute2 < 60) {
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
    }
    //print(startHour2);
    //print(startMinute2);
    for (var i = startHour2; startHour2 <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current2.getFullYear());
      addTime.setMonth(current2.getMonth());
      addTime.setDate(current2.getDate()+3);
      addTime.setHours(startHour2);
      addTime.setMinutes(startMinute2);
      addTime = moment(addTime).add(0, 'm').toDate();
      //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
      //         current2.day + 1, startHour2, startMinute2)
      //     .add(Duration(minutes: 0));
      if (startMinute2 === 0) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '0';

        startMinute2 = 15;
      } else if (startMinute2 === 15) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 30;
      } else if (startMinute2 === 30) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 45;
      } else {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);

      currenciesList5.push({'display': addition, 'date': addTime});
    }
  } catch(e) {}
  try {
    var current2 = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    //print(current2);
    var startHour2 = 0;
    var startMinute2 = 0;
    if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
      if (startMinute2 < 15) {
        startMinute2 = 15;
      } else if (startMinute2 < 30) {
        startMinute2 = 30;
      } else if (startMinute2 < 35) {
        startMinute2 = 45;
      } else if (startMinute2 < 60) {
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
    }
    //print(startHour2);
    //print(startMinute2);
    for (var i = startHour2; startHour2 <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current2.getFullYear());
      addTime.setMonth(current2.getMonth());
      addTime.setDate(current2.getDate()+4);
      addTime.setHours(startHour2);
      addTime.setMinutes(startMinute2);
      addTime = moment(addTime).add(0, 'm').toDate();
      //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
      //         current2.day + 1, startHour2, startMinute2)
      //     .add(Duration(minutes: 0));
      if (startMinute2 === 0) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '0';

        startMinute2 = 15;
      } else if (startMinute2 === 15) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 30;
      } else if (startMinute2 === 30) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 45;
      } else {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);

      currenciesList6.push({'display': addition, 'date': addTime});
    }
  } catch(e) {}
  try {
    var current2 = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    //print(current2);
    var startHour2 = 0;
    var startMinute2 = 0;
    if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
      if (startMinute2 < 15) {
        startMinute2 = 15;
      } else if (startMinute2 < 30) {
        startMinute2 = 30;
      } else if (startMinute2 < 35) {
        startMinute2 = 45;
      } else if (startMinute2 < 60) {
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
    }
    //print(startHour2);
    //print(startMinute2);
    for (var i = startHour2; startHour2 <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current2.getFullYear());
      addTime.setMonth(current2.getMonth());
      addTime.setDate(current2.getDate()+5);
      addTime.setHours(startHour2);
      addTime.setMinutes(startMinute2);
      addTime = moment(addTime).add(0, 'm').toDate();
      //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
      //         current2.day + 1, startHour2, startMinute2)
      //     .add(Duration(minutes: 0));
      if (startMinute2 === 0) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '0';

        startMinute2 = 15;
      } else if (startMinute2 === 15) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 30;
      } else if (startMinute2 === 30) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 45;
      } else {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);

      currenciesList7.push({'display': addition, 'date': addTime});
    }
  } catch(e) {}
  try {
    var current2 = moment(new Date()).add(45, 'm').toDate();
    //List timing = [];
    //print(current2);
    var startHour2 = 0;
    var startMinute2 = 0;
    if (startMinute2 !== 0 || startMinute2 !== 15 || startMinute2 !== 30 || startMinute2 !== 45) {
      if (startMinute2 < 15) {
        startMinute2 = 15;
      } else if (startMinute2 < 30) {
        startMinute2 = 30;
      } else if (startMinute2 < 35) {
        startMinute2 = 45;
      } else if (startMinute2 < 60) {
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
    }
    //print(startHour2);
    //print(startMinute2);
    for (var i = startHour2; startHour2 <= 23; i++) {
      var addition = '';
      var addTime = new Date();
      addTime.setFullYear(current2.getFullYear());
      addTime.setMonth(current2.getMonth());
      addTime.setDate(current2.getDate()+6);
      addTime.setHours(startHour2);
      addTime.setMinutes(startMinute2);
      addTime = moment(addTime).add(0, 'm').toDate();
      //addTime = DateTime(current2.year,current2.month,current2.day+1,startHour2,startMinute2).add(Duration(minutes:-45));
      // var timezone = tz.getLocation('America/Mexico_City');
      // addTime = tz.TZDateTime(timezone, current2.year, current2.month,
      //         current2.day + 1, startHour2, startMinute2)
      //     .add(Duration(minutes: 0));
      if (startMinute2 === 0) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '0';

        startMinute2 = 15;
      } else if (startMinute2 === 15) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 30;
      } else if (startMinute2 === 30) {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';

        startMinute2 = 45;
      } else {
        addition = addition +
            startHour2.toString() +
            ':' +
            startMinute2.toString() +
            '';
        startMinute2 = 0;
        startHour2 = startHour2 + 1;
      }
      addition = addition.replaceAll('24', '00');
      //print(addition);
      //print(addTime);

      currenciesList8.push({'display': addition, 'date': addTime});
    }
  } catch(e) {}
  currenciesList2 = [
    //{'title': 'ahora','date':tz.TZDateTime.now(timezone),'id':1},
    {'title': strings['hoy'], 'date': new Date(), 'id': 1},
    {
      'title': strings['mańana'],
      'date': moment(new Date()).add(1, 'd').toDate(),
      'id': 2
    },
    {
      'title': weekText[moment(new Date()).add(2, 'd').toDate().getUTCDay()],
      'date': moment(new Date()).add(2, 'd').toDate(),
      'id': 3
    },
    {
      'title': weekText[moment(new Date()).add(3, 'd').toDate().getUTCDay()],
      'date': moment(new Date()).add(3, 'd').toDate(),
      'id': 4
    },
    {
      'title': weekText[moment(new Date()).add(4, 'd').toDate().getUTCDay()],
      'date': moment(new Date()).add(4, 'd').toDate(),
      'id': 5
    },
    {
      'title': weekText[moment(new Date()).add(5, 'd').toDate().getUTCDay()],
      'date': moment(new Date()).add(5, 'd').toDate(),
      'id': 6
    },
    {
      'title': weekText[moment(new Date()).add(6, 'd').toDate().getUTCDay()],
      'date': moment(new Date()).add(6, 'd').toDate(),
      'id': 7
    },
  ];
  // if(currenciesList.length<=0) {
  //   currenciesList2.splice(0,1);
  // }
  //console.log(currenciesList);
  this.setState(
    {
      currenciesList2: currenciesList2,
      currenciesList3: currenciesList3,
      currenciesList4: currenciesList4,
      currenciesList5: currenciesList5,
      currenciesList6: currenciesList6,
      currenciesList7: currenciesList7,
      currenciesList8: currenciesList8,
      currenciesList: currenciesList,
      currenciesList2Selected: 0,
      currenciesList3Selected: 0,
      currenciesList4Selected: 0,
      currenciesList5Selected: 0,
      currenciesList6Selected: 0,
      currenciesList7Selected: 0,
      currenciesList8Selected: 0,
      currenciesListSelected: 0
    }, (newState)=>{
      resolve('resolved');
    }
  );
  });
}

handleChange() {
  // var menuD = JSON.parse(JSON.stringify(this.state.menuData));
  var tempDataOpen = [];
  var tempDataClosed = [];
  if(this.state.selectedPos === 0) {
    tempDataOpen = this.state.restOpen.map(item => ({...item}));
    tempDataClosed = this.state.restClosed.map(item => ({...item}));
  }
  if(this.state.selectedPos === 1) {
    tempDataOpen = this.state.storeOpen.map(item => ({...item}));
    tempDataClosed = this.state.storeClosed.map(item => ({...item}));
  }
  if(this.state.selectedPos === 2) {
    tempDataOpen = this.state.groceriesOpen.map(item => ({...item}));
    tempDataClosed = this.state.groceriesClosed.map(item => ({...item}));
  }
  if(this.state.selectedPos === 3) {
    tempDataOpen = this.state.pharmacyOpen.map(item => ({...item}));
    tempDataClosed = this.state.pharmacyClosed.map(item => ({...item}));
  }
  if(this.state.selectedPos === 4) {
    tempDataOpen = this.state.favorites.map(item => ({...item}));
    tempDataClosed = [];
  }
  // var menuD = this.state.menuData.map(item => ({...item}))
  // for (var i = 0; i < tempDataOpen.length; i++) {
    tempDataOpen = tempDataOpen.filter(
      (val) =>
        ((typeof val.name === 'string' &&
        (val.name.toLowerCase().includes(this.state.searchText.toLowerCase()))) || (typeof val.about === 'string' &&
        (val.about.toLowerCase().includes(this.state.searchText.toLowerCase()))) || (typeof val.description === 'string' &&
        (val.description.toLowerCase().includes(this.state.searchText.toLowerCase()))))
    );
    tempDataClosed = tempDataClosed.filter(
      (val) =>
        ((typeof val.name === 'string' &&
        (val.name.toLowerCase().includes(this.state.searchText.toLowerCase()))) || (typeof val.about === 'string' &&
        (val.about.toLowerCase().includes(this.state.searchText.toLowerCase()))) || (typeof val.description === 'string' &&
        (val.description.toLowerCase().includes(this.state.searchText.toLowerCase()))))
    );
    // //console.log(
    //   'handle change called',
    //   e.target.value,
    //   i,
    //   menuD[i]['products'].length
    // );
  // }
  this.setState({ displayOpen: tempDataOpen, displayClosed: tempDataClosed });
}

async loadCity(cityId) {
  //console.log('cityId',cityId,this.state.loadCityAgain);
  if(this.state.loadCityAgain) {
  const busDoc = doc(db, 'cities', cityId);
    const docSnap = await getDoc(busDoc);
    if (docSnap.exists()) {
      var categoriesList = (docSnap.data().category) ?? [];
      var categoriesStore = (docSnap.data().categoryStore) ?? [];
      this.setState({categoriesList:categoriesList,categoriesStore:categoriesStore,loadCityAgain: false});
    } else {
      this.setState({categoriesList:[],categoriesStore:[], loadCityAgain: false});
    }
  }
}

async getLocAndData() {
  window.addEventListener('selected', async() => {
    this.setState({loadCityAgain: true,    restOpen: [],
      restClosed: [],
      storeOpen: [],
      storeClosed: [],
      groceriesOpen: [],
      groceriesClosed: [],
      pharmacyOpen: [],
      pharmacyClosed: [],
      displayOpen: [],
      displayClosed: [],});
  });
  var displayRestOpen;
  var displayRestClosed;
  var displayStoreOpen;
  var displayStoreClosed;
    try {
      displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
      displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
      displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
      displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
    } catch(e) {
      //console.log(e);
       displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
       displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
       displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
       displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
    }
  var boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
  // var preOrder = JSON.parse(localStorage.getItem('selected')??"{}");
  if(boxSelected['latitude']==null) {
    boxSelected = {latitude: 0, longitude: 0};
  }

  // streamOpen = displayRestOpen.watch().listen((event) async {
  //   if (displayRestOpen.containsKey(event.key)) {
  //     int index = restaurant.indexWhere((element) => element.id == event.key);
  //     // int index2 =
  //     //     restaurant2.indexWhere((element) => element.id == event.key);
  //     var t1 = await displayRestOpen.get(event.key);
  //     if (index >= 0) {
  //       if (mounted) {
  //         if (index % 10 == 0 && index < 51 && index != 0) {
  //           setState(() {
  //             recommended[(index / 10).truncate() - 1] = t1;
  //           });
  //         }
  //         setState(() {
  //           restaurant[index] = t1;
  //           restaurant2[index] = t1;
  //         });
  //         //restaurant2[index] = t1;
  //       } else {
  //         return;
  //       }
  //     } else {
  //       if (mounted) {
  //         setState(() {
  //           restClosed.removeWhere((element) => element.id == event.key);
  //           restaurant.add(t1);
  //           restaurant.sort((a, b) => a.distance.compareTo(b.distance));
  //           restClosed2.removeWhere((element) => element.id == event.key);
  //           restaurant2.add(t1);
  //           restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
  //         });
  //         // restClosed2.removeWhere((element) => element.id == event.key);
  //         // restaurant2.add(t1);
  //         // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
  //         if ((restaurant.length - 1) % 10 == 0 &&
  //             restaurant.length < 52 &&
  //             restaurant.length != 1) {
  //           setState(() {
  //             recommended.add(t1);
  //           });
  //         }
  //       } else
  //         return;
  //     }
  //     // if (index2 >= 0) {
  //     //   // if (mounted) {
  //     //   //   if (index % 10 == 0 && index < 51 && index != 0) {
  //     //   //     setState(() {
  //     //   //       recommended[(index / 10).truncate() - 1] = t1;
  //     //   //     });
  //     //   //   }
  //     //   //   setState(() {
  //     //   //     restaurant[index] = t1;
  //     //   //   });
  //     //   restaurant2[index2] = t1;
  //     //   // } else {
  //     //   //   return;
  //     //   // }
  //     // } else {
  //     //   // if (mounted) {
  //     //   //   setState(() {
  //     //   //     restClosed.removeWhere((element) => element.id == event.key);
  //     //   //     restaurant.add(t1);
  //     //   //     restaurant.sort((a, b) => a.distance.compareTo(b.distance));
  //     //   //   });
  //     //   restClosed2.removeWhere((element) => element.id == event.key);
  //     //   restaurant2.add(t1);
  //     //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
  //     //   // if ((restaurant.length - 1) % 10 == 0 &&
  //     //   //     restaurant.length < 52 &&
  //     //   //     restaurant.length != 1) {
  //     //   //   setState(() {
  //     //   //     recommended.add(t1);
  //     //   //   });
  //     //   // }
  //     //   // } else
  //     //   //   return;
  //     // }
  //   } else {
  //     if (mounted) {
  //       setState(() {
  //         restaurant.removeWhere((element) => element.id == event.key);
  //         recommended.removeWhere((element) => element.id == event.key);
  //         restaurant2.removeWhere((element) => element.id == event.key);
  //       });
  //       // restaurant2.removeWhere((element) => element.id == event.key);
  //     } else
  //       return;
  //   }
  // });
  // streamClosed = displayRestClosed.watch().listen((event) async {
  //   if (displayRestClosed.containsKey(event.key)) {
  //     int index = restClosed.indexWhere((element) => element.id == event.key);
  //     // int index2 =
  //     //     restClosed2.indexWhere((element) => element.id == event.key);
  //     var t1 = await displayRestClosed.get(event.key);
  //     if (index >= 0) {
  //       if (mounted) {
  //         setState(() {
  //           restClosed[index] = t1;
  //           restClosed2[index] = t1;
  //         });
  //       } else
  //         return;
  //     } else {
  //       if (mounted) {
  //         setState(() {
  //           restaurant.removeWhere((element) => element.id == event.key);
  //           restClosed.add(t1);
  //           //print(t1.name + ' ' + t1.distance.toString());
  //           restClosed.sort((a, b) => a.distance.compareTo(b.distance));
  //           restaurant2.removeWhere((element) => element.id == event.key);
  //           restClosed2.add(t1);
  //           //print(t1.name + ' ' + t1.distance.toString());
  //           restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
  //         });
  //       } else
  //         return;
  //     }
  //     // if (index2 >= 0) {
  //     //   //if (mounted) {
  //     //   //setState(() {
  //     //   restClosed2[index2] = t1;
  //     //   //});
  //     //   // } else
  //     //   //   return;
  //     // } else {
  //     //   // if (mounted) {
  //     //   //   setState(() {
  //     //   restaurant2.removeWhere((element) => element.id == event.key);
  //     //   restClosed2.add(t1);
  //     //   //print(t1.name + ' ' + t1.distance.toString());
  //     //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
  //     //   //   });
  //     //   // } else
  //     //   //   return;
  //     // }
  //   } else {
  //     if (mounted) {
  //       setState(() {
  //         restClosed.removeWhere((element) => element.id == event.key);
  //         restClosed2.removeWhere((element) => element.id == event.key);
  //       });
  //       // restClosed2.removeWhere((element) => element.id == event.key);
  //     } else
  //       return;
  //   }
  // });
  if (boxSelected['latitude'] != null) {
    this.setState({selectedAddress : {latitude: boxSelected['latitude'],
    longitude: boxSelected['longitude']}
  });
  var restOpen = [];
            var restClosed = [];
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            //console.log('displayRestOpen12',displayRestOpen);
    if (Object.keys(displayRestOpen).length>0 || Object.keys(displayRestClosed).length>0) {
      var displayRestOpenKeys = Object.keys(displayRestOpen);
      for(var iqwe=0;iqwe<displayRestOpenKeys.length;iqwe++) {
        var key = displayRestOpenKeys[iqwe];
        var value = displayRestOpen[key];
        if(value && value.location && value.location.cityId && iqwe===0) {
          this.loadCity(value.location.cityId);
        }
      // }
      // displayRestOpen.keys.toList().forEach((key) {
      //   displayRestOpen.get(key).then((value) async {
          var isOpen;
          if (value.available) {
            // var timezone = tz.getLocation(value.timezone);
            // DateTime date = tz.TZDateTime.now(timezone);
            var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[this.state.selectedDate.getDay()];
            // int currentDay = date.weekday - 1;
            if (value.schedule[currentDay]['enabled']) {
              for (var i = 0;
                  i < value.schedule[currentDay]['lapses'].length;
                  i++) {
                var date = this.state.selectedDate;
                if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                        date.getHours() &&
                    value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                        date.getHours()) {
                  isOpen = true;
                } else if (value.schedule[currentDay]['lapses'][i]['open']
                        ['hour'] ===
                    date.getHours()) {
                  if (value.schedule[currentDay]['lapses'][i]['open']
                          ['minute'] <
                      date.getMinutes()) {
                    isOpen = true;
                  }
                } else if (value.schedule[currentDay]['lapses'][i]['close']
                        ['hour'] ===
                    date.getHours()) {
                  if (value.schedule[currentDay]['lapses'][i]['close']
                          ['minute'] >
                      date.getMinutes()) {
                    isOpen = true;
                  }
                }
              }
            }
            if (isOpen == null) isOpen = false;
          } else
            isOpen = false;
          if (isOpen) {
            var restOpen = this.state.restOpen;
            var restClosed = this.state.restClosed;
            var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            const pos = restOpen.map(e => e.id).indexOf(key);
            const pos2 = restClosed.map(e => e.id).indexOf(key);
            if(pos<0) {
              restOpen.push(value);
              restOpen = restOpen.sort((a,b) => a.distance - b.distance);
            } else {
              restOpen[pos] = value;
              restOpen = restOpen.sort((a,b) => a.distance - b.distance);
            }
            if(pos2>=0) {
              restClosed.splice(pos2, 1);
            }
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            // setState(() {
            //   restaurant.removeWhere((item) => item.id == key);
            //   restClosed.removeWhere((item) => item.id == key);
            //   restaurant.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
            //   restaurant2.removeWhere((item) => item.id == key);
            //   restClosed2.removeWhere((item) => item.id == key);
            //   restaurant2.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
            // });
            // // restaurant2.removeWhere((item) => item.id == key);
            // // restClosed2.removeWhere((item) => item.id == key);
            // // restaurant2.add(value);
            // // //print(value.name + ' ' + value.distance.toString());
            // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
            // if ((restOpen.length - 1) % 10 == 0 &&
            //     restaurant.length < 52 &&
            //     restaurant.length != 1) {
            //   setState(() {
            //     recommended.add(value);
            //   });
            // }
          } else if (!isOpen) {
            var restOpen = this.state.restOpen;
            var restClosed = this.state.restClosed;
            var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            const pos = restClosed.map(e => e.id).indexOf(key);
            const pos2 = restOpen.map(e => e.id).indexOf(key);
            if(pos<0) {
              restClosed.push(value);
              restClosed = restClosed.sort((a,b) => a.distance - b.distance);
            } else {
              restClosed[pos] = value;
              restClosed = restClosed.sort((a,b) => a.distance - b.distance);
            }
            if(pos2>=0) {
              restOpen.splice(pos2, 1);
            }
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            // setState(() {
            //   restaurant.removeWhere((item) => item.id == key);
            //   restClosed.removeWhere((item) => item.id == key);
            //   recommended.removeWhere((item) => item.id == key);
            //   restClosed.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));

            //   restaurant2.removeWhere((item) => item.id == key);
            //   restClosed2.removeWhere((item) => item.id == key);
            //   //recommended.removeWhere((item) => item.id == key);
            //   restClosed2.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
            // });
            // restaurant2.removeWhere((item) => item.id == key);
            // restClosed2.removeWhere((item) => item.id == key);
            // //recommended.removeWhere((item) => item.id == key);
            // restClosed2.add(value);
            // //print(value.name + ' ' + value.distance.toString());
            // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
            delete displayRestOpen[key];
            displayRestClosed[key] = value;
            try {
             await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
            //  window.dispatchEvent(new Event("displayRestOpen"));
            });
             await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
             // window.dispatchEvent(new Event("displayRestClosed"));
            });
           } catch(e) {
             //console.log(e);
            await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
            await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
            // window.dispatchEvent(new Event("displayRestOpen"));
            // window.dispatchEvent(new Event("displayRestClosed"));
           }
            // window.dispatchEvent(new Event("displayRestOpen"));
            // window.dispatchEvent(new Event("displayRestClosed"));
            // await displayRestOpen.delete(key);
            // await displayRestClosed.put(key, value);
          } else
            return;
        }

        var displayRestClosedKeys = Object.keys(displayRestClosed);
        for(var iqwe=0;iqwe<displayRestClosedKeys.length;iqwe++) {
          var key = displayRestClosedKeys[iqwe];
          var value = displayRestClosed[key];
          if(value && value.location && value.location.cityId && iqwe===0) {
            this.loadCity(value.location.cityId);
          }
      // displayRestClosed.keys.toList().forEach((key) {
      //   displayRestClosed.get(key).then((value) async {
          var isOpen;
          if (value.available) {
            // var timezone = tz.getLocation(value.timezone);
            // DateTime date = tz.TZDateTime.now(timezone);
            // int currentDay = date.weekday - 1;
            var weekDay = [6,0,1,2,3,4,5];
            var currentDay = weekDay[this.state.selectedDate.getDay()];
            if (value.schedule[currentDay]['enabled']) {
              for (var i = 0;
                  i < value.schedule[currentDay]['lapses'].length;
                  i++) {
                var date = this.state.selectedDate;
                if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                        date.getHours() &&
                    value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                        date.getHours()) {
                  isOpen = true;
                } else if (value.schedule[currentDay]['lapses'][i]['open']
                        ['hour'] ===
                    date.getHours()) {
                  if (value.schedule[currentDay]['lapses'][i]['open']
                          ['minute'] <
                      date.getMinutes()) {
                    isOpen = true;
                  }
                } else if (value.schedule[currentDay]['lapses'][i]['close']
                        ['hour'] ===
                    date.getHours()) {
                  if (value.schedule[currentDay]['lapses'][i]['close']
                          ['minute'] >
                      date.getMinutes()) {
                    isOpen = true;
                  }
                }
              }
            }
            if (isOpen == null) isOpen = false;
          } else
            isOpen = false;
          if (!isOpen) {
            var restOpen = this.state.restOpen;
            var restClosed = this.state.restClosed;
            var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            const pos = restClosed.map(e => e.id).indexOf(key);
            const pos2 = restOpen.map(e => e.id).indexOf(key);
            if(pos<0) {
              restClosed.push(value);
              restClosed = restClosed.sort((a,b) => a.distance - b.distance);
            } else {
              restClosed[pos] = value;
              restClosed = restClosed.sort((a,b) => a.distance - b.distance);
            }
            if(pos2>=0) {
              restOpen.splice(pos2, 1);
            }
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            // setState(() {
            //   restaurant.removeWhere((item) => item.id == key);
            //   restClosed.removeWhere((item) => item.id == key);
            //   restClosed.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));
            //   restaurant2.removeWhere((item) => item.id == key);
            //   restClosed2.removeWhere((item) => item.id == key);
            //   restClosed2.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
            // });
            // // restaurant2.removeWhere((item) => item.id == key);
            // // restClosed2.removeWhere((item) => item.id == key);
            // // restClosed2.add(value);
            // // //print(value.name + ' ' + value.distance.toString());
            // // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
          } else if (isOpen) {
            var restOpen = this.state.restOpen;
            var restClosed = this.state.restClosed;
            var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            const pos = restOpen.map(e => e.id).indexOf(key);
            const pos2 = restClosed.map(e => e.id).indexOf(key);
            if(pos<0) {
              restOpen.push(value);
              restOpen = restOpen.sort((a,b) => a.distance - b.distance);
            } else {
              restOpen[pos] = value;
              restOpen = restOpen.sort((a,b) => a.distance - b.distance);
            }
            if(pos2>=0) {
              restClosed.splice(pos2, 1);
            }
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            // setState(() {
            //   restaurant.removeWhere((item) => item.id == key);
            //   restClosed.removeWhere((item) => item.id == key);
            //   restaurant.add(value);
            //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
            //   restaurant2.removeWhere((item) => item.id == key);
            //   restClosed2.removeWhere((item) => item.id == key);
            //   restaurant2.add(value);
            //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
            //   if ((restaurant.length - 1) % 10 == 0 &&
            //       restaurant.length < 52 &&
            //       restaurant.length != 1) {
            //     recommended.add(value);
            //   }
            // });
            // // restaurant2.removeWhere((item) => item.id == key);
            // // restClosed2.removeWhere((item) => item.id == key);
            // // restaurant2.add(value);
            // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
            delete displayRestClosed[key];
            displayRestOpen[key] = value;
            try {
             await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
            //  window.dispatchEvent(new Event("displayRestOpen"));
            });
             await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
             // window.dispatchEvent(new Event("displayRestClosed"));
            });
           } catch(e) {
             //console.log(e);
            await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
            await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
            // window.dispatchEvent(new Event("displayRestOpen"));
            // window.dispatchEvent(new Event("displayRestClosed"));
           }
            // await displayRestClosed.delete(key);
            // await displayRestOpen.put(key, value);
          } else
            return;
        }
      }

        window.addEventListener('displayRestOpen', async() => {
          // this.setState({
          //   // restOpen: [],
          //   // restClosed: [],
          //   // storeOpen: [],
          //   // storeClosed: [],
          //   // displayOpen: [],
          //   // displayClosed: [],
          //   loadCityAgain: true
          // });
           var displayRestOpen;
           var displayRestClosed;
           var displayStoreOpen;
           var displayStoreClosed;
             try {
               displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
               displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
               displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
               displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
             } catch(e) {
               //console.log(e);
                displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
           displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
                displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
           displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
             }
             //console.log('displayRestOpen12',displayRestOpen);
           boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
           displayRestOpenKeys = Object.keys(displayRestOpen);
          for(var iqwe=0;iqwe<displayRestOpenKeys.length;iqwe++) {
            var key = displayRestOpenKeys[iqwe];
            var value = displayRestOpen[key];
            if(value && value.location && value.location.cityId) {
              this.loadCity(value.location.cityId && iqwe===0);
            }
          // }
          // displayRestOpen.keys.toList().forEach((key) {
          //   displayRestOpen.get(key).then((value) async {
              var isOpen;
              if (value.available) {
                // var timezone = tz.getLocation(value.timezone);
                // DateTime date = tz.TZDateTime.now(timezone);
                var weekDay = [6,0,1,2,3,4,5];
        var currentDay = weekDay[this.state.selectedDate.getDay()];
                // int currentDay = date.weekday - 1;
                if (value.schedule[currentDay]['enabled']) {
                  for (var i = 0;
                      i < value.schedule[currentDay]['lapses'].length;
                      i++) {
                    var date = this.state.selectedDate;
                    if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                            date.getHours() &&
                        value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                            date.getHours()) {
                      isOpen = true;
                    } else if (value.schedule[currentDay]['lapses'][i]['open']
                            ['hour'] ===
                        date.getHours()) {
                      if (value.schedule[currentDay]['lapses'][i]['open']
                              ['minute'] <
                          date.getMinutes()) {
                        isOpen = true;
                      }
                    } else if (value.schedule[currentDay]['lapses'][i]['close']
                            ['hour'] ===
                        date.getHours()) {
                      if (value.schedule[currentDay]['lapses'][i]['close']
                              ['minute'] >
                          date.getMinutes()) {
                        isOpen = true;
                      }
                    }
                  }
                }
                if (isOpen == null) isOpen = false;
              } else
                isOpen = false;
              if (isOpen) {
                var restOpen = this.state.restOpen;
                var restClosed = this.state.restClosed;
                var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
                const pos = restOpen.map(e => e.id).indexOf(key);
                const pos2 = restClosed.map(e => e.id).indexOf(key);
                if(pos<0) {
                  restOpen.push(value);
                  restOpen = restOpen.sort((a,b) => a.distance - b.distance);
                } else {
                  restOpen[pos] = value;
                  restOpen = restOpen.sort((a,b) => a.distance - b.distance);
                }
                if(pos2>=0) {
                  restClosed.splice(pos2, 1);
                }
                this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
                  this.handleChange();
                });
                // setState(() {
                //   restaurant.removeWhere((item) => item.id == key);
                //   restClosed.removeWhere((item) => item.id == key);
                //   restaurant.add(value);
                //   //print(value.name + ' ' + value.distance.toString());
                //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
                //   restaurant2.removeWhere((item) => item.id == key);
                //   restClosed2.removeWhere((item) => item.id == key);
                //   restaurant2.add(value);
                //   //print(value.name + ' ' + value.distance.toString());
                //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
                // });
                // // restaurant2.removeWhere((item) => item.id == key);
                // // restClosed2.removeWhere((item) => item.id == key);
                // // restaurant2.add(value);
                // // //print(value.name + ' ' + value.distance.toString());
                // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
                // if ((restOpen.length - 1) % 10 == 0 &&
                //     restaurant.length < 52 &&
                //     restaurant.length != 1) {
                //   setState(() {
                //     recommended.add(value);
                //   });
                // }
              } else if (!isOpen) {
                var restOpen = this.state.restOpen;
                var restClosed = this.state.restClosed;
                var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
                const pos = restClosed.map(e => e.id).indexOf(key);
                const pos2 = restOpen.map(e => e.id).indexOf(key);
                if(pos<0) {
                  restClosed.push(value);
                  restClosed = restClosed.sort((a,b) => a.distance - b.distance);
                } else {
                  restClosed[pos] = value;
                  restClosed = restClosed.sort((a,b) => a.distance - b.distance);
                }
                if(pos2>=0) {
                  restOpen.splice(pos2, 1);
                }
                this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
                  this.handleChange();
                });
                // setState(() {
                //   restaurant.removeWhere((item) => item.id == key);
                //   restClosed.removeWhere((item) => item.id == key);
                //   recommended.removeWhere((item) => item.id == key);
                //   restClosed.add(value);
                //   //print(value.name + ' ' + value.distance.toString());
                //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));

                //   restaurant2.removeWhere((item) => item.id == key);
                //   restClosed2.removeWhere((item) => item.id == key);
                //   //recommended.removeWhere((item) => item.id == key);
                //   restClosed2.add(value);
                //   //print(value.name + ' ' + value.distance.toString());
                //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
                // });
                // restaurant2.removeWhere((item) => item.id == key);
                // restClosed2.removeWhere((item) => item.id == key);
                // //recommended.removeWhere((item) => item.id == key);
                // restClosed2.add(value);
                // //print(value.name + ' ' + value.distance.toString());
                // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
                delete displayRestOpen[key];
                displayRestClosed[key] = value;
                try {
                 await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
                  //window.dispatchEvent(new Event("displayRestOpen"));
                });
                 await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
                  //window.dispatchEvent(new Event("displayRestClosed"));
                });
               } catch(e) {
                 //console.log(e);
                await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
                await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
                // window.dispatchEvent(new Event("displayRestOpen"));
                // window.dispatchEvent(new Event("displayRestClosed"));
               }
                // window.dispatchEvent(new Event("displayRestOpen"));
                // window.dispatchEvent(new Event("displayRestClosed"));
                // await displayRestOpen.delete(key);
                // await displayRestClosed.put(key, value);
              } else
                return;
            }
      })

      window.addEventListener('displayRestClosed', async() => {
        // this.setState({
        //   // restOpen: [],
        //   // restClosed: [],
        //   // storeOpen: [],
        //   // storeClosed: [],
        //   // displayOpen: [],
        //   // displayClosed: [],
        //   loadCityAgain: true
        // });
           var displayRestOpen;
           var displayRestClosed;
           var displayStoreOpen;
           var displayStoreClosed;
             try {
               displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
               displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
               displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
               displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
             } catch(e) {
               //console.log(e);
                displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
        displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
                displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
           displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
             }
           boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
           displayRestOpenKeys = Object.keys(displayRestOpen);
        var displayRestClosedKeys = Object.keys(displayRestClosed);
        for(var iqwe=0;iqwe<displayRestClosedKeys.length;iqwe++) {
          var key = displayRestClosedKeys[iqwe];
          var value = displayRestClosed[key];
          if(value && value.location && value.location.cityId && iqwe===0) {
            this.loadCity(value.location.cityId);
          }
      // displayRestClosed.keys.toList().forEach((key) {
      //   displayRestClosed.get(key).then((value) async {
          var isOpen;
          if (value.available) {
            // var timezone = tz.getLocation(value.timezone);
            // DateTime date = tz.TZDateTime.now(timezone);
            // int currentDay = date.weekday - 1;
            var weekDay = [6,0,1,2,3,4,5];
            var currentDay = weekDay[this.state.selectedDate.getDay()];
            if (value.schedule[currentDay]['enabled']) {
              for (var i = 0;
                  i < value.schedule[currentDay]['lapses'].length;
                  i++) {
                var date = this.state.selectedDate;
                if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                        date.getHours() &&
                    value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                        date.getHours()) {
                  isOpen = true;
                } else if (value.schedule[currentDay]['lapses'][i]['open']
                        ['hour'] ===
                    date.getHours()) {
                  if (value.schedule[currentDay]['lapses'][i]['open']
                          ['minute'] <
                      date.getMinutes()) {
                    isOpen = true;
                  }
                } else if (value.schedule[currentDay]['lapses'][i]['close']
                        ['hour'] ===
                    date.getHours()) {
                  if (value.schedule[currentDay]['lapses'][i]['close']
                          ['minute'] >
                      date.getMinutes()) {
                    isOpen = true;
                  }
                }
              }
            }
            if (isOpen == null) isOpen = false;
          } else
            isOpen = false;
          if (!isOpen) {
            var restOpen = this.state.restOpen;
            var restClosed = this.state.restClosed;
            const pos = restClosed.map(e => e.id).indexOf(key);
            const pos2 = restOpen.map(e => e.id).indexOf(key);
            var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(pos<0) {
              restClosed.push(value);
              restClosed = restClosed.sort((a,b) => a.distance - b.distance);
            } else {
              restClosed[pos] = value;
              restClosed = restClosed.sort((a,b) => a.distance - b.distance);
            }
            if(pos2>=0) {
              restOpen.splice(pos2, 1);
            }
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            // setState(() {
            //   restaurant.removeWhere((item) => item.id == key);
            //   restClosed.removeWhere((item) => item.id == key);
            //   restClosed.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));
            //   restaurant2.removeWhere((item) => item.id == key);
            //   restClosed2.removeWhere((item) => item.id == key);
            //   restClosed2.add(value);
            //   //print(value.name + ' ' + value.distance.toString());
            //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
            // });
            // // restaurant2.removeWhere((item) => item.id == key);
            // // restClosed2.removeWhere((item) => item.id == key);
            // // restClosed2.add(value);
            // // //print(value.name + ' ' + value.distance.toString());
            // // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
          } else if (isOpen) {
            var restOpen = this.state.restOpen;
            var restClosed = this.state.restClosed;
            var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            const pos = restOpen.map(e => e.id).indexOf(key);
            const pos2 = restClosed.map(e => e.id).indexOf(key);
            if(pos<0) {
              restOpen.push(value);
              restOpen = restOpen.sort((a,b) => a.distance - b.distance);
            } else {
              restOpen[pos] = value;
              restOpen = restOpen.sort((a,b) => a.distance - b.distance);
            }
            if(pos2>=0) {
              restClosed.splice(pos2, 1);
            }
            this.setState({restOpen: restOpen,restClosed: restClosed}, (newState)=>{
              this.handleChange();
            });
            // setState(() {
            //   restaurant.removeWhere((item) => item.id == key);
            //   restClosed.removeWhere((item) => item.id == key);
            //   restaurant.add(value);
            //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
            //   restaurant2.removeWhere((item) => item.id == key);
            //   restClosed2.removeWhere((item) => item.id == key);
            //   restaurant2.add(value);
            //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
            //   if ((restaurant.length - 1) % 10 == 0 &&
            //       restaurant.length < 52 &&
            //       restaurant.length != 1) {
            //     recommended.add(value);
            //   }
            // });
            // // restaurant2.removeWhere((item) => item.id == key);
            // // restClosed2.removeWhere((item) => item.id == key);
            // // restaurant2.add(value);
            // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
            delete displayRestClosed[key];
            displayRestOpen[key] = value;
            try {
             await ldb.set('displayRestOpen', JSON.stringify(displayRestOpen), function(){
             // window.dispatchEvent(new Event("displayRestOpen"));
            });
             await ldb.set('displayRestClosed', JSON.stringify(displayRestClosed), function(){
             // window.dispatchEvent(new Event("displayRestClosed"));
            });
           } catch(e) {
             //console.log(e);
            await localStorage.setItem('displayRestOpen', JSON.stringify(displayRestOpen));
            await localStorage.setItem('displayRestClosed', JSON.stringify(displayRestClosed));
            //  window.dispatchEvent(new Event("totalValues"));
            //  window.dispatchEvent(new Event("boxAllStore"));
            // window.dispatchEvent(new Event("displayRestOpen"));
            // window.dispatchEvent(new Event("displayRestClosed"));
            // window.dispatchEvent(new Event("boxAllRest"));
            // window.dispatchEvent(new Event("displayStoreClosed"));
            // window.dispatchEvent(new Event("displayStoreOpen"));
           }
            // await displayRestClosed.delete(key);
            // await displayRestOpen.put(key, value);
          } else
            return;
        }
    })
      //   });
      // });


    var storeOpen = [];
    var storeClosed = [];
    var groceriesOpen = [];
    var groceriesClosed = [];
    var pharmacyOpen = [];
    var pharmacyClosed = [];
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
if (Object.keys(displayStoreOpen).length>0 || Object.keys(displayStoreClosed).length>0) {
var displayStoreOpenKeys = Object.keys(displayStoreOpen);
for(var iqwe=0;iqwe<displayStoreOpenKeys.length;iqwe++) {
var key = displayStoreOpenKeys[iqwe];
var value = displayStoreOpen[key];
if(value && value.location && value.location.cityId && iqwe===0) {
  this.loadCity(value.location.cityId);
}
// }
// displayStoreOpen.keys.toList().forEach((key) {
//   displayStoreOpen.get(key).then((value) async {
  var isOpen;
  if (value.available) {
    // var timezone = tz.getLocation(value.timezone);
    // DateTime date = tz.TZDateTime.now(timezone);
    var weekDay = [6,0,1,2,3,4,5];
var currentDay = weekDay[this.state.selectedDate.getDay()];
    // int currentDay = date.weekday - 1;
    if (value.schedule[currentDay]['enabled']) {
      for (var i = 0;
          i < value.schedule[currentDay]['lapses'].length;
          i++) {
        var date = this.state.selectedDate;
        if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                date.getHours() &&
            value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                date.getHours()) {
          isOpen = true;
        } else if (value.schedule[currentDay]['lapses'][i]['open']
                ['hour'] ===
            date.getHours()) {
          if (value.schedule[currentDay]['lapses'][i]['open']
                  ['minute'] <
              date.getMinutes()) {
            isOpen = true;
          }
        } else if (value.schedule[currentDay]['lapses'][i]['close']
                ['hour'] ===
            date.getHours()) {
          if (value.schedule[currentDay]['lapses'][i]['close']
                  ['minute'] >
              date.getMinutes()) {
            isOpen = true;
          }
        }
      }
    }
    if (isOpen == null) isOpen = false;
  } else
    isOpen = false;
  if (isOpen) {
    var storeOpen = this.state.storeOpen;
    var storeClosed = this.state.storeClosed;
    var groceriesOpen = this.state.groceriesOpen;
    var groceriesClosed = this.state.groceriesClosed;
    var pharmacyOpen = this.state.pharmacyOpen;
    var pharmacyClosed = this.state.pharmacyClosed;
    var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(value.businessType === 1) {
    const pos = storeOpen.map(e => e.id).indexOf(key);
    const pos2 = storeClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      storeOpen.push(value);
      storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
    } else {
      storeOpen[pos] = value;
      storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      storeClosed.splice(pos2, 1);
    }
  } else if(value.businessType === 2) {
    const pos = groceriesOpen.map(e => e.id).indexOf(key);
    const pos2 = groceriesClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      groceriesOpen.push(value);
      groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
    } else {
      groceriesOpen[pos] = value;
      groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      groceriesClosed.splice(pos2, 1);
    }
  } else if(value.businessType === 3) {
    const pos = pharmacyOpen.map(e => e.id).indexOf(key);
    const pos2 = pharmacyClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      pharmacyOpen.push(value);
      pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
    } else {
      pharmacyOpen[pos] = value;
      pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      pharmacyClosed.splice(pos2, 1);
    }
  }
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
    // setState(() {
    //   store.removeWhere((item) => item.id == key);
    //   storeClosed.removeWhere((item) => item.id == key);
    //   store.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   store.sort((a, b) => a.distance.compareTo(b.distance));
    //   restaurant2.removeWhere((item) => item.id == key);
    //   restClosed2.removeWhere((item) => item.id == key);
    //   restaurant2.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
    // });
    // // restaurant2.removeWhere((item) => item.id == key);
    // // restClosed2.removeWhere((item) => item.id == key);
    // // restaurant2.add(value);
    // // //print(value.name + ' ' + value.distance.toString());
    // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
    // if ((restOpen.length - 1) % 10 == 0 &&
    //     restaurant.length < 52 &&
    //     restaurant.length != 1) {
    //   setState(() {
    //     recommended.add(value);
    //   });
    // }
  } else if (!isOpen) {
    var storeOpen = this.state.storeOpen;
    var storeClosed = this.state.storeClosed;
    var groceriesOpen = this.state.groceriesOpen;
    var groceriesClosed = this.state.groceriesClosed;
    var pharmacyOpen = this.state.pharmacyOpen;
    var pharmacyClosed = this.state.pharmacyClosed;
    var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
    if(value.businessType === 1) {
    const pos = storeClosed.map(e => e.id).indexOf(key);
    const pos2 = storeOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      storeClosed.push(value);
      storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
    } else {
      storeClosed[pos] = value;
      storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      storeOpen.splice(pos2, 1);
    }
  } else if(value.businessType === 2) {
    const pos = groceriesClosed.map(e => e.id).indexOf(key);
    const pos2 = groceriesOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      groceriesClosed.push(value);
      groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
    } else {
      groceriesClosed[pos] = value;
      groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      groceriesOpen.splice(pos2, 1);
    }
  } else if(value.businessType === 3) {
    const pos = pharmacyClosed.map(e => e.id).indexOf(key);
    const pos2 = pharmacyOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      pharmacyClosed.push(value);
      pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
    } else {
      pharmacyClosed[pos] = value;
      pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      pharmacyOpen.splice(pos2, 1);
    }
  }
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
    // setState(() {
    //   restaurant.removeWhere((item) => item.id == key);
    //   restClosed.removeWhere((item) => item.id == key);
    //   recommended.removeWhere((item) => item.id == key);
    //   restClosed.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));

    //   restaurant2.removeWhere((item) => item.id == key);
    //   restClosed2.removeWhere((item) => item.id == key);
    //   //recommended.removeWhere((item) => item.id == key);
    //   restClosed2.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
    // });
    // restaurant2.removeWhere((item) => item.id == key);
    // restClosed2.removeWhere((item) => item.id == key);
    // //recommended.removeWhere((item) => item.id == key);
    // restClosed2.add(value);
    // //print(value.name + ' ' + value.distance.toString());
    // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
    delete displayStoreOpen[key];
    displayStoreClosed[key] = value;
    try {
     await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
      //window.dispatchEvent(new Event("displayStoreOpen"));
    });
     await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
      //window.dispatchEvent(new Event("displayStoreClosed"));
    });
   } catch(e) {
     //console.log(e);
     await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
     await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
    // window.dispatchEvent(new Event("displayStoreClosed"));
    // window.dispatchEvent(new Event("displayStoreOpen"));
   }
    // window.dispatchEvent(new Event("displayStoreOpen"));
    // window.dispatchEvent(new Event("displayStoreClosed"));
    // await displayStoreOpen.delete(key);
    // await displayStoreClosed.put(key, value);
  } else
    return;
}

var displayStoreClosedKeys = Object.keys(displayStoreClosed);
for(var iqwe=0;iqwe<displayStoreClosedKeys.length;iqwe++) {
  var key = displayStoreClosedKeys[iqwe];
  var value = displayStoreClosed[key];
  if(value && value.location && value.location.cityId) {
    this.loadCity(value.location.cityId && iqwe===0);
  }
// displayStoreClosed.keys.toList().forEach((key) {
//   displayStoreClosed.get(key).then((value) async {
  var isOpen;
  if (value.available) {
    // var timezone = tz.getLocation(value.timezone);
    // DateTime date = tz.TZDateTime.now(timezone);
    // int currentDay = date.weekday - 1;
    var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[this.state.selectedDate.getDay()];
    if (value.schedule[currentDay]['enabled']) {
      for (var i = 0;
          i < value.schedule[currentDay]['lapses'].length;
          i++) {
        var date = this.state.selectedDate;
        if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                date.getHours() &&
            value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                date.getHours()) {
          isOpen = true;
        } else if (value.schedule[currentDay]['lapses'][i]['open']
                ['hour'] ===
            date.getHours()) {
          if (value.schedule[currentDay]['lapses'][i]['open']
                  ['minute'] <
              date.getMinutes()) {
            isOpen = true;
          }
        } else if (value.schedule[currentDay]['lapses'][i]['close']
                ['hour'] ===
            date.getHours()) {
          if (value.schedule[currentDay]['lapses'][i]['close']
                  ['minute'] >
              date.getMinutes()) {
            isOpen = true;
          }
        }
      }
    }
    if (isOpen == null) isOpen = false;
  } else
    isOpen = false;
  if (!isOpen) {
    var storeOpen = this.state.storeOpen;
    var storeClosed = this.state.storeClosed;
    var groceriesOpen = this.state.groceriesOpen;
    var groceriesClosed = this.state.groceriesClosed;
    var pharmacyOpen = this.state.pharmacyOpen;
    var pharmacyClosed = this.state.pharmacyClosed;
    var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
    if(value.businessType === 1) {
    const pos = storeClosed.map(e => e.id).indexOf(key);
    const pos2 = storeOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      storeClosed.push(value);
      storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
    } else {
      storeClosed[pos] = value;
      storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      storeOpen.splice(pos2, 1);
    }
  } else if(value.businessType === 2) {
    const pos = groceriesClosed.map(e => e.id).indexOf(key);
    const pos2 = groceriesOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      groceriesClosed.push(value);
      groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
    } else {
      groceriesClosed[pos] = value;
      groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      groceriesOpen.splice(pos2, 1);
    }
  } else if(value.businessType === 3) {
    const pos = pharmacyClosed.map(e => e.id).indexOf(key);
    const pos2 = pharmacyOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      pharmacyClosed.push(value);
      pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
    } else {
      pharmacyClosed[pos] = value;
      pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      pharmacyOpen.splice(pos2, 1);
    }
  }
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
    // setState(() {
    //   restaurant.removeWhere((item) => item.id == key);
    //   restClosed.removeWhere((item) => item.id == key);
    //   restClosed.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));
    //   restaurant2.removeWhere((item) => item.id == key);
    //   restClosed2.removeWhere((item) => item.id == key);
    //   restClosed2.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
    // });
    // // restaurant2.removeWhere((item) => item.id == key);
    // // restClosed2.removeWhere((item) => item.id == key);
    // // restClosed2.add(value);
    // // //print(value.name + ' ' + value.distance.toString());
    // // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
  } else if (isOpen) {
    var storeOpen = this.state.storeOpen;
    var storeClosed = this.state.storeClosed;
    var groceriesOpen = this.state.groceriesOpen;
    var groceriesClosed = this.state.groceriesClosed;
    var pharmacyOpen = this.state.pharmacyOpen;
    var pharmacyClosed = this.state.pharmacyClosed;
    var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(value.businessType === 1) {
    const pos = storeOpen.map(e => e.id).indexOf(key);
    const pos2 = storeClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      storeOpen.push(value);
      storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
    } else {
      storeOpen[pos] = value;
      storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      storeClosed.splice(pos2, 1);
    }
  } else if(value.businessType === 2) {
    const pos = groceriesOpen.map(e => e.id).indexOf(key);
    const pos2 = storeClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      groceriesOpen.push(value);
      groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
    } else {
      groceriesOpen[pos] = value;
      groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      groceriesClosed.splice(pos2, 1);
    }
  } else if(value.businessType === 3) {
    const pos = pharmacyOpen.map(e => e.id).indexOf(key);
    const pos2 = pharmacyClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      pharmacyOpen.push(value);
      pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
    } else {
      pharmacyOpen[pos] = value;
      pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      pharmacyClosed.splice(pos2, 1);
    }
  }
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
    // setState(() {
    //   restaurant.removeWhere((item) => item.id == key);
    //   restClosed.removeWhere((item) => item.id == key);
    //   restaurant.add(value);
    //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
    //   restaurant2.removeWhere((item) => item.id == key);
    //   restClosed2.removeWhere((item) => item.id == key);
    //   restaurant2.add(value);
    //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
    //   if ((restaurant.length - 1) % 10 == 0 &&
    //       restaurant.length < 52 &&
    //       restaurant.length != 1) {
    //     recommended.add(value);
    //   }
    // });
    // // restaurant2.removeWhere((item) => item.id == key);
    // // restClosed2.removeWhere((item) => item.id == key);
    // // restaurant2.add(value);
    // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
    delete displayStoreClosed[key];
    displayStoreOpen[key] = value;
    try {
     await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
     // window.dispatchEvent(new Event("displayStoreOpen"));
    });
     await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
      //window.dispatchEvent(new Event("displayStoreClosed"));
    });
   } catch(e) {
     //console.log(e);
     await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
     await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
    // window.dispatchEvent(new Event("displayStoreClosed"));
    // window.dispatchEvent(new Event("displayStoreOpen"));
   }
    // await displayStoreClosed.delete(key);
    // await displayStoreOpen.put(key, value);
  } else
    return;
}
}

window.addEventListener('displayStoreOpen', async() => {
  // this.setState({
  //   // restOpen: [],
  //   // restClosed: [],
  //   // storeOpen: [],
  //   // storeClosed: [],
  //   // displayOpen: [],
  //   // displayClosed: [],
  //   loadCityAgain: true
  // });
   var displayRestOpen;
   var displayRestClosed;
   var displayStoreOpen;
   var displayStoreClosed;
             try {
               displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
               displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
               displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
               displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
             } catch(e) {
               //console.log(e);
                displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
  displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
                displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
           displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
             }
           boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
           displayRestOpenKeys = Object.keys(displayRestOpen);
  var displayStoreOpenKeys = Object.keys(displayStoreOpen);
  for(var iqwe=0;iqwe<displayStoreOpenKeys.length;iqwe++) {
    var key = displayStoreOpenKeys[iqwe];
    var value = displayStoreOpen[key];
    if(value && value.location && value.location.cityId && iqwe===0) {
      this.loadCity(value.location.cityId);
    }
  // }
  // displayStoreOpen.keys.toList().forEach((key) {
  //   displayStoreOpen.get(key).then((value) async {
      var isOpen;
      if (value.available) {
        // var timezone = tz.getLocation(value.timezone);
        // DateTime date = tz.TZDateTime.now(timezone);
        var weekDay = [6,0,1,2,3,4,5];
var currentDay = weekDay[this.state.selectedDate.getDay()];
        // int currentDay = date.weekday - 1;
        if (value.schedule[currentDay]['enabled']) {
          for (var i = 0;
              i < value.schedule[currentDay]['lapses'].length;
              i++) {
            var date = this.state.selectedDate;
            if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                    date.getHours() &&
                value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                    date.getHours()) {
              isOpen = true;
            } else if (value.schedule[currentDay]['lapses'][i]['open']
                    ['hour'] ===
                date.getHours()) {
              if (value.schedule[currentDay]['lapses'][i]['open']
                      ['minute'] <
                  date.getMinutes()) {
                isOpen = true;
              }
            } else if (value.schedule[currentDay]['lapses'][i]['close']
                    ['hour'] ===
                date.getHours()) {
              if (value.schedule[currentDay]['lapses'][i]['close']
                      ['minute'] >
                  date.getMinutes()) {
                isOpen = true;
              }
            }
          }
        }
        if (isOpen == null) isOpen = false;
      } else
        isOpen = false;
      if (isOpen) {
        var storeOpen = this.state.storeOpen;
        var storeClosed = this.state.storeClosed;
        var groceriesOpen = this.state.groceriesOpen;
        var groceriesClosed = this.state.groceriesClosed;
        var pharmacyOpen = this.state.pharmacyOpen;
        var pharmacyClosed = this.state.pharmacyClosed;
        var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(value.businessType === 1) {
        const pos = storeOpen.map(e => e.id).indexOf(key);
        const pos2 = storeClosed.map(e => e.id).indexOf(key);
        if(pos<0) {
          storeOpen.push(value);
          storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
        } else {
          storeOpen[pos] = value;
          storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
        }
        if(pos2>=0) {
          storeClosed.splice(pos2, 1);
        }
      } else if(value.businessType === 2) {
        const pos = groceriesOpen.map(e => e.id).indexOf(key);
        const pos2 = groceriesClosed.map(e => e.id).indexOf(key);
        if(pos<0) {
          groceriesOpen.push(value);
          groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
        } else {
          groceriesOpen[pos] = value;
          groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
        }
        if(pos2>=0) {
          groceriesClosed.splice(pos2, 1);
        }
      } else if(value.businessType === 3) {
        const pos = pharmacyOpen.map(e => e.id).indexOf(key);
        const pos2 = pharmacyClosed.map(e => e.id).indexOf(key);
        if(pos<0) {
          pharmacyOpen.push(value);
          pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
        } else {
          pharmacyOpen[pos] = value;
          pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
        }
        if(pos2>=0) {
          pharmacyClosed.splice(pos2, 1);
        }
      }
        this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
          this.handleChange();
        });
        // setState(() {
        //   restaurant.removeWhere((item) => item.id == key);
        //   restClosed.removeWhere((item) => item.id == key);
        //   restaurant.add(value);
        //   //print(value.name + ' ' + value.distance.toString());
        //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
        //   restaurant2.removeWhere((item) => item.id == key);
        //   restClosed2.removeWhere((item) => item.id == key);
        //   restaurant2.add(value);
        //   //print(value.name + ' ' + value.distance.toString());
        //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
        // });
        // // restaurant2.removeWhere((item) => item.id == key);
        // // restClosed2.removeWhere((item) => item.id == key);
        // // restaurant2.add(value);
        // // //print(value.name + ' ' + value.distance.toString());
        // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
        // if ((restOpen.length - 1) % 10 == 0 &&
        //     restaurant.length < 52 &&
        //     restaurant.length != 1) {
        //   setState(() {
        //     recommended.add(value);
        //   });
        // }
      } else if (!isOpen) {
        var storeOpen = this.state.storeOpen;
        var storeClosed = this.state.storeClosed;
        var groceriesOpen = this.state.groceriesOpen;
        var groceriesClosed = this.state.groceriesClosed;
        var pharmacyOpen = this.state.pharmacyOpen;
        var pharmacyClosed = this.state.pharmacyClosed;
        var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(value.businessType === 0) {
        const pos = storeClosed.map(e => e.id).indexOf(key);
        const pos2 = storeOpen.map(e => e.id).indexOf(key);
        if(pos<0) {
          storeClosed.push(value);
          storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
        } else {
          storeClosed[pos] = value;
          storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
        }
        if(pos2>=0) {
          storeOpen.splice(pos2, 1);
        }
      } else if(value.businessType === 2) {
        const pos = groceriesClosed.map(e => e.id).indexOf(key);
        const pos2 = groceriesOpen.map(e => e.id).indexOf(key);
        if(pos<0) {
          groceriesClosed.push(value);
          groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
        } else {
          groceriesClosed[pos] = value;
          groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
        }
        if(pos2>=0) {
          groceriesOpen.splice(pos2, 1);
        }
      } else  if(value.businessType === 3) {
        const pos = pharmacyClosed.map(e => e.id).indexOf(key);
        const pos2 = pharmacyOpen.map(e => e.id).indexOf(key);
        if(pos<0) {
          pharmacyClosed.push(value);
          pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
        } else {
          pharmacyClosed[pos] = value;
          pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
        }
        if(pos2>=0) {
          pharmacyOpen.splice(pos2, 1);
        }
      }
        this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
          this.handleChange();
        });
        // setState(() {
        //   restaurant.removeWhere((item) => item.id == key);
        //   restClosed.removeWhere((item) => item.id == key);
        //   recommended.removeWhere((item) => item.id == key);
        //   restClosed.add(value);
        //   //print(value.name + ' ' + value.distance.toString());
        //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));

        //   restaurant2.removeWhere((item) => item.id == key);
        //   restClosed2.removeWhere((item) => item.id == key);
        //   //recommended.removeWhere((item) => item.id == key);
        //   restClosed2.add(value);
        //   //print(value.name + ' ' + value.distance.toString());
        //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
        // });
        // restaurant2.removeWhere((item) => item.id == key);
        // restClosed2.removeWhere((item) => item.id == key);
        // //recommended.removeWhere((item) => item.id == key);
        // restClosed2.add(value);
        // //print(value.name + ' ' + value.distance.toString());
        // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
        delete displayStoreOpen[key];
        displayStoreClosed[key] = value;
        try {
         await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
        //  window.dispatchEvent(new Event("displayStoreOpen"));
        });
         await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
         // window.dispatchEvent(new Event("displayStoreClosed"));
        });
       } catch(e) {
         //console.log(e);
         await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
         await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
        // window.dispatchEvent(new Event("displayStoreClosed"));
        // window.dispatchEvent(new Event("displayStoreOpen"));
       }
        // window.dispatchEvent(new Event("displayStoreOpen"));
        // window.dispatchEvent(new Event("displayStoreClosed"));
        // await displayStoreOpen.delete(key);
        // await displayStoreClosed.put(key, value);
      } else
        return;
    }
})

window.addEventListener('displayStoreClosed', async() => {
  // this.setState({
  //   // restOpen: [],
  //   // restClosed: [],
  //   // storeOpen: [],
  //   // storeClosed: [],
  //   // displayOpen: [],
  //   // displayClosed: [],
  //   loadCityAgain: true
  // });
   var displayRestOpen;
   var displayRestClosed;
   var displayStoreOpen;
   var displayStoreClosed;
             try {
               displayRestOpen = JSON.parse((await getAsyncItem('displayRestOpen')) ??"{}");
               displayRestClosed = JSON.parse((await getAsyncItem('displayRestClosed')) ??"{}");
               displayStoreOpen = JSON.parse((await getAsyncItem('displayStoreOpen')) ??"{}");
               displayStoreClosed = JSON.parse((await getAsyncItem('displayStoreClosed')) ??"{}");
             } catch(e) {
               //console.log(e);
                displayRestOpen = JSON.parse(localStorage.getItem('displayRestOpen')??"{}");
  displayRestClosed = JSON.parse(localStorage.getItem('displayRestClosed')??"{}");
                displayStoreOpen = JSON.parse(localStorage.getItem('displayStoreOpen')??"{}");
           displayStoreClosed = JSON.parse(localStorage.getItem('displayStoreClosed')??"{}");
             }
           boxSelected = JSON.parse(localStorage.getItem('selected')??"{}");
           displayRestOpenKeys = Object.keys(displayRestOpen);
var displayStoreClosedKeys = Object.keys(displayStoreClosed);
for(var iqwe=0;iqwe<displayStoreClosedKeys.length;iqwe++) {
  var key = displayStoreClosedKeys[iqwe];
  var value = displayStoreClosed[key];
  if(value && value.location && value.location.cityId) {
    this.loadCity(value.location.cityId && iqwe===0);
  }
// displayStoreClosed.keys.toList().forEach((key) {
//   displayStoreClosed.get(key).then((value) async {
  var isOpen;
  if (value.available) {
    // var timezone = tz.getLocation(value.timezone);
    // DateTime date = tz.TZDateTime.now(timezone);
    // int currentDay = date.weekday - 1;
    var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[this.state.selectedDate.getDay()];
    if (value.schedule[currentDay]['enabled']) {
      for (var i = 0;
          i < value.schedule[currentDay]['lapses'].length;
          i++) {
        var date = this.state.selectedDate;
        if (value.schedule[currentDay]['lapses'][i]['open']['hour'] <
                date.getHours() &&
            value.schedule[currentDay]['lapses'][i]['close']['hour'] >
                date.getHours()) {
          isOpen = true;
        } else if (value.schedule[currentDay]['lapses'][i]['open']
                ['hour'] ===
            date.getHours()) {
          if (value.schedule[currentDay]['lapses'][i]['open']
                  ['minute'] <
              date.getMinutes()) {
            isOpen = true;
          }
        } else if (value.schedule[currentDay]['lapses'][i]['close']
                ['hour'] ===
            date.getHours()) {
          if (value.schedule[currentDay]['lapses'][i]['close']
                  ['minute'] >
              date.getMinutes()) {
            isOpen = true;
          }
        }
      }
    }
    if (isOpen == null) isOpen = false;
  } else
    isOpen = false;
  if (!isOpen) {
    var storeOpen = this.state.storeOpen;
    var storeClosed = this.state.storeClosed;
    var groceriesOpen = this.state.groceriesOpen;
    var groceriesClosed = this.state.groceriesClosed;
    var pharmacyOpen = this.state.pharmacyOpen;
    var pharmacyClosed = this.state.pharmacyClosed;
    var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                //favorites[posF] = value;
                favorites.splice(posF, 1);
              } else {
                //favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(value.businessType === 0) {
    const pos = storeClosed.map(e => e.id).indexOf(key);
    const pos2 = storeOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      storeClosed.push(value);
      storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
    } else {
      storeClosed[pos] = value;
      storeClosed = storeClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      storeOpen.splice(pos2, 1);
    }
  } else if(value.businessType === 2) {
    const pos = groceriesClosed.map(e => e.id).indexOf(key);
    const pos2 = groceriesOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      groceriesClosed.push(value);
      groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
    } else {
      groceriesClosed[pos] = value;
      groceriesClosed = groceriesClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      groceriesOpen.splice(pos2, 1);
    }
  } else if(value.businessType === 3) {
    const pos = pharmacyClosed.map(e => e.id).indexOf(key);
    const pos2 = pharmacyOpen.map(e => e.id).indexOf(key);
    if(pos<0) {
      pharmacyClosed.push(value);
      pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
    } else {
      pharmacyClosed[pos] = value;
      pharmacyClosed = pharmacyClosed.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      pharmacyOpen.splice(pos2, 1);
    }
  }
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
    // setState(() {
    //   restaurant.removeWhere((item) => item.id == key);
    //   restClosed.removeWhere((item) => item.id == key);
    //   restClosed.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restClosed.sort((a, b) => a.distance.compareTo(b.distance));
    //   restaurant2.removeWhere((item) => item.id == key);
    //   restClosed2.removeWhere((item) => item.id == key);
    //   restClosed2.add(value);
    //   //print(value.name + ' ' + value.distance.toString());
    //   restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
    // });
    // // restaurant2.removeWhere((item) => item.id == key);
    // // restClosed2.removeWhere((item) => item.id == key);
    // // restClosed2.add(value);
    // // //print(value.name + ' ' + value.distance.toString());
    // // restClosed2.sort((a, b) => a.distance.compareTo(b.distance));
  } else if (isOpen) {
    var storeOpen = this.state.storeOpen;
    var storeClosed = this.state.storeClosed;
    var groceriesOpen = this.state.groceriesOpen;
    var groceriesClosed = this.state.groceriesClosed;
    var pharmacyOpen = this.state.pharmacyOpen;
    var pharmacyClosed = this.state.pharmacyClosed;
    var favorites = this.state.favorites;
            const posF = favorites.map(e => e.id).indexOf(key);
            if(this.state.userDetails && this.state.userDetails.favorites && this.state.userDetails.favorites.includes(key)) {
              if(posF>=0) {
                favorites[posF] = value;
                //favorites.splice(posF, 1);
              } else {
                favorites.push(value);
              }
            } else if(posF>=0) {
              favorites.splice(posF, 1);
            }
            this.setState({favorites: favorites},(newC)=>{
              this.handleChange();
            });
            if(value.businessType === 1) {
    const pos = storeOpen.map(e => e.id).indexOf(key);
    const pos2 = storeClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      storeOpen.push(value);
      storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
    } else {
      storeOpen[pos] = value;
      storeOpen = storeOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      storeClosed.splice(pos2, 1);
    }
  } else if(value.businessType === 2) {
    const pos = groceriesOpen.map(e => e.id).indexOf(key);
    const pos2 = groceriesClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      groceriesOpen.push(value);
      groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
    } else {
      groceriesOpen[pos] = value;
      groceriesOpen = groceriesOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      groceriesClosed.splice(pos2, 1);
    }
  } else if(value.businessType === 3) {
    const pos = pharmacyOpen.map(e => e.id).indexOf(key);
    const pos2 = pharmacyClosed.map(e => e.id).indexOf(key);
    if(pos<0) {
      pharmacyOpen.push(value);
      pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
    } else {
      pharmacyOpen[pos] = value;
      pharmacyOpen = pharmacyOpen.sort((a,b) => a.distance - b.distance);
    }
    if(pos2>=0) {
      pharmacyClosed.splice(pos2, 1);
    }
  }
    this.setState({storeOpen: storeOpen,storeClosed: storeClosed,groceriesOpen: groceriesOpen,groceriesClosed: groceriesClosed,pharmacyOpen: pharmacyOpen,pharmacyClosed: pharmacyClosed}, (newState)=>{
      this.handleChange();
    });
    // setState(() {
    //   restaurant.removeWhere((item) => item.id == key);
    //   restClosed.removeWhere((item) => item.id == key);
    //   restaurant.add(value);
    //   restaurant.sort((a, b) => a.distance.compareTo(b.distance));
    //   restaurant2.removeWhere((item) => item.id == key);
    //   restClosed2.removeWhere((item) => item.id == key);
    //   restaurant2.add(value);
    //   restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
    //   if ((restaurant.length - 1) % 10 == 0 &&
    //       restaurant.length < 52 &&
    //       restaurant.length != 1) {
    //     recommended.add(value);
    //   }
    // });
    // // restaurant2.removeWhere((item) => item.id == key);
    // // restClosed2.removeWhere((item) => item.id == key);
    // // restaurant2.add(value);
    // // restaurant2.sort((a, b) => a.distance.compareTo(b.distance));
    delete displayStoreClosed[key];
    displayStoreOpen[key] = value;
    try {
     await ldb.set('displayStoreOpen', JSON.stringify(displayStoreOpen), function(){
     // window.dispatchEvent(new Event("displayStoreOpen"));
    });
     await ldb.set('displayStoreClosed', JSON.stringify(displayStoreClosed), function(){
     // window.dispatchEvent(new Event("displayStoreClosed"));
    });
   } catch(e) {
     //console.log(e);
     await localStorage.setItem('displayStoreOpen',JSON.stringify(displayStoreOpen));
     await localStorage.setItem('displayStoreClosed',JSON.stringify(displayStoreClosed));
    // window.dispatchEvent(new Event("displayStoreClosed"));
    // window.dispatchEvent(new Event("displayStoreOpen"));
   }
    // await displayStoreClosed.delete(key);
    // await displayStoreOpen.put(key, value);
  } else
    return;
}
})
//   });
// });

  }
}

async getAdminData() {
  const busDoc = doc(db, 'admin', "adminData");
  const docSnap = await getDoc(busDoc);
  if(docSnap.exists() && docSnap.data()) {
    this.setState({adminData: docSnap.data()});
  }
}

render() {
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(this.state.anchorEl);
  const handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  const handleProfileMenuOpen = (event) => {
    // setAnchorEl(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
    // this.state.anchorEl = event.currentTarget;
  };
  const openAlert = (alertType, alertMsg) => {
    this.setState({
      openAlert: true,
      alertType: alertType,
      alertMsg: alertMsg,
    });
  };
  let columns = this.state.width < 500 ? 1 : this.state.width < 1000 ? 2 : 3;
  const handleCloseOTP = () => {
    this.setState({
      openOTP: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneCountry: '+52',
      phoneNumber: '',
      showPassword: false,
    });
  };
  const sendOTP = async (phone) => {
    const min = 0;
    const max = 999999;
    const rand = Math.floor(min + Math.random() * (max - min));
    var dataSend = rand.toString();
    for (var i = 0; i < 6; i++) {
      if (dataSend.length < 6) {
        dataSend = '0' + dataSend;
      }
    }
    // console.log(dataSend, phone);
    this.setState({ otpSend: dataSend });
    await axios
      .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-new-otp/', {
        code: dataSend,
        number: phone,
        uuid: this.state.userId
      })
      .then(async (response) => {
        // console.log(response);
      });
  };
  const handleClickOpenLogin = () => {
    this.setState({
      openLogin: true,
      showGuest: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCloseLogin();
    };
  };
  const handleCloseLogin = () => {
    this.setState({
      openLogin: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
    });
  };

  const handleClickOpenAdditionalRegister = () => {
    this.setState({
      openAdditionalRegister: true,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
    });
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCloseAdditionalRegister();
    };
  };
  const handleCloseAdditionalRegister = () => {
    auth.signOut();
    this.setState({
      openAdditionalRegister: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
    });
  };
  const handleTrackingOpen = (orderDetails, id) => {
    // setAnchorEl(event.currentTarget);
    this.setState({
      showTracking: true,
      trackingDetails: orderDetails,
      trackingId: id,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      this.setState({
        showTracking: false,
        trackingDetails: null,
        trackingId: null,
      });
    };
    // this.state.anchorEl = event.currentTarget;
  };

  const handleClickOpenRegister = () => {
    this.setState({
      openRegister: true,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCloseRegister();
    };
  };
  const handleCloseRegister = () => {
    this.setState({
      openRegister: false,
      password: '',
      email: '',
      fullName: '',
      otp: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
    });
  };
  const handleCardClickOpen = () => {
    this.setState({ openCards: true, cards:(this.state.userDetails && this.state.userDetails.cards) ? this.state.userDetails.cards : [], });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleCardClose();
    };
  };
  const handleCardClose = (value) => {
    this.setState({ openCards: false });
  };
  const handleWalletClickOpen2 = () => {
    this.setState({ openWallet: true,showOtherWallet: false, enteredAmount: 0 });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleWalletClose();
    };
  };
  const handleWalletClose = (value) => {
    this.setState({ openWallet: false });
  };
  const handleAllergyOpen = (event) => {
    this.setState({
      showAllergy: true,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      this.setState({ showAllergy: false });
    };
    // this.state.anchorEl = event.currentTarget;
  };
  const handleClickOpen = () => {
    this.setState({ openLocation: true, addedNotes: false });
    this.setState({ hasClickedLoc: false });
    this.setState({
      selectLocationData: {
        id: '',
        name: strings['mi hogar'],
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: this.state.defaultProps.center.lat,
        longitude: this.state.defaultProps.center.lng,
      },
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handleClose();
    };
  };
  const handleClose = (value) => {
    this.setState({ openLocation: false, addedNotes: false });
    this.setState({ hasClickedLoc: false });
    this.setState({ autoLoc: '' });
    this.setState({ selectedValue: value });
    this.setState({
      selectLocationData: {
        id: '',
        name: strings['mi hogar'],
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: this.state.defaultProps.center.lat,
        longitude: this.state.defaultProps.center.lng,
      },
    });
  };
  const handleAlertCancelOpen = (event) => {
    if(this.state.selectLocationName) {
    this.setState({
      openDialogCancel: true,
    });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      this.setState({ openDialogCancel: false });
    };
  }

  };

  const handleAlertCancelClose = (value) => {
    this.setState({ openDialogCancel: false });
  };
  const handlePreOrderOpen = async() => {
    this.setState({loading: true});
    await this.listMaker();
    //console.log(this.state.currenciesList3,this.state.currenciesList);
    this.setState({ openPreOrder: true,loading: false,      showTomorrow: false,      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: (this.state.showTomorrow)?(this.state.currenciesList3[this.state.currenciesList3Selected]?this.state.currenciesList3[this.state.currenciesList3Selected]['date']:null):(this.state.currenciesList && (this.state.currenciesList.length - 1)>=this.state.currenciesListSelected)?this.state.currenciesList[this.state.currenciesListSelected]['date']:((this.state.currenciesList3[this.state.currenciesList3Selected])?this.state.currenciesList3[this.state.currenciesList3Selected]['date']:null) });
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      window.onpopstate = () => {};
      window.history.back();
      handlePreOrderClose();
    };
  };
  const findTotalT = (id, i, order) => {
    var total1 =
      (order['items'][id]['customize'][i]['nameEng'] != null
        ? order['items'][id]['customize'][i]['nameEng']
        : order['items'][id]['customize'][i]['name']) + ': ';
    for (
      var j = 0;
      j < order['items'][id]['customize'][i]['options'].length;
      j++
    ) {
      if (j === 0) {
        total1 =
          total1 +
          (order['items'][id]['customize'][i]['options'][j]['nameEng'] != null
            ? order['items'][id]['customize'][i]['options'][j]['nameEng']
            : order['items'][id]['customize'][i]['options'][j]['name']);
      } else {
        total1 =
          total1 +
          ',' +
          (order['items'][id]['customize'][i]['options'][j]['nameEng'] != null
            ? order['items'][id]['customize'][i]['options'][j]['nameEng']
            : order['items'][id]['customize'][i]['options'][j]['name']);
      }
    }
    return total1;
  };
  const handlePreOrderClose = (value) => {
    this.setState({ openPreOrder: false,      showTomorrow: false,      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: null, });
  };
  const getStat = (order) => {
    var update = '';
    if (!order['acceptedByRest']) {
      update = strings['Esperando a que el negocio acepte su pedido'];
    }
    if (order['acceptedByRest'] && !order['readyToBePicked']) {
      update = strings['El negocio está preparando su producto.'];
    }
    // if (order['acceptedByRest'] && order['readyToBePicked']) {
    //   update = 'Orden lista para recolección ';
    // }
    if (
      order['driverAssigned'] &&
      !order['driverReached'] &&
      !order['orderPickedUp']
    ) {
      update =
        order['driverName'].split(' ')[0] +
        strings[' está en camino para recoger su pedido'];
    }
    if (
      order['driverAssigned'] &&
      order['driverReached'] &&
      !order['orderPickedUp']
    ) {
      update =
        order['driverName'].split(' ')[0] + strings[' llegó al negocio'];
    }

    if (order['orderPickedUp']) {
      update =
        order['driverName'].split(' ')[0] + strings[' ha recogido tu pedido'];
    }
    if (order['delivered'] && !order['cancelled']) {
      update = strings['Su orden ha sido completada'];
    }
    if (order['delivered'] && order['cancelled']) {
      update = strings['Su pedido ha sido cancelado'];
    }
    return update;
  };
  const renderMenu = (
    <>
      {!this.state.userId ? (
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem style={{ fontFamily: 'Nunito', fontWeight: '500', }}
            onClick={() => {
              handleMenuClose();
              handleClickOpenLogin();
            }}
          >
          <Stack
               direction="row"
               justifyContent="center"
               alignItems="center"
               spacing={0}
              >
              <LoginRoundedIcon
                 style={{
                   height: this.state.width > 599 ? '17px' : '20px',
                   fontSize: this.state.width > 599 ? '17px' : '20px',
                   fontWeight: 'bold',
                   color: constants.primaryColor,
                   marginRight: '10px',
                 }}
               />
              <Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 padding: '5px 11px',
                 color: '#3e4547',
                 fontSize: this.state.width > 599 ? '16px' : '18px',
               }}
             >
               {strings["Iniciar sesión"]}
             </Typography>
            </Stack>
          </MenuItem>
          <MenuItem style={{ fontFamily: 'Nunito', fontWeight: '500', }}
            onClick={() => {
              handleMenuClose();
              handleClickOpenRegister();
              // handleClickOpenOTP();
            }}
          >
          <Stack
               direction="row"
               justifyContent="center"
               alignItems="center"
               spacing={0}
              >
              <DataSaverOnRoundedIcon
                 style={{
                   height: this.state.width > 599 ? '17px' : '20px',
                   fontSize: this.state.width > 599 ? '17px' : '20px',
                   fontWeight: 'bold',
                   color: constants.primaryColor,
                   marginRight: '10px',
                 }}
               />
              <Typography
               style={{
                 fontWeight: '700',
                 fontFamily: 'Nunito',
                 textTransform: 'none',
                 padding: '5px 11px',
                 color: '#3e4547',
                 fontSize: this.state.width > 599 ? '16px' : '18px',
               }}
             >
               {strings["Registrarse"]}
             </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {this.state.userDetails && (
            <>
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                }}
              >
                <Link
                  to={'/profile'}
                  style={{
                    color: 'rgb(43 44 52)',
                    textDecoration: 'none',
                    width: '100%',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  {strings['Perfil']}
                </Link>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                }}
              >
                <Link
                  to={'/my-orders'}
                  style={{
                    color: 'rgb(43 44 52)',
                    textDecoration: 'none',
                    width: '100%',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  {strings['Mis Pedidos']}
                </Link>
              </MenuItem>
              {/* <MenuItem
                style={{
                  color: 'rgb(43 44 52)',
                  textDecoration: 'none',
                  width: '100%',
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  fontSize: '18px',
                }}
                onClick={() => {
                  handleMenuClose();
                  handleCardClickOpen();
                  // handleClickOpenOTP();
                }}
                >
                  {strings['profileMyCard']}
              </MenuItem> */}
              <MenuItem
                  style={{
                    color: 'rgb(43 44 52)',
                    textDecoration: 'none',
                    width: '100%',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    handleMenuClose();
                    handleWalletClickOpen2();
                    // handleClickOpenOTP();
                  }}
                  >
                    {strings['MiCartera']}
                </MenuItem>
              {(this.state.showAllergyData) ?
               <MenuItem
                 style={{
                   color: 'rgb(43 44 52)',
                   textDecoration: 'none',
                   width: '100%',
                   fontFamily: 'Nunito',
                   fontWeight: '600',
                   fontSize: '18px',
                 }}
                 onClick={() => {
                   handleMenuClose();
                   handleAllergyOpen();
                   // handleClickOpenOTP();
                 }}
               >{strings['profileAllergy']}
               </MenuItem>
              :null }
            </>
          )}
          <MenuItem
          style={{
            color: 'rgb(43 44 52)',
            textDecoration: 'none',
            width: '100%',
            fontFamily: 'Nunito',
            fontWeight: '600',
            fontSize: '18px',
          }}
            onClick={async () => {
              handleMenuClose();
              await auth.signOut();
            }}
          >
            {strings['Cerrar sesión']}
          </MenuItem>
        </Menu>
      )}
    </>
  );
  var _sessionToken = uuidv4();
  return (
    <>
    {/* <div id="loading_indicator" className="container">
      <img style={{width: this.state.width < 1000? "40vw":"15vw"}} src={logo} alt="loading" />
    </div> */}
    <CssBaseline />
    {this.state.orders && this.state.orders.length > 0 ? (
            <div
              style={{
                position: this.state.width > 599 ? 'fixed' : 'fixed',
                left: this.state.width > 599 ? 'calc( 100vw - 475px )' : '0px',
                height: '0px',
                zIndex: 999,
                width: this.state.width > 599 ? '460px' : '80vw',
                margin: '0px',
                top: isIOS ? 'calc((var(--vh, 1vh) * 100) - 82px)' : this.state.width > 599 ? 'calc( 100vh - 100px )' : 'calc( 100vh - 132px )',
              }}
            >
              <div
                style={{
                  padding: '0px 10px 5px',
                  marginTop: isIOS ? '-50px' : '0',
                }}
              >
                <Carousel
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '14px',
                    height: this.state.width > 599 ? '90px' : '69px',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
                    width: '94vw',
                  }}
                >
                  {this.state.orders.map((i, ind) => (
                    <div
                      onClick={() => {
                        handleTrackingOpen(i.data(), i.id);
                      }}
                      style={{
                        width: this.state.width > 599 ? 'calc(500px - 20px)' : 'calc(100vw - 20px)',
                        borderRadius: '17.5px',
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                        marginTop: this.state.width > 599 ? undefined : '4px',
                      }}
                      key={`order-id-1-${i.id}`}
                    >
                      <div
                        style={{
                          padding: this.state.width > 599 ? '11px 2% 11px' : '0px 4% 0px',
                          borderRadius: '17.5px',
                          backgroundColor: '#fff',
                        }}
                      >
                        <Stack
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box
                            textAlign="center"
                            height={'7.5%'}
                            style={{ borderRadius: '50%', display: this.state.width > 599 ? 'block' : 'block', }}
                          >
                            <img
                              src={i.data()['restLogo']}
                              alt="logo"
                              height={'50px'}
                              style={{
                                borderRadius: '50%',
                                verticalAlign: 'middle',
                              }}
                            />
                          </Box>
                          <div style={{ padding: '0px 1% 0px', display: this.state.width > 599 ? 'block' : 'none', }}></div>
                          <div style={{ width: this.state.width > 599 ? '46%' : '100%', }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: this.state.width > 599 ? '16px' : '16.1px',
                                  fontWeight: '800',
                                  color: '#2c3136',
                                }}
                              >
                                {strings['ID del pedido: ']}
                                {i.data()['number']}
                              </Typography>
                              <div style={{ padding: '1px 0px 1px', display: this.state.width > 599 ? 'block' : 'none', }}></div>
                              <Typography
                                style={{
                                  fontSize: this.state.width > 599 ? '14px' : '1.5vh',
                                  fontWeight: 'normal',
                                  color: 'slategray',
                                }}
                              >
                                {i.data()['status']}
                              </Typography>
                              <div style={{ flexGrow: 1, marginTop: '2px', display: this.state.width > 599 ? 'none' : 'block', }}>
                                <Stack
                                  direction={'column'}
                                  spacing={0}
                                  alignItems="flex-start"
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <>
                                      {!i.data()['acceptedByRest'] ? (
                                        <div
                                          style={{
                                            border:
                                              '2px solid ' + constants.primaryColor,
                                            borderRadius: '80px',
                                            width: '17px',
                                            height: '17px',
                                          }}
                                        ></div>
                                      ) : null}
                                    </>
                                    <>
                                      {i.data()['acceptedByRest'] ? (
                                        <div>
                                          <CheckCircleIcon
                                            style={{
                                              color: constants.primaryColor,
                                              padding: '5px 0px 0px',
                                              fontSize: '25px',
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                    <>
                                      {
                                        <div
                                          style={{ width: '8px', height: '3px' }}
                                        >
                                          <Divider
                                            style={{
                                              borderBottomWidth: '3px',
                                              backgroundColor: constants.primaryColor,
                                            }}
                                          />
                                        </div>
                                      }
                                    </>
                                    <>
                                      {
                                        <div
                                          style={{ width: '8px', height: '3px' }}
                                        >
                                          <Divider
                                            style={{
                                              borderBottomWidth: '3px',
                                              backgroundColor: i.data()[
                                                'acceptedByRest'
                                              ]
                                                ? constants.primaryColor
                                                : '#C4C1C0',
                                            }}
                                          />
                                        </div>
                                      }
                                    </>
                                    <>
                                      {!i.data()['driverReached'] ? (
                                        <div
                                          style={{
                                            border: i.data()['acceptedByRest']
                                              ? '2px solid ' + constants.primaryColor
                                              : '2px solid #C4C1C0',
                                            borderRadius: '80px',
                                            width: '17px',
                                            height: '17px',
                                          }}
                                        ></div>
                                      ) : null}
                                    </>
                                    <>
                                      {i.data()['driverReached'] ? (
                                        <div>
                                          <CheckCircleIcon
                                            style={{
                                              padding: '5px 0px 0px',
                                              fontSize: '25px',
                                              color: constants.primaryColor,
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                    <>
                                      {
                                        <div
                                          style={{ width: '8px', height: '3px' }}
                                        >
                                          <Divider
                                            style={{
                                              borderBottomWidth: '3px',
                                              backgroundColor: i.data()[
                                                'driverAssigned'
                                              ]
                                                ? constants.primaryColor
                                                : '#C4C1C0',
                                            }}
                                          />
                                        </div>
                                      }
                                    </>
                                    <>
                                      {
                                        <div
                                          style={{ width: '8px', height: '3px' }}
                                        >
                                          <Divider
                                            style={{
                                              borderBottomWidth: '3px',
                                              backgroundColor: i.data()[
                                                'driverReached'
                                              ]
                                                ? constants.primaryColor
                                                : '#C4C1C0',
                                            }}
                                          />
                                        </div>
                                      }
                                    </>
                                    <>
                                      {!i.data()['delivered'] ? (
                                        <div
                                          style={{
                                            border: i.data()['driverReached']
                                              ? `2px solid ${constants.primaryColor}`
                                              : '2px solid #C4C1C0',
                                            borderRadius: '80px',
                                            width: '17px',
                                            height: '17px',
                                          }}
                                        ></div>
                                      ) : null}
                                    </>
                                    <>
                                      {i.data()['delivered'] ? (
                                        <div>
                                          <CheckCircleIcon
                                            style={{
                                              padding: '5px 0px 0px',
                                              fontSize: '25px',
                                              color: constants.primaryColor,
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                    <>
                                      {
                                        <div
                                          style={{ width: '8px', height: '3px' }}
                                        >
                                          <Divider
                                            style={{
                                              borderBottomWidth: '3px',
                                              backgroundColor: i.data()[
                                                'orderPickedUp'
                                              ]
                                                ? constants.primaryColor
                                                : '#C4C1C0',
                                            }}
                                          />
                                        </div>
                                      }
                                    </>
                                    <>
                                      {
                                        <div
                                          style={{ width: '8px', height: '3px' }}
                                        >
                                          <Divider
                                            style={{
                                              borderBottomWidth: '3px',
                                              backgroundColor: i.data()['delivered']
                                                ? constants.primaryColor
                                                : '#C4C1C0',
                                            }}
                                          />
                                        </div>
                                      }
                                    </>
                                    <>
                                      {!i.data()['delivered'] ? (
                                        <div
                                          style={{
                                            border: i.data()['delivered']
                                              ? `2px solid ${constants.primaryColor}`
                                              : '2px solid #C4C1C0',
                                            borderRadius: '80px',
                                            width: '17px',
                                            height: '17px',
                                          }}
                                        ></div>
                                      ) : null}
                                    </>
                                    <>
                                      {i.data()['delivered'] &&
                                      !i.data()['cancelled'] ? (
                                        <div>
                                          <CheckCircleIcon
                                            style={{
                                              padding: '5px 0px 0px',
                                              fontSize: '25px',
                                              color: constants.primaryColor,
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                    <>
                                      {i.data()['delivered'] &&
                                      !i.data()['cancelled'] ? (
                                        <div>
                                          <CancelIcon
                                            style={{
                                              padding: '5px 0px 0px',
                                              fontSize: '25px',
                                              color: constants.primaryColor,
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                  </Stack>
                                </Stack>
                              </div>
                            </Stack>
                          </div>
                          <div style={{ padding: '0px 1% 0px', display: this.state.width > 599 ? 'block' : 'none', }}></div>
                          <div style={{ flexGrow: 1, display: this.state.width > 599 ? 'block' : 'none', }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <>
                                  {!i.data()['acceptedByRest'] ? (
                                    <div
                                      style={{
                                        border:
                                          '2px solid ' + constants.primaryColor,
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['acceptedByRest'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          color: constants.primaryColor,
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: constants.primaryColor,
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'acceptedByRest'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {!i.data()['driverReached'] ? (
                                    <div
                                      style={{
                                        border: i.data()['acceptedByRest']
                                          ? '2px solid ' + constants.primaryColor
                                          : '2px solid #C4C1C0',
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['driverReached'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: constants.primaryColor,
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'driverAssigned'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'driverReached'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {!i.data()['delivered'] ? (
                                    <div
                                      style={{
                                        border: i.data()['driverReached']
                                          ? `2px solid ${constants.primaryColor}`
                                          : '2px solid #C4C1C0',
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['delivered'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: constants.primaryColor,
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()[
                                            'orderPickedUp'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {
                                    <div
                                      style={{ width: '8px', height: '3px' }}
                                    >
                                      <Divider
                                        style={{
                                          borderBottomWidth: '3px',
                                          backgroundColor: i.data()['delivered']
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                        }}
                                      />
                                    </div>
                                  }
                                </>
                                <>
                                  {!i.data()['delivered'] ? (
                                    <div
                                      style={{
                                        border: i.data()['delivered']
                                          ? `2px solid ${constants.primaryColor}`
                                          : '2px solid #C4C1C0',
                                        borderRadius: '80px',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    ></div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['delivered'] &&
                                  !i.data()['cancelled'] ? (
                                    <div>
                                      <CheckCircleIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: constants.primaryColor,
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                                <>
                                  {i.data()['delivered'] &&
                                  !i.data()['cancelled'] ? (
                                    <div>
                                      <CancelIcon
                                        style={{
                                          padding: '5px 0px 0px',
                                          fontSize: '25px',
                                          color: constants.primaryColor,
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              </Stack>
                            </Stack>
                          </div>
                        </Stack>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : null}
                                <AlertDialog2
          selectedValue={this.state.selectedValue}
          open={this.state.openDialogCancel}
          displayTitle={""}
          onClose={handleAlertCancelClose}
          displayList={
            <DialogContent dividers style={{ textAlign: 'center', }}>
              <Typography gutterBottom style={{
                                    fontSize: '19.5px',
                                    fontWeight: '600',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}>
                {strings['Su pedido ha sido cancelado con éxito.']}
              </Typography>
              <Stack
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
  spacing={2}
>
              <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ openDialogCancel: false });
                    }}
                  >
                    {'OK'}
                  </Button></Stack>
            </DialogContent>
          }
        />
                <OTPDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openOTP}
          onClose={handleCloseOTP}
          displayList={
            <DialogContent dividers>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl variant="filled">
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                   {strings["Número de teléfono"]}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber.replace('+52','')}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                    //           endAdornment={
                    //             <InputAdornment position="end">
                    //               <IconButton
                    //                 aria-label="toggle password visibility"
                    //                 onClick={()=>{
                    //                   this.setState({'showPassword': !this.state.showPassword})
                    //                 }}
                    //                 onMouseDown={(event) => {
                    //   event.preventDefault();
                    // }}
                    //                 edge="end"
                    //               >
                    //                 {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    //               </IconButton>
                    //             </InputAdornment>
                    //           }
                  />
                </FormControl>
                &nbsp;&nbsp;
                <Button
                  variant="text"
                  style={{ textTransform: 'none' }}
                  onClick={async () => {
                    if (this.state.phoneNumber.length >= 10) {
                      this.setState({ loading: true });
                      await updateDoc(doc(db, 'users', this.state.userId), {
                        phoneNumber:
                          this.state.phoneCountry + this.state.phoneNumber,
                      });
              sendOTP(this.state.phoneCountry + this.state.phoneNumber);
                      this.setState({ loading: false });
                      this.setState({
                        openAlert: true,
                        alertType: 'success',
                        alertMsg: strings['Cambiar número Éxito'],
                      });
                      // this.setState({ userId: uid });
                      // ...
                      // })
                      // .catch((error) => {
                      //   const errorCode = error.code;
                      //   const errorMessage = error.message;
                      //   this.setState({loading: false});
                      //   openAlert('error',"Cant login");
                      //   console.log(errorMessage,errorCode);
                      // });
                    }
                  }}
                >
                  {strings["Reenviar"]}
                </Button>
              </Box>
              <br></br>
              <br></br>
              <Typography align={'center'}>
                {strings["Mensaje enviado correctamente al número anterior"]}
              </Typography>

              <Button
                fullWidth
                variant="text"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  sendOTP(this.state.phoneCountry + this.state.phoneNumber);
                }}
              ></Button>
              <br></br>
              <br></br>
              <OtpInput
                style={{ placeContent: 'center' }}
                align={'center'}
                inputStyle={{
                  width: '32px',
                  height: '32px',
                  fontSize: '17px',
                  fontWeight: 'bold',
                }}
                numInputs={6}
                onChange={(value) => {
                  this.setState({ otp: value });
                  // console.log(value, typeof value);
                }}
                separator={
                  <span style={{ width: '20px', height: '20px' }}></span>
                }
                isInputNum={true}
                shouldAutoFocus
                value={this.state.otp}
              />
              <br />
              <br />
              <Button
                fullWidth
                variant="contained"
                sx={{ backgroundColor: constants.primaryColor }}
                onClick={async () => {
                  if (this.state.otp === this.state.otpSend) {
                    this.setState({ loading: true });
                    await updateDoc(doc(db, 'users', this.state.userId), {
                      phoneNumberVerified: true,
                    });
                    this.setState({ loading: false, openOTP: false });
                    this.setState({
                      openAlert: true,
                      alertType: 'success',
                      alertMsg: strings['Has ingresado correctamente'],
                    });
                    // this.setState({ userId: uid });
                    // ...
                    // })
                    // .catch((error) => {
                    //   const errorCode = error.code;
                    //   const errorMessage = error.message;
                    //   this.setState({loading: false});
                    //   openAlert('error',"Cant login");
                    //   console.log(errorMessage,errorCode);
                    // });
                  } else {
                    this.setState({
                      openAlert: true,
                      alertType: 'error',
                      alertMsg:
                        strings['El código ingresado no es correcto, intenta nuevamente'],
                    });
                  }
                }}
              >
                {strings["Ingresar"]}
              </Button>
            </DialogContent>
          }
        />
<TrackDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showTracking}
          onClose={() => {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }}
          title={this.state.width}
          displayList={
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
              style={{ background: '#ffffff' }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width < 600 ? '24px' : '18px',
                      fontWeight: '800',
                      color: '#2c3136',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      paddingLeft: '10px',
                    }}
                  >
                    {strings['Detalles del pedido']}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({
                        showTracking: false,
                        trackingDetails: null,
                        trackingId: null,
                      });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <>
                  {this.state.trackingDetails ? (
                    <Stack
                      direction={'column'}
                      spacing={0}
                      style={{
                        height: isIOS
                          ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                          : 'calc(100vh - 50px)',
                        overflow: 'auto',
                      }}
                    >
                      <div style={{ height: '300px' }}>
                        <div style={{ height: '300px', width: '100%' }}>
                          <GoogleMapReact
                            defaultCenter={{
                              lat: this.state.trackingDetails.userLocation
                                .latitude,
                              lng: this.state.trackingDetails.userLocation
                                .longitude,
                            }}
                            defaultZoom={this.state.defaultProps.zoom}
                            resetBoundsOnResize={true}
                            options={{
                              fullscreenControl: false,
                              scrollwheel: false,
                              panControl: false,
                              zoomControl: false,
                              rotateControl: false,
                            }}
                            bootstrapURLKeys={{
                              key: constants.googleMapsAPI,
                            }}
                            style={{
                              height: '300px',
                              padding: '8px',
                              borderRadius: '10px',
                            }}
                            onChange={(changeData) => {
                              // //console.log(changeData);
                              // this.setState({
                              //   defaultProps: {
                              //     center: {
                              //       lat: changeData.center.lat,
                              //       lng: changeData.center.lng,
                              //     },
                              //     zoom: changeData.zoom,
                              //   },
                              //   selectLocationData: {
                              //     id: this.state.selectLocationData.id,
                              //     name: this.state.selectLocationData.name,
                              //     address: this.state.selectLocationData.address,
                              //     geoAddress: this.state.selectLocationData.geoAddress,
                              //     addressNotes:
                              //       this.state.selectLocationData.addressNotes,
                              //     latitude: changeData.center.lat,
                              //     longitude: changeData.center.lng,
                              //   },
                              // });
                            }}
                          >
                            {/* <div
    style={{  display: "flex",
width: "50px",
opacity: "0.5",
height: "50px",
backgroundColor: "green",
borderRadius: "50%"}}
    lat={this.state.defaultCircleProps.center.lat}
      lng={this.state.defaultCircleProps.center.lng}
  /> */}
                            {/* <HomeIcon
                              style={{
                                color: constants.primaryColor,
                                transform: 'translate(-50%, -100%)',
                              }}
                              fontSize={'large'}
                              lat={
                                this.state.trackingDetails.userLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.userLocation
                                  .longitude
                              }
                              // text={'Google Map'}
                            /> */}
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              style={{
                                fontSize: '50px',
                                padding: '6px',
                                transform: 'translate(-50%, -100%)',
                              }}
                              lat={
                                this.state.trackingDetails.userLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.userLocation
                                  .longitude
                              }
                            >
                              <rect
                                id="Rectangle-6_1_"
                                x="0.4"
                                y="0.4"
                                className="st0"
                                width="511.2"
                                height="511.2"
                              />
                              <g>
                                <path
                                  id="path-1_1_"
                                  className="st4"
                                  d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1s-334.3-46.8-379.5,0
                                              s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5C270.7,511.2,295.4,471.1,309.4,446.1z"
                                />
                              </g>
                              <path
                                className="st5"
                                d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                            c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                            c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                            c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                            C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                            c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                            c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                            c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                            C414.5,33.6,341.1,21.6,256,21.6z"
                              />
                              <path
                                className="st3"
                                d="M231.4,324.2c-7.2,0-14.1-3.1-18.9-8.5l-67.6-76c-9.3-10.5-8.3-26.5,2.1-35.8c10.5-9.3,26.5-8.4,35.8,2.1
                                            l46.5,52.3l108.2-151.5c8.1-11.4,23.9-14,35.3-5.9c11.4,8.1,14,24,5.9,35.3L252.1,313.6c-4.5,6.3-11.6,10.2-19.3,10.6
                                            C232.3,324.2,231.9,324.2,231.4,324.2z"
                              />
                            </svg>
                            {/* <StoreMallDirectoryIcon
                              style={{
                                color: constants.primaryColor,
                                transform: 'translate(-50%, -100%)',
                              }}
                              fontSize={'large'}
                              lat={
                                this.state.trackingDetails.restLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.restLocation
                                  .longitude
                              }
                              // text={'Google Map'}
                            /> */}
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              style={{
                                fontSize: '50px',
                                padding: '6px',
                                transform: 'translate(-50%, -100%)',
                              }}
                              lat={
                                this.state.trackingDetails.restLocation.latitude
                              }
                              lng={
                                this.state.trackingDetails.restLocation
                                  .longitude
                              }
                            >
                              <rect
                                id="Rectangle-6"
                                x="0.4"
                                y="0.4"
                                className="st0"
                                width="511.2"
                                height="511.2"
                              />
                              <g>
                                <path
                                  id="path-1"
                                  className="st1"
                                  d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1s-334.3-46.8-379.5,0
                                          s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5C270.7,511.2,295.4,471.1,309.4,446.1z"
                                />
                              </g>
                              <path
                                className="st2"
                                d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                        c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                        c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                        c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                        C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                        c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                        c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                        c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                        C414.5,33.6,341.1,21.6,256,21.6z"
                              />
                              <path
                                className="st3"
                                d="M386.5,182.8c-0.2-0.5-0.5-0.9-0.8-1.3l-31.9-39.6v-54c0-3.9-3.1-7-7-7H166.3c-3.9,0-7,3.1-7,7v50.9L125,183.9
                                        c-0.4,0.5-0.7,1-0.9,1.6c-0.3,0.7-6.4,16.1,1.7,28.3c4.6,6.9,12.6,11,23.7,12.1v120.2c0,3.9,3.1,7,7,7h200.7c3.9,0,7-3.1,7-7V225.7
                                        c10.7-1.5,18.2-5.6,22.5-12.4C394.9,200.2,386.9,183.5,386.5,182.8z M231.6,339.2v-84.6h56.9v84.6H231.6z M263.8,212.2v-62.6h17.4
                                        l16.8,62.6C287.2,212.2,275.6,212.2,263.8,212.2z M312.5,212.2l-16.8-62.6h16.9l33.6,62.6C338.9,212.2,327.8,212.2,312.5,212.2z
                                        M167.3,212.3l33.6-62.7h16.9L201,212.3C188,212.3,176.4,212.3,167.3,212.3z M215.5,212.2l16.8-62.6h17.6v62.6
                                        C238.2,212.2,226.5,212.2,215.5,212.2z M217.6,247.6v91.6h-54V226.3h186.7v112.9h-47.7v-91.6c0-3.9-3.1-7-7-7h-70.9
                                        C220.7,240.6,217.6,243.7,217.6,247.6z M137.4,206L137.4,206c-3.3-4.9-1.3-12.4-0.6-14.5l31.8-41.9h16.4l-33.5,62.5
                                        C144.5,211.5,139.7,209.4,137.4,206z M339.8,94.8v40.8H173.3V94.8H339.8z M342.1,149.6l32.1,39.9c0.9,2.2,4.1,10.8,0.7,16.3
                                        c-2.1,3.2-6.4,5.3-13,6.1l-33.4-62.3H342.1z"
                              />
                            </svg>
                              {this.state.trackingDetails.driverAssigned ? (
                                // <DeliveryDiningIcon
                                //   style={{
                                //     color: constants.primaryColor,
                                //     transform: 'translate(-50%, -100%)',
                                //   }}
                                //   fontSize={'large'}
                                //   lat={
                                //     this.state.trackingDetails.driverLocation
                                //       .latitude
                                //   }
                                //   lng={
                                //     this.state.trackingDetails.driverLocation
                                //       .longitude
                                //   }
                                //   // text={'Google Map'}
                                // />
                                <svg
                                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                  style={{
                                    fontSize: '50px',
                                    padding: '6px',
                                    transform: 'translate(-50%, -100%)',
                                  }}
                                  lat={
                                    this.state.trackingDetails.driverLocation
                                      .latitude
                                  }
                                  lng={
                                    this.state.trackingDetails.driverLocation
                                      .longitude
                                  }
                                >
                                  <rect
                                    id="Rectangle-6_4_"
                                    x="0.4"
                                    y="0.4"
                                    className="st0"
                                    width="511.2"
                                    height="511.2"
                                  />
                                  <g>
                                    <g>
                                      <path
                                        id="path-1_12_"
                                        className="st6"
                                        d="M309.4,446.1c63.4-4,118.7-15.1,136.4-33.5c45.2-46.8,45.2-330.3,0-377.1
                                                  s-334.3-46.8-379.5,0s-45.2,330.3,0,377.1c17.7,18.4,72.9,29.5,136.3,33.5c14,25.3,38.8,65.9,53.5,65.5
                                                  C270.7,511.2,295.4,471.1,309.4,446.1z"
                                      />
                                    </g>
                                  </g>
                                  <path
                                    className="st7"
                                    d="M255.6,511.4c-6.1,0-12.3-3.7-25.5-21.7c-8.6-11.7-18.4-27.3-27.5-43.8l0-0.1l-0.1,0
                                              c-33.2-2.1-113.1-9.5-136.2-33.4c-25-25.9-33.8-114.3-33.8-188.4S41.4,61.5,66.3,35.6C91.3,9.8,180.7,0.6,256,0.6c0,0,0,0,0,0
                                              c75.3,0,164.7,9.2,189.6,35.1c25,25.9,33.8,114.3,33.8,188.4s-8.9,162.6-33.8,188.4c-23.1,23.9-103,31.4-136.3,33.4l-0.1,0l0,0.1
                                              c-9.2,16.5-19,32-27.6,43.6c-13.3,18-19.7,21.7-25.6,21.8C255.9,511.4,255.8,511.4,255.6,511.4z M259.1,491.2
                                              C259.1,491.2,259.1,491.2,259.1,491.2L259.1,491.2z M256,21.6c-85.1,0-158.5,12-174.5,28.6c-7.9,8.2-15.3,31.2-20.4,63
                                              c-4.9,31-7.6,70.4-7.6,110.9c0,40.5,2.7,79.9,7.6,110.9c5,31.8,12.4,54.8,20.4,63c9.9,10.3,51.6,22.6,122.4,27.1l11.6,0.7l5.6,10.1
                                              c15.2,27.5,28.4,45.4,34.8,52.4c6.4-6.8,19.5-24.4,35.1-52.4l5.6-10.1l11.5-0.7c70.8-4.4,112.6-16.8,122.5-27.1
                                              c7.9-8.2,15.3-31.2,20.4-63c4.9-31,7.6-70.4,7.6-110.9c0-40.5-2.7-79.9-7.6-110.9c-5-31.8-12.4-54.8-20.4-63
                                              C414.5,33.6,341.1,21.6,256,21.6z"
                                  />
                                  <path
                                    className="st3"
                                    d="M168.9,319.9c-1.6,0-3.1-0.1-4.7-0.3c-23.9-2.9-39.5-22.4-37-46.5c0-0.1,0-0.2,0.1-0.4h-7.8
                                              c-2.2,0-4.2-1.2-5.3-3.1l-15-26.2c-1.3-2.3-1-5.2,0.8-7.1l42.7-46.8c1.2-1.3,2.8-2,4.5-2h58.1c3.4,0,6.1,2.7,6.1,6.1
                                              s-2.7,6.1-6.1,6.1h-55.4l-37.9,41.6l11.1,19.3h172.5c7.9-13.9,23.7-39.9,32.5-43.9c3-1.4,6.7-0.1,8.1,3c1.4,3.1,0,6.7-3,8
                                              c-4.4,2.3-18.4,23.5-28.7,41.9c-1.1,1.9-3.1,3.1-5.3,3.1H139.2c0.1,0.5,0.1,1,0.1,1.6c-1.7,17.2,9.3,31.1,26.4,33.2
                                              c12.8,1.6,25.1-6.2,30.4-19.3c1.3-3.1,4.8-4.6,7.9-3.3c3.1,1.3,4.6,4.8,3.4,7.9C200.5,309.5,185.5,319.9,168.9,319.9z M370.3,319.9
                                              c-21.5,0-40-16.7-43-38.8c-0.5-3.3,1.9-6.4,5.2-6.9c3.2-0.4,6.4,1.9,6.9,5.2c2.2,16.1,15.5,28.3,31,28.3c17.3,0,31.3-14.8,31.3-33.1
                                              c0-5.4-1.2-10.7-3.6-15.4c-1.5-3-0.3-6.7,2.7-8.2c3-1.5,6.7-0.3,8.2,2.7c3.2,6.4,4.9,13.6,4.9,20.8
                                              C413.8,299.6,394.3,319.9,370.3,319.9z M335.6,268.4h-19.5c-3.4,0-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1h15.8c3.9-7,14.2-21.6,30.7-21.6
                                              c13.9,0,22.9,1.4,26.4,2l4.8-1.5c-3.7-6.7-12.9-18.1-34.8-24.3c-0.2-0.1-0.4-0.1-0.6-0.2c-2.6,1-5.7,0.2-7.3-2.3
                                              c0,0-45.2-66.9-46.5-68.9c-1.2-1.7-1.4-3.9-0.6-5.9c0.8-1.9,2.5-3.3,4.6-3.7l6.6-1.1l-0.6-13.6l-4.1-1l-19.3,13.5
                                              c-1,0.7-2.2,1.1-3.5,1.1l-23.1,0.1c0,0,0,0,0,0c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1l21.2,0l20-14c1.4-1,3.2-1.3,4.9-0.9
                                              l11.2,2.7c2.6,0.6,4.5,3,4.6,5.7l1,23.6c0.1,3.1-2,5.7-5.1,6.2l-2.5,0.4c10.1,15,31.6,46.8,39.6,58.6c0.9-0.2,1.9-0.1,2.8,0.1
                                              c38.9,11.1,45.3,37.4,45.6,38.5c0.7,3.1-1.1,6.2-4.1,7.2l-12.6,3.9c-1,0.3-2.2,0.4-3.2,0.1c-0.1,0-8.9-2-25.3-2
                                              c-13.3,0-21.3,17.7-21.4,17.9C340.2,267,338.1,268.4,335.6,268.4z M276,242.7h-63c-2.3,0-4.4-1.3-5.5-3.4c-1-2.1-0.8-4.6,0.7-6.4
                                              l27.3-35.3c1.2-1.5,2.9-2.5,5-2.4l5.6,0.1c0.5-5.3-1.1-15-15.9-23.8l-91.6-0.4c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.5-6.2,6.1-6.1
                                              l93.2,0.4c1,0,2,0.3,2.9,0.8c25.8,14.4,25.4,34.9,21.7,43.8c-1,2.3-3.3,3.7-5.7,3.7l-7.4-0.2l-17.8,23H273l27.3-37.3l-20.9-36.6
                                              c-1.7-2.9-0.7-6.6,2.3-8.3c2.9-1.7,6.7-0.6,8.3,2.3l22.9,40.1c1.2,2.1,1,4.7-0.4,6.6l-31.5,42.9C279.8,241.7,278,242.7,276,242.7z"
                                  />
                                </svg>
                              ) : null}
                            
                          </GoogleMapReact>
                          <>
                            {!this.state.trackingDetails.delivered ? (
                              <div
                                style={{
                                  position: 'relative',
                                  bottom: '200px',
                                  right: '10px',
                                  width: '200px',
                                  float: 'right',
                                }}
                              >
                                <div style={{ padding: '15px' }}>
                                  <div
                                    style={{
                                      backgroundColor: '#fff',
                                      borderRadius: '20px',
                                      height: '120px',
                                      boxShadow: '0px 4px 10px #64717a42',
                                      padding: '10px',
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="center"
                                    >
                                      <>
                                        {this.state.trackingDetails != null &&
                                        (this.state.trackingDetails[
                                          'isPickup'
                                        ] == null ||
                                          !this.state.trackingDetails[
                                            'isPickup'
                                          ]) ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Pedido número']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['ID del pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['number']
                                          ? this.state.trackingDetails[
                                              'number'
                                            ].toString()
                                          : ''}
                                      </Typography>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Divider />
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        (this.state.trackingDetails[
                                          'isPickup'
                                        ] == null ||
                                          !this.state.trackingDetails[
                                            'isPickup'
                                          ]) ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Entrega estimada']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'isPickup'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'normal',
                                              color: '#777',
                                            }}
                                          >
                                            {strings['Recogida estimada']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <div
                                        style={{ padding: '2px 0px 0px' }}
                                      ></div>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {moment(this.state.trackingDetails['createdOn']
                                          .toDate()).add(Math.trunc(
                                              this.state.trackingDetails[
                                                'restEstimateTime'
                                              ]
                                                ? this.state.trackingDetails[
                                                    'restEstimateTime'
                                                  ]
                                                : 30
                                            ) -
                                            6, 'minutes').format('HH:mm').toString()+" - "+moment(this.state.trackingDetails['createdOn']
                                          .toDate()).add(Math.trunc(
                                              this.state.trackingDetails[
                                                'restEstimateTime'
                                              ]
                                                ? this.state.trackingDetails[
                                                    'restEstimateTime'
                                                  ]
                                                : 30
                                            ) +
                                            6, 'minutes').format('HH:mm').toString()}
                                        {/* {this.state.trackingDetails['createdOn']
                                          .toDate()
                                          .getHours()
                                          .toString() +
                                          ':' +
                                          (
                                            this.state.trackingDetails[
                                              'createdOn'
                                            ]
                                              .toDate()
                                              .getMinutes() +
                                            Math.trunc(
                                              this.state.trackingDetails[
                                                'restEstimateTime'
                                              ]
                                                ? this.state.trackingDetails[
                                                    'restEstimateTime'
                                                  ]
                                                : 30
                                            ) -
                                            6
                                          ).toString() +
                                          ' - ' +
                                          this.state.trackingDetails[
                                            'createdOn'
                                          ]
                                            .toDate()
                                            .getHours()
                                            .toString() +
                                          ':' +
                                          (
                                            this.state.trackingDetails[
                                              'createdOn'
                                            ]
                                              .toDate()
                                              .getMinutes() +
                                            Math.trunc(
                                              this.state.trackingDetails[
                                                'restEstimateTime'
                                              ]
                                                ? this.state.trackingDetails[
                                                    'restEstimateTime'
                                                  ]
                                                : 30
                                            ) +
                                            6
                                          ).toString()} */}
                                      </Typography>
                                    </Stack>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: '10px 20px 10px',
                          marginTop: '-38px',
                          zIndex: '9',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#fff',
                            boxShadow: '0px 4px 10px #64717a42',
                            borderRadius: '20px',
                            padding: '12px 16px 12px 16px',
                          }}
                        >
                          <Stack
                            direction={'column'}
                            spacing={0}
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '50%',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '2px',
                                      marginTop: '3px',
                                    }}
                                  >
                                    <BusinessSvg
                                      viewBox="-20 -40 550 550"
                                      style={{
                                        border: `2px solid #4dd884`,
                                        borderRadius: '50%',
                                        width: 'calc(100%)',
                                        padding: '6px',
                                        fontSize: '50px',
                                      }}
                                    />
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['acceptedByRest'] ||
                        this.state.trackingDetails['delivered'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </> */}
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor: constants.primaryColor,
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'acceptedByRest'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border: this.state.trackingDetails[
                                        'acceptedByRest'
                                      ]
                                        ? `0px solid ${constants.primaryColor}`
                                        : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    <StoreMallDirectoryIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails[
                                          'acceptedByRest'
                                        ]
                                          ? constants.primaryColor
                                          : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails[
                                          'acceptedByRest'
                                        ]
                                          ? constants.primaryColor
                                          : '#C4C1C0',
                                      }}
                                    />
                                    {/* <img
                    src={this.state.trackingDetails['restLogo']}
                    alt="logo"
                    // width={'50px'}
                    style={{ borderRadius: '50%', width: "calc(100%)" }}
                  /> */}
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['driverReached'] ||
                        this.state.trackingDetails['delivered'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </> */}
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'driverAssigned'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'driverReached'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border: this.state.trackingDetails[
                                        'driverReached'
                                      ]
                                        ? `0px solid ${constants.primaryColor}`
                                        : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '5.5px',
                                    }}
                                  >
                                    <DeliveryDiningIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails[
                                          'driverReached'
                                        ]
                                          ? constants.primaryColor
                                          : '#C4C1C0',
                                        padding: '6px',
                                        color: this.state.trackingDetails[
                                          'driverReached'
                                        ]
                                          ? constants.primaryColor
                                          : '#C4C1C0',
                                      }}
                                    />
                                    {/* <img
                    src={this.state.trackingDetails['restLogo']}
                    alt="logo"
                    // width={'50px'}
                    style={{ borderRadius: '50%', width: "calc(100%)" }}
                  /> */}
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['delivered'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </> */}

                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '60px 0 0 60px',
                                        backgroundColor:
                                          this.state.trackingDetails[
                                            'orderPickedUp'
                                          ] ||
                                          this.state.trackingDetails[
                                            'delivered'
                                          ]
                                            ? constants.primaryColor
                                            : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {
                                  <div
                                    style={{
                                      width: 'calc(100% / 16 - 5px)',
                                      height: '6px',
                                    }}
                                  >
                                    <Divider
                                      style={{
                                        borderBottomWidth: '4px',
                                        borderRadius: '0 60px 60px 0',
                                        backgroundColor: this.state
                                          .trackingDetails['delivered']
                                          ? constants.primaryColor
                                          : '#C4C1C0',
                                      }}
                                    />
                                  </div>
                                }
                              </>
                              <>
                                {true ? (
                                  <AspectRatio
                                    ratio="1/1"
                                    style={{
                                      borderRadius: '80px',
                                      border:
                                        this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails['cancelled']
                                          ? `0px solid ${constants.primaryColor}`
                                          : this.state.trackingDetails[
                                              'delivered'
                                            ] &&
                                            this.state.trackingDetails[
                                              'cancelled'
                                            ]
                                          ? '0px solid #D62B2B'
                                          : '0px solid #C4C1C0',
                                      width: 'calc(100% / 8 + 7.5px)',
                                      maxWidth: 'calc(100% / 8 + 7.5px)',
                                      padding: '2px',
                                    }}
                                  >
                                    {/* <HomeIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor: this.state.trackingDetails['delivered'] && !this.state.trackingDetails['cancelled']  ? constants.primaryColor  : this.state.trackingDetails['delivered'] &&  this.state.trackingDetails['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                        color: this.state.trackingDetails['delivered'] &&  !this.state.trackingDetails['cancelled']  ? constants.primaryColor  : this.state.trackingDetails['delivered'] && this.state.trackingDetails['cancelled']  ? '#D62B2B'  : '#C4C1C0',
                                        padding: '6px',
                                      }}
                                    /> */}
                                    <WhereToVoteIcon
                                      style={{
                                        fontSize: '40px',
                                        border: '2px solid',
                                        borderRadius: '60px',
                                        borderColor:
                                          this.state.trackingDetails[
                                            'delivered'
                                          ] &&
                                          !this.state.trackingDetails[
                                            'cancelled'
                                          ]
                                            ? constants.primaryColor
                                            : this.state.trackingDetails[
                                                'delivered'
                                              ] &&
                                              this.state.trackingDetails[
                                                'cancelled'
                                              ]
                                            ? '#D62B2B'
                                            : '#C4C1C0',
                                        color:
                                          this.state.trackingDetails[
                                            'delivered'
                                          ] &&
                                          !this.state.trackingDetails[
                                            'cancelled'
                                          ]
                                            ? constants.primaryColor
                                            : this.state.trackingDetails[
                                                'delivered'
                                              ] &&
                                              this.state.trackingDetails[
                                                'cancelled'
                                              ]
                                            ? '#D62B2B'
                                            : '#C4C1C0',
                                        padding: '6px',
                                      }}
                                    />

                                    {/* <img
                    src={this.state.trackingDetails['restLogo']}
                    alt="logo"
                    // width={'50px'}
                    style={{ borderRadius: '50%', width: "calc(100%)" }}
                  /> */}
                                  </AspectRatio>
                                ) : null}
                              </>
                              {/* <>
                      {
                        (this.state.trackingDetails['delivered'] && !this.state.trackingDetails['cancelled'])?<CheckCircleIcon style={{color: "#4dd884", fontSize: "28px"}} />:null
                      }
                      </>
                      <>
                      {
                        (this.state.trackingDetails['delivered'] && this.state.trackingDetails['cancelled'])?<CancelIcon style={{color: "#D62B2B", fontSize: "28px"}} />:null
                      }
                      </> */}
                              {/* <>{
      <div style={{width: "calc(100% / 16 - 5px)",height: "6px"}}>
        <Divider style={{borderBottomWidth: "6px", color: (this.state.trackingDetails['orderPickedUp'] || this.state.trackingDetails['delivered'])?"#4DD884":"#C4C1C0"}} />
      </div>
    }
    </>
    <>{
      <div style={{width: "calc(100% / 16 - 5px)",height: "6px"}}>
        <Divider style={{borderBottomWidth: "6px", color: (this.state.trackingDetails['delivered'])
                                          ?"#4DD884":"#C4C1C0"}} />
      </div>
    }
    </> */}
                            </Stack>
                            <div style={{ padding: '15px 0px 0px' }}></div>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#2e3436',
                                textAlign: 'center',
                              }}
                            >
                              {getStat(this.state.trackingDetails)}
                            </Typography>
                            <div style={{ padding: '15px 0px 0px' }}></div>
                            <Stack
                              onClick={() => {
                                this.setState({
                                  toggleDetails: !this.state.toggleDetails,
                                });
                              }}
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                            {!this.state.toggleDetails ? (
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  color: '#7E7D81',
                                  cursor: 'pointer',
                                }}
                              >
                                {strings['Ver más']}
                              </Typography>
                              ) : (
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                  color: '#7E7D81',
                                  cursor: 'pointer',
                                }}
                              >
                                {strings['Ver menos']}
                              </Typography>
                              )}
                              <div style={{ padding: '0px 2px 0px' }}></div>
                              <>
                                {!this.state.toggleDetails ? (
                                  <KeyboardArrowDownIcon
                                    style={{
                                      fontSize: '24px',
                                      fontWeight: 'normal',
                                      color: '#7E7D81',
                                      cursor: 'pointer',
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    style={{
                                      fontSize: '24px',
                                      fontWeight: 'normal',
                                      color: '#7E7D81',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </>
                            </Stack>
                            <div style={{ padding: '5px 0px 0px' }}></div>
                            <>
                              {this.state.toggleDetails ? (
                                <div>
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['Esperando a que el negocio acepte su pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['Te avisaremos una vez que el negocio acepte tu pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {
                                              strings['El negocio está preparando tu pedido']
                                            }
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido está siendo preparado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'acceptedByRest'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' está en camino para recoger tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' está en camino para recoger tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverAssigned'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' llegó al negocio']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha llegado al negocio y recogerá tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'driverReached'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha recogido tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'driverName'
                                            ].split(' ')[0] +
                                              strings[' ha recogido tu pedido']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'orderPickedUp'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido entregado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '400',
                                              color: 'slategray',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido entregado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'delivered'
                                        ] &&
                                        !this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Divider
                                            style={{
                                              borderBottomWidth: '1px',
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu pedido ha sido cancelado']}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <Typography
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: '400',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {this.state.trackingDetails[
                                              'reason'
                                            ] == null
                                              ? strings['Tu pedido ha sido cancelado']
                                              : this.state.trackingDetails[
                                                  'reason'
                                                ]}
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {this.state.trackingDetails[
                                          'cancelled'
                                        ] ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Divider
                                            style={{
                                              backgroundColor: '#BDBDBD',
                                            }}
                                          />
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '600',
                                              color: '#3a4247',
                                              marginRight: 'auto',
                                            }}
                                          >
                                            {strings['Tu ubicación']}:{' '}
                                            <span
                                              style={{
                                                color: 'slategray',
                                                fontWeight: '400',
                                              }}
                                            >
                                              {
                                                this.state.trackingDetails[
                                                  'userLocation'
                                                ]['geoAddress']
                                              }
                                            </span>
                                          </Typography>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                      <>
                                        {true ? (
                                          <div
                                            style={{ padding: '5px 0px 0px' }}
                                          ></div>
                                        ) : null}
                                      </>
                                    </Stack>
                                  </Stack>
                                </div>
                              ) : null}
                            </>
                          </Stack>
                        </div>
                      </div>
                      <>
                        {!this.state.trackingDetails['delivered'] ? (
                          <div
                            style={{
                              padding: '5px 10px 5px',
                              borderTop: '1px solid #d8d8d8',
                              marginTop: '10px',
                            }}
                          >
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="center"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <div
                                    style={{
                                      borderRadius: '10px',
                                      width: 'calc(15%)',
                                      paddingTop: '10px',
                                    }}
                                  >
                                    <div style={{ padding: '1.75px' }}>
                                      <img
                                        src={
                                          this.state.trackingDetails['restLogo']
                                        }
                                        alt="logo"
                                        // width={'50px'}
                                        style={{
                                          borderRadius: '12px',
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: '5px 15px 0px',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['restName']}
                                      </Typography>
                                      {/* <Stack
                                        direction={'row'}
                                        spacing={0}
                                        alignItems="center"
                                      >
                                        <StarIcon
                                          style={{
                                            fontSize: '16px',
                                            color: constants.primaryColor,
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {' ' +
                                            this.state.businessData[
                                              'averageRating'
                                            ].toFixed(2)}
                                        </Typography>
                                      </Stack> */}
                                    </Stack>
                                  </div>
                                </Stack>
                                <div style={{ padding: '0px 5px 0px' }}>
                                  <div
                                    onClick={() => {
                                      window.open(
                                        'tel://' +
                                          this.state.trackingDetails[
                                            'restContact'
                                          ],
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      width: '38px',
                                      height: '38px',
                                      backgroundColor: '#33343b',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      padding: '4px 6px 4px 8px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <LocalPhoneIcon
                                      style={{
                                        fontSize: '23px',
                                        color: '#fff',
                                      }}
                                    />
                                  </div>
                                </div>
                              </Stack>
                            </Stack>
                          </div>
                        ) : null}
                      </>

                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <Divider />
                      <>
                        {this.state.trackingDetails['driverAssigned'] &&
                        !this.state.trackingDetails['delivered'] ? (
                          <div style={{ padding: '5px 10px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="center"
                            >
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <div
                                    style={{
                                      backgroundColor: '#f8f9fb',
                                      boxShadow: '0px 0px 5px #aaa',
                                      borderRadius: '100px',
                                      width: 'calc(12%)',
                                    }}
                                  >
                                    <div style={{ padding: '1.75px' }}>
                                      <img
                                        src={
                                          this.state.trackingDetails[
                                            'driverAvatar'
                                          ]
                                        }
                                        alt="logo"
                                        // width={'50px'}
                                        style={{
                                          borderRadius: '100px',
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: '5px 15px 0px',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '18px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {
                                          this.state.trackingDetails[
                                            'driverName'
                                          ]
                                        }
                                      </Typography>
                                      <Stack
                                        direction={'row'}
                                        spacing={0}
                                        alignItems="center"
                                      >
                                        <StarIcon
                                          style={{
                                            fontSize: '18px',
                                            color: constants.primaryColor,
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {' ' +
                                          this.state.trackingDetails[
                                            'driverRating'
                                          ]
                                            ? this.state.trackingDetails[
                                                'driverRating'
                                              ].toFixed(2)
                                            : ''}
                                        </Typography>
                                        <div
                                          style={{ padding: '0px 7.5px 0px' }}
                                        ></div>
                                        <Typography
                                          style={{
                                            fontSize: '15px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                          }}
                                        >
                                          {strings['Nivel: '] +
                                          this.state.trackingDetails[
                                            'driverLevel'
                                          ]
                                            ? this.state.trackingDetails[
                                                'driverLevel'
                                              ].toString()
                                            : '1'}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Stack>
                                <div style={{ padding: '0px 5px 0px' }}>
                                  <div
                                    onClick={() => {
                                      window.open(
                                        'tel://' +
                                          this.state.trackingDetails[
                                            'driverContact'
                                          ],
                                        '_blank'
                                      );
                                    }}
                                    style={{
                                      width: '45px',
                                      height: '45px',
                                      backgroundColor: constants.secondaryColor,
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      textAlign: 'center',
                                      padding: '4px 6px 4px',
                                    }}
                                  >
                                    <LocalPhoneIcon
                                      style={{
                                        fontSize: '27px',
                                        color: '#fff',
                                      }}
                                    />
                                  </div>
                                </div>
                              </Stack>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <div style={{ padding: '15px 0px 0px' }}></div>
                      <Divider />
                      <>
                        {' '}
                        {this.state.trackingDetails != null &&
                        (this.state.trackingDetails['isPickup'] == null ||
                          !this.state.trackingDetails['isPickup']) ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Dirección de entrega']}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                }}
                              >
                                {
                                  this.state.trackingDetails['userLocation'][
                                    'geoAddress'
                                  ]
                                }
                              </Typography>
                              <>
                                {this.state.trackingDetails['userLocation'][
                                  'addressNotes'
                                ] != null &&
                                this.state.trackingDetails['userLocation'][
                                  'addressNotes'
                                ] !== '' ? (
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#6A6A6E',
                                    }}
                                  >
                                    {
                                      this.state.trackingDetails[
                                        'userLocation'
                                      ]['addressNotes']
                                    }
                                  </Typography>
                                ) : null}
                              </>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <>
                        {' '}
                        {this.state.trackingDetails != null &&
                        this.state.trackingDetails['isPickup'] != null &&
                        this.state.trackingDetails['isPickup'] ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Para recoger tu pedido debes dirigirte a:']}
                              </Typography>
                              <Typography
                                onClick={() => {
                                  window.open(
                                    'https://www.google.com/maps/search/?api=1&query=' +
                                      this.state.trackingDetails.restLocation.latitude.toString() +
                                      '%2C' +
                                      this.state.trackingDetails.restLocation.longitude.toString(),
                                    '_blank'
                                  );
                                }}
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                  cursor: 'pointer',
                                }}
                              >
                                {
                                  this.state.trackingDetails['restLocation'][
                                    'address'
                                  ]
                                }
                              </Typography>
                              {/* <>
                  {
                    (this.state.trackingDetails['userLocation']
                                                  ['addressNotes'] !=
                                              null &&
                                              this.state.trackingDetails['userLocation']
                                                  ['addressNotes'] !==
                                              '')? <Typography
                    style={{
                      fontSize: '15px',
                      fontWeight: 'normal',
                      color: '#6A6A6E',
                    }}
                  >

{this.state.trackingDetails['userLocation']['addressNotes']}
                  </Typography>:null
                  }
                  </> */}
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Método de pago']}
                          </Typography>
                          <>
                            {!this.state.trackingDetails['onlinePayment'] ? (
                              <Stack direction={'row'} spacing={0}>
                                <>
                                  {this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] != null &&
                                  this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] ? (
                                    <CreditCardIcon
                                      style={{
                                        fontWeight: 'normal',
                                        color: '#c96812',
                                      }}
                                    />
                                  ) : (
                                    <MoneyIcon
                                      style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        color: '#54bd66',
                                        marginTop: '2px',
                                      }}
                                    />
                                  )}
                                </>
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <>
                                  {this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] != null &&
                                  this.state.trackingDetails[
                                    'cardOnDelivery'
                                  ] ? (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Tarjeta contra entrega']}
                                      </Typography>
                                    </>
                                  ) : (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Efectivo']}
                                      </Typography>
                                    </>
                                  )}
                                </>
                                <div style={{ padding: '0px 5.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: '#6A6A6E',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {this.state.trackingDetails['onlinePayment'] ? (
                              <Stack direction={'row'} spacing={0}>
                                <CreditCardIcon
                                  style={{
                                    fontWeight: 'normal',
                                    color: '#2c59a8',
                                  }}
                                />
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Tarjeta']}
                                </Typography>
                                <div style={{ padding: '0px 2.5px 0px' }}></div>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: '#6A6A6E',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                        </Stack>
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Día y hora de su pedido']}
                          </Typography>
                          <Stack
                            direction={'row'}
                            spacing={0}
                            alignItems="center"
                          >
                            <AccessTimeFilledIcon
                              style={{
                                fontSize: '20px',
                                fontWeight: 'normal',
                                color: '#4b596a',
                              }}
                            />{' '}
                            <div style={{ padding: '0px 2.5px 0px' }}></div>
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#000',
                              }}
                            >
                              {moment(this.state.trackingDetails['createdOn']
                                .toDate()).add((this.state.trackingDetails['preOrderedOn']?40:0),'minutes').format('DD-MM-YYYY hh:mm a').toString()}
                              {/* {this.state.trackingDetails['createdOn']
                                .toDate()
                                .getDate()
                                .toString() +
                                '-' +
                                (
                                  this.state.trackingDetails['createdOn']
                                    .toDate()
                                    .getMonth() + 1
                                ).toString() +
                                '-' +
                                this.state.trackingDetails['createdOn']
                                  .toDate()
                                  .getFullYear()
                                  .toString() +
                                ' ' +
                                this.state.trackingDetails['createdOn']
                                  .toDate()
                                  .getHours()
                                  .toString() +
                                ':' +
                                this.state.trackingDetails['createdOn']
                                  .toDate()
                                  .getMinutes()
                                  .toString()} */}
                            </Typography>
                          </Stack>
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        </Stack>
                      </div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '5px 0px 0px' }}></div>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '5px 20px 5px' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#6A6A6E',
                                }}
                              >
                                {strings['Enviar cubiertos']}
                              </Typography>
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                              >
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {this.state.trackingDetails['giveSpoon'] !=
                                    null &&
                                  this.state.trackingDetails['giveSpoon']
                                    ? strings['Sí']
                                    : strings['No']}
                                </Typography>
                              </Stack>
                              <div style={{ padding: '5px 0px 0px' }}></div>
                            </Stack>
                          </div>
                        ) : null}
                      </>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '0px 20px 0px' }}>
                            <Divider />
                          </div>
                        ) : null}
                      </>
                      <>
                        {this.state.trackingDetails['showSpoonOption'] !=
                          null &&
                        this.state.trackingDetails['showSpoonOption'] ? (
                          <div style={{ padding: '5px 0px 0px' }}></div>
                        ) : null}
                      </>

                      <div style={{ padding: '5px 20px 5px', width: '100%' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#6A6A6E',
                            }}
                          >
                            {strings['Detalles del pedido:']}
                          </Typography>
                          <List style={{ width: '100%' }}>
                            {this.state.trackingDetails['items'].map(
                              (customize, index) => (
                                <div
                                  style={{
                                    padding: '5px 0px 0px',
                                    width: '100%',
                                  }}
                                  key={index}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {this.state.trackingDetails['items'][
                                          index
                                        ]['quantity'].toString() +
                                          ' X ' +
                                          (this.state.trackingDetails['items'][
                                            index
                                          ]['nameEng'] != null
                                            ? this.state.trackingDetails[
                                                'items'
                                              ][index]['nameEng']
                                            : this.state.trackingDetails[
                                                'items'
                                              ][index]['name'])}
                                      </Typography>
                                      <div style={{ padding: '3px 0px 0px' }}>
                                        <List>
                                          {this.state.trackingDetails['items'][
                                            index
                                          ]['customize'].map((customize, t) => (
                                            <React.Fragment key={t}>
                                              {this.state.trackingDetails[
                                                'items'
                                              ][index]['customize'][t][
                                                'options'
                                              ].length > 0 ? (
                                                <Typography
                                                  style={{
                                                    fontSize: '13px',
                                                    fontWeight: 'normal',
                                                    color: 'slategray',
                                                  }}
                                                >
                                                  {findTotalT(
                                                    index,
                                                    t,
                                                    this.state.trackingDetails
                                                  )}
                                                </Typography>
                                              ) : null}
                                            </React.Fragment>
                                          ))}
                                        </List>
                                      </div>
                                    </Stack>
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-end"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {'$' +
                                          Number(
                                            this.state.trackingDetails['items'][
                                              index
                                            ]['price'] *
                                              this.state.trackingDetails[
                                                'items'
                                              ][index]['quantity']
                                          )
                                            .toFixed(2)
                                            .toString()}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </div>
                              )
                            )}
                            {(this.state.trackingDetails['removedItems']==null && this.state.trackingDetails['selectedOffer']!=null && this.state.trackingDetails['selectedOffer']['id']!=null && this.state.trackingDetails['selectedOffer']['isFreeItem']!=null && this.state.trackingDetails['selectedOffer']['isFreeItem'])?
                            <div
                                  style={{
                                    padding: '5px 0px 0px',
                                    width: '100%',
                                  }}
                                  key={this.state.trackingDetails['items'].length}
                                >
                                  <Stack
                                    direction={'row'}
                                    spacing={0}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'bold',
                                          color: '#000',
                                        }}
                                      >
                                        {'1 X ' +
                                          (this.state.trackingDetails['selectedOffer']['freeItems'][0]
                                                      ['name'])}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={'column'}
                                      spacing={0}
                                      alignItems="flex-end"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 'normal',
                                          color: '#000',
                                        }}
                                      >
                                        {strings['Gratis']}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </div>:null}
                          </List>
                          <div style={{ padding: '10px 0px 0px' }}></div>
                          {/* <div style={{padding: "0px 20px 0px"}}>
                    <Divider />
                   </div> */}
                        </Stack>
                      </div>
                      <div style={{ padding: '0px 20px 0px' }}>
                        <Divider />
                      </div>
                      <div style={{ padding: '10px 0px 0px' }}></div>
                      <div style={{ padding: '5px 20px 5px', width: '100%' }}>
                        <Stack
                          direction={'column'}
                          spacing={0}
                          alignItems="flex-start"
                        >
                          <div style={{ width: '100%' }}>
                            <Stack
                              direction={'column'}
                              spacing={0}
                              alignItems="flex-start"
                            >
                              <div
                                style={{
                                  padding: '0px 0px 5px',
                                  width: '100%',
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#33343a',
                                    }}
                                  >
                                    {strings['Total parcial']}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: 'normal',
                                      color: '#33343a',
                                    }}
                                  >
                                    {'$' +
                                      this.state.trackingDetails[
                                        'itemCost'
                                      ].toFixed(2)}
                                  </Typography>
                                </Stack>
                              </div>
                              <>
                                {this.state.trackingDetails['driverService'] !=
                                  null &&
                                this.state.trackingDetails['driverService'] >
                                  0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Tarifa de servicio']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          this.state.trackingDetails[
                                            'driverService'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['deliveryCharge'] !=
                                  null &&
                                this.state.trackingDetails['deliveryCharge'] >
                                  0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Costo de envío']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          (
                                            this.state.trackingDetails[
                                              'deliveryCharge'
                                            ] +
                                            (this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] != null &&
                                            this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] > 0
                                              ? this.state.trackingDetails[
                                                  'changeAddressExtraCost'
                                                ] + 0.0
                                              : 0.0)
                                          ).toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['tip'] != null &&
                                this.state.trackingDetails['tip'] > 0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Propina']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'$' +
                                          this.state.trackingDetails[
                                            'tip'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                              <>
                                {this.state.trackingDetails['offerValue'] !=
                                  null &&
                                this.state.trackingDetails['offerValue'] > 0 ? (
                                  <div
                                    style={{
                                      padding: '0px 0px 5px',
                                      width: '100%',
                                    }}
                                  >
                                    <Stack
                                      direction={'row'}
                                      spacing={0}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {strings['Descuento']}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          color: '#33343a',
                                        }}
                                      >
                                        {'-$' +
                                          this.state.trackingDetails[
                                            'offerValue'
                                          ].toFixed(2)}
                                      </Typography>
                                    </Stack>
                                  </div>
                                ) : null}
                              </>
                            </Stack>
                          </div>
                          <>
                            {this.state.trackingDetails['onlinePayment'] &&
                            ((this.state.trackingDetails['isOxxoEnabled'] !=
                              null &&
                              this.state.trackingDetails['isOxxoEnabled']) ||
                              (this.state.trackingDetails[
                                'changeAddressExtraCost'
                              ] != null &&
                                this.state.trackingDetails[
                                  'changeAddressExtraCost'
                                ] > 0)) ? (
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Pagado con '] +
                                    (this.state.trackingDetails[
                                      'paypalPayment'
                                    ] != null &&
                                    this.state.trackingDetails['paypalPayment']
                                      ? 'paypal'
                                      : strings['tarjeta'])}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {'$' +
                                    this.state.trackingDetails[
                                      'grandTotalWithoutOxxo'
                                    ].toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {this.state.trackingDetails['onlinePayment'] &&
                            ((this.state.trackingDetails['isOxxoEnabled'] !=
                              null &&
                              this.state.trackingDetails['isOxxoEnabled']) ||
                              (this.state.trackingDetails[
                                'changeAddressExtraCost'
                              ] != null &&
                                this.state.trackingDetails[
                                  'changeAddressExtraCost'
                                ] > 0)) ? (
                              <Stack
                                direction={'row'}
                                spacing={0}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {strings['Pagar en efectivo']}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {'$' +
                                    (
                                      this.state.trackingDetails['grandTotal'] -
                                      this.state.trackingDetails[
                                        'grandTotalWithoutOxxo'
                                      ] +
                                      (this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] != null &&
                                      this.state.trackingDetails[
                                        'changeAddressExtraCost'
                                      ] > 0
                                        ? this.state.trackingDetails[
                                            'changeAddressExtraCost'
                                          ] + 0.0
                                        : 0.0)
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            ) : null}
                          </>
                          <>
                            {true ? (
                              <div style={{ width: '100%' }}>
                                <Stack
                                  direction={'row'}
                                  spacing={0}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold',
                                      color: '#2c3136',
                                      marginTop: '20px',
                                    }}
                                  >
                                    {strings['Total']}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: 'bold',
                                      color: '#2c3136',
                                      marginTop: '20px',
                                    }}
                                  >
                                    {'$' +
                                      (
                                        this.state.trackingDetails[
                                          'grandTotal'
                                        ] +
                                        (this.state.trackingDetails[
                                          'changeAddressExtraCost'
                                        ] != null &&
                                        this.state.trackingDetails[
                                          'changeAddressExtraCost'
                                        ] > 0
                                          ? this.state.trackingDetails[
                                              'changeAddressExtraCost'
                                            ] + 0.0
                                          : 0.0)
                                      ).toFixed(2)}
                                  </Typography>
                                </Stack>
                              </div>
                            ) : null}
                          </>
                        </Stack>
                      </div>
                      <>
                        {!this.state.trackingDetails.acceptedByRest ? (
                          <div
                            onClick={async () => {
                              this.setState({ loading: true });
                              await axios
                                .post(
                                  'https://senor-order-85fa55d895ba.herokuapp.com/drivers/cancelorder/',
                                  null,
                                  {
                                    params: {
                                      id: this.state.trackingId,
                                    },
                                  }
                                )
                                .then(async (response) => {
                                  this.setState({ loading: false });
                                  handleAlertCancelOpen();
                                  // console.log(response);
                                })
                                .catch((e) => {
                                  this.setState({ loading: false });
                                });
                            }}
                            className="hovercancelorder"
                            style={{
                              marginTop: '35px',
                              alignSelf: 'center',
                              padding: '5px 14px 0px 14px',
                              borderRadius: '9px',
                              color: '#D62B2B',
                              cursor: 'pointer',
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                              }}
                            >
                              {strings['Cancelar pedido']}
                            </Typography>
                            <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '5px', }}>
                              <img src = {require('./assets/sad_face2.png')} style={{ height: '36px',width: 'auto', }}/>
                            </div>
                          </div>
                        ) : null}
                      </>
                      <div
                        style={{
                          padding: isIOS ? '120px 0px 0px' : '65px 0px 0px',
                        }}
                      ></div>
                    </Stack>
                  ) : null}
                </>
                {/* </div> */}
              </Stack>
            </Container>
          }
        ></TrackDialog>

    <Backdrop
          onClick={() => {}}
          style={{ color: '#fff', zIndex: 9999999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <Snackbar
        style={{zIndex: 9999999}}
          open={this.state.openAlert}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }

            this.setState({ openAlert: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }

              this.setState({ openAlert: false });
            }}
            severity={this.state.alertType}
            sx={{ width: '100%' }}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
    <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="fixed"
            color="transparent"
            elevation={1}
            style={{
              border: '0px solid #e2e8f0',
              boxShadow: 'none',
              background: 'white',
              height: '60px',
            }}
          >
            <Toolbar>
            <Box
              textAlign="center"
              height={'auto'}
              // style={{ borderRadius: '50%' }}
            >
              <img
                onClick={()=>{window.open('/',"_self");}}
                src={mainLogo2}
                alt="logo"
                style={{ height: '40px', verticalAlign: 'middle',cursor: "pointer", borderRadius: '8px', boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px', }}
              />
            </Box>

              <Box sx={{ flexGrow: 1 }} />

              <Box
                sx={{ display: { xs: 'flex', md: 'flex' } }}
                style={{
                  margin: '1px',
                  borderLeft: '0px solid #cccccc',
                  color: constants.primaryColor,
                }}
              >



                <IconButton
                  style={{ background: 'white',
                    boxShadow: 'rgb(149 157 165 / 20%) 0px 8px 24px',
                    width: '40px',
                    height: '40px',
                    marginRight: '0px',
                    marginTop: this.state.width > 1000 ? '0' : '0',
                  }}
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >

                    <AccountCircle />

                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMenu}
        </Box>
        <WalletDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openWallet}
          onClose={handleWalletClose}
          displayList={
            <DialogContent dividers>
                <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              >
                <div style={{paddingLeft: "5px", marginBottom: "10px"}}
                >
                  <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Disponible:"]+"$"+this.state.balance.toFixed(2)}
                                </Typography>
                </div>
              </Stack>
            </DialogContent>
          }
        />
        <PreOrderDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openPreOrder}
          onClose={handlePreOrderClose}
          displayList={
            <DialogContent dividers style={{border: '0',}}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
              >
                <div style={{padding: "0px 15px 20px"}}>
                  <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '400',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado"]}
                                </Typography>
                                </div>
                                <div style={{width: "100%", padding: "0px 0px 20px"}}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-evenly"
                                  alignItems="center"
                                  spacing={0}
                                >
                                <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
                                        <Select
                                          value={this.state.currenciesList2Selected}
                                          onChange={(event) => {
                                            // console.log(event.target.value,this.state.currenciesList2Selected, (typeof event.target.value));
                                    if(event.target.value !== -1) {
                                      var showTomorrow = this.state.showTomorrow;
                                      var show3Day = this.state.show3Day;
                                      var show4Day = this.state.show4Day;
                                      var show5Day = this.state.show5Day;
                                      var show6Day = this.state.show6Day;
                                      var show7Day = this.state.show7Day;
                                      if (this.state.currenciesList2[event.target.value]['id'] === 1) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 2) {
                                        showTomorrow = true;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 3) {
                                        showTomorrow = false;
                                        show3Day = true;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 4) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = true;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 5) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = true;
                                        show6Day = false;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 6) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = true;
                                        show7Day = false;
                                      } else if(this.state.currenciesList2[event.target.value]['id'] === 7) {
                                        showTomorrow = false;
                                        show3Day = false;
                                        show4Day = false;
                                        show5Day = false;
                                        show6Day = false;
                                        show7Day = true;
                                      }
                                      this.setState({
                                        showTomorrow:showTomorrow,
                                        show3Day:show3Day,
                                        show4Day:show4Day,
                                        show5Day:show5Day,
                                        show6Day:show6Day,
                                        show7Day:show7Day,
                                        selectedPreOrder: (showTomorrow)?(this.state.currenciesList3[this.state.currenciesList3Selected]?this.state.currenciesList3[this.state.currenciesList3Selected]['date']:null):
                                        (show3Day)?(this.state.currenciesList4[this.state.currenciesList4Selected]?this.state.currenciesList4[this.state.currenciesList4Selected]['date']:null):
                                        (show4Day)?(this.state.currenciesList5[this.state.currenciesList5Selected]?this.state.currenciesList5[this.state.currenciesList5Selected]['date']:null):
                                        (show5Day)?(this.state.currenciesList6[this.state.currenciesList6Selected]?this.state.currenciesList6[this.state.currenciesList6Selected]['date']:null):
                                        (show7Day)?(this.state.currenciesList7[this.state.currenciesList7Selected]?this.state.currenciesList7[this.state.currenciesList7Selected]['date']:null):
                                        (show7Day)?(this.state.currenciesList8[this.state.currenciesList8Selected]?this.state.currenciesList8[this.state.currenciesList8Selected]['date']:null):
                                        (this.state.currenciesList[this.state.currenciesListSelected]?this.state.currenciesList[this.state.currenciesListSelected]['date']:null)
                                      });
                                    }
                                    this.setState({
                                      currenciesList2Selected:event.target.value
                                                                      });
                                  }}
                                          displayEmpty
                                          inputProps={{ 'aria-label': '' }}
                                        >

                                {this.state.currenciesList2.map((name2, index) => (
                                   <MenuItem value={index}>
                                    <Stack
                                  direction="column"
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  spacing={0}
                                >
                                <div style={{padding: "1.5px 0px 1.5px"}}></div>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                    color: 'slategray',
                                  }}
                                >
                                  {name2['title']}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: '19.5px',
                                    fontWeight: '600',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  { moment(name2['date'])
                                .format('DD/MM/YYYY')}
                                </Typography>
</Stack>
   </MenuItem>
))}
        </Select>
      </FormControl>
      <>{this.state.currenciesList4.length == 0 && this.state.show3Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show3Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show3Day?this.state.currenciesList4Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show3Day = this.state.show3Day;
      if (this.state.show3Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList4[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show3Day) {
    this.setState({
      currenciesList4Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList4.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList5.length == 0 && this.state.show4Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show4Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show4Day?this.state.currenciesList5Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show4Day = this.state.show4Day;
      if (this.state.show4Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList5[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show4Day) {
    this.setState({
      currenciesList5Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList5.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList6.length == 0 && this.state.show5Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show5Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show5Day?this.state.currenciesList6Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show5Day = this.state.show5Day;
      if (this.state.show5Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList6[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show5Day) {
    this.setState({
      currenciesList6Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList6.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList7.length == 0 && this.state.show6Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show6Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show6Day?this.state.currenciesList7Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var show6Day = this.state.show6Day;
      if (this.state.show6Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList7[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show6Day) {
    this.setState({
      currenciesList7Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList7.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList8.length == 0 && this.state.show7Day?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:(this.state.show7Day)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.show7Day?this.state.currenciesList8Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
        console.log(event.target.value);
      var show7Day = this.state.show7Day;
      if (this.state.show7Day) {
        this.setState({
          selectedPreOrder:this.state.currenciesList8[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.show7Day) {
    this.setState({
      currenciesList8Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList8.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
        </FormControl>: this.state.currenciesList.length == 0 && !this.state.showTomorrow ?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>
        : (!this.state.showTomorrow)?
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ borderRadius: '12px', background: 'white', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', }}>
        <Select
          value={this.state.showTomorrow?this.state.currenciesList3Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var showTomorrow = this.state.showTomorrow;
      if (this.state.showTomorrow) {
        this.setState({
          selectedPreOrder:this.state.currenciesList3[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.showTomorrow) {
    this.setState({
      currenciesList3Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>:this.state.currenciesList3.length == 0 ?
          <div style={{ padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: 'rgb(150 158 166 / 40%) 0px 8px 24px', }}>
            <Typography
              style={{
                fontSize: '17.5px',
                fontWeight: '600',
                fontFamily: 'Nunito',
                letterSpacing: 'normal',
                color: '#dd1626',
              }}
            >
              {strings["closed_today"]}
            </Typography>
          </div>:<FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={this.state.showTomorrow?this.state.currenciesList3Selected:this.state.currenciesListSelected}
          onChange={(event) => {
    if(event.target.value !== -1) {
      var showTomorrow = this.state.showTomorrow;
      if (this.state.showTomorrow) {
        this.setState({
          selectedPreOrder:this.state.currenciesList3[event.target.value]['date']
                                      });
                                      } else {
                                        this.setState({
          selectedPreOrder:this.state.currenciesList[event.target.value]['date']
                                      });
                                      }

    }
    if(this.state.showTomorrow) {
    this.setState({
      currenciesList3Selected:event.target.value
                                      });
                                    } else {
                                      this.setState({
                                        currenciesListSelected:event.target.value
                                      });
                                    }
  }}
          displayEmpty
          inputProps={{ 'aria-label': '' }}
        >

                    {this.state.currenciesList3.map((name2, index) => (
                       <MenuItem value={index}>
                    <div style={{padding: "15px 15px 15px"}}>
                    <Typography
                                                      style={{
                                                        fontSize: '17.5px',
                                                        fontWeight: '400',
                                                        fontFamily: 'Nunito',
                                                        letterSpacing: 'normal',
                                                      }}
                                                    >
                                                      {name2['display']}
                                                    </Typography></div>
                       </MenuItem>
                    ))}

          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>}
      </>
</Stack>
</div>
<Button fullWidth variant="contained" style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: constants.primaryColor, }}
              sx={{ backgroundColor: constants.primaryColor }}
onClick={

  async ()=>{

    if(this.state.selectedPreOrder && (this.state.currenciesList2Selected > 0 || (this.state.currenciesList2Selected === 0 && this.state.currenciesList.length>0))) {
    this.setState(
                        { businessData: null },
                        async (newState) => {
                          var weekDay = [6,0,1,2,3,4,5];
    var currentDay = weekDay[this.state.selectedPreOrder.getDay()];
    // const { id } = this.props.match.params;
    var dataToAdd = { selectedDate: this.state.selectedPreOrder, selectedWeekDay: currentDay, isTomorrow: this.state.showTomorrow, isToday: (!this.state.show3Day && !this.state.show4Day && !this.state.show5Day && !this.state.show6Day && !this.state.show7Day) ,isPreOrder: true };
    await localStorage.setItem('preOrder', JSON.stringify(dataToAdd));
    window.dispatchEvent(new Event("preOrder"));
    //console.log('PD1',this.state.selectedPreOrder.toString());
    this.setState({ selectedDate: this.state.selectedPreOrder, selectedWeekDay: currentDay, isTomorrow: this.state.showTomorrow, isToday: (!this.state.show3Day && !this.state.show4Day && !this.state.show5Day && !this.state.show6Day && !this.state.show7Day) ,isPreOrder: true });

                          handlePreOrderClose();
                          // // this.setState({ businessData: {} });
                          // this.getCities(id);
                        }
                      );
    }
  }
}
>{strings["Programar y avanzar"]}</Button>
</Stack>
            </DialogContent>
          }
        />
        <AllergyDialog
          selectedValue={this.state.selectedValue}
          open={this.state.showAllergy}
          onClose={() => {
            this.setState({ showAllergy: false });
          }}
          title={this.state.width}
          displayList={
            <>{ (this.state.showAllergyDisclaimer)?<Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergy"]}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showAllergy: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      textAlign:"center",
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px"
                    }}
                  >
                    {strings["allergyTerms1"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div className = "head-text" style={{width: `${this.state.width > 600 ? 370: this.state.width - 30}px`, height: `${(967 * ((this.state.width > 600 ? 370: this.state.width - 30) - 30)) / 3426}px`}}>
        <div>
          <img
          style={{width: `${this.state.width > 600 ? 385: this.state.width}px`,padding: "0px 15px 0px"}}
          src = {require('./assets/user-allergy.png')} alt = {"Alergia"} />
        </div>
          <div className='text-on-image'
          style={{
            padding: "0px 15px 0px",
            width: `${this.state.width > 600 ? 380: this.state.width - 20}px`,
            height: `${(967 * ((this.state.width > 600 ? 370: this.state.width - 30) - 30)) / 3426}px`,
            paddingLeft: `${(this.state.width > 600 ? 370: this.state.width - 30) * 0.37}px`,
            paddingRight: `${(this.state.width > 600 ? 370: this.state.width - 30) * 0.15}px`,
            top: this.state.width > 600 ? '3%' : '29%',
          }}>
          <Typography
                    sx={{ p: 0 }}
                    style={{
                      fontSize: `${this.state.width > 600 ? 16: (this.state.width * 0.00125  * 30)}px`,
                      fontWeight: '700',
                      color: '#fff',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyBannerTitle"]}
                  </Typography>
                  <Typography
                    sx={{ p: 0 }}
                    style={{
                      fontSize: `${this.state.width > 600 ? 16: (this.state.width * 0.00125  * 30)}px`,
                      fontWeight: '400',
                      color: '#fff',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyBannerSubTitle"]}
                  </Typography>
          </div>
      </div>
      <div style={{paddingTop: "20px"}}></div>
      <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms2"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      color: '#2e2c36',
                      fontWeight: '600',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms3"]}
                  </Typography>
                  </div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms4"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '18px',
                      color: '#2e2c36',
                      fontWeight: '600',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms5"]}
                  </Typography>
                  </div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms6"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px"
                    }}
                  >
                    {strings["allergyTerms7"]}
                  </Typography>
                  <div style={{paddingTop: "8px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms8"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms9"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms10"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms11"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms12"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms13"]}
                  </Typography>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms14"]}
                  </Typography>
                  <div style={{paddingTop: "5px"}}></div>
                  <div style={{borderRadius: '10px', backgroundColor: "#f8f9fb", padding:"5px 5px 5px", margin: "0px 10px 0px"}}>
                  <Stack
                  direction={'column'}
                  spacing={0}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms15"]}
                  </Typography>
                  <div style={{paddingTop: "10px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      fontWeight: '500',
                    }}
                  >
                    {strings["allergyTerms16"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms17"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms18"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms19"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms20"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms21"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms22"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms23"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms24"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms25"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms26"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms27"]}
                  </Typography>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergyTerms28"]}
                  </Typography>
                </Stack>
                  </div>
                  <div style={{paddingTop: "15px"}}></div>
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "0px 15px 0px",
                      fontWeight: '500',
                    }}
                  >
                      {strings["allergyTerms29"]}
                  </Typography>
                  <div style={{paddingTop: "20px"}}></div>
                  <div
                      style={{
                        padding: "0px 15px 0px",
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={() => {
                          this.setState({ showAllergyDisclaimer: false });
                          try {
                          localStorage.setItem('showAllergyDisclaimer', JSON.stringify(false));
                          window.dispatchEvent(new Event("showAllergyDisclaimer"));

                          } catch(e) {}
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: constants.primaryColor,
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%'
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '24px' : '22px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['begin']}
                            </Typography>
                          </Stack>
                          {/* <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </div>
                    </div>
                    <div style={{paddingTop: "20px"}}></div>
                </Stack>
                {/* <>
                  {!this.state.loading &&
                  this.state.cart &&
                  this.state.cart['totalCost'] != null &&
                  this.state.delivery != null &&
                  this.state.defaultCard == null &&
                  this.state.userDetails == null ? (
                    <div
                      style={{
                        position: this.state.width < 600 ? 'fixed' : 'relative',
                        left: '0px',
                        bottom: this.state.width < 600 ? '0' : '80px',
                        padding: '10px 15px 10px 5px',
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #0000001f',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '12%' }}>
                        <DeleteOutlineIcon
                          onClick={handleClearCart}
                          style={{
                            height: '24px',
                            position: 'relative',
                            top: '18px',
                            color: '#6a696e',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          // this.setState({ showAllergy: false });
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          height: '60px',
                          borderRadius: this.state.width < 600 ? '12px' : '8px',
                          textTransform: 'none',
                          backgroundColor: constants.primaryColor,
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          width: '88%',
                          cursor: 'pointer',
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                              }}
                            >
                              {strings['Ingresar']}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    </div>
                  ) : null} </> */}
              </Stack>
            </Container>:
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                margin: '0px',
                zIndex: '9998',
                bgcolor: '#fff',
                height: isIOS ? 'calc((var(--vh, 1vh) * 100))' : '100vh',
                maxWidth: this.state.width > 600 ? '400px' : 'auto',
              }}
            >
              {/* <Container sx={{ bgcolor: '#fff', height: '100vh', width: "100%"}} > */}
              <Stack direction={'column'} spacing={0}>
                {/* {(this.state.width<600)?<div style={{paddingTop: "15px"}}></div>:null} */}
                <Stack direction={'row'} style={{ height: '50px' }}>
                  <Typography
                    sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '24px' : '28px',
                      fontWeight: '800',
                      color: '#2e2c36',
                      lineHeight: '1',
                      letterSpacing: '-0.24px',
                      fontFamily: 'Nunito',
                    }}
                  >
                    {strings["allergy"]}
                  </Typography>
                  <IconButton
                    style={{
                      color: 'slategray',
                    }}
                    aria-label="close"
                    onClick={() => {
                      this.setState({ showAllergy: false });
                    }}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  direction={'column'}
                  spacing={0}
                  // id="cartbackdrop"
                  style={{
                    height: isIOS
                      ? 'calc((var(--vh, 1vh) * 100) - 50px)'
                      : 'calc(100vh - 50px)',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      padding: "15px 15px 15px",
                      color: 'slategray',
                    }}
                  >
                    {strings["allergyText1"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '18px',
                      fontWeight: '800',
                      color: '#2c3136',
                      fontFamily: 'Nunito',
                      letterSpacing: 'normal',
                      padding: '0 15px',
                    }}
                  >
                    {strings['my_allergies_list_tittle']}
                  </Typography>
              {this.state.allergy?.map((allergy, i) => (
                <div style={{padding: "8px"}}>
                  <div style={{borderRadius: "10px", backgroundColor: "#f8f9fb", padding: "0px 8px 0px"}}>
                  <Stack direction={'row'} spacing={0} justifyContent="space-between" alignItems="center">
                  <Typography
                    style={{
                      fontSize: this.state.width > 600 ? '16px' : '20px',
                      width: `${this.state.width > 600 ? 350: this.state.width - 50}px`,
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      overflow: 'auto',
                      overflowWrap:'anywhere',
                      padding: "15px 15px 15px"
                    }}
                  >
                  {allergy}
                  </Typography>
                  <DeleteForeverIcon
                  onClick={
                    async()=>{
                      var temp = this.state.allergy;
                      var index = temp.indexOf(allergy);
                      if(index>=0) {
                        temp.splice(index,1);
                      }
                      // console.log(temp, index);
                            this.setState({
                              allergy: temp
                            });
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              allergy:temp,
            })
                    }
                  }
                                        style={{ color: '#e53837',cursor: 'pointer', }}
                                      />
                  </Stack></div></div>
                      ))}
                  <div style={{paddingTop: "15px"}}></div>
                  <div style={{padding: "15px 15px 15px"}}>
                  <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                // error={
                //   !validator.isEmail(this.state.email) &&
                //   this.state.email.length > 0
                // }
                fullWidth
                id="filled-basicallergyAdd"
                label={strings["allergy_holder"]}
                variant="filled"
                value={this.state.newAllergy}
                onChange={(event) => {
                  this.setState({ newAllergy: event.target.value });
                }}
              /></div>
                                <div style={{paddingTop: "20px"}}></div>
                  <div
                      style={{
                        padding: "0px 15px 0px",
                        zIndex: '9',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={async() => {
                          if(this.state.newAllergy && this.state.newAllergy!=='') {
                            var temp = this.state.allergy;
                            temp.push(this.state.newAllergy);
                            this.setState({
                              newAllergy: '',
                              allergy: temp
                            });
                            try {
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              allergy:temp,
            })
          } catch(e) {
            //console.log(e);
          }
                          }
                          // this.setState({ showAllergy: false });
                          // handleClickOpenLogin();
                        }}
                        variant="contained"
                        style={{
                          borderRadius: this.state.width < 600 ? '12px' : '12px',
                          textTransform: 'none',
                          backgroundColor: constants.primaryColor,
                          padding: '13px 10px 13px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          width: '100%',
                          height: '61px',
                          boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px',
                        }}
                      >
                        <Stack
                          style={{ marginTop: '0px' }}
                          direction={'row'}
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                fontSize: this.state.width < 600 ? '20px' : '18px',
                                fontWeight: '800',
                                color: '#fff',
                                textAlign: 'center',
                                cursor: 'pointer',
                                letterSpacing: 'normal',
                                fontFamily: 'Nunito',
                                marginTop: this.state.width < 600 ? '0' : '3px',
                              }}
                            >
                              {strings['allergyAdd']}
                            </Typography>
                          </Stack>
                          {/* <Stack
                            direction={'column'}
                            spacing={0}
                            justifyContent="center"
                            alignItems="start"
                          >
                            <Stack
                              style={{ marginLeft: '0px' }}
                              direction={'row'}
                              spacing={0}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                {strings["Total"]}:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '22px',
                                  fontWeight: '800',
                                  color: '#fff',
                                  textAlign: 'center',
                                }}
                              >
                                &nbsp;&nbsp;{'$' + grandTotal2().toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </div>
                    </div>
                    <div style={{paddingTop: "20px"}}></div>
                </Stack>
              </Stack>
            </Container>
            }</>
          }
        ></AllergyDialog>
                        <SimpleDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLocation}
          onClose={handleClose}
          displayList={
            <DialogContent dividers>
              {this.state.addresses <= 0 ?
                <div style={{ textAlign: 'center', marginBottom: '12px', }}>
                  <img src = {require('./assets/location_home.png')} alt = {"Location"} style={{ height: '60px',width: 'auto', }}/>
                </div>
              :null}
              {this.state.addresses <= 0 ?
                <Typography style={{ fontSize: '20px', fontWeight: '800', letterSpacing: 'normal', color: '#2e2c36', marginBottom: '26px', textAlign: 'center', }}>
                  {strings["Selecciona tu ubicación o ingrese una nueva dirección2"]}
                </Typography>
              :null}
              {this.state.addresses <= 0 ?
                <Typography gutterBottom style={{ textAlign: 'center', }}>
                  {strings["Selecciona tu ubicación o ingrese una nueva dirección4"]}
                </Typography>
              :null}
              {this.state.addresses != 0 ?
              <Typography gutterBottom>
                {strings["Selecciona tu ubicación o ingrese una nueva dirección"]}
              </Typography>
              :null}
              <br />
              <Autocomplete
                style={{ width: '100%' }}
                id="google-map-demo"
                sx={{ width: 300 }}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={this.state.locOptions}
                loading={true}
                loadingText={strings['Buscando direcciones']}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={this.state.autoLoc}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  this.setState({ hasClickedLoc: false });
                  if (newValue.description) {

                    Geocode.setApiKey(
                      constants.googleMapsAPI
                    );
                    Geocode.setLanguage('es');
                    Geocode.setRegion('MX');
                    Geocode.setLocationType('ROOFTOP');
                    Geocode.enableDebug();
                    Geocode.fromAddress(newValue.description).then(
                      (response) => {
                        const { lat, lng } =
                          response.results[0].geometry.location;
                        // console.log(lat, lng);
                        this.setState({
                          defaultProps: {
                            center: {
                              lat: lat,
                              lng: lng,
                            },
                            zoom: 16,
                          },
                          defaultPropsCircle: {
                            center: {
                              lat: lat,
                              lng: lng,
                            },
                            zoom: 16,
                          },
                          selectLocationData: {
                            id: this.state.selectLocationData.id
                              ? this.state.selectLocationData.id
                              : '',
                            name: this.state.selectLocationData.name
                              ? this.state.selectLocationData.name
                              : strings['mi hogar'],
                            address: this.state.selectLocationData.address
                              ? this.state.selectLocationData.address
                              : '',
                            geoAddress: newValue.description,
                            addressNotes: this.state.selectLocationData
                              .addressNotes
                              ? this.state.selectLocationData.addressNotes
                              : '',
                            latitude: lat,
                            longitude: lng,
                          },
                        });
                        this.setState({ hasClickedLoc: true });
                        this.setState({ autoLoc: newValue });
                      },
                      (error) => {
                        //console.error(error);
                      }
                    );
                  }
                  // setOptions(newValue ? [newValue, ...options] : options);
                  // setValue(newValue);
                }}
                onInputChange={_.debounce(async (event, newInputValue) => {
                  // setInputValue(newInputValue);
                  const { value } = event.target;
                  if (newInputValue.length > 4) {
                    var API_KEY = constants.googleMapsAPI;
                    // var type = '(regions)';
                    var baseURL =
                      'https://senor-order-85fa55d895ba.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                    var request =
                      baseURL +
                      '?input=' +
                      value +
                      '&key=' +
                      API_KEY +
                      '&sessiontoken=' +
                      _sessionToken;
                    await axios.get(request).then(async (response) => {
                      // console.log(response);
                      this.setState({ locOptions: response.data.predictions });
                    });
                  }
                },2000)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="filled-basic"
                    label={strings["Ingrese la dirección de entrega"]}
                    variant="filled"
                    style={{ width: '100%' }}
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocationOnIcon}
                            sx={{ color: 'text.secondary', mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}

                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              {/* <TextField id="filled-basic" label="Enter delivery address" variant="filled" style={{width: "100%"}}
                  onChange={async (event) => {
          const { value } = event.target;
          if(value.length>4) {
            var API_KEY =
                                                "AIzaSyDG8OvakDZ2x2OonPWn2Yj_kmFlI1LUXvM";
                                                var type = '(regions)';
                                                var baseURL =
                                                'https://traitte-order.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                                                var request =
                                                baseURL+'?input='+value+'&key='+API_KEY+'&sessiontoken='+_sessionToken;
                                                await axios.get(request).then(async (response) => {
                                                  console.log(response);
                                                })
                                              }
        }}
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
        }}
          /> */}
              {this.state.hasClickedLoc ? (
                <>
                  <br />
                  <GoogleMapReact
                    defaultCenter={this.state.defaultProps.center}
                    defaultZoom={this.state.defaultProps.zoom}
                    resetBoundsOnResize={true}
                    yesIWantToUseGoogleMapApiInternals
  onGoogleApiLoaded={({ map, maps }) => {
    var circle = new maps.Circle({
      strokeColor: constants.primaryColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: constants.primaryColor,
      fillOpacity: 0.3,
      map,
      center: {lat: this.state.defaultPropsCircle.center.lat, lng: this.state.defaultPropsCircle.center.lng},
      radius: 100,
    });
    this.setState({
      circleData: circle
    })
  }}
                    options={{
                      fullscreenControl: false,
                      scrollwheel: false,
                      panControl: false,
                      zoomControl: false,
                      rotateControl: false,
                    }}
                    bootstrapURLKeys={{
                      key: constants.googleMapsAPI,
                    }}
                    style={{ height: '23vh' }}
                    onChange={(changeData) => {
                      // console.log(changeData);
                      var dist = geolib.getDistance(
                        {
                          latitude: changeData.center.lat,
                          longitude: changeData.center.lng,
                        },
                        {
                          latitude: this.state.defaultPropsCircle.center.lat,
                          longitude: this.state.defaultPropsCircle.center.lng,
                        }
                      );
                      if(dist<=100) {
                      this.setState({
                        defaultProps: {
                          center: {
                            lat: changeData.center.lat,
                            lng: changeData.center.lng,
                          },
                          zoom: changeData.zoom,
                        },
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          latitude: changeData.center.lat,
                          longitude: changeData.center.lng,
                        },
                      });
                    } else {
                      Geocode.setApiKey(
                        constants.googleMapsAPI
                      );
                      Geocode.setLanguage('es');
                      Geocode.setRegion('MX');
                      Geocode.setLocationType('ROOFTOP');
                      Geocode.enableDebug();
                      Geocode.fromLatLng(changeData.center.lat,changeData.center.lng).then(
                      // Geocode.fromAddress(newValue.description).then(
                        (response) => {
                          this.state.circleData.setCenter({lat: changeData.center.lat, lng: changeData.center.lng});
                          // const { lat, lng } =
                          //   response.results[0].geometry.location;
                          // console.log(lat, lng);
                          this.setState({
                            defaultProps: {
                              center: {
                                lat: changeData.center.lat,
                                lng: changeData.center.lng,
                              },
                              zoom: 16,
                            },
                            defaultPropsCircle: {
                              center: {
                                lat: changeData.center.lat,
                                lng: changeData.center.lng,
                              },
                              zoom: 16,
                            },
                            selectLocationData: {
                              id: this.state.selectLocationData.id,
                              name: this.state.selectLocationData.name,
                              address: this.state.selectLocationData.address,
                              geoAddress: this.state.selectLocationData.geoAddress,
                              addressNotes:
                                this.state.selectLocationData.addressNotes,
                              latitude: changeData.center.lat,
                              longitude: changeData.center.lng,
                            },
                          });
                          this.setState({ hasClickedLoc: true });
                          this.setState({ autoLoc: response.results[0].formatted_address });
                        },
                        (error) => {
                          console.error(error);
                        }
                      );
                    }
                    }}
                  >
                    {/* <div
        style={{  display: "flex",
  width: "50px",
  opacity: "0.5",
  height: "50px",
  backgroundColor: "green",
  borderRadius: "50%"}}
        lat={this.state.defaultCircleProps.center.lat}
          lng={this.state.defaultCircleProps.center.lng}
      /> */}
                    <LocationOnIcon
                      style={{
                        color: constants.primaryColor,
                        transform: 'translate(-50%, -100%)',
                      }}
                      fontSize={'large'}
                      lat={this.state.defaultProps.center.lat}
                      lng={this.state.defaultProps.center.lng}
                      // text={'Google Map'}
                    />
                  </GoogleMapReact>
                  <br />
                  <TextField
                    fullWidth
                    id="filled-basic"
                    label={strings["Detalles adicionales"]}
                    variant="filled"
                    value={this.state.selectLocationData.addressNotes}
                    onChange={(event) => {
                      this.setState({
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes: event.target.value,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        },
                      });
                    }}
                  />
                  <br />
                  <br />
                  <ToggleButtonGroup
                    fullWidth
                    // color="primary"
                    value={this.state.selectLocationData.name}
                    exclusive
                    onChange={(event, newAlignment) => {
                      this.setState({
                        selectLocationData: {
                          id: this.state.selectLocationData.id,
                          name: newAlignment,
                          address: this.state.selectLocationData.address,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        },
                      });
                    }}
                  >
                    <ToggleButton
                      value={strings["mi hogar"]}
                      style={{ color: constants.primaryColor }}
                    >
                      {strings["Hogar"]}
                    </ToggleButton>
                    <ToggleButton
                      value={strings["mi Trabajo"]}
                      style={{ color: constants.primaryColor }}
                    >
                      {strings["Trabajo"]}
                    </ToggleButton>
                    <ToggleButton
                      value={strings["otra dirección"]}
                      style={{ color: constants.primaryColor }}
                    >
                      {strings["Visita"]}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <br />
                  <br />
                  <Button
                    variant="text"
                    style={{ float: 'left' }}
                    onClick={() => {
                      this.setState({ hasClickedLoc: false });
                      this.setState({ autoLoc: '' });
                      this.setState({
                        selectLocationData: {
                          id: '',
                          name: strings['mi hogar'],
                          address: '',
                          geoAddress: '',
                          addressNotes: '',
                          latitude: this.state.defaultProps.center.lat,
                          longitude: this.state.defaultProps.center.lng,
                        },
                      });
                    }}
                  >
                    {strings["Cancelar"]}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ backgroundColor: constants.primaryColor }}
                    style={{
                      float: 'right',
                      color: '#ffffff',
                      boxShadow: 'none',
                    }}
                    onClick={() => {
                      var locationData = JSON.parse(
      localStorage.getItem('location')
    );
                      if (locationData == null) {
                        locationData = [];
                      }
                      if (this.state.selectLocationData.id === '') {
                        for (var i = 0; i < locationData.length; i++) {
                          locationData[i]['selected'] = false;
                        }
                        var idLoc = uuidv4();
                        locationData.push({
                          id: idLoc,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          selected: true,
                        });
                        // //console.log('locationData', locationData);
                        var selected = {
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        };
                        try {
                        localStorage.setItem(
                          'selected',
                          JSON.stringify(selected)
                        );
                        window.dispatchEvent(new Event("selected"));
                        } catch(e){}
                        if (selected == null) {
                          selected = { latitude: 0, longitude: 0 };
                        }
                        this.setState(
                          {
                            selectedAddress: selected,
                            selectLocationName:
                              this.state.selectLocationData.geoAddress,
                          },
                          (newState) => {
                            handleClose();
                            // console.log(
                            //   'selectedAddress',
                            //   this.state.selectedAddress,
                            //   typeof this.state.selectedAddress
                            // );
                          }
                        );
                      } else {
                        for (var i = 0; i < locationData.length; i++) {
                          locationData[i]['selected'] = false;
                        }
                        const indexOfTaskToDelete = locationData.findIndex(
                          (task) => task.id === this.state.selectLocationData.id
                        );
                        locationData.splice(indexOfTaskToDelete, 1);
                        locationData.push({
                          id: this.state.selectLocationData.id,
                          name: this.state.selectLocationData.name,
                          address: this.state.selectLocationData.address,
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                          geoAddress: this.state.selectLocationData.geoAddress,
                          addressNotes:
                            this.state.selectLocationData.addressNotes,
                          selected: true,
                        });
                        var selected2 = {
                          latitude: this.state.selectLocationData.latitude,
                          longitude: this.state.selectLocationData.longitude,
                        };
                        if (this.state.userId && this.state.userId !== '') {
                          updateDoc(doc(db, 'users', this.state.userId), {
                            locations: locationData,
                          });
                        }
                        try {
                        localStorage.setItem(
                          'selected',
                          JSON.stringify(selected2)
                        );
                        window.dispatchEvent(new Event("selected"));
                        } catch(e) {}
                        if (selected2 == null) {
                          selected2 = { latitude: 0, longitude: 0 };
                        }
                        this.setState(
                          {
                            selectedAddress: selected2,
                            selectLocationName:
                              this.state.selectLocationData.geoAddress,
                          },
                          (newState) => {
                            handleClose();
                            // console.log(
                            //   'selectedAddress',
                            //   this.state.selectedAddress,
                            //   typeof this.state.selectedAddress
                            // );
                          }
                        );
                      }
                      this.setState({ addresses: locationData });
                      try {
                      localStorage.setItem(
                        'location',
                        JSON.stringify(locationData)
                      );
                      window.dispatchEvent(new Event("location"));
                      } catch(e) {}
                      this.setState({ hasClickedLoc: false });
                      this.setState({ autoLoc: '' });
                      this.setState({
                        selectLocationData: {
                          id: '',
                          name: strings['mi hogar'],
                          address: '',
                          geoAddress: '',
                          addressNotes: '',
                          latitude: this.state.defaultProps.center.lat,
                          longitude: this.state.defaultProps.center.lng,
                        },
                      });
                      this.setState(
                        { canDeliverHere: false, businessData: null },
                        (newState) => {
                          handleClose();
                          // const { id } = this.props.match.params;
                          // // this.setState({ businessData: {} });
                          // this.getCities(id);
                        }
                      );
                    }}
                  >
                    {strings["Guardar"]}
                  </Button>
                </>
              ) : (
                <>
                  {/* <GoogleMapReact
                defaultCenter={this.state.defaultProps.center}
        defaultZoom={this.state.defaultProps.zoom}
        resetBoundsOnResize={true}
        bootstrapURLKeys={{ key: constants.googleMapsAPI }}
        style={{height: '50px'}}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text={'Google Map'}
        />
      </GoogleMapReact> */}
                  <br />
                  <List sx={{ pt: 0 }}>
                    {this.state.addresses != null &&
                    Array.isArray(this.state.addresses)
                      ? this.state.addresses.map((email, ind) => (
                          <div key={ind}>
                            <div
                              style={{
                                width: '100%',
                                borderBottom: '1px solid #e6ebf1',
                                padding: '5px 0px 5px 0px',
                                height: '75px',
                              }}
                            >
                              <div
                                style={{
                                  width: 'calc(30px)',
                                  float: 'left',
                                  padding: '18px 0px 0px 0px',
                                }}
                              >
                                <LocationOnIcon
                                  style={{
                                    color: email.selected
                                      ? constants.primaryColor
                                      : '#000',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: 'calc(100% - 31px)',
                                  float: 'right',
                                }}
                              >
                                <div
                                  style={{
                                    width: 'calc(100% - 55px)',
                                    float: 'left',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    var locationData;
                                        try {
                                          locationData = JSON.parse(
                                          localStorage.getItem('location')
                                        );
                                    } catch(e) {
                                    //console.log(e);
                                    }
                                    if (locationData == null) {
                                      locationData = [];
                                    }
                                    for (
                                      var i = 0;
                                      i < locationData.length;
                                      i++
                                    ) {
                                      locationData[i]['selected'] = false;
                                    }
                                    const indexOfTaskToDelete =
                                      locationData.findIndex(
                                        (task) => task.id === email.id
                                      );
                                    locationData.splice(indexOfTaskToDelete, 1);
                                    locationData.push({
                                      id: email.id,
                                      name: email.name,
                                      address: email.address,
                                      latitude: email.latitude,
                                      longitude: email.longitude,
                                      geoAddress: email.geoAddress,
                                      addressNotes: email.addressNotes,
                                      selected: true,
                                    });
                                    var selected2 = {
                                      latitude: email.latitude,
                                      longitude: email.longitude,
                                    };
                                    if (
                                      this.state.userId &&
                                      this.state.userId !== ''
                                    ) {
                                      updateDoc(
                                        doc(db, 'users', this.state.userId),
                                        {
                                          locations: locationData,
                                        }
                                      );
                                    }
                                    try {
                                    localStorage.setItem(
                                      'selected',
                                      JSON.stringify(selected2)
                                    );
                                    window.dispatchEvent(new Event("selected"));
                                    } catch(e) {}
                                    if (selected2 == null) {
                                      selected2 = { latitude: 0, longitude: 0 };
                                    }
                                    this.setState(
                                      {
                                        selectedAddress: selected2,
                                        selectLocationName: email.geoAddress,
                                      },
                                      (newState) => {
                                        handleClose();
                                        this.setState({ addresses: locationData });
                                        try {
                                    localStorage.setItem(
                                      'location',
                                      JSON.stringify(locationData)
                                    );
                                    window.dispatchEvent(new Event("location"));
                                        } catch(e) {}
                                    this.setState({
                                      canDeliverHere: false,
                                      businessData: null,
                                    });
                                    // // handleClose();
                                    // const { id } = this.props.match.params;
                                    // // this.setState({ businessData: {} });
                                    // this.getCities(id);
                                    //     // console.log(
                                    //     //   'selectedAddress',
                                    //     //   this.state.selectedAddress,
                                    //     //   typeof this.state.selectedAddress
                                    //     // );
                                      }
                                    );
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '700',
                                      textTransform: 'capitalize',
                                      color: 'slategray',
                                    }}
                                  >
                                    {email.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{ fontSize: '17px', fontWeight: '700', }}
                                  >
                                    {email.geoAddress}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      color: 'slategray',
                                    }}
                                  >
                                    {email.addressNotes}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    width: 'calc(54px)',
                                    float: 'right',
                                    padding: '18px 0px 0px 0px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span>
                                    <EditIcon
                                      style={{
                                        color: email.selected
                                          ? constants.primaryColor
                                          : '#000',
                                      }}
                                      onClick={() => {
                                        // this.setState({ hasClickedLoc: false });
                                        // console.log(email);
                                        if (email.latitude != null) {
                                          // const { lat, lng } = response.results[0].geometry.location;

                                          this.setState({
                                            defaultProps: {
                                              center: {
                                                lat: email.latitude,
                                                lng: email.longitude,
                                              },
                                              zoom: 16,
                                            },
                                            defaultPropsCircle: {
                                              center: {
                                                lat: email.latitude,
                                                lng: email.longitude,
                                              },
                                              zoom: 16,
                                            },
                                            selectLocationData: {
                                              id: email.id,
                                              name: email.name,
                                              address: email.address,
                                              geoAddress: email.geoAddress,
                                              addressNotes: email.addressNotes,
                                              latitude: email.latitude,
                                              longitude: email.longitude,
                                            },
                                          });
                                          this.setState({
                                            autoLoc: email.geoAddress,
                                          });
                                          this.setState({
                                            hasClickedLoc: true,
                                          });
                                        }
                                        // setOptions(newValue ? [newValue, ...options] : options);
                                        // setValue(newValue);
                                        // );
                                      }}
                                    />
                                    &nbsp;
                                    {email.selected ? null : (
                                      <DeleteForeverIcon
                                      onClick={
                                        ()=>{
                                          var locationData = this.state.addresses;
                        const indexOfTaskToDelete = locationData.findIndex(
                          (task) => task.id === email.id
                        );
                        locationData.splice(indexOfTaskToDelete, 1);
                        if (this.state.userId && this.state.userId !== '') {
                          updateDoc(doc(db, 'users', this.state.userId), {
                            locations: locationData,
                          });
                        }
                        this.setState({ addresses: locationData });
                        try {
                      localStorage.setItem(
                        'location',
                        JSON.stringify(locationData)
                      );
                      window.dispatchEvent(new Event("location"));
                        } catch(e) {}
                                        }
                                      }
                                        style={{ color: '#e53837' }}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </List>

                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      startIcon={<MyLocationIcon />}
                      disableElevation
                      align="center"
                      style={{
                        height: '61px',
                        borderRadius: '12px',
                       //boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px',
                       //backgroundColor: constants.primaryColor,
                       backgroundColor: 'white',
                       textTransform: 'none',
                        fontSize: '16px',
                      }}
                      onClick={async () => {
                        await navigator.geolocation.getCurrentPosition(
                          (position) => {
                            // console.log(position.coords.latitude);
                            this.setState({ hasClickedLoc: false });
                            if (position.coords.latitude != null) {
                              Geocode.setApiKey(
                                constants.googleMapsAPI
                              );
                              Geocode.setLanguage('es');
                              Geocode.setRegion('MX');
                              Geocode.setLocationType('ROOFTOP');
                              Geocode.enableDebug();
                              Geocode.fromLatLng(
                                position.coords.latitude,
                                position.coords.longitude
                              ).then(
                                (response) => {
                                  // const { lat, lng } = response.results[0].geometry.location;
                                  // console.log(lat, lng);
                                  this.setState({
                                    defaultProps: {
                                      center: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                      },
                                      zoom: 16,
                                    },
                                    defaultPropsCircle: {
                                      center: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                      },
                                      zoom: 16,
                                    },
                                    selectLocationData: {
                                      id: this.state.selectLocationData.id
                                        ? this.state.selectLocationData.id
                                        : '',
                                      name: this.state.selectLocationData.name
                                        ? this.state.selectLocationData.name
                                        : strings['mi hogar'],
                                      address: this.state.selectLocationData
                                        .address
                                        ? this.state.selectLocationData.address
                                        : '',
                                      geoAddress:
                                        response.results[0].formatted_address,
                                      addressNotes: this.state
                                        .selectLocationData.addressNotes
                                        ? this.state.selectLocationData
                                            .addressNotes
                                        : '',
                                      latitude: position.coords.latitude,
                                      longitude: position.coords.longitude,
                                    },
                                  });
                                  this.setState({ hasClickedLoc: true });
                                  this.setState({
                                    autoLoc:
                                      response.results[0].formatted_address,
                                  });
                                },
                                (error) => {
                                  //console.error(error);
                                }
                              );
                            }
                            // setOptions(newValue ? [newValue, ...options] : options);
                            // setValue(newValue);
                          },
                          (err) => console.log(err)
                        );
                      }}
                    >
                      {strings["Usar mi ubicación actual"]}
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
          }
        />
        <MyCardsDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openCards}
          onClose={handleCardClose}
          displayList={
            <DialogContent dividers>
              <>
                                              {this.state.cards.map((items, t) => (
                                                <div style={{padding: "8px"}}>
                      <div style={{borderRadius: "10px", backgroundColor: "#f8f9fb", padding: "0px 8px 0px"}}>
                      <Stack
                  direction={'row'}
                  spacing={0}
                  justifyContent="space-between"
                              alignItems="center"
                >
                  <Stack
                  direction={'row'}
                  spacing={0}
                  justifyContent="flex-start"
                              alignItems="center"
                              style={{padding: "15px 15px 15px"}}
                >
                   <img
                                style={{
                                  width: '25px',
                                  height:"auto",
                                  border: '0',
                                  marginRight: "15px"
                                }}

                                src={(items['card']['brand']
                                                      .toString()
                                                      .toLowerCase()
                                                      .includes('visa'))
                                                  ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fvisa.png?alt=media&token=1925130f-c1f1-40cf-96ce-07b6d12fb826"
                                                  : (items['card']
                                                              ['brand']
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes('master'))
                                                      ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fmastercard.png?alt=media&token=aed207cf-4abf-4617-9554-c7e4edb66d66"
                                                      : (items['card']
                                                                  ['brand']
                                                              .toString()
                                                              .toLowerCase()
                                                              .includes(
                                                                  'discover'))
                                                          ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiscover.png?alt=media&token=3321b25f-44d1-429a-916a-b10b7bf3fdc0"
                                                          : (items
                                                                          ['card']
                                                                      ['brand']
                                                                  .toString()
                                                                  .toLowerCase()
                                                                  .includes('diners'))
                                                              ? "https://firebasestorage.googleapis.com/v0/b/cafi-a68cf.appspot.com/o/cards%2Fdiners.png?alt=media&token=c08b27e3-a721-4fc0-9819-657716d471f4"
                                                              :"https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png"}
                              />
                        <Typography
                    // sx={{ p: 2 }}
                    style={{
                      fontSize: this.state.width > 600 ? '14px' : '16px',
                      width: `${this.state.width > 600 ? 300: this.state.width - 175}px`,
                      color: '#2e2c36',
                      fontFamily: 'Nunito',
                      overflow: 'auto',
                      overflowWrap:'anywhere',

                    }}
                  >
                    {"  "+strings['cardData241']+items['card']['card_number']
                                                  .toString()}
                  </Typography></Stack>
                 { (this.state.userDetails?.subscription == null ||
                                      this.state.userDetails?.subscription['default_payment_method'] ==
                                          null ||
                                      (this.state.userDetails?.subscription['status'] !== 'active' &&
                                      this.state.userDetails?.subscription['status'] !==
                                              'trialing') ||
                                      items['id'] !==
                                      this.state.userDetails?.subscription[
                                              'default_payment_method'])? <DeleteForeverIcon
                  onClick={
                    async()=>{
                      this.setState({
                              loading: true
                            });
                      var temp = this.state.cards;
                      var deletedCardId = items['id'];
                      // var index = temp.indexOf(items);
                      if(t>=0) {
                        temp.splice(t,1);
                      }
                      // console.log(temp, t);
                            this.setState({
                              cards: temp
                            });
                            await updateDoc(doc(db, 'users', this.state.userId), {
                              cards:temp,
            });
            try {
              await axios
                                      .post(
                                        'https://senor-order-85fa55d895ba.herokuapp.com/drivers/detachcustomer/',
                                        null,
                                        {
                                          params: {
                                            id: deletedCardId,
                                          },
                                        }
                                      )
                                      .then(async (response) => {
                                        this.setState({ loading: false });
                                        // console.log(response);
                                      })
                                      .catch((e) => {
                                        this.setState({ loading: false });
                                      });

            } catch(e) {
              this.setState({ loading: false });
            }
                    }
                  }
                                        style={{ color: '#e53837',cursor: 'pointer', }}
                                      />:null }
                  </Stack></div></div>
                                              ))}
                                            </>
                                            <>
                    {/* {(this.state.businessData && !this.state.businessData.cashOnly && (this.state.businessData.paymentOptions == null || this.state.businessData.paymentOptions.length === 0 || this.state.businessData.paymentOptions.includes(0))) ? (
                      <div style={{ padding: '0px 10px 0px' }}>
                        <div
                          style={{
                            padding: '12px',
                            borderBottom: '1px solid #e6ebf1',
                            cursor: 'pointer',
                          }}
                          onClick={()=>{
                            handleClickAddCardOpen();
                          }}
                        >
                          <Stack
                            direction={'row'}
                            spacing={0}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction={'row'}
                              spacing={0}
                              alignItems="center"
                            >
                              <img
                                height={'100%'}
                                style={{
                                  padding: '22px',
                                  height: '45px',
                                  width: '45px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  border: '0',
                                  backgroundImage:
                                    'url("https://dl3.pushbulletusercontent.com/CUuK1FWCl5LMK8FrR3SWbOEctMXBKazX/Payment%20icons-01.png")',
                                }}
                              />
                              <div style={{ padding: '5px 15px' }}>
                                <Typography
                                  style={{
                                    fontSize: '17.5px',
                                    fontWeight: '500',
                                    fontFamily: 'Nunito',
                                    letterSpacing: 'normal',
                                  }}
                                >
                                  {strings["Agregar nueva tarjeta2"]}
                                </Typography>
                              </div>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    ) : null} */}
                  </>
            </DialogContent>
          }
        />
                <RegisterDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openRegister}
          onClose={handleCloseRegister}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '35px',
                  }}
                >
                  {strings['Bienvenido']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '15px',
                  }}
                >
                  {strings['Crea tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={this.state.fullName.length <= 0}
                fullWidth
                id="filled-basic23"
                label={strings['Nombre completo']}
                variant="filled"
                value={this.state.fullName}
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
              />
              <br />
              <br />
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic45"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings['Contraseña']}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                  }}
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl
                  variant="filled"
                  style={{
                    color: constants.secondaryColor,
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings['Número de teléfono']}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                  />
                </FormControl>
              </Box>

              <br />
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: constants.primaryColor, }}
                fullWidth
                variant="contained"
                sx={{backgroundColor: constants.primaryColor}}
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6 &&
                    this.state.phoneNumber.length >= 10 &&
                    this.state.fullName.length > 0
                  ) {
                    this.setState({ loading: true });
                    createUserWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then(async (userCredential) => {
                        const user = userCredential.user;
                        const uid = user.uid;

                        var nameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var insertNameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var i = 0;
                        var dataInsert = '';
                        while (true) {
                          if (i === nameSearch.length) {
                            break;
                          }
                          if (dataInsert === '') {
                            dataInsert = nameSearch[i];
                          } else {
                            dataInsert = dataInsert + ' ' + nameSearch[i];
                            insertNameSearch.push(dataInsert);
                          }
                          i = i + 1;
                        }
                        await setDoc(doc(db, 'users', uid), {
                          fullName: this.state.fullName,
                          nameSearch: insertNameSearch,
                          email: this.state.email,
                          fromNew: true,
                          phoneNumber:
                            this.state.phoneCountry + this.state.phoneNumber,
                          loginType: 'Email/Password',
                          needcount: true,
                          orders: {
                            '0-2021': 0,
                            '1-2021': 0,
                            '2-2021': 0,
                            '3-2021': 0,
                            '4-2021': 0,
                            '5-2021': 0,
                            '6-2021': 0,
                            '7-2021': 0,
                            '8-2021': 0,
                            '9-2021': 0,
                            '10-2021': 0,
                            '11-2021': 0,
                          },
                          isSignedIn: false,
                          lastLogin: null,
                          lastOrder: null,
                          isTestUser: false,
        });

                        // sendOTP(
                        //   this.state.phoneCountry + this.state.phoneNumber
                        // );
                        this.setState({ loading: false, openRegister: false });
                        this.setState({
                          openAlert: true,
                          alertType: 'success',
                          alertMsg: strings['Datos correctos'],
                        });
                        this.setState({
                          userId: uid,
                          userDetails: {
                            fullName: this.state.fullName,
                            nameSearch: insertNameSearch,
                            email: this.state.email,
                            fromNew: true,
                            phoneNumber:
                              this.state.phoneCountry + this.state.phoneNumber,
                            loginType: 'Email/Password',
                            orders: {
                              '0-2021': 0,
                              '1-2021': 0,
                              '2-2021': 0,
                              '3-2021': 0,
                              '4-2021': 0,
                              '5-2021': 0,
                              '6-2021': 0,
                              '7-2021': 0,
                              '8-2021': 0,
                              '9-2021': 0,
                              '10-2021': 0,
                              '11-2021': 0,
                            },
                            isSignedIn: false,
                            lastLogin: null,
                            lastOrder: null,
                            isTestUser: false,
                          },
                          cards: [],
                        });
                        // handleClickOpenOTP();
                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        if(errorCode !== 'auth/email-already-in-use')
                        openAlert(
                          'error',
                          strings['Existe un error en el registro']
                        );
                        else {
                          openAlert(
                          'error',
                          strings['La identificación de correo electrónico ya está en uso']
                        );
                        }
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings['Crear cuenta']}
              </Button>
              <Typography
                gutterBottom
                align="center"
                style={{ marginBottom: '0' }}
              >
                {strings['o']}
              </Typography>
              <br />
              <Button style={{ fontWeight: '600', height: '61px', borderRadius: '12px', color: "#000", boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: "#fff", }}
              sx={{ backgroundColor: "#fff" }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (true
                  ) {
                    this.setState({ loading: true });
                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();
                    auth.useDeviceLanguage();
                    signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    this.setState({ loading: false });
    openAlert('error', 'The data entered is not correct');
                    });
                  }
                }}
              > 
              <img src = {require('./assets/google.png')} alt = {"Google"} style={{height: '40px',width: 'auto',marginLeft:'5px',marginRight:'5px'}}/><div style={{width: "10px"}}></div>
                {strings['first2086']}
              </Button>
            </DialogContent>
          }
        />
        <RegisterAdditionalDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openAdditionalRegister}
          onClose={handleCloseAdditionalRegister}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                {/* <div style={{ textAlign: 'center' }}>
                  <img
                    src={this.state.businessData.logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', objectFit: 'cover', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div> */}
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: this.state.fontType,
                    letterSpacing: 'normal',
                    marginTop: '35px',
                  }}
                >
                  {strings['Bienvenido de regreso']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: this.state.fontType,
                    letterSpacing: 'normal',
                    marginBottom: '15px',
                  }}
                >
                  {strings['Ingresa tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: '#272727',
                  borderRadius: '0',
                  backgroundColor: '#e6ebf1',
                  overflow: 'hidden',
                  fontFamily: this.state.fontType,
                }}
                error={this.state.fullName.length <= 0}
                fullWidth
                id="filled-basic23"
                label={strings['Nombre completo']}
                variant="filled"
                value={this.state.fullName}
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
              />
              <br />
              <br />
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CountrySelect
                  style={{
                    color: '#272727',
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    fontFamily: this.state.fontType,
                  }}
                  labels={en}
                  value={this.state.phoneCountry}
                  onChange={(number) => {
                    this.setState({ phoneCountry: number });
                  }}
                />
                &nbsp;&nbsp;
                <FormControl
                  variant="filled"
                  style={{
                    color: '#272727',
                    borderRadius: '0',
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    width: '100%',
                    fontFamily: this.state.fontType,
                  }}
                >
                  <InputLabel
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    htmlFor="filled-adornment-password"
                  >
                    {strings['Número de teléfono']}
                  </InputLabel>
                  <FilledInput
                    error={
                      this.state.phoneNumber.length < 10 &&
                      this.state.phoneNumber.length > 0
                    }
                    id="filled-adornment-password"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="phone"
                    value={this.state.phoneNumber}
                    onChange={(event) => {
                      this.setState({ phoneNumber: event.target.value });
                    }}
                    //           endAdornment={
                    //             <InputAdornment position="end">
                    //               <IconButton
                    //                 aria-label="toggle password visibility"
                    //                 onClick={()=>{
                    //                   this.setState({'showPassword': !this.state.showPassword})
                    //                 }}
                    //                 onMouseDown={(event) => {
                    //   event.preventDefault();
                    // }}
                    //                 edge="end"
                    //               >
                    //                 {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    //               </IconButton>
                    //             </InputAdornment>
                    //           }
                  />
                </FormControl>
              </Box>

              <br />
              <br />
              <Button
              style={{fontFamily: this.state.fontType, height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: constants.primaryColor}}
                fullWidth
                variant="contained"
                sx={{backgroundColor: constants.primaryColor}}
                onClick={async () => {
                  if (
                    this.state.phoneNumber.length >= 9 &&
                    this.state.fullName.length > 0
                  ) {
                    this.setState({ loading: true });
                    // await axios.get(request).then(async (response) => {
                    //           console.log(response);
                    //           this.setState({ locOptions: response.data.predictions });
                    //         });
                    // createUserWithEmailAndPassword(
                    //   auth,
                    //   this.state.email,
                    //   this.state.password
                    // )
                    //   .then(async (userCredential) => {
                        // Signed in

                        // console.log('signing', userCredential);
                        const user = this.state.userCredential;
                        const uid = user.uid;


                        var nameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var insertNameSearch = this.state.fullName
                          .toLowerCase()
                          .split(' ');
                        var i = 0;
                        var dataInsert = '';
                        while (true) {
                          if (i === nameSearch.length) {
                            break;
                          }
                          if (dataInsert === '') {
                            dataInsert = nameSearch[i];
                          } else {
                            dataInsert = dataInsert + ' ' + nameSearch[i];
                            insertNameSearch.push(dataInsert);
                          }
                          i = i + 1;
                        }
                        await setDoc(doc(db, 'users', uid), {
                          fullName: this.state.fullName,
                          nameSearch: insertNameSearch,
                          email: this.state.email,
                          fromNew: true,
                          phoneNumber:
                            this.state.phoneCountry + this.state.phoneNumber,
                          loginType: 'Email/Password',
                          needcount: true,
                          orders: {
                            '0-2021': 0,
                            '1-2021': 0,
                            '2-2021': 0,
                            '3-2021': 0,
                            '4-2021': 0,
                            '5-2021': 0,
                            '6-2021': 0,
                            '7-2021': 0,
                            '8-2021': 0,
                            '9-2021': 0,
                            '10-2021': 0,
                            '11-2021': 0,
                          },
                          isSignedIn: false,
                          lastLogin: null,
                          lastOrder: null,
                          isTestUser: false,
                        });

                        // sendOTP(
                        //   this.state.phoneCountry + this.state.phoneNumber
                        // );
                        this.setState({ loading: false, openAdditionalRegister: false });
                        this.setState({
                          openAlert: true,
                          alertType: 'success',
                          alertMsg: strings['Datos correctos'],
                        });
                        this.setState({
                          userId: uid,
                          userDetails: {
                            fullName: this.state.fullName,
                            nameSearch: insertNameSearch,
                            email: this.state.email,
                            fromNew: true,
                            phoneNumber:
                              this.state.phoneCountry + this.state.phoneNumber,
                            loginType: 'Email/Password',
                            orders: {
                              '0-2021': 0,
                              '1-2021': 0,
                              '2-2021': 0,
                              '3-2021': 0,
                              '4-2021': 0,
                              '5-2021': 0,
                              '6-2021': 0,
                              '7-2021': 0,
                              '8-2021': 0,
                              '9-2021': 0,
                              '10-2021': 0,
                              '11-2021': 0,
                            },
                            isSignedIn: false,
                            lastLogin: null,
                            lastOrder: null,
                            isTestUser: false,
                          },
                          cards: [],
                        });
                        // handleClickOpenOTP();
                        // this.setState({ userId: uid });
                        // ...
                      // })
                      // .catch((error) => {
                      //   const errorCode = error.code;
                      //   const errorMessage = error.message;
                      //   this.setState({ loading: false });
                      //   openAlert(
                      //     'error',
                      //     strings['Existe un error en el registro']
                      //   );
                      //   // console.log(errorMessage, errorCode);
                      // });
                  }
                }}
              >
                {strings['Crear cuenta']}
              </Button>
            </DialogContent>
          }
        />
        <LoginDialog
          selectedValue={this.state.selectedValue}
          open={this.state.openLogin}
          onClose={handleCloseLogin}
          displayList={
            <DialogContent dividers>
              <div className="showonphone">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={logo}
                    alt="logo"
                    height={'100%'}
                    style={{ borderRadius: '50%', width: '60px', height: '60px', boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',}}
                  />
                </div>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '800',
                    color: '#2c3136',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginTop: '65px',
                  }}
                >
                  {strings['Bienvenido de regreso']}
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    color: 'slategray',
                    fontFamily: 'Nunito',
                    letterSpacing: 'normal',
                    marginBottom: '35px',
                  }}
                >
                  {strings['Ingresa tu cuenta para continuar']}
                </Typography>
              </div>
              <TextField
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
                error={
                  !validator.isEmail(this.state.email) &&
                  this.state.email.length > 0
                }
                fullWidth
                id="filled-basic"
                label={strings['Email']}
                variant="filled"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
              <br />
              <br />
              <FormControl
                fullWidth
                variant="filled"
                style={{
                  color: constants.secondaryColor,
                  borderRadius: '0',
                  backgroundColor: '#ffffff',
                  overflow: 'hidden',
                }}
              >
                <InputLabel
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  htmlFor="filled-adornment-password"
                >
                  {strings['Contraseña']}
                </InputLabel>
                <FilledInput
                  error={
                    this.state.password.length <= 6 &&
                    this.state.password.length > 0
                  }
                  id="filled-adornment-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: '#b3b9c2' }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <Typography
                align="center"
                onClick={async ()=>{
                  if (
                    validator.isEmail(this.state.email)
                  ) {
                    this.setState({ loading: true });
                  await sendPasswordResetEmail(
                    auth,
                      this.state.email,
                  ).then((userCredential) => {
                    this.setState({ loading: false });
                    openAlert('success', strings['Restablecer instrucciones enviadas al correo electrónico ingresado']);
                  }).catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        this.setState({ loading: false });
                        openAlert('error', strings['No puedo encontrar el correo electrónico o algo salió mal.']);
                        // console.log(errorMessage, errorCode);
                      });
                } else {
                  openAlert('error', strings['Por favor ingrese un correo electrónico válido primero']);
                }
                }}
                style={{ marginBottom: '0', fontWeight: '700', fontSize: "16px", cursor: "pointer" }}
              >
                {strings['¿Olvidaste tu contraseña?']}
              </Typography>
              <br />
              <Button style={{ height: '61px', borderRadius: '12px', boxShadow: 'rgb(17 12 46 / 15%) 0px 18px 100px 0px', backgroundColor: constants.primaryColor, }}
              sx={{ backgroundColor: constants.primaryColor }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  if (
                    validator.isEmail(this.state.email) &&
                    this.state.password.length >= 6
                  ) {
                    this.setState({ loading: true });
                    signInWithEmailAndPassword(
                      auth,
                      this.state.email,
                      this.state.password
                    )
                      .then((userCredential) => {
                        // Signed in
                        // console.log('signing', userCredential);
                        const user = userCredential.user;
                        const uid = user.uid;

                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        //console.log(errorMessage, errorCode);
                        signInWithEmailAndPassword(
                      auth,
                      this.state.email.trim(),
                      'titotito'
                    )
                      .then(async(userCredential) => {

                        await updatePassword(
                          userCredential.user,
                          this.state.password
                        )
                          .then((res) => {
                            // console.log('Result after updating password', res);
                          });
                        // Signed in
                        // console.log('signing', userCredential);
                        const user = userCredential.user;
                        const uid = user.uid;

                        // this.setState({ userId: uid });
                        // ...
                      })
                      .catch((error2) => {
                        const errorCode = error2.code;
                        const errorMessage = error2.message;
                        this.setState({ loading: false });
                        openAlert('error', 'Los datos ingresados no son correctos');
                        //console.log(errorMessage, errorCode);
                      });
                        // console.log(errorMessage, errorCode);
                      });
                  }
                }}
              >
                {strings['Iniciar sesión']}
              </Button>
              <Typography
                gutterBottom
                align="center"
                style={{ marginTop: '8px' }}
              >
                {strings['o']}
              </Typography>
              <Button style={{ background: 'none', boxShadow: 'none', color: constants.primaryColor, textTransform: 'inherit', fontSize: '18px', }}
              sx={{ backgroundColor: constants.primaryColor }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  handleCloseLogin();
                  handleClickOpenRegister();
                }}
              >
                {strings['Registro']}
              </Button>
            </DialogContent>
          }
        />

        <div style={{width: '100%', overflow: 'hidden'}}>
          
          <div
            className='backgroundImageLoad'
            id="scroll-container"
            style={{
              marginTop: "60px",
              backgroundImage: this.state.adminData != null &&
                (this.state.adminData['busRegMainImg'] != null && this.state.adminData['busRegMainImg'] != '') ?
                `url(${this.state.adminData['busRegMainImg'].toString()})` :
                'url("https://dl3.pushbulletusercontent.com/Tt5MFmZgvnta2XaczrrxbnBZRWC0woy1/business_register_background.webp")',
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                display: 'flex',
                padding: this.state.width <= 600 ? '30px 5%' : '50px 5%',
                flexDirection: this.state.width <= 600 ? 'column' : undefined
              }}>
              <div style={{
                width: this.state.width <= 600 ? undefined : '50%',
                alignSelf: 'center',
                paddingRight: this.state.width <= 600 ? undefined : '2%',
                paddingBottom: this.state.width <= 600 ? 40 : undefined,
                }}>
                <Typography style={{fontSize: '46px', color: 'white', fontWeight: '800'}}>
                  {strings["0% TARIFAS POR 30 DÍAS"]}
                </Typography>
                <div style={{height: '16px'}}></div>
                <Typography style={{fontSize: '18PX', color: 'white', fontWeight: '500'}}>
                  {strings["RegistroDeNegocioDescr1"]}
                </Typography>
                <div style={{height: '16px'}}></div>
                <Typography style={{fontSize: '18PX', color: 'white', fontWeight: '500'}}>
                  {strings["RegistroDeNegocioDescr2"]}
                </Typography>
                <div style={{height: '16px'}}></div>
                <Typography style={{fontSize: '18PX', color: 'white', fontWeight: '500'}}>
                  {strings["RegistroDeNegocioDescr3"]}
                </Typography>
                <div style={{height: '16px'}}></div>
                <Typography style={{fontSize: '18PX', color: 'white', fontWeight: '800'}}>
                  {strings["RegistroDeNegocioDescr4"]}
                </Typography>
              </div>
              <div style={{padding: this.state.width <= 600 ? '10px' : '20px', width: this.state.width <= 600 ? undefined : '50%', background: 'white', borderRadius: '12px'}}>
                <Typography style={{fontSize: this.state.width <= 600 ? '28px' : '34px', color: 'black', fontWeight: '800', textAlign: 'center'}}>
                  {strings["Registra tu negocio"]}
                </Typography>
                <div style={{height: '24px'}}></div>
                <div style={{marginBottom: "20px"}}>
                  {(this.state.showwhataspp)?<br id="showwhataspp2" />:null}
                  {/* <div style={{fontSize: "15px",color: "#999999", paddingLeft: "5px"}}>{strings["Restaurante o tienda"]} {(this.state.nameerror)?<span id="nameerror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                  <input value={this.state.restName}  onChange={(event)=> {
                      this.setState({
                        restName: event.target.value
                      });
                    }}
                    required
                    type="text"
                    name=""
                    placeholder={strings["Ingresa el nombre de tu restaurante o tienda"]}
                    className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd',
                    }}/>
                  
                </div>
                <div style={{display: "flex"}}>
                  <div style={{marginBottom: "20px", width: 'calc(50% - 10px)'}}>
                    {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Tipo de comercio"]}{(this.state.typeerror)?<span id="typeerror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                    <select id="restType" value={this.state.restType}  onChange={(event)=> {
                      this.setState({
                        restType: event.target.value
                      });
                    }} required name="" className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd',
                      cursor: 'pointer',
                      background: 'white'
                    }}>
                      <option value="" selected hidden>{strings["Tipo de comercio"]}</option>
                      <option value="Restaurante">{strings["Restaurante"]}</option>
                      <option value="Tienda">{strings["Tienda"]}</option>
                    </select>
                  </div>
                  <div style={{width: '20px'}}></div>
                  <div style={{marginBottom: "20px", width: 'calc(50% - 10px)'}}>
                    {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Ciudad"]}{(this.state.cityerror)?<span id="cityerror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                    <select id="mySelect" value={this.state.selectedCityId}  onChange={(event)=> {
                      var cityName = this.state.cities.filter((i, ind) => i['id'] === event.target.value)[0]['name'];
                      this.setState({
                        selectedCityId: event.target.value,
                        selectedCity: cityName
                      });
                    }} required name="" className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd',
                      cursor: 'pointer',
                      background: 'white'
                    }}>
                      <option value="" hidden>{strings["Elige tu ciudad"]}</option>
                      {this.state.cities.map((i, ind) => (
                      <option value={i['id']}>{i['name']}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Dirección"]}{(this.state.addresserror)?<span id="addresserror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                  <div className="cel" style={{display: "flex"}}>
                    <div style={{marginBottom: "20px", minWidth: this.state.hasClickedLoc ? 'calc(50% - 10px)' : '100%'}}>
                      <div className="autocomplete" style={{width: '100%'}}>
                        <Autocomplete
                          freeSolo={false}
                          forcePopupIcon={false}
                          style={{ width: '100%' }}
                          id="google-map-demo"
                          sx={{ width: 300 }}
                          getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.description
                          }
                          filterOptions={(x) => x}
                          options={this.state.locOptions}
                          loading={true}
                          loadingText={strings['Buscando direcciones']}
                          autoComplete
                          includeInputInList
                          filterSelectedOptions
                          value={this.state.aucity2toLoc}
                          onChange={(event, newValue) => {
                            // console.log(newValue);
                            this.setState({ hasClickedLoc: false });
                            if (newValue.description) {

                              Geocode.setApiKey(
                                constants.googleMapsAPI
                              );
                              Geocode.setLanguage('es');
                              Geocode.setRegion('MX');
                              Geocode.setLocationType('ROOFTOP');
                              Geocode.enableDebug();
                              Geocode.fromAddress(newValue.description).then(
                                (response) => {
                                  const { lat, lng } =
                                    response.results[0].geometry.location;
                                  // console.log(lat, lng);
                                  this.setState({
                                    defaultProps: {
                                      center: {
                                        lat: lat,
                                        lng: lng,
                                      },
                                      zoom: 16,
                                    },
                                    defaultPropsCircle: {
                                      center: {
                                        lat: lat,
                                        lng: lng,
                                      },
                                      zoom: 16,
                                    },
                                    city2: newValue.description,
                                    cityLat: lat,
                                    cityLng: lng,
                                  });
                                  this.setState({ hasClickedLoc: true });
                                  this.setState({ autoLoc: newValue });
                                },
                                (error) => {
                                  console.error(error);
                                }
                              );
                            }
                          }}
                          onInputChange={_.debounce(async (event, newInputValue) => {
                            const { value } = event.target;
                            if (newInputValue.length > 4) {
                              var API_KEY = constants.googleMapsAPI;
                              var baseURL =
                                'https://senor-order-85fa55d895ba.herokuapp.com/drivers/proxy/https://maps.googleapis.com/maps/api/place/autocomplete/json';
                              var request =
                                baseURL +
                                '?input=' +
                                value +
                                '&key=' +
                                API_KEY +
                                '&sessiontoken=' +
                                _sessionToken;
                              await axios.get(request).then(async (response) => {
                                // console.log(response);
                                this.setState({ locOptions: response.data.predictions });
                              });
                            }
                          },1000)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="filled-basic"
                              placeholder={strings["Escribe la dirección"]}
                              variant="filled"
                              className="inputfocus"
                              multiline
                              style={{
                                borderRadius: '10px',
                                padding: this.state.hasClickedLoc ? "1px 5px" : "7.5px 5px",
                                width: "100%",
                                fontSize: "16px",
                                outline: "none",
                                color: "#555555",
                                lineHeight: "2",
                                fontWeight: "500",
                                border: '1px solid #a4aebd',
                                height: this.state.hasClickedLoc ? '108px' : undefined,
                                overflow: 'auto'
                              }}
                            />
                            )}
                          renderOption={(props, option) => {
                            const matches =
                              option.structured_formatting.main_text_matched_substrings;
                            const parts = parse(
                              option.structured_formatting.main_text,
                              matches.map((match) => [
                                match.offset,
                                match.offset + match.length,
                              ])
                            );

                            return (
                              <li {...props}>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <Box
                                      component={LocationOnIcon}
                                      sx={{ color: 'text.secondary', mr: 2 }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    {parts.map((part, index) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}

                                    <Typography variant="body2" color="text.secondary">
                                      {option.structured_formatting.secondary_text}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </li>
                            );
                          }}
                        />
                        <input type="hidden" id="city2" name="city2" />
                        <input type="hidden" id="cityLat" name="cityLat" />
                        <input type="hidden" id="cityLng" name="cityLng" />
                      </div>
                    </div>
                    <div style={{width: this.state.hasClickedLoc ? '40px' : undefined}}></div>
                    <div id="map" style={{width: '100%', height: this.state.hasClickedLoc ? '108px' : undefined, borderRadius: '10px', overflow: 'hidden', marginBottom: this.state.hasClickedLoc ? "20px" : undefined}}>
                    {(this.state.hasClickedLoc) ?
                    <GoogleMapReact
                          defaultCenter={this.state.defaultProps.center}
                          defaultZoom={this.state.defaultProps.zoom}
                          resetBoundsOnResize={true}
                          yesIWantToUseGoogleMapApiInternals
                          options={{
                            fullscreenControl: false,
                            scrollwheel: true,
                            panControl: true,
                            zoomControl: true,
                            rotateControl: false,
                            draggable: true
                          }}
                          bootstrapURLKeys={{
                            key: constants.googleMapsAPI,
                          }}
                          style={{ height: '23vh' }}
                          onChange={(changeData) => {
                            // console.log(changeData);
                            var dist = geolib.getDistance(
                              {
                                latitude: changeData.center.lat,
                                longitude: changeData.center.lng,
                              },
                              {
                                latitude: this.state.defaultPropsCircle.center.lat,
                                longitude: this.state.defaultPropsCircle.center.lng,
                              }
                            );
                            if(dist<=100) {
                            this.setState({
                              defaultProps: {
                                center: {
                                  lat: changeData.center.lat,
                                  lng: changeData.center.lng,
                                },
                                zoom: changeData.zoom,
                              },
                              cityLat: changeData.center.lat,
                              cityLng: changeData.center.lng,
                              // selectLocationData: {
                              //   id: this.state.selectLocationData.id,
                              //   name: this.state.selectLocationData.name,
                              //   address: this.state.selectLocationData.address,
                              //   geoAddress: this.state.selectLocationData.geoAddress,
                              //   addressNotes:
                              //     this.state.selectLocationData.addressNotes,
                              //   latitude: changeData.center.lat,
                              //   longitude: changeData.center.lng,
                              // },
                            });
                          } else {
                            Geocode.setApiKey(
                              constants.googleMapsAPI
                            );
                            Geocode.setLanguage('es');
                            Geocode.setRegion('MX');
                            Geocode.setLocationType('ROOFTOP');
                            Geocode.enableDebug();
                            Geocode.fromLatLng(changeData.center.lat,changeData.center.lng).then(
                            // Geocode.fromAddress(newValue.description).then(
                              (response) => {
                                this.state.circleData.setCenter({lat: changeData.center.lat, lng: changeData.center.lng});
                                // const { lat, lng } =
                                //   response.results[0].geometry.location;
                                // console.log(lat, lng);
                                this.setState({
                                  defaultProps: {
                                    center: {
                                      lat: changeData.center.lat,
                                      lng: changeData.center.lng,
                                    },
                                    zoom: 16,
                                  },
                                  defaultPropsCircle: {
                                    center: {
                                      lat: changeData.center.lat,
                                      lng: changeData.center.lng,
                                    },
                                    zoom: 16,
                                  },
                                  city2: response.results[0].formatted_address,
                                  cityLat: changeData.center.lat,
                                  cityLng: changeData.center.lng,
                                  // selectLocationData: {
                                  //   id: this.state.selectLocationData.id,
                                  //   name: this.state.selectLocationData.name,
                                  //   address: this.state.selectLocationData.address,
                                  //   geoAddress: this.state.selectLocationData.geoAddress,
                                  //   addressNotes:
                                  //     this.state.selectLocationData.addressNotes,
                                  //   latitude: changeData.center.lat,
                                  //   longitude: changeData.center.lng,
                                  // },
                                });
                                this.setState({ hasClickedLoc: true });
                                this.setState({ autoLoc: response.results[0].formatted_address });
                              },
                              (error) => {
                                console.error(error);
                              }
                            );
                          }
                          }}
                        ><LocationOnIcon
                        style={{
                          color: constants.primaryColor,
                          transform: 'translate(-50%, -100%)',
                        }}
                        fontSize={'large'}
                        lat={this.state.defaultProps.center.lat}
                        lng={this.state.defaultProps.center.lng}
                        // text={'Google Map'}
                      />
                    </GoogleMapReact>: null}</div>  
                  </div>
                </div>
                <div style={{marginBottom: "20px"}}>
                  {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Email"]}{(this.state.emailerror)?<span id="emailerror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                  <input value={this.state.restEmail}  onChange={(event)=> {
                      this.setState({
                        restEmail: event.target.value
                      });
                    }} id="restEmail" required type="email" name="" maxlength="100" placeholder={strings["Ingresa tu correo electrónico"]} className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd'
                    }}/>
                </div>
                <div style={{marginBottom: "20px"}}>
                  {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Nombre de la persona a contactar"]}{(this.state.inchargeerror)?<span id="inchargeerror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                  <input value={this.state.restPerson}  onChange={(event)=> {
                      this.setState({
                        restPerson: event.target.value
                      });
                    }} id="restPerson" required type="text" name="" maxlength="100" placeholder={strings["Ingresa el nombre de la persona a contactar"]} className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd'
                    }}/>
                </div>
                <div style={{display: "flex"}}>
                  <div style={{marginBottom: "20px", width: 'calc(50% - 10px)'}}>
                    {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Número celular"]}{(this.state.phoneerror)?<span id="phoneerror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                    <input value={this.state.restPhone}  onChange={(event)=> {
                      this.setState({
                        restPhone: event.target.value
                      });
                    }} id="restPhone" required type="phone" name="" placeholder={strings["Ingresa su teléfono"]} className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd',
                    }}/>
                  </div>
                  <div style={{width: '20px'}}></div>
                  <div style={{marginBottom: "20px", width: 'calc(50% - 10px)'}}>
                    {/* <div style={{fontSize: "15px",color: "#999999",paddingLeft: "5px"}}>{strings["Cargo de la persona"]}{(this.state.poserror)?<span id="poserror" style={{fontSize: "15px",color: "#FF4B5A",paddingLeft: "5px"}}>{strings["requerido"]}</span>:null}</div> */}
                    <input value={this.state.restPos}  onChange={(event)=> {
                      this.setState({
                        restPos: event.target.value
                      });
                    }} id="restPos" required type="text" name="" placeholder={strings["Ingresa su posición"]} className="inputfocus"
                    style={{
                      borderRadius: '10px',
                      padding: "8px 5px",
                      width: "100%",
                      fontSize: "16px",
                      outline: "none",
                      color: "#555555",
                      lineHeight: "2",
                      fontWeight: "500",
                      border: '1px solid #a4aebd',
                    }}/>
                  </div>
                </div>
                {(this.state.showwhataspp) ?
                <div id="showwhataspp" style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                }}>
                  <a href={`https://wa.me/${constants.whatsappNumber}`} target='_blank' rel="noreferrer"
                   style={{
                    color: "red",
                    fontWeight: "600",
                    fontFamily: "Nunito",
                    textDecoration: "none",
                  }}>
                    {strings["Lo sentimos, aún no operamos en la ubicación proporcionada, contáctenos aquí para obtener ayuda"]}
                  </a>
                </div>
                :null}
                <div>
                  <button type='button' onClick={async()=>{
                    this.setState({
                      nameerror: false,
                      addresserror: false,
                      typeerror: false,
                      cityerror: false,
                      emailerror: false,
                      inchargeerror: false,
                      phoneerror: false,
                      poserror: false,
                      loading: true
                    });
                  var latitude = this.state.cityLat;
                  var longtitude = this.state.cityLng;
                  var address = this.state.city2;
                  var name = this.state.restName;
                  var type = this.state.restType;
                  var city = this.state.selectedCity;
                  var cityId = this.state.selectedCityId;
                  var email = this.state.restEmail;
                  var inchargeName = this.state.restPerson;
                  var phone = this.state.restPhone;
                  var inchargePos = this.state.restPos;

                  if(latitude!=null && latitude!=='' && longtitude!=null && longtitude!=='' && address!=null && address!=='' && name!=null && name!=='' && type!=null && type!=='' && city!=null && city!=='' && cityId!=null && cityId!=='' && email!=null && email!=='' && inchargePos!=null && inchargePos!=='' && phone!=null && phone!=='' && inchargeName!=null && inchargeName!=='') {

                  console.log(latitude,longtitude,address,name,type,city,cityId,email,inchargeName,phone,inchargePos);

                  var body = { "email": email,
                        "busName": name,
                        "address": address,
                        "type": type,
                        "incharge": inchargeName,
                        "phoneIncharge": phone,
                        "position": inchargePos,
                        "city": city,
                        "cityId": cityId,
                        "latitude": latitude,
                        "longitude": longtitude };
                  await axios
                              .post('https://senor-order-85fa55d895ba.herokuapp.com/drivers/create-bus/', body)
                              .then((data) => {
                                console.log('Success:', data.data);
                                if(data.data['url']!=null) {
                                  this.setState({
                                    loading: false,
                                    });
                                  window.open(data.data['url'],"_self")
                                } else if(data.data['whatsapp']!=null) {
                                  this.setState({
                                    showwhataspp: true,
                                    showwhataspp2: true,
                                    loading: false,
                                    showwhataspp3: "https://wa.me/"+data.data['whatsapp'],
                                  });

                                    } else {
                                      this.setState({
                                        loading: false,
                                        });
                                    }
                              })
                              //Then with the error genereted...
                              .catch((error) => {
                                console.error('Error:', error);
                                this.setState({
                                  loading: false,
                                  });
                              });

                  } else {
                    if(name==null || name==='') {
                      this.setState({
                        loading: false,
                        nameerror: true
                        });
                    }
                    if(address==null || address==='' ||latitude==null || latitude==='' || longtitude==null || longtitude==='') {
                      this.setState({
                        loading: false,
                        addresserror: true
                        });
                    }
                    if(type==null || type==='') {
                      this.setState({
                        loading: false,
                        typeerror: true
                        });
                    }
                    if(city==null || city==='' ||cityId==null || cityId==='') {
                      this.setState({
                        loading: false,
                        cityerror: true
                        });
                    }
                    if(email==null || email==='') {
                      this.setState({
                        loading: false,
                        emailerror: true
                        });
                    }
                    if(inchargeName==null || inchargeName==='') {
                      this.setState({
                        loading: false,
                        inchargeerror: true
                        });
                    }
                    if(phone==null || phone==='') {
                      this.setState({
                        loading: false,
                        phoneerror: true
                        });
                    }
                    if(inchargePos==null || inchargePos==='') {
                      this.setState({
                        loading: false,
                        poserror: true
                        });
                    }
                  }
                }}
                style={{
                  border: 0,
                  borderRadius: "10px",
                  padding: "12px 30px",
                  fontSize: "17px",
                  fontEeight: "600",
                  color: "#ffffff",
                  width: '100%',
                  fontWeight: '600',
                  fontFamily: 'Nunito',
                  cursor: (
                    this.state.cityLat != null && this.state.cityLat !== '' &&
                    this.state.cityLng != null && this.state.cityLng !== '' &&
                    this.state.city2 != null && this.state.city2 !== '' &&
                    this.state.restName != null && this.state.restName !== '' &&
                    this.state.restType != null && this.state.restType !== '' &&
                    this.state.selectedCity != null && this.state.selectedCity !== '' &&
                    this.state.selectedCityId != null && this.state.selectedCityId !== '' &&
                    this.state.restEmail != null && this.state.restEmail !== '' &&
                    this.state.restPerson != null && this.state.restPerson !=='' &&
                    this.state.restPhone != null && this.state.restPhone !== '' &&
                    this.state.restPos != null && this.state.restPos !== ''
                  ) ? 'pointer' : 'not-allowed',
                  backgroundColor: (
                    this.state.cityLat != null && this.state.cityLat !== '' &&
                    this.state.cityLng != null && this.state.cityLng !== '' &&
                    this.state.city2 != null && this.state.city2 !== '' &&
                    this.state.restName != null && this.state.restName !== '' &&
                    this.state.restType != null && this.state.restType !== '' &&
                    this.state.selectedCity != null && this.state.selectedCity !== '' &&
                    this.state.selectedCityId != null && this.state.selectedCityId !== '' &&
                    this.state.restEmail != null && this.state.restEmail !== '' &&
                    this.state.restPerson != null && this.state.restPerson !=='' &&
                    this.state.restPhone != null && this.state.restPhone !== '' &&
                    this.state.restPos != null && this.state.restPos !== ''
                  ) ? constants.primaryColor : '#a4aebd'
                }}>
                    <span>{strings["Enviar solicitud"]}</span>
                  </button>
                </div>
                <Typography style={{fontSize: '14px', color: 'slategray', fontWeight: '400', lineHeight: '1.1', marginTop: '10px'}}>
                  {strings["Comenzar registro descr"]}
                </Typography>
              </div>
            </div>
          </div>

          <div style={{
              background: constants.primaryColor,
              padding: this.state.width <= 600 ? '40px 5% 50px 5%' : '60px 5% 60px 5%',
          }}>
            <div>
              <Typography style={{
                fontSize: this.state.width <= 600 ? '28px' : '34px',
                color: 'white', fontWeight: '800',
                paddingBottom: this.state.width <= 600 ? '30px' : undefined,
                textAlign: 'center'}}>
                {strings["Por qué tener tu restaurante en"]}&nbsp;{constants.appName}
              </Typography>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: this.state.width <= 600 ? 'column' : undefined,
              padding: this.state.width <= 600 ? undefined : '40px 0 0 0',
              gap: this.state.width <= 600 ? '20px' : undefined
            }}>
              <div
              style={{
                  border: '1px solid white',
                  borderRadius: '10px',
                  margin: this.state.width <= 600 ? undefined : '0 10px',
                  padding: '14px'
              }}>
                <Typography style={{fontSize: '22px', color: 'white', fontWeight: '700', lineHeight: '1.1', marginBottom: '8px'}}>
                  {strings["Incrementa tus ventas hasta un 30%"]}
                </Typography>
                <Typography style={{fontSize: '17px', color: 'white', fontWeight: '500', lineHeight: '1.1'}}>
                  {strings["Logra aumentar tus ventas hasta un 30% sin subir tus costos operativos"]}
                </Typography>
              </div>
              <div
              style={{
                  border: '1px solid white',
                  borderRadius: '10px',
                  margin: this.state.width <= 600 ? undefined : '0 10px',
                  padding: '14px'
              }}>
                <Typography style={{fontSize: '22px', color: 'white', fontWeight: '700', lineHeight: '1.1', marginBottom: '8px'}}>
                  {strings["Incrementa la exposición de tu restaurante"]}
                </Typography>
                <Typography style={{fontSize: '17px', color: 'white', fontWeight: '500', lineHeight: '1.1'}}>
                  {strings["Aumenta la visibilidad de tu restaurante"]}
                </Typography>
              </div>
              <div
              style={{
                  border: '1px solid white',
                  borderRadius: '10px',
                  margin: this.state.width <= 600 ? undefined : '0 10px',
                  padding: '14px'
              }}>
                <Typography style={{fontSize: '22px', color: 'white', fontWeight: '700', lineHeight: '1.1', marginBottom: '8px'}}>
                  {strings["Reduce tus costos"]}
                </Typography>
                <Typography style={{fontSize: '17px', color: 'white', fontWeight: '500', lineHeight: '1.1'}}>
                  {strings["Disminuye tus costos fijos"]}
                </Typography>
              </div>
              <div
              style={{
                  border: '1px solid white',
                  borderRadius: '10px',
                  margin: this.state.width <= 600 ? undefined : '0 10px',
                  padding: '14px'
              }}>
                <Typography style={{fontSize: '22px', color: 'white', fontWeight: '700', lineHeight: '1.1', marginBottom: '8px'}}>
                  {strings["Controla cada detalle de tu restaurante"]}
                </Typography>
                <Typography style={{fontSize: '17px', color: 'white', fontWeight: '500', lineHeight: '1.1'}}>
                  {strings["Toma el control en cualquier lugar"]}
                </Typography>
              </div>
            </div>
          </div>

          <div style={{
            display: 'flex',
            padding: this.state.width <= 600 ? '50px 5%' : '50px 5%',
            flexDirection: this.state.width <= 600 ? 'column' : undefined
          }}>
            <div style={{
              width: this.state.width <= 600 ? '100%' : '50%',
              paddingRight: this.state.width <= 600 ? undefined : '2%',
              alignSelf: 'center',
              marginBottom: this.state.width <= 600 ? '40px' : undefined
            }}>
              <div
              style={{
                width: '100%',
                borderRadius: '10px',
                height: this.state.width <= 600 ? '70vh' : '81vh',
                backgroundSize: 'cover',
                backgroundImage: this.state.adminData != null &&
                (this.state.adminData['busRegSecImg'] != null && this.state.adminData['busRegSecImg'] != '') ?
                `url(${this.state.adminData['busRegSecImg'].toString()})` :
                'url("https://dl3.pushbulletusercontent.com/4hSgcB08Iw8AB3FgDEnWj9csVi6zIeof/chef.jpg")',
              }}
            />
            </div>
            <img src={'https://www.facebook.com/tr?id=' + constants.facebookPixel1 + '&ev=RegisterBusinessPage'} alt = {"RegisterBusinessPage"} style={{height: '1px', width: 'auto', display: 'none'}}/>
            <img src={'https://www.facebook.com/tr?id=' + constants.facebookPixel2 + '&ev=RegisterBusinessPage'} alt = {"RegisterBusinessPage"} style={{height: '1px', width: 'auto', display: 'none'}}/>
            <div style={{
              width: this.state.width <= 600 ? '100%' :'50%',
              paddingLeft: this.state.width <= 600 ? undefined : '2%',
              alignSelf: 'center'
            }}>
              <Typography style={{fontSize: '34px', color: 'black', fontWeight: '800', lineHeight: '1.1', marginBottom: '30px'}}>
                {strings["Afilia tus Restaurantes en"]}&nbsp;
                {constants.appName}&nbsp;
                {strings["en 3 pasos y aumenta tus ventas"]}
              </Typography>
              <Typography style={{fontSize: '17px', color: 'black', fontWeight: '500', marginBottom: '30px'}}>
                {strings["Tu crecimiento es"]}
              </Typography>
              <Typography style={{fontSize: '17px', color: 'black', fontWeight: '500', marginBottom: '10px'}}>
                {strings["Registra tu negocio2"]}
              </Typography>
              <Typography style={{fontSize: '17px', color: 'black', fontWeight: '500', marginBottom: '10px'}}>
                {strings["Configura tu tienda y crea tu menú"]}
              </Typography>
              <Typography style={{fontSize: '17px', color: 'black', fontWeight: '500', marginBottom: '10px'}}>
                {strings["Actívate y empieza a recibir órdenes"]}
              </Typography>
            </div>
          </div>

          <div style={{width: `${this.state.width}px`, backgroundColor: constants.textFieldColor}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              style={{width: `${this.state.width}px`, backgroundColor: constants.textFieldColor}}
            >
              <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
              <Stack
              direction={(this.state.width <= 700)?'column': 'row'}
              justifyContent="space-evenly"
              alignItems="center"
              style={{width: `${this.state.width}px`, backgroundColor: constants.textFieldColor}}
              spacing={0}
            >
              <Stack
              direction='column'
              justifyContent="space-evenly"
              alignItems="center"
              spacing={0}
            >
              <img
          src={mainLogo}
          alt="logo"
          style={{ width: `100px`, margin: "10px"}}
        />
        <Typography
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            color: constants.textColor,
            fontSize: `16px`,
          }}
        >
          {strings["Síguenos y obtén promociones exclusivas"]}
        </Typography>
        <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
        <Stack
              direction='row'
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <FacebookRoundedIcon onClick={()=>{
        window.open(
                    constants.aboutUsFacebook,
                    '_blank'
                  );
        }} style={{margin: "10px", fontSize: "40px",cursor: 'pointer', color: constants.textColor}} />
              <div style={{paddingLeft: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
              <InstagramIcon onClick={()=>{
        window.open(
                    constants.aboutUsInstagram,
                    '_blank'
                  );
        }} style={{margin: "10px", fontSize: "40px",cursor: 'pointer', color: constants.textColor}} />
            </Stack>
            </Stack>
            <Stack
              direction='column'
              justifyContent="space-between"
              alignItems="start"
              spacing={0}
            >
              <Typography
              onClick={()=>{
        window.open(
                    constants.aboutUsAppLink,
                    '_blank'
                  );
        }}
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            cursor: 'pointer',
            color: constants.textColor,
            fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
          }}
        >
          {strings["Más sobre nosotros"]}
        </Typography>
        <div style={{paddingTop: `${(this.state.width <= 700) ? 25 : 25}px`}}></div>
        <Typography
        onClick={()=>{
        window.open(
                    constants.businessUrl,
                    '_blank'
                  );
        }}
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            cursor: 'pointer',
            color: constants.textColor,
            fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
          }}
        >
          {strings["Registra tu restaurante"]}
        </Typography>
        <div style={{paddingTop: `${(this.state.width <= 700) ? 25 : 25}px`}}></div>
        <Typography
        onClick={()=>{
        window.open(
                    constants.driverUrl,
                    '_blank'
                  );
        }}
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            color: constants.textColor,
            cursor: 'pointer',
            fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
          }}
        >
          {strings["Ingresa como Repartidor"]}
        </Typography>
            </Stack>
            <Stack
              direction='column'
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Typography
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            fontWeight: 700,
            color: constants.textColor,
            fontSize: `${(this.state.width < 720) ? 16 : 18}px`,
          }}
        >
          {strings["Descarga la app"]}
        </Typography>
        <img
        onClick={()=>{
        window.open(
                    constants.appleAppLink,
                    '_blank'
                  );
        }}
          src={Dapple}
          alt="logo"
          style={{ width: `135px`, margin: "5px 10px",cursor: 'pointer',}}
        />
        <img
        onClick={()=>{
        window.open(
                    constants.androidAppLink,
                    '_blank'
                  );
        }}
          src={Dandroid}
          alt="logo"
          style={{ width: `135px`, margin: "5px 10px",cursor: 'pointer',}}
        />
            </Stack>
            </Stack>
            <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
            <Divider
              style={{
                width: `${this.state.width}px`,
                padding: "0px 8px",
                borderBottomWidth: '3px',
                backgroundColor: constants.primaryColor,
              }}
            />
            <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
            <Stack
              direction='row'
              style={{width: `${this.state.width}px`}}
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Typography
              onClick={()=>{
                // window.open(constants.privacyUrl,'_blank');
                window.open('/privacy',"_self");
              }}
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            cursor: 'pointer',
            fontWeight: 400,
            color: 'slategrey',
            fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
          }}
        >
          {strings["Politica de privacidad"]}
        </Typography>
        <div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.035 : 20}px`}}></div>
        <Typography
        onClick={()=>{
          // window.open(constants.aboutUsTerms, '_blank');
          window.open('/terms',"_self");
         }}
          style={{
            fontFamily: 'Nunito',
            textTransform: 'none',
            cursor: 'pointer',
            fontWeight: 400,
            color: 'slategrey',
            fontSize: `${(this.state.width < 720) ? 14 : 16}px`,
          }}
        >
          {strings["Términos"]}
        </Typography>
        <div style={{paddingLeft: `${(this.state.width <= 700) ? this.state.width * 0.035 : 20}px`}}></div>
            </Stack>
            <div style={{paddingTop: `${(this.state.width <= 700) ? 10 : 10}px`}}></div>
            </Stack>
          </div>
        </div>
  </>
  );
  }
}

export default RegisterPage2;
