import { strings } from "../translations";

export const constants = {
 appName : 'Tito Delivery',
 appNameSmall : 'Tito Delivery',
 appNameCaps : 'Tito Delivery',
 appDesc : "Todo lo que buscas, en un solo lugar",
 aboutUsTerms : 'https://menu.titodelivery.online/terminos',
 herokuOrderApp : 'https://senor-order-85fa55d895ba.herokuapp.com',
 herokuOrderAppHttp : 'senor-order-85fa55d895ba.herokuapp.com',
 clubMaximumPrice : 35.00,
 clubPrice : 139.00,
 clubMinimumPrice : 189.00,
 clubTrialTimeDays : 7,
 referralTotalAmount : 50.00,
 referralFriendAmount : 50.00,
 referralFirstAmount : 25.00,
 referralSecondAmount : 25.00,
 referralThirdAmount : 0.00,
 referralForthAmount : 0.00,
 referralFifthAmount : 0.00,
 referralRemainingAmount : 25.00,
 serverKey : 'AAAA2A0u8TA:APA91bHVxEaoqDyU6JRs2OsSJKh3bn-4oMoklw43x9882sl7BIzzK85JBh5E7V4euZkTU90-Sjlgr9c311IM_YOSPWgH8hV9VpsaU3SJN79QTUV2fpImN1ui0vs4d35xPnPkPPS4WsfN',
 paypalClientId : 'ARst12ApGFA1iqMFMXELdy0SyY2AZREk-1g1wOGw1c9r7hwXHFvcjeQ3vgpCWRw3Vf45toID1ow3WYC4',
 paypalSecret : 'ECpk_P7JdNA_Rjg18INAAuQn0FaMAWfz-eBea7QEbUMXMHms-TvIkr5kXLHG-N6j0eohqR-Jt8XyOiBO',
 stripeAccountId : 'acct_1GO9T2FLpK2zu3MK',
 stripePublic : 'pk_live_yjU14pTLtQSrp5CVC48x5sQA00U9HYM4b2',
 googleMapsAPI : 'AIzaSyA__jxuP3xDJqam8cpg45nbQqfoFLcJ8QI',
 aboutUsAppLink : '',
 aboutUsFacebook : '',
 aboutUsInstagram : '',
//  welcome1Title : 'welcome1Title'.tr(),
//  welcome1SubTitle : 'welcome1SubTitle'.tr(),
//  welcome2Title : 'welcome2Title'.tr(),
//  welcome2SubTitle : 'welcome2SubTitle'.tr(args: [appName]),
//  welcome3Title : 'welcome3Title'.tr(args: [appName]),
//  welcome3SubTitle : 'welcome3SubTitle'.tr(),
 orderSupport : 'YesFloresSatanmaria@gmail.com',
 appDomain : 'https://sdfooddelivery.store',
 businessUrl : 'https://sdfooddelivery.store',
 driverUrl : 'https://sdfooddelivery.store',
 appleAppLink : '',
 androidAppLink : '',
 privacyUrl : 'https://menu.titodelivery.online/privacidad',
 userAppDomain : 'senor-delivery-app.web.app',
 herokuBusinessApp : 'https://senor-business-4a3c0bfd75a6.herokuapp.com',
 stripePICust : 'https://us-central1-senor-delivery-app-app.cloudfunctions.net/StripePICustWeb',
 firebaseLogo : 'https://firebasestorage.googleapis.com/v0/b/senor-delivery-app.appspot.com/o/logo.png?alt=media&token=be82befe-ff75-4426-b35d-6f0dd080ae6a',
primaryColor : '#000000',
secondaryColor : '#000000',
backgroundColor : '#fff',
buttonColor : '#000000',
iconColor : '#9f9fad',
circleColor : '#000000',
textFieldColor : '#f8f9fb',
textColor : '#121212',
shadowColor : '#9E9E9E',
 spinGamePrizeCaps : strings['spinGamePrizeCaps'],
 spinGamePrizeSmall : strings['spinGamePrizeSmall'],
 jackpotGamePrize : 30.00,
 scratchGamePrize : 10.00,
 storeName : 'Oxxo',
 storeAdditional : 9.00,
}
